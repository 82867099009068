/*

  r o u t e r
  Router

  :description:
  A file to hold our React DOM Router.

  Note, this file will import lots of other files.

*/

//
//  :react & redux:
import React from 'react';
import { createBrowserRouter, } from "react-router-dom";

//
//  :routes:
import View from "./view/View";
import Calibrate from './calibrate/Calibrate';
import NotFound from './not-found/NotFound';
import SideBySide from './side-by-side/SideBySide';
import Login from './login/Login';
import Approve from './approve/Approve';
//import Error from './error/Error';

//
//  :exports:
export const KEYWORDS = {
  calibrate: "calibrate",
  view: "view",
  launch: "launch",
  arid: "arid",
  sideBySide: "side-by-side",
  login: "login",
  approve: "approve",
  translate: "translate"
}

export const PATHS = {
  root: "/",
  any: "*",
  view: {
    list: `/${KEYWORDS.view}`,
    specific: `/${KEYWORDS.view}/:${KEYWORDS.arid}`
  },
  calibrate: {
    list: `/${KEYWORDS.calibrate}`,
    specific: `/${KEYWORDS.calibrate}/:${KEYWORDS.arid}`
  },
  launch: {
    list: `/${KEYWORDS.launch}`,
    specific: `/${KEYWORDS.launch}/:${KEYWORDS.arid}`
  },
  sideBySide: {
    list: `/${KEYWORDS.sideBySide}`,
    specific: `/${KEYWORDS.sideBySide}/:${KEYWORDS.arid}`
  },
  approve: {
    list: `/${KEYWORDS.approve}`,
    specific: `/${KEYWORDS.approve}/:${KEYWORDS.arid}`
  },
  login: `/${KEYWORDS.login}`,

}

export const ROUTER = createBrowserRouter([
  {
    path: PATHS.root,
    element: <NotFound></NotFound>,
  },
  {
    path: PATHS.view.specific,
    element: <View />,
  },
  /*
  //  @Louis: Removed for now.
  {
    path: PATHS.launch.specific,
    element: <EmptyComponent name="launch" />,
  },
  */
  {
    path: PATHS.calibrate.specific,
    element: <Calibrate />,
  },
  {
    path: PATHS.sideBySide.specific,
    element: <SideBySide />,
  },
  {
    path: PATHS.login,
    element: <Login />,
  },
  {
    path: PATHS.approve.specific,
    element: <Approve />,
  },
  {
    path: PATHS.any,
    element: <NotFound />,
  },
]);
