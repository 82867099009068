//
//  :react & redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//
//  :code:
import { APP_CORE } from './memory'

//
//  :state:
//  Our main redux state for this feature.
const initialState = {
  isConfigured: false,
  initialiseAppCore: {
    loading: false,
    error: false,
    response: false,
    status: null,
    ready: false,
  },
}

//
//  :thunks:
//  We only really have 1x async thunk here.

export const initialiseAppCoreAsync = createAsyncThunk('app-core/initialise-app-core', async payload => {
  const response = await APP_CORE.go()
  return response.data
})

export const appCoreSlice = createSlice({
  name: 'app-core',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    configureAppCore(state) {
      //
      //  :step 1:
      //  Configure all of the HTTP Get arguments that the app supports.
      APP_CORE.browser.addHttpGetOption('hideARQRButton', 'eky-ar-qr-button', false, val => {
        return val === 'true' || val === 'yes'
      })
      APP_CORE.browser.addHttpGetOption('viewUISticky', 'eky-view-ui-sticky', 'NE', String)
      APP_CORE.browser.addHttpGetOption('primaryColour', 'eky-colour-primary', '0CB7EB', String)
      APP_CORE.browser.addHttpGetOption('secondaryColour', 'eky-colour-secondary', 'FFFFFF', String)
      APP_CORE.browser.addHttpGetOption('qrBackgroundColour', 'eky-qr-background-colour', 'rgba(0,0,0,0.85)', String)
      APP_CORE.browser.addHttpGetOption('backgroundColour', 'eky-background-colour', '#ffffff', String)
      APP_CORE.browser.addHttpGetOption('showPausePlayUI', 'eky-show-pause-play-ui', false, Boolean, true)
      APP_CORE.browser.addHttpGetOption('startPaused', 'eky-start-paused', false, Boolean, true)
      APP_CORE.browser.addHttpGetOption('staticView', 'eky-static-view', false, Boolean, true)

      //
      //  :step 2:
      //  Configure our options.
      APP_CORE.setOptionValue('scanForAR', true)
      APP_CORE.setOptionValue('localisation', {
        app: 'eky-3d-player',
        version: 'latest',
      })

      //
      //  :step 3:
      //  Mark that we are now configured.
      state.isConfigured = true
    },
    configureLogging(state, action) {
      //
      //  :step 0:
      //  Correct the language.
      let language = APP_CORE.readBrowserConfig('language') || 'en'
      if (language) {
        language = language.split('-')[0]
      }
      //
      //  :step 1:
      //  If we loaded view, we should initialise our logging runtime.
      APP_CORE.initialiseLoggingLibraryRuntime('syndication', {
        distributor: APP_CORE.readBrowserConfig('distributor') || '3d-player-dev-distributor',
        language: language,
        page: APP_CORE.readBrowserConfig('page') || null,
        service: '3d-player',
        arid: APP_CORE.readBrowserConfig('arid') || action.payload.arid || null,
        environment: APP_CORE.readBrowserConfig('distributor') && APP_CORE.readBrowserConfig('page') ? 'prod' : 'dev', // @Louis: Repair this.
        //
        //  @Louis: This is not yet working.
        memoryOnly: true,
        MEMORY_ONLY_STORAGE_ONLY: true,
      })
    },
    logModelDelivery(state, action) {
      //
      //  :step 2:
      //  We can now log if we have everything we need to do so.
      if (
        APP_CORE.readBrowserConfig('distributor') &&
        APP_CORE.readBrowserConfig('language') &&
        APP_CORE.readBrowserConfig('page')
      ) {
        APP_CORE.log._enable()
        APP_CORE.log.send(
          {
            type: 'delivery',
            detail: `model`,
            service: '3d-player',
            arid: action.payload.arid,
          },
          'log'
        )
      }
    },
    logModelClick(state, action) {
      APP_CORE.log._enable()
      APP_CORE.log.send(
        {
          type: 'click',
          detail: `model|${action.payload.x},${action.payload.y}|${action.payload.type}`,
          service: '3d-player',
          arid: action.payload.arid,
        },
        'log'
      )
    },
  },
  extraReducers: builder => {
    builder
      //
      //  :save-to-cloud:
      .addCase(initialiseAppCoreAsync.pending, state => {
        state.initialiseAppCore.error = null
        state.initialiseAppCore.loading = true
        state.initialiseAppCore.response = null
        state.initialiseAppCore.status = 'loading'
      })
      .addCase(initialiseAppCoreAsync.fulfilled, (state, action) => {
        state.initialiseAppCore.error = null
        state.initialiseAppCore.loading = false
        state.initialiseAppCore.response = action.payload
        state.initialiseAppCore.status = 'idle'
        state.initialiseAppCore.ready = true
      })
  },
})

//
//  :exports:
//  The named exports for this feature.
export const { configureAppCore, configureLogging, logModelDelivery, logModelClick } = appCoreSlice.actions

//
//  :selectors:
//  Mechanisms to select from state.
export const selectIsConfigured = state => state.appCore.isConfigured
export const selectInitialiseAppCore = state => state.appCore.initialiseAppCore

//
//  :reducer:
export default appCoreSlice.reducer
