import React from 'react'

export const PauseIcon = props => {
  const fill = props.fill || '0CB7EB'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none">
      <circle cx="30" cy="30" r="30" fill={`#${fill}`} />
      <path fill="#fff" d="M27 48h6V24h-6v24Zm12 0h6V24h-6v24Z" style={{ transform: 'translate(-6px, -6px)' }} />
    </svg>
  )
}
