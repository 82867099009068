/*

  c a l i b r a t i o n  s t a t i c s
  Calibration Statics

  :description:
  A file to hold onto static values and enums related to calibration/viewing models.

  :steps-to-support-a-new-attribute:
  1. Add a entry to KNOWN_CONFIG_ATTRIBUTES, mapping to a string of its name
  2. Add an entry into CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE for the new attribute
  3. If required, add a sensible default to CALIBRATION_DEFAULTS_PRIMARY
  4. To activate the attribute, add it to DEFAULT_ACTIVE_CONFIG_ATTRIBUTES

*/

//
//  Some basic naming structure.
export const NAME_VIEW = 'view'
export const NAME_CALIBRATE = 'calibrate'
export const NAME_MODEL = 'model'
export const NAME_APPROVE = 'approve'

export const MODE_VIEW = NAME_VIEW
export const MODE_CALIBRATE = NAME_CALIBRATE
export const MODE_APPROVE = NAME_APPROVE

//
//  A map of all config attributes known/supported by eyekandy.
export const KNOWN_CONFIG_ATTRIBUTES = {
  //
  //  :tier-1:
  //  Attributes that can directly be updated using XHTML & model-viewer.
  shadowIntensity: 'shadowIntensity',
  shadowSoftness: 'shadowSoftness',
  autoRotate: 'autoRotate',
  cameraControls: 'cameraControls',
  modelExposure: 'modelExposure',
  environmentSkybox: 'environmentSkybox',
  orbitSensitivity: 'orbitSensitivity',
  autoPlay: 'autoPlay',
  loadMode: 'loadMode',
  altText: 'altText',
  interactionPrompt: 'interactionPrompt',
  interactionPromptMode: 'interactionPromptMode',
  rotationSpeed: 'rotationSpeed',
  rotationDelay: 'rotationDelay',
  environmentImage: 'environmentImage',
  maxCameraOrbit: 'maxCameraOrbit',
  fieldOfView: 'fieldOfView',
  minFieldOfView: 'minFieldOfView',
  cameraOrbit: 'cameraOrbit',
  backgroundColor: 'backgroundColor',
  disableZoom: 'disableZoom',
  touchAction: 'touchAction',
  disablePan: 'disablePan',
  disableTap: 'disableTap',

  //
  //  :tier-2:
  //  Attributes that need a reference to a loaded model-viewer instance in JS.
  modelMetalness: 'modelMetalness',
  modelRoughness: 'modelRoughness',
  scale: 'scale',
  modelPlacement: 'modelPlacement',
  cameraTarget: 'cameraTarget',

  //
  //  :tier-3:
  //  Attributes that need multiple different references to different attributes of a loaded model-viewer instance.
  noneForNow: 'noneForNow',

  //
  //  :tier-eky:
  //  Attributes that eyekandy has defined, they may or may not map onto model producer.
  canViewUnderModel: 'canViewUnderModel',
  staticView: 'staticView',
  isVisible: 'isVisible',
  alwaysRotate: 'alwaysRotate',

  //
  //  :tier-threeJS:
  //  Attributes that can directly be updated using model-viewer model-viewer-effects.
  toneMapping: 'toneMapping',
  contrast: 'contrast',
  brightness: 'brightness',
  hue: 'hue',
  saturation: 'saturation'
}
export const KNOWN_CONFIG_ATTRIBUTES_ARRAY = []
export const KNOWN_CONFIG_ATTRIBUTE_NAMES_ARRAY = []
for (const [k, v] of Object.entries(KNOWN_CONFIG_ATTRIBUTES)) {
  KNOWN_CONFIG_ATTRIBUTES_ARRAY.push(k)
  KNOWN_CONFIG_ATTRIBUTE_NAMES_ARRAY.push(v)
}

//
//  A map of how our attributes names map against the model viewer attribute names.
export const CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE = {}
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.shadowIntensity] = 'shadow-intensity'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.shadowSoftness] = 'shadow-softness'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.autoRotate] = 'auto-rotate'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.cameraControls] = 'camera-controls'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.scale] = 'ar-scale'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.modelPlacement] = 'ar-placement'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.modelExposure] = 'exposure'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.environmentSkybox] = 'skybox-image'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.modelMetalness] = 'metalness'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.modelRoughness] = 'roughness'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.orbitSensitivity] = 'orbit-sensitivity'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.cameraTarget] = 'camera-target'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.autoPlay] = 'autoplay'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.loadMode] = 'loading'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.altText] = 'alt'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.interactionPrompt] = 'interaction-prompt'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.interactionPromptMode] = 'interaction-prompt-style'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.rotationSpeed] = 'rotation-per-second'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.rotationDelay] = 'auto-rotate-delay'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.environmentImage] = 'environment-image'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.canViewUnderModel] = 'can-view-under-model'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.maxCameraOrbit] = 'max-camera-orbit'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.fieldOfView] = 'field-of-view'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.minFieldOfView] = 'min-field-of-view'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.cameraOrbit] = 'camera-orbit'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.backgroundColor] = 'background-color'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.disableZoom] = 'disable-zoom'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.touchAction] = 'touch-action'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.disablePan] = 'disable-pan'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.disableTap] = 'disable-tap'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.staticView] = 'static-view'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.isVisible] = 'is-visible'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.alwaysRotate] = 'always-rotate'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.toneMapping] = 'tone-mapping'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.contrast] = 'contrast'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.brightness] = 'brightness'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.hue] = 'hue'
CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[KNOWN_CONFIG_ATTRIBUTES.saturation] = 'saturation'

//
//  An inverted version of our CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE object.
export const MODEL_VIEWER_ATTRIBUTE_TO_CONFIG_ATTRIBUTE = {}
for (const [k, v] of Object.entries(CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE)) {
  MODEL_VIEWER_ATTRIBUTE_TO_CONFIG_ATTRIBUTE[v] = k
}

//
//  A set of sensible defaults for viewing a model.
export const CALIBRATION_DEFAULTS_PRIMARY = {}
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.shadowIntensity] = 0.7
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.shadowSoftness] = 1.25
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.autoRotate] = true
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.cameraControls] = true
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.modelExposure] = 1
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.environmentSkybox] = ''
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.orbitSensitivity] = 1
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.modelMetalness] = 0
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.modelRoughness] = 0
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.scale] = 'auto'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.modelPlacement] = 'floor'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.cameraTarget] = 'auto auto auto'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.autoPlay] = false
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.loadMode] = 'lazy'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.altText] = '3D Model Visualisation'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.interactionPrompt] = 'auto' // "auto/none"
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.interactionPromptMode] = 'basic' // "wiggle/basic"
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.rotationSpeed] = '-33deg'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.rotationDelay] = 5000
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.environmentImage] = ''
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.canViewUnderModel] = true
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.maxCameraOrbit] = 'auto auto auto'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.fieldOfView] = 'auto'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.minFieldOfView] = '12deg'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.cameraOrbit] = 'auto auto auto'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.backgroundColor] = '#FFFFFF'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.disableZoom] = false
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.touchAction] = 'pan-y'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.disablePan] = false
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.disableTap] = true
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.staticView] = false
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.isVisible] = true
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.toneMapping] = 'ACES_FILMIC'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.contrast] = '0'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.brightness] = '0'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.hue] = '0'
CALIBRATION_DEFAULTS_PRIMARY[KNOWN_CONFIG_ATTRIBUTES.saturation] = '0'


export const CALIBRATION_DEFAULTS_BASIC = { ...CALIBRATION_DEFAULTS_PRIMARY }
CALIBRATION_DEFAULTS_BASIC[KNOWN_CONFIG_ATTRIBUTES.autoRotate] = false
CALIBRATION_DEFAULTS_BASIC[KNOWN_CONFIG_ATTRIBUTES.canViewUnderModel] = false
CALIBRATION_DEFAULTS_BASIC[KNOWN_CONFIG_ATTRIBUTES.maxCameraOrbit] = 'auto 90deg auto'

//
//  A set of the config parameters to apply by default.
export const DEFAULT_ACTIVE_CONFIG_ATTRIBUTES = [
  //
  //  :meta:
  KNOWN_CONFIG_ATTRIBUTES.altText,
  //
  //  :loading:
  KNOWN_CONFIG_ATTRIBUTES.loadMode,
  //
  //  :camera & control:
  KNOWN_CONFIG_ATTRIBUTES.cameraControls,
  KNOWN_CONFIG_ATTRIBUTES.interactionPrompt,
  KNOWN_CONFIG_ATTRIBUTES.interactionPromptMode,
  KNOWN_CONFIG_ATTRIBUTES.modelExposure,
  KNOWN_CONFIG_ATTRIBUTES.canViewUnderModel,
  KNOWN_CONFIG_ATTRIBUTES.maxCameraOrbit,
  KNOWN_CONFIG_ATTRIBUTES.fieldOfView,
  KNOWN_CONFIG_ATTRIBUTES.minFieldOfView,
  KNOWN_CONFIG_ATTRIBUTES.cameraOrbit,
  KNOWN_CONFIG_ATTRIBUTES.disableZoom,
  KNOWN_CONFIG_ATTRIBUTES.touchAction,
  KNOWN_CONFIG_ATTRIBUTES.disablePan,
  KNOWN_CONFIG_ATTRIBUTES.disableTap,
  KNOWN_CONFIG_ATTRIBUTES.staticView,
  //
  //  :environment:
  KNOWN_CONFIG_ATTRIBUTES.environmentImage,
  KNOWN_CONFIG_ATTRIBUTES.backgroundColor,
  //
  //  :shadows:
  KNOWN_CONFIG_ATTRIBUTES.shadowIntensity,
  KNOWN_CONFIG_ATTRIBUTES.shadowSoftness,
  //
  //  :rotation:
  KNOWN_CONFIG_ATTRIBUTES.autoRotate,
  KNOWN_CONFIG_ATTRIBUTES.rotationSpeed,
  KNOWN_CONFIG_ATTRIBUTES.rotationDelay,
  //
  //  :animation:
  KNOWN_CONFIG_ATTRIBUTES.autoPlay,
  //
  //  :display:
  KNOWN_CONFIG_ATTRIBUTES.isVisible,
  //
  //  :effects:
  KNOWN_CONFIG_ATTRIBUTES.toneMapping,
  KNOWN_CONFIG_ATTRIBUTES.contrast,
  KNOWN_CONFIG_ATTRIBUTES.brightness,
  KNOWN_CONFIG_ATTRIBUTES.hue,
  KNOWN_CONFIG_ATTRIBUTES.saturation,
]

//
//  A map of some of the external resources we can use as values of config attributes.
export const KNOWN_ENVIRONMENT_IMAGES = {
  default: {
    url: '',
    name: 'Default',
  },
  legacy: {
    url: 'legacy',
    name: 'Legacy',
  },
  aircraftWorkshop: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/aircraft-workshop.hdr',
    name: 'Aircraft Workshop',
  },
  musicHall: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/music-hall.hdr',
    name: 'Music Hall',
  },
  pillars: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/pillars.hdr',
    name: 'Pillars',
  },
  spruitSunrise: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/spruit-sunrise.hdr',
    name: 'Spruit Sunrise',
  },
  whippleCreek: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/whipple-creek.hdr',
    name: 'Whipple Creek',
  },
  kitchenCream: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/kitchen-cream.hdr',
    name: 'Kitchen Cream',
  },
  kitchenColourful: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/kitchen-colourful.hdr',
    name: 'Kitchen Colourful',
  },
  kitchenWhite: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/kitchen-white.hdr',
    name: 'Kitchen White',
  },
  bathRoomOne: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/bathroom-one.hdr',
    name: 'Bathroom 1',
  },
  diningRoomOne: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/dining-room-one.hdr',
    name: 'Dining Room 1',
  },
  diningRoomTwo: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/dining-room-two.hdr',
    name: 'Dining Room 2',
  },
  diningRoomThree: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/dining-room-three.hdr',
    name: 'Dining Room 3',
  },
  livingRoomOne: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/living-room-one.hdr',
    name: 'Living Room 1',
  },
  livingRoomTwo: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/living-room-two.hdr',
    name: 'Living Room 2',
  },
  livingRoomThree: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/living-room-three.hdr',
    name: 'Living Room 3',
  },
  livingRoomFour: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/living-room-four.hdr',
    name: 'Living Room 4',
  },
  officeOne: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/office-one.hdr',
    name: 'Office 1',
  },
  officeTwo: {
    url: 'https://cdn.pointandplace.com/services/3d-player/environments/office-two.hdr',
    name: 'Office 2',
  },
}

export const KNOWN_ENVIRONMENT_IMAGES_ARRAY = []
// eslint-disable-next-line
for (const [k, v] of Object.entries(KNOWN_ENVIRONMENT_IMAGES)) {
  KNOWN_ENVIRONMENT_IMAGES_ARRAY.push(v)
}

export const CLASSIFICATION_SETTINGS = {
  EXPOSURE: {
    LOW: { min: 0, max: 1 },
    MEDIUM: { min: 1, max: 2 },
    HIGH: { min: 2, max: 3 },
    VERY_HIGH: { min: 3 },
  },
  SHADOW_INTENSITY: {
    LOW: { min: 0, max: 30 },
    MEDIUM: { min: 30, max: 60 },
    HIGH: { min: 60, max: 80 },
    VERY_HIGH: { min: 80 },
  },
  SHADOW_SOFTNESS: {
    LOW: { min: 0, max: 1 },
    MEDIUM: { min: 1, max: 2 },
    HIGH: { min: 2, max: 3 },
    VERY_HIGH: { min: 3 },
  },
}

export const CLASSIFICATION_ENUM_VALUES = {
  EXPOSURE: {
    LOW: '0.75',
    MEDIUM: '1',
    HIGH: '1.3',
    VERY_HIGH: '1.5',
    DEFAULT: CALIBRATION_DEFAULTS_BASIC.modelExposure,
  },
  SHADOW_INTENSITY: {
    LOW: '0.3',
    MEDIUM: '0.75',
    HIGH: '0.85',
    VERY_HIGH: '1.1',
    DEFAULT: CALIBRATION_DEFAULTS_BASIC.shadowIntensity,
  },
  SHADOW_SOFTNESS: {
    LOW: '1.5',
    MEDIUM: '1.7',
    HIGH: '2',
    VERY_HIGH: '2.3',
    DEFAULT: CALIBRATION_DEFAULTS_BASIC.shadowSoftness,
  },
}
