/*

  u t i l s
  Utils

  :description:
  Generic utility functions.

*/

//
//  :code:
import { CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE } from "../features/calibration/statics"

//
//  :exports:
export const isDefined = (value) => { return typeof value !== "undefined" }

export const readModelViewerAttributeValue = (config, attribute) => {
  return config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[attribute]] || null
}

export const isTruthy = (value, noIsTruthy) => {
  if (typeof value === 'undefined') { return false; }
  if (value === undefined || value === null) { return false; }
  if (value === '') { return false; }
  if (value === 0) { return false; }
  if (value === 0.0) { return false; }
  if (typeof value === "object") { if (Object.keys(value).length === 0) { return false } }
  if (value === false) { return false }
  if (value === "false") { return false }
  if (typeof value === "string") { if (value.length === 0) { return false; } }
  if (value === "no" && !noIsTruthy) { return false }
  if (value === "null") { return false }
  if (value === "undefined") { return false }
  if (value === null) { return false }
  return true;
}