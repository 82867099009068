/*

    s p i n n e r
    Spinner

    :description:
    A CSS spinner object.

*/

//
//  :react & redux:
import React from 'react';

//
//  :code:
import './Spinner.css'

//
//  :component:
export const Spinner = (props) => {
  return <>
    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </>
}
