/*
    C O N F I G  C L A S S I F I C A T I O N  v 2
    Config Classification v2

    :description:
    A class to derive old clibration settings into our new claibration config

*/

//
//  :code:
import { jsonGetRequestSafe } from "../../../js/requests";
import { CalibrationConfig } from "./CalibrationConfig";

//
//  :statics:
import { CLASSIFICATION_ENUM_VALUES, CLASSIFICATION_SETTINGS } from "../statics";

export class ClassifyLegacyCalibrationConfig extends CalibrationConfig {
  constructor() {
    super()
    //
    //  required
    this.oldPlayerConfigPath = `https://d15mv1adrb1s6e.cloudfront.net/product_settings/JSON/`

    //
    //  enums
    this.CLASSIFICATIONS = {
      EXPOSURE: {
        LOW: function (x) { return x > CLASSIFICATION_SETTINGS.EXPOSURE.LOW.min && x <= CLASSIFICATION_SETTINGS.EXPOSURE.LOW.max },
        MEDIUM: function (x) { return x > CLASSIFICATION_SETTINGS.EXPOSURE.MEDIUM.min && x <= CLASSIFICATION_SETTINGS.EXPOSURE.MEDIUM.max },
        HIGH: function (x) { return x > CLASSIFICATION_SETTINGS.EXPOSURE.HIGH.min && x <= CLASSIFICATION_SETTINGS.EXPOSURE.HIGH.max },
        VERY_HIGH: function (x) { return x > CLASSIFICATION_SETTINGS.EXPOSURE.VERY_HIGH.min }
      },
      SHADOW_SOFTNESS: {
        LOW: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_SOFTNESS.LOW.min && x <= CLASSIFICATION_SETTINGS.SHADOW_SOFTNESS.LOW.max },
        MEDIUM: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_SOFTNESS.MEDIUM.min && x <= CLASSIFICATION_SETTINGS.SHADOW_SOFTNESS.MEDIUM.max },
        HIGH: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_SOFTNESS.HIGH.min && x <= CLASSIFICATION_SETTINGS.SHADOW_SOFTNESS.HIGH.max },
        VERY_HIGH: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_SOFTNESS.VERY_HIGH.min }
      },
      SHADOW_INTENSITY: {
        LOW: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_INTENSITY.LOW.min && x <= CLASSIFICATION_SETTINGS.SHADOW_INTENSITY.LOW.max },
        MEDIUM: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_INTENSITY.MEDIUM.min && x <= CLASSIFICATION_SETTINGS.SHADOW_INTENSITY.MEDIUM.max },
        HIGH: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_INTENSITY.HIGH.min && x <= CLASSIFICATION_SETTINGS.SHADOW_INTENSITY.HIGH.max },
        VERY_HIGH: function (x) { return x > CLASSIFICATION_SETTINGS.SHADOW_INTENSITY.VERY_HIGH.min }
      }
    }
    this.CONFIG_ENUMS = {
      EXPOSURE: "EXPOSURE",
      SHADOW_SOFTNESS: "SHADOW_SOFTNESS",
      SHADOW_INTENSITY: "SHADOW_INTENSITY"
    }
  }

  //  classify function for getting the classification of the value passed
  classify(value, type) {
    if (!value) { return "DEFAULT" }
    //
    // loop through each function of type in CLASSIFICATIONS
    for (const [key, func] of Object.entries(this.CLASSIFICATIONS[type])) {
      //
      //  If a classification is found, return it
      if (func(value)) { return key }
    }
    //
    // return default if no classification was found
    return "DEFAULT";
  }


  _classifyWrapper(key, value) {
    return CLASSIFICATION_ENUM_VALUES[key][this.classify(value, this.CONFIG_ENUMS[key])]
  }

  //
  //  :lifecycle:
  //  
  async loadFromLegacyCloud(arid, isStaging) {
    //
    //  :step 1:
    //  Request the old player config for the ARID that was passed
    if (this.requesting) { return { status: 400, data: {} } }

    this.requesting = true
    const response = await jsonGetRequestSafe(`${this.oldPlayerConfigPath}${arid}.json`)
    this.requesting = false

    if (response.status > 399) { return null }

    //
    //  :step 2a:
    //  Now for each of our known translatable config settings, classify, rename, and save to self.
    //  i.e. this.autoRotate = CLASSIFIED( data.environment.orbitCamera )
    this.updateShadowIntensity(this._classifyWrapper("SHADOW_INTENSITY", response.data.shadows.blur))
    this.updateShadowSoftness(this._classifyWrapper("SHADOW_SOFTNESS", response.data.shadows.darkness))
    this.updateModelExposure(this._classifyWrapper("EXPOSURE", response.data.post.image.exposure))

    //
    //  :step 2b:
    //  Some attributes can be set directly.
    this.updateAutoRotate(response.data.environment.orbitCamera)
    this.updateCanViewUnderModel(response.data.environment.seeUnder)

    //
    //  All done, return the response.
    return response
  }
}
