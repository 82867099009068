import React from 'react'

export const FullscreenEnterIcon = props => {
  const fill = props.fill || '0CB7EB'

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
    >
      <circle cx='30' cy='30' r='30' fill='#fff' />
      <path
        fill={`#${fill}`}
        d='M16 44v-9.85h1.55v8.3h8.3V44H16Zm0-18.15V16h9.85v1.55h-8.3v8.3H16ZM34.15 44v-1.55h8.3v-8.3H44V44h-9.85Zm8.3-18.15v-8.3h-8.3V16H44v9.85h-1.55Z'
      />
    </svg>
  )
}
