/*

  
  a p p r o v e  u i
  Approve UI

  :description:

  
*/

//
//  :react & redux:
import React from 'react';

//
//  :code:
import { talk } from '@eyekandy/app-core';

//
//  :packages:

export const ApproveUI = (props) => {
  const group = `[EYEKANDY-APPROVE-UI]`

  talk([`${group} call to render`])
  return <>
    <div>
      TBC
    </div>
  </>
}

