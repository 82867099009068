import React from 'react';

export const ToggleCloseUIIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" />
        <path d="M6 6h2v12H6zm3.5 6 8.5 6V6z"></path>
    </svg>
}

export const ToggleOpenUIIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" />
        <path d="m6 18 8.5-6L6 6v12zM16 6v12h2V6h-2z"></path>
    </svg>
}