import React from 'react'

export const PlayIconv2 = props => {
  const fill = props.fill || '0CB7EB'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none">
      <circle cx="30" cy="30" r="30" fill="#fff" />
      <path d="M29 49L48 36.5L29 24V49Z" style={{ transform: 'translate(-6px, -6px)' }} fill={`#${fill}`} />
    </svg>
  )
}
