import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { login } from './authAPI'

const _localStorage = {
  getItem: key => {
    try {
      return localStorage.getItem(key)
    } catch (err) {}
    return null
  },
  removeItem: key => {
    try {
      localStorage.removeItem(key)
    } catch (err) {}
    return null
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value)
    } catch (err) {}
    return null
  }
}

export const LOCAL_STORAGE_TOKEN_KEY = 'jwt-3d-player'

export const decodeToken = token => {
  return JSON.parse(atob(token.split('.')[1]))
}

export const getToken = () => {
  return _localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
}

//
//  TODO: Handle reading the token from local storage on login.
const initialState = {
  username: _localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
    ? decodeToken(_localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)).sub
    : null,
  role: _localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
    ? decodeToken(_localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)).role
    : null,
  isLoggedIn: _localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ? true : false,
  login: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null
  }
}

export const loginAsync = createAsyncThunk('auth/login', async credentials => {
  const response = await login(credentials)
  return response.token || null
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.login.status = 'idle'
      state.login.error = null
      state.login.response = null
      state.isLoggedIn = false
      state.username = null
      state.role = null
      //
      //  Delete the token in storage.
      _localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginAsync.pending, state => {
        state.login.status = 'requesting'
        state.login.requesting = true
        state.login.error = null
        state.login.response = null
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.login.status = 'idle'
        state.login.requesting = false
        state.login.response = action.payload
        //
        //  TODO: Handle setting is isLoggedIn/username/role.
        if (action.payload) {
          const token = decodeToken(action.payload)
          state.username = token.sub
          state.role = token.role
          state.isLoggedIn = true
          _localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, action.payload)
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.login.status = 'error'
        state.login.requesting = false
        state.login.error = action.error
      })
  }
})

export const { logout } = authSlice.actions

//
//  :core:
export const selectLoginUsername = state => state.auth.username
export const selectLoginRole = state => state.auth.role
export const selectLoginIsLoggedIn = state => state.auth.isLoggedIn

//
//  :login:
export const selectLoginStatus = state => state.auth.login.status
export const selectLoginRequesting = state => state.auth.login.requesting
export const selectLoginError = state => state.auth.login.error
export const selectLoginResponse = state => state.auth.login.response

//
//  :exports:
export default authSlice.reducer
