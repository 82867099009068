import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import calibrationReducer from '../features/calibration/redux/calibrationSlice'
import appCoreReducer from '../features/app-core/redux/appCoreSlice'

export const store = configureStore({
  reducer: {
    calibration: calibrationReducer,
    auth: authReducer,
    appCore: appCoreReducer
  }
})
