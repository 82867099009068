/*

  v i e w
  View

  :description:
  Our main component to handle "view" actions in the app.
  
*/

//
//  :react & redux:
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

//
//  :code:
import './Calibrate.css';
import Page from '../../components/layout/page/Page';
import { talk } from '@eyekandy/app-core';
import EyekandyModelViewer from '../../components/models/eky-model-viewer/EyekandyModelViewer';
import { RedirectIfNotLoggedIn } from '../../components/auth/RedirectIfNotLoggedIn';
import { configureAppCore, initialiseAppCoreAsync, selectInitialiseAppCore, selectIsConfigured } from '../../features/app-core/redux/appCoreSlice';
import { useDispatch, useSelector } from 'react-redux';

//
//  :component:
const Calibrate = (props) => {
  const dispatch = useDispatch()
  const isConfigured = useSelector(selectIsConfigured)
  const initialisedAppCore = useSelector(selectInitialiseAppCore)

  const group = `[ROUTE-CALIBRATE]`

  if (!props) {
    props = {}
  }
  let { arid } = useParams();
  if (!arid) {
    arid = "8800017"
  }

  useEffect(() => {
    dispatch(configureAppCore())
  }, [dispatch])

  useEffect(() => {
    if (isConfigured) {
      dispatch(initialiseAppCoreAsync())
    }
  }, [dispatch, isConfigured])

  talk(`${group} call to render`)
  return (
    <Page>
      <RedirectIfNotLoggedIn></RedirectIfNotLoggedIn>
      {initialisedAppCore.ready && <EyekandyModelViewer arid={arid} mode={"calibrate"} calibrateUIOpen={true}></EyekandyModelViewer>}
    </Page>
  );
}

export default Calibrate;
