/*

  v i e w
  View

  :description:
  Our main component to handle "view" actions in the app.
  
*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

//
//  :code:
import './View.css'
import Page from '../../components/layout/page/Page'
import EyekandyModelViewer from '../../components/models/eky-model-viewer/EyekandyModelViewer'
import { talk } from '@eyekandy/app-core'
import { useDispatch, useSelector } from 'react-redux'
import {
  configureAppCore,
  initialiseAppCoreAsync,
  selectInitialiseAppCore,
  selectIsConfigured,
} from '../../features/app-core/redux/appCoreSlice'
import { APP_CORE } from '../../features/app-core/redux/memory'
import { updateStaticView } from '../../features/calibration/redux/calibrationSlice'

//
//  :component:
const View = props => {
  const group = `[ROUTE-VIEW]`
  const [searchParams] = useSearchParams()

  //
  //  @liam: custom code for OFL
  const [isOFL, setISOFL] = useState(false)

  //
  //  :redux:
  //  As this is a top level component we need to enable redux calls for app-core config.
  const dispatch = useDispatch()
  const isConfigured = useSelector(selectIsConfigured)
  const initialisedAppCore = useSelector(selectInitialiseAppCore)

  //
  //  :app-core:
  //  We need to first configure app core.
  //  We can then handle upserting any app core configuration.
  useEffect(() => {
    dispatch(configureAppCore())
  }, [dispatch])
  //
  //  Once configured, we can then handle initialising app-core.
  useEffect(() => {
    if (isConfigured) {
      dispatch(initialiseAppCoreAsync())
      //
      //  @liam: custom code for OFL
      setISOFL(
        APP_CORE.readBrowserConfig('distributor') === '3a649d53-0802-4bd6-b09c-352f530352cc' ||
          searchParams.get('distributor') === '3a649d53-0802-4bd6-b09c-352f530352cc' ||
          false
      )

      //
      //  :static-view:
      //  Handle upserting our get argument configuration for static view.

      if (APP_CORE.readBrowserConfig('staticView')) {
        dispatch(updateStaticView(APP_CORE.readBrowserConfig('staticView')))
      }
    }
  }, [dispatch, isConfigured, searchParams])

  if (!props) {
    props = {}
  }
  let { arid } = useParams()

  /*
  if (!arid) {
    arid = '8800017'
  }
  */

  talk(`${group} call to render`)
  return (
    <Page>
      {initialisedAppCore.ready && <EyekandyModelViewer arid={arid} isOFL={isOFL} mode={'view'}></EyekandyModelViewer>}
    </Page>
  )
}

export default View
