/*

  c o n t e n t
  Content

  :description:
  A component to act as the main area for our content.
  
*/

//
//  :react & redux:
import React from 'react';
import { talk } from '@eyekandy/app-core';

//
//  :code:
import './Content.css';

//
//  :packages:

//
//  :component:
const Content = (props) => {
  const group = `[CONTENT]`
  talk(`${group} call to render`)
  return (
    <div className='eky-content h-full'>
      {props.children}
    </div>
  );
}

export default Content;
