//
//  :environment:
export const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL || 'http://0.0.0.0:3338/dev'
export const ENVIRONMENT = process.env.ENVIRONMENT || 'dev'
export const DEFAULT_LANGAUGE = 'en-GB'

export const AUDIO_ENABLED_ARIDS = [
  '1000200051',
  '1000200050',
  '8000204',
  '3200100006',
  '3000200002',
  '8000254',
  '8000360',
  '8000332',
  '8000331',
  '8000330',
  '8000329',
  '8000328',
  '8000327',
  '8000326',
  '8000325',
  '8000324',
  '8000323',
  '8000322',
  '8000321',
  '8000375',
  '8000374',
  '8000373',
  '8000372',
  '8000371',
  '8000370',
  '8000369',
  '8000368',
  '8000367',
  '8000366',
  '8000365',
  '8000364',
  '8000363',
  '8000362',
  '8000361',
]
