import axios from 'axios';
import { getApiUrl } from '../endpoints';
import { getToken } from '../features/auth/authSlice';

export const jsonGetRequest = async (url, authed) => {
  const headers = {
    "Content-Type": "application/json",
  }
  if (authed) {
    headers["Authorization"] = `Bearer ${getToken()}`
  }
  const response = await axios.get(`${url}`, { headers: headers })
  return {
    status: response.status,
    data: response.data
  }
}

export const jsonGetRequestSafe = async (url) => {
  return new Promise(async (resolve) => {
    const headers = {
      "Content-Type": "application/json",
    }
    try {
      const response = await axios.get(`${url}`, { headers: headers })
      resolve({
        status: response.status,
        data: response.data
      })
    } catch (err) {
      //
      //  @Louis: Change back to 400 / data: {}
      resolve({ status: 398, data: {autoRotate: false} })
    }
  })
}

export const jsonPostRequest = async (apiPath, data) => {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${getToken()}`
  }
  const response = await axios.post(`${getApiUrl()}${apiPath}`, data, { headers: headers })
  return response.data
}
