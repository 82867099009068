/*

  c o n t a i n e r
  Container

  :description:
  An HTML component to provide a landscape to "contain" our content.
  
*/

//
//  :react & redux:
import React from 'react';
import { talk } from '@eyekandy/app-core';

//
//  :code:
import './Container.css';

//
//  :packages:

//
//  :component:
const Container = (props) => {
  const group = `[CONTAINER]`
  talk(`${group} call to render`)
  return (
    <div className='eky-container h-full'>
      {props.children}
    </div>
  );
}

export default Container;
