/*

  
  c a l i b r a t e  u i
  Calibrate UI

  :description:
  Our UI component of the "calibration" concept of our app (to set up the parameters for the environment 
  that a 3D model is shown in).

  Some "config" options are exposed via buttons and tied in through redux actions.
  
*/

//
//  :react & redux:
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//
//  :code:
import { talk } from '@eyekandy/app-core';
import {
  loadFromCloudAsync, loadFromLocalStorage, resetToDefaults, resetToDefaultsBasic, saveToCloudAsync, saveToLocalStorage,
  selectConfig, updateAutoRotate, updateBackgroundColor, updateBrightness, updateCameraOrbit, updateCanViewUnderModel, updateContrast, updateFieldOfView, updateHue, updateMinFieldOfView, updateModelExposure,
  updateRotationSpeed, updateSaturation, updateShadowIntensity, updateShadowSoftness, updateToneMapping
} from '../../../features/calibration/redux/calibrationSlice';
import {
  CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE,
  
} from '../../../features/calibration/statics';
import { useSearchParams } from 'react-router-dom';
import { InformationIcon } from '../../ui/svgs/information-icon';
import { StepByStepIcon } from '../../ui/svgs/step-by-step-icon';
import { LightAndDarkModeIcon } from '../../ui/svgs/light-and-dark-mode-icon';
import { ToggleCloseUIIcon, ToggleOpenUIIcon } from '../../ui/svgs/toggle-ui-icons';

export const CalibrateUI = (props) => {
  const group = `[EYEKANDY-CALIBRATE-UI]`

  //
  //  :react:
  const { enableBasicUI, enableAdvancedUI, calibrateUIOpen, enablePresetUI, enableLocalSaveLoadUI, enableCloudSaveLoadUI } = props;
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const isStaging = searchParams.get("environment") === "staging"

  const [uiIsVisible, setUIIsVisible] = useState(calibrateUIOpen)
  //const [configName, setConfigName] = useState("")
  const [cloudImportARID, setCloudImportARID] = useState("")
  const [backgroundColor, setBackgroundcolor] = useState("")
  const [shouldShowApproveUI, setShouldShowApproveUI] = useState(false)

  //
  //  :redux:
  const dispatch = useDispatch()
  const config = useSelector(selectConfig)

  //
  //  :events:
  //  Functions that are called when events are fired.
  const onClickToggleUIIsVisible = (event) => {
    setUIIsVisible(!uiIsVisible)
  }

  const autoRotateUpdated = (event) => { dispatch(updateAutoRotate(event.target.checked)) }
  const rotationSpeedUpdated = (event) => {
    const valueInRadians = `${event.target.value}deg`; dispatch(updateRotationSpeed(valueInRadians))
  }

  const modelExposureUpdated = (event) => { dispatch(updateModelExposure(event.target.value)) }
  const shadowIntensityUpdated = (event) => { dispatch(updateShadowIntensity(event.target.value)) }
  const shadowSoftnessUpdated = (event) => { dispatch(updateShadowSoftness(event.target.value)) }
  const canViewUnderModelUpdated = (event) => { dispatch(updateCanViewUnderModel(event.target.checked)) }
  const backgroundColourUpdated = (event) => { 
    setBackgroundcolor(event.target.value)
    dispatch(updateBackgroundColor(event.target.value))
  }
  const minFieldOfViewUpdated = (event) => {
    const valueInRadians = `${event.target.value}deg`; dispatch(updateMinFieldOfView(valueInRadians))
  }
  const fieldOfViewUpdated = (event) => {
    const valueInRadians = `${event.target.value}deg`; dispatch(updateFieldOfView(valueInRadians))
  }
  const toneMappingUpdated = (event) => { dispatch(updateToneMapping(event.target.value)) }
  const contrastUpdated = (event) => { dispatch(updateContrast(event.target.value)) }
  const brightnessUpdated = (event) => { dispatch(updateBrightness(event.target.value)) }
  const hueUpdated = (event) => { dispatch(updateHue(event.target.value)) }
  const hueSaturation = (event) => { dispatch(updateSaturation(event.target.value)) }

  const onClickPresetButtonDefaults = (event) => { dispatch(resetToDefaults()) }
  const onClickPresetButtonDefaultsBasic = (event) => { dispatch(resetToDefaultsBasic()) }
  const onClickSaveToCloud = async (event) => { await dispatch(saveToCloudAsync(isStaging)) }
  const onClickLoadFromCloud = async (event) => { await dispatch(loadFromCloudAsync({arid: cloudImportARID})) }

  const onClickSaveToLocalStorage = (event) => {
    const saveSlotId = event.target.dataset.id
    dispatch(saveToLocalStorage(saveSlotId))
  }

  const onClickLoadSaveFromLocalStorage = (event) => {
    const saveSlotId = event.target.dataset.id
    dispatch(loadFromLocalStorage(saveSlotId))
  }

  const onClickSetStartPositionToCurrentView = (event) => {
    //
    //  Read the values from model viewer.
    //  @Louis: TODO: Investigate this in more detail, we can't get values that are >360 deg.
    const mv = document.querySelector("model-viewer")
    const orbitData = mv.getCameraOrbit()
    const orbitValue = `${radToDeg(orbitData.theta - mv.turntableRotation)} ${radToDeg(orbitData.phi)} ${orbitData.radius.toFixed(3)}m`
    //
    //  Set the values as the camera-orbit value.
    dispatch(updateCameraOrbit(orbitValue))
    //dispatch(updateFieldOfView(`${Math.round(mv.getFieldOfView())}deg`))
    //dispatch(updateCameraOrbit(`91.52deg 78.93deg 0.891m`))
    //
  }

  const onClickZoomAuto = (event) => {
    dispatch(updateFieldOfView(`auto`))
  }

  const radToDeg = (value) => {
    return `${(value * (180 / Math.PI)).toFixed(2)}deg`
  }

  const approveButtonClickedHandler = () => {
    return setShouldShowApproveUI(!shouldShowApproveUI)
  }

  const saveCalibrationClickedHandler = () => {
    //
    // for now just redirect the user to success page
    window.location.replace("https://cdn.pointandplace.com/htmls/placeholders/success/index.html");
  }

  //
  //  :rendering:
  //  Functions to render our UI elements.
  const renderUIWidthClassNames = () => {
    return uiIsVisible ? `w-96` : "w-4"
  }

  const renderUIContainerClassnames = () => {
    return uiIsVisible ? `` : "hidden"
  }

  const renderApproveAndResetButtons = () =>{
    return <>
      <div className='flex justify-evenly'>
        <button className='bg-[#50dc5a] w-[6rem] h-[2rem] rounded' onClick={approveButtonClickedHandler}>Approve</button>
        <button className='bg-[#e31635] w-[6rem] h-[2rem] rounded'>Reset</button>
      </div>
    </>
  }

  const renderBasicCalibrationUI = () => {
    return <>
      <div className='overflow-hidden bg-[#433b81a6] rounded mt-1'>
        <label>
          <input className='absolute opacity-0 peer' type="checkbox" />
          <p className='p-5 inline-block w-11/12 cursor-pointer text-xl text-[#0CB7EB] font-bold'>Basic Calibration</p>
          <div className='max-h-0 peer-checked:max-h-screen transition-all'>{renderBasicUI()}</div>
        </label>
      </div>

      <hr className='my-2' />
    </>
  }

  const renderPresetsCalibrationUI = () => {
    return <>
      <div className='overflow-hidden bg-[#433b81a6] rounded mt-1'>
        <label>
          <input className='absolute opacity-0 peer' type="checkbox" />
          <p className='p-5 inline-block w-11/12 cursor-pointer text-xl text-[#0CB7EB] font-bold'>Presets</p>
          <div className='max-h-0 peer-checked:max-h-screen transition-all'>{renderPresetsUI()}</div>
        </label>
      </div>

      <hr className='my-2' />
    </>
  }

  const renderBasicUI = () => {
    return <>
      <div className='ui-group'>
        <div className='ui-group-content px-2 flex flex-col gap-4'>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Exposure</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.modelExposure]}
              onChange={modelExposureUpdated}
              type="range" id="vol" name="vol" min="0.25" max="7" step="0.05"
              className='accent-[#0CB7EB]'
            />
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Shadow Intensity</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.shadowIntensity]}
              onChange={shadowIntensityUpdated}
              type="range" id="vol" name="vol" min="0" max="2" step="0.05"
              className='accent-[#0CB7EB]'
            />
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Shadow Softness</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.shadowSoftness]}
              onChange={shadowSoftnessUpdated}
              type="range" id="vol" name="vol" min="0" max="2" step="0.05"
              className='accent-[#0CB7EB]'
            />
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Contrast</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.contrast]}
              onChange={contrastUpdated}
              type="range" id="vol" name="vol" min="0" max="1" step="0.01"
              className='accent-[#0CB7EB]'
            />
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Brightness</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.brightness]}
              onChange={brightnessUpdated}
              type="range" id="vol" name="vol" min="0" max="1" step="0.01"
              className='accent-[#0CB7EB]'
            />
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Hue</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.hue]}
              onChange={hueUpdated}
              type="range" id="vol" name="vol" min="0" max="1" step="0.01"
              className='accent-[#0CB7EB]'
            />
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Saturation</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.saturation]}
              onChange={hueSaturation}
              type="range" id="vol" name="vol" min="0" max="1" step="0.01"
              className='accent-[#0CB7EB]'
            />
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Tone Mapping</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <select onChange={toneMappingUpdated}>
              <option value="ACES_FILMIC">ACES_FILMIC</option>
              <option value="REINHARD">REINHARD</option>
              <option value="REINHARD2">REINHARD2</option>
              <option value="REINHARD2_ADAPTIVE">REINHARD2_ADAPTIVE</option>
              <option value="OPTIMIZED_CINEON">OPTIMIZED_CINEON</option>
            </select>
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Auto Rotate</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <label className="inline-flex relative items-center cursor-pointer mt-3">
              <input className="sr-only peer" type="checkbox" value="" onChange={autoRotateUpdated} checked={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.autoRotate] || false}></input>
              <div className="w-11 h-6 bg-[#D0D0D0] peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#0CB7EB]"></div>
            </label>
          </div>
          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Can View Underneath the Model</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <label className="inline-flex relative items-center cursor-pointer mt-3 mb-3">
              <input className="sr-only peer" type="checkbox" value="" onChange={canViewUnderModelUpdated} checked={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.canViewUnderModel] || false}></input>
              <div className="w-11 h-6 bg-[#D0D0D0] peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#0CB7EB]"></div>
            </label>
          </div>
        </div>
      </div>
    </>
  }

  const renderAdvancedUI = () => {
    return <>
      <div className='ui-group'>
        <div className='ui-group-content px-2 flex flex-col gap-4'>
          <div className='config-attribute-ui flex flex-col'>

            <div className='flex justify-between'>
              <label>Rotation Speed</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.rotationSpeed].replace(/deg/ig, "")}
              onChange={rotationSpeedUpdated}
              type="range" id="vol" name="vol" min="-60" max="60" step="1"
              className='accent-[#0CB7EB]'
            />
          </div>

          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Zoom</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <div className='w-full flex justify-center mb-1'>
              <button className='rounded w-[5rem] h-[1.5rem] bg-[#0CB7EB] text-[#363A48]' onClick={onClickZoomAuto}>Auto</button>
            </div>

            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.fieldOfView].replace(/deg/ig, "")}
              onChange={fieldOfViewUpdated}
              type="range" id="vol" name="vol" min="0" max="45" step="1"
              className='accent-[#0CB7EB]'
            />
          </div>

          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Set Camera Distance from Model</label>
              {<InformationIcon></InformationIcon>}
            </div>

            <input
              value={config[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE.minFieldOfView].replace(/deg/ig, "")}
              onChange={minFieldOfViewUpdated}
              type="range" id="vol" name="vol" min="0" max="120" step="1"
              className='accent-[#0CB7EB]'
            />
          </div>

          <div className='config-attribute-ui flex flex-col'>
            <div className='flex justify-between'>
              <label>Set Start Position to Current View</label>
              {<InformationIcon></InformationIcon>}
            </div>
            <div className='flex justify-center'>
              <button className='rounded w-[9rem] h-[1.5rem] bg-[#0CB7EB] text-[#363A48]' onClick={onClickSetStartPositionToCurrentView}>Set Position</button>
            </div>
          </div>

          <div className='config-attribute-ui flex flex-col mb-2'>
            <div className='flex justify-between'>
              <h2>Background Colour</h2>
              {<InformationIcon></InformationIcon>}
            </div>
            <input type="text" value={backgroundColor} onChange={backgroundColourUpdated} />
          </div>
        </div>
      </div>
    </>
  }

  const renderPresetsUI = () => {
    return <>
      <div className='ui-group'>
        <div className='ui-group-content px-2 grid grid-cols-3'>
          <button className='m-2 rounded w-[5rem] h-[2rem] bg-[#0CB7EB] text-[#363A48]' onClick={onClickPresetButtonDefaults}>Default</button>
          <button className='m-2 rounded w-[5rem] h-[2rem] bg-[#0CB7EB] text-[#363A48]' onClick={onClickPresetButtonDefaultsBasic}>Standard</button>
        </div>
      </div>
    </>
  }

  const renderLocalLoadSaveUI = () => {
    const SAVE_SLOTS = [
      { id: "001", text: "A" },
      { id: "002", text: "B" },
      { id: "003", text: "C" },
      { id: "004", text: "D" },
      { id: "005", text: "E" },
    ]
    let saveSlotsHtmls = []
    for (let i = 0; i < SAVE_SLOTS.length; i++) {
      saveSlotsHtmls.push(
        <button type='button'
          key={`save-${i}`}
          data-id={SAVE_SLOTS[i].id}
          onClick={onClickSaveToLocalStorage}>
          Save
        </button>
      )
    }
    let loadSlotsHtmls = []
    for (let i = 0; i < SAVE_SLOTS.length; i++) {
      loadSlotsHtmls.push(
        <button type='button'
          key={`load-${i}`}
          data-id={SAVE_SLOTS[i].id}
          onClick={onClickLoadSaveFromLocalStorage}>
          Load
        </button>
      )
    }
    const saveAndLoadSlotsHtml = []
    for (let i = 0; i < SAVE_SLOTS.length; i++) {
      saveAndLoadSlotsHtml.push(
        <div key={`save-load-${i}`} className="flex ">
          <h1>{SAVE_SLOTS[i].text}</h1>
          <div className='flex justify-around flex-1'>
            {saveSlotsHtmls[i]}
            {loadSlotsHtmls[i]}
          </div>
        </div>
      )
    }
    return <>
      <div className='ui-group'>
        <div className='ui-group-content px-2 flex flex-col gap-2'>
          {saveAndLoadSlotsHtml}
        </div>
      </div>
    </>
  }

  const renderCloudLoadSaveUI = () => {
    return <>
      <div className='ui-group'>
        <div className='ui-group-content px-2 flex flex-col gap-2'>
          <div className='w-full flex justify-center mb-1'>
            <button className='rounded w-[5rem] h-[1.5rem] bg-[#0CB7EB] text-[#363A48]' onClick={onClickSaveToCloud} type='button'>Save</button>
          </div>

          <h2>Import calibration from existing ARID:</h2>
          <input type="text" value={cloudImportARID} onChange={(event) => { setCloudImportARID(event.target.value) }} />
          <div className='w-full flex justify-center mb-1'>
            <button className='rounded w-[5rem] h-[1.5rem] bg-[#0CB7EB] text-[#363A48]' onClick={onClickLoadFromCloud} type='button'>Load</button>
          </div>

        </div>
      </div>
    </>
  }

  const renderCalibrationLocalLoadSaveUI = () => {
    return <>
      <div className='overflow-hidden bg-[#433b81a6] rounded mt-1'>
        <label>
          <input className='absolute opacity-0 peer' type="checkbox" />
          <p className='p-5 inline-block w-11/12 cursor-pointer text-xl text-[#0CB7EB] font-bold'>Save and Load Locally</p>
          <div className='max-h-0 peer-checked:max-h-screen transition-all'>{renderLocalLoadSaveUI()}</div>
        </label>
      </div>

      <hr className='my-2' />
    </>
  }

  const renderCalibrationCloudLoadSaveUI = () => {
    return <>
      <div className='overflow-hidden bg-[#433b81a6] rounded mt-1'>
        <label>
          <input className='absolute opacity-0 peer' type="checkbox" />
          <p className='p-5 inline-block w-11/12 cursor-pointer text-xl text-[#0CB7EB] font-bold'>Save and Load</p>
          <div className='max-h-0 peer-checked:max-h-screen transition-all'>{renderCloudLoadSaveUI()}</div>
        </label>
      </div>

      <hr className='my-2' />
    </>
  }

  const renderAdvancedCalibrationUI = () => {
    return <>
      <div className='overflow-hidden bg-[#433b81a6] rounded mt-1'>
        <label>
          <input className='absolute opacity-0 peer' type="checkbox" />
          <p className='p-5 inline-block w-11/12 cursor-pointer text-xl text-[#0CB7EB] font-bold'>Advanced Calibration</p>
          <div className='max-h-0 peer-checked:max-h-screen transition-all'>{renderAdvancedUI()}</div>
        </label>
      </div>

      <hr className='my-2' />
    </>
  }

  const approveConfirmationUI = () => {
    const renderApproveAndCancelButtons = () =>{
      return <>
        <div className='flex justify-evenly mt-4'>
          <button className='bg-[#50dc5a] w-[6rem] h-[2rem] rounded' onClick={saveCalibrationClickedHandler}>Approve</button>
          <button className='bg-[#e31635] w-[6rem] h-[2rem] rounded' onClick={approveButtonClickedHandler}>Cancel</button>
        </div>
      </>
    }
  
    return <>
      <div className='m-auto fixed top-[50%] bottom-[50%] right-[50%] left-[50%] w-[25rem] h-[13rem] bg-[#6168b0] rounded border border-[#0CB7EB]'>
        <div id='content' className='p-2'>
          <div className='flex justify-center mt-4'>
            <h1>Are you sure?</h1>
          </div>
          <div className='flex justify-center text-center mt-4'>
            <h1>The calibration will be used everytime this model is viewed</h1>
          </div>
  
          {renderApproveAndCancelButtons()}
        </div>
      </div>
    </>
  }

  talk([`${group} call to render`])
  return <>
    <div className={`calibrate-ui relative flex flex-row-reverse gap-1 h-full ${renderUIWidthClassNames()}`}>
      <span className='right-0 cursor-pointer top-[0.8%] absolute' onClick={onClickToggleUIIsVisible}>{uiIsVisible ? <ToggleCloseUIIcon></ToggleCloseUIIcon> : <ToggleOpenUIIcon></ToggleOpenUIIcon>}</span>
      <div className={`flex flex-col bg-[#6168b0] rounded-r-xl h-full w-[20rem] border border-[#0CB7EB] ${renderUIContainerClassnames()} `}>
        <div className='flex flex-col h-full w-full p-2 gap-2'>
          <div className='flex justify-between'>
            <h1 className='text-2xl text-[#0CB7EB] font-bold'>Calibration</h1>
            <div className='flex'>
              <div className='flex mr-5'>
                {<StepByStepIcon></StepByStepIcon>}
                {<LightAndDarkModeIcon></LightAndDarkModeIcon>}
              </div>
            </div>
          </div>
          <p className='text-center text-[#D0D0D0]'>Use the settings below to control how this model is rendered.</p>
          <p className='text-center text-[#D0D0D0]'>After saving the calibration settings, they will be used every time the model is loaded.</p>
          <hr className='my-2' />
          <div className='overflow-y-auto h-full w-full scrollbar'>
            {/* presets accordion  */}
            {enablePresetUI && renderPresetsCalibrationUI()}

            {/* basic calibration accordion  */}
            {enableBasicUI && renderBasicCalibrationUI()}

            {/* advanced calibration accordion  */}
            {enableAdvancedUI && renderAdvancedCalibrationUI()}

            {/* local save/load accordion  */}
            {enableLocalSaveLoadUI && renderCalibrationLocalLoadSaveUI()}

            {/* cloud save/load accordion  */}
            {enableCloudSaveLoadUI && renderCalibrationCloudLoadSaveUI()}

            {/* Approve and Reset buttons  */}
            {renderApproveAndResetButtons()}
          </div>
        </div>
      </div>
      {shouldShowApproveUI && approveConfirmationUI()}
    </div>
  </>
}
