/*

  a p p r o v e
  Approve

  :description:
  Our main component to handle "Approve" action in the app.
  
*/

//
//  :react & redux:
import React from 'react';
import { useParams } from 'react-router';

//
//  :code:
import './Approve.css';
import Page from '../../components/layout/page/Page';
import EyekandyModelViewer from '../../components/models/eky-model-viewer/EyekandyModelViewer';
import { talk } from '@eyekandy/app-core';
import { RedirectIfNotLoggedIn } from '../../components/auth/RedirectIfNotLoggedIn';

//
//  :component:
const Approve = (props) => {
  const group = `[ROUTE-APPROVE]`

  if (!props) {
    props = {}
  }
  let { arid } = useParams();
  if (!arid) {
    arid = "8800017"
  }
  
  talk(`${group} call to render`)
  return (
    <Page>
      <RedirectIfNotLoggedIn></RedirectIfNotLoggedIn>
      <EyekandyModelViewer arid={arid} mode={"approve"}></EyekandyModelViewer>
    </Page>
  );
}

export default Approve;
