import React from 'react'

export const FullscreenExitIcon = props => {
  const fill = props.fill || '0CB7EB'

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' fill='none'>
      <circle cx='30' cy='30' r='30' fill='#fff' />
      <path
        fill={`#${fill}`}
        d='M24.3 44v-8.3H16v-1.55h9.85V44H24.3Zm9.85 0v-9.85H44v1.55h-8.3V44h-1.55ZM16 25.85V24.3h8.3V16h1.55v9.85H16Zm18.15 0V16h1.55v8.3H44v1.55h-9.85Z'
      />
    </svg>
  )
}
