import React from 'react'

export const CloseIcon = props => {
  const fill = props.fill || '0CB7EB'

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' fill='none'>
      <circle cx='30' cy='30' r='30' fill='#fff' />
      <path
        fill={`#${fill}`}
        d='M19.1 42.9 18 41.8l11.35-11.35L18 19.1l1.1-1.1 11.35 11.35L41.8 18l1.1 1.1-11.35 11.35L42.9 41.8l-1.1 1.1-11.35-11.35L19.1 42.9Z'
      />
    </svg>
  )
}
