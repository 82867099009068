/*

  c a l i b r a t i o n  s l i c e
  Calibration Slice

  :description:
  Our redux reducer for the calibration feature.

*/

//
//  :react & redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//
//  :code:
import { talk } from '@eyekandy/app-core'
import { CONFIG, LEGACY_CONFIG } from './memory'

//
//  :state:
//  Our main redux state for this feature.
const initialState = {
  //
  //  :config:
  //  Our live serialised configuration.
  config: CONFIG.serialiseForReduxState(),
  //
  //  :runtime:
  //  State variables that we are using at runtime.
  isLoading: true,
  isFullscreen: false,
  saveToCloud: {
    requesting: false,
    error: null,
    response: null,
    status: null,
  },
  loadFromCloud: {
    requesting: false,
    error: null,
    response: null,
    status: null,
  },
}

export const saveToCloudAsync = createAsyncThunk('calibration/saveToCloud', async isStaging => {
  await CONFIG.saveToCloud(isStaging)
  return true
})

export const loadFromCloudAsync = createAsyncThunk('calibration/loadFromCloud', async args => {
  //
  //  :step 1:
  //  Try to load the current generation config from the cloud.
  const hasConfig = await CONFIG.loadFromCloud(args.arid, args.isStaging)
  //
  //  :step 2:
  //  If we were unable to read a config for this model, try to load a legacy config (may not exist).
  if (!hasConfig) {
    //
    //  Make the request to download the legacy calibration settings.
    const legacyConfigResponse = await LEGACY_CONFIG.loadFromLegacyCloud(args.arid)
    if (!legacyConfigResponse) {
      return null
    }
    //
    //  Serialise the values from the old config into an object.
    const legacyConfig_ = LEGACY_CONFIG.serialise()
    //
    //  Upsert all of the configuration settings to our actual CONFIG object.
    for (const [k, v] of Object.entries(legacyConfig_)) {
      CONFIG[k] = v
    }
  }
  //
  //  All done, return true for now.
  return true
})

export const calibrationSlice = createSlice({
  name: 'calibration',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setARID: (state, action) => {
      CONFIG.setARID(action.payload)
    },
    toggleFullscreen: state => {
      talk(`[CALIBRATION-REDUCER] toggleFullscreen() toggling fullscreen`)
      state.isFullscreen = !state.isFullscreen
    },
    enableFullscreen: state => {
      talk(`[CALIBRATION-REDUCER] enableFullscreen() enabling fullscreen`)
      state.isFullscreen = true
    },
    disableFullscreen: state => {
      talk(`[CALIBRATION-REDUCER] disableFullscreen() disabling fullscreen`)
      state.isFullscreen = false
    },
    toggleLoading: state => {
      talk(`[CALIBRATION-REDUCER] toggleLoading() toggling loading`)
      state.isLoading = !state.isLoading
    },
    setIsLoading: state => {
      talk(`[CALIBRATION-REDUCER] setIsLoading() enabling loading`)
      state.isLoading = true
    },
    setIsNotLoading: state => {
      talk(`[CALIBRATION-REDUCER] setIsNotLoading() loading complete`)
      state.isLoading = false
    },
    updateShadowIntensity: (state, action) => {
      if (state.config) {
        CONFIG.updateShadowIntensity(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateShadowSoftness: (state, action) => {
      if (state.config) {
        CONFIG.updateShadowSoftness(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateModelExposure: (state, action) => {
      if (state.config) {
        CONFIG.updateModelExposure(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateModelMetalness: (state, action) => {
      if (state.config) {
        CONFIG.updateModelMetalness(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateModelRoughness: (state, action) => {
      if (state.config) {
        CONFIG.updateModelRoughness(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateOrbitSensitivity: (state, action) => {
      if (state.config) {
        CONFIG.updateOrbitSensitivity(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateScaleValue: (state, action) => {
      if (state.config) {
        CONFIG.updateScaleValue(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateEnvironmentSkybox: (state, action) => {
      if (state.config) {
        CONFIG.updateEnvironmentSkybox(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateAutoRotate: (state, action) => {
      if (state.config) {
        CONFIG.updateAutoRotate(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },

    updateCameraControls: (state, action) => {
      if (state.config) {
        CONFIG.updateCameraControls(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateRotationSpeed: (state, action) => {
      if (state.config) {
        CONFIG.updateRotationSpeed(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateEnvironmentImage: (state, action) => {
      if (state.config) {
        CONFIG.updateEnvironmentImage(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateCanViewUnderModel: (state, action) => {
      if (state.config) {
        CONFIG.updateCanViewUnderModel(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    saveToLocalStorage: (state, action) => {
      if (state.config) {
        CONFIG.saveToLocalStorage(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    loadFromLocalStorage: (state, action) => {
      if (state.config) {
        CONFIG.loadFromLocalStorage(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    resetToDefaults: (state, action) => {
      if (state.config) {
        CONFIG.resetToDefaults(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    resetToDefaultsBasic: (state, action) => {
      if (state.config) {
        CONFIG.resetToDefaultsBasic(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateFieldOfView: (state, action) => {
      if (state.config) {
        CONFIG.updateFieldOfView(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateMinFieldOfView: (state, action) => {
      if (state.config) {
        CONFIG.updateMinFieldOfView(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateCameraOrbit: (state, action) => {
      if (state.config) {
        CONFIG.updateCameraOrbit(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateBackgroundColor: (state, action) => {
      if (state.config) {
        CONFIG.updateBackgroundColor(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateDisableZoom: (state, action) => {
      if (state.config) {
        CONFIG.updateDisableZoom(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateTouchAction: (state, action) => {
      if (state.config) {
        CONFIG.updateTouchAction(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateDisablePan: (state, action) => {
      if (state.config) {
        CONFIG.updateDisablePan(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateDisableTap: (state, action) => {
      if (state.config) {
        CONFIG.updateDisableTap(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateStaticView: (state, action) => {
      if (state.config) {
        CONFIG.updateStaticView(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateIsVisible: (state, action) => {
      if (state.config) {
        CONFIG.updateIsVisible(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateInteractionPrompt: (state, action) => {
      if (state.config) {
        CONFIG.updateInteractionPrompt(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateAlwaysRotate: (state, action) => {
      if (state.config) {
        CONFIG.updateAlwaysRotate(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateToneMapping: (state, action) => {
      if (state.config) {
        CONFIG.updateToneMapping(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateContrast: (state, action) => {
      if (state.config) {
        CONFIG.updateContrast(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateBrightness: (state, action) => {
      if (state.config) {
        CONFIG.updateBrightness(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateHue: (state, action) => {
      if (state.config) {
        CONFIG.updateHue(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
    updateSaturation: (state, action) => {
      if (state.config) {
        CONFIG.updateSaturation(action.payload)
        state.config = CONFIG.serialiseForReduxState()
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: builder => {
    builder
      //
      //  :save-to-cloud:
      .addCase(saveToCloudAsync.pending, state => {
        state.saveToCloud.error = null
        state.saveToCloud.requesting = true
        state.saveToCloud.response = null
        state.saveToCloud.status = 'requesting'
      })
      .addCase(saveToCloudAsync.fulfilled, (state, action) => {
        state.saveToCloud.error = null
        state.saveToCloud.requesting = false
        state.saveToCloud.response = action.payload
        state.saveToCloud.status = 'idle'
      })
      //
      //  :load-from-cloud:
      .addCase(loadFromCloudAsync.pending, state => {
        state.loadFromCloud.error = null
        state.loadFromCloud.requesting = true
        state.loadFromCloud.response = null
        state.loadFromCloud.status = 'requesting'
      })
      .addCase(loadFromCloudAsync.fulfilled, (state, action) => {
        state.loadFromCloud.error = null
        state.loadFromCloud.requesting = false
        state.loadFromCloud.status = 'idle'
        //
        //  We have already updated our value internally, but set it in the state too.
        state.loadFromCloud.response = action.payload
        //
        //  Now serialise into our state.
        state.config = CONFIG.serialiseForReduxState()
      })
      .addCase(loadFromCloudAsync.rejected, (state, action) => {
        talk([`[CALIBRATION-REDUCER] loadFromCloudAsync() warning, there was an error when loading from cloud`, action])
        state.loadFromCloud.error = true
        state.loadFromCloud.requesting = false
        state.loadFromCloud.status = 'errored'
        //
        //  We have already updated our value internally, but set it in the state too.
        state.loadFromCloud.response = action.payload
        //
        //  Now serialise into our state.
        state.config = CONFIG.serialiseForReduxState()
      })
  },
})

//
//  :exports:
//  The named exports for this feature.
export const {
  updateShadowIntensity,
  updateShadowSoftness,
  updateModelExposure,
  updateModelMetalness,
  updateModelRoughness,
  updateOrbitSensitivity,
  updateScaleValue,
  updateEnvironmentSkybox,
  updateAutoRotate,
  updateCameraControls,
  updateRotationSpeed,
  updateEnvironmentImage,
  updateCanViewUnderModel,
  updateInteractionPrompt,
  updateAlwaysRotate,
  saveToLocalStorage,
  loadFromLocalStorage,
  resetToDefaults,
  setARID,
  resetToDefaultsBasic,
  updateFieldOfView,
  updateMinFieldOfView,
  updateCameraOrbit,
  updateBackgroundColor,
  updateDisableZoom,
  updateDisablePan,
  updateDisableTap,
  updateStaticView,
  updateTouchAction,
  updateToneMapping,
  updateContrast,
  updateBrightness,
  updateHue,
  updateSaturation,
  //
  //  :runtime:
  toggleFullscreen,
  toggleLoading,
  enableFullscreen,
  disableFullscreen,
  setIsNotLoading,
  setIsLoading,
} = calibrationSlice.actions

export const selectConfig = state => state.calibration.config
export const selectIsFullscreen = state => state.calibration.isFullscreen
export const selectIsLoading = state => state.calibration.isLoading
export const selectLoadFromCloud = state => state.calibration.loadFromCloud

//
//  :reducer:
export default calibrationSlice.reducer
