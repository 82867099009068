/*

  p a g e
  Page

  :description:
  An HTML component to provide a JS parallel to a standard "web page".
  
*/

//
//  :react & redux:
import React from 'react';

//
//  :code:
import './Page.css';
import Content from '../content/Content';
import Container from '../container/Container';
import { talk } from '@eyekandy/app-core';

//
//  :packages:

//
//  :component:
const Page = (props) => {
  const group = `[PAGE]`
  if (!props) {
    props = {}
  }
  talk(`${group} call to render`)
  return (
    <div id="eky-page" className='eky-page h-screen'>
      <Container>
        <Content>
          {props.children}
        </Content>
      </Container>
    </div>
  );
}

export default Page;
