/*

  c a l i b r a t i o n  c o n f i g  v 2
  Calibration Config v2

  :description:
  A file to hold onto our calibration configuration settings, these can be used to edit & view the current 3d model
  that is being displayed by the app.

*/

//
//  :code:
import { talk } from '@eyekandy/app-core'
import { isDefined } from '../../../js/utils'
import {
  CALIBRATION_DEFAULTS_BASIC,
  CALIBRATION_DEFAULTS_PRIMARY,
  CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE,
  DEFAULT_ACTIVE_CONFIG_ATTRIBUTES,
  KNOWN_CONFIG_ATTRIBUTES,
  KNOWN_CONFIG_ATTRIBUTES_ARRAY,
  MODEL_VIEWER_ATTRIBUTE_TO_CONFIG_ATTRIBUTE,
} from '../statics'
import { jsonGetRequest, jsonPostRequest } from '../../../js/requests'

const _localStorage = {
  getItem: key => {
    try {
      return localStorage.getItem(key)
    } catch (err) {}
    return null
  },
  removeItem: key => {
    try {
      localStorage.removeItem(key)
    } catch (err) {}
    return null
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value)
    } catch (err) {}
    return null
  },
}

export class CalibrationConfig {
  //
  //  :core:
  arid = null

  //
  //  :config-options:
  //  Declared here as we are dynamically setting things in the constructor.
  //  Note, only here to help with type/structure inspection when coding.
  shadowIntensity = null
  shadowSoftness = null
  autoRotate = null
  cameraControls = null
  modelExposure = null
  environmentSkybox = null
  orbitSensitivity = null
  modelMetalness = null
  modelRoughness = null
  scale = null
  modelPlacement = null
  cameraTarget = null
  interactionPrompt = null
  interactionPromptMode = null
  rotationSpeed = null
  rotationDelay = null
  environmentImage = null
  canViewUnderModel = null
  maxCameraOrbit = null
  autoPlay = null
  loadMode = null
  altText = null
  fieldOfView = null
  minFieldOfView = null
  cameraOrbit = null
  disableZoom = null
  touchAction = null
  disablePan = null
  disableTap = null
  staticView = null
  isVisible = null
  toneMapping = null
  brightness = null

  constructor(options) {
    //
    //  :pre:
    //  Options must be an object.
    this.version = '2.0.0'
    this.group = '[CALIBRATION-CONFIG]'
    if (!options || typeof options !== 'object') {
      options = {}
    }

    //
    //  :core:
    //  Some of our options are read into a "core" section as they can cause changes in behaviour within this class.
    if (options.arid) {
      this.arid = options.arid
    }

    //
    //  :config-options:
    //  By default we can accept values through options, if not supplied a default will be used.
    //  Handle setting all of our properties dynamically.
    KNOWN_CONFIG_ATTRIBUTES_ARRAY.map(attribute => {
      //
      //  If we have this value in options, set it from there.
      if (isDefined(options[attribute])) {
        //
        //  Set the value given to us by the caller to our attribute.
        this[attribute] = options[attribute]
        //
        //  Return true as the caller set this config option themselves.
        return true
      }
      //
      //  We need to set a default.
      this[attribute] = CALIBRATION_DEFAULTS_PRIMARY[attribute]
      //
      //  Return false as we used the default.
      return false
    })

    this.requesting = false

    //
    //  :echo:
    //  After creating our instance, echo it into the console.
    talk([`${this.group} instance created:`, this.serialise()])
  }

  //
  //  :getters:
  //  Callers can get the internal values of this class using these functions.
  getConfigValue(attribute) {
    return this[attribute]
  }

  getModelViewerConfigValue(modelViewerAttribute) {
    const attribute = MODEL_VIEWER_ATTRIBUTE_TO_CONFIG_ATTRIBUTE[modelViewerAttribute]
    if (!attribute) {
      throw new Error(`error unmapped model-viewer attribute name '${modelViewerAttribute}'`)
    }
    return this.getConfigValue(attribute)
  }

  //
  //  :setters:
  //  Callers can set the internal values of this class using these functions.
  updateConfigAttribute(attribute, value) {
    talk(`${this.group} attempting to update the attribute '${attribute}' to '${value}'`)

    //
    //  :step 1:
    //  Verify that the given attribute is known to us.
    if (!KNOWN_CONFIG_ATTRIBUTES_ARRAY.includes(attribute)) {
      throw new Error(`${this.group} error, the attribute '${attribute}' is not valid`)
    }

    //
    //  :step 2:
    //  Set the attribute to the value given to us.
    let wasUpdated = false
    if (this[attribute] !== value) {
      this[attribute] = value
      wasUpdated = true
    }

    //
    //  :step 3:
    //  Echo that we changed a value.
    if (wasUpdated) {
      talk([`${this.group} updated the attribute '${attribute}' to '${value}'`])
    }
  }

  resetToDefaults(value) {
    KNOWN_CONFIG_ATTRIBUTES_ARRAY.map(attribute => {
      this[attribute] = CALIBRATION_DEFAULTS_PRIMARY[attribute]
      return true
    })
  }

  resetToDefaultsBasic(value) {
    KNOWN_CONFIG_ATTRIBUTES_ARRAY.map(attribute => {
      this[attribute] = CALIBRATION_DEFAULTS_BASIC[attribute]
      return true
    })
  }

  setARID(value) {
    this.arid = value
  }
  updateAutoRotate(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.autoRotate, value)
  }
  updateShadowIntensity(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.shadowIntensity, value)
  }
  updateShadowSoftness(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.shadowSoftness, value)
  }
  updateCameraControls(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.cameraControls, value)
  }
  updateScaleValue(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.scale, value)
  }
  updateModelPlacement(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.modelPlacement, value)
  }
  updateModelExposure(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.modelExposure, value)
  }
  updateEnvironmentSkybox(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.environmentSkybox, value)
  }
  updateModelMetalness(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.modelMetalness, value)
  }
  updateModelRoughness(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.modelRoughness, value)
  }
  updateOrbitSensitivity(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.orbitSensitivity, value)
  }
  updateCameraTarget(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.cameraTarget, value)
  }
  updateAutoPlay(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.autoPlay, value)
  }
  updateRotationSpeed(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.rotationSpeed, value)
  }
  updateEnvironmentImage(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.environmentImage, value)
  }
  updateFieldOfView(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.fieldOfView, value)
  }
  updateMinFieldOfView(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.minFieldOfView, value)
  }
  updateCameraOrbit(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.cameraOrbit, value)
  }
  updateBackgroundColor(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.backgroundColor, value)
  }
  updateCanViewUnderModel(value) {
    //
    //  Update our own flag.
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.canViewUnderModel, value)
    //
    //  Now apply values to the other config attributes.
    if (!value) {
      this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.maxCameraOrbit, 'auto 90deg auto')
    } else {
      this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.maxCameraOrbit, 'auto auto auto')
    }
  }
  updateDisableZoom(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.disableZoom, value)
  }
  updateTouchAction(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.touchAction, value)
  }
  updateDisablePan(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.disablePan, value)
  }
  updateDisableTap(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.disableTap, value)
  }
  updateStaticView(value) {
    //
    //  Update our own flag.
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.staticView, value)
    /*
    //
    //  @Louis: This doesn't seem to actually work, likely model-viewer handles these during its onConnectedCallback to 
    //          add the handler bindings. Moving to disabling the camera completely.
    //
    //  Now apply values to the other config attributes.
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.disableZoom, value)
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.disableTap, value)
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.disablePan, value)
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.touchAction, value ? 'none' : 'pan-y')
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.rotationDelay, value ? 5000 : 1500)
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.isVisible, !value)
    // TODO: Need to restore this value
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.autoRotate, !value)
    */
    //
    //  @Louis: v2, attempt to disable everything about the camera.
    if (!this.getConfigValue('alwaysRotate') && !value) {
      this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.rotationDelay, value ? 5000 : 1500)
    }
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.isVisible, !value)
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.cameraControls, !value)
    //
    // TODO: Need to restore this value
    if (!this.getConfigValue('alwaysRotate') && !value) {
      this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.autoRotate, !value)
    }
  }
  updateIsVisible(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.isVisible, value)
  }
  updateInteractionPrompt(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.interactionPrompt, value)
  }
  updateAlwaysRotate(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.alwaysRotate, value)
  }
  updateToneMapping(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.toneMapping, value)
  }
  updateContrast(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.contrast, value)
  }
  updateBrightness(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.brightness, value)
  }
  updateHue(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.hue, value)
  }
  updateSaturation(value) {
    this.updateConfigAttribute(KNOWN_CONFIG_ATTRIBUTES.saturation, value)
  }
  //
  //  :load-from-cloud:
  //  We can choose to load our configuration in from a JSON URL.
  async loadFromCloud(arid, isStaging) {
    const aridToLoadFrom = arid || this.arid
    talk(`${this.group} loading our config from the cloud for ARID: '${aridToLoadFrom}'`)
    const urls = {
      dev: `https://3d-player-dev.pointandplace.com/configs/${aridToLoadFrom}.json`,
      prod: `https://3d-player.pointandplace.com/configs/${aridToLoadFrom}.json`,
    }
    //
    //  @Louis: TODO: Fix this.
    let environment = 'dev'
    if (window.location.href.includes('3d-player.pointandplace.com')) {
      environment = 'prod'
    }
    if (window.location.href.includes('3d-player-dev.pointandplace.com')) {
      environment = 'dev'
    }
    if (window.location.href.includes('localhost') || window.location.href.includes('0.0.0.0')) {
      environment = 'dev'
    }
    let url = urls[environment]
    if (isStaging) {
      url = url.replace(/configs/gi, `configs/qa`)
    }
    if (this.requesting) {
      return false
    }
    this.requesting = true
    const response = await jsonGetRequest(url)
    this.requesting = false
    //
    //  Verify that was successful before applying.
    /*
    if (response.status > 399) { 
      talk(`${this.group} there is no current generation configuration for our model: '${aridToLoadFrom}'`)
      return null 
    }
    */
    if (typeof response.data === 'string') {
      talk(`${this.group} there is no current generation configuration for our model: '${aridToLoadFrom}'`)
      return null
    }
    const data = response.data
    //
    //  Ok, at this point we just need to upsert these values onto ourself.
    const keysToSet = Array.from(Object.keys(data))
    let keysSet = 0
    keysToSet.map(key => {
      talk(`${this.group} read and set a value from the cloud: '${key}' = '${data[key]}'`)
      this[key] = data[key]
      keysSet += 1
      return true
    })
    talk(`${this.group} ok, loaded and set the cloud configuration for our model: '${aridToLoadFrom}'`)
    return keysSet !== 0
  }

  //
  //  :save-to-cloud:
  //  We can save our current configuration to the cloud via HTTPS PUT.
  async saveToCloud(isStaging) {
    talk(`${this.group} saving our config to the cloud for ARID: '${this.arid}'`)
    //
    //  Make the request via fetch().
    let url = `/configs/${this.arid}`
    if (isStaging) {
      url += `?environment=staging`
    }
    const response = await jsonPostRequest(url, this.serialiseToJSON(true))
    talk(`${this.group} ok, saved the current config to the cloud for ARID: '${this.arid}'`)
    return response
  }

  //
  //  :local-storage:
  //  We can load/save from local storage at given key locations.
  getLocalStorageKeyNameForConfig(configName) {
    return `eky-3d-player-config-${configName}`
  }
  loadFromLocalStorage(configName) {
    //
    //  If we can't access local storage, abort.
    if (!_localStorage) {
      return
    }
    //
    //  Define our key name based on the config, and read its value from storage.
    const key = this.getLocalStorageKeyNameForConfig(configName)
    talk(`${this.group} attempting to load our config from localStorage @ '${key}`)
    const config = _localStorage.getItem(key)
    //
    //  If there is now value, abort.
    if (!config) {
      return
    }
    //
    //  Now parse our data from JSON and upsert it to ourselves.
    try {
      const data = JSON.parse(config)
      //
      //  We serialised from ourself to get here, so we can just upsert it all directly.
      //  Caution: Must not have sensitive data in configs.
      for (const [k, v] of Object.entries(data)) {
        talk(`${this.group} read and set a value from localStorage: '${k}' = '${v}'`)
        this[k] = v
      }
    } catch (err) {
      talk(`${this.group} error, unable to load from local storage, clearing data in localStorage @ '${key}'`, 'error')
      _localStorage.removeItem(key)
    }
  }

  saveToLocalStorage(configName) {
    //
    //  If we can't access local storage, abort.
    if (!_localStorage) {
      return false
    }
    //
    //  Define our key name based on the config, and read its value from storage.
    const key = this.getLocalStorageKeyNameForConfig(configName)
    _localStorage.setItem(key, this.serialiseToJSON())
    //
    //  Return true as we set the value.
    return true
  }

  //
  //  :serialisation:
  //  We can represent the data within this class in a number of different formats, the serialisation functions
  //  below allow for different formats to be rendered.
  toString() {
    talk(`${this.group} casting to string`)
    return `${this.group}: ${this.serialiseToJSON()}`
  }

  serialiseKnownAttributes() {
    const knownAttributesData = {}
    KNOWN_CONFIG_ATTRIBUTES_ARRAY.map(knownAttribute => {
      knownAttributesData[knownAttribute] = this[knownAttribute]
      return true
    })
    return knownAttributesData
  }

  serialise() {
    talk(`${this.group} serialising as an object`)
    return {
      group: this.group,
      version: this.version,
      ...this.serialiseKnownAttributes(),
    }
  }

  serialiseToJSON(thin) {
    if (!thin) {
      thin = false
    }
    talk(`${this.group} serialising as json`)
    return JSON.stringify(this.serialise(), null, thin ? 0 : 2)
  }

  serialiseForModelViewer() {
    talk(`${this.group} serialising for model-viewer`)
    //
    //  Consulting our list of active attributes, we can know what values to upsert into model-viewer after
    //  translating their key names.
    const modelViewerConfig = {}
    DEFAULT_ACTIVE_CONFIG_ATTRIBUTES.map(attribute => {
      //
      //  Read the value that is set for this attribute.
      const value = this[attribute]
      //
      //  @Louis: TODO Confirm this is actually correct.
      //  Verify that the value is truthy in a basic sense (non truthy values should not be omitted from  HTML).
      if (!value) {
        return false
      }
      //
      //  Now set the value against its translated attribute name in our model-viewer config.
      modelViewerConfig[CONFIG_ATTRIBUTE_TO_MODEL_VIEWER_ATTRIBUTE[attribute]] = value
      return true
    })
    //
    //  Sort the object by keys alphabetically.
    const config = {}
    const keys = Array.from(Object.keys(modelViewerConfig))
    keys.sort()
    for (let i = 0; i < keys.length; i++) {
      config[keys[i]] = modelViewerConfig[keys[i]]
    }
    //
    //  OVERRIDE
    //  Hardcode the legacy skybox for now.
    //modelViewerConfig["environment-image"] = "https://cdn.pointandplace.com/services/3d-player/environments/legacy.hdr"
    return config
  }

  serialiseForReduxState() {
    talk(`${this.group} serialising to redux state`)
    return this.serialiseForModelViewer()
  }
}
