import React, { useState } from 'react';

export const LightAndDarkModeIcon = () => {
  const [type, setType] = useState("light")

  const handleModeChange = () => {
    setType(type === "light" ? "dark" : "light")
  }

  if (type === "dark") {
    return <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="24" height="24" onClick={handleModeChange}>
      <path d="M20 15.31 23.31 12 20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69zM12 18V6c3.31 0 6 2.69 6 6s-2.69 6-6 6z"></path>
    </svg>
  } else {
    return <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="24" height="24" onClick={handleModeChange}>
      <path d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zm-2 5.79V18h-3.52L12 20.48 9.52 18H6v-3.52L3.52 12 6 9.52V6h3.52L12 3.52 14.48 6H18v3.52L20.48 12 18 14.48zM12 6.5v11c3.03 0 5.5-2.47 5.5-5.5S15.03 6.5 12 6.5z"></path>
    </svg>
  }
}