/*

  n o t  f o u n d
  Not Found

  :description:
  Our main component to represent the 404 Not Found page.
  
*/

//
//  :react & redux:
import React from 'react';

//
//  :code:
import './NotFound.css';
import Page from '../../components/layout/page/Page';
import { talk } from '@eyekandy/app-core';

//
//  :component:
const NotFound = (props) => {
  const group = `[ROUTE-NOT-FOUND]`

  if (!props) {
    props = {}
  }

  talk(`${group} call to render`)
  return (
    <Page>
      <>
        <div className="w-screen h-screen flex flex-1 bg-gray-700 text-white items-center justify-center">
          <div className="w-96">
            <h1 className="text-5xl">not found</h1>
            <p className="text-xl mb-2">the requested resource does not exist at this time</p>
            <code className="bg-gray-900 rounded-lg text-sm text-white p-2">{window.location.pathname}</code>
          </div>
        </div>
      </>
    </Page>
  );
}

export default NotFound;
