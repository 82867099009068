//
//  @Louis: Removed from live build for now.
export const EYEKANDY_MODELS = []

/*export const EYEKANDY_MODELS = [
    {
      "product_arid": 1000,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "UE40MU6400",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 3001,
      "product_name": "The Sixty Seven Large 3 Seater Sofa",
      "product_model": "The Sixty Seven Large 3 Seater Sofa",
      "product_mpn": "S12345LG",
      "product_brand": "G Plan Vintage",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 3002,
      "product_name": "The Sixty Seven Large 3 Seater Sofa",
      "product_model": "The Sixty Seven Large 3 Seater Sofa",
      "product_mpn": "S12345DG",
      "product_brand": "G Plan Vintage",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 3003,
      "product_name": "The Sixty Seven Large 3 Seater Sofa",
      "product_model": "The Sixty Seven Large 3 Seater Sofa",
      "product_mpn": "S12345BE",
      "product_brand": "G Plan Vintage",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 5000,
      "product_name": "WorkForce All-In-One Wireless Printer",
      "product_model": "WorkForce All-In-One Wireless Printer",
      "product_mpn": "ET4750",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 6000,
      "product_name": "SoundLink Micro Portable Bluetooth Speaker",
      "product_model": "SoundLink Micro Portable Bluetooth Speaker",
      "product_mpn": "783342-0100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 8000,
      "product_name": "13.3inch Spectre 13-v000na Laptop",
      "product_model": "13.3inch Spectre 13-v000na Laptop",
      "product_mpn": "W7B07EA#ABU",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 9000,
      "product_name": "Nespresso Artisan Coffee Machine",
      "product_model": "Nespresso Artisan Coffee Machine",
      "product_mpn": "5KES0504BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 10000,
      "product_name": "Sony Cyber-shot RX-100 V Camera",
      "product_model": "Sony Cyber-shot RX-100 V Camera",
      "product_mpn": "DSCRX100M5.CEH",
      "product_brand": "Sony",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 10265,
      "product_name": "Creator Ford Mustang",
      "product_model": "Creator Ford Mustang",
      "product_mpn": "10265",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 42117,
      "product_name": "lego_42117",
      "product_model": "lego_42117",
      "product_mpn": "42117",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10271,
      "product_name": "lego_10271",
      "product_model": "lego_10271",
      "product_mpn": "10271",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10875,
      "product_name": "Cargo Train",
      "product_model": "Cargo Train",
      "product_mpn": "10875",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10889,
      "product_name": "Disney Mickey's Vacation House",
      "product_model": "Disney Mickey's Vacation House",
      "product_mpn": "10889",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10899,
      "product_name": "Frozen Ice Castle",
      "product_model": "Frozen Ice Castle",
      "product_mpn": "10899",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10903,
      "product_name": "Duplo Fire Station",
      "product_model": "Duplo Fire Station",
      "product_mpn": "10903",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10906,
      "product_name": "Duplo Tropical Island",
      "product_model": "Duplo Tropical Island",
      "product_mpn": "10906",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10907,
      "product_name": "Duplo World Animals",
      "product_model": "Duplo World Animals",
      "product_mpn": "10907",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10913,
      "product_name": "Brick Box",
      "product_model": "Brick Box",
      "product_mpn": "10913",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10929,
      "product_name": "Duplo Town Modular Playhouse 3-in-1 Set",
      "product_model": "Duplo Town Modular Playhouse 3-in-1 Set",
      "product_mpn": "10929",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 21042,
      "product_name": "Statue of Liberty",
      "product_model": "Statue of Liberty",
      "product_mpn": "21042",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 21046,
      "product_name": "Empire State Building",
      "product_model": "Empire State Building",
      "product_mpn": "21046",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 21160,
      "product_name": "lego_21160",
      "product_model": "lego_21160",
      "product_mpn": "21160",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 21316,
      "product_name": "Ideas The Flintstones",
      "product_model": "Ideas The Flintstones",
      "product_mpn": "21316",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 21321,
      "product_name": "lego_21321",
      "product_model": "lego_21321",
      "product_mpn": "21321",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 31095,
      "product_name": "Creator Fairground Carousel",
      "product_model": "Creator Fairground Carousel",
      "product_mpn": "31095",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 31105,
      "product_name": "Cake Shop - Florist",
      "product_model": "Cake Shop - Florist",
      "product_mpn": "31105",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 31108,
      "product_name": "lego_31108",
      "product_model": "lego_31108",
      "product_mpn": "31108",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75274,
      "product_name": "lego_75274",
      "product_model": "lego_75274",
      "product_mpn": "75274",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41162,
      "product_name": "Disney Ariel, Aurora, and Tiana's Royal Celebration",
      "product_model": "Disney Ariel, Aurora, and Tiana's Royal Celebration",
      "product_mpn": "41162",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41368,
      "product_name": "Friends Andrea's Talent Show",
      "product_model": "Friends Andrea's Talent Show",
      "product_mpn": "41368",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41369,
      "product_name": "Friends Mia's House",
      "product_model": "Friends Mia's House",
      "product_mpn": "41369",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41374,
      "product_name": "Friends Andrea's Pool Party",
      "product_model": "Friends Andrea's Pool Party",
      "product_mpn": "41374",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41375,
      "product_name": "Heartlake City Amusement Pier",
      "product_model": "Heartlake City Amusement Pier",
      "product_mpn": "41375",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41379,
      "product_name": "Friends Heartlake City Restaurant",
      "product_model": "Friends Heartlake City Restaurant",
      "product_mpn": "41379",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41381,
      "product_name": "Friends Rescue Mission Boat",
      "product_model": "Friends Rescue Mission Boat",
      "product_mpn": "41381",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41392,
      "product_name": "Nature Glamping",
      "product_model": "Nature Glamping",
      "product_mpn": "41392",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41393,
      "product_name": "Baking Competition",
      "product_model": "Baking Competition",
      "product_mpn": "41393",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41394,
      "product_name": "Heartlake City Hospital",
      "product_model": "Heartlake City Hospital",
      "product_mpn": "41394",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41395,
      "product_name": "Friendship Bus",
      "product_model": "Friendship Bus",
      "product_mpn": "41395",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41398,
      "product_name": "lego_41398",
      "product_model": "lego_41398",
      "product_mpn": "41398",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41429,
      "product_name": "lego_41429",
      "product_model": "lego_41429",
      "product_mpn": "41429",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 41430,
      "product_name": "lego_41430",
      "product_model": "lego_41430",
      "product_mpn": "41430",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 42095,
      "product_name": "Technic Remote-Controlled Stunt Racer",
      "product_model": "Technic Remote-Controlled Stunt Racer",
      "product_mpn": "42095",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 42105,
      "product_name": "Catamaran",
      "product_model": "Catamaran",
      "product_mpn": "42105",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 42106,
      "product_name": "Stunt Show Truck & Bike",
      "product_model": "Stunt Show Truck & Bike",
      "product_mpn": "42106",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 42107,
      "product_name": "lego_42107",
      "product_model": "lego_42107",
      "product_mpn": "42107",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 43178,
      "product_name": "Cinderella's Castle Celebration",
      "product_model": "Cinderella's Castle Celebration",
      "product_mpn": "43178",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60197,
      "product_name": "Passenger Train",
      "product_model": "Passenger Train",
      "product_mpn": "60197",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60216,
      "product_name": "City  Downtown Fire Brigade",
      "product_model": "City  Downtown Fire Brigade",
      "product_mpn": "60216",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60227,
      "product_name": "City Lunar Space Station",
      "product_model": "City Lunar Space Station",
      "product_mpn": "60227",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60228,
      "product_name": "City Deep Space Rocket and Launch Control",
      "product_model": "City Deep Space Rocket and Launch Control",
      "product_mpn": "60228",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60229,
      "product_name": "City Rocket Assembly & Transport",
      "product_model": "City Rocket Assembly & Transport",
      "product_mpn": "60229",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60243,
      "product_name": "Police Helicopter Chase",
      "product_model": "Police Helicopter Chase",
      "product_mpn": "60243",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60244,
      "product_name": "Police Helicopter Transport",
      "product_model": "Police Helicopter Transport",
      "product_mpn": "60244",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60245,
      "product_name": "Police Monster Truck Heist",
      "product_model": "Police Monster Truck Heist",
      "product_mpn": "60245",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60246,
      "product_name": "Police Station",
      "product_model": "Police Station",
      "product_mpn": "60246",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60254,
      "product_name": "Race Boat Transporter",
      "product_model": "Race Boat Transporter",
      "product_mpn": "60254",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60257,
      "product_name": "lego_60257",
      "product_model": "lego_60257",
      "product_mpn": "60257",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60258,
      "product_name": "Tuning Workshop",
      "product_model": "Tuning Workshop",
      "product_mpn": "60258",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60264,
      "product_name": "lego_60264",
      "product_model": "lego_60264",
      "product_mpn": "60264",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60266,
      "product_name": "lego_60266",
      "product_model": "lego_60266",
      "product_mpn": "60266",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70425,
      "product_name": "Newbury Haunted High School",
      "product_model": "Newbury Haunted High School",
      "product_mpn": "70425",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70429,
      "product_name": "El Fuego's Stunt Plane",
      "product_model": "El Fuego's Stunt Plane",
      "product_mpn": "70429",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70431,
      "product_name": "The Lighthouse of Darkness",
      "product_model": "The Lighthouse of Darkness",
      "product_mpn": "70431",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70432,
      "product_name": "Haunted Fairground",
      "product_model": "Haunted Fairground",
      "product_mpn": "70432",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70436,
      "product_name": "lego_70436",
      "product_model": "lego_70436",
      "product_mpn": "70436",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70437,
      "product_name": "lego_70437",
      "product_model": "lego_70437",
      "product_mpn": "70437",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70677,
      "product_name": "Ninjago Land Bounty",
      "product_model": "Ninjago Land Bounty",
      "product_mpn": "70677",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 70678,
      "product_name": "Ninjago Castle of the Forsaken Emperor",
      "product_model": "Ninjago Castle of the Forsaken Emperor",
      "product_mpn": "70678",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71699,
      "product_name": "lego_71699",
      "product_model": "lego_71699",
      "product_mpn": "71699",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71702,
      "product_name": "Golden Mech",
      "product_model": "Golden Mech",
      "product_mpn": "71702",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71704,
      "product_name": "lego_71704",
      "product_model": "lego_71704",
      "product_mpn": "71704",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71709,
      "product_name": "Jay and Lloyd's Velocity Racers",
      "product_model": "Jay and Lloyd's Velocity Racers",
      "product_mpn": "71709",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71711,
      "product_name": "Jay's Cyber Dragon",
      "product_model": "Jay's Cyber Dragon",
      "product_mpn": "71711",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71712,
      "product_name": "Empire Temple of Madness",
      "product_model": "Empire Temple of Madness",
      "product_mpn": "71712",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71722,
      "product_name": "lego_71722",
      "product_model": "lego_71722",
      "product_mpn": "71722",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75242,
      "product_name": "Star Wars Black Ace TIE Interceptor",
      "product_model": "Star Wars Black Ace TIE Interceptor",
      "product_mpn": "75242",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75243,
      "product_name": "Slave l – 20th Anniversary Edition",
      "product_model": "Slave l – 20th Anniversary Edition",
      "product_mpn": "75243",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75253,
      "product_name": "Droid Commander",
      "product_model": "Droid Commander",
      "product_mpn": "75253",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75255,
      "product_name": "Yoda",
      "product_model": "Yoda",
      "product_mpn": "75255",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75257,
      "product_name": "Millennium Falcon",
      "product_model": "Millennium Falcon",
      "product_mpn": "75257",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75271,
      "product_name": "Luke Skywalker's Landspeeder",
      "product_model": "Luke Skywalker's Landspeeder",
      "product_mpn": "75271",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75272,
      "product_name": "Sith TIE Fighter",
      "product_model": "Sith TIE Fighter",
      "product_mpn": "75272",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75273,
      "product_name": "Poe Dameron's X-wing Fighter",
      "product_model": "Poe Dameron's X-wing Fighter",
      "product_mpn": "75273",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75276,
      "product_name": "lego_75276",
      "product_model": "lego_75276",
      "product_mpn": "75276",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75945,
      "product_name": "Expecto Patronum",
      "product_model": "Expecto Patronum",
      "product_mpn": "75945",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75947,
      "product_name": "Harry Potter Hagrid's Hut: Buckbeak's Rescue",
      "product_model": "Harry Potter Hagrid's Hut: Buckbeak's Rescue",
      "product_mpn": "75947",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75968,
      "product_name": "lego_75968",
      "product_model": "lego_75968",
      "product_mpn": "75968",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75974,
      "product_name": "Overwatch Bastion",
      "product_model": "Overwatch Bastion",
      "product_mpn": "75974",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76115,
      "product_name": "Marvel Spider Mech vs. Venom",
      "product_model": "Marvel Spider Mech vs. Venom",
      "product_mpn": "76115",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76122,
      "product_name": "Super Heroes Batcave Clayface™ Invasion",
      "product_model": "Super Heroes Batcave Clayface™ Invasion",
      "product_mpn": "76122",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76125,
      "product_name": "Marvel Iron Man Hall of Armor",
      "product_model": "Marvel Iron Man Hall of Armor",
      "product_mpn": "76125",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76126,
      "product_name": "Marvel Avengers Ultimate Quinjet",
      "product_model": "Marvel Avengers Ultimate Quinjet",
      "product_mpn": "76126",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76130,
      "product_name": "Marvel Stark Jet and the Drone Attack",
      "product_model": "Marvel Stark Jet and the Drone Attack",
      "product_mpn": "76130",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76131,
      "product_name": "Marvel Avengers Compound Battle",
      "product_model": "Marvel Avengers Compound Battle",
      "product_mpn": "76131",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76142,
      "product_name": "Avengers Speeder Bike Attack",
      "product_model": "Avengers Speeder Bike Attack",
      "product_mpn": "76142",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76150,
      "product_name": "Spiderjet vs. Venom Mech",
      "product_model": "Spiderjet vs. Venom Mech",
      "product_mpn": "76150",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76151,
      "product_name": "lego_76151",
      "product_model": "lego_76151",
      "product_mpn": "76151",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 100000,
      "product_name": "28\" Smart TV wall mounted",
      "product_model": "28\" Smart TV wall mounted",
      "product_mpn": "28LF491U",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100001,
      "product_name": "28\" Smart TV with stand",
      "product_model": "28\" Smart TV with stand",
      "product_mpn": "28LF491U",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100002,
      "product_name": "43\" Smart TV with webOS wall mounted",
      "product_model": "43\" Smart TV with webOS wall mounted",
      "product_mpn": "43LH590V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100003,
      "product_name": "43\" Smart TV with webOS with stand",
      "product_model": "43\" Smart TV with webOS with stand",
      "product_mpn": "43LH590V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100004,
      "product_name": "43\" ULTRA HD 4K TV wall mounted",
      "product_model": "43\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "43UH620V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100005,
      "product_name": "43\" ULTRA HD 4K TV with stand",
      "product_model": "43\" ULTRA HD 4K TV with stand",
      "product_mpn": "43UH620V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100006,
      "product_name": "49\" ULTRA HD 4K TV wall mounted",
      "product_model": "49\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "49UH620V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100007,
      "product_name": "49\" ULTRA HD 4K TV with stand",
      "product_model": "49\" ULTRA HD 4K TV with stand",
      "product_mpn": "49UH620V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100008,
      "product_name": "55\" Smart 4K Ultra HD OLED TV",
      "product_model": "55\" Smart 4K Ultra HD OLED TV",
      "product_mpn": "OLED55B7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100009,
      "product_name": "55\" Smart 4K Ultra HD OLED TV",
      "product_model": "55\" Smart 4K Ultra HD OLED TV",
      "product_mpn": "OLED55B7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100010,
      "product_name": "32\" Smart TV with webOS wall mounted",
      "product_model": "32\" Smart TV with webOS wall mounted",
      "product_mpn": "32LH590U",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100011,
      "product_name": "32\" Smart TV with webOS with stand",
      "product_model": "32\" Smart TV with webOS with stand",
      "product_mpn": "32LH590U",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100012,
      "product_name": "43\" Smart LED TV wall mounted",
      "product_model": "43\" Smart LED TV wall mounted",
      "product_mpn": "43LJ594V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100013,
      "product_name": "43\" Smart LED TV with stand",
      "product_model": "43\" Smart LED TV with stand",
      "product_mpn": "43LJ594V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100014,
      "product_name": "49\" Smart LED TV wall mounted",
      "product_model": "49\" Smart LED TV wall mounted",
      "product_mpn": "49LJ594V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100015,
      "product_name": "49\" Smart LED TV with stand",
      "product_model": "49\" Smart LED TV with stand",
      "product_mpn": "49LJ594V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100016,
      "product_name": "49\" LED TV with Freeview wall mounted",
      "product_model": "49\" LED TV with Freeview wall mounted",
      "product_mpn": "49LH5100",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100017,
      "product_name": "49\" LED TV with Freeview with stand",
      "product_model": "49\" LED TV with Freeview with stand",
      "product_mpn": "49LH5100",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100018,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "OLED55C7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100019,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "OLED55C7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100020,
      "product_name": "43\" Smart LED TV wall mounted",
      "product_model": "43\" Smart LED TV wall mounted",
      "product_mpn": "43LJ624V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100021,
      "product_name": "43\" Smart LED TV with stand",
      "product_model": "43\" Smart LED TV with stand",
      "product_mpn": "43LJ624V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100022,
      "product_name": "49\" Smart LED TV wall mounted",
      "product_model": "49\" Smart LED TV wall mounted",
      "product_mpn": "49LJ624V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100023,
      "product_name": "49\" Smart LED TV with stand",
      "product_model": "49\" Smart LED TV with stand",
      "product_mpn": "49LJ624V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100024,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "43UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100025,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "43UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100026,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "49UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100027,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "49UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100028,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100029,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100030,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100031,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65UJ701V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100032,
      "product_name": "43\" ULTRA HD 4K TV wall mounted",
      "product_model": "43\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "43UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100033,
      "product_name": "43\" ULTRA HD 4K TV with stand",
      "product_model": "43\" ULTRA HD 4K TV with stand",
      "product_mpn": "43UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100034,
      "product_name": "49\" ULTRA HD 4K TV wall mounted",
      "product_model": "49\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "49UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100035,
      "product_name": "49\" ULTRA HD 4K TV with stand",
      "product_model": "49\" ULTRA HD 4K TV with stand",
      "product_mpn": "49UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100036,
      "product_name": "55\" ULTRA HD 4K TV wall mounted",
      "product_model": "55\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "55UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100037,
      "product_name": "55\" ULTRA HD 4K TV with stand",
      "product_model": "55\" ULTRA HD 4K TV with stand",
      "product_mpn": "55UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100038,
      "product_name": "65\" ULTRA HD 4K TV wall mounted",
      "product_model": "65\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "65UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100039,
      "product_name": "65\" ULTRA HD 4K TV with stand",
      "product_model": "65\" ULTRA HD 4K TV with stand",
      "product_mpn": "65UH668V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100040,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "43UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100041,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "43UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100042,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "49UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100043,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "49UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100044,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100045,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100046,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "60UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100047,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "60UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100048,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100049,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65UJ630V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100050,
      "product_name": "55\" OLED TV wall mounted",
      "product_model": "55\" OLED TV wall mounted",
      "product_mpn": "OLED55B6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100051,
      "product_name": "55\" OLED TV with stand",
      "product_model": "55\" OLED TV with stand",
      "product_mpn": "OLED55B6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100052,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "43UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100053,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "43UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100054,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "49UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100055,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "49UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100056,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100057,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100058,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "60UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100059,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "60UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100060,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100061,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65UJ634V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100062,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TVwall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TVwall mounted",
      "product_mpn": "43UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100063,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "43UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100064,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "49UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100065,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "49UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100066,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100067,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100068,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100069,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65UJ670V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100070,
      "product_name": "55\" OLED TV wall mounted",
      "product_model": "55\" OLED TV wall mounted",
      "product_mpn": "55C6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100071,
      "product_name": "55\" OLED TV with stand",
      "product_model": "55\" OLED TV with stand",
      "product_mpn": "55C6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100072,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "43UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100073,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "43UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100074,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "49UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100075,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "49UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100076,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100077,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100078,
      "product_name": "60\" ULTRA HD 4K TV wall mounted",
      "product_model": "60\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "60UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100079,
      "product_name": "60\" ULTRA HD 4K TV with stand",
      "product_model": "60\" ULTRA HD 4K TV with stand",
      "product_mpn": "60UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100080,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100081,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65UJ750V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100082,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "49SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100083,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "49SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100084,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100085,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100086,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "60SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100087,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "60SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100088,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100089,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65SJ810V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100090,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55SJ850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100091,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55SJ850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100092,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "60SJ850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100093,
      "product_name": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "60\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "60SJ850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100094,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65SJ850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100095,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65SJ850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100096,
      "product_name": "49\" SUPER UHD TV wall mounted",
      "product_model": "49\" SUPER UHD TV wall mounted",
      "product_mpn": "49UH850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100097,
      "product_name": "49\" SUPER UHD TV with stand",
      "product_model": "49\" SUPER UHD TV with stand",
      "product_mpn": "49UH850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100098,
      "product_name": "55\" SUPER UHD TV wall mounted",
      "product_model": "55\" SUPER UHD TV wall mounted",
      "product_mpn": "55UH850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100099,
      "product_name": "55\" SUPER UHD TV with stand",
      "product_model": "55\" SUPER UHD TV with stand",
      "product_mpn": "55UH850V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100100,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "49SJ800V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100101,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "49SJ800V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100102,
      "product_name": "55\" OLED TV wall mounted",
      "product_model": "55\" OLED TV wall mounted",
      "product_mpn": "OLED55E6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100103,
      "product_name": "55\" OLED TV with stand",
      "product_model": "55\" OLED TV with stand",
      "product_mpn": "OLED55E6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100104,
      "product_name": "65\" OLED TV wall mounted",
      "product_model": "65\" OLED TV wall mounted",
      "product_mpn": "OLED65E6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100105,
      "product_name": "65\" OLED TV with stand",
      "product_model": "65\" OLED TV with stand",
      "product_mpn": "OLED65E6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100106,
      "product_name": "55\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_mpn": "OLED55E7N",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100107,
      "product_name": "55\" Smart 4K Ultra HD OLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD OLED TV with stand",
      "product_mpn": "OLED55E7N",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100108,
      "product_name": "65\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_mpn": "OLED65B7P",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100109,
      "product_name": "65\" Smart 4K Ultra HD OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD OLED TV with stand",
      "product_mpn": "OLED65B7P",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100110,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "OLED65C7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100111,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "OLED65C7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100112,
      "product_name": "65\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_mpn": "OLED65E7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100113,
      "product_name": "65\" Smart 4K Ultra HD OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD OLED TV with stand",
      "product_mpn": "OLED65E7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100114,
      "product_name": "65\" Smart 4K HDR OLED TV wall mounted",
      "product_model": "65\" Smart 4K HDR OLED TV wall mounted",
      "product_mpn": "OLED65G7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100115,
      "product_name": "65\" Smart 4K HDR OLED TV with stand",
      "product_model": "65\" Smart 4K HDR OLED TV with stand",
      "product_mpn": "OLED65G7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100116,
      "product_name": "77\" Smart 4K HDR OLED TV wall mounted",
      "product_model": "77\" Smart 4K HDR OLED TV wall mounted",
      "product_mpn": "OLED77G7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100117,
      "product_name": "77\" Smart 4K HDR OLED TV with stand",
      "product_model": "77\" Smart 4K HDR OLED TV with stand",
      "product_mpn": "OLED77G7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100118,
      "product_name": "65\" Smart 4K Wallpaper OLED TV wall mounted",
      "product_model": "65\" Smart 4K Wallpaper OLED TV wall mounted",
      "product_mpn": "OLED65W7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100119,
      "product_name": "65\" Smart 4K Wallpaper OLED TV with stand",
      "product_model": "65\" Smart 4K Wallpaper OLED TV with stand",
      "product_mpn": "OLED65W7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100120,
      "product_name": "77\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "77\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "OLED77W7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100121,
      "product_name": "77\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "77\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "OLED77W7V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100122,
      "product_name": "77\" SIGNATURE Smart OLED TV wall mounted",
      "product_model": "77\" SIGNATURE Smart OLED TV wall mounted",
      "product_mpn": "OLED77G6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100123,
      "product_name": "77\" SIGNATURE Smart OLED TV with stand",
      "product_model": "77\" SIGNATURE Smart OLED TV with stand",
      "product_mpn": "OLED77G6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100124,
      "product_name": "77\" SIGNATURE Smart OLED TV wall mounted",
      "product_model": "77\" SIGNATURE Smart OLED TV wall mounted",
      "product_mpn": "OLED77G7P",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100125,
      "product_name": "77\" SIGNATURE Smart OLED TV with stand",
      "product_model": "77\" SIGNATURE Smart OLED TV with stand",
      "product_mpn": "OLED77G7P",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100126,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "55SJ950V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100127,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "55SJ950V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100128,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "65SJ950V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100129,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "65SJ950V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100130,
      "product_name": "65\" 4K UHD Smart LED TV wall mounted",
      "product_model": "65\" 4K UHD Smart LED TV wall mounted",
      "product_mpn": "65UF8500",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100131,
      "product_name": "65\" 4K UHD Smart LED TV with stand",
      "product_model": "65\" 4K UHD Smart LED TV with stand",
      "product_mpn": "65UF8500",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100132,
      "product_name": "55\" Smart LED TV wall mounted",
      "product_model": "55\" Smart LED TV wall mounted",
      "product_mpn": "55LJ625V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100133,
      "product_name": "55\" Smart LED TV with stand",
      "product_model": "55\" Smart LED TV with stand",
      "product_mpn": "55LJ625V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100134,
      "product_name": "55\" SUPER UHD TV wall mounted",
      "product_model": "55\" SUPER UHD TV wall mounted",
      "product_mpn": "55UH950V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100135,
      "product_name": "55\" SUPER UHD TV with stand",
      "product_model": "55\" SUPER UHD TV with stand",
      "product_mpn": "55UH950V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100136,
      "product_name": "55\" ULTRA HD 4K TV wall mounted",
      "product_model": "55\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "55UH625V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100137,
      "product_name": "55\" ULTRA HD 4K TV with stand",
      "product_model": "55\" ULTRA HD 4K TV with stand",
      "product_mpn": "55UH625V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100138,
      "product_name": "55\" OLED TV wall mounted",
      "product_model": "55\" OLED TV wall mounted",
      "product_mpn": "55EG910V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100139,
      "product_name": "55\" OLED TV with stand",
      "product_model": "55\" OLED TV with stand",
      "product_mpn": "55EG910V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100140,
      "product_name": "60\" Plasma Smart TV with 3D wall mounted",
      "product_model": "60\" Plasma Smart TV with 3D wall mounted",
      "product_mpn": "60PB690V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100141,
      "product_name": "60\" Plasma Smart TV with 3D with stand",
      "product_model": "60\" Plasma Smart TV with 3D with stand",
      "product_mpn": "60PB690V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100142,
      "product_name": "60\" SUPER UHD TV wall mounted",
      "product_model": "60\" SUPER UHD TV wall mounted",
      "product_mpn": "60UH770V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100143,
      "product_name": "60\" SUPER UHD TV with stand",
      "product_model": "60\" SUPER UHD TV with stand",
      "product_mpn": "60UH770V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100144,
      "product_name": "60\" ULTRA HD 4K TV wall mounted",
      "product_model": "60\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "60UF770V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100145,
      "product_name": "60\" ULTRA HD 4K TV with stand",
      "product_model": "60\" ULTRA HD 4K TV with stand",
      "product_mpn": "60UF770V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100146,
      "product_name": "65\" OLED TV wall mounted",
      "product_model": "65\" OLED TV wall mounted",
      "product_mpn": "OLED65C6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100147,
      "product_name": "65\" OLED TV with stand",
      "product_model": "65\" OLED TV with stand",
      "product_mpn": "OLED65C6V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100148,
      "product_name": "70\" ULTRA HD 4K TV wall mounted",
      "product_model": "70\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "70UJ675V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100149,
      "product_name": "70\" ULTRA HD 4K TV with stand",
      "product_model": "70\" ULTRA HD 4K TV with stand",
      "product_mpn": "70UJ675V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100150,
      "product_name": "75\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "75\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "75UJ675V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100151,
      "product_name": "75\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "75\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "75UJ675V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100152,
      "product_name": "75\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "75\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "75SJ955V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100153,
      "product_name": "75\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "75\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "75SJ955V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100154,
      "product_name": "84\" ULTRA HD 4K TV wall mounted",
      "product_model": "84\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "84LM9600",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100155,
      "product_name": "84\" ULTRA HD 4K TV with stand",
      "product_model": "84\" ULTRA HD 4K TV with stand",
      "product_mpn": "84LM9600",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100156,
      "product_name": "86\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "86\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "86SJ957V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100157,
      "product_name": "86\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "86\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "86SJ957V",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100158,
      "product_name": "40\" ULTRA HD 4K TV wall mounted",
      "product_model": "40\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "TX-40DX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100159,
      "product_name": "40\" ULTRA HD 4K TV with stand",
      "product_model": "40\" ULTRA HD 4K TV with stand",
      "product_mpn": "TX-40DX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100160,
      "product_name": "50\" ULTRA HD 4K TV wall mounted",
      "product_model": "50\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "TX-50DX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100161,
      "product_name": "50\" ULTRA HD 4K TV with stand",
      "product_model": "50\" ULTRA HD 4K TV with stand",
      "product_mpn": "TX-50DX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100162,
      "product_name": "58\" ULTRA HD 4K TV wall mounted",
      "product_model": "58\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "TX-58DX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100163,
      "product_name": "58\" ULTRA HD 4K TV with stand",
      "product_model": "58\" ULTRA HD 4K TV with stand",
      "product_mpn": "TX-58DX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100164,
      "product_name": "50\" ULTRA HD 4K TV wall mounted",
      "product_model": "50\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "TX-50DX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100165,
      "product_name": "50\" ULTRA HD 4K TV with stand",
      "product_model": "50\" ULTRA HD 4K TV with stand",
      "product_mpn": "TX-50DX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100166,
      "product_name": "58\" ULTRA HD 4K TV wall mounted",
      "product_model": "58\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "TX-58DX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100167,
      "product_name": "58\" ULTRA HD 4K TV with stand",
      "product_model": "58\" ULTRA HD 4K TV with stand",
      "product_mpn": "TX-58DX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100168,
      "product_name": "65\" ULTRA HD 4K TV wall mounted",
      "product_model": "65\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "TX-65DX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100169,
      "product_name": "65\" ULTRA HD 4K TV with stand",
      "product_model": "65\" ULTRA HD 4K TV with stand",
      "product_mpn": "TX-65DX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100170,
      "product_name": "65\" Ultra HD 4K Pro HDR OLED TV wall mounted",
      "product_model": "65\" Ultra HD 4K Pro HDR OLED TV wall mounted",
      "product_mpn": "TX-65EZ1002B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100171,
      "product_name": "65\" Ultra HD 4K Pro HDR OLED TV with stand",
      "product_model": "65\" Ultra HD 4K Pro HDR OLED TV with stand",
      "product_mpn": "TX-65EZ1002B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100172,
      "product_name": "77\" Smart 4K Ultra HDR OLED TV wall mounted",
      "product_model": "77\" Smart 4K Ultra HDR OLED TV wall mounted",
      "product_mpn": "TX-77EZ1002B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100173,
      "product_name": "77\" Smart 4K Ultra HDR OLED TV with stand",
      "product_model": "77\" Smart 4K Ultra HDR OLED TV with stand",
      "product_mpn": "TX-77EZ1002B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100174,
      "product_name": "50\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "50\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "TX-50DX802B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100175,
      "product_name": "50\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "50\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "TX-50DX802B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100176,
      "product_name": "58\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "58\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "TX-58DX802B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100177,
      "product_name": "58\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "58\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "TX-58DX802B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100178,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-40EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100179,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-40EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100180,
      "product_name": "50\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "50\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-50EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100181,
      "product_name": "50\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "50\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-50EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100182,
      "product_name": "58\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "58\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-58EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100183,
      "product_name": "58\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "58\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-58EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100184,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-65EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100185,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-65EX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100186,
      "product_name": "50\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "50\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-50EX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100187,
      "product_name": "50\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_model": "50\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-50EX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100188,
      "product_name": "58\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "58\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-58EX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100189,
      "product_name": "58\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_model": "58\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-58EX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100190,
      "product_name": "65\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-65EX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100191,
      "product_name": "65\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-65EX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100192,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "TX-55EZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100193,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "TX-55EZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100194,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "TX-65EZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100195,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "TX-65EZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100196,
      "product_name": "40\" ULTRA HD 4K TV wall mounted",
      "product_model": "40\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "TX-40DX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100197,
      "product_name": "40\" ULTRA HD 4K TV with stand",
      "product_model": "40\" ULTRA HD 4K TV with stand",
      "product_mpn": "TX-40DX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100198,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "KD-55A1",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100199,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "KD-55A1",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100200,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "KD-65A1",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100201,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "KD-65A1",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100202,
      "product_name": "77\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "77\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "KD-77A1",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100203,
      "product_name": "77\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "77\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "KD-77A1",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100204,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-49XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100205,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-49XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100206,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-55XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100207,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-55XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100208,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-65XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100209,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-65XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100210,
      "product_name": "75\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "75\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "KD-75XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100211,
      "product_name": "75\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "75\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "KD-75XE9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100212,
      "product_name": "58\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "58\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "TX-58DX902B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100213,
      "product_name": "58\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "58\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "TX-58DX902B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100214,
      "product_name": "65\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "TX-65DX902B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100215,
      "product_name": "65\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "TX-65DX902B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100216,
      "product_name": "40\" Smart LED TV wall mounted",
      "product_model": "40\" Smart LED TV wall mounted",
      "product_mpn": "TX-40DS400B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100217,
      "product_name": "40\" Smart LED TV with stand",
      "product_model": "40\" Smart LED TV with stand",
      "product_mpn": "TX-40DS400B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100218,
      "product_name": "75\" ULTRA HD 4K TV wall mounted",
      "product_model": "75\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "KD75XD8505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100219,
      "product_name": "75\" ULTRA HD 4K TV with stand",
      "product_model": "75\" ULTRA HD 4K TV with stand",
      "product_mpn": "KD75XD8505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100220,
      "product_name": "85\" ULTRA HD 4K TV wall mounted",
      "product_model": "85\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "KD85XD8505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100221,
      "product_name": "85\" ULTRA HD 4K TV with stand",
      "product_model": "85\" ULTRA HD 4K TV with stand",
      "product_mpn": "KD85XD8505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100222,
      "product_name": "65\" Ultra HD 4K LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K LED TV wall mounted",
      "product_mpn": "TX-65CX802B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100223,
      "product_name": "65\" Ultra HD 4K LED TV with stand",
      "product_model": "65\" Ultra HD 4K LED TV with stand",
      "product_mpn": "TX-65CX802B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100226,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-55XE9305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100227,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-55XE9305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100228,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD-65XE9305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100229,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD-65XE9305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100248,
      "product_name": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD-75XE9405",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100249,
      "product_name": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD-75XE9405",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100250,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD-65ZD9",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100251,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD-65ZD9",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100252,
      "product_name": "75\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "75\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-75ZD9",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100253,
      "product_name": "75\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_model": "75\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-75ZD9",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100264,
      "product_name": "55\" LED TV wall mounted",
      "product_model": "55\" LED TV wall mounted",
      "product_mpn": "KDL-55W805A",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100265,
      "product_name": "55\" LED TV with stand",
      "product_model": "55\" LED TV with stand",
      "product_mpn": "KDL-55W805A",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100328,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE49KS7000U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100329,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE49KS7000U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100330,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE65KS7000U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100331,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE65KS7000U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100332,
      "product_name": "49\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE49MU7070T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100333,
      "product_name": "49\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "49\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE49MU7070T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100334,
      "product_name": "55\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE55MU7070T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100335,
      "product_name": "55\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "55\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE55MU7070T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100336,
      "product_name": "65\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE65MU7070T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100337,
      "product_name": "65\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "65\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE65MU7070T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100338,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE49MU9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100339,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE49MU9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100340,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE55MU9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100341,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE55MU9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100342,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE65MU9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100343,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE65MU9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100344,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE49MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100345,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE49MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100346,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE55MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100347,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE55MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100348,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE65MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100349,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE65MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100350,
      "product_name": "75\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "75\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE75MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100351,
      "product_name": "75\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "75\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "UE75MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100352,
      "product_name": "82\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "82\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE82MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100353,
      "product_name": "82\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "82\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "UE82MU7000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100354,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE49MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100355,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE49MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100356,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE55MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100357,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE55MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100358,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE65MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100359,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE65MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100360,
      "product_name": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE75MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100361,
      "product_name": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE75MU8000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100362,
      "product_name": "49\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_mpn": "QE49Q7CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100363,
      "product_name": "49\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_mpn": "QE49Q7CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100364,
      "product_name": "55\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_mpn": "QE55Q7CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100365,
      "product_name": "55\" Smart 4K Ultra HD HDR Curved QLED TVwith stand",
      "product_model": "55\" Smart 4K Ultra HD HDR Curved QLED TVwith stand",
      "product_mpn": "QE55Q7CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100366,
      "product_name": "65\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_mpn": "QE65Q7CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100367,
      "product_name": "65\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_mpn": "QE65Q7CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100368,
      "product_name": "49\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE49Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100369,
      "product_name": "49\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE49Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100370,
      "product_name": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE55Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100371,
      "product_name": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE55Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100372,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_mpn": "QE65Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100373,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_mpn": "QE65Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100374,
      "product_name": "75\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "75\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE75Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100375,
      "product_name": "75\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "75\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE75Q7FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100376,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE55KS9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100377,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE55KS9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100378,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE65KS9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100379,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE65KS9000T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100380,
      "product_name": "55\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_mpn": "QE55Q8CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100381,
      "product_name": "55\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_mpn": "QE55Q8CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100382,
      "product_name": "65\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_mpn": "QE65Q8CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100383,
      "product_name": "65\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_mpn": "QE65Q8CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100384,
      "product_name": "75\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_model": "75\" Smart 4K Ultra HD HDR Curved QLED TV wall mounted",
      "product_mpn": "QE75Q8CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100385,
      "product_name": "75\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_model": "75\" Smart 4K Ultra HD HDR Curved QLED TV with stand",
      "product_mpn": "QE75Q8CAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100386,
      "product_name": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE55Q8FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100387,
      "product_name": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE55Q8FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100388,
      "product_name": "65\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE65Q8FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100389,
      "product_name": "65\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE65Q8FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100392,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_mpn": "QE65Q9FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100393,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_mpn": "QE65Q9FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100394,
      "product_name": "43\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "43\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE43LS003AU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100395,
      "product_name": "43\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "43\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE43LS003AU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100396,
      "product_name": "55\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "UE55LS003AU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100397,
      "product_name": "55\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "UE55LS003AU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100398,
      "product_name": "55\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_mpn": "QE55Q6FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100399,
      "product_name": "55\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_mpn": "QE55Q6FAMT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100400,
      "product_name": "40\" Smart Full HD LED TV wall mounted",
      "product_model": "40\" Smart Full HD LED TV wall mounted",
      "product_mpn": "KDL-40R553C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100401,
      "product_name": "40\" Smart Full HD LED TV with stand",
      "product_model": "40\" Smart Full HD LED TV with stand",
      "product_mpn": "KDL-40R553C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100402,
      "product_name": "43\" Full HD 4K HDR LED TV wall mounted",
      "product_model": "43\" Full HD 4K HDR LED TV wall mounted",
      "product_mpn": "KDL-43WE753",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100403,
      "product_name": "43\" Full HD 4K HDR LED TV with stand",
      "product_model": "43\" Full HD 4K HDR LED TV with stand",
      "product_mpn": "KDL-43WE753",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100404,
      "product_name": "49\" Smart HDR LED TV wall mounted",
      "product_model": "49\" Smart HDR LED TV wall mounted",
      "product_mpn": "KDL-49WE753",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100405,
      "product_name": "49\" Smart HDR LED TV with stand",
      "product_model": "49\" Smart HDR LED TV with stand",
      "product_mpn": "KDL-49WE753",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100406,
      "product_name": "43\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "43\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD-43X8309C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100407,
      "product_name": "43\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "43\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD-43X8309C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100408,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD-49X8309C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100409,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD-49X8309C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100410,
      "product_name": "40\" Full HD Pro LED TV wall mounted",
      "product_model": "40\" Full HD Pro LED TV wall mounted",
      "product_mpn": "KDL-40RD453",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100411,
      "product_name": "40\" Full HD Pro LED TV with stand",
      "product_model": "40\" Full HD Pro LED TV with stand",
      "product_mpn": "KDL-40RD453",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100412,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KDL-55W755C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100413,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KDL-55W755C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100414,
      "product_name": "43\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "43\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "KD-43XD8305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100415,
      "product_name": "43\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "43\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "KD-43XD8305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100416,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD-49XD8305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100417,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD-49XD8305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100418,
      "product_name": "55\" 4K HDR Android TV wall mounted",
      "product_model": "55\" 4K HDR Android TV wall mounted",
      "product_mpn": "KD-55XD8599",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100419,
      "product_name": "55\" 4K HDR Android TV with stand",
      "product_model": "55\" 4K HDR Android TV with stand",
      "product_mpn": "KD-55XD8599",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100420,
      "product_name": "65\" 4K HDR Android TV wall mounted",
      "product_model": "65\" 4K HDR Android TV wall mounted",
      "product_mpn": "KD-65XD8599",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100421,
      "product_name": "65\" 4K HDR Android TV with stand",
      "product_model": "65\" 4K HDR Android TV with stand",
      "product_mpn": "KD-65XD8599",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100422,
      "product_name": "43\" Smart 3D Full HD TV wall mounted",
      "product_model": "43\" Smart 3D Full HD TV wall mounted",
      "product_mpn": "KDL-43W807C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100423,
      "product_name": "43\" Smart 3D Full HD TV with stand",
      "product_model": "43\" Smart 3D Full HD TV with stand",
      "product_mpn": "KDL-43W807C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100424,
      "product_name": "65\" Full HD Smart LED TV wall mounted",
      "product_model": "65\" Full HD Smart LED TV wall mounted",
      "product_mpn": "KDL-65W859C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100425,
      "product_name": "65\" Full HD Smart LED TV with stand",
      "product_model": "65\" Full HD Smart LED TV with stand",
      "product_mpn": "KDL-65W859C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100426,
      "product_name": "65\" Full HD Smart LED TV wall mounted",
      "product_model": "65\" Full HD Smart LED TV wall mounted",
      "product_mpn": "KDL-65W857C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100427,
      "product_name": "65\" Full HD Smart LED TV with stand",
      "product_model": "65\" Full HD Smart LED TV with stand",
      "product_mpn": "KDL-65W857C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100428,
      "product_name": "75\" Full HD Smart LED TV wall mounted",
      "product_model": "75\" Full HD Smart LED TV wall mounted",
      "product_mpn": "KDL-75W855C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100429,
      "product_name": "75\" Full HD Smart LED TV with stand",
      "product_model": "75\" Full HD Smart LED TV with stand",
      "product_mpn": "KDL-75W855C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100430,
      "product_name": "40\" Ultra HD 4K Smart LED TV wall mounted",
      "product_model": "40\" Ultra HD 4K Smart LED TV wall mounted",
      "product_mpn": "KDL-40WD653",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100431,
      "product_name": "40\" Ultra HD 4K Smart LED TV with stand",
      "product_model": "40\" Ultra HD 4K Smart LED TV with stand",
      "product_mpn": "KDL-40WD653",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100432,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-43XE8004",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100433,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-43XE8004",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100434,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-49XE8004",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100435,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-49XE8004",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100436,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-55XE8596",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100437,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-55XE8596",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100438,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-65XE8596",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100439,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-65XE8596",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100440,
      "product_name": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD-75XE8596",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100441,
      "product_name": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD-75XE8596",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100442,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-55XE8577",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100443,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-55XE8577",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100444,
      "product_name": "49\" Full HD Smart LED TV wall mounted",
      "product_model": "49\" Full HD Smart LED TV wall mounted",
      "product_mpn": "KDL-49WD752",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100445,
      "product_name": "49\" Full HD Smart LED TV with stand",
      "product_model": "49\" Full HD Smart LED TV with stand",
      "product_mpn": "KDL-49WD752",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100446,
      "product_name": "49\" Full HD Smart LED TV wall mounted",
      "product_model": "49\" Full HD Smart LED TV wall mounted",
      "product_mpn": "KDL-49WD754",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100447,
      "product_name": "49\" Full HD Smart LED TV with stand",
      "product_model": "49\" Full HD Smart LED TV with stand",
      "product_mpn": "KDL-49WD754",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100448,
      "product_name": "32\" Smart HDR LED TV wall mounted",
      "product_model": "32\" Smart HDR LED TV wall mounted",
      "product_mpn": "KDL-32WE613",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100449,
      "product_name": "32\" Smart HDR LED TV with stand",
      "product_model": "32\" Smart HDR LED TV with stand",
      "product_mpn": "KDL-32WE613",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100450,
      "product_name": "55\" Ultra HD 4K LCD TV wall mounted",
      "product_model": "55\" Ultra HD 4K LCD TV wall mounted",
      "product_mpn": "KD-55X8509C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100451,
      "product_name": "55\" Ultra HD 4K LCD TV with stand",
      "product_model": "55\" Ultra HD 4K LCD TV with stand",
      "product_mpn": "KD-55X8509C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100452,
      "product_name": "75\" Ultra HD 4K LCD TV wall mounted",
      "product_model": "75\" Ultra HD 4K LCD TV wall mounted",
      "product_mpn": "KD-75X9105C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100453,
      "product_name": "75\" Ultra HD 4K LCD TV with stand",
      "product_model": "75\" Ultra HD 4K LCD TV with stand",
      "product_mpn": "KD-75X9105C",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100454,
      "product_name": "49\" 4K HDR Android TV wall mounted",
      "product_model": "49\" 4K HDR Android TV wall mounted",
      "product_mpn": "KD-49XD8088",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100455,
      "product_name": "49\" 4K HDR Android TV with stand",
      "product_model": "49\" 4K HDR Android TV with stand",
      "product_mpn": "KD-49XD8088",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100456,
      "product_name": "55\" 4K HDR Android TV wall mounted",
      "product_model": "55\" 4K HDR Android TV wall mounted",
      "product_mpn": "KD-55XD8577",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100457,
      "product_name": "55\" 4K HDR Android TV with stand",
      "product_model": "55\" 4K HDR Android TV with stand",
      "product_mpn": "KD-55XD8577",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100458,
      "product_name": "55\" 4K HDR Android TV wall mounted",
      "product_model": "55\" 4K HDR Android TV wall mounted",
      "product_mpn": "KD-55XD9305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100459,
      "product_name": "55\" 4K HDR Android TV with stand",
      "product_model": "55\" 4K HDR Android TV with stand",
      "product_mpn": "KD-55XD9305",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100460,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-43XE8396",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100461,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-43XE8396",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100462,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-49XE8396",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100463,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-49XE8396",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100464,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TVwall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TVwall mounted",
      "product_mpn": "KD-55XE8396",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100465,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-55XE8396",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100466,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-43XE8077",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100467,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-43XE8077",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100468,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-49XE8077",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100469,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD-49XE8077",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100470,
      "product_name": "40\" Smart HDR LED TV wall mounted",
      "product_model": "40\" Smart HDR LED TV wall mounted",
      "product_mpn": "KDL-40WE663",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100471,
      "product_name": "40\" Smart HDR LED TV with stand",
      "product_model": "40\" Smart HDR LED TV with stand",
      "product_mpn": "KDL-40WE663",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100472,
      "product_name": "49\" Smart HDR LED TV wall mounted",
      "product_model": "49\" Smart HDR LED TV wall mounted",
      "product_mpn": "KDL-49WE663",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100473,
      "product_name": "49\" Smart HDR LED TV with stand",
      "product_model": "49\" Smart HDR LED TV with stand",
      "product_mpn": "KDL-49WE663",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100474,
      "product_name": "32\" HDR LED TV wall mounted",
      "product_model": "32\" HDR LED TV wall mounted",
      "product_mpn": "KDL-32RE403",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100475,
      "product_name": "32\" HDR LED TV with stand",
      "product_model": "32\" HDR LED TV with stand",
      "product_mpn": "KDL-32RE403",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100476,
      "product_name": "40\" LED TV wall mounted",
      "product_model": "40\" LED TV wall mounted",
      "product_mpn": "KDL-40RE453",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100477,
      "product_name": "40\" LED TV with stand",
      "product_model": "40\" LED TV with stand",
      "product_mpn": "KDL-40RE453",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100478,
      "product_name": "32\" Full HD Smart TV wall mounted",
      "product_model": "32\" Full HD Smart TV wall mounted",
      "product_mpn": "UE32K5500AK",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100479,
      "product_name": "32\" Full HD Smart TV with stand",
      "product_model": "32\" Full HD Smart TV with stand",
      "product_mpn": "UE32K5500AK",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100480,
      "product_name": "32\" Full HD Smart TV wall mounted",
      "product_model": "32\" Full HD Smart TV wall mounted",
      "product_mpn": "UE32M5520AK",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100481,
      "product_name": "32\" Full HD Smart TV with stand",
      "product_model": "32\" Full HD Smart TV with stand",
      "product_mpn": "UE32M5520AK",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100482,
      "product_name": "55\" Full HD Smart TV wall mounted",
      "product_model": "55\" Full HD Smart TV wall mounted",
      "product_mpn": "UE55M5520AK",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100483,
      "product_name": "55\" Full HD Smart TV with stand",
      "product_model": "55\" Full HD Smart TV with stand",
      "product_mpn": "UE55M5520AK",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100484,
      "product_name": "40\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "40\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE40KU6100K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100485,
      "product_name": "40\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "40\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE40KU6100K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100486,
      "product_name": "49\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE49KU6100K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100487,
      "product_name": "49\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "49\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE49KU6100K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100488,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE40MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100489,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "UE40MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100490,
      "product_name": "50\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "50\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE50MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100491,
      "product_name": "50\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "50\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE50MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100492,
      "product_name": "55\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE55MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100493,
      "product_name": "55\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "55\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE55MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100494,
      "product_name": "65\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR Smart TV wall mounted",
      "product_mpn": "UE65MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100495,
      "product_name": "65\" Ultra HD 4K HDR Smart TV with stand",
      "product_model": "65\" Ultra HD 4K HDR Smart TV with stand",
      "product_mpn": "UE65MU6120K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100496,
      "product_name": "43\" Ultra HD 4K Smart LED TV wall mounted",
      "product_model": "43\" Ultra HD 4K Smart LED TV wall mounted",
      "product_mpn": "UE43KU6000K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100497,
      "product_name": "43\" Ultra HD 4K Smart LED TV with stand",
      "product_model": "43\" Ultra HD 4K Smart LED TV with stand",
      "product_mpn": "UE43KU6000K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100498,
      "product_name": "70\" Ultra HD 4K Smart LED TV wall mounted",
      "product_model": "70\" Ultra HD 4K Smart LED TV wall mounted",
      "product_mpn": "UE70KU6000K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100499,
      "product_name": "70\" Ultra HD 4K Smart LED TV with stand",
      "product_model": "70\" Ultra HD 4K Smart LED TV with stand",
      "product_mpn": "UE70KU6000K",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100500,
      "product_name": "43\" Ultra HD 4K Smart LED TV wall mounted",
      "product_model": "43\" Ultra HD 4K Smart LED TV wall mounted",
      "product_mpn": "UE43KU6400U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100501,
      "product_name": "43\" Ultra HD 4K Smart LED TV with stand",
      "product_model": "43\" Ultra HD 4K Smart LED TV with stand",
      "product_mpn": "UE43KU6400U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100502,
      "product_name": "24\" Smart LED TV wall mounted",
      "product_model": "24\" Smart LED TV wall mounted",
      "product_mpn": "TX-24ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100503,
      "product_name": "24\" Smart LED TV with stand",
      "product_model": "24\" Smart LED TV with stand",
      "product_mpn": "TX-24ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100504,
      "product_name": "32\" Smart LED TV wall mounted",
      "product_model": "32\" Smart LED TV wall mounted",
      "product_mpn": "TX-32ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100505,
      "product_name": "32\" Smart LED TV with stand",
      "product_model": "32\" Smart LED TV with stand",
      "product_mpn": "TX-32ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100506,
      "product_name": "40\" Smart LED TV wall mounted",
      "product_model": "40\" Smart LED TV wall mounted",
      "product_mpn": "TX-40ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100507,
      "product_name": "40\" Smart LED TV with stand",
      "product_model": "40\" Smart LED TV with stand",
      "product_mpn": "TX-40ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100508,
      "product_name": "49\" Full HD Smart LED TV wall mounted",
      "product_model": "49\" Full HD Smart LED TV wall mounted",
      "product_mpn": "TX-49ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100509,
      "product_name": "49\" Full HD Smart LED TV with stand",
      "product_model": "49\" Full HD Smart LED TV with stand",
      "product_mpn": "TX-49ES500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100510,
      "product_name": "40\" Smart LED TV wall mounted",
      "product_model": "40\" Smart LED TV wall mounted",
      "product_mpn": "TX-40ES400B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100511,
      "product_name": "40\" Smart LED TV with stand",
      "product_model": "40\" Smart LED TV with stand",
      "product_mpn": "TX-40ES400B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100512,
      "product_name": "49\" Smart LED TV wall mounted",
      "product_model": "49\" Smart LED TV wall mounted",
      "product_mpn": "TX-49ES400B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100513,
      "product_name": "49\" Smart LED TV with stand",
      "product_model": "49\" Smart LED TV with stand",
      "product_mpn": "TX-49ES400B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100514,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-40EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100515,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-40EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100516,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "TX-49EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100517,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "TX-49EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100518,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "TX-55EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100519,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "TX-55EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100520,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-65EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100521,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-65EX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100522,
      "product_name": "40\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_model": "40\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_mpn": "TX-40DS500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100523,
      "product_name": "40\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_model": "40\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_mpn": "TX-40DS500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100524,
      "product_name": "55\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_mpn": "TX-55DS500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100525,
      "product_name": "55\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_mpn": "TX-55DS500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100526,
      "product_name": "49\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_mpn": "TX-49DX650B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100527,
      "product_name": "49\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_mpn": "TX-49DX650B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100528,
      "product_name": "55\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR Smart LED TV wall mounted",
      "product_mpn": "TX-55DX650B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100529,
      "product_name": "55\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR Smart LED TV with stand",
      "product_mpn": "TX-55DX650B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100530,
      "product_name": "40\" LED TV wall Mounted",
      "product_model": "40\" LED TV wall Mounted",
      "product_mpn": "40/133O",
      "product_brand": "Blaupunkt",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100531,
      "product_name": "40\" LED TV with Stand",
      "product_model": "40\" LED TV with Stand",
      "product_mpn": "40/133O",
      "product_brand": "Blaupunkt",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100532,
      "product_name": "40\" Smart LED TV with stand",
      "product_model": "40\" Smart LED TV with stand",
      "product_mpn": "40/138MXN",
      "product_brand": "Blaupunkt",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100533,
      "product_name": "40\" Smart LED TV wall mounted",
      "product_model": "40\" Smart LED TV wall mounted",
      "product_mpn": "40/138MXN",
      "product_brand": "Blaupunkt",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100534,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "H43N5700UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100535,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "H43N5700",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100536,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "H55N5500UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100537,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "H55N5500UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100538,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "H55N6800UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100539,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "H55N6800UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100540,
      "product_name": "65\" Smart 4K Ultra HD TV",
      "product_model": "65\" Smart 4K Ultra HD TV",
      "product_mpn": "H65N5300UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100541,
      "product_name": "65\" Smart 4K Ultra HD TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD TV wall mounted",
      "product_mpn": "H65N5300UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100542,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "H65N6800UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100543,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "H65N6800UK",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100544,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "KD-49XE7002",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100545,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD-49XE7002",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100550,
      "product_name": "20\" LED TV 720p HD",
      "product_model": "20\" LED TV 720p HD",
      "product_mpn": "L20HE15",
      "product_brand": "Logik",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100551,
      "product_name": "20\" LED TV 720p HD wall mounted",
      "product_model": "20\" LED TV 720p HD wall mounted",
      "product_mpn": "L20HE15",
      "product_brand": "Logik",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100552,
      "product_name": "22\" Full HD 1080p LED TV mounted",
      "product_model": "22\" Full HD 1080p LED TV mounted",
      "product_mpn": "L22FE14",
      "product_brand": "Logik",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100553,
      "product_name": "22\" Full HD 1080p LED TV with Stand",
      "product_model": "22\" Full HD 1080p LED TV with Stand",
      "product_mpn": "L22FE14",
      "product_brand": "Logik",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100554,
      "product_name": "24\" LED TV with Built-in DVD Player",
      "product_model": "24\" LED TV with Built-in DVD Player",
      "product_mpn": "L24HEDW15",
      "product_brand": "Logik",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100555,
      "product_name": "24\" LED TV with Built-in DVD Player wall mounted",
      "product_model": "24\" LED TV with Built-in DVD Player wall mounted",
      "product_mpn": "L24HEDW15",
      "product_brand": "Logik",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100556,
      "product_name": "49\" Full HD Smart LED TV with stand",
      "product_model": "49\" Full HD Smart LED TV with stand",
      "product_mpn": "LC-49CFG6352K",
      "product_brand": "Sharp",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100557,
      "product_name": "49\" Full HD Smart LED TV wall mounted",
      "product_model": "49\" Full HD Smart LED TV wall mounted",
      "product_mpn": "LC-49CFG6352K",
      "product_brand": "Sharp",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100558,
      "product_name": "55\" Smart Full HD LED TV",
      "product_model": "55\" Smart Full HD LED TV",
      "product_mpn": "LC-55CFG6241K",
      "product_brand": "Sharp",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100559,
      "product_name": "55\" Smart Full HD LED TV wall mounted",
      "product_model": "55\" Smart Full HD LED TV wall mounted",
      "product_mpn": "LC-55CFG6241K",
      "product_brand": "Sharp",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100560,
      "product_name": "24\" LED TV with Freeview HD",
      "product_model": "24\" LED TV with Freeview HD",
      "product_mpn": "LT-24C370",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100561,
      "product_name": "24\" LED TV with Freeview HD wall mounted",
      "product_model": "24\" LED TV with Freeview HD wall mounted",
      "product_mpn": "LT-24C370",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100562,
      "product_name": "32\" LED LCD TV 720p",
      "product_model": "32\" LED LCD TV 720p",
      "product_mpn": "LT-32C360",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100563,
      "product_name": "32\" LED LCD TV 720p LED TV wall mounted",
      "product_model": "32\" LED LCD TV 720p LED TV wall mounted",
      "product_mpn": "LT-32C360",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100564,
      "product_name": "32\" LED TV 720p Wall mounted",
      "product_model": "32\" LED TV 720p Wall mounted",
      "product_mpn": "LT-32C473",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100565,
      "product_name": "32\" LED TV 720p with stand",
      "product_model": "32\" LED TV 720p with stand",
      "product_mpn": "LT-32C473",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100566,
      "product_name": "32\" Smart LED TV with Built-in DVD Player",
      "product_model": "32\" Smart LED TV with Built-in DVD Player",
      "product_mpn": "LT-32C675",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100567,
      "product_name": "32\" Smart LED TV with Built-in DVD Player wall mounted",
      "product_model": "32\" Smart LED TV with Built-in DVD Player wall mounted",
      "product_mpn": "LT-32C675",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100568,
      "product_name": "32\" Smart LED TV with Built-in DVD Player",
      "product_model": "32\" Smart LED TV with Built-in DVD Player",
      "product_mpn": "LT-32C676",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100569,
      "product_name": "32\" Smart LED TV with Built-in DVD Player wall mounted",
      "product_model": "32\" Smart LED TV with Built-in DVD Player wall mounted",
      "product_mpn": "LT-32C676",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100570,
      "product_name": "40\" Full HD LED TV",
      "product_model": "40\" Full HD LED TV",
      "product_mpn": "LT-40C550",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100571,
      "product_name": "40\" Full HD LED TV wall mounted",
      "product_model": "40\" Full HD LED TV wall mounted",
      "product_mpn": "LT-40C550",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100572,
      "product_name": "55\" Smart LCD TV",
      "product_model": "55\" Smart LCD TV",
      "product_mpn": "LT-55C760",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100573,
      "product_name": "55\" Smart LCD TV wall mounted",
      "product_model": "55\" Smart LCD TV wall mounted",
      "product_mpn": "LT-55C760",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100574,
      "product_name": "55\" Smart 4K Ultra HD LED TV",
      "product_model": "55\" Smart 4K Ultra HD LED TV",
      "product_mpn": "LT-55C870",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100575,
      "product_name": "55\" Smart 4K Ultra HD LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD LED TV wall mounted",
      "product_mpn": "LT-55C870",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100576,
      "product_name": "32\" Smart Curved LED TV",
      "product_model": "32\" Smart Curved LED TV",
      "product_mpn": "LV32F390SEXXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100577,
      "product_name": "32\" Smart Curved LED TV wall mounted",
      "product_model": "32\" Smart Curved LED TV wall mounted",
      "product_mpn": "LV32F390SEXXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100578,
      "product_name": "22\" Full HD LED TV",
      "product_model": "22\" Full HD LED TV",
      "product_mpn": "T22E310",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100579,
      "product_name": "22\" Full HD LED TV wall mounted",
      "product_model": "22\" Full HD LED TV wall mounted",
      "product_mpn": "T22E310",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100580,
      "product_name": "32\" Smart LED TV",
      "product_model": "32\" Smart LED TV",
      "product_mpn": "T32E390SX",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100581,
      "product_name": "32\" Smart LED TV wall mounted",
      "product_model": "32\" Smart LED TV wall mounted",
      "product_mpn": "T32E390SX",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100582,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "TX-55EX580B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100583,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-55EX580B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100584,
      "product_name": "32\" Smart LED TV",
      "product_model": "32\" Smart LED TV",
      "product_mpn": "UE32M5520",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100585,
      "product_name": "32\" Smart LED TV wall mounted",
      "product_model": "32\" Smart LED TV wall mounted",
      "product_mpn": "UE32M5520",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100586,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "UE40MU6470U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100587,
      "product_name": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "40\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE40MU6470U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100589,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel",
      "product_mpn": "UE43LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100590,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel",
      "product_mpn": "UE43LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100592,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel wall mounted",
      "product_mpn": "UE43LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100593,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel wall mounted",
      "product_mpn": "UE43LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100594,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "UE43MU6100",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100595,
      "product_name": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "43\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE43MU6100",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100596,
      "product_name": "49\" Smart 4K Ultra HD HDR Curved LED TV",
      "product_model": "49\" Smart 4K Ultra HD HDR Curved LED TV",
      "product_mpn": "UE49MU6500",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100597,
      "product_name": "49\" Smart 4K Ultra HD HDR Curved LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR Curved LED TV wall mounted",
      "product_mpn": "UE49MU6500",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100598,
      "product_name": "49\" Smart 4K Ultra HD HDR Curved LED TV",
      "product_model": "49\" Smart 4K Ultra HD HDR Curved LED TV",
      "product_mpn": "UE49MU6670",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100599,
      "product_name": "49\" Smart 4K Ultra HD HDR Curved LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR Curved LED TV wall mounted",
      "product_mpn": "UE49MU6670",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100601,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel",
      "product_mpn": "UE55LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100602,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV & Walnut Bezel",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV & Walnut Bezel",
      "product_mpn": "UE55LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100603,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel",
      "product_mpn": "UE55LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100605,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode Beige Wood Bezel wall mounted",
      "product_mpn": "UE55LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100606,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV & Walnut Bezel wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV & Walnut Bezel wall mounted",
      "product_mpn": "UE55LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100607,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV The Frame Art Mode White Bezel wall mounted",
      "product_mpn": "UE55LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100608,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "UE55MU6470U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100609,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE55MU6470U",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100610,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV Art Mode The Frame Walnut Bezel",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV Art Mode The Frame Walnut Bezel",
      "product_mpn": "UE65LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100611,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "UE65LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100612,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV Art Mode The Frame Walnut Bezel wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV Art Mode The Frame Walnut Bezel wall mounted",
      "product_mpn": "UE65LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100613,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE65LS003",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100614,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV",
      "product_mpn": "UE65MU6400",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100615,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "UE65MU6400",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100618,
      "product_name": "32\" Curved LED TV",
      "product_model": "32\" Curved LED TV",
      "product_mpn": "V32F390",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100619,
      "product_name": "32\" Curved LED TV wall mounted",
      "product_model": "32\" Curved LED TV wall mounted",
      "product_mpn": "V32F390",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100620,
      "product_name": "65\" Super UHD 4K TV with stand",
      "product_model": "65\" Super UHD 4K TV with stand",
      "product_mpn": "65SK7900",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100621,
      "product_name": "65\" Super UHD 4K TV wall mounted",
      "product_model": "65\" Super UHD 4K TV wall mounted",
      "product_mpn": "65SK7900",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100622,
      "product_name": "49\" Curved UHD 4K Smart TV with Stand",
      "product_model": "49\" Curved UHD 4K Smart TV with Stand",
      "product_mpn": "UE49MU6205",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100623,
      "product_name": "49\" Curved UHD 4K Smart TV wall mounted",
      "product_model": "49\" Curved UHD 4K Smart TV wall mounted",
      "product_mpn": "UE49MU6205",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100624,
      "product_name": "32\" Smart TV with webOS",
      "product_model": "32\" Smart TV with webOS",
      "product_mpn": "32LK6100PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100625,
      "product_name": "32\" Smart TV with webOS Mounted",
      "product_model": "32\" Smart TV with webOS Mounted",
      "product_mpn": "32LK6100PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100626,
      "product_name": "43\" Smart TV with webOS",
      "product_model": "43\" Smart TV with webOS",
      "product_mpn": "43LK5900PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100627,
      "product_name": "43\" Smart TV with webOS Mounted",
      "product_model": "43\" Smart TV with webOS Mounted",
      "product_mpn": "43LK5900PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100628,
      "product_name": "43\" ULTRA HD 4K TV",
      "product_model": "43\" ULTRA HD 4K TV",
      "product_mpn": "43UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100629,
      "product_name": "43\" ULTRA HD 4K TV Mounted",
      "product_model": "43\" ULTRA HD 4K TV Mounted",
      "product_mpn": "43UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100630,
      "product_name": "43\" ULTRA HD 4K TV",
      "product_model": "43\" ULTRA HD 4K TV",
      "product_mpn": "43UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100631,
      "product_name": "43\" ULTRA HD 4K TV Mounted",
      "product_model": "43\" ULTRA HD 4K TV Mounted",
      "product_mpn": "43UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100632,
      "product_name": "43\" ULTRA HD 4K TV",
      "product_model": "43\" ULTRA HD 4K TV",
      "product_mpn": "43UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100633,
      "product_name": "43\" ULTRA HD 4K TV Mounted",
      "product_model": "43\" ULTRA HD 4K TV Mounted",
      "product_mpn": "43UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100634,
      "product_name": "43\" ULTRA HD 4K TV",
      "product_model": "43\" ULTRA HD 4K TV",
      "product_mpn": "43UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100635,
      "product_name": "43\" ULTRA HD 4K TV Mounted",
      "product_model": "43\" ULTRA HD 4K TV Mounted",
      "product_mpn": "43UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100636,
      "product_name": "49\" Smart TV with webOS",
      "product_model": "49\" Smart TV with webOS",
      "product_mpn": "49LK5900PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100637,
      "product_name": "49\" Smart TV with webOS Mounted",
      "product_model": "49\" Smart TV with webOS Mounted",
      "product_mpn": "49LK5900PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100638,
      "product_name": "49\" SUPER UHD TV",
      "product_model": "49\" SUPER UHD TV",
      "product_mpn": "49SK8000PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100639,
      "product_name": "49\" SUPER UHD TV Mounted",
      "product_model": "49\" SUPER UHD TV Mounted",
      "product_mpn": "49SK8000PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100640,
      "product_name": "49\" SUPER Ultra HD TV",
      "product_model": "49\" SUPER Ultra HD TV",
      "product_mpn": "49SK8500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100641,
      "product_name": "49\" SUPER Ultra HD TV Mounted",
      "product_model": "49\" SUPER Ultra HD TV Mounted",
      "product_mpn": "49SK8500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100642,
      "product_name": "49\" ULTRA HD 4K TV",
      "product_model": "49\" ULTRA HD 4K TV",
      "product_mpn": "49UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100643,
      "product_name": "49\" ULTRA HD 4K TV Mounted",
      "product_model": "49\" ULTRA HD 4K TV Mounted",
      "product_mpn": "49UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100644,
      "product_name": "49\" ULTRA HD 4K TV",
      "product_model": "49\" ULTRA HD 4K TV",
      "product_mpn": "49UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100645,
      "product_name": "49\" ULTRA HD 4K TV Mounted",
      "product_model": "49\" ULTRA HD 4K TV Mounted",
      "product_mpn": "49UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100646,
      "product_name": "50\" ULTRA HD 4K TV",
      "product_model": "50\" ULTRA HD 4K TV",
      "product_mpn": "50UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100647,
      "product_name": "50\" ULTRA HD 4K TV Mounted",
      "product_model": "50\" ULTRA HD 4K TV Mounted",
      "product_mpn": "50UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100648,
      "product_name": "50\" ULTRA HD 4K TV",
      "product_model": "50\" ULTRA HD 4K TV",
      "product_mpn": "50UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100649,
      "product_name": "50\" ULTRA HD 4K TV Mounted",
      "product_model": "50\" ULTRA HD 4K TV Mounted",
      "product_mpn": "50UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100650,
      "product_name": "50\" ULTRA HD 4K TV",
      "product_model": "50\" ULTRA HD 4K TV",
      "product_mpn": "50UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100651,
      "product_name": "50\" ULTRA HD 4K TV Mounted",
      "product_model": "50\" ULTRA HD 4K TV Mounted",
      "product_mpn": "50UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100652,
      "product_name": "55\" SUPER UHD TV",
      "product_model": "55\" SUPER UHD TV",
      "product_mpn": "55SK8000PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100653,
      "product_name": "55\" SUPER UHD TV Mounted",
      "product_model": "55\" SUPER UHD TV Mounted",
      "product_mpn": "55SK8000PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100654,
      "product_name": "55\" SUPER UHD TV",
      "product_model": "55\" SUPER UHD TV",
      "product_mpn": "55SK8100PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100655,
      "product_name": "55\" SUPER Ultra HD TV Mounted",
      "product_model": "55\" SUPER Ultra HD TV Mounted",
      "product_mpn": "55SK8100PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100656,
      "product_name": "55\" SUPER Ultra HD TV",
      "product_model": "55\" SUPER Ultra HD TV",
      "product_mpn": "55SK8500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100657,
      "product_name": "55\" SUPER Ultra HD TV Mounted",
      "product_model": "55\" SUPER Ultra HD TV Mounted",
      "product_mpn": "55SK8500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100658,
      "product_name": "55\" SUPER Ultra HD TV",
      "product_model": "55\" SUPER Ultra HD TV",
      "product_mpn": "55SK9500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100659,
      "product_name": "55\" SUPER Ultra HD TV Mounted",
      "product_model": "55\" SUPER Ultra HD TV Mounted",
      "product_mpn": "55SK9500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100660,
      "product_name": "55\" ULTRA HD 4K TV",
      "product_model": "55\" ULTRA HD 4K TV",
      "product_mpn": "55UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100661,
      "product_name": "55\" ULTRA HD 4K TV Mounted",
      "product_model": "55\" ULTRA HD 4K TV Mounted",
      "product_mpn": "55UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1006621000,
      "product_name": "55\" ULTRA HD 4K TV",
      "product_model": "55\" ULTRA HD 4K TV",
      "product_mpn": "55UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100663,
      "product_name": "55\" ULTRA HD 4K TV Mounted",
      "product_model": "55\" ULTRA HD 4K TV Mounted",
      "product_mpn": "55UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100664,
      "product_name": "55\" ULTRA HD 4K TV",
      "product_model": "55\" ULTRA HD 4K TV",
      "product_mpn": "55UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100665,
      "product_name": "55\" ULTRA HD 4K TV Mounted",
      "product_model": "55\" ULTRA HD 4K TV Mounted",
      "product_mpn": "55UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100666,
      "product_name": "55\" ULTRA HD 4K TV",
      "product_model": "55\" ULTRA HD 4K TV",
      "product_mpn": "55UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100667,
      "product_name": "55\" ULTRA HD 4K TV Mounted",
      "product_model": "55\" ULTRA HD 4K TV Mounted",
      "product_mpn": "55UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100668,
      "product_name": "65\" SUPER UHD TV",
      "product_model": "65\" SUPER UHD TV",
      "product_mpn": "65SK8000PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100669,
      "product_name": "65\" SUPER UHD TV Mounted",
      "product_model": "65\" SUPER UHD TV Mounted",
      "product_mpn": "65SK8000PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100670,
      "product_name": "65\" SUPER Ultra HD TV",
      "product_model": "65\" SUPER Ultra HD TV",
      "product_mpn": "65SK8100PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100671,
      "product_name": "65\" SUPER Ultra HD TV Mounted",
      "product_model": "65\" SUPER Ultra HD TV Mounted",
      "product_mpn": "65SK8100PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100672,
      "product_name": "65\" SUPER Ultra HD TV",
      "product_model": "65\" SUPER Ultra HD TV",
      "product_mpn": "65SK8500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100673,
      "product_name": "65\" SUPER Ultra HD TV Mounted",
      "product_model": "65\" SUPER Ultra HD TV Mounted",
      "product_mpn": "65SK8500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100674,
      "product_name": "65\" SUPER Ultra HD TV",
      "product_model": "65\" SUPER Ultra HD TV",
      "product_mpn": "65SK9500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100675,
      "product_name": "65\" SUPER Ultra HD TV Mounted",
      "product_model": "65\" SUPER Ultra HD TV Mounted",
      "product_mpn": "65SK9500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100676,
      "product_name": "65\" ULTRA HD 4K TV",
      "product_model": "65\" ULTRA HD 4K TV",
      "product_mpn": "65UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100677,
      "product_name": "65\" ULTRA HD 4K TV Mounted",
      "product_model": "65\" ULTRA HD 4K TV Mounted",
      "product_mpn": "65UK6300PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100678,
      "product_name": "65\" ULTRA HD 4K TV",
      "product_model": "65\" ULTRA HD 4K TV",
      "product_mpn": "65UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100679,
      "product_name": "65\" ULTRA HD 4K TV Mounted",
      "product_model": "65\" ULTRA HD 4K TV Mounted",
      "product_mpn": "65UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100680,
      "product_name": "65\" ULTRA HD 4K TV",
      "product_model": "65\" ULTRA HD 4K TV",
      "product_mpn": "65UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100681,
      "product_name": "65\" ULTRA HD 4K TV Mounted",
      "product_model": "65\" ULTRA HD 4K TV Mounted",
      "product_mpn": "65UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100682,
      "product_name": "65\" ULTRA HD 4K TV",
      "product_model": "65\" ULTRA HD 4K TV",
      "product_mpn": "65UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100683,
      "product_name": "65\" ULTRA HD 4K TV Mounted",
      "product_model": "65\" ULTRA HD 4K TV Mounted",
      "product_mpn": "65UK6950PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100684,
      "product_name": "70\" ULTRA HD 4K TV",
      "product_model": "70\" ULTRA HD 4K TV",
      "product_mpn": "70UK6950PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100685,
      "product_name": "70\" ULTRA HD 4K TV Mounted",
      "product_model": "70\" ULTRA HD 4K TV Mounted",
      "product_mpn": "70UK6950PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100686,
      "product_name": "75\" SUPER Ultra HD TV",
      "product_model": "75\" SUPER Ultra HD TV",
      "product_mpn": "75SK8100PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100687,
      "product_name": "75\" SUPER Ultra HD TV Mounted",
      "product_model": "75\" SUPER Ultra HD TV Mounted",
      "product_mpn": "75SK8100PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100688,
      "product_name": "86\" ULTRA HD 4K TV",
      "product_model": "86\" ULTRA HD 4K TV",
      "product_mpn": "86UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100689,
      "product_name": "86\" ULTRA HD 4K TV Mounted",
      "product_model": "86\" ULTRA HD 4K TV Mounted",
      "product_mpn": "86UK6500PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100690,
      "product_name": "55\" OLED TV",
      "product_model": "55\" OLED TV",
      "product_mpn": "OLED55B8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100691,
      "product_name": "55\" OLED TV Mounted",
      "product_model": "55\" OLED TV Mounted",
      "product_mpn": "OLED55B8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100692,
      "product_name": "55\" OLED TV",
      "product_model": "55\" OLED TV",
      "product_mpn": "OLED55C8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100693,
      "product_name": "55\" OLED TV Mounted",
      "product_model": "55\" OLED TV Mounted",
      "product_mpn": "OLED55C8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100694,
      "product_name": "55\" OLED TV",
      "product_model": "55\" OLED TV",
      "product_mpn": "OLED55E8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100695,
      "product_name": "55\" OLED TV Mounted",
      "product_model": "55\" OLED TV Mounted",
      "product_mpn": "OLED55E8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100696,
      "product_name": "65\" OLED TV",
      "product_model": "65\" OLED TV",
      "product_mpn": "OLED65B8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100697,
      "product_name": "65\" OLED TV Mounted",
      "product_model": "65\" OLED TV Mounted",
      "product_mpn": "OLED65B8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100698,
      "product_name": "65\" OLED TV",
      "product_model": "65\" OLED TV",
      "product_mpn": "OLED65C8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100699,
      "product_name": "65\" OLED TV Mounted",
      "product_model": "65\" OLED TV Mounted",
      "product_mpn": "OLED65C8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100700,
      "product_name": "65\" OLED TV",
      "product_model": "65\" OLED TV",
      "product_mpn": "OLED65E8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100701,
      "product_name": "65\" OLED TV Mounted",
      "product_model": "65\" OLED TV Mounted",
      "product_mpn": "OLED65E8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100702,
      "product_name": "65\" SIGNATURE OLED 4K TV",
      "product_model": "65\" SIGNATURE OLED 4K TV",
      "product_mpn": "OLED65G8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100703,
      "product_name": "65\" SIGNATURE OLED 4K TV Mounted",
      "product_model": "65\" SIGNATURE OLED 4K TV Mounted",
      "product_mpn": "OLED65G8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100704,
      "product_name": "65\" SIGNATURE OLED 4K TV",
      "product_model": "65\" SIGNATURE OLED 4K TV",
      "product_mpn": "OLED65W8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100705,
      "product_name": "65\" SIGNATURE OLED 4K TV Mounted",
      "product_model": "65\" SIGNATURE OLED 4K TV Mounted",
      "product_mpn": "OLED65W8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100706,
      "product_name": "77\" OLED TV",
      "product_model": "77\" OLED TV",
      "product_mpn": "OLED77C8LLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100707,
      "product_name": "77\" OLED TV Mounted",
      "product_model": "77\" OLED TV Mounted",
      "product_mpn": "OLED77C8LLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100708,
      "product_name": "77\" SIGNATURE OLED 4K TV",
      "product_model": "77\" SIGNATURE OLED 4K TV",
      "product_mpn": "OLED77W8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100709,
      "product_name": "77\" SIGNATURE OLED 4K TV Mounted",
      "product_model": "77\" SIGNATURE OLED 4K TV Mounted",
      "product_mpn": "OLED77W8PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100710,
      "product_name": "75'' Ultra HD Premium HDR 2000 Smart 4K TV",
      "product_model": "75'' Ultra HD Premium HDR 2000 Smart 4K TV",
      "product_mpn": "QE75Q9FNAT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100711,
      "product_name": "75'' Ultra HD Premium HDR 2000 Smart 4K TV Mounted",
      "product_model": "75'' Ultra HD Premium HDR 2000 Smart 4K TV Mounted",
      "product_mpn": "QE75Q9FNAT",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100714,
      "product_name": "55\" Smart 4K Ultra HD OLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD OLED TV with stand",
      "product_mpn": "OLED55B7T",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100715,
      "product_name": "55\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD OLED TV wall mounted",
      "product_mpn": "OLED55B7T",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100716,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-65FX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100717,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-65FX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100718,
      "product_name": "65\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 3D 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-65FX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100719,
      "product_name": "65\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 3D 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-65FX750B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100720,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "TX-55FZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100721,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "TX-55FZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100722,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "TH-55FZ950U",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100723,
      "product_name": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "TH-55FZ950U",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100724,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "TX-65FZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100725,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "TX-65FZ952B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100726,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "TH-65EZ950U",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100728,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV with stand",
      "product_mpn": "TH-65FZ950U",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100729,
      "product_name": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR OLED TV wall mounted",
      "product_mpn": "TH-65FZ950U",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100730,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD49XF9005BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100731,
      "product_name": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "49\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD49XF9005BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100732,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD55XF9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100733,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD55XF9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100734,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD65XF9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100735,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD65XF9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100736,
      "product_name": "75\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_model": "75\" Ultra HD 4K Pro HDR LED TV with stand",
      "product_mpn": "KD75XF9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100737,
      "product_name": "75\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_model": "75\" Ultra HD 4K Pro HDR LED TV wall mounted",
      "product_mpn": "KD75XF9005",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100738,
      "product_name": "85\" ULTRA HD 4K TV with stand",
      "product_model": "85\" ULTRA HD 4K TV with stand",
      "product_mpn": "KD85X8500F",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100739,
      "product_name": "85\" ULTRA HD 4K TV wall mounted",
      "product_model": "85\" ULTRA HD 4K TV wall mounted",
      "product_mpn": "KD85X8500F",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100740,
      "product_name": "65\" Ultra HD 4K LED TV with stand",
      "product_model": "65\" Ultra HD 4K LED TV with stand",
      "product_mpn": "TH-65FX800Z",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100741,
      "product_name": "65\" Ultra HD 4K LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K LED TV wall mounted",
      "product_mpn": "TH-65FX800Z",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100742,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE49NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100743,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE49NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100744,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UA55NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100745,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UA55NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100746,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "TX-65FX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100747,
      "product_name": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "TX-65FX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100748,
      "product_name": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE65NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100749,
      "product_name": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE65NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100750,
      "product_name": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "UE75NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100751,
      "product_name": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "UE75NU8000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100752,
      "product_name": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE55Q7FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100753,
      "product_name": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE55Q7FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100754,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_mpn": "QE65Q7FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100755,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_mpn": "QE65Q7FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100756,
      "product_name": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE55Q8FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100757,
      "product_name": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE55Q8FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100758,
      "product_name": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QA55Q8FNAWXXY",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100759,
      "product_name": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QA55Q8FNAWXXY",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100760,
      "product_name": "65\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QE65Q8FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100761,
      "product_name": "65\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QE65Q8FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100762,
      "product_name": "65\" Ultra HD 4K HDR QLED TV with stand",
      "product_model": "65\" Ultra HD 4K HDR QLED TV with stand",
      "product_mpn": "QA65Q8FNAWXXY",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100763,
      "product_name": "65\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_model": "65\" Ultra HD 4K HDR QLED TV wall mounted",
      "product_mpn": "QA65Q8FNAWXXY",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100764,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_mpn": "QE65Q9FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100765,
      "product_name": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_model": "65\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_mpn": "QE65Q9FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100766,
      "product_name": "55\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR QLED TV with stand",
      "product_mpn": "QE55Q6FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100767,
      "product_name": "55\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR QLED TV wall mounted",
      "product_mpn": "QE55Q6FNATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100768,
      "product_name": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "75\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "KD75XF8596BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100769,
      "product_name": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "75\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "KD75XF8596BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100770,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV with stand",
      "product_mpn": "KD55XF8577SU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100771,
      "product_name": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_model": "55\" Smart 4K Ultra HD HDR LED TV wall mounted",
      "product_mpn": "KD55XF8577SU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100772,
      "product_name": "55\" Ultra HD 4K LCD TV with stand",
      "product_model": "55\" Ultra HD 4K LCD TV with stand",
      "product_mpn": "KD55X8500F",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100773,
      "product_name": "55\" Ultra HD 4K LCD TV wall mounted",
      "product_model": "55\" Ultra HD 4K LCD TV wall mounted",
      "product_mpn": "KD55X8500F",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100774,
      "product_name": "32\" Smart LED TV with stand",
      "product_model": "32\" Smart LED TV with stand",
      "product_mpn": "TH-32FS500Z",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100776,
      "product_name": "40\" Smart LED TV with stand",
      "product_model": "40\" Smart LED TV with stand",
      "product_mpn": "TH-40ES500A",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100778,
      "product_name": "49\" Full HD Smart LED TV with stand",
      "product_model": "49\" Full HD Smart LED TV with stand",
      "product_mpn": "TH-49ES500A",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100780,
      "product_name": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "49\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "TX-49FX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100781,
      "product_name": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "49\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "TX-49FX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100782,
      "product_name": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_model": "55\" Ultra HD 4K HDR LED TV with stand",
      "product_mpn": "TX-55FX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100783,
      "product_name": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_model": "55\" Ultra HD 4K HDR LED TV wall mounted",
      "product_mpn": "TX-55FX600B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100786,
      "product_name": "24\" LED TV with Freeview HD with stand",
      "product_model": "24\" LED TV with Freeview HD with stand",
      "product_mpn": "LT-24N370A",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100788,
      "product_name": "65\" SUPER UHD TV with stand",
      "product_model": "65\" SUPER UHD TV with stand",
      "product_mpn": "65SK8000PTA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100790,
      "product_name": "Ultra Slim 4K UHD TV with HDR PRO with Stand",
      "product_model": "Ultra Slim 4K UHD TV with HDR PRO with Stand",
      "product_mpn": "55DC760",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100791,
      "product_name": "Ultra Slim 4K UHD TV with HDR PRO Wallmounted",
      "product_model": "Ultra Slim 4K UHD TV with HDR PRO Wallmounted",
      "product_mpn": "55DC760",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100792,
      "product_name": "55\" Ultra-Slim 4K OLED Android TV with stand",
      "product_model": "55\" Ultra-Slim 4K OLED Android TV with stand",
      "product_mpn": "55OLED903/12",
      "product_brand": "Philips",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100793,
      "product_name": "55\" Ultra-Slim 4K OLED Android TV mounted",
      "product_model": "55\" Ultra-Slim 4K OLED Android TV mounted",
      "product_mpn": "55OLED903/12",
      "product_brand": "Philips",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100794,
      "product_name": "Q950R QLED 8K Smart TV with stand",
      "product_model": "Q950R QLED 8K Smart TV with stand",
      "product_mpn": "QE98Q950RB",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100795,
      "product_name": "Q950R QLED 8K Smart TV wall mounted",
      "product_model": "Q950R QLED 8K Smart TV wall mounted",
      "product_mpn": "QE98Q950RB",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100796,
      "product_name": "Q950R Flagship QLED 8K HDR 4000 Smart TV with stand",
      "product_model": "Q950R Flagship QLED 8K HDR 4000 Smart TV with stand",
      "product_mpn": "QE82Q950RBTXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100797,
      "product_name": "Q950R Flagship QLED 8K HDR 4000 Smart TV wall mounted",
      "product_model": "Q950R Flagship QLED 8K HDR 4000 Smart TV wall mounted",
      "product_mpn": "QE82Q950RBTXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100798,
      "product_name": "Q950R Flagship QLED 8K HDR 4000 Smart TV with stand",
      "product_model": "Q950R Flagship QLED 8K HDR 4000 Smart TV with stand",
      "product_mpn": "QE75Q950RBTXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100799,
      "product_name": "Q950R Flagship QLED 8K HDR 4000 Smart TV wall mounted",
      "product_model": "Q950R Flagship QLED 8K HDR 4000 Smart TV wall mounted",
      "product_mpn": "QE75Q950RBTXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100800,
      "product_name": "Q950R Flagship QLED 8K HDR 3000 Smart TV with stand",
      "product_model": "Q950R Flagship QLED 8K HDR 3000 Smart TV with stand",
      "product_mpn": "QE65Q950RBTXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100801,
      "product_name": "Q950R Flagship QLED 8K HDR 3000 Smart TV wall mounted",
      "product_model": "Q950R Flagship QLED 8K HDR 3000 Smart TV wall mounted",
      "product_mpn": "QE65Q950RBTXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100802,
      "product_name": "Q90R Flagship QLED 4K HDR 2000 Smart TV with stand",
      "product_model": "Q90R Flagship QLED 4K HDR 2000 Smart TV with stand",
      "product_mpn": "QE75Q90RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100803,
      "product_name": "Q90R Flagship QLED 4K HDR 2000 Smart TV wall mounted",
      "product_model": "Q90R Flagship QLED 4K HDR 2000 Smart TV wall mounted",
      "product_mpn": "QE75Q90RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100804,
      "product_name": "Q90R Flagship QLED 4K HDR 2000 Smart TV with stand",
      "product_model": "Q90R Flagship QLED 4K HDR 2000 Smart TV with stand",
      "product_mpn": "QE65Q90RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100805,
      "product_name": "Q90R Flagship QLED 4K HDR 2000 Smart TV wall mounted",
      "product_model": "Q90R Flagship QLED 4K HDR 2000 Smart TV wall mounted",
      "product_mpn": "QE65Q90RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100806,
      "product_name": "Q90R Flagship QLED 4K HDR 2000 Smart TV with stand",
      "product_model": "Q90R Flagship QLED 4K HDR 2000 Smart TV with stand",
      "product_mpn": "QE55Q90RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100807,
      "product_name": "Q90R Flagship QLED 4K HDR 2000 Smart TV wall mounted",
      "product_model": "Q90R Flagship QLED 4K HDR 2000 Smart TV wall mounted",
      "product_mpn": "QE55Q90RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100808,
      "product_name": "Q85R QLED 4K HDR 1500 Smart TV with stand",
      "product_model": "Q85R QLED 4K HDR 1500 Smart TV with stand",
      "product_mpn": "QE75Q85RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100809,
      "product_name": "Q85R QLED 4K HDR 1500 Smart TV wall mounted",
      "product_model": "Q85R QLED 4K HDR 1500 Smart TV wall mounted",
      "product_mpn": "QE75Q85RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100810,
      "product_name": "Q85R QLED 4K HDR 1500 Smart TV with stand",
      "product_model": "Q85R QLED 4K HDR 1500 Smart TV with stand",
      "product_mpn": "QE65Q85RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100811,
      "product_name": "Q85R QLED 4K HDR 1500 Smart TV wall mounted",
      "product_model": "Q85R QLED 4K HDR 1500 Smart TV wall mounted",
      "product_mpn": "QE65Q85RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100812,
      "product_name": "Q85R QLED 4K HDR 1500 Smart TV with stand",
      "product_model": "Q85R QLED 4K HDR 1500 Smart TV with stand",
      "product_mpn": "QE55Q85RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100813,
      "product_name": "Q85R QLED 4K HDR 1500 Smart TV wall mounted",
      "product_model": "Q85R QLED 4K HDR 1500 Smart TV wall mounted",
      "product_mpn": "QE55Q85RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100814,
      "product_name": "Q67R QLED 4K Quantum HDR Smart TV with stand",
      "product_model": "Q67R QLED 4K Quantum HDR Smart TV with stand",
      "product_mpn": "QE65Q67RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100815,
      "product_name": "Q67R QLED 4K Quantum HDR Smart TV wall mounted",
      "product_model": "Q67R QLED 4K Quantum HDR Smart TV wall mounted",
      "product_mpn": "QE65Q67RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100816,
      "product_name": "Q67R QLED 4K Quantum HDR Smart TV with stand",
      "product_model": "Q67R QLED 4K Quantum HDR Smart TV with stand",
      "product_mpn": "QE55Q67RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100817,
      "product_name": "Q67R QLED 4K Quantum HDR Smart TV wall mounted",
      "product_model": "Q67R QLED 4K Quantum HDR Smart TV wall mounted",
      "product_mpn": "QE55Q67RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100818,
      "product_name": "Q67R QLED 4K Quantum HDR Smart TV with stand",
      "product_model": "Q67R QLED 4K Quantum HDR Smart TV with stand",
      "product_mpn": "QE49Q67RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100819,
      "product_name": "Q67R QLED 4K Quantum HDR Smart TV wall mounted",
      "product_model": "Q67R QLED 4K Quantum HDR Smart TV wall mounted",
      "product_mpn": "QE49Q67RATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100820,
      "product_name": "LED",
      "product_model": "LED",
      "product_mpn": "32LM6300PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100821,
      "product_name": "LED",
      "product_model": "LED",
      "product_mpn": "32LM6300PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100822,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM8200PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100823,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM8200PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100824,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM9000PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100825,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM9000PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100826,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "55UM7610PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100827,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "55UM7610PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100828,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "70UM7450PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100829,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "70UM7450PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100830,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "75SM9000PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100831,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "75SM9000PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100832,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "75UM7110PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100833,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "75UM7110PLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100834,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65C9MLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100835,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65C9MLB",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100836,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED77C9PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100837,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED77C9PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100838,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "43UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100839,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "43UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100840,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "43UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100841,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "43UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100842,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM8500PLA.AEKD",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100843,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM8500PLA.AEKD",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100844,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM8600PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100845,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "49SM8600PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100846,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "50UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100847,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "50UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100848,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "50UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100849,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "50UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100850,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM8200PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100851,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM8200PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100852,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM8500PLA.AEKD",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100853,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM8500PLA.AEKD",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100854,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM8600PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100855,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM8600PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100856,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM9010PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100857,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM9010PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100858,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM9800PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100859,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "55SM9800PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100860,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "55UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100861,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "55UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100862,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM8200PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100863,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM8200PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100864,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM8500PLA.AEKD",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100865,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM8500PLA.AEKD",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100866,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM8600PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100867,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM8600PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100868,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM9010PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100869,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM9010PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100870,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM9800PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100871,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "65SM9800PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100872,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "65UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100873,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "65UM7450PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100874,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "65UM7610PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100875,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "65UM7610PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100876,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "75UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100877,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "75UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100878,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "86UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100879,
      "product_name": "UHD",
      "product_model": "UHD",
      "product_mpn": "86UM7600PLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100880,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED55B9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100881,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED55B9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100882,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED55C9MLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100883,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED55C9MLB.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100884,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED55E9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100885,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED55E9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100886,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65B9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100887,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65B9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100888,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65E9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100889,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65E9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100890,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65W9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100891,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED65W9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100892,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "75SM8610PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100893,
      "product_name": "NANO CELL",
      "product_model": "NANO CELL",
      "product_mpn": "75SM8610PLA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100894,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED77W9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100895,
      "product_name": "OLED",
      "product_model": "OLED",
      "product_mpn": "OLED77W9PLA.AEK",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100896,
      "product_name": "Téléviseur LED",
      "product_model": "Téléviseur LED",
      "product_mpn": "LED65-SC1000K",
      "product_brand": "Schneider",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100897,
      "product_name": "Téléviseur LED",
      "product_model": "Téléviseur LED",
      "product_mpn": "LED65-SC1000K",
      "product_brand": "Schneider",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100898,
      "product_name": "Master OLED Professional Edition GZ2000 Series 65inch",
      "product_model": "Master OLED Professional Edition GZ2000 Series 65inch",
      "product_mpn": "TX-65GZ2000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100899,
      "product_name": "Master OLED Professional Edition GZ2000 Series 65inch",
      "product_model": "Master OLED Professional Edition GZ2000 Series 65inch",
      "product_mpn": "TX-65GZ2000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100900,
      "product_name": "Master OLED GZ1500 Series 65inch",
      "product_model": "Master OLED GZ1500 Series 65inch",
      "product_mpn": "TX-65GZ1500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100901,
      "product_name": "Master OLED GZ1500 Series 65inch",
      "product_model": "Master OLED GZ1500 Series 65inch",
      "product_mpn": "TX-65GZ1500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100902,
      "product_name": "Master OLED GZ1000 Series 65inch",
      "product_model": "Master OLED GZ1000 Series 65inch",
      "product_mpn": "TX-65GZ1000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100903,
      "product_name": "Master OLED GZ1000 Series 65inch",
      "product_model": "Master OLED GZ1000 Series 65inch",
      "product_mpn": "TX-65GZ1000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100904,
      "product_name": "AG9",
      "product_model": "AG9",
      "product_mpn": "KD55AG9BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100905,
      "product_name": "AG9",
      "product_model": "AG9",
      "product_mpn": "KD55AG9BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100906,
      "product_name": "AG9",
      "product_model": "AG9",
      "product_mpn": "KD65AG9BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100907,
      "product_name": "AG9",
      "product_model": "AG9",
      "product_mpn": "KD65AG9BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100908,
      "product_name": "AG9",
      "product_model": "AG9",
      "product_mpn": "KD77AG9BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100909,
      "product_name": "AG9",
      "product_model": "AG9",
      "product_mpn": "KD77AG9BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100910,
      "product_name": "AG8",
      "product_model": "AG8",
      "product_mpn": "KD55AG8BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100911,
      "product_name": "AG8",
      "product_model": "AG8",
      "product_mpn": "KD55AG8BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100912,
      "product_name": "AG8",
      "product_model": "AG8",
      "product_mpn": "KD65AG8BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100913,
      "product_name": "AG8",
      "product_model": "AG8",
      "product_mpn": "KD65AG8BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100914,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD55XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100915,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD55XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100916,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD65XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100917,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD65XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100918,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD75XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100919,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD75XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100920,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD55XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100921,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD55XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100922,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD65XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100923,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD65XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100924,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD75XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100925,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD75XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100926,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD85XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100927,
      "product_name": "XG85",
      "product_model": "XG85",
      "product_mpn": "KD85XG8505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100940,
      "product_name": "H55O8B",
      "product_model": "H55O8B",
      "product_mpn": "H55O8B",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100941,
      "product_name": "H55O8B",
      "product_model": "H55O8B",
      "product_mpn": "H55O8B",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100942,
      "product_name": "H65U7B",
      "product_model": "H65U7B",
      "product_mpn": "H65U7B",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100943,
      "product_name": "H65U7B",
      "product_model": "H65U7B",
      "product_mpn": "H65U7B",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100944,
      "product_name": "H75B7510",
      "product_model": "H75B7510",
      "product_mpn": "H75B7510",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100945,
      "product_name": "H75B7510",
      "product_model": "H75B7510",
      "product_mpn": "H75B7510",
      "product_brand": "Hisense",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100946,
      "product_name": "Master OLED Professional Edition GZ2000 Series 55inch",
      "product_model": "Master OLED Professional Edition GZ2000 Series 55inch",
      "product_mpn": "TX-55GZ2000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100947,
      "product_name": "Master OLED Professional Edition GZ2000 Series 55inch",
      "product_model": "Master OLED Professional Edition GZ2000 Series 55inch",
      "product_mpn": "TX-55GZ2000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100948,
      "product_name": "Master OLED GZ1500 Series 55inch wall mounted",
      "product_model": "Master OLED GZ1500 Series 55inch wall mounted",
      "product_mpn": "TX-55GZ1500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100949,
      "product_name": "Master OLED GZ1500 Series 55inch with stand",
      "product_model": "Master OLED GZ1500 Series 55inch with stand",
      "product_mpn": "TX-55GZ1500B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100950,
      "product_name": "Master OLED GZ1000 Series 55inch wall mounted",
      "product_model": "Master OLED GZ1000 Series 55inch wall mounted",
      "product_mpn": "TX-55GZ1000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100951,
      "product_name": "Master OLED GZ1000 Series 55inch with stand",
      "product_model": "Master OLED GZ1000 Series 55inch with stand",
      "product_mpn": "TX-55GZ1000B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100952,
      "product_name": "Master OLED GZ950/960 Series 65inch wall mounted",
      "product_model": "Master OLED GZ950/960 Series 65inch wall mounted",
      "product_mpn": "TX-65GZ950B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100953,
      "product_name": "Master OLED GZ950/960 Series 65inch with stand",
      "product_model": "Master OLED GZ950/960 Series 65inch with stand",
      "product_mpn": "TX-65GZ950B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100954,
      "product_name": "Master OLED GZ950/960 Series 55inch wall mounted",
      "product_model": "Master OLED GZ950/960 Series 55inch wall mounted",
      "product_mpn": "TX-55GZ950B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100955,
      "product_name": "Master OLED GZ950/960 Series 55inch with stand",
      "product_model": "Master OLED GZ950/960 Series 55inch with stand",
      "product_mpn": "TX-55GZ950B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100956,
      "product_name": "Master LED LCD GX940/920 Series 75inch wall mounted",
      "product_model": "Master LED LCD GX940/920 Series 75inch wall mounted",
      "product_mpn": "TX-75GX920B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100957,
      "product_name": "Master LED LCD GX940/920 Series 75inch with stand",
      "product_model": "Master LED LCD GX940/920 Series 75inch with stand",
      "product_mpn": "TX-75GX920B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100958,
      "product_name": "Master LED LCD GX900 Series 65inch wall mounted",
      "product_model": "Master LED LCD GX900 Series 65inch wall mounted",
      "product_mpn": "TX-65GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100959,
      "product_name": "Master LED LCD GX900 Series 65inch with stand",
      "product_model": "Master LED LCD GX900 Series 65inch with stand",
      "product_mpn": "TX-65GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100960,
      "product_name": "Master LED LCD GX900 Series 55inch wall mounted",
      "product_model": "Master LED LCD GX900 Series 55inch wall mounted",
      "product_mpn": "TX-55GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100961,
      "product_name": "Master LED LCD GX900 Series 55inch with stand",
      "product_model": "Master LED LCD GX900 Series 55inch with stand",
      "product_mpn": "TX-55GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100962,
      "product_name": "Master LED LCD GX900 Series 49inch wall mounted",
      "product_model": "Master LED LCD GX900 Series 49inch wall mounted",
      "product_mpn": "TX-49GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100963,
      "product_name": "Master LED LCD GX900 Series 49inch with stand",
      "product_model": "Master LED LCD GX900 Series 49inch with stand",
      "product_mpn": "TX-49GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100964,
      "product_name": "Master LED LCD GX900 Series 43inch wall mounted",
      "product_model": "Master LED LCD GX900 Series 43inch wall mounted",
      "product_mpn": "TX-43GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100965,
      "product_name": "Master LED LCD GX900 Series 43inch with stand",
      "product_model": "Master LED LCD GX900 Series 43inch with stand",
      "product_mpn": "TX-43GXW904",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100966,
      "product_name": "Master LED LCD GX800/820 Series 65inch wall mounted",
      "product_model": "Master LED LCD GX800/820 Series 65inch wall mounted",
      "product_mpn": "TX-65GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100967,
      "product_name": "LED LCD GX800/820 Series 65inch with stand",
      "product_model": "LED LCD GX800/820 Series 65inch with stand",
      "product_mpn": "TX-65GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100968,
      "product_name": "Master LED LCD GX800/820 Series 58inch wall mounted",
      "product_model": "Master LED LCD GX800/820 Series 58inch wall mounted",
      "product_mpn": "TX-58GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100969,
      "product_name": "LED LCD GX800/820 Series 58inch with stand",
      "product_model": "LED LCD GX800/820 Series 58inch with stand",
      "product_mpn": "TX-58GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100970,
      "product_name": "Master LED LCD GX800/820 Series 50inch wall mounted",
      "product_model": "Master LED LCD GX800/820 Series 50inch wall mounted",
      "product_mpn": "TX-50GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100971,
      "product_name": "LED LCD GX800/820 Series 50inchwith stand",
      "product_model": "LED LCD GX800/820 Series 50inchwith stand",
      "product_mpn": "TX-50GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100972,
      "product_name": "Master LED LCD GX800/820 Series 40inch wall mounted",
      "product_model": "Master LED LCD GX800/820 Series 40inch wall mounted",
      "product_mpn": "TX-40GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100973,
      "product_name": "LED LCD GX800/820 Series 40inchwith stand",
      "product_model": "LED LCD GX800/820 Series 40inchwith stand",
      "product_mpn": "TX-40GX800B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100974,
      "product_name": "Master LED LCD GX810/880 Series 65inch wall mounted",
      "product_model": "Master LED LCD GX810/880 Series 65inch wall mounted",
      "product_mpn": "TX-65GX810E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100975,
      "product_name": "LED LCD GX810/880 Series 65inchwith stand",
      "product_model": "LED LCD GX810/880 Series 65inchwith stand",
      "product_mpn": "TX-65GX810E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100976,
      "product_name": "Master LED LCD GX810/880 Series 58inch wall mounted",
      "product_model": "Master LED LCD GX810/880 Series 58inch wall mounted",
      "product_mpn": "TX-58GX810E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100977,
      "product_name": "LED LCD GX810/880 Series 58inch with stand",
      "product_model": "LED LCD GX810/880 Series 58inch with stand",
      "product_mpn": "TX-58GX810E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100978,
      "product_name": "Master LED LCD GX830 Series 65inch wall mounted",
      "product_model": "Master LED LCD GX830 Series 65inch wall mounted",
      "product_mpn": "TX-65GX830E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100979,
      "product_name": "LED LCD GX830 Series 65inch with stand",
      "product_model": "LED LCD GX830 Series 65inch with stand",
      "product_mpn": "TX-65GX830E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100980,
      "product_name": "Master LED LCD GX830 Series 58inch wall mounted",
      "product_model": "Master LED LCD GX830 Series 58inch wall mounted",
      "product_mpn": "TX-58GX830E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100981,
      "product_name": "LED LCD GX830 Series 58inch with stand",
      "product_model": "LED LCD GX830 Series 58inch with stand",
      "product_mpn": "TX-58GX830E",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100982,
      "product_name": "Master LED LCD GX700 Series 65inch wall mounted",
      "product_model": "Master LED LCD GX700 Series 65inch wall mounted",
      "product_mpn": "TX-65GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100983,
      "product_name": "LED LCD GX700 Series 65inch with stand",
      "product_model": "LED LCD GX700 Series 65inch with stand",
      "product_mpn": "TX-65GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100984,
      "product_name": "Master LED LCD GX700 Series 58inch wall mounted",
      "product_model": "Master LED LCD GX700 Series 58inch wall mounted",
      "product_mpn": "TX-58GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100985,
      "product_name": "LED LCD GX700 Series 58inch with stand",
      "product_model": "LED LCD GX700 Series 58inch with stand",
      "product_mpn": "TX-58GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100986,
      "product_name": "Master LED LCD GX700 Series 50inch wall mounted",
      "product_model": "Master LED LCD GX700 Series 50inch wall mounted",
      "product_mpn": "TX-50GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100987,
      "product_name": "LED LCD GX700 Series 50inch with stand",
      "product_model": "LED LCD GX700 Series 50inch with stand",
      "product_mpn": "TX-50GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100988,
      "product_name": "Master LED LCD GX700 Series 40inch wall mounted",
      "product_model": "Master LED LCD GX700 Series 40inch wall mounted",
      "product_mpn": "TX-40GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100989,
      "product_name": "LED LCD GX700 Series 40inch with stand",
      "product_model": "LED LCD GX700 Series 40inch with stand",
      "product_mpn": "TX-40GX700B",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100990,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD85XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 100991,
      "product_name": "XG95",
      "product_model": "XG95",
      "product_mpn": "KD85XG9505BU",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101000,
      "product_name": "XH95 65\"",
      "product_model": "XH95 65\"",
      "product_mpn": "KD65XH9505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101001,
      "product_name": "XH95 65\"",
      "product_model": "XH95 65\"",
      "product_mpn": "KD65XH9505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101003,
      "product_name": "X10",
      "product_model": "X10",
      "product_mpn": "X10",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101005,
      "product_name": "X91",
      "product_model": "X91",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101013,
      "product_name": "65Q950T",
      "product_model": "65Q950T",
      "product_mpn": "65Q950T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101017,
      "product_name": "55Q70T",
      "product_model": "55Q70T",
      "product_mpn": "55Q70T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101019,
      "product_name": "49Q80T",
      "product_model": "49Q80T",
      "product_mpn": "49Q80T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101021,
      "product_name": "55Q95T",
      "product_model": "55Q95T",
      "product_mpn": "55Q95T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101022,
      "product_name": "The Frame: 43LS03T",
      "product_model": "The Frame: 43LS03T",
      "product_mpn": "43LS03T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101025,
      "product_name": "The Serif: 49LS01TA",
      "product_model": "The Serif: 49LS01TA",
      "product_mpn": "49LS01TA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101026,
      "product_name": "XH95 75\"",
      "product_model": "XH95 75\"",
      "product_mpn": "KD75XH9505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101027,
      "product_name": "XH95 75\"",
      "product_model": "XH95 75\"",
      "product_mpn": "KD75XH9505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101029,
      "product_name": "75Q950T",
      "product_model": "75Q950T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101031,
      "product_name": "85Q950T",
      "product_model": "85Q950T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101033,
      "product_name": "65Q800T",
      "product_model": "65Q800T",
      "product_mpn": "65Q800T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101035,
      "product_name": "75Q800T",
      "product_model": "75Q800T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101037,
      "product_name": "82Q800T",
      "product_model": "82Q800T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101039,
      "product_name": "65Q70T",
      "product_model": "65Q70T",
      "product_mpn": "65Q70T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101041,
      "product_name": "75Q70T",
      "product_model": "75Q70T",
      "product_mpn": "75Q70T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101043,
      "product_name": "85Q70T",
      "product_model": "85Q70T",
      "product_mpn": "85Q70T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101045,
      "product_name": "55Q80T",
      "product_model": "55Q80T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101047,
      "product_name": "65Q80T",
      "product_model": "65Q80T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101049,
      "product_name": "75Q80T",
      "product_model": "75Q80T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101051,
      "product_name": "85Q80T",
      "product_model": "85Q80T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101053,
      "product_name": "65Q95T",
      "product_model": "65Q95T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101055,
      "product_name": "75Q95T",
      "product_model": "75Q95T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101057,
      "product_name": "85Q95T",
      "product_model": "85Q95T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101058,
      "product_name": "The Frame: 50LS03T",
      "product_model": "The Frame: 50LS03T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101060,
      "product_name": "The Frame: 55LS03T",
      "product_model": "The Frame: 55LS03T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101062,
      "product_name": "The Frame: 65LS03T",
      "product_model": "The Frame: 65LS03T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101064,
      "product_name": "The Frame: 75LS03T",
      "product_model": "The Frame: 75LS03T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101067,
      "product_name": "The Serif: 55LS01TA",
      "product_model": "The Serif: 55LS01TA",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101069,
      "product_name": "The Serif: 43LS01TA",
      "product_model": "The Serif: 43LS01TA",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101071,
      "product_name": "C81",
      "product_model": "C81",
      "product_mpn": "C81",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101073,
      "product_name": "C71",
      "product_model": "C71",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101074,
      "product_name": "XH95 55\"",
      "product_model": "XH95 55\"",
      "product_mpn": "KD-55XH9505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101075,
      "product_name": "XH95 55\"",
      "product_model": "XH95 55\"",
      "product_mpn": "KD-55XH9505",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101081,
      "product_name": "P71",
      "product_model": "P71",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101083,
      "product_name": "QE65Q83TATXXC",
      "product_model": "QE65Q83TATXXC",
      "product_mpn": "QE65Q83TATXXC",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101085,
      "product_name": "GQ65Q84TGTXZG",
      "product_model": "GQ65Q84TGTXZG",
      "product_mpn": "GQ65Q84TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101087,
      "product_name": "GQ75Q84TGTXZG",
      "product_model": "GQ75Q84TGTXZG",
      "product_mpn": "GQ75Q84TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101089,
      "product_name": "QE65Q85TATXXU",
      "product_model": "QE65Q85TATXXU",
      "product_mpn": "QE65Q85TATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101091,
      "product_name": "QE75Q85TATXXU",
      "product_model": "QE75Q85TATXXU",
      "product_mpn": "QE75Q85TATXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101093,
      "product_name": "QN65Q900TSFXZA",
      "product_model": "QN65Q900TSFXZA",
      "product_mpn": "QN65Q900TSFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101095,
      "product_name": "QN75Q900TSFXZA",
      "product_model": "QN75Q900TSFXZA",
      "product_mpn": "QN75Q900TSFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101097,
      "product_name": "QN85Q900TSFXZA",
      "product_model": "QN85Q900TSFXZA",
      "product_mpn": "QN85Q900TSFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101099,
      "product_name": "QN65Q90TAFXZA",
      "product_model": "QN65Q90TAFXZA",
      "product_mpn": "QN65Q90TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101101,
      "product_name": "QN75Q90TAFXZA",
      "product_model": "QN75Q90TAFXZA",
      "product_mpn": "QN75Q90TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101103,
      "product_name": "QN85Q90TAFXZA",
      "product_model": "QN85Q90TAFXZA",
      "product_mpn": "QN85Q90TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101105,
      "product_name": "QN85Q950TSFXZA",
      "product_model": "QN85Q950TSFXZA",
      "product_mpn": "QN85Q950TSFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101107,
      "product_name": "GQ75Q950TSTXZG",
      "product_model": "GQ75Q950TSTXZG",
      "product_mpn": "GQ75Q950TSTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101109,
      "product_name": "GQ65Q950TSTXZG",
      "product_model": "GQ65Q950TSTXZG",
      "product_mpn": "GQ65Q950TSTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101111,
      "product_name": "QN75Q950TSGXZD",
      "product_model": "QN75Q950TSGXZD",
      "product_mpn": "QN75Q950TSGXZD",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101113,
      "product_name": "QA85Q950TSWXXY",
      "product_model": "QA85Q950TSWXXY",
      "product_mpn": "QA85Q950TSWXXY",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101115,
      "product_name": "QA65Q950TSWXXY",
      "product_model": "QA65Q950TSWXXY",
      "product_mpn": "QA65Q950TSWXXY",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101117,
      "product_name": "QN65Q800TAFXZA",
      "product_model": "QN65Q800TAFXZA",
      "product_mpn": "QN65Q800TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101119,
      "product_name": "QN82Q800TAFXZA",
      "product_model": "QN82Q800TAFXZA",
      "product_mpn": "QN82Q800TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101121,
      "product_name": "QN75Q800TAFXZA",
      "product_model": "QN75Q800TAFXZA",
      "product_mpn": "QN75Q800TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101123,
      "product_name": "GQ65Q95TGTXZG",
      "product_model": "GQ65Q95TGTXZG",
      "product_mpn": "GQ65Q95TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101125,
      "product_name": "GQ85Q95TGTXZG",
      "product_model": "GQ85Q95TGTXZG",
      "product_mpn": "GQ85Q95TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101127,
      "product_name": "GQ75Q95TGTXZG",
      "product_model": "GQ75Q95TGTXZG",
      "product_mpn": "GQ75Q95TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101129,
      "product_name": "QN82Q800TAGXZD",
      "product_model": "QN82Q800TAGXZD",
      "product_mpn": "QN82Q800TAGXZD",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101131,
      "product_name": "QN75Q800TAGXZD",
      "product_model": "QN75Q800TAGXZD",
      "product_mpn": "QN75Q800TAGXZD",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101133,
      "product_name": "QA65Q800TAWXXY",
      "product_model": "QA65Q800TAWXXY",
      "product_mpn": "QA65Q800TAWXXY",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101135,
      "product_name": "QN65Q80TAFXZA",
      "product_model": "QN65Q80TAFXZA",
      "product_mpn": "QN65Q80TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101137,
      "product_name": "QN85Q80TAFXZA",
      "product_model": "QN85Q80TAFXZA",
      "product_mpn": "QN85Q80TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101139,
      "product_name": "QN75Q80TAFXZA",
      "product_model": "QN75Q80TAFXZA",
      "product_mpn": "QN75Q80TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101141,
      "product_name": "GQ65Q80TGTXZG",
      "product_model": "GQ65Q80TGTXZG",
      "product_mpn": "GQ65Q80TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101143,
      "product_name": "GQ85Q80TGTXZG",
      "product_model": "GQ85Q80TGTXZG",
      "product_mpn": "GQ85Q80TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101145,
      "product_name": "GQ75Q80TGTXZG",
      "product_model": "GQ75Q80TGTXZG",
      "product_mpn": "GQ75Q80TGTXZG",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101147,
      "product_name": "QN55LS03TAFXZA",
      "product_model": "QN55LS03TAFXZA",
      "product_mpn": "QN55LS03TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101149,
      "product_name": "QN65LS03TAFXZA",
      "product_model": "QN65LS03TAFXZA",
      "product_mpn": "QN65LS03TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101151,
      "product_name": "QN75LS03TAFXZA",
      "product_model": "QN75LS03TAFXZA",
      "product_mpn": "QN75LS03TAFXZA",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 200001,
      "product_name": "iPhone X",
      "product_model": "iPhone X",
      "product_mpn": "MQAF2B/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200007,
      "product_name": "iPhone 8 Plus",
      "product_model": "iPhone 8 Plus",
      "product_mpn": "MQ8L2ZD/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200018,
      "product_name": "iPhone 7 Plus",
      "product_model": "iPhone 7 Plus",
      "product_mpn": "MNQM2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200019,
      "product_name": "iPhone XS Max - Space Grey",
      "product_model": "iPhone XS Max - Space Grey",
      "product_mpn": "MT562HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200020,
      "product_name": "iPhone XS - Space grey",
      "product_model": "iPhone XS - Space grey",
      "product_mpn": "MT9L2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200021,
      "product_name": "iPhone XS Max - Gold",
      "product_model": "iPhone XS Max - Gold",
      "product_mpn": "MT582HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200022,
      "product_name": "iPhone XS Max - Silver",
      "product_model": "iPhone XS Max - Silver",
      "product_mpn": "MT572HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200023,
      "product_name": "iPhone XS - Gold",
      "product_model": "iPhone XS - Gold",
      "product_mpn": "MT9N2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200024,
      "product_name": "iPhone XS - Silver",
      "product_model": "iPhone XS - Silver",
      "product_mpn": "MT9M2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200025,
      "product_name": "iPhone 8 - Gold",
      "product_model": "iPhone 8 - Gold",
      "product_mpn": "MQ7H2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200026,
      "product_name": "iPhone 8 - Silver",
      "product_model": "iPhone 8 - Silver",
      "product_mpn": "MQ7G2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200027,
      "product_name": "iPhone 8 - Space Grey",
      "product_model": "iPhone 8 - Space Grey",
      "product_mpn": "MQ7F2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200028,
      "product_name": "iPhone 8 Plus - Gold",
      "product_model": "iPhone 8 Plus - Gold",
      "product_mpn": "MQ8J2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200029,
      "product_name": "iPhone 8 Plus - Silver",
      "product_model": "iPhone 8 Plus - Silver",
      "product_mpn": "MQ8H2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200030,
      "product_name": "iPhone 7 - Rose Gold",
      "product_model": "iPhone 7 - Rose Gold",
      "product_mpn": "MN952HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200031,
      "product_name": "iPhone 7 - Gold",
      "product_model": "iPhone 7 - Gold",
      "product_mpn": "MN942HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200032,
      "product_name": "iPhone 7 - Silver",
      "product_model": "iPhone 7 - Silver",
      "product_mpn": "MN932HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200033,
      "product_name": "iPhone 7 - Jet Black",
      "product_model": "iPhone 7 - Jet Black",
      "product_mpn": "MN962HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200034,
      "product_name": "iPhone 7 - Black",
      "product_model": "iPhone 7 - Black",
      "product_mpn": "MN922HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200035,
      "product_name": "iPhone 7 Plus - Rose Gold",
      "product_model": "iPhone 7 Plus - Rose Gold",
      "product_mpn": "MN4U2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200036,
      "product_name": "iPhone 7 Plus - Gold",
      "product_model": "iPhone 7 Plus - Gold",
      "product_mpn": "MN4Q2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200037,
      "product_name": "iPhone 7 Plus - Silver",
      "product_model": "iPhone 7 Plus - Silver",
      "product_mpn": "MNQN2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200038,
      "product_name": "iPhone 7 Plus - Jet Black",
      "product_model": "iPhone 7 Plus - Jet Black",
      "product_mpn": "MN4D2LL/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200039,
      "product_name": "iPhone X - Silver",
      "product_model": "iPhone X - Silver",
      "product_mpn": "MQA92HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200040,
      "product_name": "Iphone XR - Black",
      "product_model": "Iphone XR - Black",
      "product_mpn": "MRYJ2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200041,
      "product_name": "Iphone XR - White",
      "product_model": "Iphone XR - White",
      "product_mpn": "MRYL2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200042,
      "product_name": "Iphone XR- Blue",
      "product_model": "Iphone XR- Blue",
      "product_mpn": "MRYQ2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200043,
      "product_name": "Iphone XR - Red",
      "product_model": "Iphone XR - Red",
      "product_mpn": "MRYM2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200044,
      "product_name": "Iphone XR - Coral",
      "product_model": "Iphone XR - Coral",
      "product_mpn": "MRYP2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200045,
      "product_name": "Iphone XR - Yellow",
      "product_model": "Iphone XR - Yellow",
      "product_mpn": "MRYN2HN/A",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200046,
      "product_name": "Huawei P30 PRO",
      "product_model": "Huawei P30 PRO",
      "product_mpn": "P30 Pro",
      "product_brand": "Huawei",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 300005,
      "product_name": "Castro Fabric Modular Right Chaise Lounge Suite",
      "product_model": "Castro Fabric Modular Right Chaise Lounge Suite",
      "product_mpn": "",
      "product_brand": "Castro",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300011,
      "product_name": "Porto- Charcoal Grey",
      "product_model": "Porto- Charcoal Grey",
      "product_mpn": "",
      "product_brand": "Habitat",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300017,
      "product_name": "Boro Stripe Armchair",
      "product_model": "Boro Stripe Armchair",
      "product_mpn": "",
      "product_brand": "Anthropologie",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 500000,
      "product_name": "PIXMA TS9040 Printer",
      "product_model": "PIXMA TS9040 Printer",
      "product_mpn": "TS9040",
      "product_brand": "Canon",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500001,
      "product_name": "PIXMA TS5050 All-in-One Wireless Inkjet Printer",
      "product_model": "PIXMA TS5050 All-in-One Wireless Inkjet Printer",
      "product_mpn": "TS5050",
      "product_brand": "Canon",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500002,
      "product_name": "PIXMA TS6151 All-in-One Wireless Inkjet Printer",
      "product_model": "PIXMA TS6151 All-in-One Wireless Inkjet Printer",
      "product_mpn": "TS6151",
      "product_brand": "Canon",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500003,
      "product_name": "PIXMA TS5150 All-in-One Wireless Inkjet Printer",
      "product_model": "PIXMA TS5150 All-in-One Wireless Inkjet Printer",
      "product_mpn": "TS5150",
      "product_brand": "Canon",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500004,
      "product_name": "5032 All-in-One Wireless Inkjet Printer",
      "product_model": "5032 All-in-One Wireless Inkjet Printer",
      "product_mpn": "ENVY 5032",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500005,
      "product_name": "ENVY 5020 Wireless All in One Printer",
      "product_model": "ENVY 5020 Wireless All in One Printer",
      "product_mpn": "ENVY 5020",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500006,
      "product_name": "DeskJet Ink Advantage 5275 All-in-One Printer",
      "product_model": "DeskJet Ink Advantage 5275 All-in-One Printer",
      "product_mpn": "M2U76C",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500007,
      "product_name": "Xpress SL-M3015DW Printer",
      "product_model": "Xpress SL-M3015DW Printer",
      "product_mpn": "SL-M3015DW",
      "product_brand": "Samsung",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500008,
      "product_name": "Xpress SL-M2875FD Multifunction Printer",
      "product_model": "Xpress SL-M2875FD Multifunction Printer",
      "product_mpn": "SL-M2875FD",
      "product_brand": "Samsung",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500009,
      "product_name": "Xpress SL-M2070W Multifunction Printer",
      "product_model": "Xpress SL-M2070W Multifunction Printer",
      "product_mpn": "SL-M2070W",
      "product_brand": "Samsung",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500010,
      "product_name": "Tango X",
      "product_model": "Tango X",
      "product_mpn": "3DP65B",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500011,
      "product_name": "OfficeJet Pro 8024 All-in-One Printer",
      "product_model": "OfficeJet Pro 8024 All-in-One Printer",
      "product_mpn": "1KR66B",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500013,
      "product_name": "Officejet Pro 9019",
      "product_model": "Officejet Pro 9019",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500014,
      "product_name": "Tango X All-in-One Wireless Inkjet",
      "product_model": "Tango X All-in-One Wireless Inkjet",
      "product_mpn": "3DP65B",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500015,
      "product_name": "Color Laser 150nw",
      "product_model": "Color Laser 150nw",
      "product_mpn": "4ZB95A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500016,
      "product_name": "Color Laser MFP 178nw",
      "product_model": "Color Laser MFP 178nw",
      "product_mpn": "4ZB96A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500017,
      "product_name": "Color Laser MFP 179fnw",
      "product_model": "Color Laser MFP 179fnw",
      "product_mpn": "4ZB97A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500018,
      "product_name": "Laserjet Pro M479fdw",
      "product_model": "Laserjet Pro M479fdw",
      "product_mpn": "W1A80A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500025,
      "product_name": "Officejet pro 7740",
      "product_model": "Officejet pro 7740",
      "product_mpn": "G5J38A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500026,
      "product_name": "Envy photo 6230",
      "product_model": "Envy photo 6230",
      "product_mpn": "K7G25B",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 600000,
      "product_name": "HW-MS751 5.1 All-in-One Sound Bar - Silver",
      "product_model": "HW-MS751 5.1 All-in-One Sound Bar - Silver",
      "product_mpn": "HW-MS751",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600003,
      "product_name": "Portable Wireless BLUETOOTH Speaker",
      "product_model": "Portable Wireless BLUETOOTH Speaker",
      "product_mpn": "SRSXB21B.CE7",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600004,
      "product_name": "HTXF9000 2.1 Wireless Cinematic Sound Bar with Dolby Atmos",
      "product_model": "HTXF9000 2.1 Wireless Cinematic Sound Bar with Dolby Atmos",
      "product_mpn": "HT-XF9000",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600005,
      "product_name": "X300 Mobile Wireless Stereo Speaker",
      "product_model": "X300 Mobile Wireless Stereo Speaker",
      "product_mpn": "984-000421",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600006,
      "product_name": "MX Sound 2.0 Bluetooth PC Speakers",
      "product_model": "MX Sound 2.0 Bluetooth PC Speakers",
      "product_mpn": "980-001285",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600007,
      "product_name": "SoundTouch 30 wireless speaker White",
      "product_model": "SoundTouch 30 wireless speaker White",
      "product_mpn": "738102-5200",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600008,
      "product_name": "Soundlink Color II Portable Bluetooth Wireless Speaker",
      "product_model": "Soundlink Color II Portable Bluetooth Wireless Speaker",
      "product_mpn": "752195-0200",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600010,
      "product_name": "One Wireless Smart Sound Speaker - White",
      "product_model": "One Wireless Smart Sound Speaker - White",
      "product_mpn": "ONEG1UK",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600011,
      "product_name": "Ultimate Ears Boom 3 - Night Black",
      "product_model": "Ultimate Ears Boom 3 - Night Black",
      "product_mpn": "984-001360",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600012,
      "product_name": "Ultimate Ears Boom 3 - Lagoon Blue",
      "product_model": "Ultimate Ears Boom 3 - Lagoon Blue",
      "product_mpn": "984-001362",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600013,
      "product_name": "Ultimate Ears Boom 3 - Ultraviolet Purple",
      "product_model": "Ultimate Ears Boom 3 - Ultraviolet Purple",
      "product_mpn": "984-001363",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600014,
      "product_name": "Ultimate Ears Boom 3 - Sunset Red",
      "product_model": "Ultimate Ears Boom 3 - Sunset Red",
      "product_mpn": "984-001364",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600015,
      "product_name": "Ultimate Ears Megaboom 3 - Night Black",
      "product_model": "Ultimate Ears Megaboom 3 - Night Black",
      "product_mpn": "984-001402",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600016,
      "product_name": "Ultimate Ears Megaboom 3 - Lagoon Blue",
      "product_model": "Ultimate Ears Megaboom 3 - Lagoon Blue",
      "product_mpn": "984-001404",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600017,
      "product_name": "Ultimate Ears Megaboom 3 - Ultraviolet Purple",
      "product_model": "Ultimate Ears Megaboom 3 - Ultraviolet Purple",
      "product_mpn": "984-001405",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600018,
      "product_name": "Ultimate Ears Megaboom 3 - Sunset Red",
      "product_model": "Ultimate Ears Megaboom 3 - Sunset Red",
      "product_mpn": "984-001406",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600020,
      "product_name": "Party Box 300",
      "product_model": "Party Box 300",
      "product_mpn": "PARTYBOX300EU",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600021,
      "product_name": "Citation 500 Speaker",
      "product_model": "Citation 500 Speaker",
      "product_mpn": "HKCITATION500BLKEU",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600022,
      "product_name": "Citation Tower Speaker",
      "product_model": "Citation Tower Speaker",
      "product_mpn": "HKCITATIONTWRBLKEU",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600023,
      "product_name": "Citation Bar Speaker",
      "product_model": "Citation Bar Speaker",
      "product_mpn": "HKCITATIONBARBLKEU",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600024,
      "product_name": "Link View",
      "product_model": "Link View",
      "product_mpn": "LINKVIEWBLKEU",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600025,
      "product_name": "JBL Ultra HD SoundBar 5.1",
      "product_model": "JBL Ultra HD SoundBar 5.1",
      "product_mpn": "JBLBAR51BLKEP",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600026,
      "product_name": "Citation Sub Speaker",
      "product_model": "Citation Sub Speaker",
      "product_mpn": "HKCITATIONSUBBLKEU",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600027,
      "product_name": "SUB Wireless Subwoofer - Black",
      "product_model": "SUB Wireless Subwoofer - Black",
      "product_mpn": "SUBG1EU1BLK",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600028,
      "product_name": "Wonderboom 2 - Black",
      "product_model": "Wonderboom 2 - Black",
      "product_mpn": "984-001561",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600029,
      "product_name": "Wonderboom 2 - Blue",
      "product_model": "Wonderboom 2 - Blue",
      "product_mpn": "984-001562",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600030,
      "product_name": "Wonderboom 2 - Red",
      "product_model": "Wonderboom 2 - Red",
      "product_mpn": "984-001563",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600031,
      "product_name": "Wonderboom 2 - Pink",
      "product_model": "Wonderboom 2 - Pink",
      "product_mpn": "984-001564",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600032,
      "product_name": "Wonderboom 2 - Grey",
      "product_model": "Wonderboom 2 - Grey",
      "product_mpn": "984-001565",
      "product_brand": "Ultimate Ears",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600033,
      "product_name": "MusicCast 20 Wireless Smart Sound Speaker",
      "product_model": "MusicCast 20 Wireless Smart Sound Speaker",
      "product_mpn": "WX-021",
      "product_brand": "Yamaha",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600034,
      "product_name": "Home Speaker 500 Black",
      "product_model": "Home Speaker 500 Black",
      "product_mpn": "795345-1100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600035,
      "product_name": "Home Speaker 500 Silver",
      "product_model": "Home Speaker 500 Silver",
      "product_mpn": "795345-1300",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600036,
      "product_name": "Home Speaker 300 black",
      "product_model": "Home Speaker 300 black",
      "product_mpn": "808429-1100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600037,
      "product_name": "Frames Alto",
      "product_model": "Frames Alto",
      "product_mpn": "833416-0100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600038,
      "product_name": "Frames Rondo",
      "product_model": "Frames Rondo",
      "product_mpn": "833417-0100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600039,
      "product_name": "Revolve+ black",
      "product_model": "Revolve+ black",
      "product_mpn": "739617-1110",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600040,
      "product_name": "Revolve+ silver",
      "product_model": "Revolve+ silver",
      "product_mpn": "739617-1310",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600041,
      "product_name": "Taylor Portable Home Speaker Triple Black",
      "product_model": "Taylor Portable Home Speaker Triple Black",
      "product_mpn": "829393-4100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600042,
      "product_name": "One Black",
      "product_model": "One Black",
      "product_mpn": "ONEG2UK1BLK",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600043,
      "product_name": "Move",
      "product_model": "Move",
      "product_mpn": "MOVE1EU1BLK",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600044,
      "product_name": "One White",
      "product_model": "One White",
      "product_mpn": "ONEG2UK1",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600045,
      "product_name": "Master Compact Hifi System",
      "product_model": "Master Compact Hifi System",
      "product_mpn": "SC-PMX90EG-K",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600046,
      "product_name": "Variant Compact Hifi System",
      "product_model": "Variant Compact Hifi System",
      "product_mpn": "SC-PMX90EG-S",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600047,
      "product_name": "Master Compact Hifi System",
      "product_model": "Master Compact Hifi System",
      "product_mpn": "SC-PMX92EG-K",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600048,
      "product_name": "Variant Compact Hifi System",
      "product_model": "Variant Compact Hifi System",
      "product_mpn": "SC-PMX92EG-S",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600051,
      "product_name": "Master Compact Hifi System",
      "product_model": "Master Compact Hifi System",
      "product_mpn": "SC-DM502E-K",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600052,
      "product_name": "Variant Compact Hifi System",
      "product_model": "Variant Compact Hifi System",
      "product_mpn": "SC-DM502E-W",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600055,
      "product_name": "Master High Power Active Speaker System",
      "product_model": "Master High Power Active Speaker System",
      "product_mpn": "SC-TMAX5EG-K",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600056,
      "product_name": "Variant High Power Active Speaker System",
      "product_model": "Variant High Power Active Speaker System",
      "product_mpn": "SC-TMAX5EG-G",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600057,
      "product_name": "JBL Boombox 2",
      "product_model": "JBL Boombox 2",
      "product_mpn": "",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600058,
      "product_name": "HK Oasis (Grey)",
      "product_model": "HK Oasis (Grey)",
      "product_mpn": "",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 800000,
      "product_name": "14\" Chromebook 14",
      "product_model": "14\" Chromebook 14",
      "product_mpn": "F0H05UA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800001,
      "product_name": "OMEN by HP - 17-an007na",
      "product_model": "OMEN by HP - 17-an007na",
      "product_mpn": "1WQ11EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800002,
      "product_name": "15.6\" Notebook - 15-bw097na",
      "product_model": "15.6\" Notebook - 15-bw097na",
      "product_mpn": "15-bw097na",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800003,
      "product_name": "12.3\" Pixelbook 00122 12.3\" 2 in 1 Chromebook",
      "product_model": "12.3\" Pixelbook 00122 12.3\" 2 in 1 Chromebook",
      "product_mpn": "GA00122",
      "product_brand": "Google",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800004,
      "product_name": "14\" IdeaPad 720s 14\" Laptop",
      "product_model": "14\" IdeaPad 720s 14\" Laptop",
      "product_mpn": "IdeaPad 720S",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800005,
      "product_name": "12.2\" Miix 520",
      "product_model": "12.2\" Miix 520",
      "product_mpn": "Miix 520",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800006,
      "product_name": "14\" ThinkPad X1 Carbon Gen 1",
      "product_model": "14\" ThinkPad X1 Carbon Gen 1",
      "product_mpn": "X1",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800009,
      "product_name": "12\" Galaxy Book SM-W720 12\" 2 in 1",
      "product_model": "12\" Galaxy Book SM-W720 12\" 2 in 1",
      "product_mpn": "SM-W720",
      "product_brand": "Samsung",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800010,
      "product_name": "10.6\" Galaxy Book SM-W620 10.6\" 2 in 1",
      "product_model": "10.6\" Galaxy Book SM-W620 10.6\" 2 in 1",
      "product_mpn": "SM-W620",
      "product_brand": "Samsung",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800011,
      "product_name": "13.3” Gram Ultra-Lightweight Touchscreen with Intel Core i7 processor",
      "product_model": "13.3” Gram Ultra-Lightweight Touchscreen with Intel Core i7 processor",
      "product_mpn": "13Z980",
      "product_brand": "LG",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800012,
      "product_name": "Surface Pro 6 Black",
      "product_model": "Surface Pro 6 Black",
      "product_mpn": "LGP-00002",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800013,
      "product_name": "Surface Pro 6 Platinum",
      "product_model": "Surface Pro 6 Platinum",
      "product_mpn": "LGN-00002",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800014,
      "product_name": "Surface Book 2 13.5\"",
      "product_model": "Surface Book 2 13.5\"",
      "product_mpn": "HNN-00003",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800015,
      "product_name": "Surface Book 2 15\"",
      "product_model": "Surface Book 2 15\"",
      "product_mpn": "HNR-00003",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800016,
      "product_name": "Surface Laptop 2 Black",
      "product_model": "Surface Laptop 2 Black",
      "product_mpn": "DAJ-00092",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800017,
      "product_name": "Surface Laptop 2 Burgundy",
      "product_model": "Surface Laptop 2 Burgundy",
      "product_mpn": "LQN-00026",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800018,
      "product_name": "Surface Laptop 2 Cobalt",
      "product_model": "Surface Laptop 2 Cobalt",
      "product_mpn": "LQN-00040",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800019,
      "product_name": "Surface Laptop 2 Platinum",
      "product_model": "Surface Laptop 2 Platinum",
      "product_mpn": "LQN-00003",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800020,
      "product_name": "Surface Go",
      "product_model": "Surface Go",
      "product_mpn": "MHN-00002",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800021,
      "product_name": "Surface Studio 2",
      "product_model": "Surface Studio 2",
      "product_mpn": "LAH-00003",
      "product_brand": "Microsoft",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 800022,
      "product_name": "Alienware A51 Laptop",
      "product_model": "Alienware A51 Laptop",
      "product_mpn": "AREA51M",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800024,
      "product_name": "Drax (new Omen) - Omen X 2S",
      "product_model": "Drax (new Omen) - Omen X 2S",
      "product_mpn": "7GT06EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800025,
      "product_name": "Pavilion 14-ce1507sa",
      "product_model": "Pavilion 14-ce1507sa",
      "product_mpn": "6AR21EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800026,
      "product_name": "Pavilion 14-ce0594sa",
      "product_model": "Pavilion 14-ce0594sa",
      "product_mpn": "4XY64EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800027,
      "product_name": "Pavilion 14-ce1508sa",
      "product_model": "Pavilion 14-ce1508sa",
      "product_mpn": "6AT30EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800028,
      "product_name": "ENVY 13-ah1507sa",
      "product_model": "ENVY 13-ah1507sa",
      "product_mpn": "5AT63EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800029,
      "product_name": "OMEN 15-dc0507na",
      "product_model": "OMEN 15-dc0507na",
      "product_mpn": "4AY85EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800033,
      "product_name": "Alienware Gaming Laptop",
      "product_model": "Alienware Gaming Laptop",
      "product_mpn": "M15",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800034,
      "product_name": "XPS 13 Laptop",
      "product_model": "XPS 13 Laptop",
      "product_mpn": "XPS 13-9380",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800035,
      "product_name": "Alienware Gaming Laptop",
      "product_model": "Alienware Gaming Laptop",
      "product_mpn": "M17",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800036,
      "product_name": "Pavilion 14",
      "product_model": "Pavilion 14",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800037,
      "product_name": "OMEN X by HP 15-dg0001nf",
      "product_model": "OMEN X by HP 15-dg0001nf",
      "product_mpn": "6ZS38EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800038,
      "product_name": "ENVY 13-ah0002nf",
      "product_model": "ENVY 13-ah0002nf",
      "product_mpn": "4FN39EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800039,
      "product_name": "ENVY 13 x360",
      "product_model": "ENVY 13 x360",
      "product_mpn": "6RP04EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800040,
      "product_name": "Drax (new Omen) - Omen X 2S",
      "product_model": "Drax (new Omen) - Omen X 2S",
      "product_mpn": "7GT80EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800041,
      "product_name": "Predator Triton 500 - PT515-51",
      "product_model": "Predator Triton 500 - PT515-51",
      "product_mpn": "NH.Q4XEK.005",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800042,
      "product_name": "Predator Helios 300 - PH317-53",
      "product_model": "Predator Helios 300 - PH317-53",
      "product_mpn": "NH.Q5PEK.001",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800043,
      "product_name": "Aspire 5 - A514-52",
      "product_model": "Aspire 5 - A514-52",
      "product_mpn": "NX.H8AEK.005",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800044,
      "product_name": "Nitro 5",
      "product_model": "Nitro 5",
      "product_mpn": "NH.Q5XEK.001",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800047,
      "product_name": "ENVY 17 – Okinawa",
      "product_model": "ENVY 17 – Okinawa",
      "product_mpn": "6SR44EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800048,
      "product_name": "Pavilion x360 14\" FF+ - Faroe",
      "product_model": "Pavilion x360 14\" FF+ - Faroe",
      "product_mpn": "6LH99EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800049,
      "product_name": "Elite x360 1040",
      "product_model": "Elite x360 1040",
      "product_mpn": "5DF58EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800050,
      "product_name": "Zbook Studio x360",
      "product_model": "Zbook Studio x360",
      "product_mpn": "6TW47EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800051,
      "product_name": "EliteBook 840 G6",
      "product_model": "EliteBook 840 G6",
      "product_mpn": "6XD49EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800052,
      "product_name": "ProBook - G6 450",
      "product_model": "ProBook - G6 450",
      "product_mpn": "5PQ02EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800053,
      "product_name": "EliteBook - 850 G6",
      "product_model": "EliteBook - 850 G6",
      "product_mpn": "6XD57EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800054,
      "product_name": "Elite 1030 x360 – Bayfield",
      "product_model": "Elite 1030 x360 – Bayfield",
      "product_mpn": "7YL58EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800055,
      "product_name": "HP Chromebook x360",
      "product_model": "HP Chromebook x360",
      "product_mpn": " 7EB99EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800056,
      "product_name": "ENVY x360 15\" – Saba",
      "product_model": "ENVY x360 15\" – Saba",
      "product_mpn": "8XE36EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800057,
      "product_name": "Spectre 13 x360 Rainer Ash – copper",
      "product_model": "Spectre 13 x360 Rainer Ash – copper",
      "product_mpn": "8NF08EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800058,
      "product_name": "Spectre 15 x360 – Superman",
      "product_model": "Spectre 15 x360 – Superman",
      "product_mpn": "7PZ50EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800059,
      "product_name": "ENVY x360 13\" – Borocay",
      "product_model": "ENVY x360 13\" – Borocay",
      "product_mpn": "6BK12EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800060,
      "product_name": "HP Notebook - 15s Langkawi 19C1 - Natural silver",
      "product_model": "HP Notebook - 15s Langkawi 19C1 - Natural silver",
      "product_mpn": "7GM19EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800061,
      "product_name": "Pavilion 15 – Lannister (Grey)",
      "product_model": "Pavilion 15 – Lannister (Grey)",
      "product_mpn": "7VW22EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800062,
      "product_name": "Razer Blade 15 Advanced Model (W10/FHD/240HZ/i7/RTX 2070/256GB SSD)",
      "product_model": "Razer Blade 15 Advanced Model (W10/FHD/240HZ/i7/RTX 2070/256GB SSD)",
      "product_mpn": "B07QTBXDKD",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 900000,
      "product_name": "Bean-to-Cup Coffee Machine",
      "product_model": "Bean-to-Cup Coffee Machine",
      "product_mpn": "CM5300",
      "product_brand": "Miele",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900001,
      "product_name": "ARTISAN Espresso Machine",
      "product_model": "ARTISAN Espresso Machine",
      "product_mpn": "5KES2102",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900002,
      "product_name": "ECF01CRUK Espresso Coffee Machine",
      "product_model": "ECF01CRUK Espresso Coffee Machine",
      "product_mpn": "ECF01CRUK",
      "product_brand": "SMEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900003,
      "product_name": "ECF01PBUK Espresso Coffee Machine",
      "product_model": "ECF01PBUK Espresso Coffee Machine",
      "product_mpn": "ECF01PBUK",
      "product_brand": "SMEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900004,
      "product_name": "Lavazza Espresso Machine",
      "product_model": "Lavazza Espresso Machine",
      "product_mpn": "LM3100RE-U",
      "product_brand": "AEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900005,
      "product_name": "Espresso Machines",
      "product_model": "Espresso Machines",
      "product_mpn": "LM5200BK-U",
      "product_brand": "AEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900006,
      "product_name": "Vivy2 TAS1403GB Hot Drinks Machine",
      "product_model": "Vivy2 TAS1403GB Hot Drinks Machine",
      "product_mpn": "TAS1403GB",
      "product_brand": "Bosch",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900007,
      "product_name": "Expressionist Coffee Maker",
      "product_model": "Expressionist Coffee Maker",
      "product_mpn": "EKF7900",
      "product_brand": "Electrolux",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900008,
      "product_name": "PrimaDonna S Evo",
      "product_model": "PrimaDonna S Evo",
      "product_mpn": "ECAM 510.55.M",
      "product_brand": "Delonghi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900009,
      "product_name": "DINAMICA ECAM.350.75.S Bean to Cup Coffee Machine",
      "product_model": "DINAMICA ECAM.350.75.S Bean to Cup Coffee Machine",
      "product_mpn": "ECAM 350.75.S",
      "product_brand": "Delonghi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900010,
      "product_name": "Freestanding Coffee Maker",
      "product_model": "Freestanding Coffee Maker",
      "product_mpn": "KCM0402CU",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900011,
      "product_name": "Premium Coffee Brewer",
      "product_model": "Premium Coffee Brewer",
      "product_mpn": "NEW PRODUCT",
      "product_brand": "Keurig",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900012,
      "product_name": "Evidence EA893D40 Smart Bean to Cup Coffee Machine",
      "product_model": "Evidence EA893D40 Smart Bean to Cup Coffee Machine",
      "product_mpn": "YY3070FD",
      "product_brand": "Krups",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900013,
      "product_name": "Arabica Digital Bean-to-cup Coffee Machine",
      "product_model": "Arabica Digital Bean-to-cup Coffee Machine",
      "product_mpn": "EA817840",
      "product_brand": "Krups",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900014,
      "product_name": "Espresso Bean to Cup Coffee Machine",
      "product_model": "Espresso Bean to Cup Coffee Machine",
      "product_mpn": "EA9010",
      "product_brand": "Krups",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900017,
      "product_name": "Dinamica - Bean to Cup",
      "product_model": "Dinamica - Bean to Cup",
      "product_mpn": "ECAM 350.55",
      "product_brand": "Delonghi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900018,
      "product_name": "Dedica Style - Traditional Pump Espresso",
      "product_model": "Dedica Style - Traditional Pump Espresso",
      "product_mpn": "EC685.M",
      "product_brand": "Delonghi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 1000000,
      "product_name": "NX3300 with 16-50mm Power Zoom Lens",
      "product_model": "NX3300 with 16-50mm Power Zoom Lens",
      "product_mpn": "NX3300",
      "product_brand": "Samsung",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000001,
      "product_name": "Galaxy Camera EK-GN120 (Body Only)",
      "product_model": "Galaxy Camera EK-GN120 (Body Only)",
      "product_mpn": "EK-GN120",
      "product_brand": "Samsung",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000002,
      "product_name": "Gear 360 (2017) 4K Ultra HD Action Camcorder",
      "product_model": "Gear 360 (2017) 4K Ultra HD Action Camcorder",
      "product_mpn": "SM-R210NZWABTU",
      "product_brand": "Samsung",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000006,
      "product_name": "EOS M100 Mirrorless Camera",
      "product_model": "EOS M100 Mirrorless Camera",
      "product_mpn": "EOS M100",
      "product_brand": "Canon",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000007,
      "product_name": "EOS 6D Mark II Digital SLR Camera",
      "product_model": "EOS 6D Mark II Digital SLR Camera",
      "product_mpn": "1897C003",
      "product_brand": "Canon",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000008,
      "product_name": "EOS 1300D Kit with III Lens",
      "product_model": "EOS 1300D Kit with III Lens",
      "product_mpn": "EOS 1300D",
      "product_brand": "Canon",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000009,
      "product_name": "Photosmart M447 Camera",
      "product_model": "Photosmart M447 Camera",
      "product_mpn": "CC704A",
      "product_brand": "HP",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000010,
      "product_name": "Cyber-Shot DSC-H400 Bridge Cam",
      "product_model": "Cyber-Shot DSC-H400 Bridge Cam",
      "product_mpn": "DSC-H400",
      "product_brand": "Sony",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000011,
      "product_name": "PowerShot SX620 HS Superzoom Compact Camera",
      "product_model": "PowerShot SX620 HS Superzoom Compact Camera",
      "product_mpn": "SX620",
      "product_brand": "Canon",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1000012,
      "product_name": "LUMIX DMC-TZ60 18.1MP",
      "product_model": "LUMIX DMC-TZ60 18.1MP",
      "product_mpn": "DMC-TZ60",
      "product_brand": "Panasonic",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1100000,
      "product_name": "Alienware Aurora",
      "product_model": "Alienware Aurora",
      "product_mpn": "R8",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 1500000,
      "product_name": "Series 7000 Wet and Dry",
      "product_model": "Series 7000 Wet and Dry",
      "product_mpn": "S7530/50",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 1500001,
      "product_name": "Aquatouch Wet and Dry",
      "product_model": "Aquatouch Wet and Dry",
      "product_mpn": "AT890/20",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 1500002,
      "product_name": "BT3226/14",
      "product_model": "BT3226/14",
      "product_mpn": "BT3226/14",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 1500003,
      "product_name": "BHS377/00",
      "product_model": "BHS377/00",
      "product_mpn": "BHS377/00",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 1500004,
      "product_name": "S6630/11",
      "product_model": "S6630/11",
      "product_mpn": "S6630/11",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 2200002,
      "product_name": "New Hallingford 4Dr 3Drw Mirror Wardrobe Beech",
      "product_model": "New Hallingford 4Dr 3Drw Mirror Wardrobe Beech",
      "product_mpn": "247/8441",
      "product_brand": "Argos",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200005,
      "product_name": "2-Door Wardrobe",
      "product_model": "2-Door Wardrobe",
      "product_mpn": "646452",
      "product_brand": "DORIAN",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200006,
      "product_name": "Home Novara 3 Door Wardrobe",
      "product_model": "Home Novara 3 Door Wardrobe",
      "product_mpn": "",
      "product_brand": "Argos",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2500000,
      "product_name": "Batski Tripod Table",
      "product_model": "Batski Tripod Table",
      "product_mpn": "BATSKITA1",
      "product_brand": "Online Relity",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500003,
      "product_name": "Living Hazel Table With DSW Legs",
      "product_model": "Living Hazel Table With DSW Legs",
      "product_mpn": "4628",
      "product_brand": "Cult Furniture",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 4600002,
      "product_name": "Unikitty™ Unikingdom Fairground Fun",
      "product_model": "Unikitty™ Unikingdom Fairground Fun",
      "product_mpn": "41456",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 4600003,
      "product_name": "DC Super Heroes App-Controlled Batmobile",
      "product_model": "DC Super Heroes App-Controlled Batmobile",
      "product_mpn": "76112",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 4600004,
      "product_name": "Laugh & Learn Servin' Up Fun Food Truck",
      "product_model": "Laugh & Learn Servin' Up Fun Food Truck",
      "product_mpn": "DYM74",
      "product_brand": "Fisher-Price®",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 4600006,
      "product_name": "Dream House",
      "product_model": "Dream House",
      "product_mpn": "FHY73",
      "product_brand": "Barbie®",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 5400000,
      "product_name": "Aurelia 15 Pendant Light",
      "product_model": "Aurelia 15 Pendant Light",
      "product_mpn": "AUR6264",
      "product_brand": "Ocean Lighting",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "LIGHTINGS"
    },
    {
      "product_arid": 5400002,
      "product_name": "Vintage Industrial Barn Flush Mount",
      "product_model": "Vintage Industrial Barn Flush Mount",
      "product_mpn": "BN-FM-CP",
      "product_brand": "Industville",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "LIGHTINGS"
    },
    {
      "product_arid": 5400003,
      "product_name": "Kaelan 1-Light Inverted Pendant",
      "product_model": "Kaelan 1-Light Inverted Pendant",
      "product_mpn": "WSBS1046",
      "product_brand": "Wayville",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "LIGHTINGS"
    },
    {
      "product_arid": 5400004,
      "product_name": "Design LED Chromed Infinite Ribbon",
      "product_model": "Design LED Chromed Infinite Ribbon",
      "product_mpn": "400962",
      "product_brand": "Kosilight",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "LIGHTINGS"
    },
    {
      "product_arid": 6700002,
      "product_name": "EQT Support RF PRIMEKNIT Trainer",
      "product_model": "EQT Support RF PRIMEKNIT Trainer",
      "product_mpn": "BY9603",
      "product_brand": "Adidas",
      "product_category": "MENS",
      "product_subcategory": "SHOES"
    },
    {
      "product_arid": 6700004,
      "product_name": "Air Max 1",
      "product_model": "Air Max 1",
      "product_mpn": "319986-032",
      "product_brand": "Nike",
      "product_category": "MENS",
      "product_subcategory": "SHOES"
    },
    {
      "product_arid": 6700005,
      "product_name": "Classic Mini Waterproof Boot",
      "product_model": "Classic Mini Waterproof Boot",
      "product_mpn": "1019643",
      "product_brand": "Ugg®",
      "product_category": "MENS",
      "product_subcategory": "SHOES"
    },
    {
      "product_arid": 6800001,
      "product_name": "Wireless over-ear headphones",
      "product_model": "Wireless over-ear headphones",
      "product_mpn": "E55BT",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800002,
      "product_name": "G533 Wireless 7.1 Surround Gaming Headset",
      "product_model": "G533 Wireless 7.1 Surround Gaming Headset",
      "product_mpn": "981-000634",
      "product_brand": "Logitech G",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800003,
      "product_name": "RUN True Wireless Sports Headphones",
      "product_model": "RUN True Wireless Sports Headphones",
      "product_mpn": "985-000677",
      "product_brand": "Jaybird",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800004,
      "product_name": "X4 Wireless Sports Headphones",
      "product_model": "X4 Wireless Sports Headphones",
      "product_mpn": "985-000812",
      "product_brand": "Jaybird",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800005,
      "product_name": "Wireless Over-Ear NC Headphones",
      "product_model": "Wireless Over-Ear NC Headphones",
      "product_mpn": "LIVE650BTNC",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800006,
      "product_name": "Wireless Over-Ear Headphones",
      "product_model": "Wireless Over-Ear Headphones",
      "product_mpn": "LIVE400BT",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800007,
      "product_name": "Tune 600BTNC Headphone",
      "product_model": "Tune 600BTNC Headphone",
      "product_mpn": "T600BTNCBLK",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800010,
      "product_name": "Headphone 700 black",
      "product_model": "Headphone 700 black",
      "product_mpn": "794297-0100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800011,
      "product_name": "Headphone 700 silver",
      "product_model": "Headphone 700 silver",
      "product_mpn": "794297-0300",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800012,
      "product_name": "QC35II black",
      "product_model": "QC35II black",
      "product_mpn": "789564-0010",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800013,
      "product_name": "QC35II silver",
      "product_model": "QC35II silver",
      "product_mpn": "789564-0020",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800015,
      "product_name": "Jabra Elite Active 75t Navy Blue",
      "product_model": "Jabra Elite Active 75t Navy Blue",
      "product_mpn": "",
      "product_brand": "Jabra",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800016,
      "product_name": "G935 (Black)",
      "product_model": "G935 (Black)",
      "product_mpn": "981-000744",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800017,
      "product_name": "G635 (Black)",
      "product_model": "G635 (Black)",
      "product_mpn": "981-000750",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800018,
      "product_name": "Tarah Pro (Black)",
      "product_model": "Tarah Pro (Black)",
      "product_mpn": "985-000822",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800019,
      "product_name": "Jabra Elite 75t Titanium Black",
      "product_model": "Jabra Elite 75t Titanium Black",
      "product_mpn": "",
      "product_brand": "Jabra",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6900000,
      "product_name": "MX Master 2S",
      "product_model": "MX Master 2S",
      "product_mpn": "910-005139",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900001,
      "product_name": "MX Anywhere 2S",
      "product_model": "MX Anywhere 2S",
      "product_mpn": "910-005153",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900002,
      "product_name": "Craft Advanced Keyboard with Creative Input Dial",
      "product_model": "Craft Advanced Keyboard with Creative Input Dial",
      "product_mpn": "920-008503",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900003,
      "product_name": "M330 Silent Plus",
      "product_model": "M330 Silent Plus",
      "product_mpn": "910-004909",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900004,
      "product_name": "G502 HERO High Performance",
      "product_model": "G502 HERO High Performance",
      "product_mpn": "910-005471",
      "product_brand": "Logitech G",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900005,
      "product_name": "G513 Lightsync RGB Mechanical Gaming Keyboard",
      "product_model": "G513 Lightsync RGB Mechanical Gaming Keyboard",
      "product_mpn": "920-008867",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900006,
      "product_name": "MX Keys KB FR",
      "product_model": "MX Keys KB FR",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900010,
      "product_name": "Ergo K860 (Black)",
      "product_model": "Ergo K860 (Black)",
      "product_mpn": "920-009167",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900011,
      "product_name": "G910 (Black)",
      "product_model": "G910 (Black)",
      "product_mpn": "920-008015",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900012,
      "product_name": "G512  (Black)",
      "product_model": "G512  (Black)",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900013,
      "product_name": "K380 (Dark Grey)",
      "product_model": "K380 (Dark Grey)",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900014,
      "product_name": "K380 (Rose Pink)",
      "product_model": "K380 (Rose Pink)",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900015,
      "product_name": "K380 (Off White)",
      "product_model": "K380 (Off White)",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 7000000,
      "product_name": "3-sides borderless IPS display with Full HD Monitor",
      "product_model": "3-sides borderless IPS display with Full HD Monitor",
      "product_mpn": "24p1",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000001,
      "product_name": "Full HD FreeSync Gaming Monitor",
      "product_model": "Full HD FreeSync Gaming Monitor",
      "product_mpn": "G2590PX",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000002,
      "product_name": "Full HD LED Gaming Monitor",
      "product_model": "Full HD LED Gaming Monitor",
      "product_mpn": "G2590VXQ",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000003,
      "product_name": "Full HD LED FreeSync Monitor",
      "product_model": "Full HD LED FreeSync Monitor",
      "product_mpn": "G2590FX",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000004,
      "product_name": "Full HD LED Monitor",
      "product_model": "Full HD LED Monitor",
      "product_mpn": "22B1H",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000005,
      "product_name": "IPS Full HD Monitor",
      "product_model": "IPS Full HD Monitor",
      "product_mpn": "22B1HS",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000006,
      "product_name": "Full HD LED Monitor",
      "product_model": "Full HD LED Monitor",
      "product_mpn": "24B1H",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000007,
      "product_name": "Full HD LED Monitor",
      "product_model": "Full HD LED Monitor",
      "product_mpn": "24B1XH",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000008,
      "product_name": "Full HD LED Monitor",
      "product_model": "Full HD LED Monitor",
      "product_mpn": "24B1XHS",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000009,
      "product_name": "IPS Full HD Monitor",
      "product_model": "IPS Full HD Monitor",
      "product_mpn": "27B1H",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000010,
      "product_name": "Predator X34P",
      "product_model": "Predator X34P",
      "product_mpn": "UM.CX0EE.P01",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000011,
      "product_name": "Predator Z321QU",
      "product_model": "Predator Z321QU",
      "product_mpn": "UM.JZ1EE.005",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000012,
      "product_name": "Predator Z35P",
      "product_model": "Predator Z35P",
      "product_mpn": "UM.CZ1EE.P01",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000013,
      "product_name": "Aspire C24 - C24-865",
      "product_model": "Aspire C24 - C24-865",
      "product_mpn": "DQ.BBTEK.002",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000015,
      "product_name": "24\" All-in-One – Syrah ",
      "product_model": "24\" All-in-One – Syrah ",
      "product_mpn": "8KF51EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000016,
      "product_name": "Elite Display E243",
      "product_model": "Elite Display E243",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000017,
      "product_name": "Pavilion all-in-one - RADO",
      "product_model": "Pavilion all-in-one - RADO",
      "product_mpn": "7DR25EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000018,
      "product_name": "EliteDisplay E273m",
      "product_model": "EliteDisplay E273m",
      "product_mpn": "-",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000019,
      "product_name": "EliteDisplay E324q",
      "product_model": "EliteDisplay E324q",
      "product_mpn": "-",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000020,
      "product_name": "EliteDisplay S340c Curved Ultrawide Monitor",
      "product_model": "EliteDisplay S340c Curved Ultrawide Monitor",
      "product_mpn": "5FW74A*",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000021,
      "product_name": "EliteDisplay E344c Curved Monitor",
      "product_model": "EliteDisplay E344c Curved Monitor",
      "product_mpn": "6GJ95A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000022,
      "product_name": "EliteDisplay E243p",
      "product_model": "EliteDisplay E243p",
      "product_mpn": "5FT13A*",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000023,
      "product_name": "EliteDisplay S14",
      "product_model": "EliteDisplay S14",
      "product_mpn": "3HX46A*",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000024,
      "product_name": "EliteDisplay E27d Advanced Docking Monitor",
      "product_model": "EliteDisplay E27d Advanced Docking Monitor",
      "product_mpn": "6PA56A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000025,
      "product_name": "EliteDisplay E273d Docking Monitor",
      "product_model": "EliteDisplay E273d Docking Monitor",
      "product_mpn": "5WN63A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000026,
      "product_name": "EliteDisplay E24d Docking Monitor",
      "product_model": "EliteDisplay E24d Docking Monitor",
      "product_mpn": "6PA50A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000027,
      "product_name": "EliteDisplay E243d Docking Monitor",
      "product_model": "EliteDisplay E243d Docking Monitor",
      "product_mpn": "1TJ76A/7MP20AA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000028,
      "product_name": "EliteDisplay E223d Docking Monitor",
      "product_model": "EliteDisplay E223d Docking Monitor",
      "product_mpn": "5VT82A",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7100000,
      "product_name": "Yeti Professional USB Microphone",
      "product_model": "Yeti Professional USB Microphone",
      "product_mpn": "2070",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100001,
      "product_name": "Snowball Ice Microphone",
      "product_model": "Snowball Ice Microphone",
      "product_mpn": "1929",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100002,
      "product_name": "Yeti Nano USB Microphone",
      "product_model": "Yeti Nano USB Microphone",
      "product_mpn": "281",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100003,
      "product_name": "Yeti Professional USB Microphone",
      "product_model": "Yeti Professional USB Microphone",
      "product_mpn": "14134734",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100004,
      "product_name": "Yeti Professional USB Microphone",
      "product_model": "Yeti Professional USB Microphone",
      "product_mpn": "2117",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100005,
      "product_name": "Yeti Nano USB Microphone",
      "product_model": "Yeti Nano USB Microphone",
      "product_mpn": "",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100006,
      "product_name": "Blue Snowball Ice White (Variation)",
      "product_model": "Blue Snowball Ice White (Variation)",
      "product_mpn": "",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100007,
      "product_name": "Blue Yeticaster",
      "product_model": "Blue Yeticaster",
      "product_mpn": "",
      "product_brand": "Blue",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100008,
      "product_name": "Yeti X (Black)",
      "product_model": "Yeti X (Black)",
      "product_mpn": "988-000244",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7500000,
      "product_name": "Cuisinemaster Pro Dual Fuel Range Cooker",
      "product_model": "Cuisinemaster Pro Dual Fuel Range Cooker",
      "product_mpn": "PR100F530",
      "product_brand": "Leisure",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 7500001,
      "product_name": "60 cm Electric Induction Cooker",
      "product_model": "60 cm Electric Induction Cooker",
      "product_mpn": "CIS6741ECM",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 7500002,
      "product_name": "60 cm Electric Ceramic Cooker",
      "product_model": "60 cm Electric Ceramic Cooker",
      "product_mpn": "CCS6741ACM",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 7500003,
      "product_name": "Freestanding Induction Cooker",
      "product_model": "Freestanding Induction Cooker",
      "product_mpn": "EKF6772TOX",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 7500004,
      "product_name": "Freestanding Gas Cooker",
      "product_model": "Freestanding Gas Cooker",
      "product_mpn": "EKM6770BOX",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 7500005,
      "product_name": "Freestanding Dual Fuel Cooker, 90cm",
      "product_model": "Freestanding Dual Fuel Cooker, 90cm",
      "product_mpn": "",
      "product_brand": "Fisher&Paykel",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 7500009,
      "product_name": "CIB6670APM",
      "product_model": "CIB6670APM",
      "product_mpn": "CIB6670APM",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 7800000,
      "product_name": "OptiGrill+ Health GC713D40 Grill",
      "product_model": "OptiGrill+ Health GC713D40 Grill",
      "product_mpn": "GC712D12",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "GRILLS"
    },
    {
      "product_arid": 7800002,
      "product_name": "Weber Spirit E-210 Black Porcelain Enamel 2-Burner Liquid Propane Gas Grill",
      "product_model": "Weber Spirit E-210 Black Porcelain Enamel 2-Burner Liquid Propane Gas Grill",
      "product_mpn": "",
      "product_brand": "Weber",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "GRILLS"
    },
    {
      "product_arid": 7900000,
      "product_name": "Robot Cooker I - Companion XL",
      "product_model": "Robot Cooker I - Companion XL",
      "product_mpn": "YY3963FG",
      "product_brand": "Moulinex",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 7900001,
      "product_name": "Cookeo + Connect Grameez 150 Recipes",
      "product_model": "Cookeo + Connect Grameez 150 Recipes",
      "product_mpn": "CE856800",
      "product_brand": "Moulinex",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 8400007,
      "product_name": "860 PRO SATA III 2.5 inch 512GB",
      "product_model": "860 PRO SATA III 2.5 inch 512GB",
      "product_mpn": "MZ-76P512BW",
      "product_brand": "Samsung",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400008,
      "product_name": "EVO 860 2.5\" Internal SSD - 500 GB",
      "product_model": "EVO 860 2.5\" Internal SSD - 500 GB",
      "product_mpn": "MZ-76E500B/EU",
      "product_brand": "Samsung",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400009,
      "product_name": "Backup Plus External Hard Drive",
      "product_model": "Backup Plus External Hard Drive",
      "product_mpn": "STEL8000100",
      "product_brand": "Seagate",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8800000,
      "product_name": "Artisan 5KSM125BCU Stand Mixer",
      "product_model": "Artisan 5KSM125BCU Stand Mixer",
      "product_mpn": "5KSM125BCU",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800001,
      "product_name": "Ultra Mix Kitchen Machine",
      "product_model": "Ultra Mix Kitchen Machine",
      "product_mpn": "KM4100",
      "product_brand": "AEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800002,
      "product_name": "Stick Blender",
      "product_model": "Stick Blender",
      "product_mpn": "SB2500",
      "product_brand": "AEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800003,
      "product_name": "6.9 L KitchenAid PROFESSIONAL Stand Mixer 5KSM7990X",
      "product_model": "6.9 L KitchenAid PROFESSIONAL Stand Mixer 5KSM7990X",
      "product_mpn": "5KSM7990XBWH",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800004,
      "product_name": "KitchenAid ARTISAN Power Blender 5KSB7068",
      "product_model": "KitchenAid ARTISAN Power Blender 5KSB7068",
      "product_mpn": "5KSB7068BOB",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800005,
      "product_name": "6.9 L KitchenAid PROFESSIONAL Stand Mixer 5KSM7591X",
      "product_model": "6.9 L KitchenAid PROFESSIONAL Stand Mixer 5KSM7591X",
      "product_mpn": "5KSM7591XBWH",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800006,
      "product_name": "Blend-X Pro Blender BLM800WH",
      "product_model": "Blend-X Pro Blender BLM800WH",
      "product_mpn": "0W22311002",
      "product_brand": "Delonghi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800007,
      "product_name": "kMix Blender",
      "product_model": "kMix Blender",
      "product_mpn": "0WBLX51002",
      "product_brand": "Delonghi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800009,
      "product_name": "SMF01PKUK 50's Retro Stand Mixer",
      "product_model": "SMF01PKUK 50's Retro Stand Mixer",
      "product_mpn": "SMF01PKUK",
      "product_brand": "SMEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800010,
      "product_name": "Stand Mixers",
      "product_model": "Stand Mixers",
      "product_mpn": "SMF01CRUK",
      "product_brand": "SMEG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800011,
      "product_name": "Artisan Stand Mixer - Frosted Pearl",
      "product_model": "Artisan Stand Mixer - Frosted Pearl",
      "product_mpn": "5KSM156EFP ",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800012,
      "product_name": "Artisan Stand Mixer - Red",
      "product_model": "Artisan Stand Mixer - Red",
      "product_mpn": "5KSM185PSECA",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800013,
      "product_name": "Artisan Stand Mixer - Heritage Limited Edition - Blue",
      "product_model": "Artisan Stand Mixer - Heritage Limited Edition - Blue",
      "product_mpn": "5KSM180RCEMB ",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800014,
      "product_name": "Artisan Mini Stand Mixer - Red",
      "product_model": "Artisan Mini Stand Mixer - Red",
      "product_mpn": "5KSM3311XECA ",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800015,
      "product_name": "Classic Stand Mixer - White",
      "product_model": "Classic Stand Mixer - White",
      "product_mpn": "5K45SSEWH ",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800016,
      "product_name": "Artisan Stand Mixer - Red",
      "product_model": "Artisan Stand Mixer - Red",
      "product_mpn": "5KSM125EER ",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800017,
      "product_name": "Artisan Stand Mixer - Red Empire",
      "product_model": "Artisan Stand Mixer - Red Empire",
      "product_mpn": "5KSM175PSECA ",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800018,
      "product_name": "Artisan Stand Mixer - Candy Apple",
      "product_model": "Artisan Stand Mixer - Candy Apple",
      "product_mpn": "5KSM7580XECA ",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800019,
      "product_name": "Artisan Mini Stand Mixer - Empire Red",
      "product_model": "Artisan Mini Stand Mixer - Empire Red",
      "product_mpn": "5KSM3311XAER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800020,
      "product_name": "Classic Stand Mixer - Black",
      "product_model": "Classic Stand Mixer - Black",
      "product_mpn": "5KSM45AOB",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800021,
      "product_name": "Chef Titanium",
      "product_model": "Chef Titanium",
      "product_mpn": "KVC7300.S",
      "product_brand": "Kenwood",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800022,
      "product_name": "Multipro Classic",
      "product_model": "Multipro Classic",
      "product_mpn": "FDM781BA",
      "product_brand": "Kenwood",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 8800024,
      "product_name": "5KMT2116 - Red",
      "product_model": "5KMT2116 - Red",
      "product_mpn": "5KMT2116BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8800025,
      "product_name": "5KMT4116 - Red",
      "product_model": "5KMT4116 - Red",
      "product_mpn": "5KMT4116BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900000,
      "product_name": "Loft 4-Slice Toaster - Red",
      "product_model": "Loft 4-Slice Toaster - Red",
      "product_mpn": "TT760540",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900001,
      "product_name": "Loft 4-Slice Toaster- Black",
      "product_model": "Loft 4-Slice Toaster- Black",
      "product_mpn": "TT760840",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900002,
      "product_name": "Loft 4-Slice Toaster- White",
      "product_model": "Loft 4-Slice Toaster- White",
      "product_mpn": "TT760140",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900003,
      "product_name": "Variant Croustina Bread Maker",
      "product_model": "Variant Croustina Bread Maker",
      "product_mpn": "SD-ZP2000WXE",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900005,
      "product_name": "Master Croustina Bread Maker",
      "product_model": "Master Croustina Bread Maker",
      "product_mpn": "SD-ZP2000KXC",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900007,
      "product_name": "Master Breadmaker 33 Pgm w/ Dispenser",
      "product_model": "Master Breadmaker 33 Pgm w/ Dispenser",
      "product_mpn": "SD-ZB2512KXC",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900008,
      "product_name": "5KMT2204 - Red",
      "product_model": "5KMT2204 - Red",
      "product_mpn": "5KMT2204BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900010,
      "product_name": "5KMT2116 - Black",
      "product_model": "5KMT2116 - Black",
      "product_mpn": "5KMT2116BOB",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900011,
      "product_name": "5KMT4116 - Black",
      "product_model": "5KMT4116 - Black",
      "product_mpn": "5KMT4116BOB",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900012,
      "product_name": "5KMT221 - Red",
      "product_model": "5KMT221 - Red",
      "product_mpn": "5KMT221BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 8900013,
      "product_name": "5KMT2115 - White",
      "product_model": "5KMT2115 - White",
      "product_mpn": "5KMT2115BWH",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 9000000,
      "product_name": "Evoke Steel Blue Special Edition Pyramid Kettle",
      "product_model": "Evoke Steel Blue Special Edition Pyramid Kettle",
      "product_mpn": "100102",
      "product_brand": "Morphy Richards",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000001,
      "product_name": "Loft Rapid Boil Traditional Kettle - Red",
      "product_model": "Loft Rapid Boil Traditional Kettle - Red",
      "product_mpn": "KO250540",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000002,
      "product_name": "Loft Rapid Boil Traditional Kettle - black",
      "product_model": "Loft Rapid Boil Traditional Kettle - black",
      "product_mpn": "KO250840",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000003,
      "product_name": "Loft Rapid Boil Traditional Kettle - White",
      "product_model": "Loft Rapid Boil Traditional Kettle - White",
      "product_mpn": "KO250140",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000004,
      "product_name": "5KEK1522BER",
      "product_model": "5KEK1522BER",
      "product_mpn": "5KEK1522BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000005,
      "product_name": "5KEK1222 - Black",
      "product_model": "5KEK1222 - Black",
      "product_mpn": "5KEK1222BOB",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000006,
      "product_name": "5KEK1722 - Red",
      "product_model": "5KEK1722 - Red",
      "product_mpn": "5KEK1722BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000007,
      "product_name": "5KEK1722 - Black",
      "product_model": "5KEK1722 - Black",
      "product_mpn": "5KEK1722BOB",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9100001,
      "product_name": "Steam Oven",
      "product_model": "Steam Oven",
      "product_mpn": "EKS985X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100002,
      "product_name": "Steam Oven Panel",
      "product_model": "Steam Oven Panel",
      "product_mpn": "EKS985X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100003,
      "product_name": "Dual Cook Flex Oven",
      "product_model": "Dual Cook Flex Oven",
      "product_mpn": "NV75N5641RS",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100004,
      "product_name": "Dual Cook Flex Oven Panel",
      "product_model": "Dual Cook Flex Oven Panel",
      "product_mpn": "NV75N5641RS",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100005,
      "product_name": "Electric Oven with Dual Cook",
      "product_model": "Electric Oven with Dual Cook",
      "product_mpn": "NV66M3531BS",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100006,
      "product_name": "Electric Oven with Dual Cook (Front Panel)",
      "product_model": "Electric Oven with Dual Cook (Front Panel)",
      "product_mpn": "NV66M3531BS",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100007,
      "product_name": "Single Multi-function Split Cook Oven LED Timer",
      "product_model": "Single Multi-function Split Cook Oven LED Timer",
      "product_mpn": "BXVM35400",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100008,
      "product_name": "Single Multi-function Split Cook Oven LED Timer - Front Panel",
      "product_model": "Single Multi-function Split Cook Oven LED Timer - Front Panel",
      "product_mpn": "BXVM35400",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100009,
      "product_name": "Single Multi-function Pyrolytic Self-cleaning Oven",
      "product_model": "Single Multi-function Pyrolytic Self-cleaning Oven",
      "product_mpn": "BXIE32300XP",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100010,
      "product_name": "Single Multi-function Pyrolytic Self-cleaning Oven - Front Panel",
      "product_model": "Single Multi-function Pyrolytic Self-cleaning Oven - Front Panel",
      "product_mpn": "BXIE32300XP",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100011,
      "product_name": "Double Fan Oven LED Timer",
      "product_model": "Double Fan Oven LED Timer",
      "product_mpn": "BXDF29300",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100012,
      "product_name": "Double Fan Oven LED Timer - Front Panel",
      "product_model": "Double Fan Oven LED Timer - Front Panel",
      "product_mpn": "BXDF29300",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100013,
      "product_name": "Freestanding 60cm Double Oven Electric Cooker",
      "product_model": "Freestanding 60cm Double Oven Electric Cooker",
      "product_mpn": "JDC683",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100014,
      "product_name": "Freestanding 60cm Double Oven Electric Cooker - Front Panel",
      "product_model": "Freestanding 60cm Double Oven Electric Cooker - Front Panel",
      "product_mpn": "JDC683",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100015,
      "product_name": "60cm Single Fan Oven",
      "product_model": "60cm Single Fan Oven",
      "product_mpn": "GEBF34000",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100016,
      "product_name": "60cm Single Fan Oven - Front Panel",
      "product_model": "60cm Single Fan Oven - Front Panel",
      "product_mpn": "GEBF34000",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100017,
      "product_name": "Class 2 Built-In Oven - Black",
      "product_model": "Class 2 Built-In Oven - Black",
      "product_mpn": "SA2 540 H BL",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100018,
      "product_name": "Class 2 Built-In Oven - Black - Front Panel",
      "product_model": "Class 2 Built-In Oven - Black - Front Panel",
      "product_mpn": "SA2 540 H BL",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100019,
      "product_name": "Class 2 66L Built-In Oven - Stainless Steel",
      "product_model": "Class 2 66L Built-In Oven - Stainless Steel",
      "product_mpn": "SA2 540 H IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100020,
      "product_name": "Class 2 66L Built-In Oven - Stainless Steel - Front Panel",
      "product_model": "Class 2 66L Built-In Oven - Stainless Steel - Front Panel",
      "product_mpn": "SA2 540 H IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100021,
      "product_name": "Class 2 Built-In Oven - White",
      "product_model": "Class 2 Built-In Oven - White",
      "product_mpn": "SA2 540 H WH",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100022,
      "product_name": "Class 2 Built-In Oven - White - Front Panel",
      "product_model": "Class 2 Built-In Oven - White - Front Panel",
      "product_mpn": "SA2 540 H WH",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100023,
      "product_name": "Class 2 71L Built-In Oven - Stainless Steel",
      "product_model": "Class 2 71L Built-In Oven - Stainless Steel",
      "product_mpn": "SA2 544 C IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100024,
      "product_name": "Class 2 71L Built-In Oven - Stainless Steel - Front Panel",
      "product_model": "Class 2 71L Built-In Oven - Stainless Steel - Front Panel",
      "product_mpn": "SA2 544 C IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100025,
      "product_name": "Class 2 Built-In Oven - Stainless Steel",
      "product_model": "Class 2 Built-In Oven - Stainless Steel",
      "product_mpn": "SA2 840 P IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100026,
      "product_name": "Class 2 Built-In Oven - Stainless Steel - Front Panel",
      "product_model": "Class 2 Built-In Oven - Stainless Steel - Front Panel",
      "product_mpn": "SA2 840 P IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100027,
      "product_name": "Class 4 71L Electric Single Built-In Oven - Stainless Steel",
      "product_model": "Class 4 71L Electric Single Built-In Oven - Stainless Steel",
      "product_mpn": "SI4 854 C IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100028,
      "product_name": "Class 4 71L Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_model": "Class 4 71L Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_mpn": "SI4 854 C IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100029,
      "product_name": "Class 4 Electric Single Built-In Oven - Stainless Steel",
      "product_model": "Class 4 Electric Single Built-In Oven - Stainless Steel",
      "product_mpn": "SI4 854 H IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100030,
      "product_name": "Class 4 Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_model": "Class 4 Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_mpn": "SI4 854 H IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100031,
      "product_name": "Class 4 Electric Single Built-In Oven - Inox",
      "product_model": "Class 4 Electric Single Built-In Oven - Inox",
      "product_mpn": "SI4 854 P IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100032,
      "product_name": "Class 4 Electric Single Built-In Oven - Inox - Front Panel",
      "product_model": "Class 4 Electric Single Built-In Oven - Inox - Front Panel",
      "product_mpn": "SI4 854 P IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100033,
      "product_name": "Class 6 Electric Single Built-In Oven - Stainless Steel",
      "product_model": "Class 6 Electric Single Built-In Oven - Stainless Steel",
      "product_mpn": "SI6 864 SH IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100034,
      "product_name": "Class 6 Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_model": "Class 6 Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_mpn": "SI6 864 SH IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100035,
      "product_name": "Class 6 73L Electric Single Built-In Oven - Stainless Steel",
      "product_model": "Class 6 73L Electric Single Built-In Oven - Stainless Steel",
      "product_mpn": "SI6 874 SC IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100036,
      "product_name": "Class 6 73L Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_model": "Class 6 73L Electric Single Built-In Oven - Stainless Steel - Front Panel",
      "product_mpn": "SI6 874 SC IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100037,
      "product_name": "Class 6 Electric Single Built-In Oven - Inox",
      "product_model": "Class 6 Electric Single Built-In Oven - Inox",
      "product_mpn": "SI6 874 SH IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100038,
      "product_name": "Class 6 Electric Single Built-In Oven - Inox - Front Panel",
      "product_model": "Class 6 Electric Single Built-In Oven - Inox - Front Panel",
      "product_mpn": "SI6 874 SH IX",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100039,
      "product_name": "Active Cook Built-in Steam Oven - Stainless Steel",
      "product_model": "Active Cook Built-in Steam Oven - Stainless Steel",
      "product_mpn": "FA4S 544 IX H",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100040,
      "product_name": "Active Cook Built-in Steam Oven - Stainless Steel - Front Panel",
      "product_model": "Active Cook Built-in Steam Oven - Stainless Steel - Front Panel",
      "product_mpn": "FA4S 544 IX H",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100041,
      "product_name": "Sensecook - Built-In Oven With Pyrolytic Cleaning",
      "product_model": "Sensecook - Built-In Oven With Pyrolytic Cleaning",
      "product_mpn": "BPE842720M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100042,
      "product_name": "Sensecook - Built-In Oven With Pyrolytic Cleaning - Front Panel",
      "product_model": "Sensecook - Built-In Oven With Pyrolytic Cleaning - Front Panel",
      "product_mpn": "BPE842720M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100043,
      "product_name": "Steambake - Built-In Oven With Catalytic Cleaning",
      "product_model": "Steambake - Built-In Oven With Catalytic Cleaning",
      "product_mpn": "BCS552020M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100044,
      "product_name": "Steambake - Built-In Oven With Catalytic Cleaning - Front Panel",
      "product_model": "Steambake - Built-In Oven With Catalytic Cleaning - Front Panel",
      "product_mpn": "BCS552020M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100045,
      "product_name": "Oven",
      "product_model": "Oven",
      "product_mpn": "EOE7P11X0",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100046,
      "product_name": "Oven - Front Panel",
      "product_model": "Oven - Front Panel",
      "product_mpn": "EOE7P11X0",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100047,
      "product_name": "Oven",
      "product_model": "Oven",
      "product_mpn": "EOC6P71X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100048,
      "product_name": "Oven - Front Panel",
      "product_model": "Oven - Front Panel",
      "product_mpn": "EOC6P71X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100049,
      "product_name": "Oven",
      "product_model": "Oven",
      "product_mpn": "EOE8P11X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100050,
      "product_name": "Oven - Front Panel",
      "product_model": "Oven - Front Panel",
      "product_mpn": "EOE8P11X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100051,
      "product_name": "Oven",
      "product_model": "Oven",
      "product_mpn": "EOC8P31X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100052,
      "product_name": "Oven - Front Panel",
      "product_model": "Oven - Front Panel",
      "product_mpn": "EOC8P31X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100053,
      "product_name": "SteamBake  Electric Steam Oven",
      "product_model": "SteamBake  Electric Steam Oven",
      "product_mpn": "BES356010M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100054,
      "product_name": "SteamBake  Electric Steam Oven (Front Panel)",
      "product_model": "SteamBake  Electric Steam Oven (Front Panel)",
      "product_mpn": "BES356010M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100055,
      "product_name": "Multifunction Oven Pirolítico",
      "product_model": "Multifunction Oven Pirolítico",
      "product_mpn": "OAKZ9 7961 SP IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100056,
      "product_name": "Multifunction Oven Pirolítico  (Front Panel)",
      "product_model": "Multifunction Oven Pirolítico  (Front Panel)",
      "product_mpn": "OAKZ9 7961 SP IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100059,
      "product_name": "Pyrolysis Oven",
      "product_model": "Pyrolysis Oven",
      "product_mpn": "DOP7575X",
      "product_brand": "De Dietrich",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100060,
      "product_name": "Pyrolysis Oven (Front Panel)",
      "product_model": "Pyrolysis Oven (Front Panel)",
      "product_mpn": "DOP7575X",
      "product_brand": "De Dietrich",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100061,
      "product_name": "Integrated Electric Oven",
      "product_model": "Integrated Electric Oven",
      "product_mpn": "W11 OM1 4MS2 P",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100062,
      "product_name": "Integrated Electric Oven Panel",
      "product_model": "Integrated Electric Oven Panel",
      "product_mpn": "W11 OM1 4MS2 P",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100063,
      "product_name": "BI5222PX",
      "product_model": "BI5222PX",
      "product_mpn": "BI5222PX",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100064,
      "product_name": "BI5222PX",
      "product_model": "BI5222PX",
      "product_mpn": "BI5222PX",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100067,
      "product_name": "iOVEN P",
      "product_model": "iOVEN P",
      "product_mpn": "IOVENPInox",
      "product_brand": "Teka",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100068,
      "product_name": "iOVEN P",
      "product_model": "iOVEN P",
      "product_mpn": "IOVENPInox",
      "product_brand": "Teka",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100069,
      "product_name": "Oven DCF",
      "product_model": "Oven DCF",
      "product_mpn": "NV75N7646RS/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100070,
      "product_name": "Oven DCF",
      "product_model": "Oven DCF",
      "product_mpn": "NV75N7646RS/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100071,
      "product_name": "Oven Prezio-VP",
      "product_model": "Oven Prezio-VP",
      "product_mpn": "NV70H5587BB/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100072,
      "product_name": "Oven Prezio-VP",
      "product_model": "Oven Prezio-VP",
      "product_mpn": "NV70H5587BB/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100075,
      "product_name": "BPE742720M",
      "product_model": "BPE742720M",
      "product_mpn": "BPE742720M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100076,
      "product_name": "BPE742720M Panel",
      "product_model": "BPE742720M Panel",
      "product_mpn": "BPE742720M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100077,
      "product_name": "BPE642020M",
      "product_model": "BPE642020M",
      "product_mpn": "BPE642020M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100078,
      "product_name": "BPE642020M Panel",
      "product_model": "BPE642020M Panel",
      "product_mpn": "BPE642020M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100081,
      "product_name": "FA5 841 JH BLG HA Oven",
      "product_model": "FA5 841 JH BLG HA Oven",
      "product_mpn": "FA5 841 JH BLG HA",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100085,
      "product_name": "FI7 861 SH IX HA Oven",
      "product_model": "FI7 861 SH IX HA Oven",
      "product_mpn": "FI7 861 SH IX HA",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100087,
      "product_name": "BPE642120M",
      "product_model": "BPE642120M",
      "product_mpn": "BPE642120M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100088,
      "product_name": "BPE642120M",
      "product_model": "BPE642120M",
      "product_mpn": "BPE642120M",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100089,
      "product_name": "NV75K5571RS/EF",
      "product_model": "NV75K5571RS/EF",
      "product_mpn": "NV75K5571RS/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100091,
      "product_name": "NQ50T8539BK/EF",
      "product_model": "NQ50T8539BK/EF",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100093,
      "product_name": "NV75N5671RM/EF",
      "product_model": "NV75N5671RM/EF",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100095,
      "product_name": "NV75N5671RS/EF",
      "product_model": "NV75N5671RS/EF",
      "product_mpn": "NV75N5671RS/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100097,
      "product_name": "NV75N7677RS/EF",
      "product_model": "NV75N7677RS/EF",
      "product_mpn": "NV75N7677RS/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100099,
      "product_name": "NV75R7676RB/EF",
      "product_model": "NV75R7676RB/EF",
      "product_mpn": "NV75R7676RB/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100101,
      "product_name": "NV75T8979RK/EF",
      "product_model": "NV75T8979RK/EF",
      "product_mpn": "NV75T8979RK/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100103,
      "product_name": "NV75T9979CD/EF",
      "product_model": "NV75T9979CD/EF",
      "product_mpn": "NV75T9979CD/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100107,
      "product_name": "IDD 6340 IX",
      "product_model": "IDD 6340 IX",
      "product_mpn": "IDD 6340 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100108,
      "product_name": "IDD 6340 IX",
      "product_model": "IDD 6340 IX",
      "product_mpn": "IDD 6340 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100109,
      "product_name": "W7 OS4 4S1 P BL",
      "product_model": "W7 OS4 4S1 P BL",
      "product_mpn": "W7 OS4 4S1 P BL",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100110,
      "product_name": "W7 OS4 4S1 P BL",
      "product_model": "W7 OS4 4S1 P BL",
      "product_mpn": "W7 OS4 4S1 P BL",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100111,
      "product_name": "W7 OS4 4S1 P",
      "product_model": "W7 OS4 4S1 P",
      "product_mpn": "W7 OS4 4S1 P",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100112,
      "product_name": "W7 OS4 4S1 P",
      "product_model": "W7 OS4 4S1 P",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100113,
      "product_name": "AKP 785 IX",
      "product_model": "AKP 785 IX",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100114,
      "product_name": "AKP 785 IX",
      "product_model": "AKP 785 IX",
      "product_mpn": "AKP 785 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100115,
      "product_name": "DFW 5544 C IX",
      "product_model": "DFW 5544 C IX",
      "product_mpn": "DFW 5544 C IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100116,
      "product_name": "DFW 5544 C IX",
      "product_model": "DFW 5544 C IX",
      "product_mpn": "DFW 5544 C IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9200000,
      "product_name": "4 Zone Electric Ceramic Induction Hob",
      "product_model": "4 Zone Electric Ceramic Induction Hob",
      "product_mpn": "HXI64401AT",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200001,
      "product_name": "Wide Induction Hob with Touch Slider Control",
      "product_model": "Wide Induction Hob with Touch Slider Control",
      "product_mpn": "GIEI624410H",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200002,
      "product_name": "4 Burners Built-in Gas Hob - Black",
      "product_model": "4 Burners Built-in Gas Hob - Black",
      "product_mpn": "FTGHG 641 D/H(BK)",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200003,
      "product_name": "4 Burners Built-in Gas Hob - Stainless Steel",
      "product_model": "4 Burners Built-in Gas Hob - Stainless Steel",
      "product_mpn": "FTGHL 641 D/IX/H",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200004,
      "product_name": "Induction Glass-Ceramic Hob - Black",
      "product_model": "Induction Glass-Ceramic Hob - Black",
      "product_mpn": "ACC 654 F/NE",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200005,
      "product_name": "Active Cook Induction Ceramic Hob - Black",
      "product_model": "Active Cook Induction Ceramic Hob - Black",
      "product_mpn": "ACO 654 NE",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200006,
      "product_name": "Bridge Induction Hob 60 cm",
      "product_model": "Bridge Induction Hob 60 cm",
      "product_mpn": "IKE64441FB",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200007,
      "product_name": "Bridge Induction Hob 60 cm - Top View",
      "product_model": "Bridge Induction Hob 60 cm - Top View",
      "product_mpn": "IKE64441FB",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200008,
      "product_name": "Induction Hob - 60 cm",
      "product_model": "Induction Hob - 60 cm",
      "product_mpn": "IKB64301FB",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200009,
      "product_name": "Induction Hob 60 cm - Top View",
      "product_model": "Induction Hob 60 cm - Top View",
      "product_mpn": "IKB64301FB",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200010,
      "product_name": "Bridge Induction Hob 80 cm",
      "product_model": "Bridge Induction Hob 80 cm",
      "product_mpn": "IKE84441FB",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200011,
      "product_name": "Bridge Induction Hob 80 cm - Top View",
      "product_model": "Bridge Induction Hob 80 cm - Top View",
      "product_mpn": "IKE84441FB",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200012,
      "product_name": "Induction Hob",
      "product_model": "Induction Hob",
      "product_mpn": "BIT60336BK",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200013,
      "product_name": "Induction Hob - Top View",
      "product_model": "Induction Hob - Top View",
      "product_mpn": "BIT60336BK",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200014,
      "product_name": "Induction Hob",
      "product_model": "Induction Hob",
      "product_mpn": "DIT60336BK",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200015,
      "product_name": "Induction Hob - Top View",
      "product_model": "Induction Hob - Top View",
      "product_mpn": "DIT60336BK",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200016,
      "product_name": "Bridge induction Hob 60 cm",
      "product_model": "Bridge induction Hob 60 cm",
      "product_mpn": "DIV63340BK",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200017,
      "product_name": "Bridge induction Hob - Top View",
      "product_model": "Bridge induction Hob - Top View",
      "product_mpn": "DIV63340BK",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200018,
      "product_name": "I6456C",
      "product_model": "I6456C",
      "product_mpn": "I6456C",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200019,
      "product_name": "SMF 9010 C/NE/IXL",
      "product_model": "SMF 9010 C/NE/IXL",
      "product_mpn": "SMF 9010 C/NE/IXL",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200020,
      "product_name": "iKnob IT 6350",
      "product_model": "iKnob IT 6350",
      "product_mpn": "IT6350",
      "product_brand": "Teka",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200021,
      "product_name": "IAE6442SFB",
      "product_model": "IAE6442SFB",
      "product_mpn": "IAE6442SFB",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200024,
      "product_name": "SMO 658C/BT/IXL",
      "product_model": "SMO 658C/BT/IXL",
      "product_mpn": "SMO 658C/BT/IXL",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200025,
      "product_name": "HS 5160C NE",
      "product_model": "HS 5160C NE",
      "product_mpn": "HS 5160C NE",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200027,
      "product_name": "IB 88B60 NE",
      "product_model": "IB 88B60 NE",
      "product_mpn": "IB 88B60 NE",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200028,
      "product_name": "HB 3160C NE",
      "product_model": "HB 3160C NE",
      "product_mpn": "HB 3160C NE",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200029,
      "product_name": "ING 61T/BK UK",
      "product_model": "ING 61T/BK UK",
      "product_mpn": "ING 61T/BK UK",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9300000,
      "product_name": "Pro Style Upright Garment Steamer",
      "product_model": "Pro Style Upright Garment Steamer",
      "product_mpn": "IT3440G0",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "STEAMERS"
    },
    {
      "product_arid": 9300001,
      "product_name": "STILYS",
      "product_model": "STILYS",
      "product_mpn": "Stilys",
      "product_brand": "SteamOne",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "STEAMERS"
    },
    {
      "product_arid": 9400000,
      "product_name": "Chimney Hood 60 cm",
      "product_model": "Chimney Hood 60 cm",
      "product_mpn": "DKB5660HM",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9400001,
      "product_name": "Chimney Hood 90 cm",
      "product_model": "Chimney Hood 90 cm",
      "product_mpn": "DBB5960HM",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9400002,
      "product_name": "Decorative Cooker  Hood",
      "product_model": "Decorative Cooker  Hood",
      "product_mpn": "EFTF19X",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9400003,
      "product_name": "Decorative Hood AKR 037 G BL with 4 Speeds",
      "product_model": "Decorative Hood AKR 037 G BL with 4 Speeds",
      "product_mpn": "AKR 808 MR",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9400005,
      "product_name": "Chimney Cooker Hood - Stainless Steel",
      "product_model": "Chimney Cooker Hood - Stainless Steel",
      "product_mpn": "DHP7912X",
      "product_brand": "De Dietrich",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9400006,
      "product_name": "DHB7952G",
      "product_model": "DHB7952G",
      "product_mpn": "DHB7952G",
      "product_brand": "De Dietrich",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9400007,
      "product_name": "DHB7952X",
      "product_model": "DHB7952X",
      "product_mpn": "DHB7952X",
      "product_brand": "De Dietrich",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9400008,
      "product_name": "DHP7612X",
      "product_model": "DHP7612X",
      "product_mpn": "DHP7612X",
      "product_brand": "De Dietrich",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 9500000,
      "product_name": "ActiFry Plus Fryer - White",
      "product_model": "ActiFry Plus Fryer - White",
      "product_mpn": "GH840040",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 9500001,
      "product_name": "ActiFry Plus Fryer - Grey",
      "product_model": "ActiFry Plus Fryer - Grey",
      "product_mpn": "GH840B40",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 9500002,
      "product_name": "ActiFry Genius XL  Fryer - Black",
      "product_model": "ActiFry Genius XL  Fryer - Black",
      "product_mpn": "AH960800",
      "product_brand": "SEB",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 9500003,
      "product_name": "Airfryer XL",
      "product_model": "Airfryer XL",
      "product_mpn": "HD9240/90",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 9600000,
      "product_name": "14 Place Setting Dishwasher",
      "product_model": "14 Place Setting Dishwasher",
      "product_mpn": "G-6730-SC",
      "product_brand": "Miele",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600001,
      "product_name": "HDP 1D39W Full-size Dishwasher",
      "product_model": "HDP 1D39W Full-size Dishwasher",
      "product_mpn": "HDP1D39W",
      "product_brand": "Candy Hoover",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600002,
      "product_name": "Integrated Dish Washer",
      "product_model": "Integrated Dish Washer",
      "product_mpn": "DW60M9550BB/EU",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600004,
      "product_name": "Freestanding Dishwasher",
      "product_model": "Freestanding Dishwasher",
      "product_mpn": "G6730SC",
      "product_brand": "Miele",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600005,
      "product_name": "Freestanding Dishwasher",
      "product_model": "Freestanding Dishwasher",
      "product_mpn": "SPS50E42EU",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600006,
      "product_name": "Freestanding Dish Washer",
      "product_model": "Freestanding Dish Washer",
      "product_mpn": "DEN26X20G",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600008,
      "product_name": "FEB52600ZM Full-size Integrated Dishwasher",
      "product_model": "FEB52600ZM Full-size Integrated Dishwasher",
      "product_mpn": "FEB52600ZM",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600010,
      "product_name": "Retro Style DF6FABBL Full-size Dishwasher",
      "product_model": "Retro Style DF6FABBL Full-size Dishwasher",
      "product_mpn": "DI6FABBL",
      "product_brand": "SMEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600011,
      "product_name": "Fully Integrated Full Size Dishwasher with A++ energy efficiency",
      "product_model": "Fully Integrated Full Size Dishwasher with A++ energy efficiency",
      "product_mpn": "DW60M6040BB",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600012,
      "product_name": "Freestanding A++ Dishwasher AutoDosing",
      "product_model": "Freestanding A++ Dishwasher AutoDosing",
      "product_mpn": "DEN59420D",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600013,
      "product_name": "A++ Energy Rated Dishwasher with Corner Wash",
      "product_model": "A++ Energy Rated Dishwasher with Corner Wash",
      "product_mpn": "GNF41825",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600014,
      "product_name": "Ultima Dishwasher - Stainless Steel",
      "product_model": "Ultima Dishwasher - Stainless Steel",
      "product_mpn": "HSFO 3T223 W",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600015,
      "product_name": "Full-size Dishwasher - Stainless Steel",
      "product_model": "Full-size Dishwasher - Stainless Steel",
      "product_mpn": "HFP 4O22 WG C X",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600016,
      "product_name": "Smart Full-size Dishwasher - White",
      "product_model": "Smart Full-size Dishwasher - White",
      "product_mpn": "HFO 3T221 WG C",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600017,
      "product_name": "Ecotech Dishwasher - White",
      "product_model": "Ecotech Dishwasher - White",
      "product_mpn": "HFO 3C23 WF",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600018,
      "product_name": "Comfortlift - Integrated Dishwasher",
      "product_model": "Comfortlift - Integrated Dishwasher",
      "product_mpn": "FSS62800P",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600019,
      "product_name": "Comfortlift - Integrated Dishwasher with Open Door",
      "product_model": "Comfortlift - Integrated Dishwasher with Open Door",
      "product_mpn": "FSS62800P",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600020,
      "product_name": "Dishwasher with Power Dry and Power Clean",
      "product_model": "Dishwasher with Power Dry and Power Clean",
      "product_mpn": "WFO 3O33 DL X",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600026,
      "product_name": "DW60M6050FS/EC",
      "product_model": "DW60M6050FS/EC",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600027,
      "product_name": "DW60R7040FW/EF",
      "product_model": "DW60R7040FW/EF",
      "product_mpn": "DW60R7040FW/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600028,
      "product_name": "DW60R7050FS/EF",
      "product_model": "DW60R7050FS/EF",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 10300000,
      "product_name": "Serie 8 i-DOS WAWH8660GB Smart Washing Machine",
      "product_model": "Serie 8 i-DOS WAWH8660GB Smart Washing Machine",
      "product_mpn": "WAWH8660GB",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300001,
      "product_name": "Serie 8 i-DOS WAWH8660GB Smart Washing Machine",
      "product_model": "Serie 8 i-DOS WAWH8660GB Smart Washing Machine",
      "product_mpn": "WAT28661GB",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300003,
      "product_name": "HW100-BD14756 Washing Machine",
      "product_model": "HW100-BD14756 Washing Machine",
      "product_mpn": "HW100-BD14756",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300004,
      "product_name": "Dynamic Next DXOC 69AFN NFC 9 kg 1600 Spin Washing Machine",
      "product_model": "Dynamic Next DXOC 69AFN NFC 9 kg 1600 Spin Washing Machine",
      "product_mpn": "DXOC 69AFN NFC",
      "product_brand": "Candy Hoover",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300005,
      "product_name": "WIX845400 8 kg 1400 Spin Integrated Washing Machine",
      "product_model": "WIX845400 8 kg 1400 Spin Integrated Washing Machine",
      "product_mpn": "WIX845400",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300007,
      "product_name": "QuickDrive Smart 9 kg 1400 Spin Washing Machine",
      "product_model": "QuickDrive Smart 9 kg 1400 Spin Washing Machine",
      "product_mpn": "WW90M645OPX/EU",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300008,
      "product_name": "W1 WhiteEdition Washing Machine",
      "product_model": "W1 WhiteEdition Washing Machine",
      "product_mpn": "WMB120",
      "product_brand": "Miele",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300009,
      "product_name": "Series 9 Combi",
      "product_model": "Series 9 Combi",
      "product_mpn": "L9WEC169R",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300010,
      "product_name": "Washer EWF1286GDW2",
      "product_model": "Washer EWF1286GDW2",
      "product_mpn": "EWF1286GDW2",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300011,
      "product_name": "11kg Washing Machine",
      "product_model": "11kg Washing Machine",
      "product_mpn": "AQ114D69D",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300012,
      "product_name": "9 Kg Turbowash Washing Machine",
      "product_model": "9 Kg Turbowash Washing Machine",
      "product_mpn": "FH4U2VCN2",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300013,
      "product_name": "8 Kg Turbowash Washing Machine",
      "product_model": "8 Kg Turbowash Washing Machine",
      "product_mpn": "FH4U2TDN2W",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300014,
      "product_name": "12 Kg Turbowash Washing Machine",
      "product_model": "12 Kg Turbowash Washing Machine",
      "product_mpn": "FH495BDN8",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300015,
      "product_name": "12 Kg True Steam Washing Machine",
      "product_model": "12 Kg True Steam Washing Machine",
      "product_mpn": "FH495BDS2",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300016,
      "product_name": "12 Kg Centum System Washing Machine",
      "product_model": "12 Kg Centum System Washing Machine",
      "product_mpn": "FH6F9BDS2",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300017,
      "product_name": "9 Kg  Turbowash Washing Machine",
      "product_model": "9 Kg  Turbowash Washing Machine",
      "product_mpn": "FH4U2VCN8",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300018,
      "product_name": "Smart 10 Kg Direct Drive Washing Machine",
      "product_model": "Smart 10 Kg Direct Drive Washing Machine",
      "product_mpn": "FH4U2JCN2",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300019,
      "product_name": "Smart 10 Kg Direct Drive Washing Machine",
      "product_model": "Smart 10 Kg Direct Drive Washing Machine",
      "product_mpn": "FH4U2JCN8",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300020,
      "product_name": "8 Kg Direct Drive NFC Washing Machine",
      "product_model": "8 Kg Direct Drive NFC Washing Machine",
      "product_mpn": "FH4U2TDN2S",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300021,
      "product_name": "QuickDrive Smart 9 kg",
      "product_model": "QuickDrive Smart 9 kg",
      "product_mpn": "WW90M645OPW",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300022,
      "product_name": "WW5000 Washing Machine with ecobubble",
      "product_model": "WW5000 Washing Machine with ecobubble",
      "product_mpn": "WW80J5555FX",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300023,
      "product_name": "WW5000 Washing Machine with ecobubble",
      "product_model": "WW5000 Washing Machine with ecobubble",
      "product_mpn": "WW80J5555FW",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300024,
      "product_name": "WW5500 AddWash Washing Machine with ecobubble",
      "product_model": "WW5500 AddWash Washing Machine with ecobubble",
      "product_mpn": "WW80K5410UX",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300025,
      "product_name": "WW6800 QuickDrive Washing Machine with AddWash",
      "product_model": "WW6800 QuickDrive Washing Machine with AddWash",
      "product_mpn": "WW90M645OPW",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300026,
      "product_name": "WW8800 QuickDrive Washing Machine with AddWash",
      "product_model": "WW8800 QuickDrive Washing Machine with AddWash",
      "product_mpn": "WW10M86DQOA",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300027,
      "product_name": "WW5000 Washing Machine with ecobubble",
      "product_model": "WW5000 Washing Machine with ecobubble",
      "product_mpn": "WW90J5456FX",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300028,
      "product_name": "WW5500 AddWash Washing Machine with ecobubble",
      "product_model": "WW5500 AddWash Washing Machine with ecobubble",
      "product_mpn": "WW90K5410UX",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300029,
      "product_name": "WW6800 QuickDrive Washing Machine with AddWash",
      "product_model": "WW6800 QuickDrive Washing Machine with AddWash",
      "product_mpn": "WW90M645OPX",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300030,
      "product_name": "WW6800 QuickDrive Washing Machine with AddWash",
      "product_model": "WW6800 QuickDrive Washing Machine with AddWash",
      "product_mpn": "WW80M645OPW",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300031,
      "product_name": "WD4000 Washer Dryer with ecobubble",
      "product_model": "WD4000 Washer Dryer with ecobubble",
      "product_mpn": "WD80M4B53IW",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300032,
      "product_name": "Freestanding A 9kg 1400rpm Washing Machine",
      "product_model": "Freestanding A 9kg 1400rpm Washing Machine",
      "product_mpn": "WX943440",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300033,
      "product_name": "10Kg Washing Machine with 1400rpm Spin Speed",
      "product_model": "10Kg Washing Machine with 1400rpm Spin Speed",
      "product_mpn": "GWN410460C",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300034,
      "product_name": "8 kg 1400 Spin Washing Machine - Graphite",
      "product_model": "8 kg 1400 Spin Washing Machine - Graphite",
      "product_mpn": "NM10 844 GS",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300035,
      "product_name": "8 kg 1400 Spin Washing Machine - White",
      "product_model": "8 kg 1400 Spin Washing Machine - White",
      "product_mpn": "NM10 844 WW",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300036,
      "product_name": "9 kg 1400 Spin Washing Machine - Graphite",
      "product_model": "9 kg 1400 Spin Washing Machine - Graphite",
      "product_mpn": "NM10 944 GS",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300037,
      "product_name": "9 kg 1400 Spin Washing Machine - White",
      "product_model": "9 kg 1400 Spin Washing Machine - White",
      "product_mpn": "NM10 944 WW",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300038,
      "product_name": "ActiveCare 10 kg 1400 Spin Washing Machine - White",
      "product_model": "ActiveCare 10 kg 1400 Spin Washing Machine - White",
      "product_mpn": "NM11 1045 WC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300039,
      "product_name": "ActiveCare 8 kg 1400 Spin Washing Machine - Graphite",
      "product_model": "ActiveCare 8 kg 1400 Spin Washing Machine - Graphite",
      "product_mpn": "NM11 845 GC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300040,
      "product_name": "ActiveCare 8 kg 1400 Spin Washing Machine - White",
      "product_model": "ActiveCare 8 kg 1400 Spin Washing Machine - White",
      "product_mpn": "NM11 845 WC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300041,
      "product_name": "ActiveCare 9 kg 1400 Spin Washing Machine - Black",
      "product_model": "ActiveCare 9 kg 1400 Spin Washing Machine - Black",
      "product_mpn": "NM11 946 BC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300042,
      "product_name": "ActiveCare 9 kg 1400 Spin Washing Machine - Graphite",
      "product_model": "ActiveCare 9 kg 1400 Spin Washing Machine - Graphite",
      "product_mpn": "NM11 946 GC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300043,
      "product_name": "ActiveCare 9 kg 1400 Spin Washing Machine - White",
      "product_model": "ActiveCare 9 kg 1400 Spin Washing Machine - White",
      "product_mpn": "NM11 946 WC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300044,
      "product_name": "ActiveCare 9 kg 1600 Spin Washing Machine - Black",
      "product_model": "ActiveCare 9 kg 1600 Spin Washing Machine - Black",
      "product_mpn": "NM11 964 BC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300045,
      "product_name": "ActiveCare 9 kg 1600 Spin Washing Machine - Graphite",
      "product_model": "ActiveCare 9 kg 1600 Spin Washing Machine - Graphite",
      "product_mpn": "NM11 964 GC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300046,
      "product_name": "ActiveCare 9 kg 1600 Spin Washing Machine - White",
      "product_model": "ActiveCare 9 kg 1600 Spin Washing Machine - White",
      "product_mpn": "NM11 964 WC A",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300047,
      "product_name": "Aqua Washing Machine with Aquamatic 31005678",
      "product_model": "Aqua Washing Machine with Aquamatic 31005678",
      "product_mpn": "1D1035-07",
      "product_brand": "Candy",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300048,
      "product_name": "6000 Series Washing Machine 10 Kg",
      "product_model": "6000 Series Washing Machine 10 Kg",
      "product_mpn": "L6FBG142R",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300049,
      "product_name": "7000 Series 9 Kg Wash 6 Kg Dry",
      "product_model": "7000 Series 9 Kg Wash 6 Kg Dry",
      "product_mpn": "L7WEE965R",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300050,
      "product_name": "Washing Machine",
      "product_model": "Washing Machine",
      "product_mpn": "L7FEE169D",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300051,
      "product_name": "PerfectCare Washing Machine",
      "product_model": "PerfectCare Washing Machine",
      "product_mpn": "EW7F3816DB",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300052,
      "product_name": "Heat Pump Washer-Dryer",
      "product_model": "Heat Pump Washer-Dryer",
      "product_mpn": "EW9W1166RA",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300054,
      "product_name": "Free-standing Front Loading Washing Machine",
      "product_model": "Free-standing Front Loading Washing Machine",
      "product_mpn": "FWG91284W SP",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300055,
      "product_name": "8Kg Washing Machine",
      "product_model": "8Kg Washing Machine",
      "product_mpn": "FSCR80430",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300056,
      "product_name": "G+",
      "product_model": "G+",
      "product_mpn": "FH4G1BCS2",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300057,
      "product_name": "V900",
      "product_model": "V900",
      "product_mpn": "F4V910BTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300058,
      "product_name": "V900",
      "product_model": "V900",
      "product_mpn": "F4V910WTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300059,
      "product_name": "V900",
      "product_model": "V900",
      "product_mpn": "F4V909WTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300060,
      "product_name": "V700",
      "product_model": "V700",
      "product_mpn": "F4V710STS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300061,
      "product_name": "V700",
      "product_model": "V700",
      "product_mpn": "F4V710WTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300062,
      "product_name": "V700",
      "product_model": "V700",
      "product_mpn": "F4V709WTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300063,
      "product_name": "V400(WR)",
      "product_model": "V400(WR)",
      "product_mpn": "F4V509WS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300064,
      "product_name": "J+6 (C4)",
      "product_model": "J+6 (C4)",
      "product_mpn": "F4J610SS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300065,
      "product_name": "J+6 (C4)",
      "product_model": "J+6 (C4)",
      "product_mpn": "F4J610WS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300066,
      "product_name": "J+6 (C4)",
      "product_model": "J+6 (C4)",
      "product_mpn": "F4J609SS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300067,
      "product_name": "J+6 (C4)",
      "product_model": "J+6 (C4)",
      "product_mpn": "F4J609WS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300068,
      "product_name": "J+6 (C4)",
      "product_model": "J+6 (C4)",
      "product_mpn": "F4J609WN",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300069,
      "product_name": "J+6 (C4)",
      "product_model": "J+6 (C4)",
      "product_mpn": "F4J608WN",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300070,
      "product_name": "V900",
      "product_model": "V900",
      "product_mpn": "FWV917WTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300071,
      "product_name": "V900",
      "product_model": "V900",
      "product_mpn": "FWV996WTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300072,
      "product_name": "V700",
      "product_model": "V700",
      "product_mpn": "FWV796STS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300073,
      "product_name": "V700",
      "product_model": "V700",
      "product_mpn": "FWV796WTS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300074,
      "product_name": "V400(WR)",
      "product_model": "V400(WR)",
      "product_mpn": "FWV595WS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300075,
      "product_name": "J+6 (C4)",
      "product_model": "J+6 (C4)",
      "product_mpn": "FWJ685WS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300076,
      "product_name": "V900",
      "product_model": "V900",
      "product_mpn": "F4V909BTS.ABLQPUK",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300077,
      "product_name": "V700",
      "product_model": "V700",
      "product_mpn": "F4V709STS.ASSQPUK",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300078,
      "product_name": "WFGS1016VM A+++",
      "product_model": "WFGS1016VM A+++",
      "product_mpn": "WFGS1016VM",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300079,
      "product_name": "DHGS90M A++",
      "product_model": "DHGS90M A++",
      "product_mpn": "DHGS90M",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300082,
      "product_name": "WM New AW Slim",
      "product_model": "WM New AW Slim",
      "product_mpn": "WW70R62LVSXDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300083,
      "product_name": "WM Q.Drive",
      "product_model": "WM Q.Drive",
      "product_mpn": "WW10M86KNOA/LP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300084,
      "product_name": "WM AW Slim",
      "product_model": "WM AW Slim",
      "product_mpn": "WW65K42E08WDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300085,
      "product_name": "WM AW Slim",
      "product_model": "WM AW Slim",
      "product_mpn": "WW65K42E00SDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300086,
      "product_name": "WM Q.Drive",
      "product_model": "WM Q.Drive",
      "product_mpn": "WW90M74LNOA/LP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300087,
      "product_name": "WM AW Slim",
      "product_model": "WM AW Slim",
      "product_mpn": "WW65K52E69WDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300088,
      "product_name": "WM New AW Slim",
      "product_model": "WM New AW Slim",
      "product_mpn": "WW70R62LATXDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300089,
      "product_name": "WM AW Slim",
      "product_model": "WM AW Slim",
      "product_mpn": "WW65K52E69SDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300090,
      "product_name": "WM AW Slim",
      "product_model": "WM AW Slim",
      "product_mpn": "WW70R62LATWDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300091,
      "product_name": "WM Standard",
      "product_model": "WM Standard",
      "product_mpn": "WW90J5446FW/LP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300092,
      "product_name": "WM New Slim",
      "product_model": "WM New Slim",
      "product_mpn": "WW80R42LXEWDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300093,
      "product_name": "WM WW3000",
      "product_model": "WM WW3000",
      "product_mpn": "WW60J32G0PWDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300094,
      "product_name": "WM New Slim",
      "product_model": "WM New Slim",
      "product_mpn": "WW80R52LCFSDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300095,
      "product_name": "WM New Slim",
      "product_model": "WM New Slim",
      "product_mpn": "WW80R62LAFXDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300096,
      "product_name": "WM WW5000",
      "product_model": "WM WW5000",
      "product_mpn": "WW70J52E0HSDLP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300097,
      "product_name": "WM Standard",
      "product_model": "WM Standard",
      "product_mpn": "WW90J5446FX/LP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300098,
      "product_name": "WW10N645RBX/EU",
      "product_model": "WW10N645RBX/EU",
      "product_mpn": "WW10N645RBX/EU",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300099,
      "product_name": "RST 6029 ST S RU",
      "product_model": "RST 6029 ST S RU",
      "product_mpn": "RST 6029 ST S RU",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300100,
      "product_name": "RSD 8229 ST X RU",
      "product_model": "RSD 8229 ST X RU",
      "product_mpn": "RSD 8229 ST X RU",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300101,
      "product_name": "RST 703 DW",
      "product_model": "RST 703 DW",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300102,
      "product_name": "RST 601 W",
      "product_model": "RST 601 W",
      "product_mpn": "RST 601 W",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300103,
      "product_name": "RSM 601 W",
      "product_model": "RSM 601 W",
      "product_mpn": "RSM 601 W",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300107,
      "product_name": "BWE 81483X W UK",
      "product_model": "BWE 81483X W UK",
      "product_mpn": "BWE 81483X W UK",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300108,
      "product_name": "BWE 91484X W UK",
      "product_model": "BWE 91484X W UK",
      "product_mpn": "BWE 91484X W UK",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300109,
      "product_name": "FCG926",
      "product_model": "FCG926",
      "product_mpn": "FCG926",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300110,
      "product_name": "WFR629GWKS IT",
      "product_model": "WFR629GWKS IT",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300111,
      "product_name": "FWG81284SB SP",
      "product_model": "FWG81284SB SP",
      "product_mpn": "FWG81284SB SP",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300112,
      "product_name": "MTWC 91282 W UK",
      "product_model": "MTWC 91282 W UK",
      "product_mpn": "MTWC 91282 W UK",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300113,
      "product_name": "AUTODOSE 9425",
      "product_model": "AUTODOSE 9425",
      "product_mpn": "AUTODOSE 9425",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300114,
      "product_name": "SUPREME 8415",
      "product_model": "SUPREME 8415",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300115,
      "product_name": "BEST ZEN 8",
      "product_model": "BEST ZEN 8",
      "product_mpn": "BEST ZEN 8",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 11700000,
      "product_name": "Xbox One X",
      "product_model": "Xbox One X",
      "product_mpn": "5C5-00001",
      "product_brand": "Microsoft",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11700003,
      "product_name": "Xbox One S",
      "product_model": "Xbox One S",
      "product_mpn": "ZQ9-00001",
      "product_brand": "Microsoft",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11700004,
      "product_name": "G29 Driving Force Racing Wheel",
      "product_model": "G29 Driving Force Racing Wheel",
      "product_mpn": "941-000113",
      "product_brand": "Logitech G",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11900000,
      "product_name": "G920 (Black)",
      "product_model": "G920 (Black)",
      "product_mpn": "941-000123",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 12300000,
      "product_name": "Logitech G PRO X Gaming Headset",
      "product_model": "Logitech G PRO X Gaming Headset",
      "product_mpn": "981-000818",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400000,
      "product_name": "Steamcam (Graphyte)",
      "product_model": "Steamcam (Graphyte)",
      "product_mpn": "960-001281",
      "product_brand": "Logitech",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 12400001,
      "product_name": "StreamCam (White)",
      "product_model": "StreamCam (White)",
      "product_mpn": "960-001297",
      "product_brand": "Logitech",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 12400002,
      "product_name": "Spotlight (Slate)",
      "product_model": "Spotlight (Slate)",
      "product_mpn": "910-005166",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12500000,
      "product_name": "Pro Express Care High Pressure Steam Generator Iron",
      "product_model": "Pro Express Care High Pressure Steam Generator Iron",
      "product_mpn": "GV9060G0",
      "product_brand": "Tefal",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "IRONINGS"
    },
    {
      "product_arid": 12500001,
      "product_name": "CareStyle Compact Iron",
      "product_model": "CareStyle Compact Iron",
      "product_mpn": "IS2044.VI",
      "product_brand": "Braun",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "IRONINGS"
    },
    {
      "product_arid": 12600000,
      "product_name": "StainPro 10",
      "product_model": "StainPro 10",
      "product_mpn": "14562",
      "product_brand": "Bissell",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600002,
      "product_name": "V8 Absolute Cordless Bagless Vacuum Cleaner",
      "product_model": "V8 Absolute Cordless Bagless Vacuum Cleaner",
      "product_mpn": "214744-01",
      "product_brand": "Dyson",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600003,
      "product_name": "V7 Motorhead Cordless Bagless Vacuum Cleaner",
      "product_model": "V7 Motorhead Cordless Bagless Vacuum Cleaner",
      "product_mpn": "227591-01",
      "product_brand": "Dyson",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600004,
      "product_name": "ERGORAPIDO",
      "product_model": "ERGORAPIDO",
      "product_mpn": "ZB3212",
      "product_brand": "Electrolux",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600005,
      "product_name": "ULTRASILENCER ZEN",
      "product_model": "ULTRASILENCER ZEN",
      "product_mpn": "EUS8GREEN",
      "product_brand": "Electrolux",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600006,
      "product_name": "Air Stretch Pet Plus Upright Bagless Vacuum Cleaner",
      "product_model": "Air Stretch Pet Plus Upright Bagless Vacuum Cleaner",
      "product_mpn": "U85-AS-PPE",
      "product_brand": "Vax",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600007,
      "product_name": "Slim Vac Pets & Family TBTTV1P3 Cordless Vacuum Cleaner",
      "product_model": "Slim Vac Pets & Family TBTTV1P3 Cordless Vacuum Cleaner",
      "product_mpn": "TBTTV1P3",
      "product_brand": "Vax",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600008,
      "product_name": "Freedom FD22G Cordless Vacuum Cleaner",
      "product_model": "Freedom FD22G Cordless Vacuum Cleaner",
      "product_mpn": "FD22G",
      "product_brand": "Candy Hoover",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600009,
      "product_name": "Scout RX1 Robot Vacuum Cleaner",
      "product_model": "Scout RX1 Robot Vacuum Cleaner",
      "product_mpn": "RX1 - SJQL0",
      "product_brand": "Miele",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600010,
      "product_name": "Athlet Cordless Vacuum Cleaner",
      "product_model": "Athlet Cordless Vacuum Cleaner",
      "product_mpn": "BCH6ATH1GB",
      "product_brand": "Bosch",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600011,
      "product_name": "V8 Absolute Cordless Bagless Vacuum Cleaner",
      "product_model": "V8 Absolute Cordless Bagless Vacuum Cleaner",
      "product_mpn": "214744-01",
      "product_brand": "Dyson",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600012,
      "product_name": "Roomba 980 Robot Vacuum with Wi-Fi",
      "product_model": "Roomba 980 Robot Vacuum with Wi-Fi",
      "product_mpn": "Roomba 980",
      "product_brand": "iRobot",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600013,
      "product_name": "Cordless Handheld Vacuum Cleaner",
      "product_model": "Cordless Handheld Vacuum Cleaner",
      "product_mpn": "WV200UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600014,
      "product_name": "Botvac D701 Connected",
      "product_model": "Botvac D701 Connected",
      "product_mpn": "945-0296",
      "product_brand": "Neato",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600015,
      "product_name": "Botvac D703 Connected",
      "product_model": "Botvac D703 Connected",
      "product_mpn": "945-0284",
      "product_brand": "Neato",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600016,
      "product_name": "Botvac D402 Connected",
      "product_model": "Botvac D402 Connected",
      "product_mpn": "945-0317",
      "product_brand": "Neato",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600017,
      "product_name": "Botvac D404 Connected",
      "product_model": "Botvac D404 Connected",
      "product_mpn": "945-0313",
      "product_brand": "Neato",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600018,
      "product_name": "Botvac D602 Connected",
      "product_model": "Botvac D602 Connected",
      "product_mpn": "945-0318",
      "product_brand": "Neato",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600019,
      "product_name": "Botvac D603 Connected",
      "product_model": "Botvac D603 Connected",
      "product_mpn": "945-0314",
      "product_brand": "Neato",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600020,
      "product_name": "VC Jet",
      "product_model": "VC Jet",
      "product_mpn": "VS20R9046S3/EV",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600021,
      "product_name": "V11 Absolute",
      "product_model": "V11 Absolute",
      "product_mpn": "V11",
      "product_brand": "Dyson",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600022,
      "product_name": "Vaccume Cleaner",
      "product_model": "Vaccume Cleaner",
      "product_mpn": "VS20T7536T5",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600023,
      "product_name": "One Power Glide",
      "product_model": "One Power Glide",
      "product_mpn": "",
      "product_brand": "Vax",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600024,
      "product_name": "VC with Zstation",
      "product_model": "VC with Zstation",
      "product_mpn": "VS20R9046S3",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 13500000,
      "product_name": "RH7000 Food ShowCase, 555 L",
      "product_model": "RH7000 Food ShowCase, 555 L",
      "product_mpn": "RH56J69187F",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500001,
      "product_name": "American-Style Fridge Freezer",
      "product_model": "American-Style Fridge Freezer",
      "product_mpn": "RMB76111NX",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500002,
      "product_name": "Integrated Refrigerator",
      "product_model": "Integrated Refrigerator",
      "product_mpn": "ENN2853COW",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500004,
      "product_name": "HVBF5182WWK Fridge Freezer",
      "product_model": "HVBF5182WWK Fridge Freezer",
      "product_mpn": "HVBF5182WWK",
      "product_brand": "Candy Hoover",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500005,
      "product_name": "Tall Fridge Freezer",
      "product_model": "Tall Fridge Freezer",
      "product_mpn": "CVB20RR1",
      "product_brand": "SMEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500006,
      "product_name": "Multi Door 60/40 Fridge Freezer",
      "product_model": "Multi Door 60/40 Fridge Freezer",
      "product_mpn": "A2FE635CCJ",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500007,
      "product_name": "Exxcel Fridge Freezer",
      "product_model": "Exxcel Fridge Freezer",
      "product_mpn": "KGE49BI30G",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500008,
      "product_name": "Freestanding fridge-freezer",
      "product_model": "Freestanding fridge-freezer",
      "product_mpn": "KFN 16947 D",
      "product_brand": "Miele",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500009,
      "product_name": "CXFG1691S 50/50 Fridge Freezer",
      "product_model": "CXFG1691S 50/50 Fridge Freezer",
      "product_mpn": "CXFG1691S",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500011,
      "product_name": "535L American Refridgerator",
      "product_model": "535L American Refridgerator",
      "product_mpn": "RS53K4400SA",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500012,
      "product_name": "Refrigerator with Water & Ice Dispenser",
      "product_model": "Refrigerator with Water & Ice Dispenser",
      "product_mpn": "GSL961PZBV",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500013,
      "product_name": "InstaView Door-in-Door Refrigerator",
      "product_model": "InstaView Door-in-Door Refrigerator",
      "product_mpn": "GSX961NSAZ",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500014,
      "product_name": "Fridge Freezer with Wine Rack",
      "product_model": "Fridge Freezer with Wine Rack",
      "product_mpn": "GBB60PZGFB",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500015,
      "product_name": "Refrigerator with Dispenser and Smart Diagnosis",
      "product_model": "Refrigerator with Dispenser and Smart Diagnosis",
      "product_mpn": "GSL760WBXV",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500016,
      "product_name": "Fridge Freezer with  Dispenser",
      "product_model": "Fridge Freezer with  Dispenser",
      "product_mpn": "GBF59NSKZB",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500017,
      "product_name": "Door-in-Door Refrigerator with Dispenser",
      "product_model": "Door-in-Door Refrigerator with Dispenser",
      "product_mpn": "GSJ961NSBV",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500018,
      "product_name": "American-Style Smart Fridge Freezer",
      "product_model": "American-Style Smart Fridge Freezer",
      "product_mpn": "GSL960PZBV",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500019,
      "product_name": "InstaView Door-in-Door Refrigerater",
      "product_model": "InstaView Door-in-Door Refrigerater",
      "product_mpn": "GSX960NSAZ",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500020,
      "product_name": "Signature InstaView Door-in-Door Refrigerator",
      "product_model": "Signature InstaView Door-in-Door Refrigerator",
      "product_mpn": "LSR100",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500021,
      "product_name": "Large Capacity Refrigerator with Dispenser",
      "product_model": "Large Capacity Refrigerator with Dispenser",
      "product_mpn": "GSL761WBXV",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500022,
      "product_name": "Refrigerator with Door-in-Door and Multidrawer Freezer",
      "product_model": "Refrigerator with Door-in-Door and Multidrawer Freezer",
      "product_mpn": "GM6140PZQV",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500023,
      "product_name": "American-Style Smart Refrigerator",
      "product_model": "American-Style Smart Refrigerator",
      "product_mpn": "GSX961MTAZ",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500024,
      "product_name": "Fridge Freezer with Full Wine Rack and Dispenser",
      "product_model": "Fridge Freezer with Full Wine Rack and Dispenser",
      "product_mpn": "GBF60NSFZB",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500025,
      "product_name": "Instaview Door-In-Door Multi Door Smart Fridge Freezer",
      "product_model": "Instaview Door-In-Door Multi Door Smart Fridge Freezer",
      "product_mpn": "GMX936SBHV",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500026,
      "product_name": "Smart 70/30 Fridge Freezer",
      "product_model": "Smart 70/30 Fridge Freezer",
      "product_mpn": "GBB60MCGFS",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500027,
      "product_name": "Centum System Combined Refrigerator Energy Class A+++",
      "product_model": "Centum System Combined Refrigerator Energy Class A+++",
      "product_mpn": "GBB60NSYQE",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500028,
      "product_name": "Family Hub Multi-door Fridge Freezer",
      "product_model": "Family Hub Multi-door Fridge Freezer",
      "product_mpn": "RF56M9540SR",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500029,
      "product_name": "American-Style Fridge Freezer",
      "product_model": "American-Style Fridge Freezer",
      "product_mpn": "RS50N3513S8",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500030,
      "product_name": "RS8000 with Non Plumbed Water & Ice Dispenser",
      "product_model": "RS8000 with Non Plumbed Water & Ice Dispenser",
      "product_mpn": "RS68N8330B1",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500031,
      "product_name": "RS8000 with SpaceMax Technology",
      "product_model": "RS8000 with SpaceMax Technology",
      "product_mpn": "RS67N8210S9",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500032,
      "product_name": "Family Hub American Style Fridge Freezer",
      "product_model": "Family Hub American Style Fridge Freezer",
      "product_mpn": "RS68N8941SL",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500033,
      "product_name": "RS8000 with SpaceMax Technology",
      "product_model": "RS8000 with SpaceMax Technology",
      "product_mpn": "RS68N8230B1",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500034,
      "product_name": "RB31 Fridge Freezer with Digital Inverter Technology",
      "product_model": "RB31 Fridge Freezer with Digital Inverter Technology",
      "product_mpn": "RB31FDRNDSA",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500035,
      "product_name": "American-Style Fridge Freezer",
      "product_model": "American-Style Fridge Freezer",
      "product_mpn": "RS68N8320S9",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500036,
      "product_name": "RS3000 with Plumbed Water & Ice",
      "product_model": "RS3000 with Plumbed Water & Ice",
      "product_mpn": "RS50N3513BC",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500037,
      "product_name": "RS8000 with SpaceMax Technology",
      "product_model": "RS8000 with SpaceMax Technology",
      "product_mpn": "RS68N8240S9",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500038,
      "product_name": "RB5000 Fridge Freezer with All-Around Cooling",
      "product_model": "RB5000 Fridge Freezer with All-Around Cooling",
      "product_mpn": "RB37J5330SA",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500039,
      "product_name": "American Style Fridge Freezer With Multi-Zone",
      "product_model": "American Style Fridge Freezer With Multi-Zone",
      "product_mpn": "GNE480E20F",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500040,
      "product_name": "Freestanding American Style Fridge Freezer",
      "product_model": "Freestanding American Style Fridge Freezer",
      "product_mpn": "GNE60520",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500041,
      "product_name": "American Style Fridge Freezer With Multi-Zone",
      "product_model": "American Style Fridge Freezer With Multi-Zone",
      "product_mpn": "MN1416224D",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500042,
      "product_name": "American Style Fridge Freezer",
      "product_model": "American Style Fridge Freezer",
      "product_mpn": "GSBS16312",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500043,
      "product_name": "American Style Fridge Freezer",
      "product_model": "American Style Fridge Freezer",
      "product_mpn": "GQN21220W",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500044,
      "product_name": "Active Quattro Fridge Freezer",
      "product_model": "Active Quattro Fridge Freezer",
      "product_mpn": "HQ9 E1L",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500045,
      "product_name": "Cube HTF-610DM7 Fridge Freezer - Silver",
      "product_model": "Cube HTF-610DM7 Fridge Freezer - Silver",
      "product_mpn": "HTF-610DM7",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500046,
      "product_name": "Frost Free Built-In Fridge Freezer",
      "product_model": "Frost Free Built-In Fridge Freezer",
      "product_mpn": "SCE8181VTS",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500047,
      "product_name": "Frost Free Built-In Fridge Freezer with Open Door",
      "product_model": "Frost Free Built-In Fridge Freezer with Open Door",
      "product_mpn": "SCE8181VTS",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500048,
      "product_name": "Frost Free Free-Standing Fridge Freezer",
      "product_model": "Frost Free Free-Standing Fridge Freezer",
      "product_mpn": "RMB76311NX",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500049,
      "product_name": "Frost Free Free-Standing Fridge Freezer  with Open Door",
      "product_model": "Frost Free Free-Standing Fridge Freezer  with Open Door",
      "product_mpn": "RMB76311NX",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500050,
      "product_name": "Free Standing 2 Door Refrigerator",
      "product_model": "Free Standing 2 Door Refrigerator",
      "product_mpn": "LLT9VA52U",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500051,
      "product_name": "Free Standing 2 Door Refrigerator with Open Door",
      "product_model": "Free Standing 2 Door Refrigerator with Open Door",
      "product_mpn": "LLT9VA52U",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500054,
      "product_name": "Refrigerator-freezer",
      "product_model": "Refrigerator-freezer",
      "product_mpn": "W7 911I OX H",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500055,
      "product_name": "Refrigerator-freezer",
      "product_model": "Refrigerator-freezer",
      "product_mpn": "W7 921O W H",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500056,
      "product_name": "American Side-by-Side Refrigerator",
      "product_model": "American Side-by-Side Refrigerator",
      "product_mpn": "WQ9 B1L",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500057,
      "product_name": "RB38R7839B1/EU",
      "product_model": "RB38R7839B1/EU",
      "product_mpn": "RB38R7839B1/EU",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500058,
      "product_name": "RB38R7737S9/EU",
      "product_model": "RB38R7737S9/EU",
      "product_mpn": "RB38R7737S9/EU",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500059,
      "product_name": "EN3885MOX",
      "product_model": "EN3885MOX",
      "product_mpn": "EN3885MOX",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500061,
      "product_name": "W7 831T MX",
      "product_model": "W7 831T MX",
      "product_mpn": "W7 831T MX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13600000,
      "product_name": "HX6632/15 SONICARE SÉRIE 3",
      "product_model": "HX6632/15 SONICARE SÉRIE 3",
      "product_mpn": "HX6631/15",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOOTHBRUSHES"
    },
    {
      "product_arid": 13600001,
      "product_name": "Genius Pro 9000 Electric Toothbrush",
      "product_model": "Genius Pro 9000 Electric Toothbrush",
      "product_mpn": "GENIUS9000",
      "product_brand": "Oral B",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOOTHBRUSHES"
    },
    {
      "product_arid": 13600002,
      "product_name": "Oral-B Genius AI",
      "product_model": "Oral-B Genius AI",
      "product_mpn": "",
      "product_brand": "Oral B",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOOTHBRUSHES"
    },
    {
      "product_arid": 13700000,
      "product_name": "Bikini Genie for Women",
      "product_model": "Bikini Genie for Women",
      "product_mpn": "BRT382/15",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "CLIPPERS & CUTTERS"
    },
    {
      "product_arid": 13800000,
      "product_name": "Series 7000 Electric Cutter",
      "product_model": "Series 7000 Electric Cutter",
      "product_mpn": "HC7460/15",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "CLIPPERS & CUTTERS"
    },
    {
      "product_arid": 13800001,
      "product_name": "Cordless Hair Clipper",
      "product_model": "Cordless Hair Clipper",
      "product_mpn": "HC5010",
      "product_brand": "Braun",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "CLIPPERS & CUTTERS"
    },
    {
      "product_arid": 13800002,
      "product_name": "BT5090 Beard Trimmer",
      "product_model": "BT5090 Beard Trimmer",
      "product_mpn": "BT5090",
      "product_brand": "Braun",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "CLIPPERS & CUTTERS"
    },
    {
      "product_arid": 13900000,
      "product_name": "Landmark bath - Gun metal grey",
      "product_model": "Landmark bath - Gun metal grey",
      "product_mpn": "",
      "product_brand": "Landmark",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "BATHTUBS"
    },
    {
      "product_arid": 13900001,
      "product_name": "iflo Herita Free/S Bath with Tap",
      "product_model": "iflo Herita Free/S Bath with Tap",
      "product_mpn": "136415",
      "product_brand": "Travis Perkins",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "BATHTUBS"
    },
    {
      "product_arid": 13900002,
      "product_name": "iflo Herita Free/S Bath",
      "product_model": "iflo Herita Free/S Bath",
      "product_mpn": "136415",
      "product_brand": "Travis Perkins",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "BATHTUBS"
    },
    {
      "product_arid": 13900004,
      "product_name": "Sunstruck 36-in W x 66-in L White Acrylic Oval Center Drain Freestanding Air Bath",
      "product_model": "Sunstruck 36-in W x 66-in L White Acrylic Oval Center Drain Freestanding Air Bath",
      "product_mpn": "",
      "product_brand": "Kohler",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "BATHTUBS"
    },
    {
      "product_arid": 14300001,
      "product_name": "Blocks Flyer x City Grounds 26\" BMX Bike",
      "product_model": "Blocks Flyer x City Grounds 26\" BMX Bike",
      "product_mpn": "10BX-SE2105",
      "product_brand": "SE Bikes",
      "product_category": "OUTDOORS",
      "product_subcategory": "BIKES"
    },
    {
      "product_arid": 14500000,
      "product_name": "Satin Hair7 Professional SensoDryer",
      "product_model": "Satin Hair7 Professional SensoDryer",
      "product_mpn": "HD780",
      "product_brand": "Braun",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "HAIR DRYERS"
    },
    {
      "product_arid": 14600000,
      "product_name": "Cam Smart Security Camera",
      "product_model": "Cam Smart Security Camera",
      "product_mpn": "NC1102ES",
      "product_brand": "Nest",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "SECURITY CAMERAS"
    },
    {
      "product_arid": 14800000,
      "product_name": "Learning Thermostat - 3rd Generation",
      "product_model": "Learning Thermostat - 3rd Generation",
      "product_mpn": "T3010IT",
      "product_brand": "Nest",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "SMART CONTROLS"
    },
    {
      "product_arid": 14900000,
      "product_name": "Ultima High Resolution Player",
      "product_model": "Ultima High Resolution Player",
      "product_mpn": "SP1000",
      "product_brand": "A&K",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 15100000,
      "product_name": "BOILER ELECTRIC ARISTON VERTICAL PRO PLUS 80 V/5",
      "product_model": "BOILER ELECTRIC ARISTON VERTICAL PRO PLUS 80 V/5",
      "product_mpn": "3200768",
      "product_brand": "Ariston",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "BOILERS"
    },
    {
      "product_arid": 15400001,
      "product_name": "V3 Extended Trip Expandable Packing Case",
      "product_model": "V3 Extended Trip Expandable Packing Case",
      "product_mpn": "228269D",
      "product_brand": "Tumi",
      "product_category": "TRAVEL",
      "product_subcategory": "LUGGAGES"
    },
    {
      "product_arid": 15400002,
      "product_name": "Bobby, anti-theft backpack, Black",
      "product_model": "Bobby, anti-theft backpack, Black",
      "product_mpn": "P705.541",
      "product_brand": "XD Designs",
      "product_category": "TRAVEL",
      "product_subcategory": "LUGGAGES"
    },
    {
      "product_arid": 15600000,
      "product_name": "DV5000 Heat Pump Tumble Dryer A++",
      "product_model": "DV5000 Heat Pump Tumble Dryer A++",
      "product_mpn": "DV90M50003X",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600001,
      "product_name": "DV5000 Heat Pump Tumble Dryer A++",
      "product_model": "DV5000 Heat Pump Tumble Dryer A++",
      "product_mpn": "DV80M5010QX",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600002,
      "product_name": "Freestanding 9kg Tumble Dryer",
      "product_model": "Freestanding 9kg Tumble Dryer",
      "product_mpn": "DHX93460",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600003,
      "product_name": "Integrated Washer Dryer 8kg 5kg Capacity",
      "product_model": "Integrated Washer Dryer 8kg 5kg Capacity",
      "product_mpn": "WDIX8543100",
      "product_brand": "Beko",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600004,
      "product_name": "8 Kg Heat Pump White Tumble Dryer",
      "product_model": "8 Kg Heat Pump White Tumble Dryer",
      "product_mpn": "GTN38267GC",
      "product_brand": "Grundig",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600005,
      "product_name": "8 Kg Tumble Dryer - White",
      "product_model": "8 Kg Tumble Dryer - White",
      "product_mpn": "NT M10 81WK",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600006,
      "product_name": "ActiveCare 8 Kg Tumble Dryer - White",
      "product_model": "ActiveCare 8 Kg Tumble Dryer - White",
      "product_mpn": "NT M11 82XB",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600007,
      "product_name": "ActiveCare 9 Kg Tumble Dryer - White",
      "product_model": "ActiveCare 9 Kg Tumble Dryer - White",
      "product_mpn": "NT M11 92XB",
      "product_brand": "Hotpoint",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600008,
      "product_name": "Heat Pump Tumble Dryer",
      "product_model": "Heat Pump Tumble Dryer",
      "product_mpn": "EW9H3825RA",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600009,
      "product_name": "Free-standing Washer-Dryer",
      "product_model": "Free-standing Washer-Dryer",
      "product_mpn": "XWDA 751680X W EU",
      "product_brand": "Indesit",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600010,
      "product_name": "Free-standing Washer-Dryer",
      "product_model": "Free-standing Washer-Dryer",
      "product_mpn": "FWDG 96148 SBS EU",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600011,
      "product_name": "Dryer Q.Drive",
      "product_model": "Dryer Q.Drive",
      "product_mpn": "DV90N8289AW/LP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600012,
      "product_name": "Dryer",
      "product_model": "Dryer",
      "product_mpn": "DV90K6000CW/LP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600013,
      "product_name": "T8DED813D",
      "product_model": "T8DED813D",
      "product_mpn": "T8DED813D",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600015,
      "product_name": "FT M11 82 EU",
      "product_model": "FT M11 82 EU",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600016,
      "product_name": "FT M22 9X2S EU",
      "product_model": "FT M22 9X2S EU",
      "product_mpn": "FT M22 9X2S EU",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15600017,
      "product_name": "ST U 92X EU",
      "product_model": "ST U 92X EU",
      "product_mpn": "ST U 92X EU",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 15700000,
      "product_name": "Sawn Treated Timber Regularised",
      "product_model": "Sawn Treated Timber Regularised",
      "product_mpn": "C16/C24",
      "product_brand": "Travis Perkins",
      "product_category": "DIY",
      "product_subcategory": "TIMBERS"
    },
    {
      "product_arid": 16000000,
      "product_name": "LG Signature",
      "product_model": "LG Signature",
      "product_mpn": "LSR100.AGRQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000001,
      "product_name": "P-Next 6",
      "product_model": "P-Next 6",
      "product_mpn": "GMX936SBHV.ASBQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000002,
      "product_name": "P-Next 6",
      "product_model": "P-Next 6",
      "product_mpn": "GML936NSHV.ANSQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000003,
      "product_name": "P-Next 8",
      "product_model": "P-Next 8",
      "product_mpn": "GMX844MCKV.AMCQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000004,
      "product_name": "P-Next 8",
      "product_model": "P-Next 8",
      "product_mpn": "GML844PZKV.APZQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000005,
      "product_name": "P-Veyron6 Refresh",
      "product_model": "P-Veyron6 Refresh",
      "product_mpn": "GSX961MCVZ.AMCQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000006,
      "product_name": "P-Veyron6 Refresh",
      "product_model": "P-Veyron6 Refresh",
      "product_mpn": "GSX960MCVZ.AMCQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000007,
      "product_name": "P-Veyron6 Refresh",
      "product_model": "P-Veyron6 Refresh",
      "product_mpn": "GSX961NSVZ.ANSQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000008,
      "product_name": "P-Veyron6 Refresh",
      "product_model": "P-Veyron6 Refresh",
      "product_mpn": "GSJ961NSVV.ANSQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000009,
      "product_name": "P-Veyron6",
      "product_model": "P-Veyron6",
      "product_mpn": "GSL961PZBV.APZQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000010,
      "product_name": "P-Veyron6",
      "product_model": "P-Veyron6",
      "product_mpn": "GSL960PZBV.APZQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000011,
      "product_name": "P-Veyron6",
      "product_model": "P-Veyron6",
      "product_mpn": "GSL761MCXV.AMCQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000012,
      "product_name": "P-Veyron6",
      "product_model": "P-Veyron6",
      "product_mpn": "GSL760MCXV.AMCQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000013,
      "product_name": "V+ A+++-40%",
      "product_model": "V+ A+++-40%",
      "product_mpn": "GBB92MCBKP.AMCQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000014,
      "product_name": "V+ A+++-10%",
      "product_model": "V+ A+++-10%",
      "product_mpn": "GBB92STAXP.ASTQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000015,
      "product_name": "V+ A+++-10%",
      "product_model": "V+ A+++-10%",
      "product_mpn": "GBB62PZGFN.APZQLGU",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000016,
      "product_name": "V+ A++ 1.9",
      "product_model": "V+ A++ 1.9",
      "product_mpn": "GBF61PZJZN.APZQLGT",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000017,
      "product_name": "V+ A++ 1.9",
      "product_model": "V+ A++ 1.9",
      "product_mpn": "GBB61DSJZN.ADSQLGT",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000018,
      "product_name": "Refrigerator freezer combo",
      "product_model": "Refrigerator freezer combo",
      "product_mpn": "SCFD536NFB",
      "product_brand": "Schneider",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000019,
      "product_name": "Réfrigérateur deux portes",
      "product_model": "Réfrigérateur deux portes",
      "product_mpn": "SDD208VR",
      "product_brand": "Schneider",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000020,
      "product_name": "Classe A+ Verre Silver, Pose libre",
      "product_model": "Classe A+ Verre Silver, Pose libre",
      "product_mpn": "SCB250NFGLS",
      "product_brand": "Schneider",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000021,
      "product_name": "RQ689N4AC2 A++",
      "product_model": "RQ689N4AC2 A++",
      "product_mpn": "RQ689N4AC2",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000022,
      "product_name": "RS694N4TF2 A++",
      "product_model": "RS694N4TF2 A++",
      "product_mpn": "RS694N4TF2",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000023,
      "product_name": "RB438N4GX3 A+++",
      "product_model": "RB438N4GX3 A+++",
      "product_mpn": "RB438N4GX3",
      "product_brand": "Hisense",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000027,
      "product_name": "REF FDR RF 5500",
      "product_model": "REF FDR RF 5500",
      "product_mpn": "RF50K5920S8/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000028,
      "product_name": "REF RB5000",
      "product_model": "REF RB5000",
      "product_mpn": "RB37J5240EF/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000029,
      "product_name": "REF RB5000",
      "product_model": "REF RB5000",
      "product_mpn": "RB37J5200WW/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000030,
      "product_name": "REF BRB6000",
      "product_model": "REF BRB6000",
      "product_mpn": "RB37J5240SA/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000031,
      "product_name": "REF BMF Premium",
      "product_model": "REF BMF Premium",
      "product_mpn": "RB37K63412C/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000032,
      "product_name": "REF BMF Premium",
      "product_model": "REF BMF Premium",
      "product_mpn": "RB41R7847DX/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000033,
      "product_name": "REF SBS RS 5000",
      "product_model": "REF SBS RS 5000",
      "product_mpn": "RS63R5571SL/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000034,
      "product_name": "REF SBS RS 5000",
      "product_model": "REF SBS RS 5000",
      "product_mpn": "RS64R5331B4/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000035,
      "product_name": "REF SBS RS8000",
      "product_model": "REF SBS RS8000",
      "product_mpn": "RS68N8670SL/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000036,
      "product_name": "REF RB6000",
      "product_model": "REF RB6000",
      "product_mpn": "RB37K6220SS/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000037,
      "product_name": "REF RB5000",
      "product_model": "REF RB5000",
      "product_mpn": "RB37J5261SA/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000038,
      "product_name": "REF RB6000TK",
      "product_model": "REF RB6000TK",
      "product_mpn": "RB37K63411L/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000039,
      "product_name": "REF RB7000",
      "product_model": "REF RB7000",
      "product_mpn": "RB41J7861S4/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000040,
      "product_name": "REF RB5000",
      "product_model": "REF RB5000",
      "product_mpn": "RB37J5000B1/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000041,
      "product_name": "REF SBS RS 6500",
      "product_model": "REF SBS RS 6500",
      "product_mpn": "RS62K6130S8/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000042,
      "product_name": "REF SBS RS 5000",
      "product_model": "REF SBS RS 5000",
      "product_mpn": "RS62R5031B4/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000043,
      "product_name": "REF FDR RF 9000",
      "product_model": "REF FDR RF 9000",
      "product_mpn": "RF61K90407F/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000044,
      "product_name": "REF RT6000",
      "product_model": "REF RT6000",
      "product_mpn": "RT46K6360EF/WT",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000053,
      "product_name": "BRB260010WW/EF",
      "product_model": "BRB260010WW/EF",
      "product_mpn": "BRB260010WW/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000054,
      "product_name": "RB33N300NSA/EF",
      "product_model": "RB33N300NSA/EF",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000055,
      "product_name": "RB41R7817S9/EF",
      "product_model": "RB41R7817S9/EF",
      "product_mpn": "RB41R7817S9/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "REFRIGERATORS"
    },
    {
      "product_arid": 16000056,
      "product_name": "RF56J9040SR/EF",
      "product_model": "RF56J9040SR/EF",
      "product_mpn": "RF56J9040SR/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000057,
      "product_name": "RS65R5401M9/EF",
      "product_model": "RS65R5401M9/EF",
      "product_mpn": "RS65R5401M9/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000058,
      "product_name": "RT53K6510SL/EF",
      "product_model": "RT53K6510SL/EF",
      "product_mpn": "RT53K6510SL/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16000059,
      "product_name": "28-cu ft 4-Door French Door Refrigerator with Ice Maker",
      "product_model": "28-cu ft 4-Door French Door Refrigerator with Ice Maker",
      "product_mpn": "RF28R7201SR",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 16700000,
      "product_name": "8.5-kW Generator with 50-Amp Switch",
      "product_model": "8.5-kW Generator with 50-Amp Switch",
      "product_mpn": "ATS50",
      "product_brand": "Champion",
      "product_category": "OUTDOORS",
      "product_subcategory": "GENERATORS"
    },
    {
      "product_arid": 16800000,
      "product_name": "Modular Storage System Combo - Oak WorkTop",
      "product_model": "Modular Storage System Combo - Oak WorkTop",
      "product_mpn": "APMSCOMBO1W",
      "product_brand": "Sealey",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 100100000,
      "product_name": "WD Q.Drive",
      "product_model": "WD Q.Drive",
      "product_mpn": "WD90N74LNOA/LP",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 100100001,
      "product_name": "WD90N645OOX/EU",
      "product_model": "WD90N645OOX/EU",
      "product_mpn": "WD90N645OOX/EU",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 100100002,
      "product_name": "EW7W3924SP",
      "product_model": "EW7W3924SP",
      "product_mpn": "EW7W3924SP",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 100100003,
      "product_name": "L8WED164C",
      "product_model": "L8WED164C",
      "product_mpn": "L8WED164C",
      "product_brand": "AEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 100100005,
      "product_name": "FWDD1071681",
      "product_model": "FWDD1071681",
      "product_mpn": "FWDD1071681",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 101200000,
      "product_name": "SPIW324A2WF",
      "product_model": "SPIW324A2WF",
      "product_mpn": "SPIW324A2WF",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "AIR CONDITIONERS"
    },
    {
      "product_arid": 200200000,
      "product_name": "Bluetooth Sound Tower",
      "product_model": "Bluetooth Sound Tower",
      "product_mpn": "SC700SND",
      "product_brand": "Schneider",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200001,
      "product_name": "Soundbar 500",
      "product_model": "Soundbar 500",
      "product_mpn": "799702-1100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200002,
      "product_name": "Soundbar 700 black",
      "product_model": "Soundbar 700 black",
      "product_mpn": "795347-1100",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200003,
      "product_name": "Soundbar 700 white",
      "product_model": "Soundbar 700 white",
      "product_mpn": "795347-1200",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200004,
      "product_name": "Beam (Black)",
      "product_model": "Beam (Black)",
      "product_mpn": "BEAM1EU1BLK",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200005,
      "product_name": "Beam (White)",
      "product_model": "Beam (White)",
      "product_mpn": "BEAM1EU1",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200006,
      "product_name": "Master Soundbar + Subwoofer",
      "product_model": "Master Soundbar + Subwoofer",
      "product_mpn": "SC-HTB600EGK",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200007,
      "product_name": "Master Soundbar",
      "product_model": "Master Soundbar",
      "product_mpn": "SC-HTB400EGK",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200008,
      "product_name": "JBL Bar 9.1",
      "product_model": "JBL Bar 9.1",
      "product_mpn": "",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200009,
      "product_name": "HK Citation Beam (Grey)",
      "product_model": "HK Citation Beam (Grey)",
      "product_mpn": "",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200400000,
      "product_name": "Master Premium Blu-ray Player",
      "product_model": "Master Premium Blu-ray Player",
      "product_mpn": "DP-UB9000EGK",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "BLU-RAY PLAYERS"
    },
    {
      "product_arid": 200400001,
      "product_name": "Variant Premium Blu-ray Player",
      "product_model": "Variant Premium Blu-ray Player",
      "product_mpn": "DP-UB9004EGK",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "BLU-RAY PLAYERS"
    },
    {
      "product_arid": 300200002,
      "product_name": "K400 Artisan Blender 5KSB4026",
      "product_model": "K400 Artisan Blender 5KSB4026",
      "product_mpn": "5KSB4026BER",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 300300000,
      "product_name": " Master Slow Juicer - Large Feed",
      "product_model": " Master Slow Juicer - Large Feed",
      "product_mpn": " MJ-L700KXE",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 300400003,
      "product_name": "Master NN-ST48KSBPQ",
      "product_model": "Master NN-ST48KSBPQ",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400005,
      "product_name": "Master Steam MWO",
      "product_model": "Master Steam MWO",
      "product_mpn": "NN-CS88LBEPG",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400006,
      "product_name": "Master Steam MWO",
      "product_model": "Master Steam MWO",
      "product_mpn": " NN-CS89LBGPG",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400007,
      "product_name": "Variant Steam MWO",
      "product_model": "Variant Steam MWO",
      "product_mpn": "NN-CS89LBWPG",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400008,
      "product_name": "Master Combi MWO NN-CF87LBBPQ",
      "product_model": "Master Combi MWO NN-CF87LBBPQ",
      "product_mpn": "NN-CF87LBBPQ",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400009,
      "product_name": "Variant Steam MWO",
      "product_model": "Variant Steam MWO",
      "product_mpn": "NN-CS89LBBPQ",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400010,
      "product_name": "MWF 427 SL",
      "product_model": "MWF 427 SL",
      "product_mpn": "MWF 427 SL",
      "product_brand": "Whirlpool",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400011,
      "product_name": "W9 MW261 IXL",
      "product_model": "W9 MW261 IXL",
      "product_mpn": "W9 MW261 IXL",
      "product_brand": "Whirlpool",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400012,
      "product_name": "MS23K3513AK/EF",
      "product_model": "MS23K3513AK/EF",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400013,
      "product_name": "MS28F303EAW/EF",
      "product_model": "MS28F303EAW/EF",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400014,
      "product_name": "MG23K3515AK/EF",
      "product_model": "MG23K3515AK/EF",
      "product_mpn": "MG23K3515AK/EF",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400015,
      "product_name": "MC28H5015CK/EF",
      "product_model": "MC28H5015CK/EF",
      "product_mpn": "MC28H5015CK/EF",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400016,
      "product_name": "NQ50K5130BS/EF",
      "product_model": "NQ50K5130BS/EF",
      "product_mpn": "NQ50K5130BS/EF",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400017,
      "product_name": "MS22M8074AT/EF",
      "product_model": "MS22M8074AT/EF",
      "product_mpn": "MS22M8074AT/EF",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 500500000,
      "product_name": "MX Master 3 Herzog - Graphite",
      "product_model": "MX Master 3 Herzog - Graphite",
      "product_mpn": "910-005694",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500001,
      "product_name": "MX Master 3 Herzog - Mid Grey",
      "product_model": "MX Master 3 Herzog - Mid Grey",
      "product_mpn": "910-005695",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500010,
      "product_name": "G903 Hero (Black)",
      "product_model": "G903 Hero (Black)",
      "product_mpn": "910-005084",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500011,
      "product_name": "MX Vertical (Black)",
      "product_model": "MX Vertical (Black)",
      "product_mpn": "910-005448",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500012,
      "product_name": "Pebble (Turbot) - Pink",
      "product_model": "Pebble (Turbot) - Pink",
      "product_mpn": "910-005717",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500013,
      "product_name": "Pebble (Turbot) - Graphite",
      "product_model": "Pebble (Turbot) - Graphite",
      "product_mpn": "910-005718",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500014,
      "product_name": "Pebble (Turbot) - White",
      "product_model": "Pebble (Turbot) - White",
      "product_mpn": "910-005716",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500600000,
      "product_name": "C922",
      "product_model": "C922",
      "product_mpn": "960-001090",
      "product_brand": "Logitech",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 500600001,
      "product_name": "MK 470",
      "product_model": "MK 470",
      "product_mpn": "920-009192",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500600002,
      "product_name": "MK540 Advanced (Black)",
      "product_model": "MK540 Advanced (Black)",
      "product_mpn": "920-008693",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 600100000,
      "product_name": "GT 2 Elegant watch",
      "product_model": "GT 2 Elegant watch",
      "product_mpn": "55024553",
      "product_brand": "Huawei",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 999001002,
      "product_name": "Echo  2nd Generation - Charcoal",
      "product_model": "Echo  2nd Generation - Charcoal",
      "product_mpn": "B06Y5ZW72J",
      "product_brand": "Amazon",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "SMART CONTROLS"
    },
    {
      "product_arid": 1800100000,
      "product_name": "V20 20-Volt Max 1/2-in Brushless Cordless Drill",
      "product_model": "V20 20-Volt Max 1/2-in Brushless Cordless Drill",
      "product_mpn": "V20",
      "product_brand": "Craftsman",
      "product_category": "DIY",
      "product_subcategory": "TOOLS"
    },
    {
      "product_arid": 2100100000,
      "product_name": "Frances Oval Solitaire Diamond Engagement Ring",
      "product_model": "Frances Oval Solitaire Diamond Engagement Ring",
      "product_mpn": "",
      "product_brand": "DC",
      "product_category": "JEWELRY",
      "product_subcategory": "RINGS"
    },
    {
      "product_arid": 800063,
      "product_name": "Elite Dragonfly",
      "product_model": "Elite Dragonfly",
      "product_mpn": "8MK86EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 9100133,
      "product_name": "AMW 442/IX Oven",
      "product_model": "AMW 442/IX Oven",
      "product_mpn": "AMW 442/IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100134,
      "product_name": "AMW 442/IX Panel",
      "product_model": "AMW 442/IX Panel",
      "product_mpn": "AMW 442/IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100135,
      "product_name": "AKZ96490IX Oven",
      "product_model": "AKZ96490IX Oven",
      "product_mpn": "AKZ96490IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100136,
      "product_name": "AKZ96490IX Panel",
      "product_model": "AKZ96490IX Panel",
      "product_mpn": "AKZ96490IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100137,
      "product_name": "AKZ9 635 IX Oven",
      "product_model": "AKZ9 635 IX Oven",
      "product_mpn": "AKZ9 635 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100138,
      "product_name": "AKZ9 635 IX Panel",
      "product_model": "AKZ9 635 IX Panel",
      "product_mpn": "AKZ9 635 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 300400021,
      "product_name": "MWF 426 SL",
      "product_model": "MWF 426 SL",
      "product_mpn": "MWF 426 SL",
      "product_brand": "Whirlpool",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400022,
      "product_name": "MWP 3391 SX",
      "product_model": "MWP 3391 SX",
      "product_mpn": "MWP 3391 SX",
      "product_brand": "Whirlpool",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 9100139,
      "product_name": "W6 OM4 4S1 P BSS Oven",
      "product_model": "W6 OM4 4S1 P BSS Oven",
      "product_mpn": "W6 OM4 4S1 P BSS",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100140,
      "product_name": "W6 OM4 4S1 P BSS Panel",
      "product_model": "W6 OM4 4S1 P BSS Panel",
      "product_mpn": "W6 OM4 4S1 P BSS",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 13500064,
      "product_name": "W9 921C OX",
      "product_model": "W9 921C OX",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500063,
      "product_name": "W9 921D OX",
      "product_model": "W9 921D OX",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 10300135,
      "product_name": "this allocation has been used on remake of 9100105- W9 OS2 4S1 P",
      "product_model": "this allocation has been used on remake of 9100105- W9 OS2 4S1 P",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9200035,
      "product_name": "this allocation has been used on remake of 9100085- 7OFI4 851 SH IX HA",
      "product_model": "this allocation has been used on remake of 9100085- 7OFI4 851 SH IX HA",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9100130,
      "product_name": "IDU 6340 IX PANEL",
      "product_model": "IDU 6340 IX PANEL",
      "product_mpn": "IDU 6340 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100128,
      "product_name": "IFW 6340 IX PANEL",
      "product_model": "IFW 6340 IX PANEL",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 16000062,
      "product_name": "W9 941D IX H",
      "product_model": "W9 941D IX H",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 9200026,
      "product_name": "WB Q4860 NE",
      "product_model": "WB Q4860 NE",
      "product_mpn": "WB Q4860 NE",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 15600018,
      "product_name": "ST U 83E EU",
      "product_model": "ST U 83E EU",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 10300123,
      "product_name": "ZEN8",
      "product_model": "ZEN8",
      "product_mpn": "ZEN8",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9100129,
      "product_name": "IDU 6340 IX",
      "product_model": "IDU 6340 IX",
      "product_mpn": "IDU 6340 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 10300118,
      "product_name": "FSCR 12440",
      "product_model": "FSCR 12440",
      "product_mpn": "FSCR 12440",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300117,
      "product_name": "BWE 101684X W UK",
      "product_model": "BWE 101684X W UK",
      "product_mpn": "BWE 101684X W UK",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300126,
      "product_name": "ZENDOSE10",
      "product_model": "ZENDOSE10",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300124,
      "product_name": "FFDD9448BSVFR",
      "product_model": "FFDD9448BSVFR",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300125,
      "product_name": "TDLRB 65332BS FR/N",
      "product_model": "TDLRB 65332BS FR/N",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 300019,
      "product_name": "Ariana 3-seater Classic back Dapple Chocolate ",
      "product_model": "Ariana 3-seater Classic back Dapple Chocolate ",
      "product_mpn": "ARAA3SCA-CB-001",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300018,
      "product_name": "Ariana 3-seater Pillow back Dapple Chocolate ",
      "product_model": "Ariana 3-seater Pillow back Dapple Chocolate ",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 800068,
      "product_name": "Irwin - ENVY 15 x360",
      "product_model": "Irwin - ENVY 15 x360",
      "product_mpn": "2S550EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800066,
      "product_name": "HP ZbookStudio G5",
      "product_model": "HP ZbookStudio G5",
      "product_mpn": "6TW43EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800065,
      "product_name": "HP Zbook17 G6",
      "product_model": "HP Zbook17 G6",
      "product_mpn": "6TU97EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800064,
      "product_name": "HP Zbook15 G5",
      "product_model": "HP Zbook15 G5",
      "product_mpn": "6TV18EA",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800069,
      "product_name": "Kerbal - ENVY 15",
      "product_model": "Kerbal - ENVY 15",
      "product_mpn": "2T953EA#ABU",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800070,
      "product_name": "Starmade - Omen 15",
      "product_model": "Starmade - Omen 15",
      "product_mpn": "3A259EA#ABU",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800073,
      "product_name": "Aldrin - ENVY 13 x360",
      "product_model": "Aldrin - ENVY 13 x360",
      "product_mpn": "Aldrin - ENVY 13 x360",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 9100132,
      "product_name": "OAKZ9 379 P IX Panel",
      "product_model": "OAKZ9 379 P IX Panel",
      "product_mpn": "OAKZ9 379 P IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100082,
      "product_name": "FA5 841 JH BLG HA Panel",
      "product_model": "FA5 841 JH BLG HA Panel",
      "product_mpn": "FA5 841 JH BLG HA",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100086,
      "product_name": "FI7 861 SH IX HA",
      "product_model": "FI7 861 SH IX HA",
      "product_mpn": "FI7 861 SH IX HA",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 10300116,
      "product_name": "FWSG71283WS",
      "product_model": "FWSG71283WS",
      "product_mpn": "FWSG71283WS",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 300400020,
      "product_name": "Master NN-GT46KBSUG",
      "product_model": "Master NN-GT46KBSUG",
      "product_mpn": "NN-GT46KBSUG",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 600070,
      "product_name": "Master SC-HC302EB-K",
      "product_model": "Master SC-HC302EB-K",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 1011641018,
      "product_name": "SC-HTB01EGFF Variation of SC-HTB01EG",
      "product_model": "SC-HTB01EGFF Variation of SC-HTB01EG",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641017,
      "product_name": "TX-40JX800B variation of 65JX800",
      "product_model": "TX-40JX800B variation of 65JX800",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 600069,
      "product_name": "Variant SC-HC304EG-W",
      "product_model": "Variant SC-HC304EG-W",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600068,
      "product_name": "Variant SC-HC304EG-R",
      "product_model": "Variant SC-HC304EG-R",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600067,
      "product_name": "Variant SC-HC300EG-W",
      "product_model": "Variant SC-HC300EG-W",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600066,
      "product_name": "Master SC-HC300EG-K",
      "product_model": "Master SC-HC300EG-K",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 300400025,
      "product_name": "9 Master NN-DF383BEPG ",
      "product_model": "9 Master NN-DF383BEPG ",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300200005,
      "product_name": "8 Master Blender MXLM5080SXT REMAKE",
      "product_model": "8 Master Blender MXLM5080SXT REMAKE",
      "product_mpn": "REMAKE MXLM5080SXT",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 300400018,
      "product_name": "6 Master NN-DS596MWPG",
      "product_model": "6 Master NN-DS596MWPG",
      "product_mpn": "NN-DS596MWPG",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400019,
      "product_name": "5 Master NN-ST45KWBPQ",
      "product_model": "5 Master NN-ST45KWBPQ",
      "product_mpn": "NN-ST45KWBPQ",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 15600014,
      "product_name": "Sharp KD-HHH8S7GW2 CAD",
      "product_model": "Sharp KD-HHH8S7GW2 CAD",
      "product_mpn": "KD-HHH8S7GW2",
      "product_brand": "Vestel",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "TUMBLE DRYERS"
    },
    {
      "product_arid": 100100004,
      "product_name": "SHARP ES-HDB8147W0",
      "product_model": "SHARP ES-HDB8147W0",
      "product_mpn": "ES-HDB8147W0",
      "product_brand": "Vestel",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 101152,
      "product_name": "Toshiba Wall 50UA2B63DB",
      "product_model": "Toshiba Wall 50UA2B63DB",
      "product_mpn": "50UA2B63DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101153,
      "product_name": "50UA2B63DB",
      "product_model": "50UA2B63DB",
      "product_mpn": "50UA2B63DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101154,
      "product_name": "Toshiba Wall 58UA2B63DB",
      "product_model": "Toshiba Wall 58UA2B63DB",
      "product_mpn": "58UA2B63DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101155,
      "product_name": "Toshiba stand 58UA2B63DB",
      "product_model": "Toshiba stand 58UA2B63DB",
      "product_mpn": "58UA2B63DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101156,
      "product_name": "50UL2063DB",
      "product_model": "50UL2063DB",
      "product_mpn": "50UL2063DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101157,
      "product_name": "50UL2063DB",
      "product_model": "50UL2063DB",
      "product_mpn": "50UL2063DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101158,
      "product_name": "55UL2063DB",
      "product_model": "55UL2063DB",
      "product_mpn": "55UL2063DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101159,
      "product_name": "55UL2063DB",
      "product_model": "55UL2063DB",
      "product_mpn": "55UL2063DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101160,
      "product_name": "58UL2063DB",
      "product_model": "58UL2063DB",
      "product_mpn": "58UL2063DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101161,
      "product_name": "58UL2063DB",
      "product_model": "58UL2063DB",
      "product_mpn": "58UL2063DB",
      "product_brand": "Vestel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101162,
      "product_name": "65UL2063DB",
      "product_model": "65UL2063DB",
      "product_mpn": "65UL2063DB",
      "product_brand": "Toshiba",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101163,
      "product_name": "65UL2063DB",
      "product_model": "65UL2063DB",
      "product_mpn": "65UL2063DB",
      "product_brand": "Toshiba",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 300400000,
      "product_name": "Master New 34L Combi MWO (Interactive)",
      "product_model": "Master New 34L Combi MWO (Interactive)",
      "product_mpn": "NN-CD87KSBPQ",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300300001,
      "product_name": "Master Slow Juicer",
      "product_model": "Master Slow Juicer",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 300300002,
      "product_name": "Variant Slow Juicer",
      "product_model": "Variant Slow Juicer",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 300400002,
      "product_name": "Variant New 34L Combi MWO (Blank Variant)",
      "product_model": "Variant New 34L Combi MWO (Blank Variant)",
      "product_mpn": "NN-CD87KSUPG",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 9100127,
      "product_name": "IFW 6340 IX",
      "product_model": "IFW 6340 IX",
      "product_mpn": "IFW 6340 IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 900020,
      "product_name": "Vertuo Plus Titan (Deluxe C range)",
      "product_model": "Vertuo Plus Titan (Deluxe C range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900021,
      "product_name": "Vertuo Plus Silver (Deluxe D range)",
      "product_model": "Vertuo Plus Silver (Deluxe D range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900022,
      "product_name": "Vertuo Next Premium Rich Brown (D range)",
      "product_model": "Vertuo Next Premium Rich Brown (D range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900023,
      "product_name": "Vertuo Next Classic Black (C range)",
      "product_model": "Vertuo Next Classic Black (C range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900024,
      "product_name": "Creatista Plus Metal Stainless Steel",
      "product_model": "Creatista Plus Metal Stainless Steel",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900039,
      "product_name": "Vertuo Next Cherry Red (C range)",
      "product_model": "Vertuo Next Cherry Red (C range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900038,
      "product_name": "Vertuo Next Light Grey (C range)",
      "product_model": "Vertuo Next Light Grey (C range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900037,
      "product_name": "Vertuo Next White (D range)",
      "product_model": "Vertuo Next White (D range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900036,
      "product_name": "Vertuo Plus Ink Black (C range)",
      "product_model": "Vertuo Plus Ink Black (C range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900035,
      "product_name": "Vertuo Plus Red (D range)",
      "product_model": "Vertuo Plus Red (D range)",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900025,
      "product_name": "EP3246/70",
      "product_model": "EP3246/70",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900026,
      "product_name": "EP2220/10",
      "product_model": "EP2220/10",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 1500005,
      "product_name": "BRE224/00",
      "product_model": "BRE224/00",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 9500006,
      "product_name": "HD9653/90",
      "product_model": "HD9653/90",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 12500002,
      "product_name": "GC6704/30",
      "product_model": "GC6704/30",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "IRONINGS"
    },
    {
      "product_arid": 300100001,
      "product_name": "HR2375/05",
      "product_model": "HR2375/05",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 900028,
      "product_name": "LM8012/60",
      "product_model": "LM8012/60",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 500500015,
      "product_name": "G502 Lightspeed",
      "product_model": "G502 Lightspeed",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500016,
      "product_name": "G604",
      "product_model": "G604",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500017,
      "product_name": "M720",
      "product_model": "M720",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900016,
      "product_name": "K780",
      "product_model": "K780",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 11900001,
      "product_name": "X56",
      "product_model": "X56",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11700005,
      "product_name": "G923 PS4",
      "product_model": "G923 PS4",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11700006,
      "product_name": "G923 XBOX",
      "product_model": "G923 XBOX",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 6900017,
      "product_name": "K480 Black",
      "product_model": "K480 Black",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900018,
      "product_name": "K480 White",
      "product_model": "K480 White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500018,
      "product_name": "G203 Black",
      "product_model": "G203 Black",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500019,
      "product_name": "G203 White",
      "product_model": "G203 White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500020,
      "product_name": "G Pro Wireless Mouse",
      "product_model": "G Pro Wireless Mouse",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900019,
      "product_name": "G413",
      "product_model": "G413",
      "product_mpn": "Keyboard",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900020,
      "product_name": "G Pro Mechanical Keyboard",
      "product_model": "G Pro Mechanical Keyboard",
      "product_mpn": "Keyboard",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 8800028,
      "product_name": "The Smart Toast 4 Slice",
      "product_model": "The Smart Toast 4 Slice",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 9000010,
      "product_name": "The Soft Top Luxe",
      "product_model": "The Soft Top Luxe",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 8800027,
      "product_name": "The Toast Select Luxe",
      "product_model": "The Toast Select Luxe",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 9500005,
      "product_name": "Smart Oven Air Fryer",
      "product_model": "Smart Oven Air Fryer",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 300400023,
      "product_name": "The combi Wave 3 in 1",
      "product_model": "The combi Wave 3 in 1",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300300005,
      "product_name": "The Juice Fountain Cold XL",
      "product_model": "The Juice Fountain Cold XL",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 300300004,
      "product_name": "3X Bluiser Pro",
      "product_model": "3X Bluiser Pro",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 900034,
      "product_name": "Creatista Plus",
      "product_model": "Creatista Plus",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900033,
      "product_name": "The Barista Pro",
      "product_model": "The Barista Pro",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900032,
      "product_name": "The Oracle Touch",
      "product_model": "The Oracle Touch",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 9500004,
      "product_name": "Smart Oven Airfry",
      "product_model": "Smart Oven Airfry",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 9000009,
      "product_name": "Tea Maker",
      "product_model": "Tea Maker",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 8800026,
      "product_name": "Luxe Toaster",
      "product_model": "Luxe Toaster",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 9000008,
      "product_name": "Luxe Kettle",
      "product_model": "Luxe Kettle",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 300200004,
      "product_name": "The Q",
      "product_model": "The Q",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 300300003,
      "product_name": "Master Bluicer Pro",
      "product_model": "Master Bluicer Pro",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 900031,
      "product_name": "Precision Brewer",
      "product_model": "Precision Brewer",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900030,
      "product_name": "Luxe Barista Pro",
      "product_model": "Luxe Barista Pro",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900029,
      "product_name": "Oracle Touch",
      "product_model": "Oracle Touch",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 900027,
      "product_name": "Bambino Plus",
      "product_model": "Bambino Plus",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 100662,
      "product_name": "55\" ULTRA HD 4K TV",
      "product_model": "55\" ULTRA HD 4K TV",
      "product_mpn": "55UK6470PLC",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1100002,
      "product_name": "Omen 30l Desktop PC",
      "product_model": "Omen 30l Desktop PC",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 7000032,
      "product_name": "CQ27G2U",
      "product_model": "CQ27G2U",
      "product_mpn": "CQ27G2U/BK",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000031,
      "product_name": "AG273QZ",
      "product_model": "AG273QZ",
      "product_mpn": "AG273QZ",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000030,
      "product_name": "CU34G2X",
      "product_model": "CU34G2X",
      "product_mpn": "CU34G2X/BK",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000029,
      "product_name": "24G2U",
      "product_model": "24G2U",
      "product_mpn": "24G2U/BK",
      "product_brand": "AOC",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 101000001,
      "product_name": "TP04",
      "product_model": "TP04",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 101000000,
      "product_name": "HP04",
      "product_model": "HP04",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 800072,
      "product_name": "Razer Blade Pro 17",
      "product_model": "Razer Blade Pro 17",
      "product_mpn": "",
      "product_brand": "Razer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800071,
      "product_name": "Razer Blade Advanced 15 (2020)",
      "product_model": "Razer Blade Advanced 15 (2020)",
      "product_mpn": "",
      "product_brand": "Razer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800067,
      "product_name": "Razer Blade Stealth 13",
      "product_model": "Razer Blade Stealth 13",
      "product_mpn": "",
      "product_brand": "Razer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 10300132,
      "product_name": "TD I-PRO SERIES 7",
      "product_model": "TD I-PRO SERIES 7",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300131,
      "product_name": "WM I-PRO SERIES 7",
      "product_model": "WM I-PRO SERIES 7",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9100143,
      "product_name": "Forno TFT piro - HWO60SM5T9BH",
      "product_model": "Forno TFT piro - HWO60SM5T9BH",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9100142,
      "product_name": "FORNO steam piro - HWO60SM5S9BH",
      "product_model": "FORNO steam piro - HWO60SM5S9BH",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 13500071,
      "product_name": "HB20 (FD 70x200) - HB20FPAAA",
      "product_model": "HB20 (FD 70x200) - HB20FPAAA",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500070,
      "product_name": "HTF-540 (Cube 90 Slim) - HTF-540DP7",
      "product_model": "HTF-540 (Cube 90 Slim) - HTF-540DP7",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500069,
      "product_name": "HTF-520 (Cube 90 Slim with Water Dispenser) - HTF-520IP7",
      "product_model": "HTF-520 (Cube 90 Slim with Water Dispenser) - HTF-520IP7",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500068,
      "product_name": "HTF-508 (Cube 83) - HTF-508DGS7",
      "product_model": "HTF-508 (Cube 83) - HTF-508DGS7",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 9600033,
      "product_name": "WFO 3T141 PF S",
      "product_model": "WFO 3T141 PF S",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 10300133,
      "product_name": "FRR12451",
      "product_model": "FRR12451",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9600030,
      "product_name": "DFC 2B+16 UK",
      "product_model": "DFC 2B+16 UK",
      "product_mpn": "F158944",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600034,
      "product_name": "DFO 3T133 F UK",
      "product_model": "DFO 3T133 F UK",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 10300139,
      "product_name": "BI WMIL 91484 UK",
      "product_model": "BI WMIL 91484 UK",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300129,
      "product_name": "FFB 9468 BSV IT",
      "product_model": "FFB 9468 BSV IT",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9200031,
      "product_name": "WL S2260 NE",
      "product_model": "WL S2260 NE",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9100141,
      "product_name": "W7 OS4 4S1 H",
      "product_model": "W7 OS4 4S1 H",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 10300130,
      "product_name": "FFB 9448 BV SP",
      "product_model": "FFB 9448 BV SP",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9200032,
      "product_name": "WF S9560 NE",
      "product_model": "WF S9560 NE",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200036,
      "product_name": "TS 5760F NE",
      "product_model": "TS 5760F NE",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9600035,
      "product_name": "DFC 2C24 UK",
      "product_model": "DFC 2C24 UK",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600036,
      "product_name": "DIC 3B+16 UK",
      "product_model": "DIC 3B+16 UK",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 10300138,
      "product_name": "MTWC 91483 W UK",
      "product_model": "MTWC 91483 W UK",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 13500074,
      "product_name": "HQ9I MO1L UK",
      "product_model": "HQ9I MO1L UK",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 10300137,
      "product_name": "BI WMHG 91484 UK",
      "product_model": "BI WMHG 91484 UK",
      "product_mpn": "BI WMHG 91484 UK",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 100100008,
      "product_name": "BI WDHG 961484 UK",
      "product_model": "BI WDHG 961484 UK",
      "product_mpn": "BI WDHG 961484 UK",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9400009,
      "product_name": "WHVS 93F LT BSS",
      "product_model": "WHVS 93F LT BSS",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 200200010,
      "product_name": "Ray-Danz",
      "product_model": "Ray-Danz",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200047,
      "product_name": "T1 Pro",
      "product_model": "T1 Pro",
      "product_mpn": "T1 Pro",
      "product_brand": "TCL",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200049,
      "product_name": "T1 Lite",
      "product_model": "T1 Lite",
      "product_mpn": "T1 Lite",
      "product_brand": "TCL",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 1500006,
      "product_name": "HP8230/00",
      "product_model": "HP8230/00",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 9500008,
      "product_name": "HD9860/90",
      "product_model": "HD9860/90",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 1500008,
      "product_name": "BHD029/00",
      "product_model": "BHD029/00",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 300500000,
      "product_name": "AC0819/10",
      "product_model": "AC0819/10",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "AIR PURIFIERS"
    },
    {
      "product_arid": 12500003,
      "product_name": "GC1751/80",
      "product_model": "GC1751/80",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "IRONINGS"
    },
    {
      "product_arid": 900019,
      "product_name": "Breville Barista Max VCF126",
      "product_model": "Breville Barista Max VCF126",
      "product_mpn": "Barista Max VCF126",
      "product_brand": "HOLMES",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 9100131,
      "product_name": "OAKZ9 379 P IX Oven",
      "product_model": "OAKZ9 379 P IX Oven",
      "product_mpn": "OAKZ9 379 P IX",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 10300119,
      "product_name": "ZEN TDLR 724",
      "product_model": "ZEN TDLR 724",
      "product_mpn": "ZEN TDLR 724",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300120,
      "product_name": "ZEN TDLR 65330",
      "product_model": "ZEN TDLR 65330",
      "product_mpn": "ZEN TDLR 65330",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300121,
      "product_name": "TDLR 70220",
      "product_model": "TDLR 70220",
      "product_mpn": "TDLR 70220",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300122,
      "product_name": "TDLR 7221",
      "product_model": "TDLR 7221",
      "product_mpn": "TDLR 7221",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 11700008,
      "product_name": "PS 5",
      "product_model": "PS 5",
      "product_mpn": "",
      "product_brand": "Sony",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11700009,
      "product_name": "PS 5 Digital ",
      "product_model": "PS 5 Digital ",
      "product_mpn": "",
      "product_brand": "Sony",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11700010,
      "product_name": "Xbox series X",
      "product_model": "Xbox series X",
      "product_mpn": "",
      "product_brand": "Microsoft",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 12000000,
      "product_name": "Nintendo Switch",
      "product_model": "Nintendo Switch",
      "product_mpn": "",
      "product_brand": "Nintendo",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 100100006,
      "product_name": "FWDG86148W SP",
      "product_model": "FWDG86148W SP",
      "product_mpn": "FWDG86148W SP",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 800100000,
      "product_name": "Merlin Extending Dining Table and 6 Chairs",
      "product_model": "Merlin Extending Dining Table and 6 Chairs",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "LIVINGROOM",
      "product_subcategory": "DINING TABLES"
    },
    {
      "product_arid": 8000100001,
      "product_name": "Compact Dinning table RH facing corner bench",
      "product_model": "Compact Dinning table RH facing corner bench",
      "product_mpn": "E80CDSET10-001",
      "product_brand": "Furniture Village",
      "product_category": "LIVINGROOM",
      "product_subcategory": "DINING TABLES"
    },
    {
      "product_arid": 8000100002,
      "product_name": "Compact Dinning table LH facing corner bench",
      "product_model": "Compact Dinning table LH facing corner bench",
      "product_mpn": "E80CDSET9-001",
      "product_brand": "Furniture Village",
      "product_category": "LIVINGROOM",
      "product_subcategory": "DINING TABLES"
    },
    {
      "product_arid": 800100003,
      "product_name": "Merlin Extending Dining Table and 4 Chairs",
      "product_model": "Merlin Extending Dining Table and 4 Chairs",
      "product_mpn": "Merlin Extending Dining Table and 4 Chairs",
      "product_brand": "Furniture Village",
      "product_category": "LIVINGROOM",
      "product_subcategory": "DINING TABLES"
    },
    {
      "product_arid": 900100009,
      "product_name": "Activate Dual Rear Fed",
      "product_model": "Activate Dual Rear Fed",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100000,
      "product_name": "Décor Dual",
      "product_model": "Décor Dual",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100001,
      "product_name": "Realm ERD",
      "product_model": "Realm ERD",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100002,
      "product_name": "Mode Dual CF",
      "product_model": "Mode Dual CF",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100003,
      "product_name": "Mode Dual RF",
      "product_model": "Mode Dual RF",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100004,
      "product_name": "Sport Max",
      "product_model": "Sport Max",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100005,
      "product_name": "Azora Dual",
      "product_model": "Azora Dual",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100006,
      "product_name": "Jump Dual",
      "product_model": "Jump Dual",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 900100007,
      "product_name": "Atom ERD",
      "product_model": "Atom ERD",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 12400003,
      "product_name": "QcK Prism Cloth XL",
      "product_model": "QcK Prism Cloth XL",
      "product_mpn": "",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500021,
      "product_name": "Rival 600",
      "product_model": "Rival 600",
      "product_mpn": "",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 12400004,
      "product_name": "Arctis Pro Wireless - Black",
      "product_model": "Arctis Pro Wireless - Black",
      "product_mpn": "",
      "product_brand": "Steel Series",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6900021,
      "product_name": "Apex Pro Keyboard",
      "product_model": "Apex Pro Keyboard",
      "product_mpn": "",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900022,
      "product_name": "Apex Pro TKL Keyboard",
      "product_model": "Apex Pro TKL Keyboard",
      "product_mpn": "",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 9600031,
      "product_name": "WFC 3C34 P X",
      "product_model": "WFC 3C34 P X",
      "product_mpn": "F160428",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600032,
      "product_name": "WFO 3T141 P X",
      "product_model": "WFO 3T141 P X",
      "product_mpn": "F160356",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 13500067,
      "product_name": "HB18 (FD 83) - HB18FGSAAA",
      "product_model": "HB18 (FD 83) - HB18FGSAAA",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500066,
      "product_name": "Point & Place Model Production 10",
      "product_model": "Point & Place Model Production 10",
      "product_mpn": "HWS77GDAU1",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 6800020,
      "product_name": "Poly headset",
      "product_model": "Poly headset",
      "product_mpn": "",
      "product_brand": "Exertis",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 7000035,
      "product_name": "Dell U2719D Monitor",
      "product_model": "Dell U2719D Monitor",
      "product_mpn": "u2719d",
      "product_brand": "Exertis",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 500600003,
      "product_name": "Keyboard + Mouse",
      "product_model": "Keyboard + Mouse",
      "product_mpn": "",
      "product_brand": "Exertis",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 800079,
      "product_name": "Acer Aspire 3",
      "product_model": "Acer Aspire 3",
      "product_mpn": "",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 200051,
      "product_name": "iPhone 11",
      "product_model": "iPhone 11",
      "product_mpn": "",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200052,
      "product_name": "OnePlus Nord 5G",
      "product_model": "OnePlus Nord 5G",
      "product_mpn": "",
      "product_brand": "OnePlus",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200053,
      "product_name": "iPhone 11 Pro Max ",
      "product_model": "iPhone 11 Pro Max ",
      "product_mpn": "",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 1100003,
      "product_name": "Acer Nitro N50",
      "product_model": "Acer Nitro N50",
      "product_mpn": "",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 1011631005,
      "product_name": "TCL 32",
      "product_model": "TCL 32",
      "product_mpn": "32DD420",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 9500007,
      "product_name": "Philips Airfryer XXL",
      "product_model": "Philips Airfryer XXL",
      "product_mpn": "HD9650 / 90",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 800077,
      "product_name": "Lenovo Ideapad",
      "product_model": "Lenovo Ideapad",
      "product_mpn": "Lenovo Ideapad 3 14",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 200054,
      "product_name": "Samsung Galaxy Z Fold2",
      "product_model": "Samsung Galaxy Z Fold2",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 1000013,
      "product_name": "GOPRO HERO 9",
      "product_model": "GOPRO HERO 9",
      "product_mpn": "",
      "product_brand": "GoPro",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 12600028,
      "product_name": "Roborock S5 Max Robot",
      "product_model": "Roborock S5 Max Robot",
      "product_mpn": "",
      "product_brand": "Xiaomi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 1011631006,
      "product_name": "philips 58PUS7505/12",
      "product_model": "philips 58PUS7505/12",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 10300128,
      "product_name": "Bosch Series 6",
      "product_model": "Bosch Series 6",
      "product_mpn": "WAT283L8SN",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 200055,
      "product_name": "iPhone SE",
      "product_model": "iPhone SE",
      "product_mpn": "iPhone SE",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 900040,
      "product_name": "DeLonghi Magnifica coffee machine",
      "product_model": "DeLonghi Magnifica coffee machine",
      "product_mpn": "ECAM22110B ",
      "product_brand": "Delonghi",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 800078,
      "product_name": "HP 14-dq1833 14 Laptop ",
      "product_model": "HP 14-dq1833 14 Laptop ",
      "product_mpn": "dq1833 ",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 1011631007,
      "product_name": "Philips 43 PUS7505 ",
      "product_model": "Philips 43 PUS7505 ",
      "product_mpn": "43PUS7505 / 12",
      "product_brand": "Philips",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631008,
      "product_name": "TCL 40 ",
      "product_model": "TCL 40 ",
      "product_mpn": "40ES560",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631009,
      "product_name": "Philips 32 PHS5525 ",
      "product_model": "Philips 32 PHS5525 ",
      "product_mpn": "32PHS5525 / 12",
      "product_brand": "Philips",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631010,
      "product_name": "SAMSUNG UE43TU7102KXXH",
      "product_model": "SAMSUNG UE43TU7102KXXH",
      "product_mpn": "TU7100",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 300020,
      "product_name": "Ariana 3-seater Pillow back Dapple Oyster",
      "product_model": "Ariana 3-seater Pillow back Dapple Oyster",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300021,
      "product_name": "Ariana 3-seater Pillow back Dapple Mink ",
      "product_model": "Ariana 3-seater Pillow back Dapple Mink ",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300022,
      "product_name": "Ariana 3-seater Pillow back Dapple Silver",
      "product_model": "Ariana 3-seater Pillow back Dapple Silver",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300023,
      "product_name": "Ariana 3-seater Pillow back Dapple Moleskin",
      "product_model": "Ariana 3-seater Pillow back Dapple Moleskin",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300024,
      "product_name": "Ariana 3-seater Classic back Dapple Oyster ",
      "product_model": "Ariana 3-seater Classic back Dapple Oyster ",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300025,
      "product_name": "Ariana 3-seater Classic back Dapple Mink ",
      "product_model": "Ariana 3-seater Classic back Dapple Mink ",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300026,
      "product_name": "Ariana 3-seater Classic back Dapple Silver ",
      "product_model": "Ariana 3-seater Classic back Dapple Silver ",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300027,
      "product_name": "Ariana 3-seater Classic back Dapple Moleskin ",
      "product_model": "Ariana 3-seater Classic back Dapple Moleskin ",
      "product_mpn": "",
      "product_brand": "Furniture Village",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 75284,
      "product_name": "lego_75284",
      "product_model": "lego_75284",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76165,
      "product_name": "lego_76165",
      "product_model": "lego_76165",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 10953,
      "product_name": "lego_10953",
      "product_model": "lego_10953",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75980,
      "product_name": "lego_75980",
      "product_model": "lego_75980",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 75293,
      "product_name": "lego_75293",
      "product_model": "lego_75293",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 43187,
      "product_name": "lego_43187",
      "product_model": "lego_43187",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 51515,
      "product_name": "lego_51515",
      "product_model": "lego_51515",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71360,
      "product_name": "lego_71360",
      "product_model": "lego_71360",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71367,
      "product_name": "lego_71367",
      "product_model": "lego_71367",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 71369,
      "product_name": "lego_71369",
      "product_model": "lego_71369",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76164,
      "product_name": "",
      "product_model": "",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76152,
      "product_name": "",
      "product_model": "",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 76144,
      "product_name": "",
      "product_model": "",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 7000045,
      "product_name": "Optix MPG321UR-QD",
      "product_model": "Optix MPG321UR-QD",
      "product_mpn": "Optix MPG321UR-QD",
      "product_brand": "MSI",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000044,
      "product_name": "Optix MAG281URF",
      "product_model": "Optix MAG281URF",
      "product_mpn": "Optix MAG281URF",
      "product_brand": "MSI",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000043,
      "product_name": "Optix MAG274QRF-QD",
      "product_model": "Optix MAG274QRF-QD",
      "product_mpn": "Optix MAG274QRF-QD",
      "product_brand": "MSI",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 700500017,
      "product_name": "MAG CH130 I REPELTEK FABRIC",
      "product_model": "MAG CH130 I REPELTEK FABRIC",
      "product_mpn": "MAG CH130 I REPELTEK FABRIC",
      "product_brand": "MSI",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 7000037,
      "product_name": "MSI MPG ARTYMIS 343CQR",
      "product_model": "MSI MPG ARTYMIS 343CQR",
      "product_mpn": "",
      "product_brand": "MSI",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 1011631028,
      "product_name": "QN75Q70TAFXZC",
      "product_model": "QN75Q70TAFXZC",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631027,
      "product_name": "QN85Q70TAFXZC",
      "product_model": "QN85Q70TAFXZC",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631026,
      "product_name": "QN85Q60TAFXZC",
      "product_model": "QN85Q60TAFXZC",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 75969,
      "product_name": "75969",
      "product_model": "75969",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 60262,
      "product_name": "60262",
      "product_model": "60262",
      "product_mpn": "",
      "product_brand": "Lego",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 2200100000,
      "product_name": "Collider - Men - BROWN",
      "product_model": "Collider - Men - BROWN",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100001,
      "product_name": "Collider - Men - SMOKE GREY",
      "product_model": "Collider - Men - SMOKE GREY",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100002,
      "product_name": "Collider - Men - BLACK",
      "product_model": "Collider - Men - BLACK",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200200000,
      "product_name": "Monroe - Women - ROSE GOLD",
      "product_model": "Monroe - Women - ROSE GOLD",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200200001,
      "product_name": "Monroe - Women - BLACK ROSE GOLD",
      "product_model": "Monroe - Women - BLACK ROSE GOLD",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200200002,
      "product_name": "Monroe - Women - SILVER",
      "product_model": "Monroe - Women - SILVER",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100003,
      "product_name": "Neutra - Men - BLACK",
      "product_model": "Neutra - Men - BLACK",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100004,
      "product_name": "Neutra - Men - BROWN",
      "product_model": "Neutra - Men - BROWN",
      "product_mpn": "",
      "product_brand": "Fossil",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 800075,
      "product_name": "DELL Inspiron 15 3000",
      "product_model": "DELL Inspiron 15 3000",
      "product_mpn": "DELL Inspiron 15 3000",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 600061,
      "product_name": "4 Master SC-HC304EG-G",
      "product_model": "4 Master SC-HC304EG-G",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600062,
      "product_name": "3 Master SC-TMAX50E-K",
      "product_model": "3 Master SC-TMAX50E-K",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200400002,
      "product_name": "2 Master DP-UB820EBK",
      "product_model": "2 Master DP-UB820EBK",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "BLU-RAY PLAYERS"
    },
    {
      "product_arid": 600063,
      "product_name": "1 Master SC-HTB01EG",
      "product_model": "1 Master SC-HTB01EG",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200200012,
      "product_name": "TS9030",
      "product_model": "TS9030",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 1011631025,
      "product_name": "32ES560",
      "product_model": "32ES560",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631024,
      "product_name": "40ES560",
      "product_model": "40ES560",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631023,
      "product_name": "43P715",
      "product_model": "43P715",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631022,
      "product_name": "50P815",
      "product_model": "50P815",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 500027,
      "product_name": "HP OfficeJet",
      "product_model": "HP OfficeJet",
      "product_mpn": "HP OfficeJet pro 9019",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 1011631021,
      "product_name": "55P815",
      "product_model": "55P815",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631020,
      "product_name": "65P815",
      "product_model": "65P815",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631019,
      "product_name": "65QLED800",
      "product_model": "65QLED800",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631018,
      "product_name": "55QLED800",
      "product_model": "55QLED800",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631017,
      "product_name": "50QLED800",
      "product_model": "50QLED800",
      "product_mpn": "",
      "product_brand": "TCL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 13500065,
      "product_name": "WQ9I MO1L",
      "product_model": "WQ9I MO1L",
      "product_mpn": "WQ9I MO1L",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 12600029,
      "product_name": "AIR·FORCE·FLEX 760",
      "product_model": "AIR·FORCE·FLEX 760",
      "product_mpn": "",
      "product_brand": "SEB",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 7900002,
      "product_name": "Moulinex Cookeo Touch Black",
      "product_model": "Moulinex Cookeo Touch Black",
      "product_mpn": "",
      "product_brand": "SEB",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 900046,
      "product_name": "Espresso Machine",
      "product_model": "Espresso Machine",
      "product_mpn": "",
      "product_brand": "SEB",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 1800100005,
      "product_name": "VOLTCRAFT VC-522",
      "product_model": "VOLTCRAFT VC-522",
      "product_mpn": "8466755",
      "product_brand": "Conrad",
      "product_category": "DIY",
      "product_subcategory": "TOOLS"
    },
    {
      "product_arid": 1800100006,
      "product_name": "VOLTCRAFT VC-532",
      "product_model": "VOLTCRAFT VC-532",
      "product_mpn": "10706625",
      "product_brand": "Conrad",
      "product_category": "DIY",
      "product_subcategory": "TOOLS"
    },
    {
      "product_arid": 1800100004,
      "product_name": "VOLTCRAFT MS-540",
      "product_model": "VOLTCRAFT MS-540",
      "product_mpn": "1601759",
      "product_brand": "Conrad",
      "product_category": "DIY",
      "product_subcategory": "TOOLS"
    },
    {
      "product_arid": 200057,
      "product_name": "Motorola Moto G8",
      "product_model": "Motorola Moto G8",
      "product_mpn": "",
      "product_brand": "Motorola",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 1011631011,
      "product_name": "Samsung QE55Q700T",
      "product_model": "Samsung QE55Q700T",
      "product_mpn": "QE55Q700T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 200200011,
      "product_name": "Denon DHT-S416",
      "product_model": "Denon DHT-S416",
      "product_mpn": "DHT-S416",
      "product_brand": "Denon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200058,
      "product_name": "iPhone 11 Pro",
      "product_model": "iPhone 11 Pro",
      "product_mpn": "iPhone 11 Pro",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 500100000,
      "product_name": "Apple 10.2-inch iPad (8th generation)",
      "product_model": "Apple 10.2-inch iPad (8th generation)",
      "product_mpn": "IPad 10.2",
      "product_brand": "Apple",
      "product_category": "COMPUTING",
      "product_subcategory": "TABLETS"
    },
    {
      "product_arid": 1011631012,
      "product_name": "Philips 58PUS8105 / 12",
      "product_model": "Philips 58PUS8105 / 12",
      "product_mpn": "58PUS8105 / 12",
      "product_brand": "Philips",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 7000033,
      "product_name": "Acer Nitro VG0",
      "product_model": "Acer Nitro VG0",
      "product_mpn": "VG240Y ",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 200059,
      "product_name": "Xiaomi Mi 10T pro",
      "product_model": "Xiaomi Mi 10T pro",
      "product_mpn": "Xiaomi Mi 10T pro",
      "product_brand": "Xiaomi",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 1011631013,
      "product_name": "Philips 70 70PUS9005",
      "product_model": "Philips 70 70PUS9005",
      "product_mpn": "70PUS9005",
      "product_brand": "Philips",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 16000063,
      "product_name": null,
      "product_model": null,
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 12400008,
      "product_name": "Cosmos elite headset",
      "product_model": "Cosmos elite headset",
      "product_mpn": "Cosmos elite headset",
      "product_brand": "HTC",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400007,
      "product_name": "Vive cosmos elite",
      "product_model": "Vive cosmos elite",
      "product_mpn": "Vive cosmos elite",
      "product_brand": "HTC",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400006,
      "product_name": "Vive cosmos",
      "product_model": "Vive cosmos",
      "product_mpn": "Vive Cosmos",
      "product_brand": "HTC",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400005,
      "product_name": "Vive pro",
      "product_model": "Vive pro",
      "product_mpn": "Vive pro",
      "product_brand": "HTC",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 14900001,
      "product_name": "Ruark Audio R3 (Grey)",
      "product_model": "Ruark Audio R3 (Grey)",
      "product_mpn": "RUAR-R3-GREY",
      "product_brand": "Ruark",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 900041,
      "product_name": "Siemens EQ.300",
      "product_model": "Siemens EQ.300",
      "product_mpn": "TI35A209RW",
      "product_brand": "Siemens",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 1100004,
      "product_name": "Acer Predator Orion 3000-620",
      "product_model": "Acer Predator Orion 3000-620",
      "product_mpn": "Orion 3000-620",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 500100001,
      "product_name": "Apple 10.9-inch iPad Air",
      "product_model": "Apple 10.9-inch iPad Air",
      "product_mpn": "Apple 10.9-inch iPad Air",
      "product_brand": "Apple",
      "product_category": "COMPUTING",
      "product_subcategory": "TABLETS"
    },
    {
      "product_arid": 8000176,
      "product_name": "Asus VivoBook 15.6",
      "product_model": "Asus VivoBook 15.6",
      "product_mpn": "Asus VivoBook 15.6",
      "product_brand": "Asus",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 1100005,
      "product_name": "HP Pavilion Gaming 0686no ",
      "product_model": "HP Pavilion Gaming 0686no ",
      "product_mpn": "8NF17EA # UUW",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 1011631015,
      "product_name": "Samsung UE43TU7100",
      "product_model": "Samsung UE43TU7100",
      "product_mpn": "UE43TU7100",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631016,
      "product_name": "Sony 65 \"XH80 4K",
      "product_model": "Sony 65 \"XH80 4K",
      "product_mpn": "KD65XH8096",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631014,
      "product_name": "Samsung QE43Q60T",
      "product_model": "Samsung QE43Q60T",
      "product_mpn": "QE43Q60T",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 900100008,
      "product_name": "Activate Dual Ceiling Fed",
      "product_model": "Activate Dual Ceiling Fed",
      "product_mpn": "",
      "product_brand": "MIRA",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "TAPS & MIXERS"
    },
    {
      "product_arid": 500600004,
      "product_name": "MK470 (White)",
      "product_model": "MK470 (White)",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900023,
      "product_name": "G213 (Black)",
      "product_model": "G213 (Black)",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 1011631040,
      "product_name": "QE43LS05TAUXXU",
      "product_model": "QE43LS05TAUXXU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 10300153,
      "product_name": "WD80TA046BEEU",
      "product_model": "WD80TA046BEEU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300149,
      "product_name": "WW90T634DLN",
      "product_model": "WW90T634DLN",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300146,
      "product_name": "WW90TA046AE",
      "product_model": "WW90TA046AE",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300145,
      "product_name": "WW90T554DAW",
      "product_model": "WW90T554DAW",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 1011631039,
      "product_name": "QE75Q60TAUXXU",
      "product_model": "QE75Q60TAUXXU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 600065,
      "product_name": "QE75Q70TATXXU",
      "product_model": "QE75Q70TATXXU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631038,
      "product_name": "QE55LS01TAUXXU",
      "product_model": "QE55LS01TAUXXU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631037,
      "product_name": "QE65Q70TATXXU",
      "product_model": "QE65Q70TATXXU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631036,
      "product_name": "QE65Q60TAUXXU",
      "product_model": "QE65Q60TAUXXU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631035,
      "product_name": "QE65Q700TATXXU",
      "product_model": "QE65Q700TATXXU",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 800085,
      "product_name": "Alienware M15 Lunar Light",
      "product_model": "Alienware M15 Lunar Light",
      "product_mpn": "",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800084,
      "product_name": "Alienware M17 Lunar Light",
      "product_model": "Alienware M17 Lunar Light",
      "product_mpn": "",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800083,
      "product_name": "XPS 15 9500 Silver",
      "product_model": "XPS 15 9500 Silver",
      "product_mpn": "",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800082,
      "product_name": "XPS 13 9300 Silver",
      "product_model": "XPS 13 9300 Silver",
      "product_mpn": "",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800081,
      "product_name": "XPS 17 9700 Silver",
      "product_model": "XPS 17 9700 Silver",
      "product_mpn": "",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 200200013,
      "product_name": "JBL Bar 2.0",
      "product_model": "JBL Bar 2.0",
      "product_mpn": "",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 12600030,
      "product_name": "Irobot Roomba I7",
      "product_model": "Irobot Roomba I7",
      "product_mpn": "",
      "product_brand": "iRobot",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 7000036,
      "product_name": "Acer EK241Y",
      "product_model": "Acer EK241Y",
      "product_mpn": "",
      "product_brand": "Acer",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 8400010,
      "product_name": " Humax 4K Aura 2TB",
      "product_model": " Humax 4K Aura 2TB",
      "product_mpn": "",
      "product_brand": "Humax",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 1500007,
      "product_name": "PHILIPS BRI953",
      "product_model": "PHILIPS BRI953",
      "product_mpn": "",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SHAVERS"
    },
    {
      "product_arid": 13500073,
      "product_name": "Large Rohmbus Rack",
      "product_model": "Large Rohmbus Rack",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500072,
      "product_name": "Slim Rhombus Rack",
      "product_model": "Slim Rhombus Rack",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 500032,
      "product_name": "ET-2720/L3160",
      "product_model": "ET-2720/L3160",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500031,
      "product_name": "ET-7700/L7160",
      "product_model": "ET-7700/L7160",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500030,
      "product_name": "ET-4750/L6190",
      "product_model": "ET-4750/L6190",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500029,
      "product_name": "ET-4700/L5190",
      "product_model": "ET-4700/L5190",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500028,
      "product_name": "ET-2756",
      "product_model": "ET-2756",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500037,
      "product_name": "ET-2750",
      "product_model": "ET-2750",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500036,
      "product_name": "ET-M2120/M2120",
      "product_model": "ET-M2120/M2120",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500035,
      "product_name": "L3156",
      "product_model": "L3156",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500034,
      "product_name": "ET-2711/L3151",
      "product_model": "ET-2711/L3151",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500033,
      "product_name": "ET-2710/L3150",
      "product_model": "ET-2710/L3150",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 400200001,
      "product_name": "XIAOMI 70mai ",
      "product_model": "XIAOMI 70mai ",
      "product_mpn": "",
      "product_brand": "Xiaomi",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 1011631029,
      "product_name": "Sony 85 \"XH80 ",
      "product_model": "Sony 85 \"XH80 ",
      "product_mpn": "KD85XH8096",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 11700011,
      "product_name": "Xbox series S",
      "product_model": "Xbox series S",
      "product_mpn": "",
      "product_brand": "Microsoft",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 12600031,
      "product_name": "Siemens Z7 VSZ7A330",
      "product_model": "Siemens Z7 VSZ7A330",
      "product_mpn": "VSZ7A330",
      "product_brand": "Siemens",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 6900024,
      "product_name": "Apex 7 Red TKL",
      "product_model": "Apex 7 Red TKL",
      "product_mpn": "Apex 7 Red TKL",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 8800029,
      "product_name": "Kenwood KVL6300S",
      "product_model": "Kenwood KVL6300S",
      "product_mpn": "KVL6300S",
      "product_brand": "Kenwood",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 6800021,
      "product_name": "Sony WH-1000XM3",
      "product_model": "Sony WH-1000XM3",
      "product_mpn": "WH-1000XM3",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800022,
      "product_name": "Bose QuietComfort 35 QC35",
      "product_model": "Bose QuietComfort 35 QC35",
      "product_mpn": "Bose QC35",
      "product_brand": "Bose",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 800086,
      "product_name": "MacBook Air 13",
      "product_model": "MacBook Air 13",
      "product_mpn": "MacBook Air 13",
      "product_brand": "Apple",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 12600032,
      "product_name": "PHILIPS FC6404 / 01",
      "product_model": "PHILIPS FC6404 / 01",
      "product_mpn": "PHILIPS FC6404 / 01",
      "product_brand": "Philips",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 9100144,
      "product_name": "SF4920VCN1",
      "product_model": "SF4920VCN1",
      "product_mpn": "SF4920VCN1",
      "product_brand": "SMEG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 12400009,
      "product_name": "Exertis Bundle",
      "product_model": "Exertis Bundle",
      "product_mpn": "",
      "product_brand": "Exertis",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 800087,
      "product_name": "Asus D509DA-EJ881T ",
      "product_model": "Asus D509DA-EJ881T ",
      "product_mpn": "D509DA-EJ881T ",
      "product_brand": "Asus",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 6800023,
      "product_name": "SteelSeries Arctis 7",
      "product_model": "SteelSeries Arctis 7",
      "product_mpn": "SSARCTIS7BLAC",
      "product_brand": "Steel Series",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 800088,
      "product_name": "Asus Laptop 14 ",
      "product_model": "Asus Laptop 14 ",
      "product_mpn": "E406MA ",
      "product_brand": "Asus",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800089,
      "product_name": "Lenovo Legion 5",
      "product_model": "Lenovo Legion 5",
      "product_mpn": "15ARH05H ",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 200061,
      "product_name": "OnePlus 8 Pro",
      "product_model": "OnePlus 8 Pro",
      "product_mpn": "OnePlus 8 Pro",
      "product_brand": "OnePlus",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 800076,
      "product_name": "Dell ",
      "product_model": "Dell ",
      "product_mpn": "Lati 7410",
      "product_brand": "Exertis",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 200200014,
      "product_name": "Sonos Arc",
      "product_model": "Sonos Arc",
      "product_mpn": "",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 600064,
      "product_name": "Sonos Five",
      "product_model": "Sonos Five",
      "product_mpn": "",
      "product_brand": "Sonos",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 200062,
      "product_name": "Sony Xperia L4",
      "product_model": "Sony Xperia L4",
      "product_mpn": "Sony Xperia L4",
      "product_brand": "Sony",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 11700012,
      "product_name": "Playstation 4 pro",
      "product_model": "Playstation 4 pro",
      "product_mpn": "PS 4 PRO",
      "product_brand": "Sony",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 13500077,
      "product_name": "Mini Car",
      "product_model": "Mini Car",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500082,
      "product_name": "Large Slim GDC",
      "product_model": "Large Slim GDC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500081,
      "product_name": "Medium Slim GDC",
      "product_model": "Medium Slim GDC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500080,
      "product_name": "Large GDC",
      "product_model": "Large GDC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500079,
      "product_name": "Small OFC",
      "product_model": "Small OFC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500078,
      "product_name": "Medium OFC",
      "product_model": "Medium OFC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500076,
      "product_name": "Large OFC",
      "product_model": "Large OFC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500075,
      "product_name": "Baby Cooler",
      "product_model": "Baby Cooler",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 800090,
      "product_name": "Yoga Slim 7",
      "product_model": "Yoga Slim 7",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 800091,
      "product_name": "ThinkPad X1 Carbon Gen 8",
      "product_model": "ThinkPad X1 Carbon Gen 8",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 101000002,
      "product_name": "HP04 1",
      "product_model": "HP04 1",
      "product_mpn": "HP04 1",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 101000003,
      "product_name": "TP04 1",
      "product_model": "TP04 1",
      "product_mpn": "TP04 1",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 7800003,
      "product_name": "Arno GPRA Electric Grill",
      "product_model": "Arno GPRA Electric Grill",
      "product_mpn": "",
      "product_brand": "Arno",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "GRILLS"
    },
    {
      "product_arid": 9500009,
      "product_name": "Arno Super Inox IFRY Fryer",
      "product_model": "Arno Super Inox IFRY Fryer",
      "product_mpn": "",
      "product_brand": "Arno",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 8800031,
      "product_name": "Arno Deluxe SX71 Planetary Mixer",
      "product_model": "Arno Deluxe SX71 Planetary Mixer",
      "product_mpn": "",
      "product_brand": "Arno",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 900042,
      "product_name": "Arno Dolce Gusto Genio S Plus Espresso Coffee Maker",
      "product_model": "Arno Dolce Gusto Genio S Plus Espresso Coffee Maker",
      "product_mpn": "",
      "product_brand": "Arno",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 9200037,
      "product_name": "Atlas 4-burner Monaco Automatic",
      "product_model": "Atlas 4-burner Monaco Automatic",
      "product_mpn": "",
      "product_brand": "Atlas",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 2200008,
      "product_name": "Bartira Fortaleza II Wardrobe",
      "product_model": "Bartira Fortaleza II Wardrobe",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200009,
      "product_name": "Bartira Porto Wardrobe",
      "product_model": "Bartira Porto Wardrobe",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200010,
      "product_name": "Bartira Curitiba II Wardrobe",
      "product_model": "Bartira Curitiba II Wardrobe",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 1000200004,
      "product_name": "Double Box Bed + Biflex Ibiza Double Mattress",
      "product_model": "Double Box Bed + Biflex Ibiza Double Mattress",
      "product_mpn": "",
      "product_brand": "Ibiza",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 10300140,
      "product_name": "Brastemp Washer BWR12AB",
      "product_model": "Brastemp Washer BWR12AB",
      "product_mpn": "BWR12AB ",
      "product_brand": "Brastemp",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300141,
      "product_name": "Colormaq LCS10M",
      "product_model": "Colormaq LCS10M",
      "product_mpn": "",
      "product_brand": "Colormaq",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 12600033,
      "product_name": "Electrolux Pure I9.2 (PI92-4SGM)",
      "product_model": "Electrolux Pure I9.2 (PI92-4SGM)",
      "product_mpn": "PI92-4SGM",
      "product_brand": "Electrolux",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 300400026,
      "product_name": "Electrolux MS37R",
      "product_model": "Electrolux MS37R",
      "product_mpn": "",
      "product_brand": "Electrolux",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 8800030,
      "product_name": "Arno Daily SX88 Planetary Mixer",
      "product_model": "Arno Daily SX88 Planetary Mixer",
      "product_mpn": "",
      "product_brand": "Arno",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 300600000,
      "product_name": "Arno Ultra Silence Force VD40",
      "product_model": "Arno Ultra Silence Force VD40",
      "product_mpn": "",
      "product_brand": "Arno",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TABLE FANS"
    },
    {
      "product_arid": 10300144,
      "product_name": "Electrolux LES15 ",
      "product_model": "Electrolux LES15 ",
      "product_mpn": "",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 300200006,
      "product_name": "Electrolux Explore 7 E7TB1-8SML ",
      "product_model": "Electrolux Explore 7 E7TB1-8SML ",
      "product_mpn": "",
      "product_brand": "Electrolux",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 9200038,
      "product_name": "Esmaltec 4-burner ",
      "product_model": "Esmaltec 4-burner ",
      "product_mpn": "",
      "product_brand": "Esmaltec",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9200039,
      "product_name": "Esmaltec 5-burner stove Agate 5062",
      "product_model": "Esmaltec 5-burner stove Agate 5062",
      "product_mpn": "",
      "product_brand": "Esmaltec",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 2200011,
      "product_name": "Florida Plus Fame Wardrobe",
      "product_model": "Florida Plus Fame Wardrobe",
      "product_mpn": "",
      "product_brand": "Fama Móveis",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200012,
      "product_name": "Florida Plus Fame Wardrobe with Mirror",
      "product_model": "Florida Plus Fame Wardrobe with Mirror",
      "product_mpn": "",
      "product_brand": "Fama Móveis",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200013,
      "product_name": "Florida Plus Fame Wardrobe with 3 Sliding Doors ",
      "product_model": "Florida Plus Fame Wardrobe with 3 Sliding Doors ",
      "product_mpn": "",
      "product_brand": "Fama Móveis",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700300001,
      "product_name": "Itatiaia Diamante Kitchen",
      "product_model": "Itatiaia Diamante Kitchen",
      "product_mpn": "",
      "product_brand": "Itatiaia",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 100100009,
      "product_name": "LG CV5011WG4 Lava and Dry ",
      "product_model": "LG CV5011WG4 Lava and Dry ",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 1011631031,
      "product_name": "LG 43LM6300PSB",
      "product_model": "LG 43LM6300PSB",
      "product_mpn": "50000473",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 700200005,
      "product_name": "Rack Madetec Lisboa para TV de até 50",
      "product_model": "Rack Madetec Lisboa para TV de até 50",
      "product_mpn": "",
      "product_brand": "Madetec",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 9500010,
      "product_name": "Mondial AF-30I",
      "product_model": "Mondial AF-30I",
      "product_mpn": "",
      "product_brand": "Mondial",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 900043,
      "product_name": "Mondial Coffee",
      "product_model": "Mondial Coffee",
      "product_mpn": "",
      "product_brand": "Mondial",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 10300143,
      "product_name": "Electrolux LES09",
      "product_model": "Electrolux LES09",
      "product_mpn": "",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300142,
      "product_name": "Electrolux LAC12 ",
      "product_model": "Electrolux LAC12 ",
      "product_mpn": "",
      "product_brand": "Electrolux",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 300200007,
      "product_name": "Mondial Turbo Inox L-1000",
      "product_model": "Mondial Turbo Inox L-1000",
      "product_mpn": "",
      "product_brand": "Mondial",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 300600001,
      "product_name": "Mondial Turbo VT-40-B",
      "product_model": "Mondial Turbo VT-40-B",
      "product_mpn": "",
      "product_brand": "Mondial",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TABLE FANS"
    },
    {
      "product_arid": 300600002,
      "product_name": "Mondial Table Fan VTX-50",
      "product_model": "Mondial Table Fan VTX-50",
      "product_mpn": "",
      "product_brand": "Mondial",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TABLE FANS"
    },
    {
      "product_arid": 1011631032,
      "product_name": "43 \"Full HD Philco PTV43N5CG70BL",
      "product_model": "43 \"Full HD Philco PTV43N5CG70BL",
      "product_mpn": "PTV43N5CG70BL",
      "product_brand": "Philco",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 900044,
      "product_name": "Nespresso Essenza Mini D30",
      "product_model": "Nespresso Essenza Mini D30",
      "product_mpn": "",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 9200040,
      "product_name": "Cooktop a Gás Philco 4 ",
      "product_model": "Cooktop a Gás Philco 4 ",
      "product_mpn": "",
      "product_brand": "Philco",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 9500012,
      "product_name": "Philco  Air Fryer 3.2L",
      "product_model": "Philco  Air Fryer 3.2L",
      "product_mpn": "55004617",
      "product_brand": "Philco",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 7500011,
      "product_name": "Electric Pressure Cooker Philco PPPV ",
      "product_model": "Electric Pressure Cooker Philco PPPV ",
      "product_mpn": "",
      "product_brand": "Philco",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 8800032,
      "product_name": "Mixer Philips Walita Viva Collection RI1364",
      "product_model": "Mixer Philips Walita Viva Collection RI1364",
      "product_mpn": "",
      "product_brand": "Philips Walita",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 300100000,
      "product_name": "Pulsar Philips Walita Viva Collection RI7636 ",
      "product_model": "Pulsar Philips Walita Viva Collection RI7636 ",
      "product_mpn": "",
      "product_brand": "Philips Walita",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD MIXERS"
    },
    {
      "product_arid": 1000200005,
      "product_name": "Portobel Double Box Bed ",
      "product_model": "Portobel Double Box Bed ",
      "product_mpn": "",
      "product_brand": "Portobel",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 12600034,
      "product_name": " Powerbot-E VR5000RM",
      "product_model": " Powerbot-E VR5000RM",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 100100010,
      "product_name": "WD11M4453JW Lava and Dry ",
      "product_model": "WD11M4453JW Lava and Dry ",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 300500001,
      "product_name": "Suggar DI60BIBR",
      "product_model": "Suggar DI60BIBR",
      "product_mpn": "",
      "product_brand": "Suggar",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "AIR PURIFIERS"
    },
    {
      "product_arid": 900045,
      "product_name": "S04 Multi-Drink Espresso Coffee Maker",
      "product_model": "S04 Multi-Drink Espresso Coffee Maker",
      "product_mpn": "",
      "product_brand": "TRES",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 1000200006,
      "product_name": "Double Bed + Umaflex Italy Mattress",
      "product_model": "Double Bed + Umaflex Italy Mattress",
      "product_mpn": "",
      "product_brand": "Umaflex",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200007,
      "product_name": "Umaflex Italy Box Queen Size Bed",
      "product_model": "Umaflex Italy Box Queen Size Bed",
      "product_mpn": "",
      "product_brand": "Umaflex",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1011631033,
      "product_name": "Samsung 50TU8000",
      "product_model": "Samsung 50TU8000",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631034,
      "product_name": "Samsung 43TU7000",
      "product_model": "Samsung 43TU7000",
      "product_mpn": "43TU7000",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 9500011,
      "product_name": "Mondial Family NAF-03I",
      "product_model": "Mondial Family NAF-03I",
      "product_mpn": "",
      "product_brand": "Mondial",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 300400027,
      "product_name": "Whirlpool microwave MAX38 / IX",
      "product_model": "Whirlpool microwave MAX38 / IX",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 800093,
      "product_name": "HP Pavilion 15s-fq1802no",
      "product_model": "HP Pavilion 15s-fq1802no",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 500042,
      "product_name": "ET-5170",
      "product_model": "ET-5170",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500041,
      "product_name": "ET-M16600",
      "product_model": "ET-M16600",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500040,
      "product_name": "ET-16150",
      "product_model": "ET-16150",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500039,
      "product_name": "ET-16600",
      "product_model": "ET-16600",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500038,
      "product_name": "ET-5800",
      "product_model": "ET-5800",
      "product_mpn": "",
      "product_brand": "Epson",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 1011631042,
      "product_name": "LG OLED55CX6LA 55",
      "product_model": "LG OLED55CX6LA 55",
      "product_mpn": "OLED55CX6LA ",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631043,
      "product_name": "JVC LT-43C700",
      "product_model": "JVC LT-43C700",
      "product_mpn": "LT-43C700",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631044,
      "product_name": "JVC LT-32C600",
      "product_model": "JVC LT-32C600",
      "product_mpn": "LT-32C600",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631045,
      "product_name": "SAMSUNG UE65TU7020KXXU",
      "product_model": "SAMSUNG UE65TU7020KXXU",
      "product_mpn": "UE65TU7020KXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631046,
      "product_name": "LG OLED65CX6LA 65",
      "product_model": "LG OLED65CX6LA 65",
      "product_mpn": "OLED65CX6LA",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 12300001,
      "product_name": "OCULUS Quest 2",
      "product_model": "OCULUS Quest 2",
      "product_mpn": "",
      "product_brand": "Oculus",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 1011631047,
      "product_name": "JVC LT-50CF890",
      "product_model": "JVC LT-50CF890",
      "product_mpn": " LT-50CF890",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 12600035,
      "product_name": "SHARK DuoClean",
      "product_model": "SHARK DuoClean",
      "product_mpn": "AZ910UKT ",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 1011631048,
      "product_name": "SAMSUNG UE55TU7100KXXU ",
      "product_model": "SAMSUNG UE55TU7100KXXU ",
      "product_mpn": "UE55TU7100KXXU ",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631049,
      "product_name": "SAMSUNG UE32T4300AKXXU",
      "product_model": "SAMSUNG UE32T4300AKXXU",
      "product_mpn": "UE32T4300AKXXU ",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631050,
      "product_name": "LG 43UN74006LB",
      "product_model": "LG 43UN74006LB",
      "product_mpn": "43UN74006LB ",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631051,
      "product_name": "LG 50UN74006LB",
      "product_model": "LG 50UN74006LB",
      "product_mpn": "50UN74006LB ",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 800094,
      "product_name": "HP 14-cf2504sa",
      "product_model": "HP 14-cf2504sa",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 12600036,
      "product_name": "SHARK Anti Hair IZ201UK ",
      "product_model": "SHARK Anti Hair IZ201UK ",
      "product_mpn": "IZ201UK ",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 1011631052,
      "product_name": "JVC LT-40CF890 ",
      "product_model": "JVC LT-40CF890 ",
      "product_mpn": "",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631053,
      "product_name": "LG OLED55BX6LB 55",
      "product_model": "LG OLED55BX6LB 55",
      "product_mpn": "OLED55BX6LB 55",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631054,
      "product_name": "JVC LT-43CF890 ",
      "product_model": "JVC LT-43CF890 ",
      "product_mpn": "JVC LT-43CF890 ",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631055,
      "product_name": "LG OLED48CX6LB 48",
      "product_model": "LG OLED48CX6LB 48",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631056,
      "product_name": "SAMSUNG QE50Q60TAUXXU 50",
      "product_model": "SAMSUNG QE50Q60TAUXXU 50",
      "product_mpn": "QE50Q60TAUXXU 50",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631057,
      "product_name": "SONY BRAVIA KD55A85BU",
      "product_model": "SONY BRAVIA KD55A85BU",
      "product_mpn": "KD55A85BU 55",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 13500083,
      "product_name": "LG GSL460ICEV",
      "product_model": "LG GSL460ICEV",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 1011631058,
      "product_name": "LG 43UN70006LA 43",
      "product_model": "LG 43UN70006LA 43",
      "product_mpn": "43UN70006LA 43",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631059,
      "product_name": "SAMSUNG QE55Q75TATXXU 55",
      "product_model": "SAMSUNG QE55Q75TATXXU 55",
      "product_mpn": "QE55Q75TATXXU 55",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631060,
      "product_name": "JVC LT-55CF890 55",
      "product_model": "JVC LT-55CF890 55",
      "product_mpn": "JVC LT-55CF890",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011631041,
      "product_name": "SAMSUNG UE50TU7020KXXU",
      "product_model": "SAMSUNG UE50TU7020KXXU",
      "product_mpn": "UE50TU7020KXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641003,
      "product_name": "SAMSUNG UE75TU7020KXXU 75\"",
      "product_model": "SAMSUNG UE75TU7020KXXU 75\"",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 12600039,
      "product_name": "SHARK Anti Hair IZ201UKT",
      "product_model": "SHARK Anti Hair IZ201UKT",
      "product_mpn": "IZ201UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 1011641004,
      "product_name": "SAMSUNG QE65Q75TATXXU 65",
      "product_model": "SAMSUNG QE65Q75TATXXU 65",
      "product_mpn": "QE65Q75TATXXU 65",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641005,
      "product_name": "SONY KDL43WG663BU 43",
      "product_model": "SONY KDL43WG663BU 43",
      "product_mpn": "",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 8000177,
      "product_name": "LENOVO IdeaPad 3i 14",
      "product_model": "LENOVO IdeaPad 3i 14",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 1011641009,
      "product_name": "SONY BRAVIA KD55XH8096BU 55",
      "product_model": "SONY BRAVIA KD55XH8096BU 55",
      "product_mpn": "",
      "product_brand": "Sony",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 500044,
      "product_name": "HP ENVY 6032",
      "product_model": "HP ENVY 6032",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 8000178,
      "product_name": "ASUS C523 15.6",
      "product_model": "ASUS C523 15.6",
      "product_mpn": "ASUS C523 15.6\" Chromebook",
      "product_brand": "Asus",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000179,
      "product_name": "LENOVO IdeaPad 3 15.6",
      "product_model": "LENOVO IdeaPad 3 15.6",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 12600040,
      "product_name": "SHARK Flexology True Pet Anti Hair Wrap IZ251UKT",
      "product_model": "SHARK Flexology True Pet Anti Hair Wrap IZ251UKT",
      "product_mpn": "IZ251UKT ",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 1011641016,
      "product_name": "SAMSUNG UE32T5300AKXXU 32",
      "product_model": "SAMSUNG UE32T5300AKXXU 32",
      "product_mpn": "UE32T5300AKXXU ",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 12000001,
      "product_name": "NINTENDO Switch - Animal Crossing",
      "product_model": "NINTENDO Switch - Animal Crossing",
      "product_mpn": "",
      "product_brand": "Nintendo",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 1011641015,
      "product_name": "LG 43NANO796NE 43",
      "product_model": "LG 43NANO796NE 43",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641014,
      "product_name": "SAMSUNG UE58TU7100KXXU 58",
      "product_model": "SAMSUNG UE58TU7100KXXU 58",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641013,
      "product_name": "LG 65UN74006LB 65",
      "product_model": "LG 65UN74006LB 65",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1100007,
      "product_name": "HP 24-df0064na",
      "product_model": "HP 24-df0064na",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 1011641012,
      "product_name": "SAMSUNG UE55TU8507UXXU 55",
      "product_model": "SAMSUNG UE55TU8507UXXU 55",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641011,
      "product_name": "JVC LT-50CA890",
      "product_model": "JVC LT-50CA890",
      "product_mpn": "",
      "product_brand": "JVC",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641010,
      "product_name": "LG 55UN74006LB 55",
      "product_model": "LG 55UN74006LB 55",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641008,
      "product_name": "SAMSUNG Galaxy Tab A 10.1",
      "product_model": "SAMSUNG Galaxy Tab A 10.1",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641007,
      "product_name": "LG OLED55CX5LB 55",
      "product_model": "LG OLED55CX5LB 55",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641006,
      "product_name": "LG 49NANO816NA",
      "product_model": "LG 49NANO816NA",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1100006,
      "product_name": "PC SPECIALIST Vortex ST-S Gaming PC ",
      "product_model": "PC SPECIALIST Vortex ST-S Gaming PC ",
      "product_mpn": "",
      "product_brand": "PC SPECIALIST",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 1011641002,
      "product_name": "SAMSUNG UE50TU8507UXXU 50",
      "product_model": "SAMSUNG UE50TU8507UXXU 50",
      "product_mpn": "UE50TU8507UXXU ",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 101000008,
      "product_name": "TP09 White / Gold",
      "product_model": "TP09 White / Gold",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 101000010,
      "product_name": "TP07 White / Silver",
      "product_model": "TP07 White / Silver",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 101000006,
      "product_name": "HP07 White / Silver",
      "product_model": "HP07 White / Silver",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 101000004,
      "product_name": "HP09 White/Gold",
      "product_model": "HP09 White/Gold",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FANS"
    },
    {
      "product_arid": 200063,
      "product_name": "Galaxy S21+ Phantom Violet",
      "product_model": "Galaxy S21+ Phantom Violet",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200064,
      "product_name": "Galaxy S21 5G Phantom White",
      "product_model": "Galaxy S21 5G Phantom White",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200056,
      "product_name": "Galaxy S21 Ultra 5G Phantom Black",
      "product_model": "Galaxy S21 Ultra 5G Phantom Black",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 2500100000,
      "product_name": "Gocycle GX Folding Electric Bike",
      "product_model": "Gocycle GX Folding Electric Bike",
      "product_mpn": "",
      "product_brand": "Velorution",
      "product_category": "OUTDOORS",
      "product_subcategory": "BIKES"
    },
    {
      "product_arid": 500043,
      "product_name": "HP ENVY 5020",
      "product_model": "HP ENVY 5020",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 1011641001,
      "product_name": "SAMSUNG UE43TU8507UXXU 43",
      "product_model": "SAMSUNG UE43TU8507UXXU 43",
      "product_mpn": "UE43TU8507UXXU ",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 500046,
      "product_name": "HP Neverstop Laser 1001nw",
      "product_model": "HP Neverstop Laser 1001nw",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500045,
      "product_name": "HP Smart Tank Plus 555",
      "product_model": "HP Smart Tank Plus 555",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 1011641022,
      "product_name": "Samsung 55\" Q60T",
      "product_model": "Samsung 55\" Q60T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 200066,
      "product_name": "Galaxy S21 Ultra 5G Phantom Titanium",
      "product_model": "Galaxy S21 Ultra 5G Phantom Titanium",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200067,
      "product_name": "Galaxy S21 Ultra 5G Phantom Navy",
      "product_model": "Galaxy S21 Ultra 5G Phantom Navy",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200068,
      "product_name": "Galaxy S21 Ultra 5G Phantom Brown",
      "product_model": "Galaxy S21 Ultra 5G Phantom Brown",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200069,
      "product_name": "Galaxy S21+ Phantom Black",
      "product_model": "Galaxy S21+ Phantom Black",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200070,
      "product_name": "Galaxy S21+ Phantom Silver",
      "product_model": "Galaxy S21+ Phantom Silver",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200072,
      "product_name": "Galaxy S21+ Phantom Gold",
      "product_model": "Galaxy S21+ Phantom Gold",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200073,
      "product_name": "Galaxy S21+ Phantom Red",
      "product_model": "Galaxy S21+ Phantom Red",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200075,
      "product_name": "Galaxy S21 5G Phantom Grey",
      "product_model": "Galaxy S21 5G Phantom Grey",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200076,
      "product_name": "Galaxy S21 5G Phantom Pink",
      "product_model": "Galaxy S21 5G Phantom Pink",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 1100008,
      "product_name": "PCSpecialist Vortex SFi ",
      "product_model": "PCSpecialist Vortex SFi ",
      "product_mpn": "",
      "product_brand": "PC SPECIALIST",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 200074,
      "product_name": "Galaxy S21 5G Phantom Violet",
      "product_model": "Galaxy S21 5G Phantom Violet",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200065,
      "product_name": "Galaxy S21 Ultra 5G Phantom silver",
      "product_model": "Galaxy S21 Ultra 5G Phantom silver",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 500800001,
      "product_name": "Asus GeForce RTX 3080",
      "product_model": "Asus GeForce RTX 3080",
      "product_mpn": "",
      "product_brand": "Asus",
      "product_category": "COMPUTING",
      "product_subcategory": "GRAPHIC CARDS"
    },
    {
      "product_arid": 13500084,
      "product_name": "Logik Refrigerator Freezer",
      "product_model": "Logik Refrigerator Freezer",
      "product_mpn": "288164",
      "product_brand": "Logik",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 1011641061,
      "product_name": "TX-48JZ980B Variation of 65JZ980 ",
      "product_model": "TX-48JZ980B Variation of 65JZ980 ",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641060,
      "product_name": "TX-48JZ1000B variation of 65JZ1000",
      "product_model": "TX-48JZ1000B variation of 65JZ1000",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641059,
      "product_name": "TX-48JZ1500B Master",
      "product_model": "TX-48JZ1500B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641057,
      "product_name": "TX-50JX800B variation of 65JX800",
      "product_model": "TX-50JX800B variation of 65JX800",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641056,
      "product_name": "TX-58JX800B variation of 65JX800",
      "product_model": "TX-58JX800B variation of 65JX800",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641055,
      "product_name": "TX-65JX800B Master",
      "product_model": "TX-65JX800B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641054,
      "product_name": "TX-40JX850B variation of 65JX850",
      "product_model": "TX-40JX850B variation of 65JX850",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641053,
      "product_name": "TX-50JX850B variation of 65JX850",
      "product_model": "TX-50JX850B variation of 65JX850",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641052,
      "product_name": "TX-58JX850B variation of 65JX850",
      "product_model": "TX-58JX850B variation of 65JX850",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641051,
      "product_name": "TX-65JX850B Master",
      "product_model": "TX-65JX850B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641050,
      "product_name": "TX-43JX940B Master",
      "product_model": "TX-43JX940B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641048,
      "product_name": "TX-65JX940B Master",
      "product_model": "TX-65JX940B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641058,
      "product_name": "TX-75JX940B Master",
      "product_model": "TX-75JX940B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641045,
      "product_name": "TX-55JZ1000B Variation of 65JZ1000",
      "product_model": "TX-55JZ1000B Variation of 65JZ1000",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641044,
      "product_name": "TX-55JZ1500B Variation of 65JZ1000 CAD",
      "product_model": "TX-55JZ1500B Variation of 65JZ1000 CAD",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641042,
      "product_name": "TX-65JZ1000B Master",
      "product_model": "TX-65JZ1000B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641041,
      "product_name": "TX-65JZ1500B Master",
      "product_model": "TX-65JZ1500B Master",
      "product_mpn": "",
      "product_brand": "Panasonic",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 13500086,
      "product_name": "M-GDC",
      "product_model": "M-GDC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500085,
      "product_name": "Small GDC",
      "product_model": "Small GDC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 12600041,
      "product_name": "Electrolux PowerForce EPF63EB",
      "product_model": "Electrolux PowerForce EPF63EB",
      "product_mpn": "EPF63EB",
      "product_brand": "Electrolux",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 500800000,
      "product_name": "GeForce RTX 3080",
      "product_model": "GeForce RTX 3080",
      "product_mpn": "1121308",
      "product_brand": "Gigabyte",
      "product_category": "COMPUTING",
      "product_subcategory": "GRAPHIC CARDS"
    },
    {
      "product_arid": 300200010,
      "product_name": "The 3X Bluicer",
      "product_model": "The 3X Bluicer",
      "product_mpn": "",
      "product_brand": "Sage",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 300700000,
      "product_name": "SodaStream Spirit Sparkling Water Maker",
      "product_model": "SodaStream Spirit Sparkling Water Maker",
      "product_mpn": "",
      "product_brand": "Sodastream",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "SPARKLING WATER MAKERS"
    },
    {
      "product_arid": 6800024,
      "product_name": "Charge 5",
      "product_model": "Charge 5",
      "product_mpn": "",
      "product_brand": "Harman Kardon",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 13500090,
      "product_name": "HTS 8202I BX O3",
      "product_model": "HTS 8202I BX O3",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 91001310002,
      "product_name": "W11I OM1 4MS2 H",
      "product_model": "W11I OM1 4MS2 H",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9200041,
      "product_name": "WL B5860 AL",
      "product_model": "WL B5860 AL",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 10300150,
      "product_name": "W6X W845WR FR",
      "product_model": "W6X W845WR FR",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300148,
      "product_name": "W8 W046WB FR",
      "product_model": "W8 W046WB FR",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 9600037,
      "product_name": "HI 5020 WEF",
      "product_model": "HI 5020 WEF",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600038,
      "product_name": "HIC 3O33 WLEG",
      "product_model": "HIC 3O33 WLEG",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 9600039,
      "product_name": "HFC 3C26 F",
      "product_model": "HFC 3C26 F",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "DISHWASHERS"
    },
    {
      "product_arid": 10300151,
      "product_name": "W8 W046WR IT",
      "product_model": "W8 W046WR IT",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300152,
      "product_name": "W6 W845WR SPT",
      "product_model": "W6 W845WR SPT",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 16000064,
      "product_name": " HTR 7200 BX",
      "product_model": " HTR 7200 BX",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500088,
      "product_name": "HTR 8202I BZ O3",
      "product_model": "HTR 8202I BZ O3",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500089,
      "product_name": " HTS 9202I BZ O3",
      "product_model": " HTS 9202I BZ O3",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500095,
      "product_name": "HTR 9202I SX O3",
      "product_model": "HTR 9202I SX O3",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500096,
      "product_name": "HTS 5200 MX",
      "product_model": "HTS 5200 MX",
      "product_mpn": "",
      "product_brand": "Whirlpool",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 2500100001,
      "product_name": "ARID for avatar",
      "product_model": "ARID for avatar",
      "product_mpn": "ARID for avatar",
      "product_brand": "Velorution",
      "product_category": "OUTDOORS",
      "product_subcategory": "BIKES"
    },
    {
      "product_arid": 1500014,
      "product_name": "Airwrap™ styler Complete Long",
      "product_model": "Airwrap™ styler Complete Long",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "HAIR STRAIGHTENERS"
    },
    {
      "product_arid": 2100200000,
      "product_name": "Pearl Large Orb choker necklace",
      "product_model": "Pearl Large Orb choker necklace",
      "product_mpn": "",
      "product_brand": "VIVIENNE WESTWOOD",
      "product_category": "JEWELRY",
      "product_subcategory": "NECKLACES"
    },
    {
      "product_arid": 2800100002,
      "product_name": "Le Pliage large tote bag",
      "product_model": "Le Pliage large tote bag",
      "product_mpn": "",
      "product_brand": "LONGCHAMP",
      "product_category": "Bags",
      "product_subcategory": "SHOULDER BAGS"
    },
    {
      "product_arid": 2800100001,
      "product_name": "Snapshot leather cross-body bag",
      "product_model": "Snapshot leather cross-body bag",
      "product_mpn": "",
      "product_brand": "MARC JACOBS",
      "product_category": "Bags",
      "product_subcategory": "SHOULDER BAGS"
    },
    {
      "product_arid": 2700100001,
      "product_name": "Women's Runway leather ",
      "product_model": "Women's Runway leather ",
      "product_mpn": "",
      "product_brand": "ALEXANDER MCQUEEN",
      "product_category": "MENS",
      "product_subcategory": "SHOES"
    },
    {
      "product_arid": 2700200000,
      "product_name": "Iriza 100 patent",
      "product_model": "Iriza 100 patent",
      "product_mpn": "",
      "product_brand": "CHRISTIAN LOUBOUTIN",
      "product_category": "FOOTWEAR",
      "product_subcategory": "WOMEN APPARELS"
    },
    {
      "product_arid": 2900100000,
      "product_name": "Monogram quilted-leather wallet-on-chain",
      "product_model": "Monogram quilted-leather wallet-on-chain",
      "product_mpn": "",
      "product_brand": "SAINT LAURENT",
      "product_category": "Wallets",
      "product_subcategory": "WALLETS"
    },
    {
      "product_arid": 2800100000,
      "product_name": "GG Marmont small leather shoulder bag",
      "product_model": "GG Marmont small leather shoulder bag",
      "product_mpn": "",
      "product_brand": "GUCCI",
      "product_category": "Bags",
      "product_subcategory": "SHOULDER BAGS"
    },
    {
      "product_arid": 2700100000,
      "product_name": "Speed knitted high-top trainers",
      "product_model": "Speed knitted high-top trainers",
      "product_mpn": "",
      "product_brand": "BALENCIAGA",
      "product_category": "MENS",
      "product_subcategory": "SHOES"
    },
    {
      "product_arid": 1500013,
      "product_name": "Airwrap™ styler Complete",
      "product_model": "Airwrap™ styler Complete",
      "product_mpn": "",
      "product_brand": "Dyson",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "HAIR STRAIGHTENERS"
    },
    {
      "product_arid": 2600100000,
      "product_name": "MAINE DARK GRAY",
      "product_model": "MAINE DARK GRAY",
      "product_mpn": "",
      "product_brand": "InHouse",
      "product_category": "FLOORING",
      "product_subcategory": "RUGS"
    },
    {
      "product_arid": 8000181,
      "product_name": "ANIMATED Yoga Slim - EN",
      "product_model": "ANIMATED Yoga Slim - EN",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000182,
      "product_name": "ANIMATED Yoga Slim - JP",
      "product_model": "ANIMATED Yoga Slim - JP",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 10300147,
      "product_name": "WM14N2E9DN",
      "product_model": "WM14N2E9DN",
      "product_mpn": "WM14N2E9DN",
      "product_brand": "Siemens",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 7900003,
      "product_name": "NINJA Foodi Max OP350UK",
      "product_model": "NINJA Foodi Max OP350UK",
      "product_mpn": "OP350UK",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 2700200002,
      "product_name": "TestEnvStaging",
      "product_model": "TestEnvStaging",
      "product_mpn": "TestEnvStaging",
      "product_brand": "Yamaha",
      "product_category": "FOOTWEAR",
      "product_subcategory": "WOMEN APPARELS"
    },
    {
      "product_arid": 300077,
      "product_name": "Inca Sofa",
      "product_model": "Inca Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 9994600011,
      "product_name": "TestModelEnvStaging",
      "product_model": "TestModelEnvStaging",
      "product_mpn": "",
      "product_brand": "Yamaha",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 13500093,
      "product_name": "Fast lane GDC",
      "product_model": "Fast lane GDC",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500092,
      "product_name": "288 Tiered display",
      "product_model": "288 Tiered display",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500091,
      "product_name": "144 Rhombus display",
      "product_model": "144 Rhombus display",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 500700000,
      "product_name": "Netgear Router",
      "product_model": "Netgear Router",
      "product_mpn": "",
      "product_brand": "Dell",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 2000100001,
      "product_name": "Tatton wood sage",
      "product_model": "Tatton wood sage",
      "product_mpn": "",
      "product_brand": "Alitex",
      "product_category": "GARDEN OUTDOOR",
      "product_subcategory": "GARDEN BUILDINGS"
    },
    {
      "product_arid": 13500094,
      "product_name": "288 Tiered half can",
      "product_model": "288 Tiered half can",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 500048,
      "product_name": "Neverstop",
      "product_model": "Neverstop",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 500047,
      "product_name": "Smart tank",
      "product_model": "Smart tank",
      "product_mpn": "",
      "product_brand": "HP",
      "product_category": "COMPUTING",
      "product_subcategory": "PRINTERS"
    },
    {
      "product_arid": 300086,
      "product_name": "Goodwood Modular 3 Seat Recliner",
      "product_model": "Goodwood Modular 3 Seat Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300085,
      "product_name": "Jasmine 3 Seater Sofa",
      "product_model": "Jasmine 3 Seater Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300084,
      "product_name": "Clayton 3 Seater Sofa",
      "product_model": "Clayton 3 Seater Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300105,
      "product_name": "Jasmine Armchair Sofa Bed",
      "product_model": "Jasmine Armchair Sofa Bed",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300104,
      "product_name": "Jasmine Round Footstool",
      "product_model": "Jasmine Round Footstool",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300103,
      "product_name": "Jasmine 2 Seater Sofa Bed",
      "product_model": "Jasmine 2 Seater Sofa Bed",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300102,
      "product_name": "Jasmine Footstool",
      "product_model": "Jasmine Footstool",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300101,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300100,
      "product_name": "Goodwood Storage Footstool",
      "product_model": "Goodwood Storage Footstool",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300107,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300099,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300106,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300135,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Linen with Khalifa gold",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300136,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103, Campo Linen with Khalifa gold",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103, Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300137,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Linen with Khalifa gold",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300138,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091,Campo Linen with Khalifa gold",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300139,
      "product_name": "Jasmine 4 seater sofa -300082, Campo Linen with Khalifa gold",
      "product_model": "Jasmine 4 seater sofa -300082, Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300140,
      "product_name": "Jasmine accent chair -300083, Campo Linen with Khalifa gold",
      "product_model": "Jasmine accent chair -300083, Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300141,
      "product_name": "Jasmine armchair -300081,Campo Linen with Khalifa gold",
      "product_model": "Jasmine armchair -300081,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300142,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089,Campo Linen with Khalifa gold",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300143,
      "product_name": "Jasmine Armchair Sofa Bed-300105,Campo Linen with Khalifa gold",
      "product_model": "Jasmine Armchair Sofa Bed-300105,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300144,
      "product_name": "Jasmine Footstool-300102, Campo Linen with Khalifa gold",
      "product_model": "Jasmine Footstool-300102, Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300145,
      "product_name": "Jasmine Round Footstool-300104,Campo Linen with Khalifa gold",
      "product_model": "Jasmine Round Footstool-300104,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300146,
      "product_name": "Jasmine Storage Footstool-300090,Campo Linen with Khalifa gold",
      "product_model": "Jasmine Storage Footstool-300090,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700300021,
      "product_name": "Romsey Console Table",
      "product_model": "Romsey Console Table",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300020,
      "product_name": "Romsey Small Sideboard",
      "product_model": "Romsey Small Sideboard",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300019,
      "product_name": "Romsey Desk",
      "product_model": "Romsey Desk",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300018,
      "product_name": "Romsey Dressing Table",
      "product_model": "Romsey Dressing Table",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300017,
      "product_name": "Romsey Tall Bookcase",
      "product_model": "Romsey Tall Bookcase",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300016,
      "product_name": "Romsey Large TV Unit",
      "product_model": "Romsey Large TV Unit",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300015,
      "product_name": "Romsey 7 Drawer Chest",
      "product_model": "Romsey 7 Drawer Chest",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300014,
      "product_name": "Romsey Side Table",
      "product_model": "Romsey Side Table",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300013,
      "product_name": "Romsey Small TV Unit",
      "product_model": "Romsey Small TV Unit",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300012,
      "product_name": "Romsey Display Cabinet",
      "product_model": "Romsey Display Cabinet",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300011,
      "product_name": "Romsey Storage Cabinet",
      "product_model": "Romsey Storage Cabinet",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300010,
      "product_name": "Romsey 5 Drawer Tallboy",
      "product_model": "Romsey 5 Drawer Tallboy",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300009,
      "product_name": "Romsey Double Wardrobe",
      "product_model": "Romsey Double Wardrobe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300008,
      "product_name": "Romsey Nest of Tables",
      "product_model": "Romsey Nest of Tables",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300007,
      "product_name": "Romsey 2 Drawer Coffee Table",
      "product_model": "Romsey 2 Drawer Coffee Table",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300006,
      "product_name": "Romsey 2 Drawer Bedside Table",
      "product_model": "Romsey 2 Drawer Bedside Table",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300005,
      "product_name": "Romsey 5 Drawer Chest",
      "product_model": "Romsey 5 Drawer Chest",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300004,
      "product_name": "Romsey Corner TV Unit",
      "product_model": "Romsey Corner TV Unit",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300003,
      "product_name": "Romsey Large Sideboard",
      "product_model": "Romsey Large Sideboard",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300002,
      "product_name": "Romsey Triple Wardrobe",
      "product_model": "Romsey Triple Wardrobe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 300134,
      "product_name": "Jasmine 2 seater sofa -300080,Campo Linen with Khalifa gold",
      "product_model": "Jasmine 2 seater sofa -300080,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300133,
      "product_name": "Jasmine 3 Seater Sofa-300085,Campo Linen with Khalifa gold",
      "product_model": "Jasmine 3 Seater Sofa-300085,Campo Linen with Khalifa gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300098,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300087,
      "product_name": "Clayton Storage Footstool",
      "product_model": "Clayton Storage Footstool",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300088,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300078,
      "product_name": "Clayton 2 seater sofa -",
      "product_model": "Clayton 2 seater sofa -",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300079,
      "product_name": "Clayton Armchair -",
      "product_model": "Clayton Armchair -",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300080,
      "product_name": "Jasmin 2 seater sofa -",
      "product_model": "Jasmin 2 seater sofa -",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300081,
      "product_name": "Jasmine armchair -",
      "product_model": "Jasmine armchair -",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300082,
      "product_name": "Jasmine 4 seater sofa -",
      "product_model": "Jasmine 4 seater sofa -",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300083,
      "product_name": "Jasmine accent chair -",
      "product_model": "Jasmine accent chair -",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300089,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300091,
      "product_name": "Jasmine 3 Seater Sofa Bed",
      "product_model": "Jasmine 3 Seater Sofa Bed",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300092,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300093,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300094,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300095,
      "product_name": "Goodwood Electric Recliner Armchair",
      "product_model": "Goodwood Electric Recliner Armchair",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300096,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300097,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700200020,
      "product_name": "Goodwood Modular 2 Seat Recliner",
      "product_model": "Goodwood Modular 2 Seat Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300090,
      "product_name": "Jasmine Storage Footstool",
      "product_model": "Jasmine Storage Footstool",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 1000200031,
      "product_name": "Bed - Double (animated)",
      "product_model": "Bed - Double (animated)",
      "product_mpn": "",
      "product_brand": "Emma Sleep",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200033,
      "product_name": "Bed - King (animated)",
      "product_model": "Bed - King (animated)",
      "product_mpn": "",
      "product_brand": "Emma Sleep",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 75000121103,
      "product_name": "HBG876EC7S",
      "product_model": "HBG876EC7S",
      "product_mpn": "HBG876EC7S",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 700200021,
      "product_name": "Grey Goose bottle",
      "product_model": "Grey Goose bottle",
      "product_mpn": "",
      "product_brand": "Grey Goose",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 7000042,
      "product_name": "Iiyama G-MASTER GB3466WQSU-B1 34\" UWQHD 1ms 144Hz Gaming Monitor",
      "product_model": "Iiyama G-MASTER GB3466WQSU-B1 34\" UWQHD 1ms 144Hz Gaming Monitor",
      "product_mpn": "",
      "product_brand": "Iiyama",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000041,
      "product_name": "iiyama G-MASTER Red Eagle GB2470HSU-B1 24'' Full HD Gaming Monitor",
      "product_model": "iiyama G-MASTER Red Eagle GB2470HSU-B1 24'' Full HD Gaming Monitor",
      "product_mpn": "",
      "product_brand": "Iiyama",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000040,
      "product_name": "iiyama G-MASTER Red Eagle GB2770HSU-B1 27'' Full HD Gaming Monitor",
      "product_model": "iiyama G-MASTER Red Eagle GB2770HSU-B1 27'' Full HD Gaming Monitor",
      "product_mpn": "",
      "product_brand": "Iiyama",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000039,
      "product_name": "Iiyama G-MASTER G2230HS-B1 21.5\" Full HD 0.8ms Gaming Monitor ",
      "product_model": "Iiyama G-MASTER G2230HS-B1 21.5\" Full HD 0.8ms Gaming Monitor ",
      "product_mpn": "",
      "product_brand": "Iiyama",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000038,
      "product_name": "Iiyama G-Master G2530HSU-B1 24.5\" Full HD Monitor",
      "product_model": "Iiyama G-Master G2530HSU-B1 24.5\" Full HD Monitor",
      "product_mpn": "",
      "product_brand": "Iiyama",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 300197,
      "product_name": "Jasmine armchair -300081, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine armchair -300081, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300200,
      "product_name": "Jasmine Round Footstool-300104, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine Round Footstool-300104, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300201,
      "product_name": "Jasmine Storage Footstool-300090, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine Storage Footstool-300090, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300202,
      "product_name": "Jasmine Armchair Sofa Bed-300105, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine Armchair Sofa Bed-300105, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300199,
      "product_name": "Jasmine Footstool-300102, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine Footstool-300102, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300198,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300196,
      "product_name": "Jasmine accent chair -300083, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine accent chair -300083, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300195,
      "product_name": "Jasmine 4 seater sofa -300082, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine 4 seater sofa -300082, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300194,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300193,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300192,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300191,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300190,
      "product_name": "Jasmine 2 seater sofa -300080, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine 2 seater sofa -300080, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300189,
      "product_name": "Jasmine 3 Seater Sofa-300085, Cosmo Jade with Bamboo Aqua",
      "product_model": "Jasmine 3 Seater Sofa-300085, Cosmo Jade with Bamboo Aqua",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300220,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300222,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300223,
      "product_name": "Jasmine 4 seater sofa -300082, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine 4 seater sofa -300082, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300228,
      "product_name": "Jasmine Footstool-300102, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine Footstool-300102, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300229,
      "product_name": "Jasmine Round Footstool-300104, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine Round Footstool-300104, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300230,
      "product_name": "Jasmine Storage Footstool-300090, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine Storage Footstool-300090, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300227,
      "product_name": "Jasmine Armchair Sofa Bed-300105, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine Armchair Sofa Bed-300105, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300226,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300225,
      "product_name": "Jasmine armchair -300081, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine armchair -300081, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300224,
      "product_name": "Jasmine accent chair -300083, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine accent chair -300083, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300221,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300219,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300218,
      "product_name": "Jasmine 2 seater sofa -300080, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine 2 seater sofa -300080, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300217,
      "product_name": "Jasmine 3 Seater Sofa-300085, Cosmo Linen with Bamboo Taupe",
      "product_model": "Jasmine 3 Seater Sofa-300085, Cosmo Linen with Bamboo Taupe",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300238,
      "product_name": "Jasmine accent chair -300083, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine accent chair -300083, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300239,
      "product_name": "Jasmine armchair -300081, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine armchair -300081, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300240,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300242,
      "product_name": "Jasmine Footstool-300102, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine Footstool-300102, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300243,
      "product_name": "Jasmine Round Footstool-300104, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine Round Footstool-300104, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300244,
      "product_name": "Jasmine Storage Footstool-300090, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine Storage Footstool-300090, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300241,
      "product_name": "Jasmine Armchair Sofa Bed-300105, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine Armchair Sofa Bed-300105, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300237,
      "product_name": "Jasmine 4 seater sofa -300082, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine 4 seater sofa -300082, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300236,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300235,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300234,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300233,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300232,
      "product_name": "Jasmine 2 seater sofa -300080, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine 2 seater sofa -300080, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300231,
      "product_name": "Jasmine 3 Seater Sofa-300085, Cosmo Pewter with Bamboo Slate",
      "product_model": "Jasmine 3 Seater Sofa-300085, Cosmo Pewter with Bamboo Slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300261,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300265,
      "product_name": "Jasmine 4 seater sofa -300082, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine 4 seater sofa -300082, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300270,
      "product_name": "Jasmine Footstool-300102, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine Footstool-300102, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300271,
      "product_name": "Jasmine Round Footstool-300104, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine Round Footstool-300104, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300272,
      "product_name": "Jasmine Storage Footstool-300090, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine Storage Footstool-300090, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300269,
      "product_name": "Jasmine Armchair Sofa Bed-300105, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine Armchair Sofa Bed-300105, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300268,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300267,
      "product_name": "Jasmine armchair -300081, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine armchair -300081, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300266,
      "product_name": "Jasmine accent chair -300083, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine accent chair -300083, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300264,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300263,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300262,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300260,
      "product_name": "Jasmin 2 seater sofa -300080, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmin 2 seater sofa -300080, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300259,
      "product_name": "Jasmine 3 Seater Sofa-300085, Cosmo Spice with Bamboo Spice",
      "product_model": "Jasmine 3 Seater Sofa-300085, Cosmo Spice with Bamboo Spice",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300149,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300150,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300151,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300152,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300153,
      "product_name": "Jasmine 4 seater sofa -300082, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine 4 seater sofa -300082, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300154,
      "product_name": "Jasmine accent chair -300083, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine accent chair -300083, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300155,
      "product_name": "Jasmine armchair -300081, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine armchair -300081, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300156,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300157,
      "product_name": "Jasmine Armchair Sofa Bed-300105, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine Armchair Sofa Bed-300105, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300158,
      "product_name": "Jasmine Footstool-300102, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine Footstool-300102, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300159,
      "product_name": "Jasmine Round Footstool-300104, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine Round Footstool-300104, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300160,
      "product_name": "Jasmine Storage Footstool-300090, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine Storage Footstool-300090, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300148,
      "product_name": "Jasmine 2 seater sofa -300080, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine 2 seater sofa -300080, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300147,
      "product_name": "Jasmine 3 Seater Sofa-300085, Campo Pebble with Khalifa Gold",
      "product_model": "Jasmine 3 Seater Sofa-300085, Campo Pebble with Khalifa Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300285,
      "product_name": "Jasmine Round Footstool-300104,bamboo duck egg",
      "product_model": "Jasmine Round Footstool-300104,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300286,
      "product_name": "Jasmine Storage Footstool-300090,bamboo duck egg",
      "product_model": "Jasmine Storage Footstool-300090,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300284,
      "product_name": "Jasmine Footstool-300102,bamboo duck egg",
      "product_model": "Jasmine Footstool-300102,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300283,
      "product_name": "Jasmine Armchair Sofa Bed-300105,bamboo duck egg",
      "product_model": "Jasmine Armchair Sofa Bed-300105,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300282,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089,bamboo duck egg",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300281,
      "product_name": "Jasmine armchair -300081,bamboo duck egg",
      "product_model": "Jasmine armchair -300081,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300280,
      "product_name": "Jasmine accent chair -300083,bamboo duck egg",
      "product_model": "Jasmine accent chair -300083,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300279,
      "product_name": "Jasmine 4 seater sofa -300082,bamboo duck egg",
      "product_model": "Jasmine 4 seater sofa -300082,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300278,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091,bamboo duck egg",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300277,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101,bamboo duck egg",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300276,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103,bamboo duck egg",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300275,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088,bamboo duck egg",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300274,
      "product_name": "Jasmine 2 seater sofa -300080,bamboo duck egg",
      "product_model": "Jasmine 2 seater sofa -300080,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300273,
      "product_name": "Jasmine 3 Seater Sofa-300085,bamboo duck egg",
      "product_model": "Jasmine 3 Seater Sofa-300085,bamboo duck egg",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300298,
      "product_name": "Jasmine Round Footstool-300104,bamboo slate",
      "product_model": "Jasmine Round Footstool-300104,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300299,
      "product_name": "Jasmine Armchair Sofa Bed-300105,bamboo slate",
      "product_model": "Jasmine Armchair Sofa Bed-300105,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300300,
      "product_name": "Jasmine Storage Footstool-300090,bamboo slate",
      "product_model": "Jasmine Storage Footstool-300090,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300297,
      "product_name": "Jasmine Footstool-300102,bamboo slate",
      "product_model": "Jasmine Footstool-300102,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300296,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089,bamboo slate",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300295,
      "product_name": "Jasmine armchair -300081,bamboo slate",
      "product_model": "Jasmine armchair -300081,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300294,
      "product_name": "Jasmine accent chair -300083,bamboo slate",
      "product_model": "Jasmine accent chair -300083,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300293,
      "product_name": "Jasmine 4 seater sofa -300082,bamboo slate",
      "product_model": "Jasmine 4 seater sofa -300082,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300292,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091,bamboo slate",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300291,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101,bamboo slate",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300290,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103,bamboo slate",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300289,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088,bamboo slate",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300288,
      "product_name": "Jasmin 2 seater sofa -300080,bamboo slate",
      "product_model": "Jasmin 2 seater sofa -300080,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300287,
      "product_name": "Jasmine 3 Seater Sofa-300085,bamboo slate",
      "product_model": "Jasmine 3 Seater Sofa-300085,bamboo slate",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300311,
      "product_name": "Jasmine Armchair Sofa Bed-300105, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine Armchair Sofa Bed-300105, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300313,
      "product_name": "Jasmine Round Footstool-300104, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine Round Footstool-300104, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300314,
      "product_name": "Jasmine Storage Footstool-300090, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine Storage Footstool-300090, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300312,
      "product_name": "Jasmine Footstool-300102, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine Footstool-300102, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300310,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300309,
      "product_name": "Jasmine armchair -300081, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine armchair -300081, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300308,
      "product_name": "Jasmine accent chair -300083, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine accent chair -300083, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300307,
      "product_name": "Jasmine 4 seater sofa -300082, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine 4 seater sofa -300082, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300306,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091,Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091,Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300305,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300304,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103,Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103,Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300303,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300302,
      "product_name": "Jasmine 2 seater sofa -300080, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine 2 seater sofa -300080, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300301,
      "product_name": "Jasmine 3 Seater Sofa-300085, Campo Pewter with Khalifa Steel ",
      "product_model": "Jasmine 3 Seater Sofa-300085, Campo Pewter with Khalifa Steel ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300328,
      "product_name": "Jasmine Storage Footstool-300090, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine Storage Footstool-300090, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300327,
      "product_name": "Jasmine Round Footstool-300104, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine Round Footstool-300104, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300326,
      "product_name": "Jasmine Footstool-300102, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine Footstool-300102, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300325,
      "product_name": "Jasmine Armchair Sofa Bed-300105, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine Armchair Sofa Bed-300105, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300324,
      "product_name": "Jasmine Armchair Deluxe Sofa Bed-300089, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine Armchair Deluxe Sofa Bed-300089, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300323,
      "product_name": "Jasmine armchair -300081, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine armchair -300081, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300322,
      "product_name": "Jasmine accent chair -300083, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine accent chair -300083, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300321,
      "product_name": "Jasmine 4 seater sofa -300082, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine 4 seater sofa -300082, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300320,
      "product_name": "Jasmine 3 Seater Sofa Bed-300091, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine 3 Seater Sofa Bed-300091, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300319,
      "product_name": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine 3 Seater Deluxe Sofa Bed-300101, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300318,
      "product_name": "Jasmine 2 Seater Sofa Bed- 300103, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine 2 Seater Sofa Bed- 300103, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300317,
      "product_name": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine 2 Seater Deluxe Sofa Bed-300088, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300316,
      "product_name": "Jasmin 2 seater sofa -300080, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmin 2 seater sofa -300080, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300315,
      "product_name": "Jasmine 3 Seater Sofa-300085, Campo Taupe with Khalifa Gold ",
      "product_model": "Jasmine 3 Seater Sofa-300085, Campo Taupe with Khalifa Gold ",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300110,
      "product_name": "Clayton Armchair -300079,black",
      "product_model": "Clayton Armchair -300079,black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300109,
      "product_name": "Clayton 2 seater sofa -300078,black",
      "product_model": "Clayton 2 seater sofa -300078,black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300108,
      "product_name": "Clayton Storage Footstool- 300087, black",
      "product_model": "Clayton Storage Footstool- 300087, black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300115,
      "product_name": "Clayton 2 seater sofa Burgandy-300078",
      "product_model": "Clayton 2 seater sofa Burgandy-300078",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300116,
      "product_name": "Clayton Armchair Burgandy-300079",
      "product_model": "Clayton Armchair Burgandy-300079",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300114,
      "product_name": "Clayton Storage Footstool Burgandy- 300087",
      "product_model": "Clayton Storage Footstool Burgandy- 300087",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300118,
      "product_name": "Clayton 2 seater sofa Cream -300078",
      "product_model": "Clayton 2 seater sofa Cream -300078",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300119,
      "product_name": "Clayton Armchair cream -300079",
      "product_model": "Clayton Armchair cream -300079",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300117,
      "product_name": "Clayton Storage Footstool Cream- 300087",
      "product_model": "Clayton Storage Footstool Cream- 300087",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300121,
      "product_name": "Clayton 2 seater sofa -300078,grey",
      "product_model": "Clayton 2 seater sofa -300078,grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300122,
      "product_name": "Clayton Armchair -300079,grey",
      "product_model": "Clayton Armchair -300079,grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300120,
      "product_name": "Clayton Storage Footstool- 300087,grey",
      "product_model": "Clayton Storage Footstool- 300087,grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300365,
      "product_name": "Goodwood Storage Footstool-300100,fabric beige",
      "product_model": "Goodwood Storage Footstool-300100,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300364,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner-300092,fabric beige",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner-300092,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300363,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner-300096,fabric beige",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner-300096,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300362,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,fabric beige",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300361,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,fabric beige",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300360,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,fabric beige",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300359,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,fabric beige",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300358,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner-300097,fabric beige",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner-300097,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300357,
      "product_name": "Goodwood Modular 2 Seat Recliner-700200020,fabric beige",
      "product_model": "Goodwood Modular 2 Seat Recliner-700200020,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300356,
      "product_name": "Goodwood Electric Recliner Armchair-300095,fabric beige",
      "product_model": "Goodwood Electric Recliner Armchair-300095,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300355,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa-300093,fabric beige",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa-300093,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300127,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa-300094,fabric beige",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa-300094,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300377,
      "product_name": "Goodwood Storage Footstool-300100,fabric brown",
      "product_model": "Goodwood Storage Footstool-300100,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300376,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner-300092,fabric brown",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner-300092,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300375,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner-300096,fabric brown",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner-300096,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300374,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,fabric brown",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300373,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,fabric brown",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300372,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,fabric brown",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300371,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,fabric brown",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300370,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner-300097,fabric brown",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner-300097,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300369,
      "product_name": "Goodwood Modular 2 Seat Recliner-700200020,fabric brown",
      "product_model": "Goodwood Modular 2 Seat Recliner-700200020,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300368,
      "product_name": "Goodwood Electric Recliner Armchair-300095,fabric brown",
      "product_model": "Goodwood Electric Recliner Armchair-300095,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300367,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa-300093,fabric brown",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa-300093,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300366,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa-300094,fabric brown",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa-300094,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300389,
      "product_name": "Goodwood Storage Footstool-300100,leather black",
      "product_model": "Goodwood Storage Footstool-300100,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300388,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner-300092,leather black",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner-300092,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300387,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner-300096,leather black",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner-300096,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300386,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather black",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300385,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather black",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300384,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather black",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300383,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather black",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300382,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner-300097,leather black",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner-300097,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300381,
      "product_name": "Goodwood Modular 2 Seat Recliner-700200020,leather black",
      "product_model": "Goodwood Modular 2 Seat Recliner-700200020,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300380,
      "product_name": "Goodwood Electric Recliner Armchair-300095,leather black",
      "product_model": "Goodwood Electric Recliner Armchair-300095,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300379,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather black",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300378,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather black",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300401,
      "product_name": "Goodwood Storage Footstool-300100,leather brown",
      "product_model": "Goodwood Storage Footstool-300100,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300400,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner-300092,leather brown",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner-300092,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300399,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner-300096,leather brown",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner-300096,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300398,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather brown",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300397,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather brown",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300396,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather brown",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300395,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather brown",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300394,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner-300097,leather brown",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner-300097,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300393,
      "product_name": "Goodwood Modular 2 Seat Recliner-700200020,leather brown",
      "product_model": "Goodwood Modular 2 Seat Recliner-700200020,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300392,
      "product_name": "Goodwood Electric Recliner Armchair-300095,leather brown",
      "product_model": "Goodwood Electric Recliner Armchair-300095,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300391,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather brown",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300390,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather brown",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300413,
      "product_name": "Goodwood Storage Footstool-300100,leather dark grey",
      "product_model": "Goodwood Storage Footstool-300100,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300412,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner-300092,leather dark grey",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner-300092,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300411,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner-300096,leather dark grey",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner-300096,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300410,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather dark grey",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300409,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather dark grey",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300408,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather dark grey",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300407,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather dark grey",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300406,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner-300097,leather dark grey",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner-300097,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300405,
      "product_name": "Goodwood Modular 2 Seat Recliner-700200020,leather dark grey",
      "product_model": "Goodwood Modular 2 Seat Recliner-700200020,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300404,
      "product_name": "Goodwood Electric Recliner Armchair-300095,leather dark grey",
      "product_model": "Goodwood Electric Recliner Armchair-300095,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300403,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather dark grey",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300402,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather dark grey",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300424,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner-300092,leather light grey",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner-300092,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300425,
      "product_name": "Goodwood Storage Footstool-300100,leather light grey",
      "product_model": "Goodwood Storage Footstool-300100,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300423,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner-300096,leather light grey",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner-300096,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300422,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather light grey",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300421,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather light grey",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300420,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather light grey",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300419,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather light grey",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300418,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner-300097,leather light grey",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner-300097,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300417,
      "product_name": "Goodwood Modular 2 Seat Recliner-700200020,leather light grey",
      "product_model": "Goodwood Modular 2 Seat Recliner-700200020,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300416,
      "product_name": "Goodwood Electric Recliner Armchair-300095,leather light grey",
      "product_model": "Goodwood Electric Recliner Armchair-300095,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300415,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather light grey",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300414,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather light grey",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300436,
      "product_name": "Goodwood Modular 5 Seat Corner Recliner-300092,leather off white",
      "product_model": "Goodwood Modular 5 Seat Corner Recliner-300092,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300437,
      "product_name": "Goodwood Storage Footstool-300100,leather off white",
      "product_model": "Goodwood Storage Footstool-300100,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300435,
      "product_name": "Goodwood Modular 4 Seater Corner Recliner-300096,leather off white",
      "product_model": "Goodwood Modular 4 Seater Corner Recliner-300096,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300434,
      "product_name": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather off white",
      "product_model": "Goodwood Modular 4 Seat Right Hand Corner Recliner -300106,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300433,
      "product_name": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather off white",
      "product_model": "Goodwood Modular 4 Seat Left Hand Corner Recliner-300098,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300432,
      "product_name": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather off white",
      "product_model": "Goodwood Modular 3 Seat Right Hand Corner Recliner-300107,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300431,
      "product_name": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather off white",
      "product_model": "Goodwood Modular 3 Seat Left Hand Corner Recliner-300099,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300430,
      "product_name": "Goodwood Modular 3 Seat Corner Recliner-300097,leather off white",
      "product_model": "Goodwood Modular 3 Seat Corner Recliner-300097,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300429,
      "product_name": "Goodwood Modular 2 Seat Recliner-700200020,leather off white",
      "product_model": "Goodwood Modular 2 Seat Recliner-700200020,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300428,
      "product_name": "Goodwood Electric Recliner Armchair-300095,leather off white",
      "product_model": "Goodwood Electric Recliner Armchair-300095,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300427,
      "product_name": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather off white",
      "product_model": "Goodwood 3 Seater Electric Recliner Sofa-300093,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300426,
      "product_name": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather off white",
      "product_model": "Goodwood 2 Seater Electric Recliner Sofa-300094,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 1000200051,
      "product_name": "Avatar - Bed",
      "product_model": "Avatar - Bed",
      "product_mpn": "",
      "product_brand": "Emma Sleep",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200050,
      "product_name": "Avatar - mattress",
      "product_model": "Avatar - mattress",
      "product_mpn": "",
      "product_brand": "Emma Sleep",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 300128,
      "product_name": "Clayton 3 Seater Sofa-300084,black",
      "product_model": "Clayton 3 Seater Sofa-300084,black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300130,
      "product_name": "Clayton 3 Seater Sofa-300084,burgandy",
      "product_model": "Clayton 3 Seater Sofa-300084,burgandy",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300131,
      "product_name": "Clayton 3 Seater Sofa-300084,cream",
      "product_model": "Clayton 3 Seater Sofa-300084,cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300132,
      "product_name": "Clayton 3 Seater Sofa-300084,grey",
      "product_model": "Clayton 3 Seater Sofa-300084,grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 12600066,
      "product_name": "Shark S6005UK Steam Mop",
      "product_model": "Shark S6005UK Steam Mop",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600065,
      "product_name": "Shark S6003UK Steam Mop",
      "product_model": "Shark S6003UK Steam Mop",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600064,
      "product_name": "Shark S6001UK Steam Mop",
      "product_model": "Shark S6001UK Steam Mop",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600063,
      "product_name": "Shark S1000UK Steam Mop",
      "product_model": "Shark S1000UK Steam Mop",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600062,
      "product_name": "Shark WV251UK Vacuum",
      "product_model": "Shark WV251UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600061,
      "product_name": "Shark CH950UKT Vacuum",
      "product_model": "Shark CH950UKT Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600060,
      "product_name": "Shark PZ1000UKT Vacuum",
      "product_model": "Shark PZ1000UKT Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600059,
      "product_name": "Shark PZ1000UK Vacuum",
      "product_model": "Shark PZ1000UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 91001310003,
      "product_name": "Ninja SP101UK Mini Oven",
      "product_model": "Ninja SP101UK Mini Oven",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 300200016,
      "product_name": "Ninja CB350UK Blender",
      "product_model": "Ninja CB350UK Blender",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 12600058,
      "product_name": "Shark HZ500UKT Vacuum",
      "product_model": "Shark HZ500UKT Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 1500200001,
      "product_name": "Selfridges Tuxedo",
      "product_model": "Selfridges Tuxedo",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "MENS",
      "product_subcategory": "MEN'S CLOTHINGS"
    },
    {
      "product_arid": 12600057,
      "product_name": "Shark HZ500UK Vacuum",
      "product_model": "Shark HZ500UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600056,
      "product_name": "Shark IZ201UKTSB Vacuum (Amazon)",
      "product_model": "Shark IZ201UKTSB Vacuum (Amazon)",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600055,
      "product_name": "Shark NZ801UKTSB Vacuum (Amazon)",
      "product_model": "Shark NZ801UKTSB Vacuum (Amazon)",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600054,
      "product_name": "Shark NZ801UKT Vacuum",
      "product_model": "Shark NZ801UKT Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600053,
      "product_name": "Shark NZ801UK Vacuum",
      "product_model": "Shark NZ801UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600052,
      "product_name": "Shark NV602UKT Vacuum",
      "product_model": "Shark NV602UKT Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600051,
      "product_name": "Shark NV602UK Vacuum",
      "product_model": "Shark NV602UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600050,
      "product_name": "Shark AZ950UKT Vacuum",
      "product_model": "Shark AZ950UKT Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600049,
      "product_name": "Shark AZ950UK Vacuum",
      "product_model": "Shark AZ950UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600044,
      "product_name": "Shark AZ910UK Vacuum",
      "product_model": "Shark AZ910UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600048,
      "product_name": "Shark NV702UKT Vacuum",
      "product_model": "Shark NV702UKT Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600047,
      "product_name": "Shark NV702UK Vacuum",
      "product_model": "Shark NV702UK Vacuum",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 300200015,
      "product_name": "Ninja BN495UK Blender",
      "product_model": "Ninja BN495UK Blender",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 300200014,
      "product_name": "Ninja QB3001UKS Blender",
      "product_model": "Ninja QB3001UKS Blender",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 300200013,
      "product_name": "Ninja HB150UK Blender",
      "product_model": "Ninja HB150UK Blender",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 7800004,
      "product_name": "Ninja AG551UK Health Grill",
      "product_model": "Ninja AG551UK Health Grill",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "GRILLS"
    },
    {
      "product_arid": 7900008,
      "product_name": "Ninja AG301UK Health Grill",
      "product_model": "Ninja AG301UK Health Grill",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 7900007,
      "product_name": "Ninja OP450UK Multicooker",
      "product_model": "Ninja OP450UK Multicooker",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 7900006,
      "product_name": "Ninja OP500UK Multicooker",
      "product_model": "Ninja OP500UK Multicooker",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 7900005,
      "product_name": "Ninja OP300UK Multicooker",
      "product_model": "Ninja OP300UK Multicooker",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 7900004,
      "product_name": "Ninja OP100UK Multicooker",
      "product_model": "Ninja OP100UK Multicooker",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 9500016,
      "product_name": "Ninja AF300UK Air Fryer",
      "product_model": "Ninja AF300UK Air Fryer",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 9500015,
      "product_name": "Ninja AF160UK Air Fryer",
      "product_model": "Ninja AF160UK Air Fryer",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 9500014,
      "product_name": "Ninja AF100UK Air Fryer",
      "product_model": "Ninja AF100UK Air Fryer",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 300100003,
      "product_name": "Ninja BN800UK Food Processor",
      "product_model": "Ninja BN800UK Food Processor",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD PROCESSORS"
    },
    {
      "product_arid": 300200012,
      "product_name": "Ninja BN750UK Blender",
      "product_model": "Ninja BN750UK Blender",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 300100002,
      "product_name": "Ninja BN650UK Food Processor",
      "product_model": "Ninja BN650UK Food Processor",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FOOD PROCESSORS"
    },
    {
      "product_arid": 300200011,
      "product_name": "Ninja CB100UK Blender",
      "product_model": "Ninja CB100UK Blender",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 3100201,
      "product_name": "ERGO K860 Graphite",
      "product_model": "ERGO K860 Graphite",
      "product_mpn": "ERGO K860 Graphite",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 3100100001,
      "product_name": "WINE-INFUSED SALAME WITH NATURAL WHITE CHEDDAR CHEESE",
      "product_model": "WINE-INFUSED SALAME WITH NATURAL WHITE CHEDDAR CHEESE",
      "product_mpn": "WINE-INFUSED SALAME WITH NATURAL WHITE CHEDDAR CHEESE",
      "product_brand": "Tyson Foods",
      "product_category": "GROCERY",
      "product_subcategory": "SNACKS"
    },
    {
      "product_arid": 3100100000,
      "product_name": "GENOA SALAME WITH NATURAL WHITE CHEDDAR CHEESE",
      "product_model": "GENOA SALAME WITH NATURAL WHITE CHEDDAR CHEESE",
      "product_mpn": "GENOA SALAME WITH NATURAL WHITE CHEDDAR CHEESE",
      "product_brand": "Tyson Foods",
      "product_category": "GROCERY",
      "product_subcategory": "SNACKS"
    },
    {
      "product_arid": 8000187,
      "product_name": "Acer - SF313-53",
      "product_model": "Acer - SF313-53",
      "product_mpn": "Acer - SF313-53",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000186,
      "product_name": "Asus - HL701TS",
      "product_model": "Asus - HL701TS",
      "product_mpn": "Asus - HL701TS",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000185,
      "product_name": "HP - 13-BD0063TU",
      "product_model": "HP - 13-BD0063TU",
      "product_mpn": "HP - 13-BD0063TU",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000184,
      "product_name": "HP - 13-aw2001TU",
      "product_model": "HP - 13-aw2001TU",
      "product_mpn": "HP - 13-aw2001TU",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 12600067,
      "product_name": "Shark WandVac System - WV361UK",
      "product_model": "Shark WandVac System - WV361UK",
      "product_mpn": "Shark WandVac System - WV361UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600068,
      "product_name": "Shark WandVac System - WV362UKT",
      "product_model": "Shark WandVac System - WV362UKT",
      "product_mpn": "Shark WandVac System - WV362UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600069,
      "product_name": "Shark Corded Upright with powered LIFT-AWAY. Pet model. NV612UKT",
      "product_model": "Shark Corded Upright with powered LIFT-AWAY. Pet model. NV612UKT",
      "product_mpn": "Shark Corded Upright with powered LIFT-AWAY. Pet model. NV612UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600070,
      "product_name": "Shark Corded Upright NV612UK",
      "product_model": "Shark Corded Upright NV612UK",
      "product_mpn": "Shark Corded Upright NV612UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600071,
      "product_name": "Shark Corded Vacuum Cleaner AZ912UKT",
      "product_model": "Shark Corded Vacuum Cleaner AZ912UKT",
      "product_mpn": "Shark Corded Vacuum Cleaner AZ912UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600072,
      "product_name": "Shark Corded Upright with Powered Lift-Away NV620UKT",
      "product_model": "Shark Corded Upright with Powered Lift-Away NV620UKT",
      "product_mpn": "Shark Corded Upright with Powered Lift-Away NV620UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600073,
      "product_name": "Shark Corded Vacuum with Anti Hair Wrap NZ850UK",
      "product_model": "Shark Corded Vacuum with Anti Hair Wrap NZ850UK",
      "product_mpn": "Shark Corded Vacuum with Anti Hair Wrap NZ850UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600074,
      "product_name": "Corded Upright NZ850UKT",
      "product_model": "Corded Upright NZ850UKT",
      "product_mpn": "Corded Upright NZ850UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600075,
      "product_name": "Shark Cordless Vacuum IZ300UK",
      "product_model": "Shark Cordless Vacuum IZ300UK",
      "product_mpn": "Shark Cordless Vacuum IZ300UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600076,
      "product_name": "Shark Cordless Vacuum with Anti Hair-Wrap IZ300UKT",
      "product_model": "Shark Cordless Vacuum with Anti Hair-Wrap IZ300UKT",
      "product_mpn": "Shark Cordless Vacuum with Anti Hair-Wrap IZ300UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600077,
      "product_name": "Shark Cordless Vacuum IZ320UK",
      "product_model": "Shark Cordless Vacuum IZ320UK",
      "product_mpn": "Shark Cordless Vacuum IZ320UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600078,
      "product_name": "Shark Cordless Vacuum IZ320UKT",
      "product_model": "Shark Cordless Vacuum IZ320UKT",
      "product_mpn": "Shark Cordless Vacuum IZ320UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600079,
      "product_name": "Shark DuoClean Cordless Vacuum ICZ160UK",
      "product_model": "Shark DuoClean Cordless Vacuum ICZ160UK",
      "product_mpn": "Shark DuoClean Cordless Vacuum ICZ160UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600080,
      "product_name": "Shark DuoClean Cordless Vacuum ICZ160UKT",
      "product_model": "Shark DuoClean Cordless Vacuum ICZ160UKT",
      "product_mpn": "Shark DuoClean Cordless Vacuum ICZ160UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600081,
      "product_name": "Shark Cordless Vacuum Cleaner ICZ300UK",
      "product_model": "Shark Cordless Vacuum Cleaner ICZ300UK",
      "product_mpn": "Shark Cordless Vacuum Cleaner ICZ300UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600082,
      "product_name": "Shark Cordless Vacuum Cleaner ICZ300UKT",
      "product_model": "Shark Cordless Vacuum Cleaner ICZ300UKT",
      "product_mpn": "Shark Cordless Vacuum Cleaner ICZ300UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600084,
      "product_name": "HE400UK",
      "product_model": "HE400UK",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 300500002,
      "product_name": "Shark Air Purifier 6 HE600UK",
      "product_model": "Shark Air Purifier 6 HE600UK",
      "product_mpn": "Shark Air Purifier 6 HE600UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "AIR PURIFIERS"
    },
    {
      "product_arid": 12600083,
      "product_name": "Black Friday AF400UKDB",
      "product_model": "Black Friday AF400UKDB",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 9500017,
      "product_name": "Black Friday BN800UKDB",
      "product_model": "Black Friday BN800UKDB",
      "product_mpn": "",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 300300006,
      "product_name": "Ninja Cold Press Juicer",
      "product_model": "Ninja Cold Press Juicer",
      "product_mpn": "Ninja Cold Press Juicer",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "JUICERS"
    },
    {
      "product_arid": 300438,
      "product_name": "Goodwood Modular 3 Seat Recliner-300086,fabric beige",
      "product_model": "Goodwood Modular 3 Seat Recliner-300086,fabric beige",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300439,
      "product_name": "Goodwood Modular 3 Seat Recliner-300086,fabric brown",
      "product_model": "Goodwood Modular 3 Seat Recliner-300086,fabric brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300440,
      "product_name": "Goodwood Modular 3 Seat Recliner-300086,leather black",
      "product_model": "Goodwood Modular 3 Seat Recliner-300086,leather black",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300441,
      "product_name": "Goodwood Modular 3 Seat Recliner-300086,leather brown",
      "product_model": "Goodwood Modular 3 Seat Recliner-300086,leather brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300442,
      "product_name": "Goodwood Modular 3 Seat Recliner-300086,leather Dark grey",
      "product_model": "Goodwood Modular 3 Seat Recliner-300086,leather Dark grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300443,
      "product_name": "Goodwood Modular 3 Seat Recliner-300086,leather Light grey",
      "product_model": "Goodwood Modular 3 Seat Recliner-300086,leather Light grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300444,
      "product_name": "Goodwood Modular 3 Seat Recliner- 300086,leather off white",
      "product_model": "Goodwood Modular 3 Seat Recliner- 300086,leather off white",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 8000188,
      "product_name": "HP - 13-aw2001TU (ANIMATED)",
      "product_model": "HP - 13-aw2001TU (ANIMATED)",
      "product_mpn": "HP - 13-aw2001TU",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000189,
      "product_name": "HP - 13-BD0063TU (ANIMATED)",
      "product_model": "HP - 13-BD0063TU (ANIMATED)",
      "product_mpn": "HP - 13-BD0063TU",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 300448,
      "product_name": "Samson Modular 3 Seat Recliner",
      "product_model": "Samson Modular 3 Seat Recliner",
      "product_mpn": "SAM-GROUP-9-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300447,
      "product_name": "Nebraska 3 Seater Sofa",
      "product_model": "Nebraska 3 Seater Sofa",
      "product_mpn": "NEB042-AER-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300446,
      "product_name": "Jensen 3 Seater Sofa",
      "product_model": "Jensen 3 Seater Sofa",
      "product_mpn": "ST-JSN003-HAM-SIL-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300445,
      "product_name": "Colorado 3 Seater Electric Recliner Sofa",
      "product_model": "Colorado 3 Seater Electric Recliner Sofa",
      "product_mpn": "COL203-30827",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300457,
      "product_name": "Jensen 4 Seater Split Sofa",
      "product_model": "Jensen 4 Seater Split Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300458,
      "product_name": "Nebraska 2 Seater Sofa",
      "product_model": "Nebraska 2 Seater Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300459,
      "product_name": "Nebraska 2 Seater Deluxe Sofa Bed",
      "product_model": "Nebraska 2 Seater Deluxe Sofa Bed",
      "product_mpn": "NEB017-AER-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300460,
      "product_name": "Nebraska Armchair",
      "product_model": "Nebraska Armchair",
      "product_mpn": "NEB001-AER-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300462,
      "product_name": "Nebraska Corner Sofa Left Hand",
      "product_model": "Nebraska Corner Sofa Left Hand",
      "product_mpn": "NEB075L-AER-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300464,
      "product_name": "Samson Modular 2 Seat Recliner",
      "product_model": "Samson Modular 2 Seat Recliner",
      "product_mpn": "SAM-GROUP-8-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300463,
      "product_name": "Nebraska Corner Sofa Right Hand",
      "product_model": "Nebraska Corner Sofa Right Hand",
      "product_mpn": "NEB075R-AER-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300465,
      "product_name": "Samson Electric Reclining Armchair",
      "product_model": "Samson Electric Reclining Armchair",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300466,
      "product_name": "Samson Modular 5 Seat Corner Recliner",
      "product_model": "Samson Modular 5 Seat Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300467,
      "product_name": "Samson Modular 4 Seat Corner Recliner",
      "product_model": "Samson Modular 4 Seat Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300468,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner",
      "product_mpn": "SAM-GROUP-4-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300469,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner",
      "product_mpn": "SAM-GROUP-5-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300470,
      "product_name": "Samson Modular 3 Seat Corner Recliner",
      "product_model": "Samson Modular 3 Seat Corner Recliner",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300471,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner",
      "product_mpn": "SAM-GROUP-6-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300472,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner",
      "product_mpn": "SAM-GROUP-7-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300450,
      "product_name": "Colorado 2 Seater Electric Recliner Sofa",
      "product_model": "Colorado 2 Seater Electric Recliner Sofa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300451,
      "product_name": "Colorado Electric Recliner Armchair",
      "product_model": "Colorado Electric Recliner Armchair",
      "product_mpn": "COL201-30827",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300452,
      "product_name": "Jensen 2 Seater Sofa",
      "product_model": "Jensen 2 Seater Sofa",
      "product_mpn": "ST-JSN002-HAM-SIL-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300453,
      "product_name": "Jensen 4 Seater Sofa",
      "product_model": "Jensen 4 Seater Sofa",
      "product_mpn": "ST-JSN004-HAM-SIL-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300454,
      "product_name": "Jensen Armchair",
      "product_model": "Jensen Armchair",
      "product_mpn": "ST-JSN001-HAM-SIL-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300455,
      "product_name": "Jensen Footstool",
      "product_model": "Jensen Footstool",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300456,
      "product_name": "Jensen Accent Chair",
      "product_model": "Jensen Accent Chair",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300461,
      "product_name": "Nebraska Storage Footstool",
      "product_model": "Nebraska Storage Footstool",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300484,
      "product_name": "Nebraska Corner Sofa Left Hand (Pillow Back)",
      "product_model": "Nebraska Corner Sofa Left Hand (Pillow Back)",
      "product_mpn": "NEB076L-AER-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300485,
      "product_name": "Nebraska Corner Sofa Right Hand (Pillow Back)",
      "product_model": "Nebraska Corner Sofa Right Hand (Pillow Back)",
      "product_mpn": "NEB076R-AER-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700500000,
      "product_name": "Fabric Back/Seat Rubber Wood Lounge Chair",
      "product_model": "Fabric Back/Seat Rubber Wood Lounge Chair",
      "product_mpn": "",
      "product_brand": "Lorell",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 300488,
      "product_name": "Colorado Electric Recliner Armchair - Ranch Dark Brown Fabric",
      "product_model": "Colorado Electric Recliner Armchair - Ranch Dark Brown Fabric",
      "product_mpn": "COL201-RAH-DBRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300487,
      "product_name": "Colorado 2 Seater Electric Recliner Sofa - Ranch Dark Brown Fabric ",
      "product_model": "Colorado 2 Seater Electric Recliner Sofa - Ranch Dark Brown Fabric ",
      "product_mpn": "COL202-RAH-DBRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300486,
      "product_name": "Colorado 3 Seater Electric Recliner Sofa - Ranch Dark Brown Fabric",
      "product_model": "Colorado 3 Seater Electric Recliner Sofa - Ranch Dark Brown Fabric",
      "product_mpn": "COL203-RAH-DBRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300493,
      "product_name": "Jensen 4 Seater Split Sofa-Black Fabric with Zest",
      "product_model": "Jensen 4 Seater Split Sofa-Black Fabric with Zest",
      "product_mpn": "JSN004M-HAM-BLK-ZST-SPLIT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300492,
      "product_name": "Jensen Armchair-Black Fabric with Zest",
      "product_model": "Jensen Armchair-Black Fabric with Zest",
      "product_mpn": "JSN001-HAM-BLK-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300491,
      "product_name": "Jensen 4 Seater Sofa-Black Fabric with Zest",
      "product_model": "Jensen 4 Seater Sofa-Black Fabric with Zest",
      "product_mpn": "JSN004-HAM-BLK-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300490,
      "product_name": "Jensen 2 Seater Sofa-Black Fabric with Zest",
      "product_model": "Jensen 2 Seater Sofa-Black Fabric with Zest",
      "product_mpn": "JSN002-HAM-BLK-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300489,
      "product_name": "Jensen 3 Seater Sofa-Black Fabric with Zest",
      "product_model": "Jensen 3 Seater Sofa-Black Fabric with Zest",
      "product_mpn": "JSN003-HAM-BLK-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300495,
      "product_name": "Jensen 2 Seater Sofa-Beige Fabric with Zest",
      "product_model": "Jensen 2 Seater Sofa-Beige Fabric with Zest",
      "product_mpn": "JSN002-HAM-BEI-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300497,
      "product_name": "Jensen Armchair-Beige Fabric with Zest",
      "product_model": "Jensen Armchair-Beige Fabric with Zest",
      "product_mpn": "JSN001-HAM-BEI-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300498,
      "product_name": "Jensen 4 Seater Split Sofa-Beige Fabric with Zest",
      "product_model": "Jensen 4 Seater Split Sofa-Beige Fabric with Zest",
      "product_mpn": "JSN004M-HAM-BEI-ZST-SPLIT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300496,
      "product_name": "Jensen 4 Seater Sofa-Beige Fabric with Zest",
      "product_model": "Jensen 4 Seater Sofa-Beige Fabric with Zest",
      "product_mpn": "JSN004-HAM-BEI-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300494,
      "product_name": "Jensen 3 Seater Sofa-Beige Fabric with Zest",
      "product_model": "Jensen 3 Seater Sofa-Beige Fabric with Zest",
      "product_mpn": "JSN003-HAM-BEI-ZST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300503,
      "product_name": "Jensen 4 Seater Split Sofa-Silver Fabric with Coral",
      "product_model": "Jensen 4 Seater Split Sofa-Silver Fabric with Coral",
      "product_mpn": "JSN004M-HAM-SIL-COR-SPLIT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300502,
      "product_name": "Jensen Armchair-Silver Fabric with Coral",
      "product_model": "Jensen Armchair-Silver Fabric with Coral",
      "product_mpn": "ST-JSN001-HAM-SIL-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300501,
      "product_name": "Jensen 4 Seater Sofa-Silver Fabric with Coral",
      "product_model": "Jensen 4 Seater Sofa-Silver Fabric with Coral",
      "product_mpn": "ST-JSN004-HAM-SIL-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300500,
      "product_name": "Jensen 2 Seater Sofa-Silver Fabric with Coral",
      "product_model": "Jensen 2 Seater Sofa-Silver Fabric with Coral",
      "product_mpn": "ST-JSN002-HAM-SIL-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300499,
      "product_name": "Jensen 3 Seater Sofa-Silver Fabric with Coral",
      "product_model": "Jensen 3 Seater Sofa-Silver Fabric with Coral",
      "product_mpn": "ST-JSN003-HAM-SIL-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300508,
      "product_name": "Jensen 4 Seater Split Sofa-Black Fabric with Coral",
      "product_model": "Jensen 4 Seater Split Sofa-Black Fabric with Coral",
      "product_mpn": "JSN004M-HAM-BLK-COR-SPLIT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300507,
      "product_name": "Jensen Armchair-Black Fabric with Coral",
      "product_model": "Jensen Armchair-Black Fabric with Coral",
      "product_mpn": "JSN001-HAM-BLK-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300506,
      "product_name": "Jensen 4 Seater Sofa-Black Fabric with Coral",
      "product_model": "Jensen 4 Seater Sofa-Black Fabric with Coral",
      "product_mpn": "JSN004-HAM-BLK-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300505,
      "product_name": "Jensen 2 Seater Sofa-Black Fabric with Coral",
      "product_model": "Jensen 2 Seater Sofa-Black Fabric with Coral",
      "product_mpn": "JSN002-HAM-BLK-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300504,
      "product_name": "Jensen 3 Seater Sofa-Black Fabric with Coral",
      "product_model": "Jensen 3 Seater Sofa-Black Fabric with Coral",
      "product_mpn": "JSN003-HAM-BLK-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300513,
      "product_name": "Jensen 4 Seater Split Sofa-Beige Fabric with Coral",
      "product_model": "Jensen 4 Seater Split Sofa-Beige Fabric with Coral",
      "product_mpn": "JSN004M-HAM-BEI-COR-SPLIT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300512,
      "product_name": "Jensen Armchair-Beige Fabric with Coral",
      "product_model": "Jensen Armchair-Beige Fabric with Coral",
      "product_mpn": "JSN001-HAM-BEI-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300511,
      "product_name": "Jensen 4 Seater Sofa-Beige Fabric with Coral",
      "product_model": "Jensen 4 Seater Sofa-Beige Fabric with Coral",
      "product_mpn": "JSN004-HAM-BEI-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300510,
      "product_name": "Jensen 2 Seater Sofa-Beige Fabric with Coral",
      "product_model": "Jensen 2 Seater Sofa-Beige Fabric with Coral",
      "product_mpn": "JSN002-HAM-BEI-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300509,
      "product_name": "Jensen 3 Seater Sofa-Beige Fabric with Coral",
      "product_model": "Jensen 3 Seater Sofa-Beige Fabric with Coral",
      "product_mpn": "JSN003-HAM-BEI-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300515,
      "product_name": "Jensen Accent Chair-Coral Fabric",
      "product_model": "Jensen Accent Chair-Coral Fabric",
      "product_mpn": "JSN009-ELE-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300514,
      "product_name": "Jensen Footstool-Coral Fabric",
      "product_model": "Jensen Footstool-Coral Fabric",
      "product_mpn": "ST-JSNFOOT-ELE-COR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300540,
      "product_name": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Silver Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Silver Fabric with Silver",
      "product_mpn": "NEB076R-AER-SIL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300539,
      "product_name": "Nebraska Corner Sofa Left Hand (Pillow Back)-Aero Silver Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Left Hand (Pillow Back)-Aero Silver Fabric with Silver",
      "product_mpn": "NEB076L-AER-SIL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300538,
      "product_name": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB076R-AER-CHA-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300537,
      "product_name": "Nebraska Corner Sofa Left Hand (Pillow Back)-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Left Hand (Pillow Back)-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB076L-AER-CHA-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300528,
      "product_name": "Nebraska Corner Sofa Right Hand-Aero Silver Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Right Hand-Aero Silver Fabric with Silver",
      "product_mpn": "NEB075R-AER-SIL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300527,
      "product_name": "Nebraska Corner Sofa Left Hand-Aero Silver Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Left Hand-Aero Silver Fabric with Silver",
      "product_mpn": "NEB075L-AER-SIL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300526,
      "product_name": "Nebraska Armchair-Aero Silver Fabric with Silver",
      "product_model": "Nebraska Armchair-Aero Silver Fabric with Silver",
      "product_mpn": "NEB001-AER-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300525,
      "product_name": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Silver Fabric with Silver",
      "product_model": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Silver Fabric with Silver",
      "product_mpn": "NEB017-AER-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300524,
      "product_name": "Nebraska 2 Seater Sofa-Aero Silver Fabric with Silver",
      "product_model": "Nebraska 2 Seater Sofa-Aero Silver Fabric with Silver",
      "product_mpn": "NEB042-AER-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300523,
      "product_name": "Nebraska 3 Seater Sofa-Aero Silver Fabric with Silver",
      "product_model": "Nebraska 3 Seater Sofa-Aero Silver Fabric with Silver",
      "product_mpn": "NEB042-AER-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300522,
      "product_name": "Nebraska Corner Sofa Right Hand-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Right Hand-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB075R-AER-CHA-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300521,
      "product_name": "Nebraska Corner Sofa Left Hand-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska Corner Sofa Left Hand-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB075L-AER-CHA-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300520,
      "product_name": "Nebraska Storage Footstool-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska Storage Footstool-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB075SF-AER-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300519,
      "product_name": "Nebraska Armchair-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska Armchair-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB001-AER-CHA-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300518,
      "product_name": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB017-AER-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300517,
      "product_name": "Nebraska 2 Seater Sofa-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska 2 Seater Sofa-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB044-AER-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300516,
      "product_name": "Nebraska 3 Seater Sofa-Aero Charcoal Fabric with Silver",
      "product_model": "Nebraska 3 Seater Sofa-Aero Charcoal Fabric with Silver",
      "product_mpn": "NEB042-AER-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300536,
      "product_name": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB076R-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300535,
      "product_name": "Nebraska Corner Sofa Left Hand (Pillow Back) - Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska Corner Sofa Left Hand (Pillow Back) - Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB076L-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300534,
      "product_name": "Nebraska Corner Sofa Right Hand-Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska Corner Sofa Right Hand-Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB075R-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300533,
      "product_name": "Nebraska Corner Sofa Left Hand-Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska Corner Sofa Left Hand-Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB075L-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300532,
      "product_name": "Nebraska Armchair-Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska Armchair-Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB001-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300531,
      "product_name": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB017-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300530,
      "product_name": "Nebraska 2 Seater Sofa-Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska 2 Seater Sofa-Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB044-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300529,
      "product_name": "Nebraska 3 Seater Sofa-Aero Charcoal Fabric with Grey",
      "product_model": "Nebraska 3 Seater Sofa-Aero Charcoal Fabric with Grey",
      "product_mpn": "NEB042-AER-CHA-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300548,
      "product_name": "Nebraska Corner Sofa Left Hand (Pillow Back)-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska Corner Sofa Left Hand (Pillow Back)-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB076L-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300549,
      "product_name": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska Corner Sofa Right Hand (Pillow Back)-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB076R-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300547,
      "product_name": "Nebraska Corner Sofa Right Hand-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska Corner Sofa Right Hand-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB075R-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300546,
      "product_name": "Nebraska Corner Sofa Left Hand-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska Corner Sofa Left Hand-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB075L-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300545,
      "product_name": "Nebraska Storage Footstool-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska Storage Footstool-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB075SF-AER-FAW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300544,
      "product_name": "Nebraska Armchair-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska Armchair-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB001-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300543,
      "product_name": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska 2 Seater Deluxe Sofa Bed-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB017-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300542,
      "product_name": "Nebraska 2 Seater Sofa-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska 2 Seater Sofa-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB044-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300541,
      "product_name": "Nebraska 3 Seater Sofa-Aero Fawn Fabric with Taupe",
      "product_model": "Nebraska 3 Seater Sofa-Aero Fawn Fabric with Taupe",
      "product_mpn": "NEB042-AER-FAW-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300558,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner-White Leather",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner-White Leather",
      "product_mpn": "SAM-GROUP-7-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300559,
      "product_name": "Samson Modular 3 Seat Recliner-White Leather",
      "product_model": "Samson Modular 3 Seat Recliner-White Leather",
      "product_mpn": "SAM-GROUP-9-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300557,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner-White Leather",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner-White Leather",
      "product_mpn": "SAM-GROUP-6-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300556,
      "product_name": "Samson Modular 3 Seat Corner Recliner-White Leather",
      "product_model": "Samson Modular 3 Seat Corner Recliner-White Leather",
      "product_mpn": "SAM-GROUP-1-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300555,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner-White Leather",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner-White Leather",
      "product_mpn": "SAM-GROUP-4-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300554,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner-White Leather",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner-White Leather",
      "product_mpn": "SAM-GROUP-4-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300553,
      "product_name": "Samson Modular 4 Seat Corner Recliner-White Leather",
      "product_model": "Samson Modular 4 Seat Corner Recliner-White Leather",
      "product_mpn": "SAM-GROUP-2-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300552,
      "product_name": "Samson Modular 5 Seat Corner Recliner-White Leather",
      "product_model": "Samson Modular 5 Seat Corner Recliner-White Leather",
      "product_mpn": "SAM-GROUP-3-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300551,
      "product_name": "Samson Modular 2 Seat Recliner-White Leather",
      "product_model": "Samson Modular 2 Seat Recliner-White Leather",
      "product_mpn": "SAM-GROUP-8-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300550,
      "product_name": "Samson Electric Reclining Armchair-White Leather",
      "product_model": "Samson Electric Reclining Armchair-White Leather",
      "product_mpn": "SAM201-LEATH-NL5116-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300568,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner-Beige Leather",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-7-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300569,
      "product_name": "Samson Modular 3 Seat Recliner-Beige Leather",
      "product_model": "Samson Modular 3 Seat Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-9-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300567,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner-Beige Leather",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-6-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300566,
      "product_name": "Samson Modular 3 Seat Corner Recliner-Beige Leather",
      "product_model": "Samson Modular 3 Seat Corner Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-1-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300565,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner-Beige Leather",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-5-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300564,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner-Beige Leather",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-4-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300563,
      "product_name": "Samson Modular 4 Seat Corner Recliner-Beige Leather",
      "product_model": "Samson Modular 4 Seat Corner Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-2-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300562,
      "product_name": "Samson Modular 5 Seat Corner Recliner-Beige Leather",
      "product_model": "Samson Modular 5 Seat Corner Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-3-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300561,
      "product_name": "Samson Modular 2 Seat Recliner-Beige Leather",
      "product_model": "Samson Modular 2 Seat Recliner-Beige Leather",
      "product_mpn": "SAM-GROUP-8-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300560,
      "product_name": "Samson Electric Reclining Armchair-Beige Leather",
      "product_model": "Samson Electric Reclining Armchair-Beige Leather",
      "product_mpn": "SAM201-LEATH-NL5112-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300579,
      "product_name": "Samson Modular 3 Seat Recliner-Stone Leather",
      "product_model": "Samson Modular 3 Seat Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-9-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300578,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner-Stone Leather",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-7-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300577,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner-Stone Leather",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-6-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300576,
      "product_name": "Samson Modular 3 Seat Corner Recliner-Stone Leather",
      "product_model": "Samson Modular 3 Seat Corner Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-1-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300575,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner-Stone Leather",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-5-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300574,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner-Stone Leather",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-4-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300573,
      "product_name": "Samson Modular 4 Seat Corner Recliner-Stone Leather",
      "product_model": "Samson Modular 4 Seat Corner Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-2-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300572,
      "product_name": "Samson Modular 5 Seat Corner Recliner-Stone Leather",
      "product_model": "Samson Modular 5 Seat Corner Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-3-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300571,
      "product_name": "Samson Modular 2 Seat Recliner-Stone Leather",
      "product_model": "Samson Modular 2 Seat Recliner-Stone Leather",
      "product_mpn": "SAM-GROUP-8-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300570,
      "product_name": "Samson Electric Reclining Armchair-Stone Leather",
      "product_model": "Samson Electric Reclining Armchair-Stone Leather",
      "product_mpn": "SAM201-LEATH-NL5115-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300588,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner-Taupe Leather",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-7-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300589,
      "product_name": "Samson Modular 3 Seat Recliner-Taupe Leather",
      "product_model": "Samson Modular 3 Seat Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-9-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300587,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner-Taupe Leather",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-6-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300586,
      "product_name": "Samson Modular 3 Seat Corner Recliner-Taupe Leather",
      "product_model": "Samson Modular 3 Seat Corner Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-1-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300585,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner-Taupe Leather",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-5-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300584,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner-Taupe Leather",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-4-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300583,
      "product_name": "Samson Modular 4 Seat Corner Recliner-Taupe Leather",
      "product_model": "Samson Modular 4 Seat Corner Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-2-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300582,
      "product_name": "Samson Modular 5 Seat Corner Recliner-Taupe Leather",
      "product_model": "Samson Modular 5 Seat Corner Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-3-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300581,
      "product_name": "Samson Modular 2 Seat Recliner-Taupe Leather",
      "product_model": "Samson Modular 2 Seat Recliner-Taupe Leather",
      "product_mpn": "SAM-GROUP-8-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300580,
      "product_name": "Samson Electric Reclining Armchair-Taupe Leather",
      "product_model": "Samson Electric Reclining Armchair-Taupe Leather",
      "product_mpn": "SAM201-LEATH-NL5103-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300599,
      "product_name": "Samson Modular 3 Seat Recliner-Light Blue Leather",
      "product_model": "Samson Modular 3 Seat Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-9-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300598,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner-Light Blue Leather",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-7-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300597,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner-Light Blue Leather",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-6-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300596,
      "product_name": "Samson Modular 3 Seat Corner Recliner-Light Blue Leather",
      "product_model": "Samson Modular 3 Seat Corner Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-1-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300595,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner-Light Blue Leather",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-5-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300594,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner-Light Blue Leather",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-4-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300593,
      "product_name": "Samson Modular 4 Seat Corner Recliner-Light Blue Leather",
      "product_model": "Samson Modular 4 Seat Corner Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-2-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300592,
      "product_name": "Samson Modular 5 Seat Corner Recliner-Light Blue Leather",
      "product_model": "Samson Modular 5 Seat Corner Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-3-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300591,
      "product_name": "Samson Modular 2 Seat Recliner-Light Blue Leather",
      "product_model": "Samson Modular 2 Seat Recliner-Light Blue Leather",
      "product_mpn": "SAM-GROUP-8-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300590,
      "product_name": "Samson Electric Reclining Armchair-Light Blue Leather",
      "product_model": "Samson Electric Reclining Armchair-Light Blue Leather",
      "product_mpn": "SAM201-LEATH-NL5133-LBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300609,
      "product_name": "Samson Modular 3 Seat Recliner-Black Leather",
      "product_model": "Samson Modular 3 Seat Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-9-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300608,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner-Black Leather",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-7-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300607,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner-Black Leather",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-6-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300606,
      "product_name": "Samson Modular 3 Seat Corner Recliner-Black Leather",
      "product_model": "Samson Modular 3 Seat Corner Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-1-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300605,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner-Black Leather",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-5-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300604,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner-Black Leather",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-4-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300603,
      "product_name": "Samson Modular 4 Seat Corner Recliner-Black Leather",
      "product_model": "Samson Modular 4 Seat Corner Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-2-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300602,
      "product_name": "Samson Modular 5 Seat Corner Recliner-Black Leather",
      "product_model": "Samson Modular 5 Seat Corner Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-3-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300601,
      "product_name": "Samson Modular 2 Seat Recliner-Black Leather",
      "product_model": "Samson Modular 2 Seat Recliner-Black Leather",
      "product_mpn": "SAM-GROUP-8-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300600,
      "product_name": "Samson Electric Reclining Armchair-Black Leather",
      "product_model": "Samson Electric Reclining Armchair-Black Leather",
      "product_mpn": "SAM201-LEATH-NL5109-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300619,
      "product_name": "Samson Modular 3 Seat Recliner-Slate Leather",
      "product_model": "Samson Modular 3 Seat Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-9-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300618,
      "product_name": "Samson Modular 3 Seat Right Hand Corner Recliner-Slate Leather",
      "product_model": "Samson Modular 3 Seat Right Hand Corner Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-7-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300617,
      "product_name": "Samson Modular 3 Seat Left Hand Corner Recliner-Slate Leather",
      "product_model": "Samson Modular 3 Seat Left Hand Corner Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-6-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300616,
      "product_name": "Samson Modular 3 Seat Corner Recliner-Slate Leather",
      "product_model": "Samson Modular 3 Seat Corner Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-1-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300615,
      "product_name": "Samson Modular 4 Seat Right Hand Corner Recliner-Slate Leather",
      "product_model": "Samson Modular 4 Seat Right Hand Corner Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-5-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300614,
      "product_name": "Samson Modular 4 Seat Left Hand Corner Recliner-Slate Leather",
      "product_model": "Samson Modular 4 Seat Left Hand Corner Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-4-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300613,
      "product_name": "Samson Modular 4 Seat Corner Recliner-Slate Leather",
      "product_model": "Samson Modular 4 Seat Corner Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-2-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300612,
      "product_name": "Samson Modular 5 Seat Corner Recliner-Slate Leather",
      "product_model": "Samson Modular 5 Seat Corner Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-3-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300611,
      "product_name": "Samson Modular 2 Seat Recliner-Slate Leather",
      "product_model": "Samson Modular 2 Seat Recliner-Slate Leather",
      "product_mpn": "SAM-GROUP-8-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300610,
      "product_name": "Samson Electric Reclining Armchair-Slate Leather",
      "product_model": "Samson Electric Reclining Armchair-Slate Leather",
      "product_mpn": "SAM201-LEATH-NL5101-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 8000190,
      "product_name": "Asus - HL701TS (ANIMATED)",
      "product_model": "Asus - HL701TS (ANIMATED)",
      "product_mpn": "Asus - HL701TS",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000191,
      "product_name": "Acer - SF313-53 (ANIMATED)",
      "product_model": "Acer - SF313-53 (ANIMATED)",
      "product_mpn": "Acer - SF313-53",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 300682,
      "product_name": "Clayton Armchair -300079, Light Brown",
      "product_model": "Clayton Armchair -300079, Light Brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300681,
      "product_name": "Clayton 2 seater sofa -300078, Light Brown",
      "product_model": "Clayton 2 seater sofa -300078, Light Brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300680,
      "product_name": "Clayton Storage Footstool- 300087, Light Brown",
      "product_model": "Clayton Storage Footstool- 300087, Light Brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300679,
      "product_name": "Clayton 3 Seater Sofa-300084, Light Brown",
      "product_model": "Clayton 3 Seater Sofa-300084, Light Brown",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 200080,
      "product_name": "Samsung Galaxy Z Fold 3- Phantom Silver",
      "product_model": "Samsung Galaxy Z Fold 3- Phantom Silver",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200079,
      "product_name": "Samsung Galaxy Z Fold 3- Phantom Green",
      "product_model": "Samsung Galaxy Z Fold 3- Phantom Green",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200078,
      "product_name": "Samsung Galaxy Z Fold 3-Phantom Black",
      "product_model": "Samsung Galaxy Z Fold 3-Phantom Black",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200082,
      "product_name": "Samsung Galaxy Z Flip 3-Green",
      "product_model": "Samsung Galaxy Z Flip 3-Green",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200084,
      "product_name": "Samsung Galaxy Z Flip 3- lavender",
      "product_model": "Samsung Galaxy Z Flip 3- lavender",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200083,
      "product_name": "Samsung Galaxy Z Flip 3- Phantom black",
      "product_model": "Samsung Galaxy Z Flip 3- Phantom black",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 200081,
      "product_name": "Samsung Galaxy Z Flip 3- Cream",
      "product_model": "Samsung Galaxy Z Flip 3- Cream",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 3200100001,
      "product_name": "Jacobs Flipz FSDU",
      "product_model": "Jacobs Flipz FSDU",
      "product_mpn": "Jacobs Flipz FSDU",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 3200100000,
      "product_name": "McVities Flipz FSDU",
      "product_model": "McVities Flipz FSDU",
      "product_mpn": "McVities Flipz FSDU",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 301300000,
      "product_name": "Bee & Willow Home Hudson Table Lamp in Black",
      "product_model": "Bee & Willow Home Hudson Table Lamp in Black",
      "product_mpn": "Bee & Willow Home Hudson Table Lamp in Black",
      "product_brand": "Bed Bath and Beyond",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TABLE LAMPS"
    },
    {
      "product_arid": 700004,
      "product_name": "Carol Robinson Abstract Lapis Light Canvas Wall Art",
      "product_model": "Carol Robinson Abstract Lapis Light Canvas Wall Art",
      "product_mpn": "Carol Robinson Abstract Lapis Light Canvas Wall Art",
      "product_brand": "Bed Bath and Beyond",
      "product_category": "ALL ROOMS",
      "product_subcategory": "ARTWORKS"
    },
    {
      "product_arid": 700700000,
      "product_name": "Madison Park Shandra ll Storage Bench in Charcoal",
      "product_model": "Madison Park Shandra ll Storage Bench in Charcoal",
      "product_mpn": "Madison Park Shandra ll Storage Bench in Charcoal",
      "product_brand": "Bed Bath and Beyond",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STORAGE BENCHES"
    },
    {
      "product_arid": 3200100003,
      "product_name": "2M Pladis Shelf",
      "product_model": "2M Pladis Shelf",
      "product_mpn": "",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 3200100002,
      "product_name": "1M Pladis Shelf",
      "product_model": "1M Pladis Shelf",
      "product_mpn": "1M Pladis Shelf",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 300687,
      "product_name": "Orbit 3 Seater Sofa-Green Fabric",
      "product_model": "Orbit 3 Seater Sofa-Green Fabric",
      "product_mpn": "ORB003-PLAIN-GRN-GRS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300686,
      "product_name": "Brighton 3 Seater Sofa-Charcoal Fabric",
      "product_model": "Brighton 3 Seater Sofa-Charcoal Fabric",
      "product_mpn": "BGN003-PLAIN-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300685,
      "product_name": "Arizona 3 Seater Sofa-Charcoal Fabric",
      "product_model": "Arizona 3 Seater Sofa-Charcoal Fabric",
      "product_mpn": "ARZ003-PLAIN-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300684,
      "product_name": "Houston 3 Seater Sofa-Teal Velvet ",
      "product_model": "Houston 3 Seater Sofa-Teal Velvet ",
      "product_mpn": "HSN003-VEL-TEA-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300683,
      "product_name": "Dylan 3 Seater Electric Recliner Sofa-Oxford Grey Fabric",
      "product_model": "Dylan 3 Seater Electric Recliner Sofa-Oxford Grey Fabric",
      "product_mpn": "DLN203-CU91075-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300713,
      "product_name": "Orbit Storage Footstool-Green Fabric",
      "product_model": "Orbit Storage Footstool-Green Fabric",
      "product_mpn": "ORB010-PLAIN-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300712,
      "product_name": "Orbit Footstool-Green Fabric",
      "product_model": "Orbit Footstool-Green Fabric",
      "product_mpn": "ORBFOOT-PLAIN-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300711,
      "product_name": "Orbit Loveseat-Green Fabric",
      "product_model": "Orbit Loveseat-Green Fabric",
      "product_mpn": "ORB057-PLAIN-GRN-GRS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300710,
      "product_name": "Orbit 4 Seater Sofa-Green Fabric",
      "product_model": "Orbit 4 Seater Sofa-Green Fabric",
      "product_mpn": "ORB004-PLAIN-GRN-GRS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300709,
      "product_name": "Orbit 2 Seater Sofa-Green Fabric",
      "product_model": "Orbit 2 Seater Sofa-Green Fabric",
      "product_mpn": "ORB002-PLAIN-GRN-GRS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300708,
      "product_name": "Brighton Footstool-Charcoal Fabric",
      "product_model": "Brighton Footstool-Charcoal Fabric",
      "product_mpn": "BGNFOOT-PLAIN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300707,
      "product_name": "Brighton Accent Chair-Charcoal Fabric",
      "product_model": "Brighton Accent Chair-Charcoal Fabric",
      "product_mpn": "BGN009-PLAIN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300706,
      "product_name": "Brighton Love Seat-Charcoal Fabric",
      "product_model": "Brighton Love Seat-Charcoal Fabric",
      "product_mpn": "BGN020-PLAIN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300705,
      "product_name": "Brighton Right Hand Chaise Sofa-Charcoal Fabric",
      "product_model": "Brighton Right Hand Chaise Sofa-Charcoal Fabric",
      "product_mpn": "BGN059R-PLAIN-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300704,
      "product_name": "Brighton Armchair-Charcoal Fabric",
      "product_model": "Brighton Armchair-Charcoal Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300703,
      "product_name": "Brighton 4 Seater Sofa-Charcoal Fabric",
      "product_model": "Brighton 4 Seater Sofa-Charcoal Fabric",
      "product_mpn": "BGN004-PLAIN-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300702,
      "product_name": "Brighton Corner Sofa Right Hand-Charcoal Fabric",
      "product_model": "Brighton Corner Sofa Right Hand-Charcoal Fabric",
      "product_mpn": "BGN021R-PLAIN-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300701,
      "product_name": "Brighton Corner Sofa Left Hand-Charcoal Fabric",
      "product_model": "Brighton Corner Sofa Left Hand-Charcoal Fabric",
      "product_mpn": "BGN021L-PLAIN-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300700,
      "product_name": "Brighton Left Hand Chaise Sofa-Charcoal Fabric",
      "product_model": "Brighton Left Hand Chaise Sofa-Charcoal Fabric",
      "product_mpn": "BGN059L-PLAIN-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300699,
      "product_name": "Brighton 2 Seater Sofa-Charcoal Fabric",
      "product_model": "Brighton 2 Seater Sofa-Charcoal Fabric",
      "product_mpn": "BGN002-PLAIN-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300698,
      "product_name": "Arizona Storage Footstool-Charcoal Fabric",
      "product_model": "Arizona Storage Footstool-Charcoal Fabric",
      "product_mpn": "ARZ010-PLAIN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300697,
      "product_name": "Arizona Armchair-Charcoal Fabric",
      "product_model": "Arizona Armchair-Charcoal Fabric",
      "product_mpn": "ARZ001-PLAIN-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300696,
      "product_name": "Arizona 2 Seater Sofa-Charcoal Fabric",
      "product_model": "Arizona 2 Seater Sofa-Charcoal Fabric",
      "product_mpn": "ARZ002-PLAIN-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300695,
      "product_name": "Houston Footstool-Teal Velvet ",
      "product_model": "Houston Footstool-Teal Velvet ",
      "product_mpn": "HSNFOOT-VEL-TEA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300694,
      "product_name": "Houston Armchair-Teal Velvet ",
      "product_model": "Houston Armchair-Teal Velvet ",
      "product_mpn": "HSN001-VEL-TEA-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300693,
      "product_name": "Houston Loveseat-Teal Velvet ",
      "product_model": "Houston Loveseat-Teal Velvet ",
      "product_mpn": "HSN057-VEL-TEA-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300692,
      "product_name": "Houston 4 Seater Sofa-Teal Velvet ",
      "product_model": "Houston 4 Seater Sofa-Teal Velvet ",
      "product_mpn": "HSN004-VEL-TEA-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300691,
      "product_name": "Houston 2 Seater Sofa-Teal Velvet ",
      "product_model": "Houston 2 Seater Sofa-Teal Velvet ",
      "product_mpn": "HSN002-VEL-TEA-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300690,
      "product_name": "Dylan Storage Footstool-Oxford Grey Fabric",
      "product_model": "Dylan Storage Footstool-Oxford Grey Fabric",
      "product_mpn": "DLN032-CU91075-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300689,
      "product_name": "Dylan Electric Recliner Armchair-Oxford Grey Fabric",
      "product_model": "Dylan Electric Recliner Armchair-Oxford Grey Fabric",
      "product_mpn": "DLN201-CU91075-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300688,
      "product_name": "Dylan 2 Seater Electric Recliner Sofa-Oxford Grey Fabric",
      "product_model": "Dylan 2 Seater Electric Recliner Sofa-Oxford Grey Fabric",
      "product_mpn": "DLN202-CU91075-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300756,
      "product_name": "Bramble Footstool-Pellier Thyme Fabric",
      "product_model": "Bramble Footstool-Pellier Thyme Fabric",
      "product_mpn": "BBLFOOT-PLR-THM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300755,
      "product_name": "Bramble Armchair-Pellier Thyme Fabric",
      "product_model": "Bramble Armchair-Pellier Thyme Fabric",
      "product_mpn": "ST-BBL001-PLR-THM-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300754,
      "product_name": "Bramble 2 Seater Sofa-Pellier Thyme Fabric",
      "product_model": "Bramble 2 Seater Sofa-Pellier Thyme Fabric",
      "product_mpn": "ST-BBL002-PLR-THM-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300753,
      "product_name": "Montgomery Footstool-Navy Velvet",
      "product_model": "Montgomery Footstool-Navy Velvet",
      "product_mpn": "MTGFOOT-RSA-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300752,
      "product_name": "Montgomery Armchair-Navy Velvet",
      "product_model": "Montgomery Armchair-Navy Velvet",
      "product_mpn": "MTG001-RSA-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300751,
      "product_name": "Montgomery 2 Seater Sofa-Navy Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Navy Velvet",
      "product_mpn": "MTG002-RSA-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300750,
      "product_name": "Muse Storage Footstool-Light Grey Leather",
      "product_model": "Muse Storage Footstool-Light Grey Leather",
      "product_mpn": "MSEFOOT-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300749,
      "product_name": "Muse Electric Recliner Armchair-Light Grey Leather",
      "product_model": "Muse Electric Recliner Armchair-Light Grey Leather",
      "product_mpn": "MSE201-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300748,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Light Grey Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Light Grey Leather",
      "product_mpn": "MSE202-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300747,
      "product_name": "Linden Storage Footstool-Dark Grey Leather",
      "product_model": "Linden Storage Footstool-Dark Grey Leather",
      "product_mpn": "LDN010-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300746,
      "product_name": "Linden Armchair-Dark Grey Leather",
      "product_model": "Linden Armchair-Dark Grey Leather",
      "product_mpn": "LDN001-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300745,
      "product_name": "LInden 2 Seater Sofa-Dark Grey Leather",
      "product_model": "LInden 2 Seater Sofa-Dark Grey Leather",
      "product_mpn": "LDN002-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300744,
      "product_name": "Palermo Storage Footstool-Two Tone Brown Leather",
      "product_model": "Palermo Storage Footstool-Two Tone Brown Leather",
      "product_mpn": "PLM032-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300743,
      "product_name": "Palermo Armchair-Two Tone Brown Leather",
      "product_model": "Palermo Armchair-Two Tone Brown Leather",
      "product_mpn": "PLM001-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300742,
      "product_name": "Palermo 2 Seater Sofa-Two Tone Brown Leather",
      "product_model": "Palermo 2 Seater Sofa-Two Tone Brown Leather",
      "product_mpn": "PLM002-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300741,
      "product_name": "Zodiac Footstool-Grey Fabric",
      "product_model": "Zodiac Footstool-Grey Fabric",
      "product_mpn": "ZOD500FS-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300740,
      "product_name": "Zodiac 3 Seat Right Hand Modular Corner Sofa-Grey Fabric",
      "product_model": "Zodiac 3 Seat Right Hand Modular Corner Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-7-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300739,
      "product_name": "Zodiac 3 Seat Left Hand Modular Corner Sofa-Grey Fabric",
      "product_model": "Zodiac 3 Seat Left Hand Modular Corner Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-6-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300738,
      "product_name": "Zodiac 4 Seat Right Hand Modular Corner Sofa-Grey Fabric",
      "product_model": "Zodiac 4 Seat Right Hand Modular Corner Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-5-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300737,
      "product_name": "Zodiac 4 Seat Left Hand Modular Corner Sofa-Grey Fabric",
      "product_model": "Zodiac 4 Seat Left Hand Modular Corner Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-4-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300736,
      "product_name": "Zodiac 4 Seat Modular Corner Sofa-Grey Fabric",
      "product_model": "Zodiac 4 Seat Modular Corner Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-2-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300735,
      "product_name": "Zodiac 5 Seat Modular Corner Sofa-Grey Fabric",
      "product_model": "Zodiac 5 Seat Modular Corner Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-3-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300734,
      "product_name": "Zodiac 2 Seat Modular Sofa-Grey Fabric",
      "product_model": "Zodiac 2 Seat Modular Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-8-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300733,
      "product_name": "Zodiac 3 Seat Modular Corner Sofa- Grey Fabric",
      "product_model": "Zodiac 3 Seat Modular Corner Sofa- Grey Fabric",
      "product_mpn": "ZOD-GROUP-1-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300732,
      "product_name": "Zodiac Modular 4 Seat Left Hand Chaise Sofa-Grey Fabric",
      "product_model": "Zodiac Modular 4 Seat Left Hand Chaise Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-4FS-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300731,
      "product_name": "Zodiac Modular 4 Seat Right Hand Chaise Sofa-Grey Fabric",
      "product_model": "Zodiac Modular 4 Seat Right Hand Chaise Sofa-Grey Fabric",
      "product_mpn": "ZOD-GROUP-5FS-B1312-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300730,
      "product_name": "Carter Storage Footstool -Grey Fabric",
      "product_model": "Carter Storage Footstool -Grey Fabric",
      "product_mpn": "CRT010-MLR-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300729,
      "product_name": "Carter Electric Recliner Armchair-Grey Fabric",
      "product_model": "Carter Electric Recliner Armchair-Grey Fabric",
      "product_mpn": "CRT201-MLR-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300728,
      "product_name": "Carter 2 Seat Electric Recliner-Grey Fabric",
      "product_model": "Carter 2 Seat Electric Recliner-Grey Fabric",
      "product_mpn": "CRT202-MLR-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300721,
      "product_name": "Bramble 3 Seater Sofa-Pellier Thyme Fabric",
      "product_model": "Bramble 3 Seater Sofa-Pellier Thyme Fabric",
      "product_mpn": "ST-BBL003-PLR-THM-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300720,
      "product_name": "Montgomery 3 Seater Sofa-Navy Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Navy Velvet",
      "product_mpn": "MTG003-RSA-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300719,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Light Grey Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Light Grey Leather",
      "product_mpn": "MSE203-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300718,
      "product_name": "Linden 3 Seater Sofa-Dark Grey Leather",
      "product_model": "Linden 3 Seater Sofa-Dark Grey Leather",
      "product_mpn": "LDN003-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300717,
      "product_name": "Palermo 3 Seater Sofa-Two Tone Brown Leather",
      "product_model": "Palermo 3 Seater Sofa-Two Tone Brown Leather",
      "product_mpn": "PLM003-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300716,
      "product_name": "Zodiac 3 Seater Modular Sofa-Grey Fabric",
      "product_model": "Zodiac 3 Seater Modular Sofa-Grey Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300715,
      "product_name": "Carter 3 Seat Electric Recliner-Grey Fabric",
      "product_model": "Carter 3 Seat Electric Recliner-Grey Fabric",
      "product_mpn": "CRT203-MLR-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300904,
      "product_name": "Palermo Armchair - Tan Leather",
      "product_model": "Palermo Armchair - Tan Leather",
      "product_mpn": "PLM001-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300905,
      "product_name": "Palermo Storage Footstool-Tan Leather",
      "product_model": "Palermo Storage Footstool-Tan Leather",
      "product_mpn": "PLM032-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300903,
      "product_name": "Palermo 2 Seater Sofa-Tan Leather",
      "product_model": "Palermo 2 Seater Sofa-Tan Leather",
      "product_mpn": "PLM002-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300902,
      "product_name": "Palermo 3 Seater Sofa-Tan Leather",
      "product_model": "Palermo 3 Seater Sofa-Tan Leather",
      "product_mpn": "PLM003-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300909,
      "product_name": "Palermo Storage Footstool-Off White Leather",
      "product_model": "Palermo Storage Footstool-Off White Leather",
      "product_mpn": "PLM032-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300908,
      "product_name": "Palermo Armchair-Off White Leather",
      "product_model": "Palermo Armchair-Off White Leather",
      "product_mpn": "PLM001-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300907,
      "product_name": "Palermo 2 Seater Sofa-Off White Leather",
      "product_model": "Palermo 2 Seater Sofa-Off White Leather",
      "product_mpn": "PLM002-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300906,
      "product_name": "Palermo 3 Seater Sofa-Off White Leather",
      "product_model": "Palermo 3 Seater Sofa-Off White Leather",
      "product_mpn": "PLM003-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300913,
      "product_name": "Palermo Storage Footstool-Light Grey Leather",
      "product_model": "Palermo Storage Footstool-Light Grey Leather",
      "product_mpn": "PLM032-LEATH-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300912,
      "product_name": "Palermo Armchair-Light Grey Leather",
      "product_model": "Palermo Armchair-Light Grey Leather",
      "product_mpn": "PLM001-LEATH-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300911,
      "product_name": "Palermo 2 Seater Sofa-Light Grey Leather",
      "product_model": "Palermo 2 Seater Sofa-Light Grey Leather",
      "product_mpn": "PLM002-LEATH-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300910,
      "product_name": "Palermo 3 Seater Sofa-Light Grey Leather",
      "product_model": "Palermo 3 Seater Sofa-Light Grey Leather",
      "product_mpn": "PLM003-LEATH-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300917,
      "product_name": "Palermo Storage Footstool-Dark Grey Leather",
      "product_model": "Palermo Storage Footstool-Dark Grey Leather",
      "product_mpn": "PLM032-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300916,
      "product_name": "Palermo Armchair-Dark Grey Leather",
      "product_model": "Palermo Armchair-Dark Grey Leather",
      "product_mpn": "PLM001-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300915,
      "product_name": "Palermo 2 Seater Sofa-Dark Grey Leather",
      "product_model": "Palermo 2 Seater Sofa-Dark Grey Leather",
      "product_mpn": "PLM002-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300914,
      "product_name": "Palermo 3 Seater Sofa-Dark Grey Leather",
      "product_model": "Palermo 3 Seater Sofa-Dark Grey Leather",
      "product_mpn": "PLM003-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300921,
      "product_name": "Palermo Storage Footstool-Black Leather",
      "product_model": "Palermo Storage Footstool-Black Leather",
      "product_mpn": "PLM032-LEATH-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300920,
      "product_name": "Palermo Armchair-Black Leather",
      "product_model": "Palermo Armchair-Black Leather",
      "product_mpn": "PLM001-LEATH-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300919,
      "product_name": "Palermo 2 Seater Sofa-Black Leather",
      "product_model": "Palermo 2 Seater Sofa-Black Leather",
      "product_mpn": "PLM002-LEATH-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300918,
      "product_name": "Palermo 3 Seater Sofa-Black Leather",
      "product_model": "Palermo 3 Seater Sofa-Black Leather",
      "product_mpn": "PLM003-LEATH-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300925,
      "product_name": "Palermo Storage Footstool-Petrol Leather",
      "product_model": "Palermo Storage Footstool-Petrol Leather",
      "product_mpn": "PLM032-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300924,
      "product_name": "Palermo Armchair-Petrol Leather",
      "product_model": "Palermo Armchair-Petrol Leather",
      "product_mpn": "PLM001-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300923,
      "product_name": "Palermo 2 Seater Sofa-Petrol Leather",
      "product_model": "Palermo 2 Seater Sofa-Petrol Leather",
      "product_mpn": "PLM002-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300922,
      "product_name": "Palermo 3 Seater Sofa-Petrol Leather",
      "product_model": "Palermo 3 Seater Sofa-Petrol Leather",
      "product_mpn": "PLM003-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300929,
      "product_name": "Palermo Storage Footstool-Slate Leather",
      "product_model": "Palermo Storage Footstool-Slate Leather",
      "product_mpn": "PLM032-LEATH-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300928,
      "product_name": "Palermo Armchair-Slate Leather",
      "product_model": "Palermo Armchair-Slate Leather",
      "product_mpn": "PLM001-LEATH-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300927,
      "product_name": "Palermo 2 Seater Sofa-Slate Leather",
      "product_model": "Palermo 2 Seater Sofa-Slate Leather",
      "product_mpn": "PLM002-LEATH-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300926,
      "product_name": "Palermo 3 Seater Sofa-Slate Leather",
      "product_model": "Palermo 3 Seater Sofa-Slate Leather",
      "product_mpn": "PLM003-LEATH-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300933,
      "product_name": "Linden Storage Footstool-Off White Leather",
      "product_model": "Linden Storage Footstool-Off White Leather",
      "product_mpn": "LDN010-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300932,
      "product_name": "Linden Armchair-Off White Leather",
      "product_model": "Linden Armchair-Off White Leather",
      "product_mpn": "LDN001-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300931,
      "product_name": "Linden 2 Seater Sofa-Off White Leather",
      "product_model": "Linden 2 Seater Sofa-Off White Leather",
      "product_mpn": "LDN002-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300930,
      "product_name": "Linden 3 Seater Sofa-Off White Leather",
      "product_model": "Linden 3 Seater Sofa-Off White Leather",
      "product_mpn": "LDN003-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300937,
      "product_name": "Linden Storage Footstool-Light Grey Leather",
      "product_model": "Linden Storage Footstool-Light Grey Leather",
      "product_mpn": "LDN010-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300936,
      "product_name": "Linden Armchair-Light Grey Leather",
      "product_model": "Linden Armchair-Light Grey Leather",
      "product_mpn": "LDN001-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300935,
      "product_name": "LInden 2 Seater Sofa-Light Grey Leather",
      "product_model": "LInden 2 Seater Sofa-Light Grey Leather",
      "product_mpn": "LDN002-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300934,
      "product_name": "Linden 3 Seater Sofa-Light Grey Leather",
      "product_model": "Linden 3 Seater Sofa-Light Grey Leather",
      "product_mpn": "LDN003-M5653-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300941,
      "product_name": "Linden Storage Footstool-Slate Leather",
      "product_model": "Linden Storage Footstool-Slate Leather",
      "product_mpn": "LDN010-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300940,
      "product_name": "Linden Armchair-Slate Leather",
      "product_model": "Linden Armchair-Slate Leather",
      "product_mpn": "LDN001-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300939,
      "product_name": "Linden 2 Seater Sofa-Slate Leather",
      "product_model": "Linden 2 Seater Sofa-Slate Leather",
      "product_mpn": "LDN002-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300938,
      "product_name": "Linden 3 Seater Sofa-Slate Leather",
      "product_model": "Linden 3 Seater Sofa-Slate Leather",
      "product_mpn": "LDN003-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300945,
      "product_name": "Linden Storage Footstool-Black Leather",
      "product_model": "Linden Storage Footstool-Black Leather",
      "product_mpn": "LDN010-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300944,
      "product_name": "Linden Armchair-Black Leather",
      "product_model": "Linden Armchair-Black Leather",
      "product_mpn": "LDN001-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300943,
      "product_name": "Linden 2 Seater Sofa-Black Leather",
      "product_model": "Linden 2 Seater Sofa-Black Leather",
      "product_mpn": "LDN002-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300942,
      "product_name": "Linden 3 Seater Sofa-Black Leather",
      "product_model": "Linden 3 Seater Sofa-Black Leather",
      "product_mpn": "LDN003-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300949,
      "product_name": "Linden Storage Footstool-Petrol Leather",
      "product_model": "Linden Storage Footstool-Petrol Leather",
      "product_mpn": "LDN010-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300948,
      "product_name": "Linden Armchair-Petrol Leather",
      "product_model": "Linden Armchair-Petrol Leather",
      "product_mpn": "LDN001-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300947,
      "product_name": "Linden 2 Seater Sofa-Petrol Leather",
      "product_model": "Linden 2 Seater Sofa-Petrol Leather",
      "product_mpn": "LDN002-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300946,
      "product_name": "Linden 3 Seater Sofa-Petrol Leather",
      "product_model": "Linden 3 Seater Sofa-Petrol Leather",
      "product_mpn": "LDN003-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300953,
      "product_name": "Linden Storage Footstool-Tan Leather",
      "product_model": "Linden Storage Footstool-Tan Leather",
      "product_mpn": "LDN010-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300952,
      "product_name": "Linden Armchair-Tan Leather",
      "product_model": "Linden Armchair-Tan Leather",
      "product_mpn": "LDN001-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300951,
      "product_name": "Linden 2 Seater Sofa-Tan Leather",
      "product_model": "Linden 2 Seater Sofa-Tan Leather",
      "product_mpn": "LDN002-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300950,
      "product_name": "Linden 3 Seater Sofa-Tan Leather",
      "product_model": "Linden 3 Seater Sofa-Tan Leather",
      "product_mpn": "LDN003-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300957,
      "product_name": "Linden Storage Footstool-Two Tone Brown Leather",
      "product_model": "Linden Storage Footstool-Two Tone Brown Leather",
      "product_mpn": "LDN010-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300956,
      "product_name": "Linden Armchair-Two Tone Brown Leather",
      "product_model": "Linden Armchair-Two Tone Brown Leather",
      "product_mpn": "LDN001-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300955,
      "product_name": "Linden 2 Seater Sofa-Two Tone Brown Leather",
      "product_model": "Linden 2 Seater Sofa-Two Tone Brown Leather",
      "product_mpn": "LDN002-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300954,
      "product_name": "Linden 3 Seater Sofa-Two Tone Brown Leather",
      "product_model": "Linden 3 Seater Sofa-Two Tone Brown Leather",
      "product_mpn": "LDN003-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300961,
      "product_name": "Muse Storage Footstool-Off White Leather",
      "product_model": "Muse Storage Footstool-Off White Leather",
      "product_mpn": "MSEFOOT-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300960,
      "product_name": "Muse Electric Recliner Armchair-Off White Leather",
      "product_model": "Muse Electric Recliner Armchair-Off White Leather",
      "product_mpn": "MSE201-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300959,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Off White Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Off White Leather",
      "product_mpn": "MSE202-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300958,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Off White Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Off White Leather",
      "product_mpn": "MSE203-LEATH-M5651-OFFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300965,
      "product_name": "Muse Storage Footstool-Dark Grey Leather",
      "product_model": "Muse Storage Footstool-Dark Grey Leather",
      "product_mpn": "MSEFOOT-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300964,
      "product_name": "Muse Electric Recliner Armchair-Dark Grey Leather",
      "product_model": "Muse Electric Recliner Armchair-Dark Grey Leather",
      "product_mpn": "MSE201-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300963,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Dark Grey Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Dark Grey Leather",
      "product_mpn": "MSE202-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300962,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Dark Grey Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Dark Grey Leather",
      "product_mpn": "MSE203-LEATH-M5655-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300969,
      "product_name": "Muse Storage Footstool-Slate Leather",
      "product_model": "Muse Storage Footstool-Slate Leather",
      "product_mpn": "MSEFOOT-LEATH-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300968,
      "product_name": "Muse Electric Recliner Armchair-Slate Leather",
      "product_model": "Muse Electric Recliner Armchair-Slate Leather",
      "product_mpn": "MSE201-LEATH-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300967,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Slate Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Slate Leather",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300966,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Slate Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Slate Leather",
      "product_mpn": "MSE203-LEATH-M5663-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300973,
      "product_name": "Muse Storage Footstool-Black Leather",
      "product_model": "Muse Storage Footstool-Black Leather",
      "product_mpn": "MSEFOOT-LEATH-M5657-BLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300972,
      "product_name": "Muse Electric Recliner Armchair-Black Leather",
      "product_model": "Muse Electric Recliner Armchair-Black Leather",
      "product_mpn": "MSE201-LEATH-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300971,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Black Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Black Leather",
      "product_mpn": "MSE202-LEATH-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300970,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Black Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Black Leather",
      "product_mpn": "MSE203-LEATH-M5657-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300977,
      "product_name": "Muse Storage Footstool-Petrol Leather",
      "product_model": "Muse Storage Footstool-Petrol Leather",
      "product_mpn": "MSEFOOT-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300976,
      "product_name": "Muse Electric Recliner Armchair-Petrol Leather",
      "product_model": "Muse Electric Recliner Armchair-Petrol Leather",
      "product_mpn": "MSE201-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300975,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Petrol Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Petrol Leather",
      "product_mpn": "MSE202-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300974,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Petrol Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Petrol Leather",
      "product_mpn": "MSE203-LEATH-M5661-PET",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300981,
      "product_name": "Muse Storage Footstool-Tan Leather",
      "product_model": "Muse Storage Footstool-Tan Leather",
      "product_mpn": "MSEFOOT-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300980,
      "product_name": "Muse Electric Recliner Armchair-Tan Leather",
      "product_model": "Muse Electric Recliner Armchair-Tan Leather",
      "product_mpn": "MSE201-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300979,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Tan Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Tan Leather",
      "product_mpn": "MSE202-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300978,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Tan Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Tan Leather",
      "product_mpn": "MSE203-LEATH-M1595-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300985,
      "product_name": "Muse Storage Footstool-Two Tone Brown Leather",
      "product_model": "Muse Storage Footstool-Two Tone Brown Leather",
      "product_mpn": "MSEFOOT-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300984,
      "product_name": "Muse Electric Recliner Armchair-Two Tone Brown Leather",
      "product_model": "Muse Electric Recliner Armchair-Two Tone Brown Leather",
      "product_mpn": "MSE201-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300983,
      "product_name": "Muse 2 Seater Electric Recliner Sofa-Two Tone Brown Leather",
      "product_model": "Muse 2 Seater Electric Recliner Sofa-Two Tone Brown Leather",
      "product_mpn": "MSE202-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300982,
      "product_name": "Muse 3 Seater Electric Recliner Sofa-Two Tone Brown Leather",
      "product_model": "Muse 3 Seater Electric Recliner Sofa-Two Tone Brown Leather",
      "product_mpn": "MSE203-LEATH-M1209-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300989,
      "product_name": "Montgomery Footstool-Teal Velvet",
      "product_model": "Montgomery Footstool-Teal Velvet",
      "product_mpn": "MTGFOOT-RSA-TEA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300988,
      "product_name": "Montgomery Armchair-Teal Velvet",
      "product_model": "Montgomery Armchair-Teal Velvet",
      "product_mpn": "MTG001-RSA-TEA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300987,
      "product_name": "Montgomery 2 Seater Sofa-Teal Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Teal Velvet",
      "product_mpn": "MTG002-RSA-TEA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300986,
      "product_name": "Montgomery 3 Seater Sofa-Teal Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Teal Velvet",
      "product_mpn": "MTG003-RSA-TEA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300993,
      "product_name": "Montgomery Footstool-Indigo Velvet",
      "product_model": "Montgomery Footstool-Indigo Velvet",
      "product_mpn": "MTGFOOT-RSA-IDO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300992,
      "product_name": "Montgomery Armchair-Indigo Velvet",
      "product_model": "Montgomery Armchair-Indigo Velvet",
      "product_mpn": "MTG001-RSA-IDO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300991,
      "product_name": "Montgomery 2 Seater Sofa-Indigo Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Indigo Velvet",
      "product_mpn": "MTG002-RSA-IDO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300990,
      "product_name": "Montgomery 3 Seater Sofa-Indigo Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Indigo Velvet",
      "product_mpn": "MTG003-RSA-IDO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300997,
      "product_name": "Montgomery Footstool-Charcoal Velvet",
      "product_model": "Montgomery Footstool-Charcoal Velvet",
      "product_mpn": "MTGFOOT-RSA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300996,
      "product_name": "Montgomery Armchair-Charcoal Velvet",
      "product_model": "Montgomery Armchair-Charcoal Velvet",
      "product_mpn": "MTG001-RSA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300995,
      "product_name": "Montgomery 2 Seater Sofa-Charcoal Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Charcoal Velvet",
      "product_mpn": "MTG002-RSA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300994,
      "product_name": "Montgomery 3 Seater Sofa-Charcoal Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Charcoal Velvet",
      "product_mpn": "MTG003-RSA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301001,
      "product_name": "Montgomery Footstool -Mustard Velvet",
      "product_model": "Montgomery Footstool -Mustard Velvet",
      "product_mpn": "MTGFOOT-RSA-MUS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301000,
      "product_name": "Montgomery Armchair-Mustard Velvet",
      "product_model": "Montgomery Armchair-Mustard Velvet",
      "product_mpn": "MTG001-RSA-MUS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300999,
      "product_name": "Montgomery 2 Seater Sofa-Mustard Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Mustard Velvet",
      "product_mpn": "MTG002-RSA-MUS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300998,
      "product_name": "Montgomery 3 Seater Sofa-Mustard Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Mustard Velvet",
      "product_mpn": "MTG003-RSA-MUS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301005,
      "product_name": "Montgomery Footstool-Burgundy Velvet",
      "product_model": "Montgomery Footstool-Burgundy Velvet",
      "product_mpn": "MTGFOOT-RSA-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301004,
      "product_name": "Montgomery Armchair-Burgundy Velvet",
      "product_model": "Montgomery Armchair-Burgundy Velvet",
      "product_mpn": "MTG001-RSA-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301003,
      "product_name": "Montgomery 2 Seater Sofa-Burgundy Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Burgundy Velvet",
      "product_mpn": "MTG002-RSA-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301002,
      "product_name": "Montgomery 3 Seater Sofa-Burgundy Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Burgundy Velvet",
      "product_mpn": "MTG003-RSA-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301009,
      "product_name": "Montgomery Footstool-Green Velvet",
      "product_model": "Montgomery Footstool-Green Velvet",
      "product_mpn": "MTGFOOT-RSA-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301008,
      "product_name": "Montgomery Armchair-Green Velvet",
      "product_model": "Montgomery Armchair-Green Velvet",
      "product_mpn": "MTG001-RSA-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301007,
      "product_name": "Montgomery 2 Seater Sofa-Green Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Green Velvet",
      "product_mpn": "MTG002-RSA-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301006,
      "product_name": "Montgomery 3 Seater Sofa-Green Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Green Velvet",
      "product_mpn": "MTG003-RSA-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301013,
      "product_name": "Montgomery Footstool-Light Grey Velvet",
      "product_model": "Montgomery Footstool-Light Grey Velvet",
      "product_mpn": "MTGFOOT-RSA-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301012,
      "product_name": "Montgomery Armchair-Light Grey Velvet",
      "product_model": "Montgomery Armchair-Light Grey Velvet",
      "product_mpn": "MTG001-RSA-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301011,
      "product_name": "Montgomery 2 Seater Sofa-Light Grey Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Light Grey Velvet",
      "product_mpn": "MTG002-RSA-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301010,
      "product_name": "Montgomery 3 Seater Sofa-Light Grey Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Light Grey Velvet",
      "product_mpn": "MTG003-RSA-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301017,
      "product_name": "Montgomery Footstool-Beige Velvet",
      "product_model": "Montgomery Footstool-Beige Velvet",
      "product_mpn": "MTGFOOT-RSA-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301016,
      "product_name": "Montgomery Armchair-Beige Velvet",
      "product_model": "Montgomery Armchair-Beige Velvet",
      "product_mpn": "MTG001-RSA-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301015,
      "product_name": "Montgomery 2 Seater Sofa-Beige Velvet",
      "product_model": "Montgomery 2 Seater Sofa-Beige Velvet",
      "product_mpn": "MTG002-RSA-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301014,
      "product_name": "Montgomery 3 Seater Sofa-Beige Velvet",
      "product_model": "Montgomery 3 Seater Sofa-Beige Velvet",
      "product_mpn": "MTG003-RSA-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301021,
      "product_name": "Bramble Footstool-Pellier Smoke Fabric",
      "product_model": "Bramble Footstool-Pellier Smoke Fabric",
      "product_mpn": "BBLFOOT-PLR-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301020,
      "product_name": "Bramble Armchair-Pellier Smoke Fabric",
      "product_model": "Bramble Armchair-Pellier Smoke Fabric",
      "product_mpn": "BBL001-PLR-SMO-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301019,
      "product_name": "Bramble 2 Seater Sofa-Pellier Smoke Fabric",
      "product_model": "Bramble 2 Seater Sofa-Pellier Smoke Fabric",
      "product_mpn": "BBL002-PLR-SMO-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301018,
      "product_name": "Bramble 3 Seater Sofa-Pellier Smoke Fabric",
      "product_model": "Bramble 3 Seater Sofa-Pellier Smoke Fabric",
      "product_mpn": "BBL003-PLR-SMO-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301025,
      "product_name": "Bramble Footstool-Pellier Charcoal Fabric",
      "product_model": "Bramble Footstool-Pellier Charcoal Fabric",
      "product_mpn": "BBLFOOT-PLR-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301024,
      "product_name": "Bramble Armchair-Pellier Charcoal Fabric",
      "product_model": "Bramble Armchair-Pellier Charcoal Fabric",
      "product_mpn": "BBL001-PLR-CHA-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301023,
      "product_name": "Bramble 2 Seater Sofa-Pellier Charcoal Fabric",
      "product_model": "Bramble 2 Seater Sofa-Pellier Charcoal Fabric",
      "product_mpn": "BBL002-PLR-CHA-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301022,
      "product_name": "Bramble 3 Seater Sofa-Pellier Charcoal Fabric",
      "product_model": "Bramble 3 Seater Sofa-Pellier Charcoal Fabric",
      "product_mpn": "BBL003-PLR-CHA-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301029,
      "product_name": "Bramble Footstool-Pellier Beige Fabric",
      "product_model": "Bramble Footstool-Pellier Beige Fabric",
      "product_mpn": "BBLFOOT-PLR-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301028,
      "product_name": "Bramble Armchair-Pellier Beige Fabric",
      "product_model": "Bramble Armchair-Pellier Beige Fabric",
      "product_mpn": "BBL001-PLR-BEI-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301027,
      "product_name": "Bramble 2 Seater Sofa-Pellier Beige Fabric",
      "product_model": "Bramble 2 Seater Sofa-Pellier Beige Fabric",
      "product_mpn": "BBL002-PLR-BEI-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301026,
      "product_name": "Bramble 3 Seater Sofa-Pellier Beige Fabric",
      "product_model": "Bramble 3 Seater Sofa-Pellier Beige Fabric",
      "product_mpn": "BBL003-PLR-BEI-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301033,
      "product_name": "Bramble Footstool-Pellier Taupe Fabric",
      "product_model": "Bramble Footstool-Pellier Taupe Fabric",
      "product_mpn": "BBLFOOT-PLR-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301032,
      "product_name": "Bramble Armchair-Pellier Taupe Fabric",
      "product_model": "Bramble Armchair-Pellier Taupe Fabric",
      "product_mpn": "BBL001-PLR-TAU-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301031,
      "product_name": "Bramble 2 Seater Sofa-Pellier Taupe Fabric",
      "product_model": "Bramble 2 Seater Sofa-Pellier Taupe Fabric",
      "product_mpn": "BBL002-PLR-TAU-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301030,
      "product_name": "Bramble 3 Seater Sofa-Pellier Taupe Fabric",
      "product_model": "Bramble 3 Seater Sofa-Pellier Taupe Fabric",
      "product_mpn": "BBL003-PLR-TAU-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301037,
      "product_name": "Bramble Footstool-Melrose Carbon ",
      "product_model": "Bramble Footstool-Melrose Carbon ",
      "product_mpn": "BBLFOOT-MLS-CBN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301036,
      "product_name": "Bramble Armchair-Melrose Carbon with Thyme",
      "product_model": "Bramble Armchair-Melrose Carbon with Thyme",
      "product_mpn": "BBL001-MLS-CBN-THM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301035,
      "product_name": "Bramble 2 Seater Sofa-Melrose Carbon with Thyme",
      "product_model": "Bramble 2 Seater Sofa-Melrose Carbon with Thyme",
      "product_mpn": "BBL002-MLS-CBN-THM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301034,
      "product_name": "Bramble 3 Seater Sofa-Melrose Carbon with Thyme",
      "product_model": "Bramble 3 Seater Sofa-Melrose Carbon with Thyme",
      "product_mpn": "BBL003-MLS-CBN-THM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301040,
      "product_name": "Bramble Armchair-Melrose Carbon with Smoke",
      "product_model": "Bramble Armchair-Melrose Carbon with Smoke",
      "product_mpn": "BBL001-MLS-CBN-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301039,
      "product_name": "Bramble 2 Seater Sofa-Melrose Carbon with Smoke",
      "product_model": "Bramble 2 Seater Sofa-Melrose Carbon with Smoke",
      "product_mpn": "BBL002-MLS-CBN-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301038,
      "product_name": "Bramble 3 Seater Sofa-Melrose Carbon with Smoke",
      "product_model": "Bramble 3 Seater Sofa-Melrose Carbon with Smoke",
      "product_mpn": "BBL003-MLS-CBN-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301043,
      "product_name": "Bramble Armchair-Melrose Carbon with Charcoal",
      "product_model": "Bramble Armchair-Melrose Carbon with Charcoal",
      "product_mpn": "BBL001-MLS-CBN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301042,
      "product_name": "Bramble 2 Seater Sofa-Melrose Carbon with Charcoal",
      "product_model": "Bramble 2 Seater Sofa-Melrose Carbon with Charcoal",
      "product_mpn": "BBL002-MLS-CBN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301041,
      "product_name": "Bramble 3 Seater Sofa-Melrose Carbon with Charcoal",
      "product_model": "Bramble 3 Seater Sofa-Melrose Carbon with Charcoal",
      "product_mpn": "BBL003-MLS-CBN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301047,
      "product_name": "Bramble Footstool-Melrose Mink ",
      "product_model": "Bramble Footstool-Melrose Mink ",
      "product_mpn": "BBLFOOT-MLS-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301046,
      "product_name": "Bramble Armchair-Melrose Mink with Beige",
      "product_model": "Bramble Armchair-Melrose Mink with Beige",
      "product_mpn": "BBL001-MLS-MIN-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301045,
      "product_name": "Bramble 2 Seater Sofa-Melrose Mink with Beige",
      "product_model": "Bramble 2 Seater Sofa-Melrose Mink with Beige",
      "product_mpn": "BBL002-MLS-MIN-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301044,
      "product_name": "Bramble 3 Seater Sofa-Melrose Mink with Beige",
      "product_model": "Bramble 3 Seater Sofa-Melrose Mink with Beige",
      "product_mpn": "BBL003-MLS-MIN-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301050,
      "product_name": "Bramble Armchair-Melrose Mink with Taupe",
      "product_model": "Bramble Armchair-Melrose Mink with Taupe",
      "product_mpn": "BBL001-MLS-MIN-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301049,
      "product_name": "Bramble 2 Seater Sofa-Melrose Mink with Taupe",
      "product_model": "Bramble 2 Seater Sofa-Melrose Mink with Taupe",
      "product_mpn": "BBL002-MLS-MIN-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301048,
      "product_name": "Bramble 3 Seater Sofa-Melrose Mink with Taupe",
      "product_model": "Bramble 3 Seater Sofa-Melrose Mink with Taupe",
      "product_mpn": "BBL003-MLS-MIN-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 500500029,
      "product_name": "G703 lightspeed gaming mouse Hero 25k sensor Black",
      "product_model": "G703 lightspeed gaming mouse Hero 25k sensor Black",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900029,
      "product_name": "G613 wireless mechanical gaming keyboard CH",
      "product_model": "G613 wireless mechanical gaming keyboard CH",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500028,
      "product_name": "G403 Hero gaming mouse",
      "product_model": "G403 Hero gaming mouse",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500027,
      "product_name": "G305 light speed gaming mouse White",
      "product_model": "G305 light speed gaming mouse White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500025,
      "product_name": "G305 light speed gaming mouse Lilac",
      "product_model": "G305 light speed gaming mouse Lilac",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900028,
      "product_name": "G305 light speed gaming mouse blue",
      "product_model": "G305 light speed gaming mouse blue",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500024,
      "product_name": "G305 light speed gaming mouse black",
      "product_model": "G305 light speed gaming mouse black",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500023,
      "product_name": "g203 lightsync gaming mouse Lilac",
      "product_model": "g203 lightsync gaming mouse Lilac",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500022,
      "product_name": "g203 lightsync gaming mouse Blue",
      "product_model": "g203 lightsync gaming mouse Blue",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 7100009,
      "product_name": "Baby Bottle SL Black Pro blue",
      "product_model": "Baby Bottle SL Black Pro blue",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 300760,
      "product_name": "Dylan Storage Footstool",
      "product_model": "Dylan Storage Footstool",
      "product_mpn": "DLN032-OXD-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300759,
      "product_name": "Dylan Electric Recliner Armchair-Oxford Silver Fabric",
      "product_model": "Dylan Electric Recliner Armchair-Oxford Silver Fabric",
      "product_mpn": "DLN201-OXD-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300758,
      "product_name": "Dylan 2 Seater Electric Recliner Sofa-Oxford Silver Fabric",
      "product_model": "Dylan 2 Seater Electric Recliner Sofa-Oxford Silver Fabric",
      "product_mpn": "DLN202-OXD-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300757,
      "product_name": "Dylan 3 Seater Electric Recliner Sofa-Oxford Silver Fabric",
      "product_model": "Dylan 3 Seater Electric Recliner Sofa-Oxford Silver Fabric",
      "product_mpn": "DLN203-OXD-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300764,
      "product_name": "Dylan Storage Footstool-Oxford Beige Fabric",
      "product_model": "Dylan Storage Footstool-Oxford Beige Fabric",
      "product_mpn": "DLN032-OXD-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300763,
      "product_name": "Dylan Electric Recliner Armchair",
      "product_model": "Dylan Electric Recliner Armchair",
      "product_mpn": "DLN201-OXD-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300762,
      "product_name": "Dylan 2 Seater Electric Recliner Sofa",
      "product_model": "Dylan 2 Seater Electric Recliner Sofa",
      "product_mpn": "DLN202-OXD-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300761,
      "product_name": "Dylan 3 Seater Electric Recliner Sofa",
      "product_model": "Dylan 3 Seater Electric Recliner Sofa",
      "product_mpn": "DLN203-OXD-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300788,
      "product_name": "Houston Footstool-Charcoal Velvet",
      "product_model": "Houston Footstool-Charcoal Velvet",
      "product_mpn": "HSNFOOT-VEL-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300787,
      "product_name": "Houston Armchair-Charcoal Velvet",
      "product_model": "Houston Armchair-Charcoal Velvet",
      "product_mpn": "HSN001-VEL-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300786,
      "product_name": "Houston Loveseat-Charcoal Velvet",
      "product_model": "Houston Loveseat-Charcoal Velvet",
      "product_mpn": "HSN057-VEL-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300785,
      "product_name": "Houston 4 Seater Sofa-Charcoal Velvet",
      "product_model": "Houston 4 Seater Sofa-Charcoal Velvet",
      "product_mpn": "HSN004-VEL-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300784,
      "product_name": "Houston 2 Seater Sofa-Charcoal Velvet",
      "product_model": "Houston 2 Seater Sofa-Charcoal Velvet",
      "product_mpn": "HSN002-VEL-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300783,
      "product_name": "Houston 3 Seater Sofa-Charcoal Velvet",
      "product_model": "Houston 3 Seater Sofa-Charcoal Velvet",
      "product_mpn": "HSN003-VEL-CHA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300776,
      "product_name": "Houston Footstool-Ivory Velvet",
      "product_model": "Houston Footstool-Ivory Velvet",
      "product_mpn": "HSNFOOT-VEL-IVO-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300775,
      "product_name": "Houston Armchair-Ivory Velvet",
      "product_model": "Houston Armchair-Ivory Velvet",
      "product_mpn": "HSN001-VEL-IVO-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300774,
      "product_name": "Houston Loveseat-Ivory Velvet",
      "product_model": "Houston Loveseat-Ivory Velvet",
      "product_mpn": "HSN057-VEL-IVO-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300773,
      "product_name": "Houston 4 Seater Sofa-Ivory Velvet",
      "product_model": "Houston 4 Seater Sofa-Ivory Velvet",
      "product_mpn": "HSN004-VEL-IVO-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300772,
      "product_name": "Houston 2 Seater Sofa-Ivory Velvet",
      "product_model": "Houston 2 Seater Sofa-Ivory Velvet",
      "product_mpn": "HSN002-VEL-IVO-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300771,
      "product_name": "Houston 3 Seater Sofa-Ivory Velvet",
      "product_model": "Houston 3 Seater Sofa-Ivory Velvet",
      "product_mpn": "HSN003-VEL-IVO-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300782,
      "product_name": "Houston Footstool-Turquoise Velvet",
      "product_model": "Houston Footstool-Turquoise Velvet",
      "product_mpn": "HSNFOOT-VEL-TUR-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300781,
      "product_name": "Houston Armchair-Turquoise Velvet",
      "product_model": "Houston Armchair-Turquoise Velvet",
      "product_mpn": "HSN001-VEL-TUR-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300780,
      "product_name": "Houston Loveseat-Turquoise Velvet",
      "product_model": "Houston Loveseat-Turquoise Velvet",
      "product_mpn": "HSN057-VEL-TUR-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300779,
      "product_name": "Houston 4 Seater Sofa-Turquoise Velvet",
      "product_model": "Houston 4 Seater Sofa-Turquoise Velvet",
      "product_mpn": "HSN004-VEL-TUR-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300778,
      "product_name": "Houston 2 Seater Sofa-Turquoise Velvet",
      "product_model": "Houston 2 Seater Sofa-Turquoise Velvet",
      "product_mpn": "HSN002-VEL-TUR-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300777,
      "product_name": "Houston 3 Seater Sofa-Turquoise Velvet",
      "product_model": "Houston 3 Seater Sofa-Turquoise Velvet",
      "product_mpn": "HSN003-VEL-TUR-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300769,
      "product_name": "Houston Armchair-Grey Velvet",
      "product_model": "Houston Armchair-Grey Velvet",
      "product_mpn": "HSN001-VEL-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300770,
      "product_name": "Houston Footstool-Grey Velvet",
      "product_model": "Houston Footstool-Grey Velvet",
      "product_mpn": "HSNFOOT-VEL-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300768,
      "product_name": "Houston Loveseat-Grey Velvet",
      "product_model": "Houston Loveseat-Grey Velvet",
      "product_mpn": "HSN057-VEL-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300767,
      "product_name": "Houston 4 Seater Sofa-Grey Velvet",
      "product_model": "Houston 4 Seater Sofa-Grey Velvet",
      "product_mpn": "HSN004-VEL-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300766,
      "product_name": "Houston 2 Seater Sofa-Grey Velvet",
      "product_model": "Houston 2 Seater Sofa-Grey Velvet",
      "product_mpn": "HSN002-VEL-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300765,
      "product_name": "Houston 3 Seater Sofa-Grey Velvet",
      "product_model": "Houston 3 Seater Sofa-Grey Velvet",
      "product_mpn": "HSN003-VEL-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300794,
      "product_name": "Houston Footstool-Putty Velvet",
      "product_model": "Houston Footstool-Putty Velvet",
      "product_mpn": "HSNFOOT-VEL-PUT-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300793,
      "product_name": "Houston Armchair-Putty Velvet",
      "product_model": "Houston Armchair-Putty Velvet",
      "product_mpn": "HSN001-VEL-PUT-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300792,
      "product_name": "Houston Loveseat-Putty Velvet",
      "product_model": "Houston Loveseat-Putty Velvet",
      "product_mpn": "HSN057-VEL-PUT-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300791,
      "product_name": "Houston 4 Seater Sofa-Putty Velvet",
      "product_model": "Houston 4 Seater Sofa-Putty Velvet",
      "product_mpn": "HSN004-VEL-PUT-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300790,
      "product_name": "Houston 2 Seater Sofa-Putty Velvet",
      "product_model": "Houston 2 Seater Sofa-Putty Velvet",
      "product_mpn": "HSN002-VEL-PUT-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300789,
      "product_name": "Houston 3 Seater Sofa-Putty Velvet",
      "product_model": "Houston 3 Seater Sofa-Putty Velvet",
      "product_mpn": "HSN003-VEL-PUT-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300798,
      "product_name": "Arizona Storage Footstool-Grey Fabric",
      "product_model": "Arizona Storage Footstool-Grey Fabric",
      "product_mpn": "ARZ010-PLAIN-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300797,
      "product_name": "Arizona Armchair-Grey Fabric",
      "product_model": "Arizona Armchair-Grey Fabric",
      "product_mpn": "ARZ001-PLAIN-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300796,
      "product_name": "Arizona 2 Seater Sofa-Grey Fabric",
      "product_model": "Arizona 2 Seater Sofa-Grey Fabric",
      "product_mpn": "ARZ002-PLAIN-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300795,
      "product_name": "Arizona 3 Seater Sofa-Grey Fabric",
      "product_model": "Arizona 3 Seater Sofa-Grey Fabric",
      "product_mpn": "ARZ003-PLAIN-GRY-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300802,
      "product_name": "Arizona Storage Footstool-Oatmeal Fabric",
      "product_model": "Arizona Storage Footstool-Oatmeal Fabric",
      "product_mpn": "ARZ010-PLAIN-OAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300801,
      "product_name": "Arizona Armchair-Oatmeal Fabric",
      "product_model": "Arizona Armchair-Oatmeal Fabric",
      "product_mpn": "ARZ001-PLAIN-OAT-MSH",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300800,
      "product_name": "Arizona 2 Seater Sofa-Oatmeal Fabric",
      "product_model": "Arizona 2 Seater Sofa-Oatmeal Fabric",
      "product_mpn": "ARZ002-PLAIN-OAT-MSH",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300799,
      "product_name": "Arizona 3 Seater Sofa-Oatmeal Fabric",
      "product_model": "Arizona 3 Seater Sofa-Oatmeal Fabric",
      "product_mpn": "ARZ003-PLAIN-OAT-MSH",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300806,
      "product_name": "Arizona Storage Footstool-Marine Fabric",
      "product_model": "Arizona Storage Footstool-Marine Fabric",
      "product_mpn": "ARZ010-PLAIN-MRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300805,
      "product_name": "Arizona Armchair-Marine Fabric",
      "product_model": "Arizona Armchair-Marine Fabric",
      "product_mpn": "ARZ001-PLAIN-MRN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300804,
      "product_name": "Arizona 2 Seater Sofa-Marine Fabric",
      "product_model": "Arizona 2 Seater Sofa-Marine Fabric",
      "product_mpn": "ARZ002-PLAIN-MRN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300803,
      "product_name": "Arizona 3 Seater Sofa-Marine Fabric",
      "product_model": "Arizona 3 Seater Sofa-Marine Fabric",
      "product_mpn": "ARZ003-PLAIN-MRN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300816,
      "product_name": "Brighton Accent Chair-Parchment Fabric",
      "product_model": "Brighton Accent Chair-Parchment Fabric",
      "product_mpn": "BGN009-PLAIN-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300817,
      "product_name": "Brighton Footstool-Parchment Fabric",
      "product_model": "Brighton Footstool-Parchment Fabric",
      "product_mpn": "BGNFOOT-PLAIN-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300815,
      "product_name": "Brighton Love Seat-Parchment Fabric",
      "product_model": "Brighton Love Seat-Parchment Fabric",
      "product_mpn": "BGN020-PLAIN-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300814,
      "product_name": "Brighton Right Hand Chaise Sofa-Parchment Fabric",
      "product_model": "Brighton Right Hand Chaise Sofa-Parchment Fabric",
      "product_mpn": "BGN059R-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300813,
      "product_name": "Brighton Armchair-Parchment Fabric",
      "product_model": "Brighton Armchair-Parchment Fabric",
      "product_mpn": "BGN001-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300812,
      "product_name": "Brighton 4 Seater Sofa-Parchment Fabric",
      "product_model": "Brighton 4 Seater Sofa-Parchment Fabric",
      "product_mpn": "BGN004-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300811,
      "product_name": "Brighton Corner Sofa Right Hand-Parchment Fabric",
      "product_model": "Brighton Corner Sofa Right Hand-Parchment Fabric",
      "product_mpn": "BGN021R-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300810,
      "product_name": "Brighton Corner Sofa Left Hand-Parchment Fabric",
      "product_model": "Brighton Corner Sofa Left Hand-Parchment Fabric",
      "product_mpn": "BGN021L-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300809,
      "product_name": "Brighton Left Hand Chaise Sofa-Parchment Fabric",
      "product_model": "Brighton Left Hand Chaise Sofa-Parchment Fabric",
      "product_mpn": "BGN059L-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300808,
      "product_name": "Brighton 2 Seater Sofa-Parchment Fabric",
      "product_model": "Brighton 2 Seater Sofa-Parchment Fabric",
      "product_mpn": "BGN002-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300807,
      "product_name": "Brighton 3 Seater Sofa-Parchment Fabric",
      "product_model": "Brighton 3 Seater Sofa-Parchment Fabric",
      "product_mpn": "BGN003-PLAIN-PAR-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300828,
      "product_name": "Brighton Footstool-Pebble Fabric",
      "product_model": "Brighton Footstool-Pebble Fabric",
      "product_mpn": "BGNFOOT-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300827,
      "product_name": "Brighton Accent Chair-Pebble Fabric",
      "product_model": "Brighton Accent Chair-Pebble Fabric",
      "product_mpn": "BGN009-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300826,
      "product_name": "Brighton Love Seat-Pebble Fabric",
      "product_model": "Brighton Love Seat-Pebble Fabric",
      "product_mpn": "BGN020-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300825,
      "product_name": "Brighton Right Hand Chaise Sofa-Pebble Fabric",
      "product_model": "Brighton Right Hand Chaise Sofa-Pebble Fabric",
      "product_mpn": "BGN059R-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300824,
      "product_name": "Brighton Armchair-Pebble Fabric",
      "product_model": "Brighton Armchair-Pebble Fabric",
      "product_mpn": "BGN001-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300823,
      "product_name": "Brighton 4 Seater Sofa-Pebble Fabric",
      "product_model": "Brighton 4 Seater Sofa-Pebble Fabric",
      "product_mpn": "BGN004-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300822,
      "product_name": "Brighton Corner Sofa Right Hand-Pebble Fabric",
      "product_model": "Brighton Corner Sofa Right Hand-Pebble Fabric",
      "product_mpn": "BGN021R-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300821,
      "product_name": "Brighton Corner Sofa Left Hand-Pebble Fabric",
      "product_model": "Brighton Corner Sofa Left Hand-Pebble Fabric",
      "product_mpn": "BGN021L-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300820,
      "product_name": "Brighton Left Hand Chaise Sofa-Pebble Fabric",
      "product_model": "Brighton Left Hand Chaise Sofa-Pebble Fabric",
      "product_mpn": "BGN059L-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300819,
      "product_name": "Brighton 2 Seater Sofa-Pebble Fabric",
      "product_model": "Brighton 2 Seater Sofa-Pebble Fabric",
      "product_mpn": "BGN002-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300818,
      "product_name": "Brighton 3 Seater Sofa-Pebble Fabric",
      "product_model": "Brighton 3 Seater Sofa-Pebble Fabric",
      "product_mpn": "BGN003-PLAIN-PEB-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300839,
      "product_name": "Brighton Footstool-Sea Spray Fabric",
      "product_model": "Brighton Footstool-Sea Spray Fabric",
      "product_mpn": "BGNFOOT-PLAIN-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300838,
      "product_name": "Brighton Accent Chair-Sea Spray Fabric",
      "product_model": "Brighton Accent Chair-Sea Spray Fabric",
      "product_mpn": "BGN009-PLAIN-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300837,
      "product_name": "Brighton Love Seat-Sea Spray Fabric",
      "product_model": "Brighton Love Seat-Sea Spray Fabric",
      "product_mpn": "BGN020-PLAIN-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300836,
      "product_name": "Brighton Right Hand Chaise Sofa-Sea Spray Fabric",
      "product_model": "Brighton Right Hand Chaise Sofa-Sea Spray Fabric",
      "product_mpn": "BGN059R-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300835,
      "product_name": "Brighton Armchair-Sea Spray Fabric",
      "product_model": "Brighton Armchair-Sea Spray Fabric",
      "product_mpn": "BGN001-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300834,
      "product_name": "Brighton 4 Seater Sofa-Sea Spray Fabric",
      "product_model": "Brighton 4 Seater Sofa-Sea Spray Fabric",
      "product_mpn": "BGN004-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300833,
      "product_name": "Brighton Corner Sofa Right Hand-Sea Spray Fabric",
      "product_model": "Brighton Corner Sofa Right Hand-Sea Spray Fabric",
      "product_mpn": "BGN021R-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300832,
      "product_name": "Brighton Corner Sofa Left Hand-Sea Spray Fabric",
      "product_model": "Brighton Corner Sofa Left Hand-Sea Spray Fabric",
      "product_mpn": "BGN021L-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300831,
      "product_name": "Brighton Left Hand Chaise Sofa-Sea Spray Fabric",
      "product_model": "Brighton Left Hand Chaise Sofa-Sea Spray Fabric",
      "product_mpn": "BGN059L-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300830,
      "product_name": "Brighton 2 Seater Sofa-Sea Spray Fabric",
      "product_model": "Brighton 2 Seater Sofa-Sea Spray Fabric",
      "product_mpn": "BGN002-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300829,
      "product_name": "Brighton 3 Seater Sofa-Sea Spray Fabric",
      "product_model": "Brighton 3 Seater Sofa-Sea Spray Fabric",
      "product_mpn": "BGN003-PLAIN-SSP-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300850,
      "product_name": "Brighton Footstool-Saffron Fabric",
      "product_model": "Brighton Footstool-Saffron Fabric",
      "product_mpn": "BGNFOOT-PLAIN-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300849,
      "product_name": "Brighton Accent Chair-Saffron Fabric",
      "product_model": "Brighton Accent Chair-Saffron Fabric",
      "product_mpn": "BGN009-PLAIN-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300848,
      "product_name": "Brighton Love Seat-Saffron Fabric",
      "product_model": "Brighton Love Seat-Saffron Fabric",
      "product_mpn": "BGN020-PLAIN-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300847,
      "product_name": "Brighton Right Hand Chaise Sofa-Saffron Fabric",
      "product_model": "Brighton Right Hand Chaise Sofa-Saffron Fabric",
      "product_mpn": "BGN059R-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300846,
      "product_name": "Brighton Armchair-Saffron Fabric",
      "product_model": "Brighton Armchair-Saffron Fabric",
      "product_mpn": "BGN001-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300845,
      "product_name": "Brighton 4 Seater Sofa-Saffron Fabric",
      "product_model": "Brighton 4 Seater Sofa-Saffron Fabric",
      "product_mpn": "BGN004-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300844,
      "product_name": "Brighton Corner Sofa Right Hand-Saffron Fabric",
      "product_model": "Brighton Corner Sofa Right Hand-Saffron Fabric",
      "product_mpn": "BGN021R-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300843,
      "product_name": "Brighton Corner Sofa Left Hand-Saffron Fabric",
      "product_model": "Brighton Corner Sofa Left Hand-Saffron Fabric",
      "product_mpn": "BGN021L-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300842,
      "product_name": "Brighton Left Hand Chaise Sofa-Saffron Fabric",
      "product_model": "Brighton Left Hand Chaise Sofa-Saffron Fabric",
      "product_mpn": "BGN059L-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300841,
      "product_name": "Brighton 2 Seater Sofa-Saffron Fabric",
      "product_model": "Brighton 2 Seater Sofa-Saffron Fabric",
      "product_mpn": "BGN002-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300840,
      "product_name": "Brighton 3 Seater Sofa-Saffron Fabric",
      "product_model": "Brighton 3 Seater Sofa-Saffron Fabric",
      "product_mpn": "BGN003-PLAIN-SAF-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300858,
      "product_name": "Brighton Footstool-Saffron Pattern",
      "product_model": "Brighton Footstool-Saffron Pattern",
      "product_mpn": "BGN006-PATT-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300857,
      "product_name": "Brighton Footstool-Sea Spray Pattern",
      "product_model": "Brighton Footstool-Sea Spray Pattern",
      "product_mpn": "BGN006-PATT-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300856,
      "product_name": "Brighton Footstool-Parchment Pattern",
      "product_model": "Brighton Footstool-Parchment Pattern",
      "product_mpn": "BGN006-PATT-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300855,
      "product_name": "Brighton Footstool-Charcoal Pattern",
      "product_model": "Brighton Footstool-Charcoal Pattern",
      "product_mpn": "BGN006-PATT-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300854,
      "product_name": "Brighton Accent Chair-Saffron Pattern",
      "product_model": "Brighton Accent Chair-Saffron Pattern",
      "product_mpn": "BGN009-PATT-SAF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300853,
      "product_name": "Brighton Accent Chair-Sea Spray Pattern",
      "product_model": "Brighton Accent Chair-Sea Spray Pattern",
      "product_mpn": "BGN009-PATT-SSP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300852,
      "product_name": "Brighton Accent Chair-Parchment Pattern",
      "product_model": "Brighton Accent Chair-Parchment Pattern",
      "product_mpn": "BGN009-PATT-PAR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300851,
      "product_name": "Brighton Accent Chair-Charcoal Pattern",
      "product_model": "Brighton Accent Chair-Charcoal Pattern",
      "product_mpn": "BGN009-PATT-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300864,
      "product_name": "Orbit Storage Footstool-Light Grey Fabric",
      "product_model": "Orbit Storage Footstool-Light Grey Fabric",
      "product_mpn": "ORB010-PLAIN-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300863,
      "product_name": "Orbit Footstool-Light Grey Fabric",
      "product_model": "Orbit Footstool-Light Grey Fabric",
      "product_mpn": "ORBFOOT-PLAIN-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300862,
      "product_name": "Orbit Loveseat-Light Grey Fabric",
      "product_model": "Orbit Loveseat-Light Grey Fabric",
      "product_mpn": "ORB057-PLAIN-LGRY-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300861,
      "product_name": "Orbit 4 Seater Sofa-Light Grey Fabric",
      "product_model": "Orbit 4 Seater Sofa-Light Grey Fabric",
      "product_mpn": "ORB004-PLAIN-LGRY-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300860,
      "product_name": "Orbit 2 Seater Sofa-Light Grey Fabric",
      "product_model": "Orbit 2 Seater Sofa-Light Grey Fabric",
      "product_mpn": "ORB002-PLAIN-LGRY-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300859,
      "product_name": "Orbit 3 Seater Sofa-Light Grey Fabric",
      "product_model": "Orbit 3 Seater Sofa-Light Grey Fabric",
      "product_mpn": "ORB003-PLAIN-LGRY-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300870,
      "product_name": "Orbit Storage Footstool-Dark Grey Fabric",
      "product_model": "Orbit Storage Footstool-Dark Grey Fabric",
      "product_mpn": "ORB010-PLAIN-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300869,
      "product_name": "Orbit Footstool-Dark Grey Fabric",
      "product_model": "Orbit Footstool-Dark Grey Fabric",
      "product_mpn": "ORBFOOT-PLAIN-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300868,
      "product_name": "Orbit Loveseat-Dark Grey Fabric",
      "product_model": "Orbit Loveseat-Dark Grey Fabric",
      "product_mpn": "ORB057-PLAIN-DGRY-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300867,
      "product_name": "Orbit 4 Seater Sofa-Dark Grey Fabric",
      "product_model": "Orbit 4 Seater Sofa-Dark Grey Fabric",
      "product_mpn": "ORB004-PLAIN-DGRY-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300866,
      "product_name": "Orbit 2 Seater Sofa-Dark Grey Fabric",
      "product_model": "Orbit 2 Seater Sofa-Dark Grey Fabric",
      "product_mpn": "ORB002-PLAIN-DGRY-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300865,
      "product_name": "Orbit 3 Seater Sofa-Dark Grey Fabric",
      "product_model": "Orbit 3 Seater Sofa-Dark Grey Fabric",
      "product_mpn": "ORB003-PLAIN-DGRY-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300876,
      "product_name": "Orbit Storage Footstool-Oatmeal Fabric",
      "product_model": "Orbit Storage Footstool-Oatmeal Fabric",
      "product_mpn": "ORB010-PLAIN-OAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300875,
      "product_name": "Orbit Footstool-Oatmeal Fabric",
      "product_model": "Orbit Footstool-Oatmeal Fabric",
      "product_mpn": "ORBFOOT-PLAIN-OAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300874,
      "product_name": "Orbit Loveseat-Oatmeal Fabric",
      "product_model": "Orbit Loveseat-Oatmeal Fabric",
      "product_mpn": "ORB057-PLAIN-OAT-OAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300873,
      "product_name": "Orbit 4 Seater Sofa-Oatmeal Fabric",
      "product_model": "Orbit 4 Seater Sofa-Oatmeal Fabric",
      "product_mpn": "ORB004-PLAIN-OAT-OAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300872,
      "product_name": "Orbit 2 Seater Sofa-Oatmeal Fabric",
      "product_model": "Orbit 2 Seater Sofa-Oatmeal Fabric",
      "product_mpn": "ORB002-PLAIN-OAT-OAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300871,
      "product_name": "Orbit 3 Seater Sofa-Oatmeal Fabric",
      "product_model": "Orbit 3 Seater Sofa-Oatmeal Fabric",
      "product_mpn": "ORB003-PLAIN-OAT-OAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300880,
      "product_name": "Orbit Accent Chair-Peacock Velvet",
      "product_model": "Orbit Accent Chair-Peacock Velvet",
      "product_mpn": "ORB009-VEL-PEA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300879,
      "product_name": "Orbit Accent Chair-Dark Grey Velvet",
      "product_model": "Orbit Accent Chair-Dark Grey Velvet",
      "product_mpn": "ORB009-VEL-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300878,
      "product_name": "Orbit Accent Chair-Light Grey Velvet",
      "product_model": "Orbit Accent Chair-Light Grey Velvet",
      "product_mpn": "ORB009-VEL-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 300877,
      "product_name": "Orbit Accent Chair-Gold Velvet",
      "product_model": "Orbit Accent Chair-Gold Velvet",
      "product_mpn": "ORB009-VEL-GOL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 2200100013,
      "product_name": "Galaxy watch4 classic black 42mm",
      "product_model": "Galaxy watch4 classic black 42mm",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100012,
      "product_name": "Galaxy watch4 classic silver 42mm",
      "product_model": "Galaxy watch4 classic silver 42mm",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100011,
      "product_name": "Galaxy watch classic Silver 46mm",
      "product_model": "Galaxy watch classic Silver 46mm",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100010,
      "product_name": "Galaxy watch classic black 46mm",
      "product_model": "Galaxy watch classic black 46mm",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 700200027,
      "product_name": "Cozinha em MDF Nesher Princesa com 6 Portas 4 Gavetas e 12 Prateleiras - Freijo/Off White",
      "product_model": "Cozinha em MDF Nesher Princesa com 6 Portas 4 Gavetas e 12 Prateleiras - Freijo/Off White",
      "product_mpn": "",
      "product_brand": "Nesher",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 301059,
      "product_name": "Conjunto Sofá 2 e 3 Lugares Hellen Genebra em Tecido Suede - Marrom",
      "product_model": "Conjunto Sofá 2 e 3 Lugares Hellen Genebra em Tecido Suede - Marrom",
      "product_mpn": "",
      "product_brand": "Hellen",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700500003,
      "product_name": "Mesa de Jantar com 4 Cadeiras Brigatto New Montreal",
      "product_model": "Mesa de Jantar com 4 Cadeiras Brigatto New Montreal",
      "product_mpn": "",
      "product_brand": "Brigatto",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 700300026,
      "product_name": "Cozinha Bartira Ametista com 8 Portas e 3 Gavetas",
      "product_model": "Cozinha Bartira Ametista com 8 Portas e 3 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300025,
      "product_name": "Cozinha Itatiaia Diamante com 13 Portas e 4 Gavetas",
      "product_model": "Cozinha Itatiaia Diamante com 13 Portas e 4 Gavetas",
      "product_mpn": "",
      "product_brand": "Itatiaia",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 1000200054,
      "product_name": "Cama Box Queen Size Umaflex Berlim com Molas Ensacadas",
      "product_model": "Cama Box Queen Size Umaflex Berlim com Molas Ensacadas",
      "product_mpn": "",
      "product_brand": "Umaflex",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 301058,
      "product_name": "Sofá 3 e 2 Lugares Linoforte Mercury em Tecido Suede",
      "product_model": "Sofá 3 e 2 Lugares Linoforte Mercury em Tecido Suede",
      "product_mpn": "",
      "product_brand": "Linoforte",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301057,
      "product_name": "Sofá de Canto Umaflex Encanto II em Tecido Veludo",
      "product_model": "Sofá de Canto Umaflex Encanto II em Tecido Veludo",
      "product_mpn": "",
      "product_brand": "Umaflex",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 1000200053,
      "product_name": "Cama Box de Casal Portobel + Colchão Portobel Diplomata Light com Pillow Top e Molas Ensacadas",
      "product_model": "Cama Box de Casal Portobel + Colchão Portobel Diplomata Light com Pillow Top e Molas Ensacadas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700500002,
      "product_name": "Mesa de Jantar com 4 Cadeiras Viero Carmem",
      "product_model": "Mesa de Jantar com 4 Cadeiras Viero Carmem",
      "product_mpn": "",
      "product_brand": "Viero Móveis",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 301056,
      "product_name": "Sofá 3 e 2 Lugares Luizzi Caribe II em Tecido Suede",
      "product_model": "Sofá 3 e 2 Lugares Luizzi Caribe II em Tecido Suede",
      "product_mpn": "",
      "product_brand": "Luizzi",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 15500002,
      "product_name": "Berço Pura Magia Gutti Liv com Suporte para Mosquiteiro e Rodízios - Branco",
      "product_model": "Berço Pura Magia Gutti Liv com Suporte para Mosquiteiro e Rodízios - Branco",
      "product_mpn": "",
      "product_brand": "Pura Magia",
      "product_category": "BABY",
      "product_subcategory": "BABY PRODUCTS"
    },
    {
      "product_arid": 1000200052,
      "product_name": "Cama Box Queen Size + Colchão Herval Capri Pillow Inn e Molas Ensacadas 64x158x198cm",
      "product_model": "Cama Box Queen Size + Colchão Herval Capri Pillow Inn e Molas Ensacadas 64x158x198cm",
      "product_mpn": "",
      "product_brand": "Herval",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700300024,
      "product_name": "Cozinha Completa Bartira Turmalina com 9 Portas, 3 Gavetas 1 Balcão",
      "product_model": "Cozinha Completa Bartira Turmalina com 9 Portas, 3 Gavetas 1 Balcão",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 15500001,
      "product_name": "Guarda-Roupa Infantil Pura Magia Gutti Flex com 2 Portas, 1 Gaveta e 1 Prateleira - Branco/Azul/Rosa",
      "product_model": "Guarda-Roupa Infantil Pura Magia Gutti Flex com 2 Portas, 1 Gaveta e 1 Prateleira - Branco/Azul/Rosa",
      "product_mpn": "",
      "product_brand": "Pura Magia",
      "product_category": "BABY",
      "product_subcategory": "BABY PRODUCTS"
    },
    {
      "product_arid": 15500000,
      "product_name": "Berço Pura Magia Gutti com Cômoda e Suporte para Mosquiteiro - Branco",
      "product_model": "Berço Pura Magia Gutti com Cômoda e Suporte para Mosquiteiro - Branco",
      "product_mpn": "",
      "product_brand": "Pura Magia",
      "product_category": "BABY",
      "product_subcategory": "BABY PRODUCTS"
    },
    {
      "product_arid": 700200026,
      "product_name": "Painel Home EDN Torino II para TV’s de até 50\"",
      "product_model": "Painel Home EDN Torino II para TV’s de até 50\"",
      "product_mpn": "",
      "product_brand": "Edn Móveis",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2200018,
      "product_name": "Guarda-Roupa Bartira Ventura com 3 Portas e 4 Gavetas",
      "product_model": "Guarda-Roupa Bartira Ventura com 3 Portas e 4 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 301055,
      "product_name": "Sofá 3 Lugares Luizzi Monet Retrátil e Reclinável em Veludo 191 cm de largura",
      "product_model": "Sofá 3 Lugares Luizzi Monet Retrátil e Reclinável em Veludo 191 cm de largura",
      "product_mpn": "",
      "product_brand": "Luizzi",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301054,
      "product_name": "Sofá 3 Lugares Phormatta Decor Berlin II com Assento Retrátil e Encosto Reclinável em Tecido Veludo",
      "product_model": "Sofá 3 Lugares Phormatta Decor Berlin II com Assento Retrátil e Encosto Reclinável em Tecido Veludo",
      "product_mpn": "",
      "product_brand": "Phormatta Decor",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301053,
      "product_name": "Sofá 4 Lugares Linoforte Bonno com Assento Retrátil e Encosto Reclinável em Tecido Suede",
      "product_model": "Sofá 4 Lugares Linoforte Bonno com Assento Retrátil e Encosto Reclinável em Tecido Suede",
      "product_mpn": "",
      "product_brand": "Linoforte",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 2200017,
      "product_name": "Guarda-Roupa Bartira Monte Rei com 6 Portas e 6 Gavetas",
      "product_model": "Guarda-Roupa Bartira Monte Rei com 6 Portas e 6 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200025,
      "product_name": "Painel Home DJ Móveis Voxi 140 para TV's de até 50''",
      "product_model": "Painel Home DJ Móveis Voxi 140 para TV's de até 50''",
      "product_mpn": "",
      "product_brand": "DJ Móveis",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200024,
      "product_name": "Bancada Suspensa Madetec Lana para TV de até 60''",
      "product_model": "Bancada Suspensa Madetec Lana para TV de até 60''",
      "product_mpn": "",
      "product_brand": "Madetec",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200023,
      "product_name": "Estante Home Theater Madetec Alan para TV de até 55''",
      "product_model": "Estante Home Theater Madetec Alan para TV de até 55''",
      "product_mpn": "",
      "product_brand": "Madetec",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2200016,
      "product_name": "Guarda-Roupa Bartira Napoli II com 6 Portas e 2 Gavetas",
      "product_model": "Guarda-Roupa Bartira Napoli II com 6 Portas e 2 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 301052,
      "product_name": "Sofá 4 Lugares Linoforte Versalhes com Assento Retrátil e Encosto Reclinável em Tecido Suede",
      "product_model": "Sofá 4 Lugares Linoforte Versalhes com Assento Retrátil e Encosto Reclinável em Tecido Suede",
      "product_mpn": "",
      "product_brand": "Linoforte",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700300023,
      "product_name": "Rack Madetec Lisboa para TV até 50”",
      "product_model": "Rack Madetec Lisboa para TV até 50”",
      "product_mpn": "",
      "product_brand": "Madetec",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2200015,
      "product_name": "Guarda-Roupa Bartira Texas com 5 Portas e 4 Gavetas",
      "product_model": "Guarda-Roupa Bartira Texas com 5 Portas e 4 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200014,
      "product_name": "Guarda-Roupa Bartira Havana II com 7 Portas e 4 Gavetas",
      "product_model": "Guarda-Roupa Bartira Havana II com 7 Portas e 4 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 800100004,
      "product_name": "Norbury 6 seater dining table grey",
      "product_model": "Norbury 6 seater dining table grey",
      "product_mpn": "",
      "product_brand": "Homebase",
      "product_category": "LIVINGROOM",
      "product_subcategory": "DINING TABLES"
    },
    {
      "product_arid": 700300022,
      "product_name": "Norbury Oak small side board Grey",
      "product_model": "Norbury Oak small side board Grey",
      "product_mpn": "12940853",
      "product_brand": "Homebase",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 301051,
      "product_name": "Freya folding sofa bed ",
      "product_model": "Freya folding sofa bed ",
      "product_mpn": "12838330",
      "product_brand": "Homebase",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700200022,
      "product_name": "Clever Cube 2x4 white",
      "product_model": "Clever Cube 2x4 white",
      "product_mpn": "12813309",
      "product_brand": "Homebase",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2000100002,
      "product_name": "Plastic Keter outdoor storage shed GREY GRAPHITE ",
      "product_model": "Plastic Keter outdoor storage shed GREY GRAPHITE ",
      "product_mpn": "12811193",
      "product_brand": "Homebase",
      "product_category": "GARDEN OUTDOOR",
      "product_subcategory": "GARDEN BUILDINGS"
    },
    {
      "product_arid": 700500001,
      "product_name": "The Occassion Chair - Grey",
      "product_model": "The Occassion Chair - Grey",
      "product_mpn": "12825367",
      "product_brand": "Homebase",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 8400013,
      "product_name": "Z623 THX Black - 2 1 System - Mojo - Hexa",
      "product_model": "Z623 THX Black - 2 1 System - Mojo - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 8400012,
      "product_name": "Z607 Black - 5 1 System - Merida - Hexa",
      "product_model": "Z607 Black - 5 1 System - Merida - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 8400011,
      "product_name": "Z407 Graphite - 2.1 System - Metallica - Hexa",
      "product_model": "Z407 Graphite - 2.1 System - Metallica - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 7100012,
      "product_name": "YETI X WOW Grey - CE Blue Microphone - Yeti X - Hexa",
      "product_model": "YETI X WOW Grey - CE Blue Microphone - Yeti X - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 6800035,
      "product_name": "WONDERBOOM 2 Just Peach - Mobile Speakers - Kings",
      "product_model": "WONDERBOOM 2 Just Peach - Mobile Speakers - Kings",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 500500038,
      "product_name": "PRO X SUPERLIGHT White - Gaming Mice - Bazooka",
      "product_model": "PRO X SUPERLIGHT White - Gaming Mice - Bazooka",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500037,
      "product_name": "PRO X SUPERLIGHT Black - Gaming Mice - Bazooka ",
      "product_model": "PRO X SUPERLIGHT Black - Gaming Mice - Bazooka ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500036,
      "product_name": "PEBBLE M350 Eucalyptus - Cordless Mice - Turbot - Hexa ",
      "product_model": "PEBBLE M350 Eucalyptus - Cordless Mice - Turbot - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900071,
      "product_name": "MX KEYS MAC Space Grey - Cordless Keyboards - Wkb Foster Mac -",
      "product_model": "MX KEYS MAC Space Grey - Cordless Keyboards - Wkb Foster Mac -",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500035,
      "product_name": "MX ANYWHERE 3 Rose - Cordless Mice - Zaha - Hexa ",
      "product_model": "MX ANYWHERE 3 Rose - Cordless Mice - Zaha - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500034,
      "product_name": "MX ANYWHERE 3 Pale Grey - Cordless Mice - Zaha ",
      "product_model": "MX ANYWHERE 3 Pale Grey - Cordless Mice - Zaha ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900070,
      "product_name": "MX ANYWHERE 3 Graphite - Cordless Mice - Zaha ",
      "product_model": "MX ANYWHERE 3 Graphite - Cordless Mice - Zaha ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900069,
      "product_name": "MK295 Off White - Cordless Combos - Wcb Pattaya Silent",
      "product_model": "MK295 Off White - Cordless Combos - Wcb Pattaya Silent",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900068,
      "product_name": "MK295 Graphite - Cordless Combos - Wcb Pattaya Silent",
      "product_model": "MK295 Graphite - Cordless Combos - Wcb Pattaya Silent",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900067,
      "product_name": "MK270 Black - Cordless Combos - Wcb Pattaya - Hexa ",
      "product_model": "MK270 Black - Cordless Combos - Wcb Pattaya - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500033,
      "product_name": "M590 SILENT Mid Grey Tonal - Cordless Mice - Beluga Silent ",
      "product_model": "M590 SILENT Mid Grey Tonal - Cordless Mice - Beluga Silent ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500032,
      "product_name": "M590 SILENT Graphite Tonal - Cordless Mice - Beluga Silent - Hexa ",
      "product_model": "M590 SILENT Graphite Tonal - Cordless Mice - Beluga Silent - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500031,
      "product_name": "M575 Graphite - Trackball - Heka - Hexa",
      "product_model": "M575 Graphite - Trackball - Heka - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900066,
      "product_name": "M220 SILENT Charcoal - Cordless Mice - Palau Silent - Hexa ",
      "product_model": "M220 SILENT Charcoal - Cordless Mice - Palau Silent - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900065,
      "product_name": "Keys-To-Go Classic Blue - Tablet Keyboards - Dwell Smart - Hexa",
      "product_model": "Keys-To-Go Classic Blue - Tablet Keyboards - Dwell Smart - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900064,
      "product_name": "Keys-To-Go Blush Pink - Tablet Keyboards - Dwell Smart ",
      "product_model": "Keys-To-Go Blush Pink - Tablet Keyboards - Dwell Smart ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900063,
      "product_name": "Keys-To-Go Black - Tablet Keyboards - Dwell Smart - Hexa",
      "product_model": "Keys-To-Go Black - Tablet Keyboards - Dwell Smart - Hexa",
      "product_mpn": "Combo touch",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "APPLE ACCESSORIES"
    },
    {
      "product_arid": 6900062,
      "product_name": "K580 Graphite - Cordless Keyboards - Wkb Samui - Hexa",
      "product_model": "K580 Graphite - Cordless Keyboards - Wkb Samui - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900060,
      "product_name": "G915 TKL Carbon - Gaming Keyboard - Harpy Tkl - Hex",
      "product_model": "G915 TKL Carbon - Gaming Keyboard - Harpy Tkl - Hex",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900059,
      "product_name": "G915 Carbon - Gaming Keyboard - Harpy - Hexa",
      "product_model": "G915 Carbon - Gaming Keyboard - Harpy - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900058,
      "product_name": "G815 Carbon - Gaming Keyboard - Harpy Corded - Hexa",
      "product_model": "G815 Carbon - Gaming Keyboard - Harpy Corded - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 8400014,
      "product_name": "Z906 THX Black - 5 1 System - Blueline - Hexa ",
      "product_model": "Z906 THX Black - 5 1 System - Blueline - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 6900072,
      "product_name": "M575 Offwhite - Trackball - Heka - Hexa",
      "product_model": "M575 Offwhite - Trackball - Heka - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 8000192,
      "product_name": "HP Spectre x360",
      "product_model": "HP Spectre x360",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000203,
      "product_name": "Lenovo Yoga 7i",
      "product_model": "Lenovo Yoga 7i",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000202,
      "product_name": "Lenovo Yoga Slim 7i",
      "product_model": "Lenovo Yoga Slim 7i",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000201,
      "product_name": "Razer Book 13",
      "product_model": "Razer Book 13",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000199,
      "product_name": "MSI Prestige 14",
      "product_model": "MSI Prestige 14",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000198,
      "product_name": "Asus Zenbook 13 UX363",
      "product_model": "Asus Zenbook 13 UX363",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000197,
      "product_name": "Acer Swift 3",
      "product_model": "Acer Swift 3",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000196,
      "product_name": "LG Gram 1690P",
      "product_model": "LG Gram 1690P",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000195,
      "product_name": "Samsung Galaxy Book Pro",
      "product_model": "Samsung Galaxy Book Pro",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000194,
      "product_name": "Dell XPS ",
      "product_model": "Dell XPS ",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 500500039,
      "product_name": "MX MASTER 3 MAC Space Grey - Cordless Mice - Herzog Mac - Hexa",
      "product_model": "MX MASTER 3 MAC Space Grey - Cordless Mice - Herzog Mac - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 12400012,
      "product_name": "PRO X Wireless Headset LIGHTSPEED Black - Gaming Headsets - Quake",
      "product_model": "PRO X Wireless Headset LIGHTSPEED Black - Gaming Headsets - Quake",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 8000193,
      "product_name": "Acer Swift 5",
      "product_model": "Acer Swift 5",
      "product_mpn": "",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 13500097,
      "product_name": "KGN49XIEA",
      "product_model": "KGN49XIEA",
      "product_mpn": "KGN49XIEA",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 75000121107,
      "product_name": "HRG8769C7",
      "product_model": "HRG8769C7",
      "product_mpn": "HRG8769C7",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 75000121106,
      "product_name": "HMG8764C7",
      "product_model": "HMG8764C7",
      "product_mpn": "HMG8764C7",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 75000121105,
      "product_name": "PXX895D66E",
      "product_model": "PXX895D66E",
      "product_mpn": "PXX895D66E",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOBS"
    },
    {
      "product_arid": 75000121104,
      "product_name": "HBG4795B0S",
      "product_model": "HBG4795B0S",
      "product_mpn": "HBG4795B0S",
      "product_brand": "Bosch",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 1011641062,
      "product_name": "43\" The Serif QLED 4K ",
      "product_model": "43\" The Serif QLED 4K ",
      "product_mpn": "QE43LS01TBUXXU",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 8000204,
      "product_name": "Intel Evo 11th Gen Avatar (UK Eng Master)",
      "product_model": "Intel Evo 11th Gen Avatar (UK Eng Master)",
      "product_mpn": "Intel Evo 11th Gen Avatar (UK Eng Master)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 1200500005,
      "product_name": "GJ17 Enchantimals Castle",
      "product_model": "GJ17 Enchantimals Castle",
      "product_mpn": "",
      "product_brand": "Mattel",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 1200500006,
      "product_name": "HBD77_LP_Skyway",
      "product_model": "HBD77_LP_Skyway",
      "product_mpn": "",
      "product_brand": "Mattel",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 1200500004,
      "product_name": "GRP99 Sensimal Mobile",
      "product_model": "GRP99 Sensimal Mobile",
      "product_mpn": "",
      "product_brand": "Mattel",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 1200500003,
      "product_name": "Hot Wheels® City Ultimate Garage",
      "product_model": "Hot Wheels® City Ultimate Garage",
      "product_mpn": "",
      "product_brand": "Mattel",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 1200500002,
      "product_name": "Barbie® 3-in-1 DreamCamper",
      "product_model": "Barbie® 3-in-1 DreamCamper",
      "product_mpn": "",
      "product_brand": "Mattel",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 6900078,
      "product_name": "PEBBLE M350 Blue Grey - Cordless Mice - Turbot ",
      "product_model": "PEBBLE M350 Blue Grey - Cordless Mice - Turbot ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 12400021,
      "product_name": "G910 Black - Gaming Keyboard - Raptor - Hexa",
      "product_model": "G910 Black - Gaming Keyboard - Raptor - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 12400020,
      "product_name": "G733 White - Gaming Headsets - Tico - Hexa ",
      "product_model": "G733 White - Gaming Headsets - Tico - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6900077,
      "product_name": "G733 Lilac - Gaming Headsets - Tico - Hexa",
      "product_model": "G733 Lilac - Gaming Headsets - Tico - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400019,
      "product_name": "G733 Blue - Gaming Headsets - Tico - Hexa",
      "product_model": "G733 Blue - Gaming Headsets - Tico - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400018,
      "product_name": "G733 Black - Gaming Headsets - Tico - Hexa",
      "product_model": "G733 Black - Gaming Headsets - Tico - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400017,
      "product_name": "G435 White - Gaming Headsets - Alice - Hexa ",
      "product_model": "G435 White - Gaming Headsets - Alice - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400016,
      "product_name": "G435 Blue - Gaming Headsets - Alice - Hexa ",
      "product_model": "G435 Blue - Gaming Headsets - Alice - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400015,
      "product_name": "G435 Black - Gaming Headsets - Alice - Hexa",
      "product_model": "G435 Black - Gaming Headsets - Alice - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400014,
      "product_name": "G335 WIRED White - Gaming Headsets - Lara Corded - Hexa",
      "product_model": "G335 WIRED White - Gaming Headsets - Lara Corded - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400013,
      "product_name": "G335 WIRED Mint - Gaming Headsets - Lara Corded - Hexa ",
      "product_model": "G335 WIRED Mint - Gaming Headsets - Lara Corded - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6900076,
      "product_name": "G335 WIRED Black - Gaming Headsets - Lara Corded - Hexa ",
      "product_model": "G335 WIRED Black - Gaming Headsets - Lara Corded - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6900075,
      "product_name": "COMBO TOUCH IPAD PRO 12 9 Grey - Tablet Keyboards - Snapdragon - Hexa",
      "product_model": "COMBO TOUCH IPAD PRO 12 9 Grey - Tablet Keyboards - Snapdragon - Hexa",
      "product_mpn": "Combo touch",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "APPLE ACCESSORIES"
    },
    {
      "product_arid": 6900074,
      "product_name": "COMBO TOUCH IPAD PRO 11 Grey - Tablet Keyboards - Sunflower - Hexa ",
      "product_model": "COMBO TOUCH IPAD PRO 11 Grey - Tablet Keyboards - Sunflower - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "APPLE ACCESSORIES"
    },
    {
      "product_arid": 6900073,
      "product_name": "BLUEBIRD SL Blue - Pro Blue Microphone - Bluebird Pro ",
      "product_model": "BLUEBIRD SL Blue - Pro Blue Microphone - Bluebird Pro ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 8000205,
      "product_name": "Dell Inspiron 13 (5310)",
      "product_model": "Dell Inspiron 13 (5310)",
      "product_mpn": "Inspiron 13 (5310)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 12600096,
      "product_name": "Cordless KION",
      "product_model": "Cordless KION",
      "product_mpn": "Cordless KION",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600094,
      "product_name": "WV362RGUKT - Wandvac System Rose Gold (2 batt)",
      "product_model": "WV362RGUKT - Wandvac System Rose Gold (2 batt)",
      "product_mpn": "WV362RGUKT ",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600093,
      "product_name": "WV362BLUKT - Wandvac System Blue (2 batt)",
      "product_model": "WV362BLUKT - Wandvac System Blue (2 batt)",
      "product_mpn": "WV362BLUKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600091,
      "product_name": "WV270UK - Shark Handheld ",
      "product_model": "WV270UK - Shark Handheld ",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600092,
      "product_name": "CZ500UKT - Shark Cylinder AHW & DuoClean",
      "product_model": "CZ500UKT - Shark Cylinder AHW & DuoClean",
      "product_mpn": "CZ500UKT ",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 301200000,
      "product_name": "HD110UK - Shark Hairdryer",
      "product_model": "HD110UK - Shark Hairdryer",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "HAIR DRYERS"
    },
    {
      "product_arid": 8900017,
      "product_name": "ST202UK - Ninja Silver 3-in-1 Toaster Argos",
      "product_model": "ST202UK - Ninja Silver 3-in-1 Toaster Argos",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "TOASTERS"
    },
    {
      "product_arid": 9000011,
      "product_name": "KT201UK - Ninja Kettle - Argos Silver",
      "product_model": "KT201UK - Ninja Kettle - Argos Silver",
      "product_mpn": "KT201UK - Ninja Kettle - Argos Silver",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 9000012,
      "product_name": "KT200UK Ninja Kettle - Black",
      "product_model": "KT200UK Ninja Kettle - Black",
      "product_mpn": "KT200UK Ninja Kettle - Black",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "KETTLES"
    },
    {
      "product_arid": 91001310006,
      "product_name": "DT200UK Ninja 10-in-1 Multi Oven",
      "product_model": "DT200UK Ninja 10-in-1 Multi Oven",
      "product_mpn": "DT200UK",
      "product_brand": "Ninja",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 9500018,
      "product_name": "AG651UK Ninja Max Health Grill",
      "product_model": "AG651UK Ninja Max Health Grill",
      "product_mpn": "AG651UK Ninja Max Health Grill",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "FRYERS"
    },
    {
      "product_arid": 300200017,
      "product_name": "BN500UK Ninja Blender",
      "product_model": "BN500UK Ninja Blender",
      "product_mpn": "BN500UK ",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 7900013,
      "product_name": "OL750UK Ninja SmartLid Multi Cooker",
      "product_model": "OL750UK Ninja SmartLid Multi Cooker",
      "product_mpn": "OL750UK",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 7900012,
      "product_name": "OL650UK Ninja SmartLid Multi Cooker",
      "product_model": "OL650UK Ninja SmartLid Multi Cooker",
      "product_mpn": "OL650UK ",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 7900011,
      "product_name": "OL550UK Ninja SmartLid Multi Cooker",
      "product_model": "OL550UK Ninja SmartLid Multi Cooker",
      "product_mpn": "",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 301500000,
      "product_name": "NC300UK Ice Cream Maker",
      "product_model": "NC300UK Ice Cream Maker",
      "product_mpn": "NC300UK ",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "ICE CREAM MAKERS"
    },
    {
      "product_arid": 7900010,
      "product_name": "AF400UK",
      "product_model": "AF400UK",
      "product_mpn": "AF400UK",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MULTI- COOKERS"
    },
    {
      "product_arid": 1011641079,
      "product_name": "AU8005",
      "product_model": "AU8005",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641078,
      "product_name": "AU7175",
      "product_model": "AU7175",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641077,
      "product_name": "The Terrace",
      "product_model": "The Terrace",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641075,
      "product_name": "The Serif White",
      "product_model": "The Serif White",
      "product_mpn": "SKU: QE43LS01TAUXXC",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641074,
      "product_name": "The Serif Blue",
      "product_model": "The Serif Blue",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300001,
      "product_name": "The Frame",
      "product_model": "The Frame",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641073,
      "product_name": "Q60A",
      "product_model": "Q60A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641072,
      "product_name": "Q68A",
      "product_model": "Q68A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641071,
      "product_name": "Q77A",
      "product_model": "Q77A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641070,
      "product_name": "Q80A",
      "product_model": "Q80A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641069,
      "product_name": "QN85A",
      "product_model": "QN85A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641068,
      "product_name": "QN90A",
      "product_model": "QN90A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641160,
      "product_name": "AU9075 75\" variant of 1011641080",
      "product_model": "AU9075 75\" variant of 1011641080",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641159,
      "product_name": "AU9075 55\" variant of 1011641080",
      "product_model": "AU9075 55\" variant of 1011641080",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641158,
      "product_name": "AU9075 50\" variant of 1011641080",
      "product_model": "AU9075 50\" variant of 1011641080",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641157,
      "product_name": "AU9075 43\" variant of 1011641080",
      "product_model": "AU9075 43\" variant of 1011641080",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641156,
      "product_name": "AU8005 85\" variant of 1011641079",
      "product_model": "AU8005 85\" variant of 1011641079",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641155,
      "product_name": "AU7175 85\" variant of 1011641078",
      "product_model": "AU7175 85\" variant of 1011641078",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641154,
      "product_name": "AU7175 75\" variant of 1011641078",
      "product_model": "AU7175 75\" variant of 1011641078",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641153,
      "product_name": "AU7175 58\" variant of 1011641078",
      "product_model": "AU7175 58\" variant of 1011641078",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641152,
      "product_name": "AU7175 55\" variant of 1011641078",
      "product_model": "AU7175 55\" variant of 1011641078",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641151,
      "product_name": "AU7175 50\" variant of 1011641078",
      "product_model": "AU7175 50\" variant of 1011641078",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641150,
      "product_name": "AU7175 43\" variant of 1011641078",
      "product_model": "AU7175 43\" variant of 1011641078",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641149,
      "product_name": "The Terrace 75\" variant of 1011641077",
      "product_model": "The Terrace 75\" variant of 1011641077",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641148,
      "product_name": "The Terrace 55\" variant of 1011641077",
      "product_model": "The Terrace 55\" variant of 1011641077",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641147,
      "product_name": "The Serif White 55\" variant of 1011641075",
      "product_model": "The Serif White 55\" variant of 1011641075",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641146,
      "product_name": "The Serif White 50\" variant of 1011641075",
      "product_model": "The Serif White 50\" variant of 1011641075",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641145,
      "product_name": "The Serif Blue 55\" variant of 1011641074 ",
      "product_model": "The Serif Blue 55\" variant of 1011641074 ",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641144,
      "product_name": "The Serif Blue 50\" variant of 1011641074 ",
      "product_model": "The Serif Blue 50\" variant of 1011641074 ",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641143,
      "product_name": "The Frame 32\" variant of 1001000300001",
      "product_model": "The Frame 32\" variant of 1001000300001",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641142,
      "product_name": "The Frame 85\" variant of 1001000300001",
      "product_model": "The Frame 85\" variant of 1001000300001",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641141,
      "product_name": "The Frame 75\" variant of 1001000300001",
      "product_model": "The Frame 75\" variant of 1001000300001",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641140,
      "product_name": "The Frame 55\" variant of 1001000300001",
      "product_model": "The Frame 55\" variant of 1001000300001",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641139,
      "product_name": "The Frame 50\" variant of 1001000300001",
      "product_model": "The Frame 50\" variant of 1001000300001",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641138,
      "product_name": "The Frame 43\" variant of 1001000300001",
      "product_model": "The Frame 43\" variant of 1001000300001",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641137,
      "product_name": "Q60A 85\" Variant of 1011641073",
      "product_model": "Q60A 85\" Variant of 1011641073",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641136,
      "product_name": "Q60A 75\" Variant of 1011641073",
      "product_model": "Q60A 75\" Variant of 1011641073",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641135,
      "product_name": "Q60A 70\" Variant of 1011641073",
      "product_model": "Q60A 70\" Variant of 1011641073",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641134,
      "product_name": "Q60A 55\" Variant of 1011641073",
      "product_model": "Q60A 55\" Variant of 1011641073",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641133,
      "product_name": "Q60A 50\" Variant of 1011641073",
      "product_model": "Q60A 50\" Variant of 1011641073",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641132,
      "product_name": "Q60A 43\" Variant of 1011641073",
      "product_model": "Q60A 43\" Variant of 1011641073",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641131,
      "product_name": "Q68A 75\" variant of 1011641072",
      "product_model": "Q68A 75\" variant of 1011641072",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641130,
      "product_name": "Q68A 55\" variant of 1011641072",
      "product_model": "Q68A 55\" variant of 1011641072",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641129,
      "product_name": "Q68A 50\" variant of 1011641072",
      "product_model": "Q68A 50\" variant of 1011641072",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641128,
      "product_name": "Q68A 43\" variant of 1011641072",
      "product_model": "Q68A 43\" variant of 1011641072",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641127,
      "product_name": "Q70A 85\" variant of 1011641081",
      "product_model": "Q70A 85\" variant of 1011641081",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641126,
      "product_name": "Q70A 75\" variant of 1011641081",
      "product_model": "Q70A 75\" variant of 1011641081",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641125,
      "product_name": "Q70A 65\" variant of 1011641081",
      "product_model": "Q70A 65\" variant of 1011641081",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641124,
      "product_name": "Q77A 75\" variant of 101164071",
      "product_model": "Q77A 75\" variant of 101164071",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641123,
      "product_name": "Q77A 55\" variant of 101164071",
      "product_model": "Q77A 55\" variant of 101164071",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641122,
      "product_name": "Q80A 85\" variant of 1011641070",
      "product_model": "Q80A 85\" variant of 1011641070",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641121,
      "product_name": "Q80A 75\" variant of 1011641070",
      "product_model": "Q80A 75\" variant of 1011641070",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641120,
      "product_name": "Q80A 55\" variant of 1011641070",
      "product_model": "Q80A 55\" variant of 1011641070",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641119,
      "product_name": "Q80A 50\" variant of 1011641070",
      "product_model": "Q80A 50\" variant of 1011641070",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641118,
      "product_name": "QN85A 85\" variant of 1011641069",
      "product_model": "QN85A 85\" variant of 1011641069",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641117,
      "product_name": "QN85A 75\" variant of 1011641069",
      "product_model": "QN85A 75\" variant of 1011641069",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641116,
      "product_name": "QN85A 55\" variant of 1011641069",
      "product_model": "QN85A 55\" variant of 1011641069",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641115,
      "product_name": "QN90A 85\" variant of 1011641068",
      "product_model": "QN90A 85\" variant of 1011641068",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641114,
      "product_name": "QN90A 75\" variant of 1011641068",
      "product_model": "QN90A 75\" variant of 1011641068",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641113,
      "product_name": "QN90A 55\" variant of 1011641068",
      "product_model": "QN90A 55\" variant of 1011641068",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641112,
      "product_name": "QN90A 50\" variant of 1011641068",
      "product_model": "QN90A 50\" variant of 1011641068",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641111,
      "product_name": "QN93A 75\" variant of 1011641067",
      "product_model": "QN93A 75\" variant of 1011641067",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641110,
      "product_name": "QN93A 55\" variant of 1011641067",
      "product_model": "QN93A 55\" variant of 1011641067",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641109,
      "product_name": "QN93A 50\" variant of 1011641067",
      "product_model": "QN93A 50\" variant of 1011641067",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641108,
      "product_name": "QN95A 85\" variation of 1011641066",
      "product_model": "QN95A 85\" variation of 1011641066",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641107,
      "product_name": "QN95A 75\" variation of 1011641066",
      "product_model": "QN95A 75\" variation of 1011641066",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641106,
      "product_name": "QN95A 55\" variation of 1011641066",
      "product_model": "QN95A 55\" variation of 1011641066",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641105,
      "product_name": "QN750A 75\" variation of 1001000300000",
      "product_model": "QN750A 75\" variation of 1001000300000",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641104,
      "product_name": "QN750A 55\" variation of 1001000300000",
      "product_model": "QN750A 55\" variation of 1001000300000",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641103,
      "product_name": "QN800A 85\" variant of 1011641065",
      "product_model": "QN800A 85\" variant of 1011641065",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641102,
      "product_name": "QN800A 75\" variant of 1011641065",
      "product_model": "QN800A 75\" variant of 1011641065",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641101,
      "product_name": "QN900A 85\" variant of 1011641064",
      "product_model": "QN900A 85\" variant of 1011641064",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641100,
      "product_name": "QN900A 75\" variant of 1011641064",
      "product_model": "QN900A 75\" variant of 1011641064",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641064,
      "product_name": "QN900A",
      "product_model": "QN900A",
      "product_mpn": "QN900A",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641065,
      "product_name": "QN800A",
      "product_model": "QN800A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300000,
      "product_name": "QN750A",
      "product_model": "QN750A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641066,
      "product_name": "QN95A",
      "product_model": "QN95A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641067,
      "product_name": "QN93A",
      "product_model": "QN93A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641080,
      "product_name": "AU9075",
      "product_model": "AU9075",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641083,
      "product_name": "The Premiere 9T",
      "product_model": "The Premiere 9T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641082,
      "product_name": "The Premiere 7T",
      "product_model": "The Premiere 7T",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641081,
      "product_name": "Q70A",
      "product_model": "Q70A",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 13500099,
      "product_name": "A grade B/F GBB92STBAP",
      "product_model": "A grade B/F GBB92STBAP",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 10300156,
      "product_name": "Craft Ice side by side GSXV90MCDE",
      "product_model": "Craft Ice side by side GSXV90MCDE",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300155,
      "product_name": "Slim washer dryer CV92T5S2SQE",
      "product_model": "Slim washer dryer CV92T5S2SQE",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300154,
      "product_name": "Slim washer dryer F2DV707S2WS",
      "product_model": "Slim washer dryer F2DV707S2WS",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 13500098,
      "product_name": "Signature side by side LSR100",
      "product_model": "Signature side by side LSR100",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 101300000,
      "product_name": "Signature wine cellar LSR200W",
      "product_model": "Signature wine cellar LSR200W",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WINE CELLARS"
    },
    {
      "product_arid": 9300004,
      "product_name": "Styler Big s5BB",
      "product_model": "Styler Big s5BB",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "STEAMERS"
    },
    {
      "product_arid": 9300003,
      "product_name": "Styler standard S3MFC",
      "product_model": "Styler standard S3MFC",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "STEAMERS"
    },
    {
      "product_arid": 1011641099,
      "product_name": "NanoCell Nano86 55\"",
      "product_model": "NanoCell Nano86 55\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641098,
      "product_name": "QNED QNED91 65\"",
      "product_model": "QNED QNED91 65\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641097,
      "product_name": "OLED TV C1 48\"",
      "product_model": "OLED TV C1 48\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641084,
      "product_name": "OLED TV G1 55\"",
      "product_model": "OLED TV G1 55\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641096,
      "product_name": "Nano. ell 86\"",
      "product_model": "Nano. ell 86\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641095,
      "product_name": "Nano Cell 75\"",
      "product_model": "Nano Cell 75\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641094,
      "product_name": "Nano Cell 50\"",
      "product_model": "Nano Cell 50\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641093,
      "product_name": "NanoCell 65\"",
      "product_model": "NanoCell 65\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641092,
      "product_name": "QNED 86\"",
      "product_model": "QNED 86\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641091,
      "product_name": "QNED 75\"",
      "product_model": "QNED 75\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641090,
      "product_name": "OLED TV C1 83\"",
      "product_model": "OLED TV C1 83\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641089,
      "product_name": "OLED TV C1 77\"",
      "product_model": "OLED TV C1 77\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641088,
      "product_name": "OLED TV C1 65\"",
      "product_model": "OLED TV C1 65\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641087,
      "product_name": "OLED TV C1 55\"",
      "product_model": "OLED TV C1 55\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641086,
      "product_name": "OLED TV G1 77\"",
      "product_model": "OLED TV G1 77\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1011641085,
      "product_name": "OLED TV G1 65\"",
      "product_model": "OLED TV G1 65\"",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 9300002,
      "product_name": "Styler standard S3WF",
      "product_model": "Styler standard S3WF",
      "product_mpn": "",
      "product_brand": "LG",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "STEAMERS"
    },
    {
      "product_arid": 3200100007,
      "product_name": "Animated 2M shelf",
      "product_model": "Animated 2M shelf",
      "product_mpn": "Animated 2M shelf",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 3200100004,
      "product_name": "Animated 1M shelf",
      "product_model": "Animated 1M shelf",
      "product_mpn": "",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 301069,
      "product_name": "Malvern Modular 3 Seat Sofa-Beige Fabric",
      "product_model": "Malvern Modular 3 Seat Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-9U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301068,
      "product_name": "Inca 3 Seater Sofa-Silver Fabric",
      "product_model": "Inca 3 Seater Sofa-Silver Fabric",
      "product_mpn": "ICA003-BKW-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301067,
      "product_name": "Evie 3 Seater Sofa-Charcoal Fabric",
      "product_model": "Evie 3 Seater Sofa-Charcoal Fabric",
      "product_mpn": "EVI003-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301066,
      "product_name": "Dexter 3 Seater Sofa-Brown Fabric",
      "product_model": "Dexter 3 Seater Sofa-Brown Fabric",
      "product_mpn": "ST-DXT003-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301065,
      "product_name": "Carrington 3 Seater Sofa-Silver Fabric",
      "product_model": "Carrington 3 Seater Sofa-Silver Fabric",
      "product_mpn": "CGT003-BRE-SIL-ST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301064,
      "product_name": "Broadway 3 Seater Sofa-Nickel Fabric",
      "product_model": "Broadway 3 Seater Sofa-Nickel Fabric",
      "product_mpn": "ST-BWY003-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301063,
      "product_name": "Texas 3 Seater Sofa-Silver Fabric",
      "product_model": "Texas 3 Seater Sofa-Silver Fabric",
      "product_mpn": "TXS003-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301062,
      "product_name": "Opal 3 Seater Sofa-Ivory Fabric",
      "product_model": "Opal 3 Seater Sofa-Ivory Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301060,
      "product_name": "Denver Corner Sofa Left Hand-Barley Grey",
      "product_model": "Denver Corner Sofa Left Hand-Barley Grey",
      "product_mpn": "DEN021L-BAR-GRE-SLT-SILV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301089,
      "product_name": "Texas Armchair-Silver Fabric",
      "product_model": "Texas Armchair-Silver Fabric",
      "product_mpn": "TXS001-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301088,
      "product_name": "Texas 2 Seater Sofa-Silver Fabric",
      "product_model": "Texas 2 Seater Sofa-Silver Fabric",
      "product_mpn": "TXS002-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301087,
      "product_name": "Texas Armchair Sofa Bed-Silver Fabric",
      "product_model": "Texas Armchair Sofa Bed-Silver Fabric",
      "product_mpn": "TXS037-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301086,
      "product_name": "Texas 2 Seater Sofa Bed-Silver Fabric",
      "product_model": "Texas 2 Seater Sofa Bed-Silver Fabric",
      "product_mpn": "TXS017-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301085,
      "product_name": "Texas 3 Seater Sofa Bed-Silver Fabric",
      "product_model": "Texas 3 Seater Sofa Bed-Silver Fabric",
      "product_mpn": "TXS035-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301084,
      "product_name": "Texas Corner Sofa Right Hand-Silver Fabric",
      "product_model": "Texas Corner Sofa Right Hand-Silver Fabric",
      "product_mpn": "TXS075R-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301083,
      "product_name": "Texas Corner Sofa Left Hand-Silver Fabric",
      "product_model": "Texas Corner Sofa Left Hand-Silver Fabric",
      "product_mpn": "TXS075L-COS-SIL-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301082,
      "product_name": "Opal Footstool-Ivory Fabric",
      "product_model": "Opal Footstool-Ivory Fabric",
      "product_mpn": "OPAFOOT-PTA-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301081,
      "product_name": "Opal Loveseat-Ivory Fabric",
      "product_model": "Opal Loveseat-Ivory Fabric",
      "product_mpn": "OPA057-PTA-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301080,
      "product_name": "Opal 4 Seater Sofa-Ivory Fabric",
      "product_model": "Opal 4 Seater Sofa-Ivory Fabric",
      "product_mpn": "OPA004M-PTA-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301079,
      "product_name": "Opal Corner Sofa Right Hand-Ivory Fabric",
      "product_model": "Opal Corner Sofa Right Hand-Ivory Fabric",
      "product_mpn": "OPA071R-PTA-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301078,
      "product_name": "Opal Corner Sofa Left Hand-Ivory Fabric",
      "product_model": "Opal Corner Sofa Left Hand-Ivory Fabric",
      "product_mpn": "OPA071L-PTA-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301077,
      "product_name": "Opal Large Corner Sofa-Ivory Fabric",
      "product_model": "Opal Large Corner Sofa-Ivory Fabric",
      "product_mpn": "OPA072-PTA-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301072,
      "product_name": "Denver Round Footstool-Barley Grey",
      "product_model": "Denver Round Footstool-Barley Grey",
      "product_mpn": "DEN033-BAR-GRE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301071,
      "product_name": "Denver Armchair-Barley Grey",
      "product_model": "Denver Armchair-Barley Grey",
      "product_mpn": "DEN001-BAR-GRE-SLT-SILV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301118,
      "product_name": "Evie Loveseat-Charcoal Fabric",
      "product_model": "Evie Loveseat-Charcoal Fabric",
      "product_mpn": "ST-EVI057-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301119,
      "product_name": "Evie Armchair-Charcoal Fabric",
      "product_model": "Evie Armchair-Charcoal Fabric",
      "product_mpn": "EVI001-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301117,
      "product_name": "Evie Swivel Chair-Charcoal Fabric",
      "product_model": "Evie Swivel Chair-Charcoal Fabric",
      "product_mpn": "EVI038-PLAIN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301116,
      "product_name": "Evie Corner Sofa Left Hand-Charcoal Fabric",
      "product_model": "Evie Corner Sofa Left Hand-Charcoal Fabric",
      "product_mpn": "ST-EVI067L-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301115,
      "product_name": "Evie 2 Seater Sofa-Charcoal Fabric",
      "product_model": "Evie 2 Seater Sofa-Charcoal Fabric",
      "product_mpn": "ST-EVI002-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301114,
      "product_name": "Evie Corner Sofa Right Hand-Charcoal Fabric",
      "product_model": "Evie Corner Sofa Right Hand-Charcoal Fabric",
      "product_mpn": "ST-EVI067R-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301113,
      "product_name": "Dexter Storage Footstool-Brown Fabric",
      "product_model": "Dexter Storage Footstool-Brown Fabric",
      "product_mpn": "ST-DXT010-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301112,
      "product_name": "Dexter Footstool-Brown Fabric",
      "product_model": "Dexter Footstool-Brown Fabric",
      "product_mpn": "ST-DXTFOOT-HLD-BRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301111,
      "product_name": "Dexter Armchair-Brown Fabric",
      "product_model": "Dexter Armchair-Brown Fabric",
      "product_mpn": "ST-DXT001-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301110,
      "product_name": "Dexter 2 Seater Sofa (Pillow Back)-Brown Fabric",
      "product_model": "Dexter 2 Seater Sofa (Pillow Back)-Brown Fabric",
      "product_mpn": "ST-DXT042-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301109,
      "product_name": "Dexter 3 Seater Sofa (Pillow Back)-Brown Fabric",
      "product_model": "Dexter 3 Seater Sofa (Pillow Back)-Brown Fabric",
      "product_mpn": "ST-DXT043-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301139,
      "product_name": "Malvern Modular 3 Seat Left Hand Corner Sofa-Beige Fabric",
      "product_model": "Malvern Modular 3 Seat Left Hand Corner Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-6U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301138,
      "product_name": "Malvern Modular 4 Seat Left Hand Corner Sofa-Beige Fabric",
      "product_model": "Malvern Modular 4 Seat Left Hand Corner Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-4U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301137,
      "product_name": "Malvern Half Moon Footstool-Beige Fabric",
      "product_model": "Malvern Half Moon Footstool-Beige Fabric",
      "product_mpn": "ST-MLV024-CPD-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301108,
      "product_name": "Dexter 4 Seater Sofa (Pillow Back)-Brown Fabric",
      "product_model": "Dexter 4 Seater Sofa (Pillow Back)-Brown Fabric",
      "product_mpn": "ST-DXT061M-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301104,
      "product_name": "Carrington 2 Seater Sofa (Pillow Back)-Silver Fabric",
      "product_model": "Carrington 2 Seater Sofa (Pillow Back)-Silver Fabric",
      "product_mpn": "CGT045-BRE-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301103,
      "product_name": "Carrington 3 Seater Sofa (Pillow Back)-Silver Fabric",
      "product_model": "Carrington 3 Seater Sofa (Pillow Back)-Silver Fabric",
      "product_mpn": "CGT043-BRE-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301102,
      "product_name": "Carrington Footstool-Silver Fabric",
      "product_model": "Carrington Footstool-Silver Fabric",
      "product_mpn": "CGT010-BRE-SIL-ST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301101,
      "product_name": "Carrington Loveseat-Silver Fabric",
      "product_model": "Carrington Loveseat-Silver Fabric",
      "product_mpn": "CGT057-BRE-SIL-ST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301100,
      "product_name": "Carrington 2 Seater Sofa-Silver Fabric",
      "product_model": "Carrington 2 Seater Sofa-Silver Fabric",
      "product_mpn": "CGT002-BRE-SIL-ST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301099,
      "product_name": "Carrington 4 Seater Sofa (PIllow Back)-Silver Fabric",
      "product_model": "Carrington 4 Seater Sofa (PIllow Back)-Silver Fabric",
      "product_mpn": "CGT061-BRE-SIL-ST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301098,
      "product_name": "Carrington 4 Seater Sofa-Silver Fabric",
      "product_model": "Carrington 4 Seater Sofa-Silver Fabric",
      "product_mpn": "CGT004-BRE-SIL-ST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301097,
      "product_name": "Broadway Storage Footstool-Nickel Fabric",
      "product_model": "Broadway Storage Footstool-Nickel Fabric",
      "product_mpn": "ST-BWY010-HDW-NIC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301096,
      "product_name": "Broadway Armchair-Nickel Fabric",
      "product_model": "Broadway Armchair-Nickel Fabric",
      "product_mpn": "ST-BWY001-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301095,
      "product_name": "Broadway 2 Seater Sofa (Pillow Back)-Nickel Fabric",
      "product_model": "Broadway 2 Seater Sofa (Pillow Back)-Nickel Fabric",
      "product_mpn": "ST-BWY042-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301094,
      "product_name": "Broadway 2 Seater Sofa-Nickel Fabric",
      "product_model": "Broadway 2 Seater Sofa-Nickel Fabric",
      "product_mpn": "ST-BWY002-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301093,
      "product_name": "Broadway 3 Seater Sofa (Pillow Back)-Nickel Fabric",
      "product_model": "Broadway 3 Seater Sofa (Pillow Back)-Nickel Fabric",
      "product_mpn": "ST-BWY043-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301092,
      "product_name": "Broadway Loveseat-Nickel Fabric",
      "product_model": "Broadway Loveseat-Nickel Fabric",
      "product_mpn": "ST-BWY057-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301091,
      "product_name": "Broadway 4 Seater Sofa -Nickel Fabric",
      "product_model": "Broadway 4 Seater Sofa -Nickel Fabric",
      "product_mpn": "ST-BWY004M-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301090,
      "product_name": "Broadway 4 Seater Sofa (Pillow Back)- Nickel Fabric",
      "product_model": "Broadway 4 Seater Sofa (Pillow Back)- Nickel Fabric",
      "product_mpn": "ST-BWY061M-HDW-NIC-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301136,
      "product_name": "Malvern Storage Footstool-Beige Fabric",
      "product_model": "Malvern Storage Footstool-Beige Fabric",
      "product_mpn": "ST-MLV010-CPD-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301135,
      "product_name": "Malvern Modular 2 Seat Sofa-Beige Fabric",
      "product_model": "Malvern Modular 2 Seat Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-8U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301134,
      "product_name": "Malvern Swivel Chair-Beige Fabric",
      "product_model": "Malvern Swivel Chair-Beige Fabric",
      "product_mpn": "ST-MLV023-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301133,
      "product_name": "Malvern Modular 3 Seat Right Hand Corner Sofa-Beige Fabric",
      "product_model": "Malvern Modular 3 Seat Right Hand Corner Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-7U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301132,
      "product_name": "Malvern Modular 3 Seat Corner Sofa-Beige Fabric",
      "product_model": "Malvern Modular 3 Seat Corner Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-1U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301131,
      "product_name": "Malvern Modular 4 Seat Right Hand Corner Sofa-Beige Fabric",
      "product_model": "Malvern Modular 4 Seat Right Hand Corner Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-5U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301130,
      "product_name": "Malvern Modular 4 Seat Corner Sofa-Beige Fabric",
      "product_model": "Malvern Modular 4 Seat Corner Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-2U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301129,
      "product_name": "Malvern Modular 5 Seat Corner Sofa-Beige Fabric",
      "product_model": "Malvern Modular 5 Seat Corner Sofa-Beige Fabric",
      "product_mpn": "ST-MLV-GROUP-3U-CPD-BEI-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301128,
      "product_name": "Inca Corner Sofa Right Hand-Silver Fabric",
      "product_model": "Inca Corner Sofa Right Hand-Silver Fabric",
      "product_mpn": "ST-ICA075R-BKW-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301127,
      "product_name": "Inca Corner Sofa Left Hand-Silver Fabric",
      "product_model": "Inca Corner Sofa Left Hand-Silver Fabric",
      "product_mpn": "ST-ICA075L-BKW-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301126,
      "product_name": "Inca Storage Footstool-Silver Fabric",
      "product_model": "Inca Storage Footstool-Silver Fabric",
      "product_mpn": "ST-ICA010-BKW-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301125,
      "product_name": "Inca Armchair-Silver Fabric",
      "product_model": "Inca Armchair-Silver Fabric",
      "product_mpn": "ST-ICA001-BKW-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301124,
      "product_name": "Inca 2 Seater Sofa-Silver Fabric",
      "product_model": "Inca 2 Seater Sofa-Silver Fabric",
      "product_mpn": "ICA002-BKW-SIL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301123,
      "product_name": "Evie Accent Swivel Chair-Purple Fabric",
      "product_model": "Evie Accent Swivel Chair-Purple Fabric",
      "product_mpn": "ST-EVI038-PATT-PUR-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301122,
      "product_name": "Evie Accent Footstool-Purple Fabric",
      "product_model": "Evie Accent Footstool-Purple Fabric",
      "product_mpn": "EVI006-PATT-PUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301121,
      "product_name": "Evie Footstool-Charcoal Fabric",
      "product_model": "Evie Footstool-Charcoal Fabric",
      "product_mpn": "EVI006-PLAIN-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301120,
      "product_name": "Evie Storage Footstool-Charcoal Fabric",
      "product_model": "Evie Storage Footstool-Charcoal Fabric",
      "product_mpn": "EVI010-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301107,
      "product_name": "Dexter 2 Seater Sofa-Brown Fabric",
      "product_model": "Dexter 2 Seater Sofa-Brown Fabric",
      "product_mpn": "ST-DXT002-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301106,
      "product_name": "Dexter 4 Seater Sofa-Brown Fabric",
      "product_model": "Dexter 4 Seater Sofa-Brown Fabric",
      "product_mpn": "ST-DXT004M-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301105,
      "product_name": "Dexter Accent Chair-Brown Fabric",
      "product_model": "Dexter Accent Chair-Brown Fabric",
      "product_mpn": "ST-DXT009-HLD-BRN-RED",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301070,
      "product_name": "Denver Corner Sofa Right Hand-Barley Grey",
      "product_model": "Denver Corner Sofa Right Hand-Barley Grey",
      "product_mpn": "DEN021R-BAR-GRE-SLT-SILV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301143,
      "product_name": "Denver Round Footstool-Barley Silver",
      "product_model": "Denver Round Footstool-Barley Silver",
      "product_mpn": "DEN033-BAR-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301142,
      "product_name": "Denver Armchair-Barley Silver",
      "product_model": "Denver Armchair-Barley Silver",
      "product_mpn": "DEN001-BAR-SIL-SLT-SILV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301141,
      "product_name": "Denver Corner Sofa Right Hand-Barley Silver",
      "product_model": "Denver Corner Sofa Right Hand-Barley Silver",
      "product_mpn": "DEN021R-BAR-SIL-SLT-SILV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301140,
      "product_name": "Denver Corner Sofa Left Hand-Barley Silver",
      "product_model": "Denver Corner Sofa Left Hand-Barley Silver",
      "product_mpn": "DEN021L-BAR-SIL-SLT-SILV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301147,
      "product_name": "Denver Round Footstool-Barley Beige",
      "product_model": "Denver Round Footstool-Barley Beige",
      "product_mpn": "DEN033-BAR-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301146,
      "product_name": "Denver Armchair-Barley Beige",
      "product_model": "Denver Armchair-Barley Beige",
      "product_mpn": "DEN001-BAR-BEI-SLT-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301145,
      "product_name": "Denver Corner Sofa Right Hand-Barley Beige",
      "product_model": "Denver Corner Sofa Right Hand-Barley Beige",
      "product_mpn": "DEN021R-BAR-BEI-SLT-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301144,
      "product_name": "Denver Corner Sofa Left Hand-Barley Beige",
      "product_model": "Denver Corner Sofa Left Hand-Barley Beige",
      "product_mpn": "DEN021L-BAR-BEI-SLT-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301178,
      "product_name": "Opal Footstool-Royal Blue Fabric",
      "product_model": "Opal Footstool-Royal Blue Fabric",
      "product_mpn": "OPAFOOT-PTA-RBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301177,
      "product_name": "Opal Loveseat-Royal Blue Fabric",
      "product_model": "Opal Loveseat-Royal Blue Fabric",
      "product_mpn": "OPA057-PTA-RBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301176,
      "product_name": "Opal 4 Seater Sofa-Royal Blue Fabric",
      "product_model": "Opal 4 Seater Sofa-Royal Blue Fabric",
      "product_mpn": "OPA004M-PTA-RBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301175,
      "product_name": "Opal Corner Sofa Right Hand-Royal Blue Fabric",
      "product_model": "Opal Corner Sofa Right Hand-Royal Blue Fabric",
      "product_mpn": "OPA071R-PTA-RBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301174,
      "product_name": "Opal Corner Sofa Left Hand-Royal Blue Fabric",
      "product_model": "Opal Corner Sofa Left Hand-Royal Blue Fabric",
      "product_mpn": "OPA071L-PTA-RBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301173,
      "product_name": "Opal Large Corner Sofa-Royal Blue Fabric",
      "product_model": "Opal Large Corner Sofa-Royal Blue Fabric",
      "product_mpn": "OPA072-PTA-RBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301172,
      "product_name": "Opal 3 Seater Sofa-Royal Blue Fabric",
      "product_model": "Opal 3 Seater Sofa-Royal Blue Fabric",
      "product_mpn": "OPA003-PTA-RBLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301185,
      "product_name": "Opal Footstool-Slate Fabric",
      "product_model": "Opal Footstool-Slate Fabric",
      "product_mpn": "OPAFOOT-PTA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301184,
      "product_name": "Opal Loveseat-Slate Fabric",
      "product_model": "Opal Loveseat-Slate Fabric",
      "product_mpn": "OPA057-PTA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301183,
      "product_name": "Opal 4 Seater Sofa-Slate Fabric",
      "product_model": "Opal 4 Seater Sofa-Slate Fabric",
      "product_mpn": "OPA004M-PTA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301182,
      "product_name": "Opal Corner Sofa Right Hand-Slate Fabric",
      "product_model": "Opal Corner Sofa Right Hand-Slate Fabric",
      "product_mpn": "OPA071R-PTA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301181,
      "product_name": "Opal Corner Sofa Left Hand-Slate Fabric",
      "product_model": "Opal Corner Sofa Left Hand-Slate Fabric",
      "product_mpn": "OPA071L-PTA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301180,
      "product_name": "Opal Large Corner Sofa -Slate Fabric",
      "product_model": "Opal Large Corner Sofa -Slate Fabric",
      "product_mpn": "OPA072-PTA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301179,
      "product_name": "Opal 3 Seater Sofa-Slate Fabric",
      "product_model": "Opal 3 Seater Sofa-Slate Fabric",
      "product_mpn": "OPA003-PTA-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301192,
      "product_name": "Opal Footstool-Mink Fabric",
      "product_model": "Opal Footstool-Mink Fabric",
      "product_mpn": "OPAFOOT-PTA-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301191,
      "product_name": "Opal Loveseat-Mink Fabric",
      "product_model": "Opal Loveseat-Mink Fabric",
      "product_mpn": "OPA057-PTA-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301190,
      "product_name": "Opal 4 Seater Sofa-Mink Fabric",
      "product_model": "Opal 4 Seater Sofa-Mink Fabric",
      "product_mpn": "OPA004M-PTA-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301189,
      "product_name": "Opal Corner Sofa Right Hand-Mink Fabric",
      "product_model": "Opal Corner Sofa Right Hand-Mink Fabric",
      "product_mpn": "OPA071R-PTA-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301188,
      "product_name": "Opal Corner Sofa Left Hand-Mink Fabric",
      "product_model": "Opal Corner Sofa Left Hand-Mink Fabric",
      "product_mpn": "OPA071L-PTA-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301187,
      "product_name": "Opal Large Corner Sofa-Mink Fabric",
      "product_model": "Opal Large Corner Sofa-Mink Fabric",
      "product_mpn": "OPA072-PTA-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301186,
      "product_name": "Opal 3 Seater Sofa-Mink Fabric",
      "product_model": "Opal 3 Seater Sofa-Mink Fabric",
      "product_mpn": "OPA003-PTA-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301200,
      "product_name": "Texas Armchair-Rose Fabric",
      "product_model": "Texas Armchair-Rose Fabric",
      "product_mpn": "TXS001-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301199,
      "product_name": "Texas 2 Seater Sofa-Rose Fabric",
      "product_model": "Texas 2 Seater Sofa-Rose Fabric",
      "product_mpn": "TXS002-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301198,
      "product_name": "Texas Armchair Sofa Bed-Rose Fabric",
      "product_model": "Texas Armchair Sofa Bed-Rose Fabric",
      "product_mpn": "TXS037-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301197,
      "product_name": "Texas 2 Seater Sofa Bed-Rose Fabric",
      "product_model": "Texas 2 Seater Sofa Bed-Rose Fabric",
      "product_mpn": "TXS017-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301196,
      "product_name": "Texas 3 Seater Sofa Bed-Rose Fabric",
      "product_model": "Texas 3 Seater Sofa Bed-Rose Fabric",
      "product_mpn": "TXS035-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301195,
      "product_name": "Texas Corner Sofa Right Hand-Rose Fabric",
      "product_model": "Texas Corner Sofa Right Hand-Rose Fabric",
      "product_mpn": "TXS075R-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301194,
      "product_name": "Texas Corner Sofa Left Hand-Rose Fabric",
      "product_model": "Texas Corner Sofa Left Hand-Rose Fabric",
      "product_mpn": "TXS075L-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301193,
      "product_name": "Texas 3 Seater Sofa-Rose Fabric",
      "product_model": "Texas 3 Seater Sofa-Rose Fabric",
      "product_mpn": "TXS003-COS-ROS-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301208,
      "product_name": "Texas Armchair-Navy Fabric",
      "product_model": "Texas Armchair-Navy Fabric",
      "product_mpn": "ST-TXS001-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301207,
      "product_name": "Texas 2 Seater Sofa-Navy Fabric",
      "product_model": "Texas 2 Seater Sofa-Navy Fabric",
      "product_mpn": "ST-TXS002-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301206,
      "product_name": "Texas Armchair Sofa Bed-Navy Fabric",
      "product_model": "Texas Armchair Sofa Bed-Navy Fabric",
      "product_mpn": "ST-TXS037-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301205,
      "product_name": "Texas 2 Seater Sofa Bed-Navy Fabric",
      "product_model": "Texas 2 Seater Sofa Bed-Navy Fabric",
      "product_mpn": "ST-TXS017-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301204,
      "product_name": "Texas 3 Seater Sofa Bed-Navy Fabric",
      "product_model": "Texas 3 Seater Sofa Bed-Navy Fabric",
      "product_mpn": "ST-TXS035-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301203,
      "product_name": "Texas Corner Sofa Right Hand-Navy Fabric",
      "product_model": "Texas Corner Sofa Right Hand-Navy Fabric",
      "product_mpn": "ST-TXS075R-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301202,
      "product_name": "Texas Corner Sofa Left Hand-Navy Fabric",
      "product_model": "Texas Corner Sofa Left Hand-Navy Fabric",
      "product_mpn": "ST-TXS075L-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301201,
      "product_name": "Texas 3 Seater Sofa-Navy Fabric",
      "product_model": "Texas 3 Seater Sofa-Navy Fabric",
      "product_mpn": "ST-TXS003-COS-NAV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301216,
      "product_name": "Texas Armchair-Pewter Fabric",
      "product_model": "Texas Armchair-Pewter Fabric",
      "product_mpn": "ST-TXS001-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301215,
      "product_name": "Texas 2 Seater Sofa-Pewter Fabric",
      "product_model": "Texas 2 Seater Sofa-Pewter Fabric",
      "product_mpn": "ST-TXS002-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301214,
      "product_name": "Texas Armchair Sofa Bed-Pewter Fabric",
      "product_model": "Texas Armchair Sofa Bed-Pewter Fabric",
      "product_mpn": "ST-TXS037-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301213,
      "product_name": "Texas 2 Seater Sofa Bed-Pewter Fabric",
      "product_model": "Texas 2 Seater Sofa Bed-Pewter Fabric",
      "product_mpn": "ST-TXS017-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301212,
      "product_name": "Texas 3 Seater Sofa Bed-Pewter Fabric",
      "product_model": "Texas 3 Seater Sofa Bed-Pewter Fabric",
      "product_mpn": "ST-TXS035-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301211,
      "product_name": "Texas Corner Sofa Right Hand-Pewter Fabric",
      "product_model": "Texas Corner Sofa Right Hand-Pewter Fabric",
      "product_mpn": "ST-TXS075R-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301210,
      "product_name": "Texas Corner Sofa Left Hand-Pewter Fabric",
      "product_model": "Texas Corner Sofa Left Hand-Pewter Fabric",
      "product_mpn": "ST-TXS075L-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301209,
      "product_name": "Texas 3 Seater Sofa-Pewter Fabric",
      "product_model": "Texas 3 Seater Sofa-Pewter Fabric",
      "product_mpn": "ST-TXS003-COS-PEW-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301225,
      "product_name": "Broadway Storage Footstool-Beige Fabric",
      "product_model": "Broadway Storage Footstool-Beige Fabric",
      "product_mpn": "BWY010-HDW-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301224,
      "product_name": "Broadway Armchair-Beige Fabric",
      "product_model": "Broadway Armchair-Beige Fabric",
      "product_mpn": "BWY001-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301223,
      "product_name": "Broadway 2 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_model": "Broadway 2 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_mpn": "BWY042-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301222,
      "product_name": "Broadway 2 Seater Sofa-Beige Fabric",
      "product_model": "Broadway 2 Seater Sofa-Beige Fabric",
      "product_mpn": "BWY002-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301221,
      "product_name": "Broadway 3 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_model": "Broadway 3 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_mpn": "BWY043-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301220,
      "product_name": "Broadway Loveseat-Beige Fabric",
      "product_model": "Broadway Loveseat-Beige Fabric",
      "product_mpn": "BWY057-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301219,
      "product_name": "Broadway 4 Seater Sofa -Beige Fabric",
      "product_model": "Broadway 4 Seater Sofa -Beige Fabric",
      "product_mpn": "BWY004M-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301218,
      "product_name": "Broadway 4 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_model": "Broadway 4 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_mpn": "BWY061M-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301217,
      "product_name": "Broadway 3 Seater Sofa-Beige Fabric",
      "product_model": "Broadway 3 Seater Sofa-Beige Fabric",
      "product_mpn": "BWY003-HDW-BEI-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301233,
      "product_name": "Broadway Armchair-Black Fabric",
      "product_model": "Broadway Armchair-Black Fabric",
      "product_mpn": "BWY001-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301234,
      "product_name": "Broadway Storage Footstool-Black Fabric",
      "product_model": "Broadway Storage Footstool-Black Fabric",
      "product_mpn": "BWY010-HDW-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301232,
      "product_name": "Broadway 2 Seater Sofa (Pillow Back)-Black Fabric",
      "product_model": "Broadway 2 Seater Sofa (Pillow Back)-Black Fabric",
      "product_mpn": "BWY042-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301231,
      "product_name": "Broadway 2 Seater Sofa-Black Fabric",
      "product_model": "Broadway 2 Seater Sofa-Black Fabric",
      "product_mpn": "BWY002-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301230,
      "product_name": "Broadway 3 Seater Sofa (Pillow Back)-Black Fabric",
      "product_model": "Broadway 3 Seater Sofa (Pillow Back)-Black Fabric",
      "product_mpn": "BWY043-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301229,
      "product_name": "Broadway Loveseat-Black Fabric",
      "product_model": "Broadway Loveseat-Black Fabric",
      "product_mpn": "BWY057-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301228,
      "product_name": "Broadway 4 Seater Sofa -Black Fabric",
      "product_model": "Broadway 4 Seater Sofa -Black Fabric",
      "product_mpn": "BWY004M-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301227,
      "product_name": "Broadway 4 Seater Sofa-(Pillow Back)-Black Fabric",
      "product_model": "Broadway 4 Seater Sofa-(Pillow Back)-Black Fabric",
      "product_mpn": "BWY061M-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301226,
      "product_name": "Broadway 3 Seater Sofa-Black Fabric",
      "product_model": "Broadway 3 Seater Sofa-Black Fabric",
      "product_mpn": "BWY003-HDW-BLK-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301243,
      "product_name": "Broadway Storage Footstool-Grey Fabric",
      "product_model": "Broadway Storage Footstool-Grey Fabric",
      "product_mpn": "BWY010-HDW-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301242,
      "product_name": "Broadway Armchair-Grey Fabric",
      "product_model": "Broadway Armchair-Grey Fabric",
      "product_mpn": "BWY001-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301241,
      "product_name": "Broadway 2 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_model": "Broadway 2 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_mpn": "BWY042-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301240,
      "product_name": "Broadway 2 Seater Sofa- Grey Fabric",
      "product_model": "Broadway 2 Seater Sofa- Grey Fabric",
      "product_mpn": "BWY002-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301239,
      "product_name": "Broadway 3 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_model": "Broadway 3 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_mpn": "BWY043-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301238,
      "product_name": "Broadway Loveseat-Grey Fabric",
      "product_model": "Broadway Loveseat-Grey Fabric",
      "product_mpn": "BWY057-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301237,
      "product_name": "Broadway 4 Seater Sofa -Grey Fabric",
      "product_model": "Broadway 4 Seater Sofa -Grey Fabric",
      "product_mpn": "BWY004M-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301236,
      "product_name": "Broadway 4 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_model": "Broadway 4 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_mpn": "BWY061M-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301235,
      "product_name": "Broadway 3 Seater Sofa-Grey Fabric",
      "product_model": "Broadway 3 Seater Sofa-Grey Fabric",
      "product_mpn": "BWY003-HDW-GRY-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301251,
      "product_name": "Carrington 2 Seater Sofa (Pillow Back)-Biscuit Fabric",
      "product_model": "Carrington 2 Seater Sofa (Pillow Back)-Biscuit Fabric",
      "product_mpn": "CGT045-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301250,
      "product_name": "Carrington 3 Seater Sofa (Pillow Back)-Biscuit Fabric",
      "product_model": "Carrington 3 Seater Sofa (Pillow Back)-Biscuit Fabric",
      "product_mpn": "CGT043-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301249,
      "product_name": "Carrington Footstool-Biscuit Fabric",
      "product_model": "Carrington Footstool-Biscuit Fabric",
      "product_mpn": "CGT010-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301248,
      "product_name": "Carrington Loveseat-Biscuit Fabric",
      "product_model": "Carrington Loveseat-Biscuit Fabric",
      "product_mpn": "CGT057-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301247,
      "product_name": "Carrington 2 Seater Sofa-Biscuit Fabric",
      "product_model": "Carrington 2 Seater Sofa-Biscuit Fabric",
      "product_mpn": "CGT002-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301246,
      "product_name": "Carrington 4 Seater Sofa (PIllow Back)-Biscuit Fabric",
      "product_model": "Carrington 4 Seater Sofa (PIllow Back)-Biscuit Fabric",
      "product_mpn": "CGT061-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301245,
      "product_name": "Carrington 4 Seater Sofa-Biscuit Fabric",
      "product_model": "Carrington 4 Seater Sofa-Biscuit Fabric",
      "product_mpn": "CGT004-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301244,
      "product_name": "Carrington 3 Seater Sofa -Biscuit Fabric",
      "product_model": "Carrington 3 Seater Sofa -Biscuit Fabric",
      "product_mpn": "CGT003-BRE-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301259,
      "product_name": "Carrington 2 Seater Sofa (Pillow Back)-Navy Fabric",
      "product_model": "Carrington 2 Seater Sofa (Pillow Back)-Navy Fabric",
      "product_mpn": "CGT045-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301258,
      "product_name": "Carrington 3 Seater Sofa (Pillow Back)-Navy Fabric",
      "product_model": "Carrington 3 Seater Sofa (Pillow Back)-Navy Fabric",
      "product_mpn": "CGT043-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301257,
      "product_name": "Carrington Footstool-Navy Fabric",
      "product_model": "Carrington Footstool-Navy Fabric",
      "product_mpn": "CGT010-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301256,
      "product_name": "Carrington Loveseat-Navy Fabric",
      "product_model": "Carrington Loveseat-Navy Fabric",
      "product_mpn": "CGT057-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301255,
      "product_name": "Carrington 2 Seater Sofa-Navy Fabric",
      "product_model": "Carrington 2 Seater Sofa-Navy Fabric",
      "product_mpn": "CGT002-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301254,
      "product_name": "Carrington 4 Seater Sofa (PIllow Back)-Navy Fabric",
      "product_model": "Carrington 4 Seater Sofa (PIllow Back)-Navy Fabric",
      "product_mpn": "CGT061-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301253,
      "product_name": "Carrington 4 Seater Sofa-Navy Fabric",
      "product_model": "Carrington 4 Seater Sofa-Navy Fabric",
      "product_mpn": "CGT004-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301252,
      "product_name": "Carrington 3 Seater Sofa -Navy Fabric",
      "product_model": "Carrington 3 Seater Sofa -Navy Fabric",
      "product_mpn": "CGT003-BRE-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301268,
      "product_name": "Dexter Storage Footstool-Beige Fabric",
      "product_model": "Dexter Storage Footstool-Beige Fabric",
      "product_mpn": "DXT010-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301267,
      "product_name": "Dexter Footstool-Beige Fabric",
      "product_model": "Dexter Footstool-Beige Fabric",
      "product_mpn": "DXTFOOT-HLD-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301266,
      "product_name": "Dexter Armchair-Beige Fabric",
      "product_model": "Dexter Armchair-Beige Fabric",
      "product_mpn": "DXT001-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301265,
      "product_name": "Dexter 2 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_model": "Dexter 2 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_mpn": "DXT042-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301264,
      "product_name": "Dexter 3 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_model": "Dexter 3 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_mpn": "DXT043-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301263,
      "product_name": "Dexter 4 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_model": "Dexter 4 Seater Sofa (Pillow Back)-Beige Fabric",
      "product_mpn": "DXT061M-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301262,
      "product_name": "Dexter 2 Seater Sofa-Beige Fabric",
      "product_model": "Dexter 2 Seater Sofa-Beige Fabric",
      "product_mpn": "DXT002-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301261,
      "product_name": "Dexter 4 Seater Sofa-Beige Fabric",
      "product_model": "Dexter 4 Seater Sofa-Beige Fabric",
      "product_mpn": "DXT004M-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301260,
      "product_name": "Dexter 3 Seater Sofa-Beige Fabric",
      "product_model": "Dexter 3 Seater Sofa-Beige Fabric",
      "product_mpn": "DXT003-HLD-BEI-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301277,
      "product_name": "Dexter Storage Footstool-Coffee Fabric",
      "product_model": "Dexter Storage Footstool-Coffee Fabric",
      "product_mpn": "DXT010-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301276,
      "product_name": "Dexter Footstool-Coffee Fabric",
      "product_model": "Dexter Footstool-Coffee Fabric",
      "product_mpn": "DXTFOOT-HLD-COF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301275,
      "product_name": "Dexter Armchair-Coffee Fabric",
      "product_model": "Dexter Armchair-Coffee Fabric",
      "product_mpn": "DXT001-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301274,
      "product_name": "Dexter 2 Seater Sofa (Pillow Back)-Coffee Fabric",
      "product_model": "Dexter 2 Seater Sofa (Pillow Back)-Coffee Fabric",
      "product_mpn": "DXT042-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301273,
      "product_name": "Dexter 3 Seater Sofa (Pillow Back)-Coffee Fabric",
      "product_model": "Dexter 3 Seater Sofa (Pillow Back)-Coffee Fabric",
      "product_mpn": "DXT043-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301272,
      "product_name": "Dexter 4 Seater Sofa (Pillow Back) -Coffee Fabric",
      "product_model": "Dexter 4 Seater Sofa (Pillow Back) -Coffee Fabric",
      "product_mpn": "DXT061M-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301271,
      "product_name": "Dexter 2 Seater Sofa-Coffee Fabric",
      "product_model": "Dexter 2 Seater Sofa-Coffee Fabric",
      "product_mpn": "DXT002-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301270,
      "product_name": "Dexter 4 Seater Sofa-Coffee Fabric",
      "product_model": "Dexter 4 Seater Sofa-Coffee Fabric",
      "product_mpn": "DXT004M-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301269,
      "product_name": "Dexter 3 Seater Sofa-Coffee Fabric",
      "product_model": "Dexter 3 Seater Sofa-Coffee Fabric",
      "product_mpn": "DXT003-HLD-COF-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301286,
      "product_name": "Dexter Storage Footstool-Grey Fabric",
      "product_model": "Dexter Storage Footstool-Grey Fabric",
      "product_mpn": "DXT010-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301285,
      "product_name": "Dexter Footstool-Grey Fabric",
      "product_model": "Dexter Footstool-Grey Fabric",
      "product_mpn": "DXTFOOT-HLD-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301284,
      "product_name": "Dexter Armchair-Grey Fabric",
      "product_model": "Dexter Armchair-Grey Fabric",
      "product_mpn": "DXT001-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301283,
      "product_name": "Dexter 2 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_model": "Dexter 2 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_mpn": "DXT042-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301282,
      "product_name": "Dexter 3 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_model": "Dexter 3 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_mpn": "DXT043-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301281,
      "product_name": "Dexter 4 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_model": "Dexter 4 Seater Sofa (Pillow Back)-Grey Fabric",
      "product_mpn": "DXT061M-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301280,
      "product_name": "Dexter 2 Seater Sofa-Grey Fabric",
      "product_model": "Dexter 2 Seater Sofa-Grey Fabric",
      "product_mpn": "DXT002-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301279,
      "product_name": "Dexter 4 Seater Sofa-Grey Fabric",
      "product_model": "Dexter 4 Seater Sofa-Grey Fabric",
      "product_mpn": "DXT004M-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301278,
      "product_name": "Dexter 3 Seater Sofa-Grey Fabric",
      "product_model": "Dexter 3 Seater Sofa-Grey Fabric",
      "product_mpn": "DXT003-HLD-GRY-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301295,
      "product_name": "Evie Footstool-Lime Fabric",
      "product_model": "Evie Footstool-Lime Fabric",
      "product_mpn": "EVI006-PLAIN-LIME",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301294,
      "product_name": "Evie Storage Footstool-Lime Fabric",
      "product_model": "Evie Storage Footstool-Lime Fabric",
      "product_mpn": "EVI010-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301293,
      "product_name": "Evie Armchair-Lime Fabric",
      "product_model": "Evie Armchair-Lime Fabric",
      "product_mpn": "EVI001-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301292,
      "product_name": "Evie Loveseat -Lime Fabric",
      "product_model": "Evie Loveseat -Lime Fabric",
      "product_mpn": "EVI057-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301291,
      "product_name": "Evie Swivel Chair-Lime Fabric",
      "product_model": "Evie Swivel Chair-Lime Fabric",
      "product_mpn": "EVI038-PLAIN-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301290,
      "product_name": "Evie Corner Sofa Left Hand-Lime Fabric",
      "product_model": "Evie Corner Sofa Left Hand-Lime Fabric",
      "product_mpn": "EVI067L-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301289,
      "product_name": "Evie 2 Seater Sofa-Lime Fabric",
      "product_model": "Evie 2 Seater Sofa-Lime Fabric",
      "product_mpn": "EVI002-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301288,
      "product_name": "Evie Corner Sofa Right Hand-Lime Fabric",
      "product_model": "Evie Corner Sofa Right Hand-Lime Fabric",
      "product_mpn": "EVI067R-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301287,
      "product_name": "Evie 3 Seater Sofa-Lime Fabric",
      "product_model": "Evie 3 Seater Sofa-Lime Fabric",
      "product_mpn": "EVI003-LIM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301304,
      "product_name": "Evie Footstool-Ivory Fabric",
      "product_model": "Evie Footstool-Ivory Fabric",
      "product_mpn": "EVI006-PLAIN-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301303,
      "product_name": "Evie Storage Footstool-Ivory Fabric",
      "product_model": "Evie Storage Footstool-Ivory Fabric",
      "product_mpn": "EVI010-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301302,
      "product_name": "Evie Armchair-Ivory Fabric",
      "product_model": "Evie Armchair-Ivory Fabric",
      "product_mpn": "EVI001-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301301,
      "product_name": "Evie Loveseat-Ivory Fabric",
      "product_model": "Evie Loveseat-Ivory Fabric",
      "product_mpn": "EVI057-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301300,
      "product_name": "Evie Swivel Chair-Ivory Fabric",
      "product_model": "Evie Swivel Chair-Ivory Fabric",
      "product_mpn": "EVI038-PLAIN-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301299,
      "product_name": "Evie Corner Sofa Left Hand-Ivory Fabric",
      "product_model": "Evie Corner Sofa Left Hand-Ivory Fabric",
      "product_mpn": "EVI067L-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301298,
      "product_name": "Evie 2 Seater Sofa-Ivory Fabric",
      "product_model": "Evie 2 Seater Sofa-Ivory Fabric",
      "product_mpn": "EVI002-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301297,
      "product_name": "Evie Corner Sofa Right Hand-Ivory Fabric",
      "product_model": "Evie Corner Sofa Right Hand-Ivory Fabric",
      "product_mpn": "EVI067R-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301296,
      "product_name": "Evie 3 Seater Sofa-Ivory Fabric",
      "product_model": "Evie 3 Seater Sofa-Ivory Fabric",
      "product_mpn": "EVI003-IVO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301313,
      "product_name": "Evie Footstool-Mink Fabric",
      "product_model": "Evie Footstool-Mink Fabric",
      "product_mpn": "EVI006-PLAIN-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301312,
      "product_name": "Evie Storage Footstool-Mink Fabric",
      "product_model": "Evie Storage Footstool-Mink Fabric",
      "product_mpn": "EVI010-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301311,
      "product_name": "Evie Armchair-Mink Fabric",
      "product_model": "Evie Armchair-Mink Fabric",
      "product_mpn": "EVI001-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301310,
      "product_name": "Evie Loveseat-Mink Fabric",
      "product_model": "Evie Loveseat-Mink Fabric",
      "product_mpn": "EVI057-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301309,
      "product_name": "Evie Swivel Chair-Mink Fabric",
      "product_model": "Evie Swivel Chair-Mink Fabric",
      "product_mpn": "EVI038-PLAIN-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301308,
      "product_name": "Evie Corner Sofa Left Hand-Mink Fabric",
      "product_model": "Evie Corner Sofa Left Hand-Mink Fabric",
      "product_mpn": "EVI067L-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301307,
      "product_name": "Evie 2 Seater Sofa-Mink Fabric",
      "product_model": "Evie 2 Seater Sofa-Mink Fabric",
      "product_mpn": "EVI002-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301306,
      "product_name": "Evie Corner Sofa Right Hand-Mink Fabric",
      "product_model": "Evie Corner Sofa Right Hand-Mink Fabric",
      "product_mpn": "EVI067R-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301305,
      "product_name": "Evie 3 Seater Sofa-Mink Fabric",
      "product_model": "Evie 3 Seater Sofa-Mink Fabric",
      "product_mpn": "EVI003-MNK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301322,
      "product_name": "Evie Footstool-Silver Fabric",
      "product_model": "Evie Footstool-Silver Fabric",
      "product_mpn": "EVI006-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301321,
      "product_name": "Evie Storage Footstool-Silver Fabric",
      "product_model": "Evie Storage Footstool-Silver Fabric",
      "product_mpn": "EVI010-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301320,
      "product_name": "Evie Armchair-Silver Fabric",
      "product_model": "Evie Armchair-Silver Fabric",
      "product_mpn": "EVI001-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301319,
      "product_name": "Evie Loveseat-Silver Fabric",
      "product_model": "Evie Loveseat-Silver Fabric",
      "product_mpn": "EVI057-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301318,
      "product_name": "Evie Swivel Chair-Silver Fabric",
      "product_model": "Evie Swivel Chair-Silver Fabric",
      "product_mpn": "EVI038-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301317,
      "product_name": "Evie Corner Sofa Left Hand-Silver Fabric",
      "product_model": "Evie Corner Sofa Left Hand-Silver Fabric",
      "product_mpn": "EVI067L-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301316,
      "product_name": "Evie 2 Seater Sofa-Silver Fabric",
      "product_model": "Evie 2 Seater Sofa-Silver Fabric",
      "product_mpn": "EVI002-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301315,
      "product_name": "Evie Corner Sofa Right Hand-Silver Fabric",
      "product_model": "Evie Corner Sofa Right Hand-Silver Fabric",
      "product_mpn": "EVI067R-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301314,
      "product_name": "Evie 3 Seater Sofa-Silver Fabric",
      "product_model": "Evie 3 Seater Sofa-Silver Fabric",
      "product_mpn": "EVI003-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301331,
      "product_name": "Evie Footstool-Blue Fabric",
      "product_model": "Evie Footstool-Blue Fabric",
      "product_mpn": "ST-EVI006-PLAIN-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301330,
      "product_name": "Evie Storage Footstool-Blue Fabric",
      "product_model": "Evie Storage Footstool-Blue Fabric",
      "product_mpn": "ST-EVI010-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301329,
      "product_name": "Evie Armchair-Blue Fabric",
      "product_model": "Evie Armchair-Blue Fabric",
      "product_mpn": "ST-EVI001-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301328,
      "product_name": "Evie Loveseat-Blue Fabric",
      "product_model": "Evie Loveseat-Blue Fabric",
      "product_mpn": "ST-EVI057-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301327,
      "product_name": "Evie Swivel Chair-Blue Fabric",
      "product_model": "Evie Swivel Chair-Blue Fabric",
      "product_mpn": "ST-EVI038-PLAIN-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301326,
      "product_name": "Evie Corner Sofa Left Hand-Blue Fabric",
      "product_model": "Evie Corner Sofa Left Hand-Blue Fabric",
      "product_mpn": "ST-EVI067L-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301325,
      "product_name": "Evie 2 Seater Sofa-Blue Fabric",
      "product_model": "Evie 2 Seater Sofa-Blue Fabric",
      "product_mpn": "ST-EVI002-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301324,
      "product_name": "Evie Corner Sofa Right Hand-Blue Fabric",
      "product_model": "Evie Corner Sofa Right Hand-Blue Fabric",
      "product_mpn": "ST-EVI067R-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301323,
      "product_name": "Evie 3 Seater Sofa-Blue Fabric",
      "product_model": "Evie 3 Seater Sofa-Blue Fabric",
      "product_mpn": "ST-EVI003-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301337,
      "product_name": "Inca Corner Sofa Right Hand-Cream Fabric",
      "product_model": "Inca Corner Sofa Right Hand-Cream Fabric",
      "product_mpn": "ICA075R-BKW-CRM-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301336,
      "product_name": "Inca Corner Sofa Left Hand-Cream Fabric",
      "product_model": "Inca Corner Sofa Left Hand-Cream Fabric",
      "product_mpn": "ICA075L-BKW-CRM-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301335,
      "product_name": "Inca Storage Footstool-Cream Fabric",
      "product_model": "Inca Storage Footstool-Cream Fabric",
      "product_mpn": "ICA010-BKW-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301334,
      "product_name": "Inca Armchair-Cream Fabric",
      "product_model": "Inca Armchair-Cream Fabric",
      "product_mpn": "ICA001-BKW-CRM-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301333,
      "product_name": "Inca 2 Seater Sofa-Cream Fabric",
      "product_model": "Inca 2 Seater Sofa-Cream Fabric",
      "product_mpn": "ICA002-BKW-CRM-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301332,
      "product_name": "Inca 3 Seater Sofa-Cream Fabric",
      "product_model": "Inca 3 Seater Sofa-Cream Fabric",
      "product_mpn": "ICA003-BKW-CRM-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301343,
      "product_name": "Inca Corner Sofa Right Hand-Beige Fabric",
      "product_model": "Inca Corner Sofa Right Hand-Beige Fabric",
      "product_mpn": "ICA075R-BKW-BEI-RST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301342,
      "product_name": "Inca Corner Sofa Left Hand-Beige Fabric",
      "product_model": "Inca Corner Sofa Left Hand-Beige Fabric",
      "product_mpn": "ICA075L-BKW-BEI-RST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301341,
      "product_name": "Inca Storage Footstool-Beige Fabric",
      "product_model": "Inca Storage Footstool-Beige Fabric",
      "product_mpn": "ICA010-BKW-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301340,
      "product_name": "Inca Armchair-Beige Fabric",
      "product_model": "Inca Armchair-Beige Fabric",
      "product_mpn": "ICA001-BKW-BEI-RST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301339,
      "product_name": "Inca 2 Seater Sofa-Beige Fabric",
      "product_model": "Inca 2 Seater Sofa-Beige Fabric",
      "product_mpn": "ICA002-BKW-BEI-RST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301338,
      "product_name": "Inca 3 Seater Sofa-Beige Fabric",
      "product_model": "Inca 3 Seater Sofa-Beige Fabric",
      "product_mpn": "ICA003-BKW-BEI-RST",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301349,
      "product_name": "Inca Corner Sofa Right Hand-Charcoal Fabric",
      "product_model": "Inca Corner Sofa Right Hand-Charcoal Fabric",
      "product_mpn": "ICA075R-BKW-CHA-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301348,
      "product_name": "Inca Corner Sofa Left Hand-Charcoal Fabric",
      "product_model": "Inca Corner Sofa Left Hand-Charcoal Fabric",
      "product_mpn": "ICA075L-BKW-CHA-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301347,
      "product_name": "Inca Storage Footstool-Charcoal Fabric",
      "product_model": "Inca Storage Footstool-Charcoal Fabric",
      "product_mpn": "ICA010-BKW-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301346,
      "product_name": "Inca Armchair-Charcoal Fabric",
      "product_model": "Inca Armchair-Charcoal Fabric",
      "product_mpn": "ICA001-BKW-CHA-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301345,
      "product_name": "Inca 2 Seater Sofa -Charcoal Fabric",
      "product_model": "Inca 2 Seater Sofa -Charcoal Fabric",
      "product_mpn": "ICA002-BKW-CHA-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301344,
      "product_name": "Inca 3 Seater Sofa-Charcoal Fabric",
      "product_model": "Inca 3 Seater Sofa-Charcoal Fabric",
      "product_mpn": "ICA003-BKW-CHA-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301361,
      "product_name": "Malvern Modular 3 Seat Left Hand Corner Sofa-Brown Fabric",
      "product_model": "Malvern Modular 3 Seat Left Hand Corner Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-6U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301360,
      "product_name": "Malvern Modular 4 Seat Left Hand Corner Sofa-Brown Fabric",
      "product_model": "Malvern Modular 4 Seat Left Hand Corner Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-4U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301359,
      "product_name": "Malvern Half Moon Footstool-Brown Fabric",
      "product_model": "Malvern Half Moon Footstool-Brown Fabric",
      "product_mpn": "MLV024-CPD-BRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301358,
      "product_name": "Malvern Storage Footstool-Brown Fabric",
      "product_model": "Malvern Storage Footstool-Brown Fabric",
      "product_mpn": "MLV010-CPD-BRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301357,
      "product_name": "Malvern Modular 2 Seat Sofa-Brown Fabric",
      "product_model": "Malvern Modular 2 Seat Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-8U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301356,
      "product_name": "Malvern Swivel Chair-Brown Fabric",
      "product_model": "Malvern Swivel Chair-Brown Fabric",
      "product_mpn": "MLV023-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301355,
      "product_name": "Malvern Modular 3 Seat Right Hand Corner Sofa-Brown Fabric",
      "product_model": "Malvern Modular 3 Seat Right Hand Corner Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-7U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301354,
      "product_name": "Malvern Modular 3 Seat Corner Sofa-Brown Fabric",
      "product_model": "Malvern Modular 3 Seat Corner Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-1U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301353,
      "product_name": "Malvern Modular 4 Seat Right Hand Corner Sofa-Brown Fabric",
      "product_model": "Malvern Modular 4 Seat Right Hand Corner Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-5U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301352,
      "product_name": "Malvern Modular 4 Seat Corner Sofa-Brown Fabric",
      "product_model": "Malvern Modular 4 Seat Corner Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-2U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301351,
      "product_name": "Malvern Modular 5 Seat Corner Sofa-Brown Fabric",
      "product_model": "Malvern Modular 5 Seat Corner Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-3U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301350,
      "product_name": "Malvern Modular 3 Seat Sofa-Brown Fabric",
      "product_model": "Malvern Modular 3 Seat Sofa-Brown Fabric",
      "product_mpn": "MLV-GROUP-9U-CPD-BRN-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301373,
      "product_name": "Malvern Modular 3 Seat Left Hand Corner Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 3 Seat Left Hand Corner Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-6U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301372,
      "product_name": "Malvern Modular 4 Seat Left Hand Corner Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 4 Seat Left Hand Corner Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-4U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301371,
      "product_name": "Malvern Half Moon Footstool-Charcoal Fabric",
      "product_model": "Malvern Half Moon Footstool-Charcoal Fabric",
      "product_mpn": "MLV024-CPD-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301370,
      "product_name": "Malvern Storage Footstool-Charcoal Fabric",
      "product_model": "Malvern Storage Footstool-Charcoal Fabric",
      "product_mpn": "MLV010-CPD-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301369,
      "product_name": "Malvern Modular 2 Seat Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 2 Seat Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-8U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301368,
      "product_name": "Malvern Swivel Chair-Charcoal Fabric",
      "product_model": "Malvern Swivel Chair-Charcoal Fabric",
      "product_mpn": "MLV023-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301367,
      "product_name": "Malvern Modular 3 Seat Right Hand Corner Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 3 Seat Right Hand Corner Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-7U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301366,
      "product_name": "Malvern Modular 3 Seat Corner Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 3 Seat Corner Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-1U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301365,
      "product_name": "Malvern Modular 4 Seat Right Hand Corner Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 4 Seat Right Hand Corner Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-5U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301364,
      "product_name": "Malvern Modular 4 Seat Corner Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 4 Seat Corner Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-2U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301363,
      "product_name": "Malvern Modular 5 Seat Corner Sofa0Charcoal Fabric",
      "product_model": "Malvern Modular 5 Seat Corner Sofa0Charcoal Fabric",
      "product_mpn": "MLV-GROUP-3U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301362,
      "product_name": "Malvern Modular 3 Seat Sofa-Charcoal Fabric",
      "product_model": "Malvern Modular 3 Seat Sofa-Charcoal Fabric",
      "product_mpn": "MLV-GROUP-9U-CPD-CHA-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301385,
      "product_name": "Malvern Modular 3 Seat Left Hand Corner Sofa-Silver Fabric",
      "product_model": "Malvern Modular 3 Seat Left Hand Corner Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-6U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301384,
      "product_name": "Malvern Modular 4 Seat Left Hand Corner Sofa-Silver Fabric",
      "product_model": "Malvern Modular 4 Seat Left Hand Corner Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-4U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301383,
      "product_name": "Malvern Half Moon Footstool-Silver Fabric",
      "product_model": "Malvern Half Moon Footstool-Silver Fabric",
      "product_mpn": "ST-MLV024-CPD-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301382,
      "product_name": "Malvern Storage Footstool-Silver Fabric",
      "product_model": "Malvern Storage Footstool-Silver Fabric",
      "product_mpn": "ST-MLV010-CPD-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301381,
      "product_name": "Malvern Modular 2 Seat Sofa-Silver Fabric",
      "product_model": "Malvern Modular 2 Seat Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-8U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301380,
      "product_name": "Malvern Swivel Chair-Silver Fabric",
      "product_model": "Malvern Swivel Chair-Silver Fabric",
      "product_mpn": "ST-MLV023-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301379,
      "product_name": "Malvern Modular 3 Seat Right Hand Corner Sofa-Silver Fabric",
      "product_model": "Malvern Modular 3 Seat Right Hand Corner Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-7U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301378,
      "product_name": "Malvern Modular 3 Seat Corner Sofa-Silver Fabric",
      "product_model": "Malvern Modular 3 Seat Corner Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-1U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301377,
      "product_name": "Malvern Modular 4 Seat Right Hand Corner Sofa-Silver Fabric",
      "product_model": "Malvern Modular 4 Seat Right Hand Corner Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-5U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301376,
      "product_name": "Malvern Modular 4 Seat Corner Sofa-Silver Fabric",
      "product_model": "Malvern Modular 4 Seat Corner Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-2U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301375,
      "product_name": "Malvern Modular 5 Seat Corner Sofa-Silver Fabric",
      "product_model": "Malvern Modular 5 Seat Corner Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-3U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301374,
      "product_name": "Malvern Modular 3 Seat Sofa-Silver Fabric",
      "product_model": "Malvern Modular 3 Seat Sofa-Silver Fabric",
      "product_mpn": "ST-MLV-GROUP-9U-CPD-SIL-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 3200100006,
      "product_name": "LAURA Avatar",
      "product_model": "LAURA Avatar",
      "product_mpn": "",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 700600026,
      "product_name": "Howdens Softwood Ledged and Braced external door",
      "product_model": "Howdens Softwood Ledged and Braced external door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600025,
      "product_name": "Howdens Hemlock Softwood stile and rail external door",
      "product_model": "Howdens Hemlock Softwood stile and rail external door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600024,
      "product_name": "Howdens Regent External Door",
      "product_model": "Howdens Regent External Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600023,
      "product_name": "Howdens Dordogne external door",
      "product_model": "Howdens Dordogne external door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600022,
      "product_name": "Howdens Bosworth Clear glazed external door",
      "product_model": "Howdens Bosworth Clear glazed external door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600021,
      "product_name": "Howdens Richmond Frosted Glazed External Door",
      "product_model": "Howdens Richmond Frosted Glazed External Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600020,
      "product_name": "Howdens Mondello H2XG External Clear Glazed door",
      "product_model": "Howdens Mondello H2XG External Clear Glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600019,
      "product_name": "Howdens Carolina Clear Glazed External Door",
      "product_model": "Howdens Carolina Clear Glazed External Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600018,
      "product_name": "Howdens Dordogne oak frosted glazed external door",
      "product_model": "Howdens Dordogne oak frosted glazed external door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600017,
      "product_name": "Howdens Cottage Oak Frosted Glazed External Door",
      "product_model": "Howdens Cottage Oak Frosted Glazed External Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600016,
      "product_name": "Howdens Linear Frosted Glazed External Door",
      "product_model": "Howdens Linear Frosted Glazed External Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600015,
      "product_name": "Howdens Richmond Primed Frosted Glazed External Door",
      "product_model": "Howdens Richmond Primed Frosted Glazed External Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600014,
      "product_name": "Howdens Dunsfold Plywood Flush Georgian Wired Glazed Door",
      "product_model": "Howdens Dunsfold Plywood Flush Georgian Wired Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600013,
      "product_name": "Howdens Solid Core Blank Plywood Flush Door",
      "product_model": "Howdens Solid Core Blank Plywood Flush Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600012,
      "product_name": "Howdens Dunsfold plywood flush external door",
      "product_model": "Howdens Dunsfold plywood flush external door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600011,
      "product_name": "Howdens Spey Plywood clear glazed flush door",
      "product_model": "Howdens Spey Plywood clear glazed flush door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600010,
      "product_name": "Howdens Llandow oak foil 16G clear glazed flush door",
      "product_model": "Howdens Llandow oak foil 16G clear glazed flush door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600009,
      "product_name": "Howdens Blyton Oak Veneer Clear Glazed Door",
      "product_model": "Howdens Blyton Oak Veneer Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600008,
      "product_name": "Howdens Spey Plywood Georgian wired glazed flush door",
      "product_model": "Howdens Spey Plywood Georgian wired glazed flush door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600005,
      "product_name": "Howdens Heyford Ash Veneer Pre-finished flush door",
      "product_model": "Howdens Heyford Ash Veneer Pre-finished flush door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600003,
      "product_name": "Howdens Llandow Oak Foil Flush Door",
      "product_model": "Howdens Llandow Oak Foil Flush Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600002,
      "product_name": "Howdens Blyton Oak Veneer Flush door",
      "product_model": "Howdens Blyton Oak Veneer Flush door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600001,
      "product_name": "Howdens Buckmore Light Grey Oak Foil Flush Door",
      "product_model": "Howdens Buckmore Light Grey Oak Foil Flush Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600000,
      "product_name": "Howdens spey Plywood flush door",
      "product_model": "Howdens spey Plywood flush door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600077,
      "product_name": "Howdens Worcester white primed 3 panel clear glazed door",
      "product_model": "Howdens Worcester white primed 3 panel clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600084,
      "product_name": "Howdens Linear White Primed Door",
      "product_model": "Howdens Linear White Primed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600083,
      "product_name": "Howdens Dordogne White primed door",
      "product_model": "Howdens Dordogne White primed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600082,
      "product_name": "Howdens Shaker White Primed 4 panel door",
      "product_model": "Howdens Shaker White Primed 4 panel door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600081,
      "product_name": "Howdens Worcester White Primed 3 panel door",
      "product_model": "Howdens Worcester White Primed 3 panel door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600080,
      "product_name": "Howdens Linear White Primed Clear glazed door",
      "product_model": "Howdens Linear White Primed Clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600079,
      "product_name": "Howdens Pattern 10 White Primed 1 panel clear glazed door",
      "product_model": "Howdens Pattern 10 White Primed 1 panel clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600078,
      "product_name": "Howdens Dordogne White Primed Clear Glazed Door",
      "product_model": "Howdens Dordogne White Primed Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600075,
      "product_name": "Howdens Pembrey Clear pine 4 panel softwood door",
      "product_model": "Howdens Pembrey Clear pine 4 panel softwood door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600074,
      "product_name": "Howdens Pembrey Clear Pine 4 Panel softwood frosted glazed door",
      "product_model": "Howdens Pembrey Clear Pine 4 Panel softwood frosted glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600073,
      "product_name": "Howdens colonial white grained 6 panel moulded developer door",
      "product_model": "Howdens colonial white grained 6 panel moulded developer door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600072,
      "product_name": "Howdens Metro white smooth moulded door",
      "product_model": "Howdens Metro white smooth moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600071,
      "product_name": "Howdens linear white smooth moulded door",
      "product_model": "Howdens linear white smooth moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600070,
      "product_name": "Howdens shaker white smooth 4 panel moulded door",
      "product_model": "Howdens shaker white smooth 4 panel moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600069,
      "product_name": "Howdens donnington white smooth 4 panel moulded door",
      "product_model": "Howdens donnington white smooth 4 panel moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 8000206,
      "product_name": "Dell Inspiron 13 (5310) - ANIMATED",
      "product_model": "Dell Inspiron 13 (5310) - ANIMATED",
      "product_mpn": "Dell Inspiron 13 (5310) - ANIMATED",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 12400027,
      "product_name": "STUDIO SERIES DESK MAT Lavender - Keyboards & Combos Other - Baltic",
      "product_model": "STUDIO SERIES DESK MAT Lavender - Keyboards & Combos Other - Baltic",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400026,
      "product_name": "STUDIO SERIES DESK MAT Darker Rose - Keyboards & Combos Other - Baltic",
      "product_model": "STUDIO SERIES DESK MAT Darker Rose - Keyboards & Combos Other - Baltic",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400025,
      "product_name": "STUDIO SERIES DESK MAT Mid Grey - Keyboards & Combos Other - Baltic",
      "product_model": "STUDIO SERIES DESK MAT Mid Grey - Keyboards & Combos Other - Baltic",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400024,
      "product_name": "STUDIO SERIES MOUSE PAD Blue Grey - Pointing Devices Other - Sulu",
      "product_model": "STUDIO SERIES MOUSE PAD Blue Grey - Pointing Devices Other - Sulu",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400023,
      "product_name": "STUDIO SERIES MOUSE PAD Darker Rose - Pointing Devices Other - Sulu",
      "product_model": "STUDIO SERIES MOUSE PAD Darker Rose - Pointing Devices Other - Sulu",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 6800037,
      "product_name": "Z607 Black - 5 1 System - Merida",
      "product_model": "Z607 Black - 5 1 System - Merida",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 12400022,
      "product_name": "STUDIO SERIES MOUSE PAD Graphite - Pointing Devices Other - Sulu",
      "product_model": "STUDIO SERIES MOUSE PAD Graphite - Pointing Devices Other - Sulu",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 6800036,
      "product_name": "WONDERBOOM 2 Deep Space Black - Mobile Speakers ",
      "product_model": "WONDERBOOM 2 Deep Space Black - Mobile Speakers ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 1000014,
      "product_name": "StreamCam Graphite - Streaming Cam - Dali ",
      "product_model": "StreamCam Graphite - Streaming Cam - Dali ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 6900095,
      "product_name": "G840 Lol 2 - Gaming Mice - Bam Lol2 - Hexa",
      "product_model": "G840 Lol 2 - Gaming Mice - Bam Lol2 - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 11700014,
      "product_name": "G923 XBOX Black - Gaming Steering Wheels - Piquet ",
      "product_model": "G923 XBOX Black - Gaming Steering Wheels - Piquet ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 11700013,
      "product_name": "G29 PS Black - Gaming Steering Wheels - Daylight Sony ",
      "product_model": "G29 PS Black - Gaming Steering Wheels - Daylight Sony ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 6900094,
      "product_name": "PRO GAMING KEYBOARD Lol 2 - Gaming Keyboard - Dewey",
      "product_model": "PRO GAMING KEYBOARD Lol 2 - Gaming Keyboard - Dewey",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900093,
      "product_name": "K380 Rose - Cordless Keyboards - Wkb Innsbruck ",
      "product_model": "K380 Rose - Cordless Keyboards - Wkb Innsbruck ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900092,
      "product_name": "MK470 Graphite - Cordless Combos - Wcb Samui",
      "product_model": "MK470 Graphite - Cordless Combos - Wcb Samui",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900091,
      "product_name": "K380 Offwhite - Cordless Keyboards - Wkb Innsbruck",
      "product_model": "K380 Offwhite - Cordless Keyboards - Wkb Innsbruck",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900090,
      "product_name": "K380 Dark Grey - Cordless Keyboards - Wkb Innsbruck ",
      "product_model": "K380 Dark Grey - Cordless Keyboards - Wkb Innsbruck ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900089,
      "product_name": "PRO WIRELESS Lol 2 - Gaming Mice - Footloose",
      "product_model": "PRO WIRELESS Lol 2 - Gaming Mice - Footloose",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900088,
      "product_name": "G305 Lilac - Gaming Mice - Recoil",
      "product_model": "G305 Lilac - Gaming Mice - Recoil",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900087,
      "product_name": "G305 Blue - Gaming Mice - Recoil",
      "product_model": "G305 Blue - Gaming Mice - Recoil",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900086,
      "product_name": "G203 LIGHTSYNC Lilac - Gaming Mice - Karax Rainbow ",
      "product_model": "G203 LIGHTSYNC Lilac - Gaming Mice - Karax Rainbow ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900085,
      "product_name": "G203 LIGHTSYNC Black - Gaming Mice - Karax Rainbow",
      "product_model": "G203 LIGHTSYNC Black - Gaming Mice - Karax Rainbow",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900084,
      "product_name": "G203 LIGHTSYNC Blue - Gaming Mice - Karax Rainbow",
      "product_model": "G203 LIGHTSYNC Blue - Gaming Mice - Karax Rainbow",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900083,
      "product_name": "PEBBLE M350 Graphite - Cordless Mice - Turbot ",
      "product_model": "PEBBLE M350 Graphite - Cordless Mice - Turbot ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900082,
      "product_name": "G604 LIGHTSPEED Black - Gaming Mice - Armadillo",
      "product_model": "G604 LIGHTSPEED Black - Gaming Mice - Armadillo",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900081,
      "product_name": "G502 LIGHTSPEED Black - Gaming Mice - Stalker",
      "product_model": "G502 LIGHTSPEED Black - Gaming Mice - Stalker",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900080,
      "product_name": "G305 Black - Gaming Mice - Recoil - Hexa",
      "product_model": "G305 Black - Gaming Mice - Recoil - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900079,
      "product_name": "MX KEYS Graphite - Cordless Keyboards - Wkb Foster - Hexa",
      "product_model": "MX KEYS Graphite - Cordless Keyboards - Wkb Foster - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 700200031,
      "product_name": "Large Dresser Natural Oak",
      "product_model": "Large Dresser Natural Oak",
      "product_mpn": "RMS016_RMS025",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200030,
      "product_name": "Blanket Box-Natural Oak",
      "product_model": "Blanket Box-Natural Oak",
      "product_mpn": "RMS006",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500014,
      "product_name": "1 Drawer Bedside Table Natural Oak",
      "product_model": "1 Drawer Bedside Table Natural Oak",
      "product_mpn": "RMS008",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700200029,
      "product_name": "Small Dresser Natural Oak",
      "product_model": "Small Dresser Natural Oak",
      "product_mpn": "RMS022_RMS023",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 1000200057,
      "product_name": "Super King Size Bed-Natural Oak",
      "product_model": "Super King Size Bed-Natural Oak",
      "product_mpn": "RMS015",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700200028,
      "product_name": "Small Bookcase-Natural Oak",
      "product_model": "Small Bookcase-Natural Oak",
      "product_mpn": "RMS021",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700800000,
      "product_name": "Dressing Table Stool-Natural Oak",
      "product_model": "Dressing Table Stool-Natural Oak",
      "product_mpn": "RMS012",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 1000200056,
      "product_name": "King Size Bed Natural Oak",
      "product_model": "King Size Bed Natural Oak",
      "product_mpn": "RMS014",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200055,
      "product_name": "Double Bed-Natural Oak",
      "product_model": "Double Bed-Natural Oak",
      "product_mpn": "RMS013",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700200041,
      "product_name": "Small Dresser-Light Grey Glazed Oak",
      "product_model": "Small Dresser-Light Grey Glazed Oak",
      "product_mpn": "WLO003-WLO004",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300031,
      "product_name": "Display Cabinet-Light Grey Glazed Oak",
      "product_model": "Display Cabinet-Light Grey Glazed Oak",
      "product_mpn": "WLO014",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700200040,
      "product_name": "Small Sideboard-Light Grey Glazed Oak",
      "product_model": "Small Sideboard-Light Grey Glazed Oak",
      "product_mpn": "WLO003",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200039,
      "product_name": "Large Dresser-Light Grey Glazed Oak",
      "product_model": "Large Dresser-Light Grey Glazed Oak",
      "product_mpn": "WLO001-WLO002",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200038,
      "product_name": "Small TV Unit-Light Grey Glazed Oak",
      "product_model": "Small TV Unit-Light Grey Glazed Oak",
      "product_mpn": "WLO006",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300030,
      "product_name": "6 Drawer Chest-Light Grey Glazed Oak",
      "product_model": "6 Drawer Chest-Light Grey Glazed Oak",
      "product_mpn": "WLO118",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2500020,
      "product_name": "Console Table Light Grey Glazed Oak",
      "product_model": "Console Table Light Grey Glazed Oak",
      "product_mpn": "WLO011",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 1000200061,
      "product_name": "Super King Size Bed-Light Grey Glazed Oak",
      "product_model": "Super King Size Bed-Light Grey Glazed Oak",
      "product_mpn": "WLO113",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 2500019,
      "product_name": "Side Table-Light Grey Glazed Oak",
      "product_model": "Side Table-Light Grey Glazed Oak",
      "product_mpn": "WLO009",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500018,
      "product_name": "1 Drawer Bedside Table-Light Grey Glazed Oak",
      "product_model": "1 Drawer Bedside Table-Light Grey Glazed Oak",
      "product_mpn": "WLO107",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700200037,
      "product_name": "Tall Bookcase-Light Grey Glazed Oak",
      "product_model": "Tall Bookcase-Light Grey Glazed Oak",
      "product_mpn": "WLO012",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 1000200060,
      "product_name": "Double Bed-Light Grey Glazed Oak",
      "product_model": "Double Bed-Light Grey Glazed Oak",
      "product_mpn": "WLO111",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 2500017,
      "product_name": "Nest of 2 Tables-Light Grey Glazed Oak",
      "product_model": "Nest of 2 Tables-Light Grey Glazed Oak",
      "product_mpn": "WLO010",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700800001,
      "product_name": "Dressing Table Stool-Light Grey Glazed Oak",
      "product_model": "Dressing Table Stool-Light Grey Glazed Oak",
      "product_mpn": "WLO116",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700200036,
      "product_name": "Large TV Unit-Light Grey Glazed Oak",
      "product_model": "Large TV Unit-Light Grey Glazed Oak",
      "product_mpn": "WLO005",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 1000200059,
      "product_name": "Single Bed-Light Grey Glazed Oak",
      "product_model": "Single Bed-Light Grey Glazed Oak",
      "product_mpn": "WLO110",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200058,
      "product_name": "King Size Bed Light Grey Glazed Oak",
      "product_model": "King Size Bed Light Grey Glazed Oak",
      "product_mpn": "WLO112",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700200035,
      "product_name": "Small Bookcase-Light Grey Glazed Oak",
      "product_model": "Small Bookcase-Light Grey Glazed Oak",
      "product_mpn": "WLO013",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500016,
      "product_name": "Coffee Table-Light Grey Glazed Oak",
      "product_model": "Coffee Table-Light Grey Glazed Oak",
      "product_mpn": "WLO008",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700900001,
      "product_name": "Small Desk-Light Grey Glazed Oak",
      "product_model": "Small Desk-Light Grey Glazed Oak",
      "product_mpn": "WLO015",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700900000,
      "product_name": "Large Desk-Light Grey Glazed Oak",
      "product_model": "Large Desk-Light Grey Glazed Oak",
      "product_mpn": "WLO016",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700200034,
      "product_name": "Corner TV Unit-Light Grey Glazed Oak",
      "product_model": "Corner TV Unit-Light Grey Glazed Oak",
      "product_mpn": "WLO007",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300029,
      "product_name": "7 Drawer Chest-Light Grey Glazed Oak",
      "product_model": "7 Drawer Chest-Light Grey Glazed Oak",
      "product_mpn": "WLO104",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2200020,
      "product_name": "Triple Wardrobe-Light Grey Glazed Oak",
      "product_model": "Triple Wardrobe-Light Grey Glazed Oak",
      "product_mpn": "WLO102",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200033,
      "product_name": "Dressing Table-Light Grey Glazed Oak",
      "product_model": "Dressing Table-Light Grey Glazed Oak",
      "product_mpn": "WLO115",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300028,
      "product_name": "5 Drawer Tallboy-Light Grey Glazed Oak",
      "product_model": "5 Drawer Tallboy-Light Grey Glazed Oak",
      "product_mpn": "WLO105",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300027,
      "product_name": "5 Drawer Chest-Light Grey Glazed Oak",
      "product_model": "5 Drawer Chest-Light Grey Glazed Oak",
      "product_mpn": "WLO103",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2200019,
      "product_name": "Double Wardrobe-Light Grey Glazed Oak",
      "product_model": "Double Wardrobe-Light Grey Glazed Oak",
      "product_mpn": "WLO101",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200032,
      "product_name": "Large Sideboard-Light Grey Glazed Oak",
      "product_model": "Large Sideboard-Light Grey Glazed Oak",
      "product_mpn": "WLO001",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500015,
      "product_name": "2 Drawer Bedside Table-Light Grey Glazed Oak",
      "product_model": "2 Drawer Bedside Table-Light Grey Glazed Oak",
      "product_mpn": "WLO108",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 1000200063,
      "product_name": "King Size Bed - Rustic Oak",
      "product_model": "King Size Bed - Rustic Oak",
      "product_mpn": "King Size Bed - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700200049,
      "product_name": "3 Drawer Dressing Table Set - Rustic Oak",
      "product_model": "3 Drawer Dressing Table Set - Rustic Oak",
      "product_mpn": "3 Drawer Dressing Table Set - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200048,
      "product_name": "Small TV Unit - Rustic Oak",
      "product_model": "Small TV Unit - Rustic Oak",
      "product_mpn": "Small TV Unit - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 1000200062,
      "product_name": "Double Bed - Rustic Oak",
      "product_model": "Double Bed - Rustic Oak",
      "product_mpn": "Double Bed - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700700001,
      "product_name": "Blanket Box - Rustic Oak",
      "product_model": "Blanket Box - Rustic Oak",
      "product_mpn": "Blanket Box - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STORAGE BENCHES"
    },
    {
      "product_arid": 700300036,
      "product_name": "5 Drawer Tallboy - Rustic Oak",
      "product_model": "5 Drawer Tallboy - Rustic Oak",
      "product_mpn": "5 Drawer Tallboy - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700900002,
      "product_name": "Desk - Rustic Oak",
      "product_model": "Desk - Rustic Oak",
      "product_mpn": "Desk - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700200047,
      "product_name": "Large Dresser - Rustic Oak",
      "product_model": "Large Dresser - Rustic Oak",
      "product_mpn": "Large Dresser - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700800003,
      "product_name": "Square Stool - Rustic Oak",
      "product_model": "Square Stool - Rustic Oak",
      "product_mpn": "Square Stool - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700200046,
      "product_name": "Display Cabinet - Rustic Oak",
      "product_model": "Display Cabinet - Rustic Oak",
      "product_mpn": "Display Cabinet - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2500028,
      "product_name": "Side Table - Rustic Oak",
      "product_model": "Side Table - Rustic Oak",
      "product_mpn": "Side Table - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500027,
      "product_name": "1 Drawer Bedside Table - Rustic Oak",
      "product_model": "1 Drawer Bedside Table - Rustic Oak",
      "product_mpn": "1 Drawer Bedside Table - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700800002,
      "product_name": "Dressing Table Stool - Rustic Oak",
      "product_model": "Dressing Table Stool - Rustic Oak",
      "product_mpn": "Dressing Table Stool - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700200045,
      "product_name": "Small Dresser - Rustic Oak",
      "product_model": "Small Dresser - Rustic Oak",
      "product_mpn": "Small Dresser - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200044,
      "product_name": "Large TV Unit - Rustic Oak",
      "product_model": "Large TV Unit - Rustic Oak",
      "product_mpn": "Large TV Unit - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200043,
      "product_name": "Small Bookcase - Rustic Oak",
      "product_model": "Small Bookcase - Rustic Oak",
      "product_mpn": "Small Bookcase - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500026,
      "product_name": "3 Drawer Dressing Table - Rustic Oak",
      "product_model": "3 Drawer Dressing Table - Rustic Oak",
      "product_mpn": "3 Drawer Dressing Table - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500025,
      "product_name": "3 Drawer Bedside Table - Rustic Oak",
      "product_model": "3 Drawer Bedside Table - Rustic Oak",
      "product_mpn": "3 Drawer Bedside Table - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500024,
      "product_name": "Corner TV Unit - Rustic Oak",
      "product_model": "Corner TV Unit - Rustic Oak",
      "product_mpn": "Corner TV Unit - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500023,
      "product_name": "4 Drawer Coffee Table - Rustic Oak",
      "product_model": "4 Drawer Coffee Table - Rustic Oak",
      "product_mpn": "4 Drawer Coffee Table - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500022,
      "product_name": "Nest of Tables - Rustic Oak",
      "product_model": "Nest of Tables - Rustic Oak",
      "product_mpn": "Nest of Tables - Rustic Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500021,
      "product_name": "Console Table-Rustic Oak",
      "product_model": "Console Table-Rustic Oak",
      "product_mpn": "RUS20",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700300035,
      "product_name": "7 Drawer Chest-Rustic Oak",
      "product_model": "7 Drawer Chest-Rustic Oak",
      "product_mpn": "RUS08",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2200022,
      "product_name": "Triple Wardrobe-Rustic Oak",
      "product_model": "Triple Wardrobe-Rustic Oak",
      "product_mpn": "RUS03_Top1_Top2_Base",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2200021,
      "product_name": "Double Wardrobe-Rustic Oak",
      "product_model": "Double Wardrobe-Rustic Oak",
      "product_mpn": "RUS01",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200042,
      "product_name": "Tall Bookcase-Rustic Oak",
      "product_model": "Tall Bookcase-Rustic Oak",
      "product_mpn": "RUS21",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300034,
      "product_name": "5 Drawer Chest-Rustic Oak",
      "product_model": "5 Drawer Chest-Rustic Oak",
      "product_mpn": "RUS06",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300033,
      "product_name": "Small Sideboard-Rustic Oak",
      "product_model": "Small Sideboard-Rustic Oak",
      "product_mpn": "RUS23",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300032,
      "product_name": "Large Sideboard-Rustic Oak",
      "product_model": "Large Sideboard-Rustic Oak",
      "product_mpn": "RUS17",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700200059,
      "product_name": "Large Dresser - White Painted Oak",
      "product_model": "Large Dresser - White Painted Oak",
      "product_mpn": "Large Dresser - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700700002,
      "product_name": "Blanket Box - White Painted Oak",
      "product_model": "Blanket Box - White Painted Oak",
      "product_mpn": "Blanket Box - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STORAGE BENCHES"
    },
    {
      "product_arid": 700200058,
      "product_name": "Dressing Table - White Painted Oak",
      "product_model": "Dressing Table - White Painted Oak",
      "product_mpn": "Dressing Table - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500034,
      "product_name": "1 Drawer Bedside Table - White Painted Oak",
      "product_model": "1 Drawer Bedside Table - White Painted Oak",
      "product_mpn": "1 Drawer Bedside Table - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700200057,
      "product_name": "Small Dresser - White Painted Oak",
      "product_model": "Small Dresser - White Painted Oak",
      "product_mpn": "Small Dresser - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500033,
      "product_name": "Side Table - White Painted Oak",
      "product_model": "Side Table - White Painted Oak",
      "product_mpn": "Side Table - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700300040,
      "product_name": "5 Drawer Chest - White Painted Oak",
      "product_model": "5 Drawer Chest - White Painted Oak",
      "product_mpn": "5 Drawer Chest - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700200056,
      "product_name": "Small TV Unit - White Painted Oak",
      "product_model": "Small TV Unit - White Painted Oak",
      "product_mpn": "Small TV Unit - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200055,
      "product_name": "Large TV Unit - White Painted Oak",
      "product_model": "Large TV Unit - White Painted Oak",
      "product_mpn": "Large TV Unit - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200054,
      "product_name": "Small Sideboard - White Painted Oak",
      "product_model": "Small Sideboard - White Painted Oak",
      "product_mpn": "Small Sideboard - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200053,
      "product_name": "Corner TV Unit - White Painted Oak",
      "product_model": "Corner TV Unit - White Painted Oak",
      "product_mpn": "Corner TV Unit - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300039,
      "product_name": "5 Drawer Tallboy - White Painted Oak",
      "product_model": "5 Drawer Tallboy - White Painted Oak",
      "product_mpn": "5 Drawer Tallboy - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700200052,
      "product_name": "Small Bookcase - White Painted Oak",
      "product_model": "Small Bookcase - White Painted Oak",
      "product_mpn": "Small Bookcase - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700800004,
      "product_name": "Dressing Table Stool - White Painted Oak",
      "product_model": "Dressing Table Stool - White Painted Oak",
      "product_mpn": "Dressing Table Stool - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 1000200065,
      "product_name": "King Size Bed - White Painted Oak",
      "product_model": "King Size Bed - White Painted Oak",
      "product_mpn": "King Size Bed - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 2500032,
      "product_name": "Console Table - White Painted Oak",
      "product_model": "Console Table - White Painted Oak",
      "product_mpn": "Console Table - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700300038,
      "product_name": "7 Drawer Chest - White Painted Oak",
      "product_model": "7 Drawer Chest - White Painted Oak",
      "product_mpn": "7 Drawer Chest - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2500031,
      "product_name": "2 Drawer Bedside Table - White Painted Oak",
      "product_model": "2 Drawer Bedside Table - White Painted Oak",
      "product_mpn": "2 Drawer Bedside Table - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500030,
      "product_name": "Nest of Tables - White Painted Oak",
      "product_model": "Nest of Tables - White Painted Oak",
      "product_mpn": "Nest of Tables - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 1000200064,
      "product_name": "Double Bed - White Painted Oak",
      "product_model": "Double Bed - White Painted Oak",
      "product_mpn": "Double Bed - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700900003,
      "product_name": "Desk - White Painted Oak",
      "product_model": "Desk - White Painted Oak",
      "product_mpn": "Desk - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700200051,
      "product_name": "Tall Bookcase - White Painted Oak",
      "product_model": "Tall Bookcase - White Painted Oak",
      "product_mpn": "Tall Bookcase - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300037,
      "product_name": "Display Cabinet - White Painted Oak",
      "product_model": "Display Cabinet - White Painted Oak",
      "product_mpn": "Display Cabinet - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2200024,
      "product_name": "Triple Wardrobe - White Painted Oak",
      "product_model": "Triple Wardrobe - White Painted Oak",
      "product_mpn": "Triple Wardrobe - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2500029,
      "product_name": "4 Drawer Coffee Table - White Painted Oak",
      "product_model": "4 Drawer Coffee Table - White Painted Oak",
      "product_mpn": "4 Drawer Coffee Table - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2200023,
      "product_name": "Double Wardrobe - White Painted Oak",
      "product_model": "Double Wardrobe - White Painted Oak",
      "product_mpn": "Double Wardrobe - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200050,
      "product_name": "Large Sideboard - White Painted Oak",
      "product_model": "Large Sideboard - White Painted Oak",
      "product_mpn": "Large Sideboard - White Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500045,
      "product_name": "Bedside Table - Natural Oak",
      "product_model": "Bedside Table - Natural Oak",
      "product_mpn": "Bedside Table - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700200077,
      "product_name": "Small Dresser - Natural Oak",
      "product_model": "Small Dresser - Natural Oak",
      "product_mpn": "Small Dresser - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300049,
      "product_name": "Display Cabinet - Natural Oak",
      "product_model": "Display Cabinet - Natural Oak",
      "product_mpn": "Display Cabinet - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700200076,
      "product_name": "Small Sideboard - Natural Oak",
      "product_model": "Small Sideboard - Natural Oak",
      "product_mpn": "Small Sideboard - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300048,
      "product_name": "Large Dresser - Natural Oak",
      "product_model": "Large Dresser - Natural Oak",
      "product_mpn": "Large Dresser - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300047,
      "product_name": "6 Drawer Chest - Natural Oak",
      "product_model": "6 Drawer Chest - Natural Oak",
      "product_mpn": "6 Drawer Chest - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2500044,
      "product_name": "Side Table - Natural Oak",
      "product_model": "Side Table - Natural Oak",
      "product_mpn": "Side Table - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500043,
      "product_name": "1 Drawer Bedside Table - Natural Oak",
      "product_model": "1 Drawer Bedside Table - Natural Oak",
      "product_mpn": "1 Drawer Bedside Table - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700200075,
      "product_name": "Tall Bookcase - Natural Oak",
      "product_model": "Tall Bookcase - Natural Oak",
      "product_mpn": "Tall Bookcase - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 1000200070,
      "product_name": "Double Bed - Natural Oak",
      "product_model": "Double Bed - Natural Oak",
      "product_mpn": "Double Bed - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700800006,
      "product_name": "Dressing Table Stool - Natural Oak",
      "product_model": "Dressing Table Stool - Natural Oak",
      "product_mpn": "Dressing Table Stool - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700200074,
      "product_name": "Large TV Unit - Natural Oak",
      "product_model": "Large TV Unit - Natural Oak",
      "product_mpn": "Large TV Unit - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 1000200069,
      "product_name": "Single Bed - Natural Oak",
      "product_model": "Single Bed - Natural Oak",
      "product_mpn": "Single Bed - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200068,
      "product_name": "King Size Bed - Natural Oak",
      "product_model": "King Size Bed - Natural Oak",
      "product_mpn": "King Size Bed - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700200073,
      "product_name": "Small Bookcase - Natural Oak",
      "product_model": "Small Bookcase - Natural Oak",
      "product_mpn": "Small Bookcase - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500042,
      "product_name": "Coffee Table - Natural Oak",
      "product_model": "Coffee Table - Natural Oak",
      "product_mpn": "Coffee Table - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700900006,
      "product_name": "Small Desk - Natural Oak",
      "product_model": "Small Desk - Natural Oak",
      "product_mpn": "Small Desk - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700900005,
      "product_name": "Large Desk - Natural Oak",
      "product_model": "Large Desk - Natural Oak",
      "product_mpn": "Large Desk - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700200072,
      "product_name": "Corner TV Unit - Natural Oak",
      "product_model": "Corner TV Unit - Natural Oak",
      "product_mpn": "Corner TV Unit - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2200028,
      "product_name": "Triple Wardrobe - Natural Oak",
      "product_model": "Triple Wardrobe - Natural Oak",
      "product_mpn": "Triple Wardrobe - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200071,
      "product_name": "Dressing Table - Natural Oak",
      "product_model": "Dressing Table - Natural Oak",
      "product_mpn": "Dressing Table - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300046,
      "product_name": "5 Drawer Tallboy - Natural Oak",
      "product_model": "5 Drawer Tallboy - Natural Oak",
      "product_mpn": "5 Drawer Tallboy - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300045,
      "product_name": "5 Drawer Chest - Natural Oak",
      "product_model": "5 Drawer Chest - Natural Oak",
      "product_mpn": "5 Drawer Chest - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2200027,
      "product_name": "Double Wardrobe - Natural Oak",
      "product_model": "Double Wardrobe - Natural Oak",
      "product_mpn": "Double Wardrobe - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200070,
      "product_name": "Large Sideboard - Natural Oak",
      "product_model": "Large Sideboard - Natural Oak",
      "product_mpn": "Large Sideboard - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500041,
      "product_name": "2 Drawer Bedside Table - Natural Oak",
      "product_model": "2 Drawer Bedside Table - Natural Oak",
      "product_mpn": "2 Drawer Bedside Table - Natural Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700200069,
      "product_name": "Large Dresser - Dark Grey Painted Oak",
      "product_model": "Large Dresser - Dark Grey Painted Oak",
      "product_mpn": "Large Dresser - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700700003,
      "product_name": "Blanket Box - Dark Grey Painted Oak",
      "product_model": "Blanket Box - Dark Grey Painted Oak",
      "product_mpn": "Blanket Box - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STORAGE BENCHES"
    },
    {
      "product_arid": 700200068,
      "product_name": "Dressing Table - Dark Grey Painted Oak",
      "product_model": "Dressing Table - Dark Grey Painted Oak",
      "product_mpn": "Dressing Table - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500040,
      "product_name": "1 Drawer Bedside Table - Dark Grey Painted Oak",
      "product_model": "1 Drawer Bedside Table - Dark Grey Painted Oak",
      "product_mpn": "1 Drawer Bedside Table - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700200067,
      "product_name": "Small Dresser - Dark Grey Painted Oak",
      "product_model": "Small Dresser - Dark Grey Painted Oak",
      "product_mpn": "Small Dresser - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 2500039,
      "product_name": "Side Table - Dark Grey Painted Oak",
      "product_model": "Side Table - Dark Grey Painted Oak",
      "product_mpn": "Side Table - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700300044,
      "product_name": "5 Drawer Chest - Dark Grey Painted Oak",
      "product_model": "5 Drawer Chest - Dark Grey Painted Oak",
      "product_mpn": "5 Drawer Chest - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700200066,
      "product_name": "Small TV Unit - Dark Grey Painted Oak",
      "product_model": "Small TV Unit - Dark Grey Painted Oak",
      "product_mpn": "Small TV Unit - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200065,
      "product_name": "Large TV Unit - Dark Grey Painted Oak",
      "product_model": "Large TV Unit - Dark Grey Painted Oak",
      "product_mpn": "Large TV Unit - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200064,
      "product_name": "Small Sideboard - Dark Grey Painted Oak",
      "product_model": "Small Sideboard - Dark Grey Painted Oak",
      "product_mpn": "Small Sideboard - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700200063,
      "product_name": "Corner TV Unit - Dark Grey Painted Oak",
      "product_model": "Corner TV Unit - Dark Grey Painted Oak",
      "product_mpn": "Corner TV Unit - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300043,
      "product_name": "5 Drawer Tallboy - Dark Grey Painted Oak",
      "product_model": "5 Drawer Tallboy - Dark Grey Painted Oak",
      "product_mpn": "5 Drawer Tallboy - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700200062,
      "product_name": "Small Bookcase - Dark Grey Painted Oak",
      "product_model": "Small Bookcase - Dark Grey Painted Oak",
      "product_mpn": "Small Bookcase - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700800005,
      "product_name": "Dressing Table Stool - Dark Grey Painted Oak",
      "product_model": "Dressing Table Stool - Dark Grey Painted Oak",
      "product_mpn": "Dressing Table Stool - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 1000200067,
      "product_name": "King Size Bed - Dark Grey Painted Oak",
      "product_model": "King Size Bed - Dark Grey Painted Oak",
      "product_mpn": "King Size Bed - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 2500038,
      "product_name": "Console Table - Dark Grey Painted Oak",
      "product_model": "Console Table - Dark Grey Painted Oak",
      "product_mpn": "Console Table - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 700300042,
      "product_name": "7 Drawer Chest - Dark Grey Painted Oak",
      "product_model": "7 Drawer Chest - Dark Grey Painted Oak",
      "product_mpn": "7 Drawer Chest - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2500037,
      "product_name": "2 Drawer Bedside Table - Dark Grey Painted Oak",
      "product_model": "2 Drawer Bedside Table - Dark Grey Painted Oak",
      "product_mpn": "2 Drawer Bedside Table - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2500036,
      "product_name": "Nest of Tables - Dark Grey Painted Oak",
      "product_model": "Nest of Tables - Dark Grey Painted Oak",
      "product_mpn": "Nest of Tables - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 1000200066,
      "product_name": "Double Bed - Dark Grey Painted Oak",
      "product_model": "Double Bed - Dark Grey Painted Oak",
      "product_mpn": "Double Bed - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700900004,
      "product_name": "Large Desk - Dark Grey Painted Oak",
      "product_model": "Large Desk - Dark Grey Painted Oak",
      "product_mpn": "Large Desk - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700200061,
      "product_name": "Tall Bookcase - Dark Grey Painted Oak",
      "product_model": "Tall Bookcase - Dark Grey Painted Oak",
      "product_mpn": "Tall Bookcase - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 700300041,
      "product_name": "Display Cabinet - Dark Grey Painted Oak",
      "product_model": "Display Cabinet - Dark Grey Painted Oak",
      "product_mpn": "Display Cabinet - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 2200026,
      "product_name": "Triple Wardrobe - Dark Grey Painted Oak",
      "product_model": "Triple Wardrobe - Dark Grey Painted Oak",
      "product_mpn": "Triple Wardrobe - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 2500035,
      "product_name": "4 Drawer Coffee Table - Dark Grey Painted Oak",
      "product_model": "4 Drawer Coffee Table - Dark Grey Painted Oak",
      "product_mpn": "4 Drawer Coffee Table - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 2200025,
      "product_name": "Double Wardrobe - Dark Grey Painted Oak",
      "product_model": "Double Wardrobe - Dark Grey Painted Oak",
      "product_mpn": "Double Wardrobe - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "BEDROOM",
      "product_subcategory": "WARDROBES"
    },
    {
      "product_arid": 700200060,
      "product_name": "Large Sideboard - Dark Grey Painted Oak",
      "product_model": "Large Sideboard - Dark Grey Painted Oak",
      "product_mpn": "Large Sideboard - Dark Grey Painted Oak",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "PRODUCT DISPLAYS"
    },
    {
      "product_arid": 3000200002,
      "product_name": "Systane iLux2 Animated Avatar",
      "product_model": "Systane iLux2 Animated Avatar",
      "product_mpn": "Systane iLux2 Animated Avatar",
      "product_brand": "Alcon",
      "product_category": "HEALTH & PERSONAL CARE",
      "product_subcategory": "EYECARE"
    },
    {
      "product_arid": 700600042,
      "product_name": "howdens dordogne oak door",
      "product_model": "howdens dordogne oak door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600027,
      "product_name": "Howdens Rockingham Oak 4 panel clear glazed door",
      "product_model": "Howdens Rockingham Oak 4 panel clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600028,
      "product_name": "Howdens Worcester Oak 3 panel clear glazed door",
      "product_model": "Howdens Worcester Oak 3 panel clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600029,
      "product_name": "Howdens Genoa Oak Clear Glazed Door",
      "product_model": "Howdens Genoa Oak Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600030,
      "product_name": "Howdens Burford Oak 4 panel clear glazed door",
      "product_model": "Howdens Burford Oak 4 panel clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600031,
      "product_name": "Howdens Dordogne oak clear glazed door",
      "product_model": "Howdens Dordogne oak clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600032,
      "product_name": "Howdens Linear Oak Clear Glazed Door",
      "product_model": "Howdens Linear Oak Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600033,
      "product_name": "Howdens Holdenby Oak Clear Glazed door",
      "product_model": "Howdens Holdenby Oak Clear Glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600034,
      "product_name": "Howdens Linear Grey Oak Clear Glazed door",
      "product_model": "Howdens Linear Grey Oak Clear Glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600035,
      "product_name": "Howdens Shaker Oak 4 panel clear glazed door",
      "product_model": "Howdens Shaker Oak 4 panel clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600036,
      "product_name": "Howdens Daytona Oak Clear Glazed Door",
      "product_model": "Howdens Daytona Oak Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600037,
      "product_name": "Howdens Westlock oak door",
      "product_model": "Howdens Westlock oak door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600038,
      "product_name": "Howdens Rockingham oak 4 panel door",
      "product_model": "Howdens Rockingham oak 4 panel door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600039,
      "product_name": "Howdens Worcester Oak 3 panel door",
      "product_model": "Howdens Worcester Oak 3 panel door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600040,
      "product_name": "Howdens Shaker oak 4 panel door",
      "product_model": "Howdens Shaker oak 4 panel door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600041,
      "product_name": "Howdens Solid Rustic Ledged oak door",
      "product_model": "Howdens Solid Rustic Ledged oak door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600043,
      "product_name": "Howdens Burford oak 4 panel door",
      "product_model": "Howdens Burford oak 4 panel door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600044,
      "product_name": "Howdens Genoa oak door",
      "product_model": "Howdens Genoa oak door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600045,
      "product_name": "Howdens linear oak door",
      "product_model": "Howdens linear oak door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600046,
      "product_name": "Howdens Holdenby oak door",
      "product_model": "Howdens Holdenby oak door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600047,
      "product_name": "Howdens Linear Grey Door",
      "product_model": "Howdens Linear Grey Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600048,
      "product_name": "Howdens Daytona oak door",
      "product_model": "Howdens Daytona oak door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600049,
      "product_name": "Howdens Genoa Oak Bifold door",
      "product_model": "Howdens Genoa Oak Bifold door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600050,
      "product_name": "Howdens Holdenby Oak bifold door",
      "product_model": "Howdens Holdenby Oak bifold door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600052,
      "product_name": "Howdens Dordogne oak pre-finished clear glazed FD30 fire door",
      "product_model": "Howdens Dordogne oak pre-finished clear glazed FD30 fire door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600053,
      "product_name": "Howdens colonial white grained 6 panel moulded bifold door",
      "product_model": "Howdens colonial white grained 6 panel moulded bifold door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600054,
      "product_name": "Howdens Thruxton white grained 4 panel moulded bifold door",
      "product_model": "Howdens Thruxton white grained 4 panel moulded bifold door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600055,
      "product_name": "Howdens Dordogne white smooth moulded bifold door",
      "product_model": "Howdens Dordogne white smooth moulded bifold door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600068,
      "product_name": "Howdens Thruxton white grained 4 panel moulded door",
      "product_model": "Howdens Thruxton white grained 4 panel moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600067,
      "product_name": "howdens colonial white grained 6 panel moulded door",
      "product_model": "howdens colonial white grained 6 panel moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600062,
      "product_name": "Howdens metro white smooth moulded clear glazed door",
      "product_model": "Howdens metro white smooth moulded clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600063,
      "product_name": "Howdens linear white smooth moulded clear glazed door",
      "product_model": "Howdens linear white smooth moulded clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600064,
      "product_name": "Howdens thruxton white grained 4 panel moulded clear glazed door",
      "product_model": "Howdens thruxton white grained 4 panel moulded clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600065,
      "product_name": "Howdens dordogne white smooth moulded door",
      "product_model": "Howdens dordogne white smooth moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600066,
      "product_name": "Howdens Burford white smooth 4 panel moulded door",
      "product_model": "Howdens Burford white smooth 4 panel moulded door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600056,
      "product_name": "Howdens Burford white smooth 4 panel moulded clear glazed door",
      "product_model": "Howdens Burford white smooth 4 panel moulded clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600058,
      "product_name": "Howdens Thruxton white grained 4 panel moulded frosted glazed door",
      "product_model": "Howdens Thruxton white grained 4 panel moulded frosted glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600059,
      "product_name": "Howdens dordogne white smooth moulded clear glazed door",
      "product_model": "Howdens dordogne white smooth moulded clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600060,
      "product_name": "Howdens shaker white primed 4 panel clear glazed door",
      "product_model": "Howdens shaker white primed 4 panel clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600061,
      "product_name": "Howdens colonial white grained 6 panel moulded clear glazed door",
      "product_model": "Howdens colonial white grained 6 panel moulded clear glazed door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700300061,
      "product_name": "Painel Home Colibri Tocantins para TV de até 65” - 180cm de largura",
      "product_model": "Painel Home Colibri Tocantins para TV de até 65” - 180cm de largura",
      "product_mpn": "",
      "product_brand": "Colibri Móveis",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300060,
      "product_name": "Estante Home Madetec Cross 200 para TV de até 60”",
      "product_model": "Estante Home Madetec Cross 200 para TV de até 60”",
      "product_mpn": "",
      "product_brand": "Madetec",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 1000200076,
      "product_name": "Berço Pura Magia Soneca Baby II",
      "product_model": "Berço Pura Magia Soneca Baby II",
      "product_mpn": "",
      "product_brand": "Pura Magia",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700900008,
      "product_name": "Escrivaninha Mobler Crystal com Penteadeira",
      "product_model": "Escrivaninha Mobler Crystal com Penteadeira",
      "product_mpn": "",
      "product_brand": "Mobler",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 700900007,
      "product_name": "Mesa para Computador EDN Dóris com 1 Gaveta e 1 Porta",
      "product_model": "Mesa para Computador EDN Dóris com 1 Gaveta e 1 Porta",
      "product_mpn": "",
      "product_brand": "Edn Móveis",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DESKS"
    },
    {
      "product_arid": 1000200074,
      "product_name": "Berço Mini-Cama Henn Bala de Menta",
      "product_model": "Berço Mini-Cama Henn Bala de Menta",
      "product_mpn": "",
      "product_brand": "Henn",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700300059,
      "product_name": "Mesa para Computador Brigatto com Estante",
      "product_model": "Mesa para Computador Brigatto com Estante",
      "product_mpn": "",
      "product_brand": "Brigatto",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 1000200075,
      "product_name": "Cama Infantil Pura Magia New Carros",
      "product_model": "Cama Infantil Pura Magia New Carros",
      "product_mpn": "",
      "product_brand": "Pura Magia",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 700300058,
      "product_name": "Guarda-Roupa Bartira Arezzo com 3 Portas e 9 Gavetas",
      "product_model": "Guarda-Roupa Bartira Arezzo com 3 Portas e 9 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300057,
      "product_name": "Guarda-Roupa Europa Triunfo Flex com 2 Portas de Correr e Espelho",
      "product_model": "Guarda-Roupa Europa Triunfo Flex com 2 Portas de Correr e Espelho",
      "product_mpn": "",
      "product_brand": "Móveis Europa",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300056,
      "product_name": "Guarda-Roupa Móveis Europa Londres Flex com Espelho, 2 Portas de Correr e 4 Gavetas",
      "product_model": "Guarda-Roupa Móveis Europa Londres Flex com Espelho, 2 Portas de Correr e 4 Gavetas",
      "product_mpn": "",
      "product_brand": "Móveis Europa",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300055,
      "product_name": "Guarda-Roupa Bartira Napoli II com 6 Portas e 2 Gavetas- different model",
      "product_model": "Guarda-Roupa Bartira Napoli II com 6 Portas e 2 Gavetas- different model",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300054,
      "product_name": "Guarda-Roupa Bartira Brusque II com 6 Portas e 2 Gavetas",
      "product_model": "Guarda-Roupa Bartira Brusque II com 6 Portas e 2 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 1000200073,
      "product_name": "Cama Box de Casal Flex Toscana D33 com Euro Pillow e Molas Ensacadas 77x138x188cm",
      "product_model": "Cama Box de Casal Flex Toscana D33 com Euro Pillow e Molas Ensacadas 77x138x188cm",
      "product_mpn": "",
      "product_brand": "Flex do Brasil",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200072,
      "product_name": "Cama Box King Size Umaflex Itália com Molas Ensacadas e Pillow Top 68 x 193 x 203 cm",
      "product_model": "Cama Box King Size Umaflex Itália com Molas Ensacadas e Pillow Top 68 x 193 x 203 cm",
      "product_mpn": "",
      "product_brand": "Umaflex",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 1000200071,
      "product_name": "Cama Box Casal Herval Ômega com Pillow Top e Molas Ensacadas 60x138x188cm",
      "product_model": "Cama Box Casal Herval Ômega com Pillow Top e Molas Ensacadas 60x138x188cm",
      "product_mpn": "",
      "product_brand": "Herval",
      "product_category": "BEDROOM",
      "product_subcategory": "BEDS"
    },
    {
      "product_arid": 800100006,
      "product_name": "Mesa de Jantar com 6 Cadeiras DJ Móveis Raquel",
      "product_model": "Mesa de Jantar com 6 Cadeiras DJ Móveis Raquel",
      "product_mpn": "",
      "product_brand": "DJ Móveis",
      "product_category": "LIVINGROOM",
      "product_subcategory": "DINING TABLES"
    },
    {
      "product_arid": 800100005,
      "product_name": "Mesa de Jantar Viero Esmeralda com 4 Cadeiras em Tecido Veludo",
      "product_model": "Mesa de Jantar Viero Esmeralda com 4 Cadeiras em Tecido Veludo",
      "product_mpn": "",
      "product_brand": "Viero Móveis",
      "product_category": "LIVINGROOM",
      "product_subcategory": "DINING TABLES"
    },
    {
      "product_arid": 301393,
      "product_name": "Sofá 3 Lugares Besthouse Arezzo com Assento Retrátil e Encosto Reclinável ",
      "product_model": "Sofá 3 Lugares Besthouse Arezzo com Assento Retrátil e Encosto Reclinável ",
      "product_mpn": "",
      "product_brand": "Besthouse",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301392,
      "product_name": "Sofá 4 Lugares Naturalle Creta 4 com Chaise Direita",
      "product_model": "Sofá 4 Lugares Naturalle Creta 4 com Chaise Direita",
      "product_mpn": "",
      "product_brand": "Naturalle",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301391,
      "product_name": "Sofá 3 Lugares Phormatta Oxford com Assento Retrátil e Encosto Reclinável",
      "product_model": "Sofá 3 Lugares Phormatta Oxford com Assento Retrátil e Encosto Reclinável",
      "product_mpn": "",
      "product_brand": "Phormatta Decor",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301390,
      "product_name": "Sofá 4 Lugares Linoforte Dublin com Assento Retrátil, Encosto Reclinável e Baú ",
      "product_model": "Sofá 4 Lugares Linoforte Dublin com Assento Retrátil, Encosto Reclinável e Baú ",
      "product_mpn": "",
      "product_brand": "Linoforte",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301389,
      "product_name": "Sofá 4 Lugares Luizzi Veneto com Assento Retrátil e Encosto Reclinável",
      "product_model": "Sofá 4 Lugares Luizzi Veneto com Assento Retrátil e Encosto Reclinável",
      "product_mpn": "",
      "product_brand": "Luizzi",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301388,
      "product_name": "Sofá 4 Lugares Luizzi Falconi",
      "product_model": "Sofá 4 Lugares Luizzi Falconi",
      "product_mpn": "",
      "product_brand": "Luizzi",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301387,
      "product_name": "Sofá de Canto Master Royal com Puff",
      "product_model": "Sofá de Canto Master Royal com Puff",
      "product_mpn": "",
      "product_brand": "Master",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301386,
      "product_name": "Sofá 3 Lugares Master Soft Plus com Assento Retrátil e Encosto Reclinável",
      "product_model": "Sofá 3 Lugares Master Soft Plus com Assento Retrátil e Encosto Reclinável",
      "product_mpn": "",
      "product_brand": "Master",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700300053,
      "product_name": "Cozinha Completa Bartira Topázio com 5 Portas, 6 Prateleiras e 2 Nichos",
      "product_model": "Cozinha Completa Bartira Topázio com 5 Portas, 6 Prateleiras e 2 Nichos",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300052,
      "product_name": "Cozinha Compacta Bartira Jaspe com 6 Portas, 3 Gavetas e 6 Prateleiras",
      "product_model": "Cozinha Compacta Bartira Jaspe com 6 Portas, 3 Gavetas e 6 Prateleiras",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300051,
      "product_name": "Cozinha Completa Bartira Ametista com 11 Portas, 5 Gavetas e 3 Prateleiras",
      "product_model": "Cozinha Completa Bartira Ametista com 11 Portas, 5 Gavetas e 3 Prateleiras",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 700300050,
      "product_name": "Cozinha Completa Bartira Rubi com 6 Portas e 5 Gavetas",
      "product_model": "Cozinha Completa Bartira Rubi com 6 Portas e 5 Gavetas",
      "product_mpn": "",
      "product_brand": "Bartira",
      "product_category": "ALL ROOMS",
      "product_subcategory": "CABINETS"
    },
    {
      "product_arid": 12600088,
      "product_name": "HU300UPT",
      "product_model": "HU300UPT",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 91001310005,
      "product_name": "HO9DC3E3078IN",
      "product_model": "HO9DC3E3078IN",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 91001310004,
      "product_name": "HOC3E3158IN",
      "product_model": "HOC3E3158IN",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "OVENS"
    },
    {
      "product_arid": 10300159,
      "product_name": "HBWOS 69TAMCBET",
      "product_model": "HBWOS 69TAMCBET",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 10300158,
      "product_name": "HBWOS 69TAMCET",
      "product_model": "HBWOS 69TAMCET",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 13500103,
      "product_name": "HVN 6182W5KN",
      "product_model": "HVN 6182W5KN",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500102,
      "product_name": "HTR5619FNMI",
      "product_model": "HTR5619FNMI",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500101,
      "product_name": "HSF818FWDXK ",
      "product_model": "HSF818FWDXK ",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500100,
      "product_name": "HTF-520IP7 ",
      "product_model": "HTF-520IP7 ",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 10300157,
      "product_name": "• HWB 49AMC",
      "product_model": "• HWB 49AMC",
      "product_mpn": "",
      "product_brand": "HAIER",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "WASHING MACHINES"
    },
    {
      "product_arid": 8000407,
      "product_name": "Legion 5 7th Gen Storm Grey",
      "product_model": "Legion 5 7th Gen Storm Grey",
      "product_mpn": "Legion 5 7th Gen Storm Grey",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000253,
      "product_name": "V15 Gen 2 Grey",
      "product_model": "V15 Gen 2 Grey",
      "product_mpn": "V15 Gen 2 Grey",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000218,
      "product_name": "ThinkPad x1 Yoga gen 6",
      "product_model": "ThinkPad x1 Yoga gen 6",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000217,
      "product_name": "ThinkPad x1 carbon gen 9",
      "product_model": "ThinkPad x1 carbon gen 9",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000216,
      "product_name": "Legion s7  15ACH6",
      "product_model": "Legion s7  15ACH6",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000215,
      "product_name": "Legion 5 pro 16ITH6H",
      "product_model": "Legion 5 pro 16ITH6H",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000214,
      "product_name": "Legion 7 ITHg6 ",
      "product_model": "Legion 7 ITHg6 ",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000213,
      "product_name": "Legion 5 17 ITH6H",
      "product_model": "Legion 5 17 ITH6H",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000212,
      "product_name": "Legion 5 15 IMH6",
      "product_model": "Legion 5 15 IMH6",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000211,
      "product_name": "Yoga Slim 9 14ITL shadow black",
      "product_model": "Yoga Slim 9 14ITL shadow black",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000210,
      "product_name": "Yoga Slim 7 Pro 14ACH5/14ITL slate grey",
      "product_model": "Yoga Slim 7 Pro 14ACH5/14ITL slate grey",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000209,
      "product_name": "Yoga 9 14ITL5 Shadow black",
      "product_model": "Yoga 9 14ITL5 Shadow black",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000208,
      "product_name": "Yoga 7 15 ITL5 Slate grey",
      "product_model": "Yoga 7 15 ITL5 Slate grey",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000207,
      "product_name": "Yoga 7 14ACN6/ITL5 slate grey",
      "product_model": "Yoga 7 14ACN6/ITL5 slate grey",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 1200500007,
      "product_name": "GRP99 sensimal mobile 1",
      "product_model": "GRP99 sensimal mobile 1",
      "product_mpn": "",
      "product_brand": "Mattel",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 1200400000,
      "product_name": "GHL93 Barbie camper van",
      "product_model": "GHL93 Barbie camper van",
      "product_mpn": "",
      "product_brand": "Mattel",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "TOYS"
    },
    {
      "product_arid": 3300100001,
      "product_name": "Moto X Bike",
      "product_model": "Moto X Bike",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "BIKE",
      "product_subcategory": "BIKES"
    },
    {
      "product_arid": 3300100000,
      "product_name": "Moto GP Bike",
      "product_model": "Moto GP Bike",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "BIKE",
      "product_subcategory": "BIKES"
    },
    {
      "product_arid": 13500110,
      "product_name": "6 Parasite Rack 2020",
      "product_model": "6 Parasite Rack 2020",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 301600000,
      "product_name": "Temporary Pallet Wrap High Crowner",
      "product_model": "Temporary Pallet Wrap High Crowner",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "PALLET WRAPS"
    },
    {
      "product_arid": 13500109,
      "product_name": "Transparent Cooler",
      "product_model": "Transparent Cooler",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500108,
      "product_name": "Q-Lane Cooler",
      "product_model": "Q-Lane Cooler",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500107,
      "product_name": "Small OFC with stand",
      "product_model": "Small OFC with stand",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500106,
      "product_name": "Small GDC on stand",
      "product_model": "Small GDC on stand",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500105,
      "product_name": "Baby GDC on cooler stand",
      "product_model": "Baby GDC on cooler stand",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500104,
      "product_name": "Mega GDC with DiCro",
      "product_model": "Mega GDC with DiCro",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500112,
      "product_name": "Large GDC With DiCro",
      "product_model": "Large GDC With DiCro",
      "product_mpn": "",
      "product_brand": "Red Bull",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500114,
      "product_name": "RB34A7B5D22/EF",
      "product_model": "RB34A7B5D22/EF",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500113,
      "product_name": "RZ32T743539/EE",
      "product_model": "RZ32T743539/EE",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500132,
      "product_name": "RL38A7B63CW/EF",
      "product_model": "RL38A7B63CW/EF",
      "product_mpn": "RL38A7B63CW/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500131,
      "product_name": "RL38A7B63B1/EF",
      "product_model": "RL38A7B63B1/EF",
      "product_mpn": "RL38A7B63B1/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500130,
      "product_name": "RB38A7B6AS9/EF",
      "product_model": "RB38A7B6AS9/EF",
      "product_mpn": "RB38A7B6AS9/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500129,
      "product_name": "RB38A7B5D41/EF",
      "product_model": "RB38A7B5D41/EF",
      "product_mpn": "RB38A7B5D41/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500128,
      "product_name": "RB38A7B5D39/EF",
      "product_model": "RB38A7B5D39/EF",
      "product_mpn": "RB38A7B5D39/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500127,
      "product_name": "RB38A7B5D22/EF",
      "product_model": "RB38A7B5D22/EF",
      "product_mpn": "RB38A7B5D22/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500125,
      "product_name": "RR39T746334/EE",
      "product_model": "RR39T746334/EE",
      "product_mpn": "RR39T746334/EE",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500124,
      "product_name": "RR39T746348/EE",
      "product_model": "RR39T746348/EE",
      "product_mpn": "RR39T746348/EE",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500123,
      "product_name": "RR39T746339/EE",
      "product_model": "RR39T746339/EE",
      "product_mpn": "RR39T746339/EE",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500122,
      "product_name": "RR39A746341/EE",
      "product_model": "RR39A746341/EE",
      "product_mpn": "RR39A746341/EE",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500121,
      "product_name": "RZ32T743534/EE",
      "product_model": "RZ32T743534/EE",
      "product_mpn": "RZ32T743534/EE",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500120,
      "product_name": "RZ32T743548/EE",
      "product_model": "RZ32T743548/EE",
      "product_mpn": "RZ32T743548/EE",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500119,
      "product_name": "RB34A7B5D41/EF",
      "product_model": "RB34A7B5D41/EF",
      "product_mpn": "RB34A7B5D41/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500118,
      "product_name": "RB34A7B5DS9/EF",
      "product_model": "RB34A7B5DS9/EF",
      "product_mpn": "RB34A7B5DS9/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500116,
      "product_name": "RZ32A743541/EE",
      "product_model": "RZ32A743541/EE",
      "product_mpn": "",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500115,
      "product_name": "RB34A7B5D39/EF",
      "product_model": "RB34A7B5D39/EF",
      "product_mpn": "RB34A7B5D39/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 301400,
      "product_name": "Hampton 3 Seater Sofa-Natural Fabric",
      "product_model": "Hampton 3 Seater Sofa-Natural Fabric",
      "product_mpn": "ST-HPT003-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301399,
      "product_name": "Claremont 3 Seater Sofa-Slate Velvet",
      "product_model": "Claremont 3 Seater Sofa-Slate Velvet",
      "product_mpn": "ST-CLM003-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301398,
      "product_name": "Hastings 3 Seater Sofa (Recliner)-Two Tone Brown Leather",
      "product_model": "Hastings 3 Seater Sofa (Recliner)-Two Tone Brown Leather",
      "product_mpn": "HSG203-LEATH-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301397,
      "product_name": "Eastbourne 3 Seater Sofa (Electric Recliner)-",
      "product_model": "Eastbourne 3 Seater Sofa (Electric Recliner)-",
      "product_mpn": "EAS203-SAN-STE-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301396,
      "product_name": "Fern 3 Seater Sofa-Green Velvet",
      "product_model": "Fern 3 Seater Sofa-Green Velvet",
      "product_mpn": "FER003-OPL-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301395,
      "product_name": "Boulevard 3 Seater Sofa- Graphite Fabric",
      "product_model": "Boulevard 3 Seater Sofa- Graphite Fabric",
      "product_mpn": "BLV003-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301394,
      "product_name": "Witney 3 Seater Sofa- Storm Fabric",
      "product_model": "Witney 3 Seater Sofa- Storm Fabric",
      "product_mpn": "WIT003-PIR-STM-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301446,
      "product_name": "Hampton Storage Footstool-Natural Fabric",
      "product_model": "Hampton Storage Footstool-Natural Fabric",
      "product_mpn": "ST-HPT010-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301445,
      "product_name": "Hampton Accent Chair-Natural Fabric",
      "product_model": "Hampton Accent Chair-Natural Fabric",
      "product_mpn": "HPT009-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301444,
      "product_name": "Hampton Armchair-Natural Fabric",
      "product_model": "Hampton Armchair-Natural Fabric",
      "product_mpn": "ST-HPT001-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301443,
      "product_name": "Hampton Loveseat-Natural Fabric",
      "product_model": "Hampton Loveseat-Natural Fabric",
      "product_mpn": "ST-HPT057-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301442,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Natural Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Natural Fabric",
      "product_mpn": "HPT042-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301441,
      "product_name": "Hampton 2 Seater Sofa-Natural Fabric",
      "product_model": "Hampton 2 Seater Sofa-Natural Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301440,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Natural Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Natural Fabric",
      "product_mpn": "HPT043-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301439,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Natural Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Natural Fabric",
      "product_mpn": "ST-HPT061-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301438,
      "product_name": "Hampton 4 Seater Sofa-Natural Fabric",
      "product_model": "Hampton 4 Seater Sofa-Natural Fabric",
      "product_mpn": "ST-HPT004-PLAIN-NAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301437,
      "product_name": "Claremont Storage Footstool-Slate Velvet",
      "product_model": "Claremont Storage Footstool-Slate Velvet",
      "product_mpn": "ST-CLM010-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301436,
      "product_name": "Claremont Accent Chair-Slate Velvet",
      "product_model": "Claremont Accent Chair-Slate Velvet",
      "product_mpn": "CLM009-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301435,
      "product_name": "Claremont Armchair-Slate Velvet",
      "product_model": "Claremont Armchair-Slate Velvet",
      "product_mpn": "ST-CLM001-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301434,
      "product_name": "Claremont Loveseat-Slate Velvet",
      "product_model": "Claremont Loveseat-Slate Velvet",
      "product_mpn": "ST-CLM057-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301433,
      "product_name": "Claremont 2 Seater Sofa-Slate Velvet",
      "product_model": "Claremont 2 Seater Sofa-Slate Velvet",
      "product_mpn": "ST-CLM002-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301432,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Slate Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Slate Velvet",
      "product_mpn": "CLM042-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301431,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Slate Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Slate Velvet",
      "product_mpn": "CLM043-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301430,
      "product_name": "Claremont 4 Seater Sofa-Slate Velvet",
      "product_model": "Claremont 4 Seater Sofa-Slate Velvet",
      "product_mpn": "ST-CLM004-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301429,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Slate Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Slate Velvet",
      "product_mpn": "CLM061-PLV-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301428,
      "product_name": "Hastings Storage Footstool-Two Tone Brown Leather",
      "product_model": "Hastings Storage Footstool-Two Tone Brown Leather",
      "product_mpn": "HSG010-LEATH-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301427,
      "product_name": "Hastings Armchair (Recliner)-Two Tone Brown Leather",
      "product_model": "Hastings Armchair (Recliner)-Two Tone Brown Leather",
      "product_mpn": "HSG201-LEATH-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301426,
      "product_name": "Hastings 2 Seater Sofa (Recliner)-Two Tone Brown Leather",
      "product_model": "Hastings 2 Seater Sofa (Recliner)-Two Tone Brown Leather",
      "product_mpn": "HSG202-LEATH-2BRO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301425,
      "product_name": "Eastbourne Storage Footstool-Santos Feel Fabric",
      "product_model": "Eastbourne Storage Footstool-Santos Feel Fabric",
      "product_mpn": "EAS010-SAN-STE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301424,
      "product_name": "Eastbourne Riser Armchair (Electric Recliner)-Santos Feel Fabric",
      "product_model": "Eastbourne Riser Armchair (Electric Recliner)-Santos Feel Fabric",
      "product_mpn": "EAS204-SAN-STE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301423,
      "product_name": "Eastbourne Armchair (Electric Recliner) Santos Feel Fabric",
      "product_model": "Eastbourne Armchair (Electric Recliner) Santos Feel Fabric",
      "product_mpn": "EAS201-SAN-STE-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301422,
      "product_name": "Eastbourne 2 Seater Sofa (Electric Recliner) Santos Feel Fabric",
      "product_model": "Eastbourne 2 Seater Sofa (Electric Recliner) Santos Feel Fabric",
      "product_mpn": "EAS202-SAN-STE-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301421,
      "product_name": "Fern Footstool-Green Velvet",
      "product_model": "Fern Footstool-Green Velvet",
      "product_mpn": "FERFOOT-OPL-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301420,
      "product_name": "Fern Armchair-Green Velvet",
      "product_model": "Fern Armchair-Green Velvet",
      "product_mpn": "FER001-OPL-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301419,
      "product_name": "Fern 2 Seater Sofa-Green Velvet",
      "product_model": "Fern 2 Seater Sofa-Green Velvet",
      "product_mpn": "FER002-OPL-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301418,
      "product_name": "Fern 4 Seater Sofa-Green Velvet",
      "product_model": "Fern 4 Seater Sofa-Green Velvet",
      "product_mpn": "FER004-OPL-GRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301415,
      "product_name": "Boulevard Armchair-Graphite Fabric with Studs",
      "product_model": "Boulevard Armchair-Graphite Fabric with Studs",
      "product_mpn": "BLV001-STUD-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301414,
      "product_name": "Boulevard Loveseat-Graphite Fabric with Studs",
      "product_model": "Boulevard Loveseat-Graphite Fabric with Studs",
      "product_mpn": "BLV057-STUD-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301413,
      "product_name": "Boulevard 2 Seater Sofa-Graphite Fabric with Studs",
      "product_model": "Boulevard 2 Seater Sofa-Graphite Fabric with Studs",
      "product_mpn": "BLV002-STUD-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301412,
      "product_name": "Boulevard 4 Seater Sofa-Graphite Fabric with Studs",
      "product_model": "Boulevard 4 Seater Sofa-Graphite Fabric with Studs",
      "product_mpn": "BLV004M-STUD-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301411,
      "product_name": "Boulevard 3 Seater Sofa-Graphite Fabric with Studs",
      "product_model": "Boulevard 3 Seater Sofa-Graphite Fabric with Studs",
      "product_mpn": "BLV003-STUD-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301410,
      "product_name": "Boulevard Round Footstool-Graphite Fabric",
      "product_model": "Boulevard Round Footstool-Graphite Fabric",
      "product_mpn": "BLVFOOT-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301409,
      "product_name": "Boulevard Storage Footstool-Graphite Fabric",
      "product_model": "Boulevard Storage Footstool-Graphite Fabric",
      "product_mpn": "BLV010-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301408,
      "product_name": "Boulevard Armchair-Graphite Fabric",
      "product_model": "Boulevard Armchair-Graphite Fabric",
      "product_mpn": "BLV001-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301407,
      "product_name": "Boulevard Loveseat-Graphite Fabric",
      "product_model": "Boulevard Loveseat-Graphite Fabric",
      "product_mpn": "BLV057-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301406,
      "product_name": "Boulevard 2 Seater Sofa-Graphite Fabric",
      "product_model": "Boulevard 2 Seater Sofa-Graphite Fabric",
      "product_mpn": "BLV002-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301405,
      "product_name": "Boulevard 4 Seater Sofa-Graphite Fabric",
      "product_model": "Boulevard 4 Seater Sofa-Graphite Fabric",
      "product_mpn": "BLV004M-ADL-GRA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301404,
      "product_name": "Witney Storage Footstool-Storm Fabric",
      "product_model": "Witney Storage Footstool-Storm Fabric",
      "product_mpn": "WIT010-PIR-STM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301403,
      "product_name": "Witney Armchair-Storm Fabric",
      "product_model": "Witney Armchair-Storm Fabric",
      "product_mpn": "WIT001-PIR-STM-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301402,
      "product_name": "Witney 2 Seater Sofa-Storm Fabric",
      "product_model": "Witney 2 Seater Sofa-Storm Fabric",
      "product_mpn": "WIT002-PIR-STM-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301450,
      "product_name": "Witney Storage Footstool-Linen Fabric",
      "product_model": "Witney Storage Footstool-Linen Fabric",
      "product_mpn": "WIT010-PIR-LIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301449,
      "product_name": "Witney Armchair-Linen Fabric",
      "product_model": "Witney Armchair-Linen Fabric",
      "product_mpn": "WIT001-PIR-LIN-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301448,
      "product_name": "Witney 2 Seater Sofa-Linen Fabric",
      "product_model": "Witney 2 Seater Sofa-Linen Fabric",
      "product_mpn": "WIT002-PIR-LIN-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301447,
      "product_name": "Witney 3 Seater Sofa-Linen Fabric",
      "product_model": "Witney 3 Seater Sofa-Linen Fabric",
      "product_mpn": "WIT003-PIR-LIN-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301454,
      "product_name": "Witney Storage Footstool-Silver Fabric",
      "product_model": "Witney Storage Footstool-Silver Fabric",
      "product_mpn": "WIT010-PIR-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301453,
      "product_name": "Witney Armchair-Silver Fabric",
      "product_model": "Witney Armchair-Silver Fabric",
      "product_mpn": "WIT001-PIR-SIL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301452,
      "product_name": "Witney 2 Seater Sofa-Silver Fabric",
      "product_model": "Witney 2 Seater Sofa-Silver Fabric",
      "product_mpn": "WIT002-PIR-SIL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301451,
      "product_name": "Witney 3 Seater Sofa-Silver Fabric",
      "product_model": "Witney 3 Seater Sofa-Silver Fabric",
      "product_mpn": "WIT003-PIR-SIL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301458,
      "product_name": "Witney Storage Footstool-Charcoal Fabric",
      "product_model": "Witney Storage Footstool-Charcoal Fabric",
      "product_mpn": "WIT010-PIR-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301457,
      "product_name": "Witney Armchair-Charcoal Fabric",
      "product_model": "Witney Armchair-Charcoal Fabric",
      "product_mpn": "WIT001-PIR-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301456,
      "product_name": "Witney 2 Seater Sofa-Charcoal Fabric",
      "product_model": "Witney 2 Seater Sofa-Charcoal Fabric",
      "product_mpn": "WIT002-PIR-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301455,
      "product_name": "Witney 3 Seater Sofa-Charcoal Fabric",
      "product_model": "Witney 3 Seater Sofa-Charcoal Fabric",
      "product_mpn": "WIT003-PIR-CHA-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301465,
      "product_name": "Boulevard Round Footstool-Stone Fabric",
      "product_model": "Boulevard Round Footstool-Stone Fabric",
      "product_mpn": "BLVFOOT-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301464,
      "product_name": "Boulevard Storage Footstool-Stone Fabric",
      "product_model": "Boulevard Storage Footstool-Stone Fabric",
      "product_mpn": "BLV010-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301463,
      "product_name": "Boulevard Armchair-Stone Fabric",
      "product_model": "Boulevard Armchair-Stone Fabric",
      "product_mpn": "BLV001-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301462,
      "product_name": "Boulevard Loveseat-Stone Fabric",
      "product_model": "Boulevard Loveseat-Stone Fabric",
      "product_mpn": "BLV057-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301461,
      "product_name": "Boulevard 2 Seater Sofa-Stone Fabric",
      "product_model": "Boulevard 2 Seater Sofa-Stone Fabric",
      "product_mpn": "BLV002-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301460,
      "product_name": "Boulevard 4 Seater Sofa Stone Fabric",
      "product_model": "Boulevard 4 Seater Sofa Stone Fabric",
      "product_mpn": "BLV004M-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301459,
      "product_name": "Boulevard 3 Seater Sofa-Stone Fabric",
      "product_model": "Boulevard 3 Seater Sofa-Stone Fabric",
      "product_mpn": "BLV003-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301470,
      "product_name": "Boulevard Armchair Stone Fabric with Studs",
      "product_model": "Boulevard Armchair Stone Fabric with Studs",
      "product_mpn": "BLV001-STUD-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301469,
      "product_name": "Boulevard Loveseat-Stone Fabric with Studs",
      "product_model": "Boulevard Loveseat-Stone Fabric with Studs",
      "product_mpn": "BLV057-STUD-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301468,
      "product_name": "Boulevard 2 Seater Sofa Stone Fabric with Studs",
      "product_model": "Boulevard 2 Seater Sofa Stone Fabric with Studs",
      "product_mpn": "BLV002-STUD-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301467,
      "product_name": "Boulevard 4 Seater Sofa Stone Fabric with Studs",
      "product_model": "Boulevard 4 Seater Sofa Stone Fabric with Studs",
      "product_mpn": "BLV004M-STUD-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301466,
      "product_name": "Boulevard 3 Seater Sofa Stone Fabric with Studs",
      "product_model": "Boulevard 3 Seater Sofa Stone Fabric with Studs",
      "product_mpn": "BLV003-STUD-ADL-STO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301479,
      "product_name": "Boulevard Round Footstool-Silver Fabric",
      "product_model": "Boulevard Round Footstool-Silver Fabric",
      "product_mpn": "BLVFOOT-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301478,
      "product_name": "Boulevard Storage Footstool-Silver Fabric",
      "product_model": "Boulevard Storage Footstool-Silver Fabric",
      "product_mpn": "BLV010-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301477,
      "product_name": "Boulevard Armchair-Silver Fabric",
      "product_model": "Boulevard Armchair-Silver Fabric",
      "product_mpn": "BLV001-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301476,
      "product_name": "Boulevard Loveseat-Silver Fabric",
      "product_model": "Boulevard Loveseat-Silver Fabric",
      "product_mpn": "BLV057-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301475,
      "product_name": "Boulevard 2 Seater Sofa Silver Fabric",
      "product_model": "Boulevard 2 Seater Sofa Silver Fabric",
      "product_mpn": "BLV002-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301474,
      "product_name": "Boulevard 4 Seater-Sofa Silver Fabric",
      "product_model": "Boulevard 4 Seater-Sofa Silver Fabric",
      "product_mpn": "BLV003-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301473,
      "product_name": "Boulevard 3 Seater Sofa-Silver Fabric",
      "product_model": "Boulevard 3 Seater Sofa-Silver Fabric",
      "product_mpn": "BLV003-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301484,
      "product_name": "Boulevard Armchair Silver Fabric with Studs",
      "product_model": "Boulevard Armchair Silver Fabric with Studs",
      "product_mpn": "BLV001-STUD-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301483,
      "product_name": "Boulevard Loveseat-Silver Fabric with Studs",
      "product_model": "Boulevard Loveseat-Silver Fabric with Studs",
      "product_mpn": "BLV057-STUD-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301482,
      "product_name": "Boulevard 2 Seater Sofa Silver Fabric with Studs",
      "product_model": "Boulevard 2 Seater Sofa Silver Fabric with Studs",
      "product_mpn": "BLV002-STUD-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301481,
      "product_name": "Boulevard 4 Seater Sofa Silver Fabric with Studs",
      "product_model": "Boulevard 4 Seater Sofa Silver Fabric with Studs",
      "product_mpn": "BLV004M-STUD-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301480,
      "product_name": "Boulevard 3 Seater Sofa Silver Fabric with Studs",
      "product_model": "Boulevard 3 Seater Sofa Silver Fabric with Studs",
      "product_mpn": "BLV003-STUD-ADL-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301491,
      "product_name": "Fern Footstool Gold Velvet",
      "product_model": "Fern Footstool Gold Velvet",
      "product_mpn": "FERFOOT-OPL-GLD",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301490,
      "product_name": "Fern Armchair-Gold Velvet",
      "product_model": "Fern Armchair-Gold Velvet",
      "product_mpn": "FER001-OPL-GLD",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301489,
      "product_name": "Fern 2 Seater Sofa Gold Velvet",
      "product_model": "Fern 2 Seater Sofa Gold Velvet",
      "product_mpn": "FER002-OPL-GLD",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301488,
      "product_name": "Fern 4 Seater Sofa Gold Velvet",
      "product_model": "Fern 4 Seater Sofa Gold Velvet",
      "product_mpn": "FER004-OPL-GLD",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301487,
      "product_name": "Fern 3 Seater Sofa Gold Velvet",
      "product_model": "Fern 3 Seater Sofa Gold Velvet",
      "product_mpn": "FER003-OPL-GLD",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301496,
      "product_name": "Fern Footstool-Grey Velvet",
      "product_model": "Fern Footstool-Grey Velvet",
      "product_mpn": "FERFOOT-OPL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301495,
      "product_name": "Fern Armchair-Grey Velvet",
      "product_model": "Fern Armchair-Grey Velvet",
      "product_mpn": "FER001-OPL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301494,
      "product_name": "Fern 2 Seater Sofa Grey Velvet",
      "product_model": "Fern 2 Seater Sofa Grey Velvet",
      "product_mpn": "FER002-OPL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301493,
      "product_name": "Fern 4 Seater Sofa Grey Velvet",
      "product_model": "Fern 4 Seater Sofa Grey Velvet",
      "product_mpn": "FER004-OPL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301492,
      "product_name": "Fern 3 Seater Sofa Grey Velvet",
      "product_model": "Fern 3 Seater Sofa Grey Velvet",
      "product_mpn": "FER003-OPL-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301501,
      "product_name": "Fern Footstool Navy Velvet",
      "product_model": "Fern Footstool Navy Velvet",
      "product_mpn": "FERFOOT-OPL-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301500,
      "product_name": "Fern Armchair Navy Velvet",
      "product_model": "Fern Armchair Navy Velvet",
      "product_mpn": "FER001-OPL-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301499,
      "product_name": "Fern 2 Seater Sofa Navy Velvet",
      "product_model": "Fern 2 Seater Sofa Navy Velvet",
      "product_mpn": "FER002-OPL-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301498,
      "product_name": "Fern 4 Seater Sofa Navy Velvet",
      "product_model": "Fern 4 Seater Sofa Navy Velvet",
      "product_mpn": "FER004-OPL-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301497,
      "product_name": "Fern 3 Seater Sofa Navy Velvet",
      "product_model": "Fern 3 Seater Sofa Navy Velvet",
      "product_mpn": "FER003-OPL-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301506,
      "product_name": "Eastbourne Storage Footstool-Dorset Beige Fabric",
      "product_model": "Eastbourne Storage Footstool-Dorset Beige Fabric",
      "product_mpn": "EAS010-DOR-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301505,
      "product_name": "Eastbourne Riser Armchair (Electric Recliner)-Dorset Beige Fabric",
      "product_model": "Eastbourne Riser Armchair (Electric Recliner)-Dorset Beige Fabric",
      "product_mpn": "EAS204-DOR-BEI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301504,
      "product_name": "Eastbourne Armchair (Electric Recliner)-Dorset Beige Fabric",
      "product_model": "Eastbourne Armchair (Electric Recliner)-Dorset Beige Fabric",
      "product_mpn": "EAS201-DOR-BEI-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301503,
      "product_name": "Eastbourne 2 Seater Sofa (Electric Recliner)-Dorset Beige Fabric",
      "product_model": "Eastbourne 2 Seater Sofa (Electric Recliner)-Dorset Beige Fabric",
      "product_mpn": "EAS202-DOR-BEI-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301502,
      "product_name": "Eastbourne 3 Seater Sofa (Electric Recliner) Dorset Beige Fabric",
      "product_model": "Eastbourne 3 Seater Sofa (Electric Recliner) Dorset Beige Fabric",
      "product_mpn": "EAS203-DOR-BEI-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301511,
      "product_name": "Eastbourne Storage Footstool-Andaz Silver Fabric",
      "product_model": "Eastbourne Storage Footstool-Andaz Silver Fabric",
      "product_mpn": "EAS010-ADZ-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301510,
      "product_name": "Eastbourne Riser Armchair (Electric Recliner)-Andaz Silver Fabric",
      "product_model": "Eastbourne Riser Armchair (Electric Recliner)-Andaz Silver Fabric",
      "product_mpn": "EAS204-ADZ-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301509,
      "product_name": "Eastbourne Armchair (Electric Recliner)-Andaz Silver Fabric",
      "product_model": "Eastbourne Armchair (Electric Recliner)-Andaz Silver Fabric",
      "product_mpn": "EAS201-ADZ-SIL-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301508,
      "product_name": "Eastbourne 2 Seater Sofa (Electric Recliner)-Andaz Silver Fabric",
      "product_model": "Eastbourne 2 Seater Sofa (Electric Recliner)-Andaz Silver Fabric",
      "product_mpn": "EAS202-ADZ-SIL-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301507,
      "product_name": "Eastbourne 3 Seater Sofa (Electric Recliner)-Andaz Silver Fabric",
      "product_model": "Eastbourne 3 Seater Sofa (Electric Recliner)-Andaz Silver Fabric",
      "product_mpn": "EAS203-ADZ-SIL-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301516,
      "product_name": "Eastbourne Storage Footstool-Andaz Charcoal Fabric",
      "product_model": "Eastbourne Storage Footstool-Andaz Charcoal Fabric",
      "product_mpn": "EAS010-ADZ-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301515,
      "product_name": "Eastbourne Riser Armchair (Electric Recliner)-Andaz Charcoal Fabric",
      "product_model": "Eastbourne Riser Armchair (Electric Recliner)-Andaz Charcoal Fabric",
      "product_mpn": "EAS204-ADZ-CHA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301514,
      "product_name": "Eastbourne Armchair (Electric Recliner)-Andaz Charcoal Fabric",
      "product_model": "Eastbourne Armchair (Electric Recliner)-Andaz Charcoal Fabric",
      "product_mpn": "EAS201-ADZ-CHA-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301513,
      "product_name": "Eastbourne 2 Seater Sofa (Electric Recliner)-Andaz Charcoal Fabric",
      "product_model": "Eastbourne 2 Seater Sofa (Electric Recliner)-Andaz Charcoal Fabric",
      "product_mpn": "EAS202-ADZ-CHA-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301512,
      "product_name": "Eastbourne 3 Seater Sofa (Electric Recliner)-Andaz Charcoal Fabric",
      "product_model": "Eastbourne 3 Seater Sofa (Electric Recliner)-Andaz Charcoal Fabric",
      "product_mpn": "EAS203-ADZ-CHA-USB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301520,
      "product_name": "Hastings Storage Footstool-Black Leather",
      "product_model": "Hastings Storage Footstool-Black Leather",
      "product_mpn": "HSG010-LEATH-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301519,
      "product_name": "Hastings Armchair (Recliner) Black Leather",
      "product_model": "Hastings Armchair (Recliner) Black Leather",
      "product_mpn": "HSG201-LEATH-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301518,
      "product_name": "Hastings 2 Seater Sofa (Recliner)-Black Leather",
      "product_model": "Hastings 2 Seater Sofa (Recliner)-Black Leather",
      "product_mpn": "HSG202-LEATH-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301517,
      "product_name": "Hastings 3 Seater Sofa (Recliner)-Black Leather",
      "product_model": "Hastings 3 Seater Sofa (Recliner)-Black Leather",
      "product_mpn": "HSG203-LEATH-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301524,
      "product_name": "Hastings Storage Footstool-Burgundy Leather",
      "product_model": "Hastings Storage Footstool-Burgundy Leather",
      "product_mpn": "HSG010-LEATH-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301523,
      "product_name": "Hastings Armchair (Recliner)-Burgundy Leather",
      "product_model": "Hastings Armchair (Recliner)-Burgundy Leather",
      "product_mpn": "HSG201-LEATH-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301522,
      "product_name": "Hastings 2 Seater Sofa (Recliner) Burgundy Leather",
      "product_model": "Hastings 2 Seater Sofa (Recliner) Burgundy Leather",
      "product_mpn": "HSG202-LEATH-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301521,
      "product_name": "Hastings 3 Seater Sofa (Recliner)-Burgundy Leather",
      "product_model": "Hastings 3 Seater Sofa (Recliner)-Burgundy Leather",
      "product_mpn": "HSG203-LEATH-BUR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301528,
      "product_name": "Hastings Storage Footstool-Light Grey Leather",
      "product_model": "Hastings Storage Footstool-Light Grey Leather",
      "product_mpn": "HSG010-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301527,
      "product_name": "Hastings Armchair (Recliner) Light Grey Leather",
      "product_model": "Hastings Armchair (Recliner) Light Grey Leather",
      "product_mpn": "HSG201-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301526,
      "product_name": "Hastings 2 Seater Sofa (Recliner)-Light Grey Leather",
      "product_model": "Hastings 2 Seater Sofa (Recliner)-Light Grey Leather",
      "product_mpn": "HSG202-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301525,
      "product_name": "Hastings 3 Seater Sofa (Recliner)-Light Grey Leather",
      "product_model": "Hastings 3 Seater Sofa (Recliner)-Light Grey Leather",
      "product_mpn": "HSG203-LEATH-LGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301532,
      "product_name": "Hastings Storage Footstool-Tan Leather",
      "product_model": "Hastings Storage Footstool-Tan Leather",
      "product_mpn": "HSG010-LEATH-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301531,
      "product_name": "Hastings Armchair (Recliner)Tan Leather",
      "product_model": "Hastings Armchair (Recliner)Tan Leather",
      "product_mpn": "HSG201-LEATH-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301530,
      "product_name": "Hastings 2 Seater Sofa (Recliner)-Tan Leather",
      "product_model": "Hastings 2 Seater Sofa (Recliner)-Tan Leather",
      "product_mpn": "HSG202-LEATH-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301529,
      "product_name": "Hastings 3 Seater Sofa (Recliner)-Tan Leather",
      "product_model": "Hastings 3 Seater Sofa (Recliner)-Tan Leather",
      "product_mpn": "HSG203-LEATH-TAN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301536,
      "product_name": "Hastings Storage Footstool-Dark Grey Leather",
      "product_model": "Hastings Storage Footstool-Dark Grey Leather",
      "product_mpn": "HSG010-LEATH-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301535,
      "product_name": "Hastings Armchair (Recliner)-Dark Grey Leather",
      "product_model": "Hastings Armchair (Recliner)-Dark Grey Leather",
      "product_mpn": "HSG201-LEATH-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301534,
      "product_name": "Hastings 2 Seater Sofa (Recliner)-Dark Grey Leather",
      "product_model": "Hastings 2 Seater Sofa (Recliner)-Dark Grey Leather",
      "product_mpn": "HSG202-LEATH-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301533,
      "product_name": "Hastings 3 Seater Sofa (Recliner) Dark Grey Leather",
      "product_model": "Hastings 3 Seater Sofa (Recliner) Dark Grey Leather",
      "product_mpn": "HSG203-LEATH-DGRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301546,
      "product_name": "Claremont Storage Footstool Slate Green Velvet",
      "product_model": "Claremont Storage Footstool Slate Green Velvet",
      "product_mpn": "CLM010-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301545,
      "product_name": "Claremont Accent Chair-Slate Green Velvet",
      "product_model": "Claremont Accent Chair-Slate Green Velvet",
      "product_mpn": "ST-CLM009-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301544,
      "product_name": "Claremont Armchair-Slate Green Velvet",
      "product_model": "Claremont Armchair-Slate Green Velvet",
      "product_mpn": "CLM001-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301543,
      "product_name": "Claremont Loveseat-Slate Green Velvet",
      "product_model": "Claremont Loveseat-Slate Green Velvet",
      "product_mpn": "ST-CLM057-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301542,
      "product_name": "Claremont 2 Seater Sofa-Slate Green Velvet",
      "product_model": "Claremont 2 Seater Sofa-Slate Green Velvet",
      "product_mpn": "CLM002-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301541,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Slate Green Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Slate Green Velvet",
      "product_mpn": "CLM042-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301540,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Slate Green Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Slate Green Velvet",
      "product_mpn": "CLM043-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301539,
      "product_name": "Claremont 4 Seater Sofa-Slate Green Velvet",
      "product_model": "Claremont 4 Seater Sofa-Slate Green Velvet",
      "product_mpn": "CLM004-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301538,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Slate Green Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Slate Green Velvet",
      "product_mpn": "CLM061-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301537,
      "product_name": "Claremont 3 Seater Sofa-Slate Green Velvet",
      "product_model": "Claremont 3 Seater Sofa-Slate Green Velvet",
      "product_mpn": "CLM003-PLV-SGRN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301556,
      "product_name": "Claremont Storage Footstool-Gunmetal Velvet",
      "product_model": "Claremont Storage Footstool-Gunmetal Velvet",
      "product_mpn": "CLM010-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301555,
      "product_name": "Claremont Accent Chair-Gunmetal Velvet",
      "product_model": "Claremont Accent Chair-Gunmetal Velvet",
      "product_mpn": "CLM009-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301554,
      "product_name": "Claremont Armchair-Gunmetal Velvet",
      "product_model": "Claremont Armchair-Gunmetal Velvet",
      "product_mpn": "CLM001-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301553,
      "product_name": "Claremont Loveseat-Gunmetal Velvet",
      "product_model": "Claremont Loveseat-Gunmetal Velvet",
      "product_mpn": "CLM057-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301552,
      "product_name": "Claremont 2 Seater Sofa-Gunmetal Velvet",
      "product_model": "Claremont 2 Seater Sofa-Gunmetal Velvet",
      "product_mpn": "CLM002-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301551,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Gunmetal Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Gunmetal Velvet",
      "product_mpn": "CLM042-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301550,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Gunmetal Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Gunmetal Velvet",
      "product_mpn": "CLM043-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301549,
      "product_name": "Claremont 4 Seater Sofa-Gunmetal Velvet",
      "product_model": "Claremont 4 Seater Sofa-Gunmetal Velvet",
      "product_mpn": "CLM004-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301548,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Gunmetal Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Gunmetal Velvet",
      "product_mpn": "CLM061-PLV-GMTL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301547,
      "product_name": "Claremont 3 Seater Sofa-Gunmetal Velvet",
      "product_model": "Claremont 3 Seater Sofa-Gunmetal Velvet",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301566,
      "product_name": "Claremont Storage Footstool-Navy Velvet",
      "product_model": "Claremont Storage Footstool-Navy Velvet",
      "product_mpn": "CLM010-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301565,
      "product_name": "Claremont Accent Chair-Navy Velvet",
      "product_model": "Claremont Accent Chair-Navy Velvet",
      "product_mpn": "CLM009-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301564,
      "product_name": "Claremont Armchair-Navy Velvet",
      "product_model": "Claremont Armchair-Navy Velvet",
      "product_mpn": "CLM001-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301563,
      "product_name": "Claremont Loveseat-Navy Velvet",
      "product_model": "Claremont Loveseat-Navy Velvet",
      "product_mpn": "CLM057-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301562,
      "product_name": "Claremont 2 Seater Sofa-Navy Velvet",
      "product_model": "Claremont 2 Seater Sofa-Navy Velvet",
      "product_mpn": "CLM002-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301561,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Navy Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Navy Velvet",
      "product_mpn": "CLM042-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301560,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Navy Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Navy Velvet",
      "product_mpn": "CLM043-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301559,
      "product_name": "Claremont 4 Seater Sofa-Navy Velvet",
      "product_model": "Claremont 4 Seater Sofa-Navy Velvet",
      "product_mpn": "CLM004-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301558,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Navy Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Navy Velvet",
      "product_mpn": "CLM061-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301557,
      "product_name": "Claremont 3 Seater Sofa-Navy Velvet",
      "product_model": "Claremont 3 Seater Sofa-Navy Velvet",
      "product_mpn": "CLM003-PLV-NAV",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301576,
      "product_name": "Claremont Storage Footstool-Chrome Velvet",
      "product_model": "Claremont Storage Footstool-Chrome Velvet",
      "product_mpn": "CLM010-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301575,
      "product_name": "Claremont Accent Chair-Chrome Velvet",
      "product_model": "Claremont Accent Chair-Chrome Velvet",
      "product_mpn": "CLM009-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301574,
      "product_name": "Claremont Armchair-Chrome Velvet",
      "product_model": "Claremont Armchair-Chrome Velvet",
      "product_mpn": "CLM001-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301573,
      "product_name": "Claremont Loveseat-Chrome Velvet",
      "product_model": "Claremont Loveseat-Chrome Velvet",
      "product_mpn": "CLM057-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301572,
      "product_name": "Claremont 2 Seater Sofa-Chrome Velvet",
      "product_model": "Claremont 2 Seater Sofa-Chrome Velvet",
      "product_mpn": "CLM002-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301571,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Chrome Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Chrome Velvet",
      "product_mpn": "CLM042-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301570,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Chrome Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Chrome Velvet",
      "product_mpn": "CLM043-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301569,
      "product_name": "Claremont 4 Seater Sofa-Chrome Velvet",
      "product_model": "Claremont 4 Seater Sofa-Chrome Velvet",
      "product_mpn": "CLM004-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301568,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Chrome Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Chrome Velvet",
      "product_mpn": "CLM061-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301567,
      "product_name": "Claremont 3 Seater Sofa-Chrome Velvet",
      "product_model": "Claremont 3 Seater Sofa-Chrome Velvet",
      "product_mpn": "CLM003-PLV-CHR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301586,
      "product_name": "Claremont Storage Footstool-Ochre Velvet",
      "product_model": "Claremont Storage Footstool-Ochre Velvet",
      "product_mpn": "CLM010-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301585,
      "product_name": "Claremont Accent Chair-Ochre Velvet",
      "product_model": "Claremont Accent Chair-Ochre Velvet",
      "product_mpn": "CLM009-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301584,
      "product_name": "Claremont Armchair-Ochre Velvet",
      "product_model": "Claremont Armchair-Ochre Velvet",
      "product_mpn": "CLM001-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301583,
      "product_name": "Claremont Loveseat-Ochre Velvet",
      "product_model": "Claremont Loveseat-Ochre Velvet",
      "product_mpn": "CLM057-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301582,
      "product_name": "Claremont 2 Seater Sofa-Ochre Velvet",
      "product_model": "Claremont 2 Seater Sofa-Ochre Velvet",
      "product_mpn": "CLM002-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301581,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Ochre Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Ochre Velvet",
      "product_mpn": "CLM042-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301580,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Ochre Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Ochre Velvet",
      "product_mpn": "CLM043-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301579,
      "product_name": "Claremont 4 Seater Sofa-Ochre Velvet",
      "product_model": "Claremont 4 Seater Sofa-Ochre Velvet",
      "product_mpn": "CLM004-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301578,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Ochre Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Ochre Velvet",
      "product_mpn": "CLM061-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301577,
      "product_name": "Claremont 3 Seater Sofa-Ochre Velvet",
      "product_model": "Claremont 3 Seater Sofa-Ochre Velvet",
      "product_mpn": "CLM003-PLV-OCR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301596,
      "product_name": "Claremont Storage Footstool-Pebble Velvet",
      "product_model": "Claremont Storage Footstool-Pebble Velvet",
      "product_mpn": "CLM010-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301595,
      "product_name": "Claremont Accent Chair-Pebble Velvet",
      "product_model": "Claremont Accent Chair-Pebble Velvet",
      "product_mpn": "CLM009-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301594,
      "product_name": "Claremont Armchair-Pebble Velvet",
      "product_model": "Claremont Armchair-Pebble Velvet",
      "product_mpn": "CLM001-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301593,
      "product_name": "Claremont Loveseat-Pebble Velvet",
      "product_model": "Claremont Loveseat-Pebble Velvet",
      "product_mpn": "CLM057-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301592,
      "product_name": "Claremont 2 Seater Sofa-Pebble Velvet",
      "product_model": "Claremont 2 Seater Sofa-Pebble Velvet",
      "product_mpn": "CLM002-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301591,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Pebble Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Pebble Velvet",
      "product_mpn": "CLM042-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301590,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Pebble Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Pebble Velvet",
      "product_mpn": "CLM043-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301589,
      "product_name": "Claremont 4 Seater Sofa-Pebble Velvet",
      "product_model": "Claremont 4 Seater Sofa-Pebble Velvet",
      "product_mpn": "CLM004-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301588,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Pebble Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Pebble Velvet",
      "product_mpn": "CLM061-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301587,
      "product_name": "Claremont 3 Seater Sofa-Pebble Velvet",
      "product_model": "Claremont 3 Seater Sofa-Pebble Velvet",
      "product_mpn": "CLM003-PLV-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301606,
      "product_name": "Claremont Storage Footstool-Truffle Velvet",
      "product_model": "Claremont Storage Footstool-Truffle Velvet",
      "product_mpn": "CLM010-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301605,
      "product_name": "Claremont Accent Chair-Truffle Velvet",
      "product_model": "Claremont Accent Chair-Truffle Velvet",
      "product_mpn": "CLM009-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301604,
      "product_name": "Claremont Armchair-Truffle Velvet",
      "product_model": "Claremont Armchair-Truffle Velvet",
      "product_mpn": "CLM001-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301603,
      "product_name": "Claremont Loveseat-Truffle Velvet",
      "product_model": "Claremont Loveseat-Truffle Velvet",
      "product_mpn": "CLM057-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301602,
      "product_name": "Claremont 2 Seater Sofa-Truffle Velvet",
      "product_model": "Claremont 2 Seater Sofa-Truffle Velvet",
      "product_mpn": "CLM002-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301601,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Truffle Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Truffle Velvet",
      "product_mpn": "CLM042-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301600,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Truffle Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Truffle Velvet",
      "product_mpn": "CLM043-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301599,
      "product_name": "Claremont 4 Seater Sofa-Truffle Velvet",
      "product_model": "Claremont 4 Seater Sofa-Truffle Velvet",
      "product_mpn": "CLM004-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301598,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Truffle Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Truffle Velvet",
      "product_mpn": "CLM061-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301597,
      "product_name": "Claremont 3 Seater Sofa-Truffle Velvet",
      "product_model": "Claremont 3 Seater Sofa-Truffle Velvet",
      "product_mpn": "CLM003-PLV-TRUF",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301616,
      "product_name": "Claremont Storage Footstool-Cream Velvet",
      "product_model": "Claremont Storage Footstool-Cream Velvet",
      "product_mpn": "CLM010-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301615,
      "product_name": "Claremont Accent Chair-Cream Velvet",
      "product_model": "Claremont Accent Chair-Cream Velvet",
      "product_mpn": "CLM009-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301614,
      "product_name": "Claremont Armchair-Cream Velvet",
      "product_model": "Claremont Armchair-Cream Velvet",
      "product_mpn": "CLM001-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301613,
      "product_name": "Claremont Loveseat-Cream Velvet",
      "product_model": "Claremont Loveseat-Cream Velvet",
      "product_mpn": "CLM057-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301612,
      "product_name": "Claremont 2 Seater Sofa-Cream Velvet",
      "product_model": "Claremont 2 Seater Sofa-Cream Velvet",
      "product_mpn": "CLM002-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301611,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Cream Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Cream Velvet",
      "product_mpn": "CLM042-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301610,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Cream Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Cream Velvet",
      "product_mpn": "CLM043-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301609,
      "product_name": "Claremont 4 Seater Sofa-Cream Velvet",
      "product_model": "Claremont 4 Seater Sofa-Cream Velvet",
      "product_mpn": "CLM004-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301608,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Cream Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Cream Velvet",
      "product_mpn": "CLM061-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301607,
      "product_name": "Claremont 3 Seater Sofa-Cream Velvet",
      "product_model": "Claremont 3 Seater Sofa-Cream Velvet",
      "product_mpn": "CLM003-PLV-CRM",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301626,
      "product_name": "Claremont Storage Footstool-Shale Velvet",
      "product_model": "Claremont Storage Footstool-Shale Velvet",
      "product_mpn": "CLM010-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301625,
      "product_name": "Claremont Accent Chair-Shale Velvet",
      "product_model": "Claremont Accent Chair-Shale Velvet",
      "product_mpn": "CLM009-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301624,
      "product_name": "Claremont Armchair-Shale Velvet",
      "product_model": "Claremont Armchair-Shale Velvet",
      "product_mpn": "CLM001-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301623,
      "product_name": "Claremont Loveseat-Shale Velvet",
      "product_model": "Claremont Loveseat-Shale Velvet",
      "product_mpn": "CLM057-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301622,
      "product_name": "Claremont 2 Seater Sofa-Shale Velvet",
      "product_model": "Claremont 2 Seater Sofa-Shale Velvet",
      "product_mpn": "CLM002-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301621,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Shale Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Shale Velvet",
      "product_mpn": "CLM042-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301620,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Shale Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Shale Velvet",
      "product_mpn": "CLM043-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301619,
      "product_name": "Claremont 4 Seater Sofa-Shale Velvet",
      "product_model": "Claremont 4 Seater Sofa-Shale Velvet",
      "product_mpn": "CLM004-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301618,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Shale Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Shale Velvet",
      "product_mpn": "CLM061-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301617,
      "product_name": "Claremont 3 Seater Sofa-Shale Velvet",
      "product_model": "Claremont 3 Seater Sofa-Shale Velvet",
      "product_mpn": "CLM003-PLV-SHL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301636,
      "product_name": "Claremont Storage Footstool-Kiwi Velvet",
      "product_model": "Claremont Storage Footstool-Kiwi Velvet",
      "product_mpn": "CLM010-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301635,
      "product_name": "Claremont Accent Chair-Kiwi Velvet",
      "product_model": "Claremont Accent Chair-Kiwi Velvet",
      "product_mpn": "CLM009-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301634,
      "product_name": "Claremont Armchair-Kiwi Velvet",
      "product_model": "Claremont Armchair-Kiwi Velvet",
      "product_mpn": "CLM001-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301633,
      "product_name": "Claremont Loveseat-Kiwi Velvet",
      "product_model": "Claremont Loveseat-Kiwi Velvet",
      "product_mpn": "CLM057-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301632,
      "product_name": "Claremont 2 Seater Sofa-Kiwi Velvet",
      "product_model": "Claremont 2 Seater Sofa-Kiwi Velvet",
      "product_mpn": "CLM002-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301631,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Kiwi Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Kiwi Velvet",
      "product_mpn": "CLM042-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301630,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Kiwi Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Kiwi Velvet",
      "product_mpn": "CLM043-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301629,
      "product_name": "Claremont 4 Seater Sofa-Kiwi Velvet",
      "product_model": "Claremont 4 Seater Sofa-Kiwi Velvet",
      "product_mpn": "CLM004-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301628,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Kiwi Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Kiwi Velvet",
      "product_mpn": "CLM061-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301627,
      "product_name": "Claremont 3 Seater Sofa-Kiwi Velvet",
      "product_model": "Claremont 3 Seater Sofa-Kiwi Velvet",
      "product_mpn": "CLM003-PLV-KWI",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301646,
      "product_name": "Claremont Storage Footstool-Biscuit Velvet",
      "product_model": "Claremont Storage Footstool-Biscuit Velvet",
      "product_mpn": "CLM010-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301645,
      "product_name": "Claremont Accent Chair-Biscuit Velvet",
      "product_model": "Claremont Accent Chair-Biscuit Velvet",
      "product_mpn": "CLM009-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301644,
      "product_name": "Claremont Armchair-Biscuit Velvet",
      "product_model": "Claremont Armchair-Biscuit Velvet",
      "product_mpn": "CLM001-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301643,
      "product_name": "Claremont Loveseat-Biscuit Velvet",
      "product_model": "Claremont Loveseat-Biscuit Velvet",
      "product_mpn": "CLM057-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301642,
      "product_name": "Claremont 2 Seater Sofa-Biscuit Velvet",
      "product_model": "Claremont 2 Seater Sofa-Biscuit Velvet",
      "product_mpn": "CLM002-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301641,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Biscuit Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Biscuit Velvet",
      "product_mpn": "CLM042-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301640,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Biscuit Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Biscuit Velvet",
      "product_mpn": "CLM043-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301639,
      "product_name": "Claremont 4 Seater Sofa-Biscuit Velvet",
      "product_model": "Claremont 4 Seater Sofa-Biscuit Velvet",
      "product_mpn": "CLM004-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301638,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Biscuit Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Biscuit Velvet",
      "product_mpn": "CLM061-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301637,
      "product_name": "Claremont 3 Seater Sofa-Biscuit Velvet",
      "product_model": "Claremont 3 Seater Sofa-Biscuit Velvet",
      "product_mpn": "CLM003-PLV-BIS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301656,
      "product_name": "Claremont Storage Footstool-Ruby Velvet",
      "product_model": "Claremont Storage Footstool-Ruby Velvet",
      "product_mpn": "CLM010-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301655,
      "product_name": "Claremont Accent ChairRuby Velvet",
      "product_model": "Claremont Accent ChairRuby Velvet",
      "product_mpn": "CLM009-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301654,
      "product_name": "Claremont Armchair-Ruby Velvet",
      "product_model": "Claremont Armchair-Ruby Velvet",
      "product_mpn": "CLM001-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301653,
      "product_name": "Claremont Loveseat-Ruby Velvet",
      "product_model": "Claremont Loveseat-Ruby Velvet",
      "product_mpn": "CLM057-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301652,
      "product_name": "Claremont 2 Seater Sofa-Ruby Velvet",
      "product_model": "Claremont 2 Seater Sofa-Ruby Velvet",
      "product_mpn": "CLM002-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301651,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Ruby Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Ruby Velvet",
      "product_mpn": "CLM042-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301650,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Ruby Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Ruby Velvet",
      "product_mpn": "CLM043-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301649,
      "product_name": "Claremont 4 Seater Sofa-Ruby Velvet",
      "product_model": "Claremont 4 Seater Sofa-Ruby Velvet",
      "product_mpn": "CLM004-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301648,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Ruby Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Ruby Velvet",
      "product_mpn": "CLM061-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301647,
      "product_name": "Claremont 3 Seater Sofa-Ruby Velvet",
      "product_model": "Claremont 3 Seater Sofa-Ruby Velvet",
      "product_mpn": "CLM003-PLV-RBY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301666,
      "product_name": "Claremont Storage Footstool Ocean Velvet",
      "product_model": "Claremont Storage Footstool Ocean Velvet",
      "product_mpn": "CLM010-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301665,
      "product_name": "Claremont Accent Chair Ocean Velvet",
      "product_model": "Claremont Accent Chair Ocean Velvet",
      "product_mpn": "CLM009-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301664,
      "product_name": "Claremont Armchair-Ocean Velvet",
      "product_model": "Claremont Armchair-Ocean Velvet",
      "product_mpn": "CLM001-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301663,
      "product_name": "Claremont Loveseat-Ocean Velvet",
      "product_model": "Claremont Loveseat-Ocean Velvet",
      "product_mpn": "CLM057-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301662,
      "product_name": "Claremont 2 Seater Sofa-Ocean Velvet",
      "product_model": "Claremont 2 Seater Sofa-Ocean Velvet",
      "product_mpn": "CLM002-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301661,
      "product_name": "Claremont 2 Seater Pillow Back Sofa-Ocean Velvet",
      "product_model": "Claremont 2 Seater Pillow Back Sofa-Ocean Velvet",
      "product_mpn": "CLM042-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301660,
      "product_name": "Claremont 3 Seater Pillow Back Sofa-Ocean Velvet",
      "product_model": "Claremont 3 Seater Pillow Back Sofa-Ocean Velvet",
      "product_mpn": "CLM043-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301659,
      "product_name": "Claremont 4 Seater Sofa-Ocean Velvet",
      "product_model": "Claremont 4 Seater Sofa-Ocean Velvet",
      "product_mpn": "CLM004-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301658,
      "product_name": "Claremont 4 Seater Pillow Back Sofa-Ocean Velvet",
      "product_model": "Claremont 4 Seater Pillow Back Sofa-Ocean Velvet",
      "product_mpn": "CLM061-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301657,
      "product_name": "Claremont 3 Seater Sofa-Ocean Velvet",
      "product_model": "Claremont 3 Seater Sofa-Ocean Velvet",
      "product_mpn": "CLM003-PLV-OCE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301676,
      "product_name": "Hampton Storage Footstool-Latte Fabric",
      "product_model": "Hampton Storage Footstool-Latte Fabric",
      "product_mpn": "HPT010-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301675,
      "product_name": "Hampton Accent Chair-Latte Fabric",
      "product_model": "Hampton Accent Chair-Latte Fabric",
      "product_mpn": "HPT009-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301674,
      "product_name": "Hampton Armchair-Latte Fabric",
      "product_model": "Hampton Armchair-Latte Fabric",
      "product_mpn": "HPT001-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301673,
      "product_name": "Hampton Loveseat Latte Fabric",
      "product_model": "Hampton Loveseat Latte Fabric",
      "product_mpn": "HPT057-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301672,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Latte Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Latte Fabric",
      "product_mpn": "HPT042-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301671,
      "product_name": "Hampton 2 Seater Sofa-Latte Fabric",
      "product_model": "Hampton 2 Seater Sofa-Latte Fabric",
      "product_mpn": "HPT002-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301670,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Latte Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Latte Fabric",
      "product_mpn": "HPT043-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301669,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Latte Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Latte Fabric",
      "product_mpn": "HPT061-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301668,
      "product_name": "Hampton 4 Seater Sofa Latte Fabric",
      "product_model": "Hampton 4 Seater Sofa Latte Fabric",
      "product_mpn": "HPT004-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301667,
      "product_name": "Hampton 3 Seater Sofa Latte Fabric",
      "product_model": "Hampton 3 Seater Sofa Latte Fabric",
      "product_mpn": "HPT003-PLAIN-LAT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301686,
      "product_name": "Hampton Storage Footstool-Cappuccino Fabric",
      "product_model": "Hampton Storage Footstool-Cappuccino Fabric",
      "product_mpn": "HPT010-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301685,
      "product_name": "Hampton Accent Chair-Cappuccino Fabric",
      "product_model": "Hampton Accent Chair-Cappuccino Fabric",
      "product_mpn": "HPT009-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301684,
      "product_name": "Hampton Armchair Cappuccino Fabric",
      "product_model": "Hampton Armchair Cappuccino Fabric",
      "product_mpn": "HPT001-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301683,
      "product_name": "Hampton Loveseat Cappuccino Fabric",
      "product_model": "Hampton Loveseat Cappuccino Fabric",
      "product_mpn": "HPT057-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301682,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Cappuccino Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Cappuccino Fabric",
      "product_mpn": "HPT042-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301681,
      "product_name": "Hampton 2 Seater Sofa-Cappuccino Fabric",
      "product_model": "Hampton 2 Seater Sofa-Cappuccino Fabric",
      "product_mpn": "HPT002-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301680,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Cappuccino Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Cappuccino Fabric",
      "product_mpn": "HPT043-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301679,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Cappuccino Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Cappuccino Fabric",
      "product_mpn": "HPT061-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301678,
      "product_name": "Hampton 4 Seater Sofa-Cappuccino Fabric",
      "product_model": "Hampton 4 Seater Sofa-Cappuccino Fabric",
      "product_mpn": "HPT004-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301677,
      "product_name": "Hampton 3 Seater Sofa-Cappuccino Fabric",
      "product_model": "Hampton 3 Seater Sofa-Cappuccino Fabric",
      "product_mpn": "HPT003-PLAIN-CAP",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301696,
      "product_name": "Hampton Storage Footstool-Pebble Fabric",
      "product_model": "Hampton Storage Footstool-Pebble Fabric",
      "product_mpn": "HPT010-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301695,
      "product_name": "Hampton Accent Chair-Pebble Fabric",
      "product_model": "Hampton Accent Chair-Pebble Fabric",
      "product_mpn": "HPT009-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301694,
      "product_name": "Hampton Armchair Pebble Fabric",
      "product_model": "Hampton Armchair Pebble Fabric",
      "product_mpn": "HPT001-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301693,
      "product_name": "Hampton Loveseat Pebble Fabric",
      "product_model": "Hampton Loveseat Pebble Fabric",
      "product_mpn": "HPT057-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301692,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Pebble Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Pebble Fabric",
      "product_mpn": "HPT042-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301691,
      "product_name": "Hampton 2 Seater Sofa-Pebble Fabric",
      "product_model": "Hampton 2 Seater Sofa-Pebble Fabric",
      "product_mpn": "HPT002-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301690,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Pebble Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Pebble Fabric",
      "product_mpn": "HPT043-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301689,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Pebble Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Pebble Fabric",
      "product_mpn": "HPT061-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301688,
      "product_name": "Hampton 4 Seater Sofa Pebble Fabric",
      "product_model": "Hampton 4 Seater Sofa Pebble Fabric",
      "product_mpn": "HPT004-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301687,
      "product_name": "Hampton 3 Seater Sofa-Pebble Fabric",
      "product_model": "Hampton 3 Seater Sofa-Pebble Fabric",
      "product_mpn": "HPT003-PLAIN-PEB",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301706,
      "product_name": "Hampton Storage Footstool-Mocha Fabric",
      "product_model": "Hampton Storage Footstool-Mocha Fabric",
      "product_mpn": "HPT010-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301705,
      "product_name": "Hampton Accent Chair-Mocha Fabric",
      "product_model": "Hampton Accent Chair-Mocha Fabric",
      "product_mpn": "HPT009-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301704,
      "product_name": "Hampton Armchair Mocha Fabric",
      "product_model": "Hampton Armchair Mocha Fabric",
      "product_mpn": "HPT001-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301703,
      "product_name": "Hampton Loveseat Mocha Fabric",
      "product_model": "Hampton Loveseat Mocha Fabric",
      "product_mpn": "HPT057-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301702,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Mocha Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Mocha Fabric",
      "product_mpn": "HPT042-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301701,
      "product_name": "Hampton 2 Seater Sofa-Mocha Fabric",
      "product_model": "Hampton 2 Seater Sofa-Mocha Fabric",
      "product_mpn": "HPT002-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301700,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Mocha Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Mocha Fabric",
      "product_mpn": "HPT043-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301699,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Mocha Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Mocha Fabric",
      "product_mpn": "HPT061-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301698,
      "product_name": "Hampton 4 Seater Sofa-Mocha Fabric",
      "product_model": "Hampton 4 Seater Sofa-Mocha Fabric",
      "product_mpn": "HPT004-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301697,
      "product_name": "Hampton 3 Seater Sofa-Mocha Fabric",
      "product_model": "Hampton 3 Seater Sofa-Mocha Fabric",
      "product_mpn": "HPT003-PLAIN-MOC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301716,
      "product_name": "Hampton Storage Footstool-Silver Fabric",
      "product_model": "Hampton Storage Footstool-Silver Fabric",
      "product_mpn": "HPT010-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301715,
      "product_name": "Hampton Accent Chair-Silver Fabric",
      "product_model": "Hampton Accent Chair-Silver Fabric",
      "product_mpn": "HPT009-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301714,
      "product_name": "Hampton Armchair Silver Fabric",
      "product_model": "Hampton Armchair Silver Fabric",
      "product_mpn": "HPT001-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301713,
      "product_name": "Hampton Loveseat Silver Fabric",
      "product_model": "Hampton Loveseat Silver Fabric",
      "product_mpn": "HPT057-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301712,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Silver Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Silver Fabric",
      "product_mpn": "HPT042-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301711,
      "product_name": "Hampton 2 Seater Sofa-Silver Fabric",
      "product_model": "Hampton 2 Seater Sofa-Silver Fabric",
      "product_mpn": "HPT002-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301710,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Silver Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Silver Fabric",
      "product_mpn": "HPT043-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301709,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Silver Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Silver Fabric",
      "product_mpn": "HPT061-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301708,
      "product_name": "Hampton 4 Seater Sofa Silver Fabric",
      "product_model": "Hampton 4 Seater Sofa Silver Fabric",
      "product_mpn": "HPT004-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301707,
      "product_name": "Hampton 3 Seater Sofa Silver Fabric",
      "product_model": "Hampton 3 Seater Sofa Silver Fabric",
      "product_mpn": "HPT003-PLAIN-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301726,
      "product_name": "Hampton Storage Footstool-Slate Fabric",
      "product_model": "Hampton Storage Footstool-Slate Fabric",
      "product_mpn": "HPT010-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301725,
      "product_name": "Hampton Accent Chair-Slate Fabric",
      "product_model": "Hampton Accent Chair-Slate Fabric",
      "product_mpn": "HPT009-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301724,
      "product_name": "Hampton Armchair Slate Fabric",
      "product_model": "Hampton Armchair Slate Fabric",
      "product_mpn": "HPT001-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301723,
      "product_name": "Hampton Loveseat Slate Fabric",
      "product_model": "Hampton Loveseat Slate Fabric",
      "product_mpn": "HPT057-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301722,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Slate Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Slate Fabric",
      "product_mpn": "HPT042-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301721,
      "product_name": "Hampton 2 Seater Sofa-Slate Fabric",
      "product_model": "Hampton 2 Seater Sofa-Slate Fabric",
      "product_mpn": "HPT002-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301720,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Slate Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Slate Fabric",
      "product_mpn": "HPT043-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301719,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Slate Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Slate Fabric",
      "product_mpn": "HPT061-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301718,
      "product_name": "Hampton 4 Seater Sofa-Slate Fabric",
      "product_model": "Hampton 4 Seater Sofa-Slate Fabric",
      "product_mpn": "HPT004-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301717,
      "product_name": "Hampton 3 Seater Sofa Slate Fabric",
      "product_model": "Hampton 3 Seater Sofa Slate Fabric",
      "product_mpn": "HPT003-PLAIN-SLA",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301736,
      "product_name": "Hampton Storage Footstool-Duck Egg Fabric",
      "product_model": "Hampton Storage Footstool-Duck Egg Fabric",
      "product_mpn": "ST-HPT010-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301735,
      "product_name": "Hampton Accent Chair-Duck Egg Fabric",
      "product_model": "Hampton Accent Chair-Duck Egg Fabric",
      "product_mpn": "HPT009-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301734,
      "product_name": "Hampton Armchair-Duck Egg Fabric",
      "product_model": "Hampton Armchair-Duck Egg Fabric",
      "product_mpn": "ST-HPT001-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301733,
      "product_name": "Hampton Loveseat-Duck Egg Fabric",
      "product_model": "Hampton Loveseat-Duck Egg Fabric",
      "product_mpn": "HPT057-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301732,
      "product_name": "Hampton 2 Seater Pillow Back Sofa-Duck Egg Fabric",
      "product_model": "Hampton 2 Seater Pillow Back Sofa-Duck Egg Fabric",
      "product_mpn": "HPT042-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301731,
      "product_name": "Hampton 2 Seater Sofa-Duck Egg Fabric",
      "product_model": "Hampton 2 Seater Sofa-Duck Egg Fabric",
      "product_mpn": "ST-HPT002-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301730,
      "product_name": "Hampton 3 Seater Pillow Back Sofa-Duck Egg Fabric",
      "product_model": "Hampton 3 Seater Pillow Back Sofa-Duck Egg Fabric",
      "product_mpn": "HPT043-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301729,
      "product_name": "Hampton 4 Seater Pillow Back Sofa-Duck Egg Fabric",
      "product_model": "Hampton 4 Seater Pillow Back Sofa-Duck Egg Fabric",
      "product_mpn": "HPT061-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301728,
      "product_name": "Hampton 4 Seater Sofa-Duck Egg Fabric",
      "product_model": "Hampton 4 Seater Sofa-Duck Egg Fabric",
      "product_mpn": "ST-HPT004-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301727,
      "product_name": "Hampton 3 Seater Sofa-Duck Egg Fabric",
      "product_model": "Hampton 3 Seater Sofa-Duck Egg Fabric",
      "product_mpn": "ST-HPT003-PLAIN-DUC",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 6800040,
      "product_name": "PRO X GAMING HEADSET Lol 2 - Gaming Headsets - Hypersonic Premium - Hexa",
      "product_model": "PRO X GAMING HEADSET Lol 2 - Gaming Headsets - Hypersonic Premium - Hexa",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800039,
      "product_name": "HYPERBOOM Black - Mobile Speakers - Motor City ",
      "product_model": "HYPERBOOM Black - Mobile Speakers - Motor City ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 11700015,
      "product_name": "G920 XBOX Black - Gaming Steering Wheels - Starlight",
      "product_model": "G920 XBOX Black - Gaming Steering Wheels - Starlight",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 7100013,
      "product_name": "EMBER XLR Grey - Pro Blue Microphone - Ember Pro - Hexa ",
      "product_model": "EMBER XLR Grey - Pro Blue Microphone - Ember Pro - Hexa ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 700600092,
      "product_name": "Howdens Heyford Ash Veneer 16G Clear Glazed Pre-Finished Flush FD30 Fire Door",
      "product_model": "Howdens Heyford Ash Veneer 16G Clear Glazed Pre-Finished Flush FD30 Fire Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600091,
      "product_name": "Howdens Holdenby Laminate Grey Pre-Finished Clear Glazed Door",
      "product_model": "Howdens Holdenby Laminate Grey Pre-Finished Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600090,
      "product_name": "Howdens Daytona Laminate Grey Pre-Finished Clear Glazed Door",
      "product_model": "Howdens Daytona Laminate Grey Pre-Finished Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600089,
      "product_name": "Howdens Holdenby Laminate Grey Pre-Finished Door",
      "product_model": "Howdens Holdenby Laminate Grey Pre-Finished Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600088,
      "product_name": "Howdens Daytona Laminate Grey Pre-Finished Door",
      "product_model": "Howdens Daytona Laminate Grey Pre-Finished Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600087,
      "product_name": "Howdens Shaker White Primed 1 Panel Door",
      "product_model": "Howdens Shaker White Primed 1 Panel Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600086,
      "product_name": "Howdens Shaker White Smooth 4 Panel Pre-Finished Moulded Door",
      "product_model": "Howdens Shaker White Smooth 4 Panel Pre-Finished Moulded Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600085,
      "product_name": "Howdens Shaker White Smooth 4 Panel Pre-Finished Moulded Clear Glazed Door",
      "product_model": "Howdens Shaker White Smooth 4 Panel Pre-Finished Moulded Clear Glazed Door",
      "product_mpn": "",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600101,
      "product_name": "Howdens Primed 1930 Door",
      "product_model": "Howdens Primed 1930 Door",
      "product_mpn": "Howdens Primed 1930 Door",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 700600100,
      "product_name": "Howdens Primed 1930 Glazed Door",
      "product_model": "Howdens Primed 1930 Glazed Door",
      "product_mpn": "Howdens Primed 1930 Glazed Door",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "DOORS"
    },
    {
      "product_arid": 16800031,
      "product_name": "Bespoke Silestone 30mm Charcoal Soapstone Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Charcoal Soapstone Quartz Worktop",
      "product_mpn": "SSWPS1125",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800030,
      "product_name": "Bespoke Silestone 30mm White Arabeaque Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm White Arabeaque Quartz Worktop",
      "product_mpn": "SSWPS1123",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800029,
      "product_name": "Bespoke Silestone 30mm Eternal Marquina Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Eternal Marquina Quartz Worktop",
      "product_mpn": "SSWPS1121",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800028,
      "product_name": "Bespoke Silestone 30mm Alpina White Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Alpina White Quartz Worktop",
      "product_mpn": "SSWPS1117",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800027,
      "product_name": "Bespoke Silestone 30mm Desert Silver Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Desert Silver Quartz Worktop",
      "product_mpn": "SSWPS1114",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800026,
      "product_name": "Bespoke Silestone 30mm Bianco Calacatta Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Bianco Calacatta Quartz Worktop",
      "product_mpn": "SSWPS1113",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800025,
      "product_name": "Bespoke Silestone 30mm Eternal Calacatta Gold Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Eternal Calacatta Gold Quartz Worktop",
      "product_mpn": "SSWPS1112",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800024,
      "product_name": "Bespoke Silestone 30mm Bianco Maple Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Bianco Maple Quartz Worktop",
      "product_mpn": "SSWPS1111",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800023,
      "product_name": "Bespoke Silestone 30mm Miami White Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Miami White Quartz Worktop",
      "product_mpn": "SSWPS1108",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800022,
      "product_name": "Bespoke Silestone 30mm White Marble Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm White Marble Quartz Worktop",
      "product_mpn": "SSWPS1107",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800021,
      "product_name": "Bespoke Silestone 30mm Grey Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Grey Quartz Worktop",
      "product_mpn": "SSWPS1106",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800020,
      "product_name": "Bespoke Silestone 30mm White Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm White Quartz Worktop",
      "product_mpn": "SSWPS1104",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800019,
      "product_name": "Bespoke Silestone 30mm Grey Mirror Chip Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Grey Mirror Chip Quartz Worktop",
      "product_mpn": "SSWPS1103",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800018,
      "product_name": "Bespoke Silestone 30mm White Mirror Chip Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm White Mirror Chip Quartz Worktop",
      "product_mpn": "SSWPS1102",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800017,
      "product_name": "Bespoke Silestone 30mm Black Mirror Chip Quartz Worktop",
      "product_model": "Bespoke Silestone 30mm Black Mirror Chip Quartz Worktop",
      "product_mpn": "SSWPS1101",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800016,
      "product_name": "Bespoke Silestone 20mm Charcoal Soapstone Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Charcoal Soapstone Quartz Worktop",
      "product_mpn": "SSWPS1026",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800015,
      "product_name": "Bespoke Silestone 20mm White Arabesque Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm White Arabesque Quartz Worktop",
      "product_mpn": "SSWPS1024",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800014,
      "product_name": "Bespoke Silestone 20mm Eternal Marquina Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Eternal Marquina Quartz Worktop",
      "product_mpn": "SSWPS1021",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800013,
      "product_name": "Bespoke Silestone 20mm Alpina white Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Alpina white Quartz Worktop",
      "product_mpn": "SSWPS1017",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800012,
      "product_name": "Bespoke Silestone 20mm Desert Silver Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Desert Silver Quartz Worktop",
      "product_mpn": "SSWPS1014",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800011,
      "product_name": "Bespoke Silestone 20mm Bianco Calacatta Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Bianco Calacatta Quartz Worktop",
      "product_mpn": "SSWPS1013",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800010,
      "product_name": "Bespoke Silestone 20mm Eternal Calacatta Gold Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Eternal Calacatta Gold Quartz Worktop",
      "product_mpn": "SSWPS1012",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800009,
      "product_name": "Bespoke Silestone 20mm Bianco Maple Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Bianco Maple Quartz Worktop",
      "product_mpn": "SSWPS1011",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800008,
      "product_name": "Bespoke Silestone 20mm Miami White Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Miami White Quartz Worktop",
      "product_mpn": "SSWPS1008",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800007,
      "product_name": "Bepsoke Silestone 20mm White Marble Quartz Worktop",
      "product_model": "Bepsoke Silestone 20mm White Marble Quartz Worktop",
      "product_mpn": "SSWPS1007",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800006,
      "product_name": "Bespoke Silestone 20mm Grey Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Grey Quartz Worktop",
      "product_mpn": "SSWPS1006",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800005,
      "product_name": "Bespoke Silestone 20mm White Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm White Quartz Worktop",
      "product_mpn": "SSWPS1004",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800004,
      "product_name": "Bespoke Silestone 20mm Grey Mirror Chip Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Grey Mirror Chip Quartz Worktop",
      "product_mpn": "SSWPS1003",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800003,
      "product_name": "Bespoke Silestone 20mm White Mirror Chip Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm White Mirror Chip Quartz Worktop",
      "product_mpn": "SSWPS1002",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 16800002,
      "product_name": "Bespoke Silestone 20mm Black Mirror Chip Quartz Worktop",
      "product_model": "Bespoke Silestone 20mm Black Mirror Chip Quartz Worktop",
      "product_mpn": "SSWPS1001",
      "product_brand": "Howdens",
      "product_category": "ALL ROOMS",
      "product_subcategory": "WORKTOPS"
    },
    {
      "product_arid": 13900005,
      "product_name": "BetteLuxShape",
      "product_model": "BetteLuxShape",
      "product_mpn": "",
      "product_brand": "BETTE",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "BATHTUBS"
    },
    {
      "product_arid": 8000335,
      "product_name": "Samsung Galaxy Book2 Pro 12th Gen (15\") i5",
      "product_model": "Samsung Galaxy Book2 Pro 12th Gen (15\") i5",
      "product_mpn": "Samsung Galaxy Book2 Pro 12th Gen (15\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000337,
      "product_name": "Samsung Galaxy Book2 Pro 360 12th Gen (13.3\") i7",
      "product_model": "Samsung Galaxy Book2 Pro 360 12th Gen (13.3\") i7",
      "product_mpn": "Samsung Galaxy Book2 Pro 360 12th Gen (13.3\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000339,
      "product_name": "Samsung Galaxy Book2 Pro 360 12th Gen (15\") i7",
      "product_model": "Samsung Galaxy Book2 Pro 360 12th Gen (15\") i7",
      "product_mpn": "Samsung Galaxy Book2 Pro 360 12th Gen (15\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000402,
      "product_name": "Dell XPS 13 Notebook 9310 13.4\" 11th Gen Evo i7",
      "product_model": "Dell XPS 13 Notebook 9310 13.4\" 11th Gen Evo i7",
      "product_mpn": "Dell XPS 13 Notebook 9310 13.4\" 11th Gen Evo i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000397,
      "product_name": "Acer Aspire Vero Jade 15.6\" 12 gen Evo ",
      "product_model": "Acer Aspire Vero Jade 15.6\" 12 gen Evo ",
      "product_mpn": "Acer Aspire Vero Jade 15.6\" 12 gen Evo i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000392,
      "product_name": "Dell XPS 13 9315 12 gen Evo i5",
      "product_model": "Dell XPS 13 9315 12 gen Evo i5",
      "product_mpn": "Dell XPS 13 9315 12 gen Evo i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000343,
      "product_name": "Samsung Galaxy Book2 360 12th Gen (13\") i5",
      "product_model": "Samsung Galaxy Book2 360 12th Gen (13\") i5",
      "product_mpn": "Samsung Galaxy Book2 360 12th Gen (13\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000341,
      "product_name": "Microsoft Surface Pro 8 (13\") i5 11th Gen",
      "product_model": "Microsoft Surface Pro 8 (13\") i5 11th Gen",
      "product_mpn": "Microsoft Surface Pro 8 (13\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000342,
      "product_name": "ASUS Zenbook Pro 15 Flip OLED UP6502ZD (15.6\") i5",
      "product_model": "ASUS Zenbook Pro 15 Flip OLED UP6502ZD (15.6\") i5",
      "product_mpn": "ASUS Zenbook Pro 15 Flip OLED UP6502ZD (15.6\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000333,
      "product_name": "Samsung Galaxy Book2 Pro 12th Gen (13\") i5",
      "product_model": "Samsung Galaxy Book2 Pro 12th Gen (13\") i5",
      "product_mpn": "Samsung Galaxy Book2 Pro 12th Gen (13\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000288,
      "product_name": "Lenovo Yoga 7i Slim Carbon - 13\" i7",
      "product_model": "Lenovo Yoga 7i Slim Carbon - 13\" i7",
      "product_mpn": "Lenovo Yoga 7i Slim Carbon - 13\" i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000377,
      "product_name": "Acer Nitro 5 i7 12 gen (17.3\")",
      "product_model": "Acer Nitro 5 i7 12 gen (17.3\")",
      "product_mpn": "Acer Nitro 5 i7 (17.3\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000320,
      "product_name": "Acer Swift 3 SF314-512-52A812 (2022, 12 Gen) 14\" i5",
      "product_model": "Acer Swift 3 SF314-512-52A812 (2022, 12 Gen) 14\" i5",
      "product_mpn": "Acer Swift 3 (2022, 12 Gen) 14\" i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000287,
      "product_name": "Lenovo Legion 5i Pro (APJ) 16\" i7",
      "product_model": "Lenovo Legion 5i Pro (APJ) 16\" i7",
      "product_mpn": "Lenovo Legion 5i Pro (APJ) 16\" i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000286,
      "product_name": "Asus Zenbook 14 (UX3402) i7",
      "product_model": "Asus Zenbook 14 (UX3402) i7",
      "product_mpn": "Asus Zenbook 14 (UX3402) i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000255,
      "product_name": "HP Spectre x360 Frozone (16\") i7",
      "product_model": "HP Spectre x360 Frozone (16\") i7",
      "product_mpn": "HP Spectre x360 Frozone (16\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000249,
      "product_name": "Acer Swift 3 2022 (14\") i7",
      "product_model": "Acer Swift 3 2022 (14\") i7",
      "product_mpn": "Acer Swift 3 2022 (14\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000252,
      "product_name": "Asus Vivobook i7 (15\")",
      "product_model": "Asus Vivobook i7 (15\")",
      "product_mpn": "Asus Vivobook i7 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000251,
      "product_name": "Asus Vivobook S14 i7 (14\")",
      "product_model": "Asus Vivobook S14 i7 (14\")",
      "product_mpn": "Asus Vivobook S14 i7 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000250,
      "product_name": "MSI Pulse GL66 i7 (15\")",
      "product_model": "MSI Pulse GL66 i7 (15\")",
      "product_mpn": "MSI Pulse GL66 i7 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000246,
      "product_name": "Dell Alienware Aurora Desktop i7",
      "product_model": "Dell Alienware Aurora Desktop i7",
      "product_mpn": "Dell Alienware Aurora Desktop i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000237,
      "product_name": "Lenovo Legion Tower 7i Desktop i7",
      "product_model": "Lenovo Legion Tower 7i Desktop i7",
      "product_mpn": "Lenovo Legion Tower 7i Desktop i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000244,
      "product_name": "Acer Nitro 5 i7 (15\")",
      "product_model": "Acer Nitro 5 i7 (15\")",
      "product_mpn": "Acer Nitro 5 i7 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000243,
      "product_name": "Dell Alienware x14 i7 (14\")",
      "product_model": "Dell Alienware x14 i7 (14\")",
      "product_mpn": "Dell Alienware x14 i7 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000232,
      "product_name": "MSI Katana GF66 (15\") i7",
      "product_model": "MSI Katana GF66 (15\") i7",
      "product_mpn": "MSI Katana GF66 (15\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000238,
      "product_name": "HP Omen Laptop i7 (17\")",
      "product_model": "HP Omen Laptop i7 (17\")",
      "product_mpn": "HP Omen Laptop i7 (17\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000234,
      "product_name": "Dell G15 (15\") i7",
      "product_model": "Dell G15 (15\") i7",
      "product_mpn": "Dell G15 (15\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000241,
      "product_name": "Asus TUF Dash i7 (15\")",
      "product_model": "Asus TUF Dash i7 (15\")",
      "product_mpn": "Asus TUF Dash i7 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000245,
      "product_name": "Acer Predator Helios 300 (15\") i7",
      "product_model": "Acer Predator Helios 300 (15\") i7",
      "product_mpn": "Acer Predator Helios 300 (15\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000242,
      "product_name": "Asus ROG Strix Scar (17\") i7",
      "product_model": "Asus ROG Strix Scar (17\") i7",
      "product_mpn": "Asus ROG Strix Scar (17\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000233,
      "product_name": "MSI Raider GE76 (17\") i7",
      "product_model": "MSI Raider GE76 (17\") i7",
      "product_mpn": "MSI Raider GE76 (17\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000240,
      "product_name": "HP Spectre x360 Dashiel i7 (14\")",
      "product_model": "HP Spectre x360 Dashiel i7 (14\")",
      "product_mpn": "HP Spectre x360 i7 Dashiel (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000231,
      "product_name": "Dell Inspiron 2-in-1 (16\") i7",
      "product_model": "Dell Inspiron 2-in-1 (16\") i7",
      "product_mpn": "Dell Inspiron 2-in-1 (16\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000230,
      "product_name": "Lenovo Yoga 7i - i7 (14\")",
      "product_model": "Lenovo Yoga 7i - i7 (14\")",
      "product_mpn": "Lenovo Yoga 7i - i7 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000229,
      "product_name": "Dell XPS 13 (13.4\") i7",
      "product_model": "Dell XPS 13 (13.4\") i7",
      "product_mpn": "Dell XPS 13 (13.4\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000228,
      "product_name": "Lenovo Yoga Slim 7i PRO X (14\") i5",
      "product_model": "Lenovo Yoga Slim 7i PRO X (14\") i5",
      "product_mpn": "Lenovo Yoga Slim 7i PRO (14\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000227,
      "product_name": "Acer Swift 5 2022 i7 (14\")",
      "product_model": "Acer Swift 5 2022 i7 (14\")",
      "product_mpn": "Acer Swift 5 2022 i7 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000226,
      "product_name": "LG Gram Ultra-Lightweight (17\") i7",
      "product_model": "LG Gram Ultra-Lightweight (17\") i7",
      "product_mpn": "LG Gram Ultra-Lightweight (17\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000235,
      "product_name": "Asus Zenbook Pro 14 UX8402 i7 (14.5\")",
      "product_model": "Asus Zenbook Pro 14 UX8402 i7 (14.5\")",
      "product_mpn": "Asus Zenbook Pro 14 UX8402 i7 (14.5\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000224,
      "product_name": "Lenovo Yoga Slim 9i (14\") i7",
      "product_model": "Lenovo Yoga Slim 9i (14\") i7",
      "product_mpn": "Lenovo Yoga Slim 9i (14\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 1100009,
      "product_name": "Dell XPS 13 9315 (i7)",
      "product_model": "Dell XPS 13 9315 (i7)",
      "product_mpn": "Dell XPS 13 9315 (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 8000400,
      "product_name": "Dell G15 (15.6\") i5",
      "product_model": "Dell G15 (15.6\") i5",
      "product_mpn": "Dell G15 (15.6\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000399,
      "product_name": "Dell Inspiron i13 (13.3\") 11th gen Evo i7",
      "product_model": "Dell Inspiron i13 (13.3\") 11th gen Evo i7",
      "product_mpn": "Dell Inspiron i13 (13.3\") 11th gen Evo i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000398,
      "product_name": "Dell Inspiron i13 (13.3\") 11th gen Evo i5",
      "product_model": "Dell Inspiron i13 (13.3\") 11th gen Evo i5",
      "product_mpn": "Dell Inspiron i13 (13.3\") 11th gen Evo i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000395,
      "product_name": "LG Gram Ultra-Lightweight (17\") i5      ",
      "product_model": "LG Gram Ultra-Lightweight (17\") i5      ",
      "product_mpn": "LG Gram Ultra-Lightweight (17\") i5      ",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000396,
      "product_name": "Lenovo Yoga Slim 9i (14\") i5",
      "product_model": "Lenovo Yoga Slim 9i (14\") i5",
      "product_mpn": "Lenovo Yoga Slim 9i (14\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000393,
      "product_name": "HP Laptop Victus 16 inch 11 gen Intel Core i7",
      "product_model": "HP Laptop Victus 16 inch 11 gen Intel Core i7",
      "product_mpn": "HP Laptop Victus 16 inch 11 gen Intel Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000394,
      "product_name": "Samsung Galaxy Book2 Pro 360 12th Gen 13.3\" i5",
      "product_model": "Samsung Galaxy Book2 Pro 360 12th Gen 13.3\" i5",
      "product_mpn": "Samsung Galaxy Book2 Pro 360 12th Gen 13.3\" i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000391,
      "product_name": "Samsung Galaxy Book2 Pro 360 12th Gen 15 inch i5",
      "product_model": "Samsung Galaxy Book2 Pro 360 12th Gen 15 inch i5",
      "product_mpn": "Samsung Galaxy Book2 Pro 360 12th Gen 15 inch i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000334,
      "product_name": "Samsung Galaxy Book2 Pro 12th Gen (13\") i7",
      "product_model": "Samsung Galaxy Book2 Pro 12th Gen (13\") i7",
      "product_mpn": "Samsung Galaxy Book2 Pro 12th Gen (13\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000336,
      "product_name": "Samsung Galaxy Book2 Pro 12th Gen (15\") i7",
      "product_model": "Samsung Galaxy Book2 Pro 12th Gen (15\") i7",
      "product_mpn": "Samsung Galaxy Book2 Pro 12th Gen (15\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000345,
      "product_name": "ASUS Zenbook Pro 15 Flip OLED UP6502ZD (15.6\") i7",
      "product_model": "ASUS Zenbook Pro 15 Flip OLED UP6502ZD (15.6\") i7",
      "product_mpn": "ASUS Zenbook Pro 15 Flip OLED UP6502ZD (15.6\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000389,
      "product_name": "Lenovo Yoga 7i - i7 (16\")",
      "product_model": "Lenovo Yoga 7i - i7 (16\")",
      "product_mpn": "Lenovo Yoga 7i - i7 (16\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000390,
      "product_name": "Acer Swift 3 (2022, 12 Gen) 14\" i7",
      "product_model": "Acer Swift 3 (2022, 12 Gen) 14\" i7",
      "product_mpn": "Acer Swift 3 (2022, 12 Gen) 14\" i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000383,
      "product_name": "MSI Katana GF66 (15\") i5",
      "product_model": "MSI Katana GF66 (15\") i5",
      "product_mpn": "MSI Katana GF66 (15\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000344,
      "product_name": "Samsung Galaxy Book2 360 12th Gen (13\") i7",
      "product_model": "Samsung Galaxy Book2 360 12th Gen (13\") i7",
      "product_mpn": "Samsung Galaxy Book2 360 12th Gen (13\") i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000285,
      "product_name": "MSI Pulse GL66 i9 (15\")",
      "product_model": "MSI Pulse GL66 i9 (15\")",
      "product_mpn": "MSI Pulse GL66 i9 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000283,
      "product_name": "Dell Alienware Aurora Desktop i9",
      "product_model": "Dell Alienware Aurora Desktop i9",
      "product_mpn": "Dell Alienware Aurora Desktop i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000282,
      "product_name": "Lenovo Legion Tower 7i Desktop i9",
      "product_model": "Lenovo Legion Tower 7i Desktop i9",
      "product_mpn": "Lenovo Legion Tower 7i Desktop i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000281,
      "product_name": "Acer Nitro 5 i9 (15\")",
      "product_model": "Acer Nitro 5 i9 (15\")",
      "product_mpn": "Acer Nitro 5 i9 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000280,
      "product_name": "Dell Alienware x14 i9 (14\")",
      "product_model": "Dell Alienware x14 i9 (14\")",
      "product_mpn": "Dell Alienware x14 i9 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000279,
      "product_name": "MSI Katana GF66 (15\") i9",
      "product_model": "MSI Katana GF66 (15\") i9",
      "product_mpn": "MSI Katana GF66 (15\") i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000278,
      "product_name": "HP Omen Laptop (17\") i9",
      "product_model": "HP Omen Laptop (17\") i9",
      "product_mpn": "HP Omen Laptop (17\") i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000277,
      "product_name": "Dell G15 (15\") i9",
      "product_model": "Dell G15 (15\") i9",
      "product_mpn": "Dell G15 (15\") i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000276,
      "product_name": "Asus TUF Dash i9 (15\")",
      "product_model": "Asus TUF Dash i9 (15\")",
      "product_mpn": "Asus TUF Dash i9 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000275,
      "product_name": "Acer Predator Helios 300 (15\") i9",
      "product_model": "Acer Predator Helios 300 (15\") i9",
      "product_mpn": "Acer Predator Helios 300 (15\") i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000274,
      "product_name": "Asus ROG Strix Scar (17\") i9",
      "product_model": "Asus ROG Strix Scar (17\") i9",
      "product_mpn": "Asus ROG Strix Scar (17\") i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000273,
      "product_name": "MSI Raider GE76 (17\") i9",
      "product_model": "MSI Raider GE76 (17\") i9",
      "product_mpn": "MSI Raider GE76 (17\") i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000272,
      "product_name": "HP Spectre x360 (16\") i5",
      "product_model": "HP Spectre x360 (16\") i5",
      "product_mpn": "HP Spectre x360 (16\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000271,
      "product_name": "Acer Swift 3 2022 i5 (14\")",
      "product_model": "Acer Swift 3 2022 i5 (14\")",
      "product_mpn": "Acer Swift 3 2022 i5 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000270,
      "product_name": "Asus Vivobook i5 (15\")",
      "product_model": "Asus Vivobook i5 (15\")",
      "product_mpn": "Asus Vivobook i5 (15\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000269,
      "product_name": "Asus Vivobook S14 i5 (14\")",
      "product_model": "Asus Vivobook S14 i5 (14\")",
      "product_mpn": "Asus Vivobook S14 i5 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000268,
      "product_name": "HP Spectre x360 14 i5",
      "product_model": "HP Spectre x360 14 i5",
      "product_mpn": "HP Spectre x360 14 i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000266,
      "product_name": "Lenovo Yoga 7i - i5 (14\")",
      "product_model": "Lenovo Yoga 7i - i5 (14\")",
      "product_mpn": "Lenovo Yoga 7i - i5 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000265,
      "product_name": "Dell XPS 13 (13.4\") i5",
      "product_model": "Dell XPS 13 (13.4\") i5",
      "product_mpn": "Dell XPS 13 (13.4\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000264,
      "product_name": "Acer Swift 5 2022 i5 (14\")",
      "product_model": "Acer Swift 5 2022 i5 (14\")",
      "product_mpn": "Acer Swift 5 2022 i5 (14\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000222,
      "product_name": "ThinkPad x1 Yoga gen 6 animated",
      "product_model": "ThinkPad x1 Yoga gen 6 animated",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000221,
      "product_name": "Yoga 9 14 Black 5th gen",
      "product_model": "Yoga 9 14 Black 5th gen",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000220,
      "product_name": "Yoga 7 15 5th gen",
      "product_model": "Yoga 7 15 5th gen",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000219,
      "product_name": "Yoga 7 14 5th and 6th gen",
      "product_model": "Yoga 7 14 5th and 6th gen",
      "product_mpn": "",
      "product_brand": "Lenovo",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 600200000,
      "product_name": "Apple iWatch Series 3",
      "product_model": "Apple iWatch Series 3",
      "product_mpn": "Apple iWatch Series 3",
      "product_brand": "Apple",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "WELLBEING"
    },
    {
      "product_arid": 135001331010,
      "product_name": "Workflow testing with https",
      "product_model": "Workflow testing with https",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "LIVINGROOM",
      "product_subcategory": "SIDE TABLES"
    },
    {
      "product_arid": 13500126,
      "product_name": "RL38A7B63S9/EF",
      "product_model": "RL38A7B63S9/EF",
      "product_mpn": "RL38A7B63S9/EF",
      "product_brand": "Samsung",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 7000056,
      "product_name": "MSI Optix MAG275R",
      "product_model": "MSI Optix MAG275R",
      "product_mpn": "MSI Optix MAG275R",
      "product_brand": "MSI",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 3200100009,
      "product_name": "BTG FSDU",
      "product_model": "BTG FSDU",
      "product_mpn": "BTG FSDU",
      "product_brand": "Pladis",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 1001000300086,
      "product_name": "The Sero - 43\"",
      "product_model": "The Sero - 43\"",
      "product_mpn": "The Sero - 43\"",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300044,
      "product_name": "The Freestyle",
      "product_model": "The Freestyle",
      "product_mpn": "The Freestyle",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300043,
      "product_name": "The Serif (Blue)",
      "product_model": "The Serif (Blue)",
      "product_mpn": "The Serif (Blue)",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300042,
      "product_name": "The Serif (white)",
      "product_model": "The Serif (white)",
      "product_mpn": "The Serif (white)",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300041,
      "product_name": "The Frame - 85in",
      "product_model": "The Frame - 85in",
      "product_mpn": "The Frame - 85in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300018,
      "product_name": "QN900B",
      "product_model": "QN900B",
      "product_mpn": "QN900B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300017,
      "product_name": "QN800B",
      "product_model": "QN800B",
      "product_mpn": "QN800B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300016,
      "product_name": "QN700B",
      "product_model": "QN700B",
      "product_mpn": "QN700B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300015,
      "product_name": "QN95B",
      "product_model": "QN95B",
      "product_mpn": "QN95B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300014,
      "product_name": "S95B",
      "product_model": "S95B",
      "product_mpn": "S95B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300013,
      "product_name": "QN93B",
      "product_model": "QN93B",
      "product_mpn": "QN93B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300012,
      "product_name": "QN91B",
      "product_model": "QN91B",
      "product_mpn": "QN91B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300011,
      "product_name": "QN90B",
      "product_model": "QN90B",
      "product_mpn": "QN90B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300010,
      "product_name": "QN85B",
      "product_model": "QN85B",
      "product_mpn": "QN85B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300009,
      "product_name": "Q83B",
      "product_model": "Q83B",
      "product_mpn": "Q83B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300008,
      "product_name": "Q80B",
      "product_model": "Q80B",
      "product_mpn": "Q80B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300007,
      "product_name": "Q77B",
      "product_model": "Q77B",
      "product_mpn": "Q77B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300006,
      "product_name": "Q75B",
      "product_model": "Q75B",
      "product_mpn": "Q75B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300005,
      "product_name": "Q70B",
      "product_model": "Q70B",
      "product_mpn": "Q70B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300004,
      "product_name": "Q68B",
      "product_model": "Q68B",
      "product_mpn": "Q68B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300003,
      "product_name": "Q65B",
      "product_model": "Q65B",
      "product_mpn": "Q65B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300002,
      "product_name": "Q60B",
      "product_model": "Q60B",
      "product_mpn": "Q60B",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300089,
      "product_name": "The Terrace 55\" (2022)",
      "product_model": "The Terrace 55\" (2022)",
      "product_mpn": "The Terrace 55\" (2022)",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300088,
      "product_name": "The Terrace 65\" (2022)",
      "product_model": "The Terrace 65\" (2022)",
      "product_mpn": "The Terrace 65\" (2022)",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300087,
      "product_name": "The Terrace 75\" (2022)",
      "product_model": "The Terrace 75\" (2022)",
      "product_mpn": "The Terrace 75\" (2022)",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300085,
      "product_name": "The Serif (Blue) - 55in",
      "product_model": "The Serif (Blue) - 55in",
      "product_mpn": "The Serif (Blue) - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300084,
      "product_name": "The Serif (blue) - 43in",
      "product_model": "The Serif (blue) - 43in",
      "product_mpn": "The Serif (blue) - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300083,
      "product_name": "The Serif (blue) - 50in",
      "product_model": "The Serif (blue) - 50in",
      "product_mpn": "The Serif (blue) - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300082,
      "product_name": "The Serif (white) - 43in",
      "product_model": "The Serif (white) - 43in",
      "product_mpn": "The Serif (white) - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300081,
      "product_name": "The Serif (white) - 50in",
      "product_model": "The Serif (white) - 50in",
      "product_mpn": "The Serif (white) - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300080,
      "product_name": "The Serif (white) - 55in",
      "product_model": "The Serif (white) - 55in",
      "product_mpn": "The Serif (white) - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300079,
      "product_name": "The Frame - 32in",
      "product_model": "The Frame - 32in",
      "product_mpn": "The Frame - 32in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300078,
      "product_name": "The Frame - 43in",
      "product_model": "The Frame - 43in",
      "product_mpn": "The Frame - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300077,
      "product_name": "The Frame - 50in",
      "product_model": "The Frame - 50in",
      "product_mpn": "The Frame - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300076,
      "product_name": "The Frame - 55in",
      "product_model": "The Frame - 55in",
      "product_mpn": "The Frame - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300075,
      "product_name": "The Frame - 65in",
      "product_model": "The Frame - 65in",
      "product_mpn": "The Frame - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300074,
      "product_name": "The Frame - 75in",
      "product_model": "The Frame - 75in",
      "product_mpn": "The Frame - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300073,
      "product_name": "Q60B - 43in",
      "product_model": "Q60B - 43in",
      "product_mpn": "Q60B - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300072,
      "product_name": "Q60B - 50in",
      "product_model": "Q60B - 50in",
      "product_mpn": "Q60B - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300071,
      "product_name": "Q60B - 55in",
      "product_model": "Q60B - 55in",
      "product_mpn": "Q60B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300070,
      "product_name": "Q60B - 65in",
      "product_model": "Q60B - 65in",
      "product_mpn": "Q60B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300069,
      "product_name": "Q60B - 75in",
      "product_model": "Q60B - 75in",
      "product_mpn": "Q60B - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300068,
      "product_name": "Q65B - 43in",
      "product_model": "Q65B - 43in",
      "product_mpn": "Q65B - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300067,
      "product_name": "Q65B - 50in",
      "product_model": "Q65B - 50in",
      "product_mpn": "Q65B - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300066,
      "product_name": "Q65B - 55in",
      "product_model": "Q65B - 55in",
      "product_mpn": "Q65B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300065,
      "product_name": "Q65 - 65in",
      "product_model": "Q65 - 65in",
      "product_mpn": "Q65 - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300064,
      "product_name": "Q68B - 43in",
      "product_model": "Q68B - 43in",
      "product_mpn": "Q68B - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300063,
      "product_name": "Q68B - 50in",
      "product_model": "Q68B - 50in",
      "product_mpn": "Q68B - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300062,
      "product_name": "Q68B - 55in",
      "product_model": "Q68B - 55in",
      "product_mpn": "Q68B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300061,
      "product_name": "Q68B - 65in",
      "product_model": "Q68B - 65in",
      "product_mpn": "Q68B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300060,
      "product_name": "Q70B - 55in",
      "product_model": "Q70B - 55in",
      "product_mpn": "Q70B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300059,
      "product_name": "Q70B - 65in",
      "product_model": "Q70B - 65in",
      "product_mpn": "Q70B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300058,
      "product_name": "Q70B - 75in",
      "product_model": "Q70B - 75in",
      "product_mpn": "Q70B - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300057,
      "product_name": "Q75B - 55in",
      "product_model": "Q75B - 55in",
      "product_mpn": "Q75B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300056,
      "product_name": "Q75B - 65in",
      "product_model": "Q75B - 65in",
      "product_mpn": "Q75B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300055,
      "product_name": "Q77B - 55in",
      "product_model": "Q77B - 55in",
      "product_mpn": "Q77B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300054,
      "product_name": "Q77B - 65in",
      "product_model": "Q77B - 65in",
      "product_mpn": "Q77B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300053,
      "product_name": "Q80B - 50in",
      "product_model": "Q80B - 50in",
      "product_mpn": "Q80B - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300052,
      "product_name": "Q80B - 55in",
      "product_model": "Q80B - 55in",
      "product_mpn": "Q80B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300051,
      "product_name": "Q80B - 65in",
      "product_model": "Q80B - 65in",
      "product_mpn": "Q80B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300050,
      "product_name": "Q80B - 75in",
      "product_model": "Q80B - 75in",
      "product_mpn": "Q80B - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300049,
      "product_name": "Q83B - 55in",
      "product_model": "Q83B - 55in",
      "product_mpn": "Q83B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300048,
      "product_name": "Q83B - 65in",
      "product_model": "Q83B - 65in",
      "product_mpn": "Q83B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300047,
      "product_name": "QN85B - 55in",
      "product_model": "QN85B - 55in",
      "product_mpn": "QN85B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300046,
      "product_name": "QN85B - 65in",
      "product_model": "QN85B - 65in",
      "product_mpn": "QN85B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300045,
      "product_name": "QN85B - 75in",
      "product_model": "QN85B - 75in",
      "product_mpn": "QN85B - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300040,
      "product_name": "QN90B - 43in",
      "product_model": "QN90B - 43in",
      "product_mpn": "QN90B - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300039,
      "product_name": "QN90B - 50in",
      "product_model": "QN90B - 50in",
      "product_mpn": "QN90B - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300038,
      "product_name": "QN90B - 55in",
      "product_model": "QN90B - 55in",
      "product_mpn": "QN90B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300037,
      "product_name": "QN90B - 65in",
      "product_model": "QN90B - 65in",
      "product_mpn": "QN90B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300036,
      "product_name": "QN90B - 75in",
      "product_model": "QN90B - 75in",
      "product_mpn": "QN90B - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300035,
      "product_name": "QN91B - 43in",
      "product_model": "QN91B - 43in",
      "product_mpn": "QN91B - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300034,
      "product_name": "QN91B - 50in",
      "product_model": "QN91B - 50in",
      "product_mpn": "QN91B - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300033,
      "product_name": "QN91B - 55in",
      "product_model": "QN91B - 55in",
      "product_mpn": "QN91B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300032,
      "product_name": "QN91B - 65in",
      "product_model": "QN91B - 65in",
      "product_mpn": "QN91B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300031,
      "product_name": "QN93B - 43in",
      "product_model": "QN93B - 43in",
      "product_mpn": "QN93B - 43in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300030,
      "product_name": "QN93B - 50in",
      "product_model": "QN93B - 50in",
      "product_mpn": "QN93B - 50in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300029,
      "product_name": "QN93B - 55in",
      "product_model": "QN93B - 55in",
      "product_mpn": "QN93B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300028,
      "product_name": "QN93B - 65in",
      "product_model": "QN93B - 65in",
      "product_mpn": "QN93B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300027,
      "product_name": "QN95B - 55in",
      "product_model": "QN95B - 55in",
      "product_mpn": "QN95B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300026,
      "product_name": "QN95B - 65in",
      "product_model": "QN95B - 65in",
      "product_mpn": "QN95B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300025,
      "product_name": "QN95B - 75in",
      "product_model": "QN95B - 75in",
      "product_mpn": "QN95B - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300024,
      "product_name": "QN700B - 55in",
      "product_model": "QN700B - 55in",
      "product_mpn": "QN700B - 55in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300023,
      "product_name": "QN700B - 65in",
      "product_model": "QN700B - 65in",
      "product_mpn": "QN700B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300022,
      "product_name": "QN800B - 65in",
      "product_model": "QN800B - 65in",
      "product_mpn": "QN800B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300021,
      "product_name": "QN800B - 75in ",
      "product_model": "QN800B - 75in ",
      "product_mpn": "QN800B - 75in ",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300020,
      "product_name": "QN900B - 65in",
      "product_model": "QN900B - 65in",
      "product_mpn": "QN900B - 65in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 1001000300019,
      "product_name": "QN900B - 75in",
      "product_model": "QN900B - 75in",
      "product_mpn": "QN900B - 75in",
      "product_brand": "Samsung",
      "product_category": "SOUND & VISION",
      "product_subcategory": "TELEVISIONS"
    },
    {
      "product_arid": 600200001,
      "product_name": "Garmin Venu® 2 Plus",
      "product_model": "Garmin Venu® 2 Plus",
      "product_mpn": "Garmin Venu® 2 Plus - Slate Stainless Steel Bezel with Black Case and Silicone Band",
      "product_brand": "Best Buy",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "WELLBEING"
    },
    {
      "product_arid": 13500133,
      "product_name": "Insignia™ - 25.4 Cu. Ft. French Door Refrigerator with Water Dispenser - Stainless steel",
      "product_model": "Insignia™ - 25.4 Cu. Ft. French Door Refrigerator with Water Dispenser - Stainless steel",
      "product_mpn": "NS-RFD26XSS0",
      "product_brand": "Best Buy",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 3200100013,
      "product_name": "Slim Medium GDC",
      "product_model": "Slim Medium GDC",
      "product_mpn": "Slim Medium GDC",
      "product_brand": "Red Bull",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 3200100012,
      "product_name": "Slim Mega GDC",
      "product_model": "Slim Mega GDC",
      "product_mpn": "Slim Mega GDC",
      "product_brand": "Red Bull",
      "product_category": "PRODUCT DISPLAY",
      "product_subcategory": "FDSU"
    },
    {
      "product_arid": 6800043,
      "product_name": "Intel PC Rooms Unbranded Earbud Headphones",
      "product_model": "Intel PC Rooms Unbranded Earbud Headphones",
      "product_mpn": "Intel PC Rooms Unbranded Earbud Headphones",
      "product_brand": "intel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 12400031,
      "product_name": "Intel PC Rooms Unbranded Ring Light",
      "product_model": "Intel PC Rooms Unbranded Ring Light",
      "product_mpn": "Intel PC Rooms Unbranded Ring Light",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 7100016,
      "product_name": "Intel PC Rooms Unbranded Standing Microphone",
      "product_model": "Intel PC Rooms Unbranded Standing Microphone",
      "product_mpn": "Intel PC Rooms Unbranded Standing Microphone",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 6800041,
      "product_name": "Nonbranded version of Sony WH-1000XM3 headphones, variant of ARID 6800021",
      "product_model": "Nonbranded version of Sony WH-1000XM3 headphones, variant of ARID 6800021",
      "product_mpn": "Nonbranded version of Sony WH-1000XM3 headphones, variant of ARID 6800021",
      "product_brand": "intel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6900102,
      "product_name": "Nonbranded Microsoft Keyboard - Variant of ARID 500600003",
      "product_model": "Nonbranded Microsoft Keyboard - Variant of ARID 500600003",
      "product_mpn": "Nonbranded Microsoft Keyboard - Variant of ARID 500600003",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900100,
      "product_name": "Nonbranded Microsoft Mouse, Variant of 500600003",
      "product_model": "Nonbranded Microsoft Mouse, Variant of 500600003",
      "product_mpn": "Nonbranded Microsoft Mouse, Variant of 500600003",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 8000260,
      "product_name": "Nonbranded - Iiyama G-Master 24.5\" Full HD Monitor, variant of ARID 7000038",
      "product_model": "Nonbranded - Iiyama G-Master 24.5\" Full HD Monitor, variant of ARID 7000038",
      "product_mpn": "Iiyama G-Master G2530HSU-B1 24.5\" Full HD Monitor",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 8000254,
      "product_name": "Intel EVO avatar",
      "product_model": "Intel EVO avatar",
      "product_mpn": "Intel EVO avatar",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 6800079,
      "product_name": "zone vibe 100",
      "product_model": "zone vibe 100",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6900114,
      "product_name": "MX MECHANICAL MINI Graphite",
      "product_model": "MX MECHANICAL MINI Graphite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900113,
      "product_name": "MX MECHANICAL Graphite",
      "product_model": "MX MECHANICAL Graphite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500068,
      "product_name": "LIFT FOR BUSINESS LEFT -GRAPHITE / BLACK",
      "product_model": "LIFT FOR BUSINESS LEFT -GRAPHITE / BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 7100014,
      "product_name": "Blackout Spark SL XLR Condenser Mic",
      "product_model": "Blackout Spark SL XLR Condenser Mic",
      "product_mpn": "Blackout Spark SL XLR Condenser Mic",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 500500045,
      "product_name": "POP Mouse with emoji HEARTBREAKER_ROSE",
      "product_model": "POP Mouse with emoji HEARTBREAKER_ROSE",
      "product_mpn": "POP Mouse with emoji HEARTBREAKER_ROSE",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500044,
      "product_name": "POP Mouse with emoji DAYDREAM_MINT",
      "product_model": "POP Mouse with emoji DAYDREAM_MINT",
      "product_mpn": "POP Mouse with emoji DAYDREAM_MINT",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500043,
      "product_name": "POP Mouse with emoji BLAST_YELLOW",
      "product_model": "POP Mouse with emoji BLAST_YELLOW",
      "product_mpn": "POP Mouse with emoji BLAST_YELLOW",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900099,
      "product_name": "POP KEYS DAYDREAM_MINT",
      "product_model": "POP KEYS DAYDREAM_MINT",
      "product_mpn": "POP KEYS DAYDREAM_MINT",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900098,
      "product_name": "POP KEYS BLAST_YELLOW",
      "product_model": "POP KEYS BLAST_YELLOW",
      "product_mpn": "POP KEYS BLAST_YELLOW",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900097,
      "product_name": "MX KEYS MINI Mac",
      "product_model": "MX KEYS MINI Mac",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900096,
      "product_name": "MX KEYS MINI Pale Grey",
      "product_model": "MX KEYS MINI Pale Grey",
      "product_mpn": "MX KEYS MINI Pale Grey",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500042,
      "product_name": "M650 L LEFT GRAPHITE",
      "product_model": "M650 L LEFT GRAPHITE",
      "product_mpn": "M650 L LEFT GRAPHITE",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500041,
      "product_name": "M650 L GRAPHITE",
      "product_model": "M650 L GRAPHITE",
      "product_mpn": "M650 L GRAPHITE",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500040,
      "product_name": "M650",
      "product_model": "M650",
      "product_mpn": "M650",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 1000016,
      "product_name": "C922X PRO STREAM WEBCAM",
      "product_model": "C922X PRO STREAM WEBCAM",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 301737,
      "product_name": "Fern Footstool  - Blue Fabric",
      "product_model": "Fern Footstool  - Blue Fabric",
      "product_mpn": "Fern Footstool  - Blue Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301738,
      "product_name": "Fern Footstool  - Green Fabric",
      "product_model": "Fern Footstool  - Green Fabric",
      "product_mpn": "Fern Footstool  - Green Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301739,
      "product_name": "Fern Footstool  - Gold Fabric",
      "product_model": "Fern Footstool  - Gold Fabric",
      "product_mpn": "Fern Footstool  - Gold Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 8000256,
      "product_name": "Intel Evo Generic",
      "product_model": "Intel Evo Generic",
      "product_mpn": "Intel Evo Generic",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 6900103,
      "product_name": "Rival 600 - Gaming Mice - Variant of ARID 500500021",
      "product_model": "Rival 600 - Gaming Mice - Variant of ARID 500500021",
      "product_mpn": "Rival 600 - Gaming Mice - Variant of ARID 500500021",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900101,
      "product_name": "Apex Pro Gaming Keyboard - Variant of ARID 6900021",
      "product_model": "Apex Pro Gaming Keyboard - Variant of ARID 6900021",
      "product_mpn": "Apex Pro Gaming Keyboard - Variant of ARID 6900021",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 12400030,
      "product_name": "MSI MPG ARTYMIS 343CQR - Gaming Monitor - Variant of ARID 700037",
      "product_model": "MSI MPG ARTYMIS 343CQR - Gaming Monitor - Variant of ARID 700037",
      "product_mpn": "MSI MPG ARTYMIS 343CQR",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 301753,
      "product_name": "Claremont Accent Chair - Aurora Ruby Fabric",
      "product_model": "Claremont Accent Chair - Aurora Ruby Fabric",
      "product_mpn": "Claremont Accent Chair - Aurora Ruby Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301752,
      "product_name": "Claremont Accent Chair - Aurora Willow Fabric",
      "product_model": "Claremont Accent Chair - Aurora Willow Fabric",
      "product_mpn": "Claremont Accent Chair - Aurora Willow Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301751,
      "product_name": "Claremont Accent Chair - Aurora Cream Fabric",
      "product_model": "Claremont Accent Chair - Aurora Cream Fabric",
      "product_mpn": "Claremont Accent Chair - Aurora Cream Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301750,
      "product_name": "Claremont Accent Chair - Aurora Truffle Fabric",
      "product_model": "Claremont Accent Chair - Aurora Truffle Fabric",
      "product_mpn": "Claremont Accent Chair - Aurora Truffle Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301749,
      "product_name": "Claremont Accent Chair - Aurora Ochre Fabric",
      "product_model": "Claremont Accent Chair - Aurora Ochre Fabric",
      "product_mpn": "Claremont Accent Chair - Aurora Ochre Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301748,
      "product_name": "Claremont Accent Chair - Aurora Navy Fabric",
      "product_model": "Claremont Accent Chair - Aurora Navy Fabric",
      "product_mpn": "Claremont Accent Chair - Aurora Navy Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301747,
      "product_name": "Claremont Accent Chair - Aurora Aqua Fabric",
      "product_model": "Claremont Accent Chair - Aurora Aqua Fabric",
      "product_mpn": "Claremont Accent Chair - Aurora Aqua Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301746,
      "product_name": "Claremont Accent Storage Footstool - Aurora Ruby Fabric",
      "product_model": "Claremont Accent Storage Footstool - Aurora Ruby Fabric",
      "product_mpn": "Claremont Accent Storage Footstool - Aurora Ruby Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301745,
      "product_name": "Claremont Accent Storage Footstool - Aurora Willow Fabric",
      "product_model": "Claremont Accent Storage Footstool - Aurora Willow Fabric",
      "product_mpn": "Claremont Accent Storage Footstool - Aurora Willow Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301744,
      "product_name": "Claremont Accent Storage Footstool - Aurora Cream Fabric",
      "product_model": "Claremont Accent Storage Footstool - Aurora Cream Fabric",
      "product_mpn": "Claremont Accent Storage Footstool - Aurora Cream Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301743,
      "product_name": "Claremont Accent Storage Footstool - Aurora Truffle Fabric",
      "product_model": "Claremont Accent Storage Footstool - Aurora Truffle Fabric",
      "product_mpn": "Claremont Accent Storage Footstool - Aurora Truffle Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301742,
      "product_name": "Claremont Accent Storage Footstool - Aurora Ochre Fabric",
      "product_model": "Claremont Accent Storage Footstool - Aurora Ochre Fabric",
      "product_mpn": "Claremont Accent Storage Footstool - Aurora Ochre Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301741,
      "product_name": "Claremont Accent Storage Footstool - Aurora Navy Fabric",
      "product_model": "Claremont Accent Storage Footstool - Aurora Navy Fabric",
      "product_mpn": "Claremont Accent Storage Footstool - Aurora Navy Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301740,
      "product_name": "Claremont Accent Storage Footstool - Aurora Aqua Fabric",
      "product_model": "Claremont Accent Storage Footstool - Aurora Aqua Fabric",
      "product_mpn": "Claremont Accent Storage Footstool - Aurora Aqua Fabric",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301763,
      "product_name": "Hampton Storage Footstool - Pebble & Silver Gingham",
      "product_model": "Hampton Storage Footstool - Pebble & Silver Gingham",
      "product_mpn": "Hampton Storage Footstool - Pebble & Silver Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301762,
      "product_name": "Hampton Storage Footstool - Silver & Slate Gingham",
      "product_model": "Hampton Storage Footstool - Silver & Slate Gingham",
      "product_mpn": "Hampton Storage Footstool - Silver & Slate Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301761,
      "product_name": "Hampton Storage Footstool - Mocha & Pebble Gingham",
      "product_model": "Hampton Storage Footstool - Mocha & Pebble Gingham",
      "product_mpn": "Hampton Storage Footstool - Mocha & Pebble Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301760,
      "product_name": "Hampton Storage Footstool - Cappuccino & Latte Gingham",
      "product_model": "Hampton Storage Footstool - Cappuccino & Latte Gingham",
      "product_mpn": "Hampton Storage Footstool - Cappuccino & Latte Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301759,
      "product_name": "Hampton Storage Footstool - Duck Egg & Natural Gingham",
      "product_model": "Hampton Storage Footstool - Duck Egg & Natural Gingham",
      "product_mpn": "Hampton Storage Footstool - Duck Egg & Natural Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301758,
      "product_name": "Hampton Accent Chair - Pebble & Silver Gingham",
      "product_model": "Hampton Accent Chair - Pebble & Silver Gingham",
      "product_mpn": "Hampton Accent Chair - Pebble & Silver Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301757,
      "product_name": "Hampton Accent Chair - Silver & Slate Gingham",
      "product_model": "Hampton Accent Chair - Silver & Slate Gingham",
      "product_mpn": "Hampton Accent Chair - Silver & Slate Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301756,
      "product_name": "Hampton Accent Chair - Mocha & Pebble Gingham",
      "product_model": "Hampton Accent Chair - Mocha & Pebble Gingham",
      "product_mpn": "Hampton Accent Chair - Mocha & Pebble Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301755,
      "product_name": "Hampton Accent Chair - Cappuccino & Latte Gingham",
      "product_model": "Hampton Accent Chair - Cappuccino & Latte Gingham",
      "product_mpn": "Hampton Accent Chair - Cappuccino & Latte Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301754,
      "product_name": "Hampton Accent Chair - Duck Egg & Natural Gingham",
      "product_model": "Hampton Accent Chair - Duck Egg & Natural Gingham",
      "product_mpn": "Hampton Accent Chair - Duck Egg & Natural Gingham",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301772,
      "product_name": "Roma Leather -Roma Swivel Chair-Anthracite Leather",
      "product_model": "Roma Leather -Roma Swivel Chair-Anthracite Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301771,
      "product_name": "Roma Fabric -Roma Swivel Chair-Grey Fabric",
      "product_model": "Roma Fabric -Roma Swivel Chair-Grey Fabric",
      "product_mpn": "RMA038-U030066-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301770,
      "product_name": "Milano Leather- Milano 3 Seater Sofa-Off White Leather",
      "product_model": "Milano Leather- Milano 3 Seater Sofa-Off White Leather",
      "product_mpn": "Milano 3 Seater Sofa",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301769,
      "product_name": "Vittoria Leather- Vittoria 3 Seater Sofa-Smoke Leather",
      "product_model": "Vittoria Leather- Vittoria 3 Seater Sofa-Smoke Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301768,
      "product_name": "Vittoria Fabric- Vittoria 3 Seater Sofa-Mink Fabric",
      "product_model": "Vittoria Fabric- Vittoria 3 Seater Sofa-Mink Fabric",
      "product_mpn": "VTO003-Z370009-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301767,
      "product_name": "Sienna Leather- Sienna 3 Seater Sofa-White Leather",
      "product_model": "Sienna Leather- Sienna 3 Seater Sofa-White Leather",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301766,
      "product_name": "Sienna Fabric-Sienna 3 Seater Sofa-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna 3 Seater Sofa-Grey Fabric",
      "product_mpn": "SNA003-Z361009-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301765,
      "product_name": "Sorrento Leather-Sorrento 3 Seater Sofa-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento 3 Seater Sofa-Anthracite Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301764,
      "product_name": "Sorrento Fabric- Sorrento 3 Seater Sofa-Silver Fabric",
      "product_model": "Sorrento Fabric- Sorrento 3 Seater Sofa-Silver Fabric",
      "product_mpn": "SRN003-U226009-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301821,
      "product_name": "Milano-Milano Storage Footstool -Off White Leather",
      "product_model": "Milano-Milano Storage Footstool -Off White Leather",
      "product_mpn": "MNO010-U323253-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301820,
      "product_name": "Milano-Milano Triple Motion Recliner Armchair -Off White Leather",
      "product_model": "Milano-Milano Triple Motion Recliner Armchair -Off White Leather",
      "product_mpn": "MNO301-Z365N54-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301819,
      "product_name": "Milano-Milano Armchair-Off White Leather",
      "product_model": "Milano-Milano Armchair-Off White Leather",
      "product_mpn": "MNO001-Z365003-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301818,
      "product_name": "Milano-Milano Right Hand Chaise Sofa with Triple Motion Recliner-Off White Leather",
      "product_model": "Milano-Milano Right Hand Chaise Sofa with Triple Motion Recliner-Off White Leather",
      "product_mpn": "MNO359R-Z365N50291049-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301817,
      "product_name": "Milano-Milano Left Hand Chaise Sofa with Triple Motion Recliner-Off White Leather",
      "product_model": "Milano-Milano Left Hand Chaise Sofa with Triple Motion Recliner-Off White Leather",
      "product_mpn": "MNO359L-Z365047291N52-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301816,
      "product_name": "Milano-Milano 2 Seater Triple Motion Recliner Sofa-Off White Leather",
      "product_model": "Milano-Milano 2 Seater Triple Motion Recliner Sofa-Off White Leather",
      "product_mpn": "MNO302M-Z365M93-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301815,
      "product_name": "Milano-Milano 2 Seater Sofa-Off White Leather",
      "product_model": "Milano-Milano 2 Seater Sofa-Off White Leather",
      "product_mpn": "MNO002-Z365005-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301814,
      "product_name": "Milano-Milano 3 Seater Triple Motion Recliner Sofa-Off White Leather",
      "product_model": "Milano-Milano 3 Seater Triple Motion Recliner Sofa-Off White Leather",
      "product_mpn": "MNO303M-Z365M46-LEA-A0YQ-OFW",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301813,
      "product_name": "Vittoria Leather-Vittoria Footstool-Smoke Leather",
      "product_model": "Vittoria Leather-Vittoria Footstool-Smoke Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301812,
      "product_name": "Vittoria Leather-Vittoria Armchair-Smoke Leather",
      "product_model": "Vittoria Leather-Vittoria Armchair-Smoke Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301811,
      "product_name": "Vittoria Leather-Vittoria 2 Seater Sofa-Smoke Leather",
      "product_model": "Vittoria Leather-Vittoria 2 Seater Sofa-Smoke Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301810,
      "product_name": "Vittoria Fabric-Vittoria Footstool-Mink Fabric",
      "product_model": "Vittoria Fabric-Vittoria Footstool-Mink Fabric",
      "product_mpn": "VTOFOOT-Z370253-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301809,
      "product_name": "Vittoria Fabric-Vittoria Armchair-Mink Fabric",
      "product_model": "Vittoria Fabric-Vittoria Armchair-Mink Fabric",
      "product_mpn": "VTO001-Z370003-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301808,
      "product_name": "Vittoria Fabric-Vittoria 2 Seater Sofa-Mink Fabric",
      "product_model": "Vittoria Fabric-Vittoria 2 Seater Sofa-Mink Fabric",
      "product_mpn": "VTO001-Z370003-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301807,
      "product_name": "Sienna Leather-Sienna Footstool-White Leather",
      "product_model": "Sienna Leather-Sienna Footstool-White Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301806,
      "product_name": "Sienna Leather-Sienna Recliner Armchair-White Leather",
      "product_model": "Sienna Leather-Sienna Recliner Armchair-White Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301805,
      "product_name": "Sienna Leather-Sienna Armchair-White Leather",
      "product_model": "Sienna Leather-Sienna Armchair-White Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301804,
      "product_name": "Sienna Leather-Sienna Right Hand Corner Sofa with One Recliner-White Leather",
      "product_model": "Sienna Leather-Sienna Right Hand Corner Sofa with One Recliner-White Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301803,
      "product_name": "Sienna Leather-Sienna Left Hand Corner Sofa with One Recliner-White Leather",
      "product_model": "Sienna Leather-Sienna Left Hand Corner Sofa with One Recliner-White Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301802,
      "product_name": "Sienna Leather-Sienna 2 Seater Recliner Sofa-White Leather",
      "product_model": "Sienna Leather-Sienna 2 Seater Recliner Sofa-White Leather",
      "product_mpn": "SNA202M-Z361T30-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301801,
      "product_name": "Sienna Leather-Sienna 2 Seater Sofa-White Leather",
      "product_model": "Sienna Leather-Sienna 2 Seater Sofa-White Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301800,
      "product_name": "Sienna Leather-Sienna 3 Seater Recliner Sofa-White Leather",
      "product_model": "Sienna Leather-Sienna 3 Seater Recliner Sofa-White Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301799,
      "product_name": "Sienna Fabric-Sienna Footstool-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna Footstool-Grey Fabric",
      "product_mpn": "SNAFOOT-Z361243-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301798,
      "product_name": "Sienna Fabric-Sienna Recliner Armchair-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna Recliner Armchair-Grey Fabric",
      "product_mpn": "SNA201-Z361154-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301797,
      "product_name": "Sienna Fabric-Sienna Armchair-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna Armchair-Grey Fabric",
      "product_mpn": "SNA001-Z361003-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301796,
      "product_name": "Sienna Fabric-Sienna Right Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna Right Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_mpn": "SNA221R-Z361183200-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301792,
      "product_name": "Sienna Fabric-Sienna Left Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna Left Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_mpn": "SNA221L-Z361182201-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301791,
      "product_name": "Sienna Fabric-Sienna 2 Seater Recliner Sofa-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna 2 Seater Recliner Sofa-Grey Fabric",
      "product_mpn": "SNA202M-Z361T30-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301790,
      "product_name": "Sienna Fabric-Sienna 2 Seater Sofa-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna 2 Seater Sofa-Grey Fabric",
      "product_mpn": "SNA002-Z361005-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301789,
      "product_name": "Sienna Fabric-Sienna 3 Seater Recliner Sofa-Grey Fabric",
      "product_model": "Sienna Fabric-Sienna 3 Seater Recliner Sofa-Grey Fabric",
      "product_mpn": "SNA203M-Z361T66-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301788,
      "product_name": "Sorrento Leather-Sorrento Storage Footstool-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento Storage Footstool-Anthracite Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301787,
      "product_name": "Sorrento Leather-Sorrento Recliner Armchair-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento Recliner Armchair-Anthracite Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301786,
      "product_name": "Sorrento Leather-Sorrento Armchair-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento Armchair-Anthracite Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301785,
      "product_name": "Sorrento Leather-Sorrento Right Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento Right Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301784,
      "product_name": "Sorrento Leather-Sorrento Left Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento Left Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301783,
      "product_name": "Sorrento Leather-Sorrento 2 Seater Recliner Sofa-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento 2 Seater Recliner Sofa-Anthracite Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301782,
      "product_name": "Sorrento Leather-Sorrento 2 Seater Sofa-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento 2 Seater Sofa-Anthracite Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301781,
      "product_name": "Sorrento Leather-Sorrento 3 Seater Recliner Sofa-Anthracite Leather",
      "product_model": "Sorrento Leather-Sorrento 3 Seater Recliner Sofa-Anthracite Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301780,
      "product_name": "Sorrento Fabric-Sorrento Storage Footstool-Silver Fabric",
      "product_model": "Sorrento Fabric-Sorrento Storage Footstool-Silver Fabric",
      "product_mpn": "SRN010-U323253-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301779,
      "product_name": "Sorrento Fabric-Sorrento Recliner Armchair-Silver Fabric",
      "product_model": "Sorrento Fabric-Sorrento Recliner Armchair-Silver Fabric",
      "product_mpn": "SRN201-U226154-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301778,
      "product_name": "Sorrento Fabric-Sorrento Armchair-Silver Fabric",
      "product_model": "Sorrento Fabric-Sorrento Armchair-Silver Fabric",
      "product_mpn": "SRN001-U226003-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301777,
      "product_name": "Sorrento Fabric-Sorrento Right Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_model": "Sorrento Fabric-Sorrento Right Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_mpn": "SRN271L-U226400001029002-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301776,
      "product_name": "Sorrento Fabric-Sorrento Left Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_model": "Sorrento Fabric-Sorrento Left Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_mpn": "SRN271L-U226400001029002-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301775,
      "product_name": "Sorrento Fabric-Sorrento 2 Seater Recliner Sofa-Silver Fabric",
      "product_model": "Sorrento Fabric-Sorrento 2 Seater Recliner Sofa-Silver Fabric",
      "product_mpn": "SRN202M-U226193-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301774,
      "product_name": "Sorrento Fabric-Sorrento 2 Seater Sofa-Silver Fabric",
      "product_model": "Sorrento Fabric-Sorrento 2 Seater Sofa-Silver Fabric",
      "product_mpn": "SRN002-U226005-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301773,
      "product_name": "Sorrento Fabric- Sorrento 3 Seater Recliner Sofa-Silver Fabric",
      "product_model": "Sorrento Fabric- Sorrento 3 Seater Recliner Sofa-Silver Fabric",
      "product_mpn": "SRN203M-U226446-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301887,
      "product_name": "Sorrento Storage Footstool-Grey Fabric",
      "product_model": "Sorrento Storage Footstool-Grey Fabric",
      "product_mpn": "SRN010-U323253-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301886,
      "product_name": "Sorrento Recliner Armchair-Grey Fabric",
      "product_model": "Sorrento Recliner Armchair-Grey Fabric",
      "product_mpn": "SRN201-U226154-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301885,
      "product_name": "Sorrento Armchair-Grey Fabric",
      "product_model": "Sorrento Armchair-Grey Fabric",
      "product_mpn": "SRN001-U226003-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301884,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_mpn": "SRN271R-U226402001029000-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301883,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Grey Fabric",
      "product_mpn": "SRN271L-U226400001029002-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301882,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Grey Fabric",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Grey Fabric",
      "product_mpn": "SRN202M-U226193-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301881,
      "product_name": "Sorrento 2 Seater Sofa-Grey Fabric",
      "product_model": "Sorrento 2 Seater Sofa-Grey Fabric",
      "product_mpn": "SRN002-U226005-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301880,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Grey Fabric",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Grey Fabric",
      "product_mpn": "SRN203M-U226446-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301879,
      "product_name": "Sorrento 3 Seater Sofa-Grey Fabric",
      "product_model": "Sorrento 3 Seater Sofa-Grey Fabric",
      "product_mpn": "SRN003-U226009-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301910,
      "product_name": "Sorrento Storage Footstool-Taupe Fabric",
      "product_model": "Sorrento Storage Footstool-Taupe Fabric",
      "product_mpn": "SRN010-U323253-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301909,
      "product_name": "Sorrento Recliner Armchair-Taupe Fabric",
      "product_model": "Sorrento Recliner Armchair-Taupe Fabric",
      "product_mpn": "SRN201-U226154-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301908,
      "product_name": "Sorrento Armchair-Taupe Fabric",
      "product_model": "Sorrento Armchair-Taupe Fabric",
      "product_mpn": "SRN001-U226003-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301907,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_mpn": "SRN271R-U226402001029000-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301906,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_mpn": "SRN271L-U226400001029002-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301905,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Taupe Fabric",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Taupe Fabric",
      "product_mpn": "SRN202M-U226193-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301904,
      "product_name": "Sorrento 2 Seater Sofa-Taupe Fabric",
      "product_model": "Sorrento 2 Seater Sofa-Taupe Fabric",
      "product_mpn": "SRN002-U226005-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302391,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Taupe Fabric",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Taupe Fabric",
      "product_mpn": "SRN203M-U226446-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301892,
      "product_name": "Sorrento 3 Seater Sofa Fabric-Taupe Fabric",
      "product_model": "Sorrento 3 Seater Sofa Fabric-Taupe Fabric",
      "product_mpn": "SRN003-U226009-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302394,
      "product_name": "Sorrento Storage Footstool-Blue Fabric",
      "product_model": "Sorrento Storage Footstool-Blue Fabric",
      "product_mpn": "SRN010-U323253-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301918,
      "product_name": "Sorrento Recliner Armchair-Blue Fabric",
      "product_model": "Sorrento Recliner Armchair-Blue Fabric",
      "product_mpn": "SRN201-U226154-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301917,
      "product_name": "Sorrento Armchair-Blue Fabric",
      "product_model": "Sorrento Armchair-Blue Fabric",
      "product_mpn": "SRN001-U226003-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301916,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_mpn": "SRN271R-U226402001029000-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302393,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_mpn": "SRN271L-U226400001029002-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301914,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Blue Fabric",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Blue Fabric",
      "product_mpn": "SRN202M-U226193-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302392,
      "product_name": "Sorrento 2 Seater Sofa-Blue Fabric",
      "product_model": "Sorrento 2 Seater Sofa-Blue Fabric",
      "product_mpn": "SRN002-U226005-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301912,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Blue Fabric",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Blue Fabric",
      "product_mpn": "SRN203M-U226446-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301911,
      "product_name": "Sorrento 3 Seater Sofa Fabric-Blue Fabric",
      "product_model": "Sorrento 3 Seater Sofa Fabric-Blue Fabric",
      "product_mpn": "SRN003-U226009-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302399,
      "product_name": "Sorrento Storage Footstool-Mink Fabric",
      "product_model": "Sorrento Storage Footstool-Mink Fabric",
      "product_mpn": "SRN010-U323253-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302398,
      "product_name": "Sorrento Recliner Armchair-Mink Fabric",
      "product_model": "Sorrento Recliner Armchair-Mink Fabric",
      "product_mpn": "SRN201-U226154-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301933,
      "product_name": "Sorrento Armchair-Mink Fabric",
      "product_model": "Sorrento Armchair-Mink Fabric",
      "product_mpn": "SRN001-U226003-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302397,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_mpn": "SRN271R-U226402001029000-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302396,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_mpn": "SRN271L-U226400001029002-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302395,
      "product_name": "Sorrento 2 Seater Sofa-Mink Fabric",
      "product_model": "Sorrento 2 Seater Sofa-Mink Fabric",
      "product_mpn": "SRN002-U226005-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301922,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Mink Fabric",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Mink Fabric",
      "product_mpn": "SRN202M-U226193-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301921,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Mink Fabric",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Mink Fabric",
      "product_mpn": "SRN203M-U226446-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301920,
      "product_name": "Sorrento 3 Seater Sofa Fabric-Mink Fabric",
      "product_model": "Sorrento 3 Seater Sofa Fabric-Mink Fabric",
      "product_mpn": "SRN003-U226009-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301901,
      "product_name": "Sorrento Storage Footstool-Chocolate Leather",
      "product_model": "Sorrento Storage Footstool-Chocolate Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301900,
      "product_name": "Sorrento Recliner Armchair-Chocolate Leather",
      "product_model": "Sorrento Recliner Armchair-Chocolate Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301899,
      "product_name": "Sorrento Armchair-Chocolate Leather",
      "product_model": "Sorrento Armchair-Chocolate Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301898,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301897,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301896,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Chocolate Leather",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Chocolate Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301895,
      "product_name": "Sorrento 2 Seater Sofa-Chocolate Leather",
      "product_model": "Sorrento 2 Seater Sofa-Chocolate Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301894,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Chocolate Leather",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Chocolate Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301893,
      "product_name": "Sorrento 3 Seater Sofa Leather-Chocolate Leather",
      "product_model": "Sorrento 3 Seater Sofa Leather-Chocolate Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301926,
      "product_name": "Sorrento Storage Footstool-Rose Beige Leather",
      "product_model": "Sorrento Storage Footstool-Rose Beige Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301925,
      "product_name": "Sorrento Recliner Armchair-Rose Beige Leather",
      "product_model": "Sorrento Recliner Armchair-Rose Beige Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301924,
      "product_name": "Sorrento Armchair-Rose Beige Leather",
      "product_model": "Sorrento Armchair-Rose Beige Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301923,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301919,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301915,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Rose Beige Leather",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Rose Beige Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301913,
      "product_name": "Sorrento 2 Seater Sofa-Rose Beige Leather",
      "product_model": "Sorrento 2 Seater Sofa-Rose Beige Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301903,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Rose Beige Leather",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Rose Beige Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301902,
      "product_name": "Sorrento 3 Seater Sofa Leather-Rose Beige Leather",
      "product_model": "Sorrento 3 Seater Sofa Leather-Rose Beige Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301936,
      "product_name": "Sorrento Storage Footstool-Taupe Leather",
      "product_model": "Sorrento Storage Footstool-Taupe Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301935,
      "product_name": "Sorrento Recliner Armchair-Taupe Leather",
      "product_model": "Sorrento Recliner Armchair-Taupe Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301934,
      "product_name": "Sorrento Armchair-Taupe Leather",
      "product_model": "Sorrento Armchair-Taupe Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301932,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301931,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301930,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Taupe Leather",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Taupe Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301929,
      "product_name": "Sorrento 2 Seater Sofa-Taupe Leather",
      "product_model": "Sorrento 2 Seater Sofa-Taupe Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301928,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Taupe Leather",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Taupe Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301927,
      "product_name": "Sorrento 3 Seater Sofa Leather-Taupe Leather",
      "product_model": "Sorrento 3 Seater Sofa Leather-Taupe Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302400,
      "product_name": "Sorrento Storage Footstool-Black Leather",
      "product_model": "Sorrento Storage Footstool-Black Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301944,
      "product_name": "Sorrento Recliner Armchair-Black Leather",
      "product_model": "Sorrento Recliner Armchair-Black Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301943,
      "product_name": "Sorrento Armchair-Black Leather",
      "product_model": "Sorrento Armchair-Black Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301942,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Black Leather",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Black Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301941,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Black Leather",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Black Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301940,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Black Leather",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Black Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301939,
      "product_name": "Sorrento 2 Seater Sofa-Black Leather",
      "product_model": "Sorrento 2 Seater Sofa-Black Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301938,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Black Leather",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Black Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301937,
      "product_name": "Sorrento 3 Seater Sofa Leather-Black Leather",
      "product_model": "Sorrento 3 Seater Sofa Leather-Black Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301958,
      "product_name": "Sorrento Storage Footstool-White Leather",
      "product_model": "Sorrento Storage Footstool-White Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301957,
      "product_name": "Sorrento Recliner Armchair-White Leather",
      "product_model": "Sorrento Recliner Armchair-White Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301956,
      "product_name": "Sorrento Armchair-White Leather",
      "product_model": "Sorrento Armchair-White Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301955,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-White Leather",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-White Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302404,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-White Leather",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-White Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301953,
      "product_name": "Sorrento 2 Seater Recliner Sofa-White Leather",
      "product_model": "Sorrento 2 Seater Recliner Sofa-White Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302403,
      "product_name": "Sorrento 2 Seater Sofa-White Leather",
      "product_model": "Sorrento 2 Seater Sofa-White Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302402,
      "product_name": "Sorrento 3 Seater Recliner Sofa-White Leather",
      "product_model": "Sorrento 3 Seater Recliner Sofa-White Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302401,
      "product_name": "Sorrento 3 Seater Sofa Leather-White Leather",
      "product_model": "Sorrento 3 Seater Sofa Leather-White Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301969,
      "product_name": "Sorrento Storage Footstool-Grey Leather",
      "product_model": "Sorrento Storage Footstool-Grey Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302408,
      "product_name": "Sorrento Recliner Armchair-Grey Leather",
      "product_model": "Sorrento Recliner Armchair-Grey Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302407,
      "product_name": "Sorrento Armchair-Grey Leather",
      "product_model": "Sorrento Armchair-Grey Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302406,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Grey Leather",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Grey Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301964,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner-Grey Leather",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner-Grey Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301963,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Grey Leather",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Grey Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302405,
      "product_name": "Sorrento 2 Seater Sofa-Grey Leather",
      "product_model": "Sorrento 2 Seater Sofa-Grey Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301960,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Grey Leather",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Grey Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301959,
      "product_name": "Sorrento 3 Seater Sofa Leather-Grey Leather",
      "product_model": "Sorrento 3 Seater Sofa Leather-Grey Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301954,
      "product_name": "Sorrento Storage Footstool-Smoke Leather",
      "product_model": "Sorrento Storage Footstool-Smoke Leather",
      "product_mpn": "SRN010-U323253-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301952,
      "product_name": "Sorrento Recliner Armchair-Smoke Leather",
      "product_model": "Sorrento Recliner Armchair-Smoke Leather",
      "product_mpn": "SRN201-U226154-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301951,
      "product_name": "Sorrento Armchair-Smoke Leather",
      "product_model": "Sorrento Armchair-Smoke Leather",
      "product_mpn": "SRN001-U226003-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301950,
      "product_name": "Sorrento Right Hand Corner Sofa with One Recliner-Smoke Leather",
      "product_model": "Sorrento Right Hand Corner Sofa with One Recliner-Smoke Leather",
      "product_mpn": "SRN271R-U226402001029000-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301949,
      "product_name": "Sorrento Left Hand Corner Sofa with One Recliner -Smoke Leather",
      "product_model": "Sorrento Left Hand Corner Sofa with One Recliner -Smoke Leather",
      "product_mpn": "SRN271L-U226400001029002-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301948,
      "product_name": "Sorrento 2 Seater Recliner Sofa-Smoke Leather",
      "product_model": "Sorrento 2 Seater Recliner Sofa-Smoke Leather",
      "product_mpn": "SRN202M-U226193-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301947,
      "product_name": "Sorrento 2 Seater Sofa-Smoke Leather",
      "product_model": "Sorrento 2 Seater Sofa-Smoke Leather",
      "product_mpn": "SRN002-U226005-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301946,
      "product_name": "Sorrento 3 Seater Recliner Sofa-Smoke Leather",
      "product_model": "Sorrento 3 Seater Recliner Sofa-Smoke Leather",
      "product_mpn": "SRN203M-U226446-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301945,
      "product_name": "Sorrento 3 Seater Sofa Leather-Smoke Leather",
      "product_model": "Sorrento 3 Seater Sofa Leather-Smoke Leather",
      "product_mpn": "SRN003-U226009-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301972,
      "product_name": "Sienna Footstool-Silver Fabric",
      "product_model": "Sienna Footstool-Silver Fabric",
      "product_mpn": "SNAFOOT-Z361243-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301971,
      "product_name": "Sienna Recliner Armchair-Silver Fabric",
      "product_model": "Sienna Recliner Armchair-Silver Fabric",
      "product_mpn": "SNA201-Z361154-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301970,
      "product_name": "Sienna Armchair-Silver Fabric",
      "product_model": "Sienna Armchair-Silver Fabric",
      "product_mpn": "SNA001-Z361003-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301968,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_mpn": "SNA221R-Z361183200-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301967,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Silver Fabric",
      "product_mpn": "SNA221L-Z361182201-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301966,
      "product_name": "Sienna 2 Seater Recliner Sofa-Silver Fabric",
      "product_model": "Sienna 2 Seater Recliner Sofa-Silver Fabric",
      "product_mpn": "SNA202M-Z361T30-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301965,
      "product_name": "Sienna 2 Seater Sofa-Silver Fabric",
      "product_model": "Sienna 2 Seater Sofa-Silver Fabric",
      "product_mpn": "SNA002-Z361005-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301962,
      "product_name": "Sienna 3 Seater Recliner Sofa-Silver Fabric",
      "product_model": "Sienna 3 Seater Recliner Sofa-Silver Fabric",
      "product_mpn": "SNA203M-Z361T66-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301961,
      "product_name": "Sienna 3 Seater Sofa-Silver Fabric",
      "product_model": "Sienna 3 Seater Sofa-Silver Fabric",
      "product_mpn": "SNA003-Z361009-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302420,
      "product_name": "Sienna Footstool-Chocolate Leather",
      "product_model": "Sienna Footstool-Chocolate Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302007,
      "product_name": "Sienna Recliner Armchair-Chocolate Leather",
      "product_model": "Sienna Recliner Armchair-Chocolate Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302419,
      "product_name": "Sienna Armchair-Chocolate Leather",
      "product_model": "Sienna Armchair-Chocolate Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302005,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302418,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Chocolate Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302003,
      "product_name": "Sienna 2 Seater Recliner Sofa-Chocolate Leather",
      "product_model": "Sienna 2 Seater Recliner Sofa-Chocolate Leather",
      "product_mpn": "SNA202M-Z361T30-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302002,
      "product_name": "Sienna 2 Seater Sofa-Chocolate Leather",
      "product_model": "Sienna 2 Seater Sofa-Chocolate Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302001,
      "product_name": "Sienna 3 Seater Recliner Sofa-Chocolate Leather",
      "product_model": "Sienna 3 Seater Recliner Sofa-Chocolate Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302000,
      "product_name": "Sienna 3 Seater Sofa Leather-Chocolate Leather",
      "product_model": "Sienna 3 Seater Sofa Leather-Chocolate Leather",
      "product_mpn": "SNA003-Z361009-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302417,
      "product_name": "Sienna Footstool-Blue Fabric",
      "product_model": "Sienna Footstool-Blue Fabric",
      "product_mpn": "SNAFOOT-Z361243-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301989,
      "product_name": "Sienna Recliner Armchair-Blue Fabric",
      "product_model": "Sienna Recliner Armchair-Blue Fabric",
      "product_mpn": "SNA201-Z361154-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301988,
      "product_name": "Sienna Armchair-Blue Fabric",
      "product_model": "Sienna Armchair-Blue Fabric",
      "product_mpn": "SNA001-Z361003-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302416,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_mpn": "SNA221R-Z361183200-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301986,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Blue Fabric",
      "product_mpn": "SNA221L-Z361182201-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302415,
      "product_name": "Sienna 2 Seater Recliner Sofa-Blue Fabric",
      "product_model": "Sienna 2 Seater Recliner Sofa-Blue Fabric",
      "product_mpn": "SNA202M-Z361T30-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302414,
      "product_name": "Sienna 2 Seater Sofa-Blue Fabric",
      "product_model": "Sienna 2 Seater Sofa-Blue Fabric",
      "product_mpn": "SNA002-Z361005-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301983,
      "product_name": "Sienna 3 Seater Recliner Sofa-Blue Fabric",
      "product_model": "Sienna 3 Seater Recliner Sofa-Blue Fabric",
      "product_mpn": "SNA203M-Z361T66-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302413,
      "product_name": "Sienna 3 Seater Sofa Leather -Blue Fabric",
      "product_model": "Sienna 3 Seater Sofa Leather -Blue Fabric",
      "product_mpn": "SNA003-Z361009-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301999,
      "product_name": "Sienna Footstool-Mink Fabric",
      "product_model": "Sienna Footstool-Mink Fabric",
      "product_mpn": "SNAFOOT-Z361243-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301998,
      "product_name": "Sienna Recliner Armchair-Mink Fabric",
      "product_model": "Sienna Recliner Armchair-Mink Fabric",
      "product_mpn": "SNA201-Z361154-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301997,
      "product_name": "Sienna Armchair-Mink Fabric",
      "product_model": "Sienna Armchair-Mink Fabric",
      "product_mpn": "SNA001-Z361003-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301996,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_mpn": "SNA221R-Z361183200-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301995,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Mink Fabric",
      "product_mpn": "SNA221L-Z361182201-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301994,
      "product_name": "Sienna 2 Seater Recliner Sofa-Mink Fabric",
      "product_model": "Sienna 2 Seater Recliner Sofa-Mink Fabric",
      "product_mpn": "SNA202M-Z361T30-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301993,
      "product_name": "Sienna 2 Seater Sofa-Mink Fabric",
      "product_model": "Sienna 2 Seater Sofa-Mink Fabric",
      "product_mpn": "SNA002-Z361005-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301992,
      "product_name": "Sienna 3 Seater Recliner Sofa-Mink Fabric",
      "product_model": "Sienna 3 Seater Recliner Sofa-Mink Fabric",
      "product_mpn": "SNA203M-Z361T66-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301991,
      "product_name": "Sienna 3 Seater Sofa Leather-Mink Fabric",
      "product_model": "Sienna 3 Seater Sofa Leather-Mink Fabric",
      "product_mpn": "SNA003-Z361009-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301981,
      "product_name": "Sienna Footstool-Taupe Fabric",
      "product_model": "Sienna Footstool-Taupe Fabric",
      "product_mpn": "SNAFOOT-Z361243-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302412,
      "product_name": "Sienna Recliner Armchair-Taupe Fabric",
      "product_model": "Sienna Recliner Armchair-Taupe Fabric",
      "product_mpn": "SNA201-Z361154-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301979,
      "product_name": "Sienna Armchair-Taupe Fabric",
      "product_model": "Sienna Armchair-Taupe Fabric",
      "product_mpn": "SNA001-Z361003-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302411,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_mpn": "Sienna Right Hand Corner Sofa with One Recliner",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301977,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Taupe Fabric",
      "product_mpn": "SNA221L-Z361182201-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301976,
      "product_name": "Sienna 2 Seater Recliner Sofa-Taupe Fabric",
      "product_model": "Sienna 2 Seater Recliner Sofa-Taupe Fabric",
      "product_mpn": "SNA202M-Z361T30-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302410,
      "product_name": "Sienna 2 Seater Sofa-Taupe Fabric",
      "product_model": "Sienna 2 Seater Sofa-Taupe Fabric",
      "product_mpn": "SNA002-Z361005-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301974,
      "product_name": "Sienna 3 Seater Recliner Sofa-Taupe Fabric",
      "product_model": "Sienna 3 Seater Recliner Sofa-Taupe Fabric",
      "product_mpn": "SNA203M-Z361T66-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302409,
      "product_name": "Sienna 3 Seater Sofa Leather -Taupe Fabric",
      "product_model": "Sienna 3 Seater Sofa Leather -Taupe Fabric",
      "product_mpn": "SNA003-Z361009-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301990,
      "product_name": "Sienna Footstool-Anthracite Leather",
      "product_model": "Sienna Footstool-Anthracite Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301987,
      "product_name": "Sienna Recliner Armchair-Anthracite Leather",
      "product_model": "Sienna Recliner Armchair-Anthracite Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301985,
      "product_name": "Sienna Armchair-Anthracite Leather",
      "product_model": "Sienna Armchair-Anthracite Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301984,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301982,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Anthracite Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301980,
      "product_name": "Sienna 2 Seater Recliner Sofa-Anthracite Leather",
      "product_model": "Sienna 2 Seater Recliner Sofa-Anthracite Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301978,
      "product_name": "Sienna 2 Seater Sofa-Anthracite Leather",
      "product_model": "Sienna 2 Seater Sofa-Anthracite Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301975,
      "product_name": "Sienna 3 Seater Recliner Sofa-Anthracite Leather",
      "product_model": "Sienna 3 Seater Recliner Sofa-Anthracite Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301973,
      "product_name": "Sienna 3 Seater Sofa Leather-Anthracite Leather",
      "product_model": "Sienna 3 Seater Sofa Leather-Anthracite Leather",
      "product_mpn": "SNA003-Z361009-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302422,
      "product_name": "Sienna Footstool-Smoke Leather",
      "product_model": "Sienna Footstool-Smoke Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302421,
      "product_name": "Sienna Recliner Armchair-Smoke Leather",
      "product_model": "Sienna Recliner Armchair-Smoke Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302012,
      "product_name": "Sienna Armchair-Smoke Leather",
      "product_model": "Sienna Armchair-Smoke Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302011,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Smoke Leather",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Smoke Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302010,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Smoke Leather",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Smoke Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302009,
      "product_name": "Sienna 2 Seater Recliner Sofa-Smoke Leather",
      "product_model": "Sienna 2 Seater Recliner Sofa-Smoke Leather",
      "product_mpn": "SNA202M-Z361T30-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302008,
      "product_name": "Sienna 2 Seater Sofa-Smoke Leather",
      "product_model": "Sienna 2 Seater Sofa-Smoke Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302006,
      "product_name": "Sienna 3 Seater Recliner Sofa-Smoke Leather",
      "product_model": "Sienna 3 Seater Recliner Sofa-Smoke Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302004,
      "product_name": "Sienna 3 Seater Sofa Leather-Smoke Leather",
      "product_model": "Sienna 3 Seater Sofa Leather-Smoke Leather",
      "product_mpn": "SNA003-Z361009-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302426,
      "product_name": "Sienna Footstool-Taupe Leather",
      "product_model": "Sienna Footstool-Taupe Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302425,
      "product_name": "Sienna Recliner Armchair-Taupe Leather",
      "product_model": "Sienna Recliner Armchair-Taupe Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302026,
      "product_name": "Sienna Armchair-Taupe Leather",
      "product_model": "Sienna Armchair-Taupe Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302025,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302024,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Taupe Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302023,
      "product_name": "Sienna 2 Seater Recliner Sofa-Taupe Leather",
      "product_model": "Sienna 2 Seater Recliner Sofa-Taupe Leather",
      "product_mpn": "SNA202M-Z361T30-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302022,
      "product_name": "Sienna 2 Seater Sofa-Taupe Leather",
      "product_model": "Sienna 2 Seater Sofa-Taupe Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302424,
      "product_name": "Sienna 3 Seater Recliner Sofa-Taupe Leather",
      "product_model": "Sienna 3 Seater Recliner Sofa-Taupe Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302423,
      "product_name": "Sienna 3 Seater Sofa Leather-Taupe Leather",
      "product_model": "Sienna 3 Seater Sofa Leather-Taupe Leather",
      "product_mpn": "SNA003-Z361009-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302430,
      "product_name": "Sienna Footstool-Black Leather",
      "product_model": "Sienna Footstool-Black Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302044,
      "product_name": "Sienna Recliner Armchair-Black Leather",
      "product_model": "Sienna Recliner Armchair-Black Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302043,
      "product_name": "Sienna Armchair-Black Leather",
      "product_model": "Sienna Armchair-Black Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302042,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Black Leather",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Black Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302041,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Black Leather",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Black Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302040,
      "product_name": "Sienna 2 Seater Recliner Sofa-Black Leather",
      "product_model": "Sienna 2 Seater Recliner Sofa-Black Leather",
      "product_mpn": "SNA202M-Z361T30-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302429,
      "product_name": "Sienna 2 Seater Sofa-Black Leather",
      "product_model": "Sienna 2 Seater Sofa-Black Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302428,
      "product_name": "Sienna 3 Seater Recliner Sofa-Black Leather",
      "product_model": "Sienna 3 Seater Recliner Sofa-Black Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302427,
      "product_name": "Sienna 3 Seater Sofa Leather-Black Leather",
      "product_model": "Sienna 3 Seater Sofa Leather-Black Leather",
      "product_mpn": "SNA003-Z361009-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302021,
      "product_name": "Sienna Footstool-Rose Beige Leather",
      "product_model": "Sienna Footstool-Rose Beige Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302020,
      "product_name": "Sienna Recliner Armchair-Rose Beige Leather",
      "product_model": "Sienna Recliner Armchair-Rose Beige Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302019,
      "product_name": "Sienna Armchair-Rose Beige Leather",
      "product_model": "Sienna Armchair-Rose Beige Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302018,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302017,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Rose Beige Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302016,
      "product_name": "Sienna 2 Seater Recliner Sofa-Rose Beige Leather",
      "product_model": "Sienna 2 Seater Recliner Sofa-Rose Beige Leather",
      "product_mpn": "SNA202M-Z361T30-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302015,
      "product_name": "Sienna 2 Seater Sofa-Rose Beige Leather",
      "product_model": "Sienna 2 Seater Sofa-Rose Beige Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302014,
      "product_name": "Sienna 3 Seater Recliner Sofa-Rose Beige Leather",
      "product_model": "Sienna 3 Seater Recliner Sofa-Rose Beige Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302013,
      "product_name": "Sienna 3 Seater Sofa Leather -Rose Beige Leather",
      "product_model": "Sienna 3 Seater Sofa Leather -Rose Beige Leather",
      "product_mpn": "SNA003-Z361009-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302035,
      "product_name": "Sienna Footstool-Grey Leather",
      "product_model": "Sienna Footstool-Grey Leather",
      "product_mpn": "SNAFOOT-Z361243-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302034,
      "product_name": "Sienna Recliner Armchair-Grey Leather",
      "product_model": "Sienna Recliner Armchair-Grey Leather",
      "product_mpn": "SNA201-Z361154-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302033,
      "product_name": "Sienna Armchair-Grey Leather",
      "product_model": "Sienna Armchair-Grey Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302032,
      "product_name": "Sienna Right Hand Corner Sofa with One Recliner-Grey Leather",
      "product_model": "Sienna Right Hand Corner Sofa with One Recliner-Grey Leather",
      "product_mpn": "SNA221R-Z361183200-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302031,
      "product_name": "Sienna Left Hand Corner Sofa with One Recliner-Grey Leather",
      "product_model": "Sienna Left Hand Corner Sofa with One Recliner-Grey Leather",
      "product_mpn": "SNA221L-Z361182201-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302030,
      "product_name": "Sienna 2 Seater Recliner Sofa-Grey Leather",
      "product_model": "Sienna 2 Seater Recliner Sofa-Grey Leather",
      "product_mpn": "SNA202M-Z361T30-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302029,
      "product_name": "Sienna 2 Seater Sofa-Grey Leather",
      "product_model": "Sienna 2 Seater Sofa-Grey Leather",
      "product_mpn": "SNA002-Z361005-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302028,
      "product_name": "Sienna 3 Seater Recliner Sofa-Grey Leather",
      "product_model": "Sienna 3 Seater Recliner Sofa-Grey Leather",
      "product_mpn": "SNA203M-Z361T66-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302027,
      "product_name": "Sienna 3 Seater Sofa Leather -Grey Leather",
      "product_model": "Sienna 3 Seater Sofa Leather -Grey Leather",
      "product_mpn": "SNA001-Z361003-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302039,
      "product_name": "Vittoria Footstool-Grey Fabric",
      "product_model": "Vittoria Footstool-Grey Fabric",
      "product_mpn": "VTOFOOT-Z370253-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302038,
      "product_name": "Vittoria Armchair-Grey Fabric",
      "product_model": "Vittoria Armchair-Grey Fabric",
      "product_mpn": "VTO001-Z370003-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302037,
      "product_name": "Vittoria 2 Seater Sofa-Grey Fabric",
      "product_model": "Vittoria 2 Seater Sofa-Grey Fabric",
      "product_mpn": "VTO002-Z370005-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302036,
      "product_name": "Vittoria 3 Seater Sofa Fabric-Grey Fabric",
      "product_model": "Vittoria 3 Seater Sofa Fabric-Grey Fabric",
      "product_mpn": "VTO003-Z370009-BZA-03-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302053,
      "product_name": "Vittoria Footstool-Silver Fabric",
      "product_model": "Vittoria Footstool-Silver Fabric",
      "product_mpn": "VTOFOOT-Z370253-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302047,
      "product_name": "Vittoria Armchair-Silver Fabric",
      "product_model": "Vittoria Armchair-Silver Fabric",
      "product_mpn": "VTO001-Z370003-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302046,
      "product_name": "Vittoria 2 Seater Sofa-Silver Fabric",
      "product_model": "Vittoria 2 Seater Sofa-Silver Fabric",
      "product_mpn": "VTO002-Z370005-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302045,
      "product_name": "Vittoria 3 Seater Sofa Fabric-Silver Fabric",
      "product_model": "Vittoria 3 Seater Sofa Fabric-Silver Fabric",
      "product_mpn": "VTO003-Z370009-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302050,
      "product_name": "Vittoria Footstool-Taupe Fabric",
      "product_model": "Vittoria Footstool-Taupe Fabric",
      "product_mpn": "VTOFOOT-Z370253-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302049,
      "product_name": "Vittoria Armchair-Taupe Fabric",
      "product_model": "Vittoria Armchair-Taupe Fabric",
      "product_mpn": "VTO001-Z370003-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302048,
      "product_name": "Vittoria 2 Seater Sofa-Taupe Fabric",
      "product_model": "Vittoria 2 Seater Sofa-Taupe Fabric",
      "product_mpn": "VTO002-Z370005-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302054,
      "product_name": "Vittoria 3 Seater Sofa Fabric-Taupe Fabric",
      "product_model": "Vittoria 3 Seater Sofa Fabric-Taupe Fabric",
      "product_mpn": "VTO003-Z370009-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302056,
      "product_name": "Vittoria Footstool-Blue Fabric",
      "product_model": "Vittoria Footstool-Blue Fabric",
      "product_mpn": "VTOFOOT-Z370253-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302055,
      "product_name": "Vittoria Armchair-Blue Fabric",
      "product_model": "Vittoria Armchair-Blue Fabric",
      "product_mpn": "VTO001-Z370003-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302052,
      "product_name": "Vittoria 2 Seater Sofa-Blue Fabric",
      "product_model": "Vittoria 2 Seater Sofa-Blue Fabric",
      "product_mpn": "VTO002-Z370005-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302051,
      "product_name": "Vittoria 3 Seater Sofa Fabric-Blue Fabric",
      "product_model": "Vittoria 3 Seater Sofa Fabric-Blue Fabric",
      "product_mpn": "VTO003-Z370009-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302060,
      "product_name": "Vittoria Footstool-Chocolate Leather",
      "product_model": "Vittoria Footstool-Chocolate Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302059,
      "product_name": "Vittoria Armchair-Chocolate Leather",
      "product_model": "Vittoria Armchair-Chocolate Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302058,
      "product_name": "Vittoria 2 Seater Sofa-Chocolate Leather",
      "product_model": "Vittoria 2 Seater Sofa-Chocolate Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302057,
      "product_name": "Vittoria 3 Seater Sofa Leather-Chocolate Leather",
      "product_model": "Vittoria 3 Seater Sofa Leather-Chocolate Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302064,
      "product_name": "Vittoria Footstool-Rose Beige Leather",
      "product_model": "Vittoria Footstool-Rose Beige Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302063,
      "product_name": "Vittoria Armchair-Rose Beige Leather",
      "product_model": "Vittoria Armchair-Rose Beige Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302062,
      "product_name": "Vittoria 2 Seater Sofa-Rose Beige Leather",
      "product_model": "Vittoria 2 Seater Sofa-Rose Beige Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302061,
      "product_name": "Vittoria 3 Seater Sofa Leather-Rose Beige Leather",
      "product_model": "Vittoria 3 Seater Sofa Leather-Rose Beige Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302433,
      "product_name": "Vittoria Footstool-Anthracite Leather",
      "product_model": "Vittoria Footstool-Anthracite Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302072,
      "product_name": "Vittoria Armchair-Anthracite Leather",
      "product_model": "Vittoria Armchair-Anthracite Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302432,
      "product_name": "Vittoria 2 Seater Sofa-Anthracite Leather",
      "product_model": "Vittoria 2 Seater Sofa-Anthracite Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302431,
      "product_name": "Vittoria 3 Seater Sofa Leather-Anthracite Leather",
      "product_model": "Vittoria 3 Seater Sofa Leather-Anthracite Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BI-ANT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302435,
      "product_name": "Vittoria Footstool-Taupe Leather",
      "product_model": "Vittoria Footstool-Taupe Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302077,
      "product_name": "Vittoria Armchair-Taupe Leather",
      "product_model": "Vittoria Armchair-Taupe Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302434,
      "product_name": "Vittoria 2 Seater Sofa-Taupe Leather",
      "product_model": "Vittoria 2 Seater Sofa-Taupe Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302075,
      "product_name": "Vittoria 3 Seater Sofa Leather -Taupe Leather",
      "product_model": "Vittoria 3 Seater Sofa Leather -Taupe Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302437,
      "product_name": "Vittoria Footstool-White Leather",
      "product_model": "Vittoria Footstool-White Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302081,
      "product_name": "Vittoria Armchair-White Leather",
      "product_model": "Vittoria Armchair-White Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302436,
      "product_name": "Vittoria 2 Seater Sofa-White Leather",
      "product_model": "Vittoria 2 Seater Sofa-White Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302079,
      "product_name": "Vittoria 3 Seater Sofa Leather-White Leather",
      "product_model": "Vittoria 3 Seater Sofa Leather-White Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302439,
      "product_name": "Vittoria Footstool-Grey Leather",
      "product_model": "Vittoria Footstool-Grey Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302085,
      "product_name": "Vittoria Armchair-Grey Leather",
      "product_model": "Vittoria Armchair-Grey Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302084,
      "product_name": "Vittoria 2 Seater Sofa-Grey Leather",
      "product_model": "Vittoria 2 Seater Sofa-Grey Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302438,
      "product_name": "Vittoria 3 Seater Sofa Leather-Grey Leather",
      "product_model": "Vittoria 3 Seater Sofa Leather-Grey Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302090,
      "product_name": "Vittoria Footstool-Black Leather",
      "product_model": "Vittoria Footstool-Black Leather",
      "product_mpn": "VTOFOOT-Z370253-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302441,
      "product_name": "Vittoria Armchair-Black Leather",
      "product_model": "Vittoria Armchair-Black Leather",
      "product_mpn": "VTO001-Z370003-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302088,
      "product_name": "Vittoria 2 Seater Sofa-Black Leather",
      "product_model": "Vittoria 2 Seater Sofa-Black Leather",
      "product_mpn": "VTO002-Z370005-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302440,
      "product_name": "Vittoria 3 Seater Sofa Leather-Black Leather",
      "product_model": "Vittoria 3 Seater Sofa Leather-Black Leather",
      "product_mpn": "VTO003-Z370009-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302074,
      "product_name": "Milano Storage Footstool-Dark Brown Leather",
      "product_model": "Milano Storage Footstool-Dark Brown Leather",
      "product_mpn": "MNO010-U323253-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302073,
      "product_name": "Milano Triple Motion Recliner Armchair -Dark Brown Leather",
      "product_model": "Milano Triple Motion Recliner Armchair -Dark Brown Leather",
      "product_mpn": "MNO301-Z365N54-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302071,
      "product_name": "Milano Armchair-Dark Brown Leather",
      "product_model": "Milano Armchair-Dark Brown Leather",
      "product_mpn": "MNO001-Z365003-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302070,
      "product_name": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Dark Brown Leather",
      "product_model": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Dark Brown Leather",
      "product_mpn": "MNO359R-Z365N50291049-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302069,
      "product_name": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Dark Brown Leather",
      "product_model": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Dark Brown Leather",
      "product_mpn": "MNO359L-Z365047291N52-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302068,
      "product_name": "Milano 2 Seater Triple Motion Recliner Sofa-Dark Brown Leather",
      "product_model": "Milano 2 Seater Triple Motion Recliner Sofa-Dark Brown Leather",
      "product_mpn": "MNO302M-Z365M93-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302067,
      "product_name": "Milano 2 Seater Sofa-Dark Brown Leather",
      "product_model": "Milano 2 Seater Sofa-Dark Brown Leather",
      "product_mpn": "MNO002-Z365005-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302066,
      "product_name": "Milano 3 Seater Triple Motion Recliner Sofa-Dark Brown Leather",
      "product_model": "Milano 3 Seater Triple Motion Recliner Sofa-Dark Brown Leather",
      "product_mpn": "MNO303M-Z365M46-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302065,
      "product_name": "Milano 3 Seater Sofa Leather-Dark Brown Leather",
      "product_model": "Milano 3 Seater Sofa Leather-Dark Brown Leather",
      "product_mpn": "MNO003-Z365009-LEA-A0YM-DBR",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302442,
      "product_name": "Milano Storage Footstool-Grey Leather",
      "product_model": "Milano Storage Footstool-Grey Leather",
      "product_mpn": "MNO010-U323253-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302089,
      "product_name": "Milano Triple Motion Recliner Armchair -Grey Leather",
      "product_model": "Milano Triple Motion Recliner Armchair -Grey Leather",
      "product_mpn": "MNO301-Z365N54-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302087,
      "product_name": "Milano Armchair-Grey Leather",
      "product_model": "Milano Armchair-Grey Leather",
      "product_mpn": "MNO001-Z365003-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302086,
      "product_name": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Grey Leather",
      "product_model": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Grey Leather",
      "product_mpn": "MNO359R-Z365N50291049-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302083,
      "product_name": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Grey Leather",
      "product_model": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Grey Leather",
      "product_mpn": "MNO359L-Z365047291N52-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302082,
      "product_name": "Milano 2 Seater Triple Motion Recliner Sofa-Grey Leather",
      "product_model": "Milano 2 Seater Triple Motion Recliner Sofa-Grey Leather",
      "product_mpn": "MNO302M-Z365M93-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302080,
      "product_name": "Milano 2 Seater-Grey Leather",
      "product_model": "Milano 2 Seater-Grey Leather",
      "product_mpn": "MNO002-Z365005-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302078,
      "product_name": "Milano 3 Seater Triple Motion Recliner Sofa-Grey Leather",
      "product_model": "Milano 3 Seater Triple Motion Recliner Sofa-Grey Leather",
      "product_mpn": "MNO303M-Z365M46-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302076,
      "product_name": "Milano 3 Seater Sofa Leather-Grey Leather",
      "product_model": "Milano 3 Seater Sofa Leather-Grey Leather",
      "product_mpn": "MNO003-Z365009-LEA-A0YO-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302449,
      "product_name": "Milano Storage Footstool-Black Leather",
      "product_model": "Milano Storage Footstool-Black Leather",
      "product_mpn": "MNO010-U323253-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302448,
      "product_name": "Milano Triple Motion Recliner Armchair -Black Leather",
      "product_model": "Milano Triple Motion Recliner Armchair -Black Leather",
      "product_mpn": "MNO301-Z365N54-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302447,
      "product_name": "Milano Armchair-Black Leather",
      "product_model": "Milano Armchair-Black Leather",
      "product_mpn": "MNO001-Z365003-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302446,
      "product_name": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Black Leather",
      "product_model": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Black Leather",
      "product_mpn": "MNO359R-Z365N50291049-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302101,
      "product_name": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Black Leather",
      "product_model": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Black Leather",
      "product_mpn": "MNO359L-Z365047291N52-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302390,
      "product_name": "Milano 2 Seater Triple Motion Recliner Sofa-Black Leather",
      "product_model": "Milano 2 Seater Triple Motion Recliner Sofa-Black Leather",
      "product_mpn": "MNO302M-Z365M93-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302445,
      "product_name": "Milano 2 Seater Sofa-Black Leather",
      "product_model": "Milano 2 Seater Sofa-Black Leather",
      "product_mpn": "MNO002-Z365005-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302444,
      "product_name": "Milano 3 Seater Triple Motion Recliner Sofa-Black Leather",
      "product_model": "Milano 3 Seater Triple Motion Recliner Sofa-Black Leather",
      "product_mpn": "MNO303M-Z365M46-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302443,
      "product_name": "Milano 3 Seater Sofa Leather-Black Leather",
      "product_model": "Milano 3 Seater Sofa Leather-Black Leather",
      "product_mpn": "MNO003-Z365009-LEA-A0YP-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302099,
      "product_name": "Milano Storage Footstool-Chestnut Leather",
      "product_model": "Milano Storage Footstool-Chestnut Leather",
      "product_mpn": "MNO010-U323253-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302098,
      "product_name": "Milano Triple Motion Recliner Armchair -Chestnut Leather",
      "product_model": "Milano Triple Motion Recliner Armchair -Chestnut Leather",
      "product_mpn": "MNO301-Z365N54-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302097,
      "product_name": "Milano Armchair-Chestnut Leather",
      "product_model": "Milano Armchair-Chestnut Leather",
      "product_mpn": "MNO001-Z365003-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302096,
      "product_name": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Chestnut Leather",
      "product_model": "Milano Right Hand Chaise Sofa with Triple Motion Recliner-Chestnut Leather",
      "product_mpn": "MNO359R-Z365N50291049-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302095,
      "product_name": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Chestnut Leather",
      "product_model": "Milano Left Hand Chaise Sofa with Triple Motion Recliner-Chestnut Leather",
      "product_mpn": "MNO359L-Z365047291N52-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302094,
      "product_name": "Milano 2 Seater Triple Motion Recliner Sofa-Chestnut Leather",
      "product_model": "Milano 2 Seater Triple Motion Recliner Sofa-Chestnut Leather",
      "product_mpn": "MNO302M-Z365M93-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302093,
      "product_name": "Milano 2 Seater Sofa-Chestnut Leather",
      "product_model": "Milano 2 Seater Sofa-Chestnut Leather",
      "product_mpn": "MNO002-Z365005-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302092,
      "product_name": "Milano 3 Seater Triple Motion Recliner Sofa-Chestnut Leather",
      "product_model": "Milano 3 Seater Triple Motion Recliner Sofa-Chestnut Leather",
      "product_mpn": "MNO303M-Z365M46-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302091,
      "product_name": "Milano 3 Seater Sofa Leather-Chestnut Leather",
      "product_model": "Milano 3 Seater Sofa Leather-Chestnut Leather",
      "product_mpn": "MNO003-Z365009-LEA-A0YR-CHS",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302111,
      "product_name": "Roma Leather-Grey Leather",
      "product_model": "Roma Leather-Grey Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BZ-GRY",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302110,
      "product_name": "Roma Leather-White Leather",
      "product_model": "Roma Leather-White Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BY-WHT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302109,
      "product_name": "Roma Leather-Black Leather",
      "product_model": "Roma Leather-Black Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BU-BLK",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302108,
      "product_name": "Roma Leather-Taupe Leather",
      "product_model": "Roma Leather-Taupe Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BT-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302107,
      "product_name": "Roma Leather-Smoke Leather",
      "product_model": "Roma Leather-Smoke Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BS-SMO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302106,
      "product_name": "Roma Leather-Chocolate Leather",
      "product_model": "Roma Leather-Chocolate Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BH-CHO",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302105,
      "product_name": "Roma Leather-Rose Beige Leather",
      "product_model": "Roma Leather-Rose Beige Leather",
      "product_mpn": "RMA038-U030066-LEA-A0BF-RBE",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302104,
      "product_name": "Roma Fabric-Blue Fabric",
      "product_model": "Roma Fabric-Blue Fabric",
      "product_mpn": "RMA038-U030066-BZA-20-BLU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302103,
      "product_name": "Roma Fabric-Mink Fabric",
      "product_model": "Roma Fabric-Mink Fabric",
      "product_mpn": "RMA038-U030066-BZA-21-MIN",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302102,
      "product_name": "Roma Fabric-Taupe Fabric",
      "product_model": "Roma Fabric-Taupe Fabric",
      "product_mpn": "RMA038-U030066-BZA-16-TAU",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302100,
      "product_name": "Roma Fabric-Silver Fabric",
      "product_model": "Roma Fabric-Silver Fabric",
      "product_mpn": "RMA038-U030066-BZA-07-SIL",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301793,
      "product_name": "Dalby- 3 Seater High Back-Enzo Gold Fabric",
      "product_model": "Dalby- 3 Seater High Back-Enzo Gold Fabric",
      "product_mpn": "DBY003",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301890,
      "product_name": "Ashby- 3 Seater High Back-Enzo Cream Fabric",
      "product_model": "Ashby- 3 Seater High Back-Enzo Cream Fabric",
      "product_mpn": "ASB003",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301889,
      "product_name": "Bassett- 3 Seater High Back- Alexandra Truffle Fabric",
      "product_model": "Bassett- 3 Seater High Back- Alexandra Truffle Fabric",
      "product_mpn": "BST003",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301888,
      "product_name": "Willoughby - 3 Seater Pillow Back - Enzo Cream Fabric",
      "product_model": "Willoughby - 3 Seater Pillow Back - Enzo Cream Fabric",
      "product_mpn": "WLB043",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301891,
      "product_name": "Bridgeport- 3 Seater Sofa-Cherub Cream Fabric",
      "product_model": "Bridgeport- 3 Seater Sofa-Cherub Cream Fabric",
      "product_mpn": "BRP003",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301795,
      "product_name": "Fiesta-3 Seater Sofa-VNM Grey Fabric",
      "product_model": "Fiesta-3 Seater Sofa-VNM Grey Fabric",
      "product_mpn": "FST003",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301794,
      "product_name": "Milner- 3 Seater Sofa-Fernando Granite Fabric",
      "product_model": "Milner- 3 Seater Sofa-Fernando Granite Fabric",
      "product_mpn": "MLN003",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301878,
      "product_name": "Bridgeport-Storage Footstool-Cherub Cream Fabric",
      "product_model": "Bridgeport-Storage Footstool-Cherub Cream Fabric",
      "product_mpn": "BRP010",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301872,
      "product_name": "Fiesta-Footstool-VNM Grey Fabric",
      "product_model": "Fiesta-Footstool-VNM Grey Fabric",
      "product_mpn": "FSTFOOT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301871,
      "product_name": "Fiesta-Loveseat-VNM Grey Fabric",
      "product_model": "Fiesta-Loveseat-VNM Grey Fabric",
      "product_mpn": "FST057",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301870,
      "product_name": "Fiesta-2 Seater Sofa-VNM Grey Fabric",
      "product_model": "Fiesta-2 Seater Sofa-VNM Grey Fabric",
      "product_mpn": "FST002",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301869,
      "product_name": "Milner-Storage Footstool-Fernando Granite Fabric",
      "product_model": "Milner-Storage Footstool-Fernando Granite Fabric",
      "product_mpn": "MLN010",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301868,
      "product_name": "Milner-Armchair-Fernando Granite Fabric",
      "product_model": "Milner-Armchair-Fernando Granite Fabric",
      "product_mpn": "MLN001",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301867,
      "product_name": "Milner-2 Seater Sofa-Fernando Granite Fabric",
      "product_model": "Milner-2 Seater Sofa-Fernando Granite Fabric",
      "product_mpn": "MLN002",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301866,
      "product_name": "Milner-4 Seater Sofa-Fernando Granite Fabric",
      "product_model": "Milner-4 Seater Sofa-Fernando Granite Fabric",
      "product_mpn": "MLN004",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301865,
      "product_name": "Dalby-Large 4 Seater High Back-Enzo Gold Fabric",
      "product_model": "Dalby-Large 4 Seater High Back-Enzo Gold Fabric",
      "product_mpn": "DBY007",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301864,
      "product_name": "Dalby-4 Seater High Back-Enzo Gold Fabric",
      "product_model": "Dalby-4 Seater High Back-Enzo Gold Fabric",
      "product_mpn": "DBY004",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301863,
      "product_name": "Dalby-2 Seater High Back-Enzo Gold Fabric",
      "product_model": "Dalby-2 Seater High Back-Enzo Gold Fabric",
      "product_mpn": "DBY002",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301862,
      "product_name": "Dalby-Loveseat High Back-Enzo Gold Fabric",
      "product_model": "Dalby-Loveseat High Back-Enzo Gold Fabric",
      "product_mpn": "DBY057",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301861,
      "product_name": "Dalby-Armchair-Enzo Gold Fabric",
      "product_model": "Dalby-Armchair-Enzo Gold Fabric",
      "product_mpn": "DBY001",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301860,
      "product_name": "Dalby-Storage Footstool-Enzo Gold Fabric",
      "product_model": "Dalby-Storage Footstool-Enzo Gold Fabric",
      "product_mpn": "DBY010",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301859,
      "product_name": "Dalby-Accent Footstool-Enzo Gold Fabric",
      "product_model": "Dalby-Accent Footstool-Enzo Gold Fabric",
      "product_mpn": "DBYFOOT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301856,
      "product_name": "Ashby-4 Seater Pillow Back-Enzo Cream Fabric",
      "product_model": "Ashby-4 Seater Pillow Back-Enzo Cream Fabric",
      "product_mpn": "ASB061M",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301854,
      "product_name": "Ashby-4 Seater High Back-Enzo Cream Fabric",
      "product_model": "Ashby-4 Seater High Back-Enzo Cream Fabric",
      "product_mpn": "ASB004M",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301853,
      "product_name": "Ashby-3 Seater Pillow Back-Enzo Cream Fabric",
      "product_model": "Ashby-3 Seater Pillow Back-Enzo Cream Fabric",
      "product_mpn": "ASB043",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301852,
      "product_name": "Ashby-2 Seater Pillow Back-Enzo Cream Fabric",
      "product_model": "Ashby-2 Seater Pillow Back-Enzo Cream Fabric",
      "product_mpn": "ASB042",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301851,
      "product_name": "Ashby-2 Seater High Back-Enzo Cream Fabric",
      "product_model": "Ashby-2 Seater High Back-Enzo Cream Fabric",
      "product_mpn": "ASB002",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301850,
      "product_name": "Ashby-Loveseat Pillow Back-Enzo Cream Fabric",
      "product_model": "Ashby-Loveseat Pillow Back-Enzo Cream Fabric",
      "product_mpn": "ASB040",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301849,
      "product_name": "Ashby-Loveseat High Back-Enzo Cream Fabric",
      "product_model": "Ashby-Loveseat High Back-Enzo Cream Fabric",
      "product_mpn": "ASB057",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301848,
      "product_name": "Ashby-Armchair-Enzo Cream Fabric",
      "product_model": "Ashby-Armchair-Enzo Cream Fabric",
      "product_mpn": "ASB001",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301847,
      "product_name": "Ashby-Storage Footstool-Enzo Cream Fabric",
      "product_model": "Ashby-Storage Footstool-Enzo Cream Fabric",
      "product_mpn": "ASB010",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301846,
      "product_name": "Ashby-Accent Footstool-Enzo Cream Fabric",
      "product_model": "Ashby-Accent Footstool-Enzo Cream Fabric",
      "product_mpn": "ASBFOOT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301844,
      "product_name": "Bassett-Grande Pillow Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-Grande Pillow Back-Alexandra Truffle Fabric",
      "product_mpn": "BST045",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301843,
      "product_name": "Bassett-Grande High Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-Grande High Back-Alexandra Truffle Fabric",
      "product_mpn": "BST007",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301842,
      "product_name": "Bassett-4 Seater Pillow Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-4 Seater Pillow Back-Alexandra Truffle Fabric",
      "product_mpn": "BST061",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301841,
      "product_name": "Bassett-4 Seater High Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-4 Seater High Back-Alexandra Truffle Fabric",
      "product_mpn": "BST004",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301840,
      "product_name": "Bassett-3 Seater Pillow Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-3 Seater Pillow Back-Alexandra Truffle Fabric",
      "product_mpn": "BST043",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301839,
      "product_name": "Bassett-2 Seater Pillow Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-2 Seater Pillow Back-Alexandra Truffle Fabric",
      "product_mpn": "BST042",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301838,
      "product_name": "Bassett-2 Seater High Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-2 Seater High Back-Alexandra Truffle Fabric",
      "product_mpn": "BST002",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301837,
      "product_name": "Bassett-Loveseat Pillow Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-Loveseat Pillow Back-Alexandra Truffle Fabric",
      "product_mpn": "BST040",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301836,
      "product_name": "Bassett-Loveseat High Back-Alexandra Truffle Fabric",
      "product_model": "Bassett-Loveseat High Back-Alexandra Truffle Fabric",
      "product_mpn": "BST057",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301835,
      "product_name": "Bassett-Armchair-Alexandra Truffle Fabric",
      "product_model": "Bassett-Armchair-Alexandra Truffle Fabric",
      "product_mpn": "BST001",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301834,
      "product_name": "Bassett-Storage Footstool-Alexandra Truffle Fabric",
      "product_model": "Bassett-Storage Footstool-Alexandra Truffle Fabric",
      "product_mpn": "BST010",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301833,
      "product_name": "Bassett-Footstool-Alexandra Truffle Fabric",
      "product_model": "Bassett-Footstool-Alexandra Truffle Fabric",
      "product_mpn": "BSTFOOT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301831,
      "product_name": "Willoughby-Accent Footstool-Enzo Cream Fabric",
      "product_model": "Willoughby-Accent Footstool-Enzo Cream Fabric",
      "product_mpn": "WLBFOOT",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301830,
      "product_name": "Willoughby-Storage Footstool-Enzo Cream Fabric",
      "product_model": "Willoughby-Storage Footstool-Enzo Cream Fabric",
      "product_mpn": "WLB010",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301829,
      "product_name": "Willoughby-Armchair-Enzo Cream Fabric",
      "product_model": "Willoughby-Armchair-Enzo Cream Fabric",
      "product_mpn": "WLB001",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301828,
      "product_name": "Willoughby-Loveseat High Back-Enzo Cream Fabric",
      "product_model": "Willoughby-Loveseat High Back-Enzo Cream Fabric",
      "product_mpn": "WLB057",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301827,
      "product_name": "Willoughby-Large Symmetrical Corner-Enzo Cream Fabric",
      "product_model": "Willoughby-Large Symmetrical Corner-Enzo Cream Fabric",
      "product_mpn": "WLB082",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301826,
      "product_name": "Willoughby-2 Seater Pillow Back-Enzo Cream Fabric",
      "product_model": "Willoughby-2 Seater Pillow Back-Enzo Cream Fabric",
      "product_mpn": "WLB042",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301825,
      "product_name": "Willoughby-Large 4 Seat Pillow Back-Enzo Cream Fabric",
      "product_model": "Willoughby-Large 4 Seat Pillow Back-Enzo Cream Fabric",
      "product_mpn": "WLB045",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301824,
      "product_name": "Willoughby-Corner Sofa Right Hand-Enzo Cream Fabric",
      "product_model": "Willoughby-Corner Sofa Right Hand-Enzo Cream Fabric",
      "product_mpn": "WLB081R",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301823,
      "product_name": "Willoughby-Corner Sofa Left Hand-Enzo Cream Fabric",
      "product_model": "Willoughby-Corner Sofa Left Hand-Enzo Cream Fabric",
      "product_mpn": "WLB081L",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 301822,
      "product_name": "Willoughby-4 Seater Pillow Back-Enzo Cream Fabric",
      "product_model": "Willoughby-4 Seater Pillow Back-Enzo Cream Fabric",
      "product_mpn": "WLB061",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 1100010,
      "product_name": "Cube Legendary Elite in Black",
      "product_model": "Cube Legendary Elite in Black",
      "product_mpn": "Cube Legendary Elite in Black",
      "product_brand": "Cube",
      "product_category": "COMPUTING",
      "product_subcategory": "DESKTOPS"
    },
    {
      "product_arid": 302122,
      "product_name": "Accent Footstool-Enzo Stone Fabric",
      "product_model": "Accent Footstool-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302121,
      "product_name": "Storage Footstool-Enzo Stone Fabric",
      "product_model": "Storage Footstool-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302120,
      "product_name": "Armchair-Enzo Stone Fabric",
      "product_model": "Armchair-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302119,
      "product_name": "Loveseat High Back-Enzo Stone Fabric",
      "product_model": "Loveseat High Back-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302118,
      "product_name": "Large Symmetrical Corner-Enzo Stone Fabric",
      "product_model": "Large Symmetrical Corner-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302117,
      "product_name": "2 Seater Pillow Back-Enzo Stone Fabric",
      "product_model": "2 Seater Pillow Back-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302116,
      "product_name": "Large 4 Seat Pillow Back-Enzo Stone Fabric",
      "product_model": "Large 4 Seat Pillow Back-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302115,
      "product_name": "Corner Sofa Right Hand-Enzo Stone Fabric",
      "product_model": "Corner Sofa Right Hand-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302114,
      "product_name": "Corner Sofa Left Hand-Enzo Stone Fabric",
      "product_model": "Corner Sofa Left Hand-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302113,
      "product_name": "4 Seater Pillow Back-Enzo Stone Fabric",
      "product_model": "4 Seater Pillow Back-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302112,
      "product_name": "3 Seater Pillow Back-Enzo Stone Fabric",
      "product_model": "3 Seater Pillow Back-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302133,
      "product_name": "Accent Footstool-Enzo Cocoa Fabric",
      "product_model": "Accent Footstool-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302132,
      "product_name": "Storage Footstool-Enzo Cocoa Fabric",
      "product_model": "Storage Footstool-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302131,
      "product_name": "Armchair-Enzo Cocoa Fabric",
      "product_model": "Armchair-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302130,
      "product_name": "Loveseat High Back-Enzo Cocoa Fabric",
      "product_model": "Loveseat High Back-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302129,
      "product_name": "Large Symmetrical Corner-Enzo Cocoa Fabric",
      "product_model": "Large Symmetrical Corner-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302128,
      "product_name": "2 Seater Pillow Back-Enzo Cocoa Fabric",
      "product_model": "2 Seater Pillow Back-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302127,
      "product_name": "Large 4 Seat Pillow Back-Enzo Cocoa Fabric",
      "product_model": "Large 4 Seat Pillow Back-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302126,
      "product_name": "Corner Sofa Right Hand-Enzo Cocoa Fabric",
      "product_model": "Corner Sofa Right Hand-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302125,
      "product_name": "Corner Sofa Left Hand-Enzo Cocoa Fabric",
      "product_model": "Corner Sofa Left Hand-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302124,
      "product_name": "4 Seater Pillow Back-Enzo Cocoa Fabric",
      "product_model": "4 Seater Pillow Back-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302123,
      "product_name": "3 Seater Pillow Back-Enzo Cocoa Fabric",
      "product_model": "3 Seater Pillow Back-Enzo Cocoa Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302208,
      "product_name": "Willoughby-Accent Footstool-Enzo Platinum Fabric",
      "product_model": "Willoughby-Accent Footstool-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302207,
      "product_name": "Willoughby-Storage Footstool-Enzo Platinum Fabric",
      "product_model": "Willoughby-Storage Footstool-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302206,
      "product_name": "Willoughby-Armchair-Enzo Platinum Fabric",
      "product_model": "Willoughby-Armchair-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302205,
      "product_name": "Willoughby-Loveseat High Back-Enzo Platinum Fabric",
      "product_model": "Willoughby-Loveseat High Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302204,
      "product_name": "Willoughby-Large Symmetrical Corner-Enzo Platinum Fabric",
      "product_model": "Willoughby-Large Symmetrical Corner-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302203,
      "product_name": "Willoughby-2 Seater Pillow Back-Enzo Platinum Fabric",
      "product_model": "Willoughby-2 Seater Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302202,
      "product_name": "Willoughby-Large 4 Seat Pillow Back-Enzo Platinum Fabric",
      "product_model": "Willoughby-Large 4 Seat Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302201,
      "product_name": "Willoughby-Corner Sofa Right Hand-Enzo Platinum Fabric",
      "product_model": "Willoughby-Corner Sofa Right Hand-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302200,
      "product_name": "Willoughby-Corner Sofa Left Hand-Enzo Platinum Fabric",
      "product_model": "Willoughby-Corner Sofa Left Hand-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302199,
      "product_name": "Willoughby-4 Seater Pillow Back-Enzo Platinum Fabric",
      "product_model": "Willoughby-4 Seater Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302198,
      "product_name": "Willoughby-3 Seater Pillow Back-Enzo Platinum Fabric",
      "product_model": "Willoughby-3 Seater Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302219,
      "product_name": "Willoughby-Accent Footstool-Enzo Slate Fabric",
      "product_model": "Willoughby-Accent Footstool-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302218,
      "product_name": "Willoughby-Storage Footstool-Enzo Slate Fabric",
      "product_model": "Willoughby-Storage Footstool-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302217,
      "product_name": "Willoughby-Armchair-Enzo Slate Fabric",
      "product_model": "Willoughby-Armchair-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302216,
      "product_name": "Willoughby-Loveseat High Back-Enzo Slate Fabric",
      "product_model": "Willoughby-Loveseat High Back-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302215,
      "product_name": "Willoughby-Large Symmetrical Corner-Enzo Slate Fabric",
      "product_model": "Willoughby-Large Symmetrical Corner-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302214,
      "product_name": "Willoughby-2 Seater Pillow Back-Enzo Slate Fabric",
      "product_model": "Willoughby-2 Seater Pillow Back-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302213,
      "product_name": "Willoughby-Large 4 Seat Pillow Back-Enzo Slate Fabric",
      "product_model": "Willoughby-Large 4 Seat Pillow Back-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302212,
      "product_name": "Willoughby-Corner Sofa Right Hand-Enzo Slate Fabric",
      "product_model": "Willoughby-Corner Sofa Right Hand-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302211,
      "product_name": "Willoughby-Corner Sofa Left Hand-Enzo Slate Fabric",
      "product_model": "Willoughby-Corner Sofa Left Hand-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302210,
      "product_name": "Willoughby-4 Seater Pillow Back-Enzo Slate Fabric",
      "product_model": "Willoughby-4 Seater Pillow Back-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302209,
      "product_name": "Willoughby-3 Seater Pillow Back-Enzo Slate Fabric",
      "product_model": "Willoughby-3 Seater Pillow Back-Enzo Slate Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302229,
      "product_name": "Willoughby-Storage Footstool-Enzo Silver Fabric",
      "product_model": "Willoughby-Storage Footstool-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302230,
      "product_name": "Willoughby-Accent Footstool-Enzo Silver Fabric",
      "product_model": "Willoughby-Accent Footstool-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302228,
      "product_name": "Willoughby-Armchair-Enzo Silver Fabric",
      "product_model": "Willoughby-Armchair-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302227,
      "product_name": "Willoughby-Loveseat High Back-Enzo Silver Fabric",
      "product_model": "Willoughby-Loveseat High Back-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302226,
      "product_name": "Willoughby-Large Symmetrical Corner-Enzo Silver Fabric",
      "product_model": "Willoughby-Large Symmetrical Corner-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302225,
      "product_name": "Willoughby-2 Seater Pillow Back-Enzo Silver Fabric",
      "product_model": "Willoughby-2 Seater Pillow Back-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302224,
      "product_name": "Willoughby-Large 4 Seat Pillow Back-Enzo Silver Fabric",
      "product_model": "Willoughby-Large 4 Seat Pillow Back-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302223,
      "product_name": "Willoughby-Corner Sofa Right Hand Enzo Silver Fabric",
      "product_model": "Willoughby-Corner Sofa Right Hand Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302222,
      "product_name": "Willoughby-Corner Sofa Left Hand-Enzo Silver Fabric",
      "product_model": "Willoughby-Corner Sofa Left Hand-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302221,
      "product_name": "Willoughby-4 Seater Pillow Back-Enzo Silver Fabric",
      "product_model": "Willoughby-4 Seater Pillow Back-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302220,
      "product_name": "Willoughby-3 Seater Pillow Back-Enzo Silver Fabric",
      "product_model": "Willoughby-3 Seater Pillow Back-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800021,
      "product_name": "Storage Footstool-Manolo Mole",
      "product_model": "Storage Footstool-Manolo Mole",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800020,
      "product_name": "Storage Footstool-Manolo Blush",
      "product_model": "Storage Footstool-Manolo Blush",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800019,
      "product_name": "Storage Footstool-Manolo Marble",
      "product_model": "Storage Footstool-Manolo Marble",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800018,
      "product_name": "Storage Footstool-Manolo Spruce",
      "product_model": "Storage Footstool-Manolo Spruce",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800017,
      "product_name": "Storage Footstool-Manolo Latte",
      "product_model": "Storage Footstool-Manolo Latte",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800016,
      "product_name": "Storage Footstool-Manolo Bark",
      "product_model": "Storage Footstool-Manolo Bark",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800014,
      "product_name": "Accent Footstool-Manolo Mole",
      "product_model": "Accent Footstool-Manolo Mole",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800013,
      "product_name": "Accent Footstool-Manolo Blush",
      "product_model": "Accent Footstool-Manolo Blush",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800012,
      "product_name": "Accent Footstool-Manolo Marble",
      "product_model": "Accent Footstool-Manolo Marble",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800011,
      "product_name": "Accent Footstool-Manolo Spruce",
      "product_model": "Accent Footstool-Manolo Spruce",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800010,
      "product_name": "Accent Footstool-Manolo Latte",
      "product_model": "Accent Footstool-Manolo Latte",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800009,
      "product_name": "Accent Footstool-Manolo Bark",
      "product_model": "Accent Footstool-Manolo Bark",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302241,
      "product_name": "Grande Pillow Back-Alexandra Ecru",
      "product_model": "Grande Pillow Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302240,
      "product_name": "Grande High Back-Alexandra Ecru",
      "product_model": "Grande High Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302239,
      "product_name": "4 Seater Pillow Back-Alexandra Ecru",
      "product_model": "4 Seater Pillow Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302238,
      "product_name": "4 Seater High Back-Alexandra Ecru",
      "product_model": "4 Seater High Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302237,
      "product_name": "3 Seater Pillow Back-Alexandra Ecru",
      "product_model": "3 Seater Pillow Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302236,
      "product_name": "2 Seater Pillow Back-Alexandra Ecru",
      "product_model": "2 Seater Pillow Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302235,
      "product_name": "2 Seater High Back-Alexandra Ecru",
      "product_model": "2 Seater High Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302234,
      "product_name": "Loveseat Pillow Back-Alexandra Ecru",
      "product_model": "Loveseat Pillow Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302233,
      "product_name": "Loveseat High Back-Alexandra Ecru",
      "product_model": "Loveseat High Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302232,
      "product_name": "Armchair-Alexandra Ecru",
      "product_model": "Armchair-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800023,
      "product_name": "Storage Footstool-Alexandra Ecru",
      "product_model": "Storage Footstool-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800022,
      "product_name": "Footstool-Alexandra Ecru",
      "product_model": "Footstool-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302231,
      "product_name": "3 Seater High Back-Alexandra Ecru",
      "product_model": "3 Seater High Back-Alexandra Ecru",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302252,
      "product_name": "Grande Pillow Back Alexandra Natural",
      "product_model": "Grande Pillow Back Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302251,
      "product_name": "Grande High Back-Alexandra Natural",
      "product_model": "Grande High Back-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302250,
      "product_name": "4 Seater Pillow Back-Alexandra Natural",
      "product_model": "4 Seater Pillow Back-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302249,
      "product_name": "4 Seater High Back Alexandra Natural",
      "product_model": "4 Seater High Back Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302248,
      "product_name": "3 Seater Pillow Back-Alexandra Natural",
      "product_model": "3 Seater Pillow Back-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302247,
      "product_name": "2 Seater Pillow Back-Alexandra Natural",
      "product_model": "2 Seater Pillow Back-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302246,
      "product_name": "2 Seater High Back Alexandra Natural",
      "product_model": "2 Seater High Back Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302245,
      "product_name": "Loveseat Pillow Back-Alexandra Natural",
      "product_model": "Loveseat Pillow Back-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302244,
      "product_name": "Loveseat High Back-Alexandra Natural",
      "product_model": "Loveseat High Back-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302243,
      "product_name": "Armchair Alexandra Natural",
      "product_model": "Armchair Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800025,
      "product_name": "Storage Footstool-Alexandra Natural",
      "product_model": "Storage Footstool-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800024,
      "product_name": "Footstool-Alexandra Natural",
      "product_model": "Footstool-Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302242,
      "product_name": "3 Seater High Back Alexandra Natural",
      "product_model": "3 Seater High Back Alexandra Natural",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302302,
      "product_name": "Grande Pillow Back Alexandra Grey",
      "product_model": "Grande Pillow Back Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302301,
      "product_name": "Grande High Back-Alexandra Grey",
      "product_model": "Grande High Back-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302300,
      "product_name": "4 Seater Pillow Back-Alexandra Grey",
      "product_model": "4 Seater Pillow Back-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302299,
      "product_name": "4 Seater High Back Alexandra Grey",
      "product_model": "4 Seater High Back Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302298,
      "product_name": "3 Seater Pillow Back-Alexandra Grey",
      "product_model": "3 Seater Pillow Back-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302297,
      "product_name": "2 Seater Pillow Back-Alexandra Grey",
      "product_model": "2 Seater Pillow Back-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302296,
      "product_name": "2 Seater High Back-Alexandra Grey",
      "product_model": "2 Seater High Back-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302295,
      "product_name": "Loveseat Pillow Back-Alexandra Grey",
      "product_model": "Loveseat Pillow Back-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302294,
      "product_name": "Loveseat High Back-Alexandra Grey",
      "product_model": "Loveseat High Back-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302293,
      "product_name": "Armchair Alexandra Grey",
      "product_model": "Armchair Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800047,
      "product_name": "Storage Footstool-Alexandra Grey",
      "product_model": "Storage Footstool-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800046,
      "product_name": "Footstool-Alexandra Grey",
      "product_model": "Footstool-Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302253,
      "product_name": "3 Seater High Back Alexandra Grey",
      "product_model": "3 Seater High Back Alexandra Grey",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302313,
      "product_name": "Grande Pillow Back Alexandra Charcoal",
      "product_model": "Grande Pillow Back Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302312,
      "product_name": "Grande High Back -Alexandra Charcoal",
      "product_model": "Grande High Back -Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302311,
      "product_name": "4 Seater Pillow Back-Alexandra Charcoal",
      "product_model": "4 Seater Pillow Back-Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302310,
      "product_name": "4 Seater High Back Alexandra Charcoal",
      "product_model": "4 Seater High Back Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302309,
      "product_name": "3 Seater Pillow Back-Alexandra Charcoal",
      "product_model": "3 Seater Pillow Back-Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302308,
      "product_name": "2 Seater Pillow Back-Alexandra Charcoal",
      "product_model": "2 Seater Pillow Back-Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302307,
      "product_name": "2 Seater High Back Alexandra Charcoal",
      "product_model": "2 Seater High Back Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302306,
      "product_name": "Loveseat Pillow Back-Alexandra Charcoal",
      "product_model": "Loveseat Pillow Back-Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302305,
      "product_name": "Loveseat High Back-Alexandra Charcoal",
      "product_model": "Loveseat High Back-Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302304,
      "product_name": "Armchair-Alexandra Charcoal",
      "product_model": "Armchair-Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800049,
      "product_name": "Storage Footstool-Alexandra Charcoal",
      "product_model": "Storage Footstool-Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800048,
      "product_name": "Footstool Alexandra Charcoal",
      "product_model": "Footstool Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302303,
      "product_name": "3 Seater High Back Alexandra Charcoal",
      "product_model": "3 Seater High Back Alexandra Charcoal",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302325,
      "product_name": "Grande Pillow Back Alexandra Cocoa",
      "product_model": "Grande Pillow Back Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302324,
      "product_name": "Grande High Back-Alexandra Cocoa",
      "product_model": "Grande High Back-Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302323,
      "product_name": "4 Seater Pillow Back-Alexandra Cocoa",
      "product_model": "4 Seater Pillow Back-Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302322,
      "product_name": "4 Seater High Back Alexandra Cocoa",
      "product_model": "4 Seater High Back Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302321,
      "product_name": "3 Seater Pillow Back-Alexandra Cocoa",
      "product_model": "3 Seater Pillow Back-Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302320,
      "product_name": "2 Seater Pillow Back-Alexandra Cocoa",
      "product_model": "2 Seater Pillow Back-Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302319,
      "product_name": "2 Seater High Back Alexandra Cocoa",
      "product_model": "2 Seater High Back Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302318,
      "product_name": "Loveseat Pillow Back-Alexandra Cocoa",
      "product_model": "Loveseat Pillow Back-Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302317,
      "product_name": "Loveseat High Back-Alexandra Cocoa",
      "product_model": "Loveseat High Back-Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302316,
      "product_name": "Armchair Alexandra Cocoa",
      "product_model": "Armchair Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302315,
      "product_name": "Storage Footstool-Alexandra Cocoa",
      "product_model": "Storage Footstool-Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800050,
      "product_name": "Footstool Alexandra Cocoa",
      "product_model": "Footstool Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800051,
      "product_name": "3 Seater High Back Alexandra Cocoa",
      "product_model": "3 Seater High Back Alexandra Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800063,
      "product_name": "Storage Footstool-Enzo Ivory",
      "product_model": "Storage Footstool-Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800062,
      "product_name": "Footstool Enzo Ivory",
      "product_model": "Footstool Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800061,
      "product_name": "Bassett-Storage Footstool-Enzo Blush",
      "product_model": "Bassett-Storage Footstool-Enzo Blush",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800060,
      "product_name": "Footstool Enzo Blush",
      "product_model": "Footstool Enzo Blush",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800059,
      "product_name": "Storage Footstool-Enzo Gold",
      "product_model": "Storage Footstool-Enzo Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800058,
      "product_name": "Footstool Enzo Gold",
      "product_model": "Footstool Enzo Gold",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800057,
      "product_name": "Storage Footstool-Enzo Denim",
      "product_model": "Storage Footstool-Enzo Denim",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800056,
      "product_name": "Footstool Enzo Denim",
      "product_model": "Footstool Enzo Denim",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800053,
      "product_name": "Storage Footstool-Enzo Steel",
      "product_model": "Storage Footstool-Enzo Steel",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800052,
      "product_name": "Footstool Enzo Steel",
      "product_model": "Footstool Enzo Steel",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302185,
      "product_name": "4 Seater Pillow Back-Enzo Stone Fabric-Ashby",
      "product_model": "4 Seater Pillow Back-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302184,
      "product_name": "4 Seater High Back-Enzo Stone Fabric-Ashby",
      "product_model": "4 Seater High Back-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302183,
      "product_name": "3 Seater Pillow Back-Enzo Stone Fabric-Ashby",
      "product_model": "3 Seater Pillow Back-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302182,
      "product_name": "2 Seater Pillow Back-Enzo Stone Fabric-Ashby",
      "product_model": "2 Seater Pillow Back-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302181,
      "product_name": "2 Seater High Back-Enzo Stone Fabric-Ashby",
      "product_model": "2 Seater High Back-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302180,
      "product_name": "Loveseat Pillow Back-Enzo Stone Fabric-Ashby",
      "product_model": "Loveseat Pillow Back-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302179,
      "product_name": "Loveseat High Back-Enzo Stone Fabric-Ashby",
      "product_model": "Loveseat High Back-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302178,
      "product_name": "Armchair-Enzo Stone Fabric-Ashby",
      "product_model": "Armchair-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302177,
      "product_name": "Storage Footstool-Enzo Stone Fabric-Ashby",
      "product_model": "Storage Footstool-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302176,
      "product_name": "Accent Footstool-Enzo Stone Fabric-Ashby",
      "product_model": "Accent Footstool-Enzo Stone Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302175,
      "product_name": "3 Seater High Back-Enzo Stone Fabric",
      "product_model": "3 Seater High Back-Enzo Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302196,
      "product_name": "4 Seater Pillow Back-Enzo Platinum Fabric",
      "product_model": "4 Seater Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302195,
      "product_name": "4 Seater High Back-Enzo Platinum Fabric",
      "product_model": "4 Seater High Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302194,
      "product_name": "3 Seater Pillow Back-Enzo Platinum Fabric",
      "product_model": "3 Seater Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302193,
      "product_name": "2 Seater Pillow Back-Enzo Platinum Fabric",
      "product_model": "2 Seater Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302192,
      "product_name": "2 Seater High Back-Enzo Platinum Fabric",
      "product_model": "2 Seater High Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302191,
      "product_name": "Loveseat Pillow Back-Enzo Platinum Fabric",
      "product_model": "Loveseat Pillow Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302190,
      "product_name": "Loveseat High Back-Enzo Platinum Fabric",
      "product_model": "Loveseat High Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302189,
      "product_name": "Armchair-Enzo Platinum Fabric",
      "product_model": "Armchair-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302188,
      "product_name": "Storage Footstool-Enzo Platinum Fabric",
      "product_model": "Storage Footstool-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302187,
      "product_name": "Accent Footstool-Enzo Platinum Fabric",
      "product_model": "Accent Footstool-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302186,
      "product_name": "3 Seater High Back-Enzo Platinum Fabric",
      "product_model": "3 Seater High Back-Enzo Platinum Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302261,
      "product_name": "4 Seater Pillow Back-Enzo Silver Fabric-Ashby",
      "product_model": "4 Seater Pillow Back-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302260,
      "product_name": "4 Seater High Back-Enzo Silver Fabric-Ashby",
      "product_model": "4 Seater High Back-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302259,
      "product_name": "3 Seater Pillow Back-Enzo Silver Fabric-Ashby",
      "product_model": "3 Seater Pillow Back-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302258,
      "product_name": "2 Seater Pillow Back-Enzo Silver Fabric-Ashby",
      "product_model": "2 Seater Pillow Back-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302257,
      "product_name": "2 Seater High Back-Enzo Silver Fabric-Ashby",
      "product_model": "2 Seater High Back-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302256,
      "product_name": "Loveseat Pillow Back-Enzo Silver Fabric-Ashby",
      "product_model": "Loveseat Pillow Back-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302255,
      "product_name": "Loveseat High Back-Enzo Silver Fabric-Ashby",
      "product_model": "Loveseat High Back-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302254,
      "product_name": "Armchair-Enzo Silver Fabric-Ashby",
      "product_model": "Armchair-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800027,
      "product_name": "Storage Footstool-Enzo Silver Fabric-Ashby",
      "product_model": "Storage Footstool-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800026,
      "product_name": "Accent Footstool-Enzo Silver Fabric-Ashby",
      "product_model": "Accent Footstool-Enzo Silver Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302197,
      "product_name": "3 Seater High Back-Enzo Silver Fabric",
      "product_model": "3 Seater High Back-Enzo Silver Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302270,
      "product_name": "4 Seater Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_model": "4 Seater Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302269,
      "product_name": "4 Seater High Back-Enzo Ivory Fabric-Ashby",
      "product_model": "4 Seater High Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302268,
      "product_name": "3 Seater Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_model": "3 Seater Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302267,
      "product_name": "2 Seater Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_model": "2 Seater Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302266,
      "product_name": "2 Seater High Back-Enzo Ivory Fabric-Ashby",
      "product_model": "2 Seater High Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302265,
      "product_name": "Loveseat Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_model": "Loveseat Pillow Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302264,
      "product_name": "Loveseat High Back-Enzo Ivory Fabric-Ashby",
      "product_model": "Loveseat High Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302263,
      "product_name": "Armchair-Enzo Ivory Fabric-Ashby",
      "product_model": "Armchair-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800029,
      "product_name": "Storage Footstool-Enzo Ivory Fabric-Ashby",
      "product_model": "Storage Footstool-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800028,
      "product_name": "Accent Footstool-Enzo Ivory Fabric-Ashby",
      "product_model": "Accent Footstool-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302262,
      "product_name": "3 Seater High Back-Enzo Ivory Fabric-Ashby",
      "product_model": "3 Seater High Back-Enzo Ivory Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302279,
      "product_name": "4 Seater Pillow Back-Enzo Olive Fabric-Ashby",
      "product_model": "4 Seater Pillow Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302278,
      "product_name": "4 Seater High Back-Enzo Olive Fabric-Ashby",
      "product_model": "4 Seater High Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302277,
      "product_name": "3 Seater Pillow Back-Enzo Olive Fabric-Ashby",
      "product_model": "3 Seater Pillow Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302276,
      "product_name": "2 Seater Pillow Back-Enzo Olive Fabric-Ashby",
      "product_model": "2 Seater Pillow Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302275,
      "product_name": "2 Seater High Back-Enzo Olive Fabric-Ashby",
      "product_model": "2 Seater High Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302274,
      "product_name": "Loveseat Pillow Back-Enzo Olive Fabric-Ashby",
      "product_model": "Loveseat Pillow Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302273,
      "product_name": "Loveseat High Back-Enzo Olive Fabric-Ashby",
      "product_model": "Loveseat High Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302272,
      "product_name": "Armchair-Enzo Olive Fabric-Ashby",
      "product_model": "Armchair-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800031,
      "product_name": "Storage Footstool-Enzo Olive Fabric-Ashby",
      "product_model": "Storage Footstool-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800030,
      "product_name": "Accent Footstool-Enzo Olive Fabric-Ashby",
      "product_model": "Accent Footstool-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302271,
      "product_name": "3 Seater High Back-Enzo Olive Fabric-Ashby",
      "product_model": "3 Seater High Back-Enzo Olive Fabric-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800043,
      "product_name": "Storage Footstool-Chamonix Linen-Ashby",
      "product_model": "Storage Footstool-Chamonix Linen-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800042,
      "product_name": "Accent Footstool-Chamonix Linen-Ashby",
      "product_model": "Accent Footstool-Chamonix Linen-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800041,
      "product_name": "Storage Footstool-Chamonix Blush-Ashby",
      "product_model": "Storage Footstool-Chamonix Blush-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800040,
      "product_name": "Accent Footstool-Chamonix Blush-Ashby",
      "product_model": "Accent Footstool-Chamonix Blush-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800039,
      "product_name": "Storage Footstool-Chamonix Steel-Ashby",
      "product_model": "Storage Footstool-Chamonix Steel-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800038,
      "product_name": "Accent Footstool-Chamonix Steel-Ashby",
      "product_model": "Accent Footstool-Chamonix Steel-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800037,
      "product_name": "Storage Footstool-Chamonix Anthracite-Ashby",
      "product_model": "Storage Footstool-Chamonix Anthracite-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800036,
      "product_name": "Accent Footstool-Chamonix Anthracite-Ashby",
      "product_model": "Accent Footstool-Chamonix Anthracite-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800035,
      "product_name": "Storage Footstool-Chamonix Pecan-Ashby",
      "product_model": "Storage Footstool-Chamonix Pecan-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800034,
      "product_name": "Accent Footstool-Chamonix Pecan-Ashby",
      "product_model": "Accent Footstool-Chamonix Pecan-Ashby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302285,
      "product_name": "Large 4 Seater High Back-Enzo Denim-Dalby",
      "product_model": "Large 4 Seater High Back-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302284,
      "product_name": "4 Seater High Back-Enzo Denim-Dalby",
      "product_model": "4 Seater High Back-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302283,
      "product_name": "2 Seater High Back-Enzo Denim-Dalby",
      "product_model": "2 Seater High Back-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302282,
      "product_name": "Loveseat High Back-Enzo Denim-Dalby",
      "product_model": "Loveseat High Back-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302281,
      "product_name": "Armchair-Enzo Denim-Dalby",
      "product_model": "Armchair-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800033,
      "product_name": "Storage Footstool-Enzo Denim-Dalby",
      "product_model": "Storage Footstool-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800032,
      "product_name": "Accent Footstool-Enzo Denim-Dalby",
      "product_model": "Accent Footstool-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302280,
      "product_name": "3 Seater High Back-Enzo Denim-Dalby",
      "product_model": "3 Seater High Back-Enzo Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302291,
      "product_name": "Large 4 Seater High Back-Enzo Silver-Dalby",
      "product_model": "Large 4 Seater High Back-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302290,
      "product_name": "4 Seater High Back-Enzo Silver-Dalby",
      "product_model": "4 Seater High Back-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302289,
      "product_name": "2 Seater High Back-Enzo Silver-Dalby",
      "product_model": "2 Seater High Back-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302288,
      "product_name": "Loveseat High Back-Enzo Silver-Dalby",
      "product_model": "Loveseat High Back-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302287,
      "product_name": "Armchair-Enzo Silver-Dalby",
      "product_model": "Armchair-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800045,
      "product_name": "Storage Footstool-Enzo Silver-Dalby",
      "product_model": "Storage Footstool-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800044,
      "product_name": "Accent Footstool-Enzo Silver-Dalby",
      "product_model": "Accent Footstool-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302286,
      "product_name": "3 Seater High Back-Enzo Silver-Dalby",
      "product_model": "3 Seater High Back-Enzo Silver-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302330,
      "product_name": "Large 4 Seater High Back-Enzo Cream",
      "product_model": "Large 4 Seater High Back-Enzo Cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302329,
      "product_name": "4 Seater High Back Enzo Cream",
      "product_model": "4 Seater High Back Enzo Cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302328,
      "product_name": "2 Seater High Back Enzo Cream",
      "product_model": "2 Seater High Back Enzo Cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302327,
      "product_name": "Loveseat High Back-Enzo Cream",
      "product_model": "Loveseat High Back-Enzo Cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302326,
      "product_name": "Armchair Enzo Cream",
      "product_model": "Armchair Enzo Cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800065,
      "product_name": "Storage Footstool-Enzo Cream",
      "product_model": "Storage Footstool-Enzo Cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800064,
      "product_name": "Accent Footstool-Enzo Cream",
      "product_model": "Accent Footstool-Enzo Cream",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302292,
      "product_name": "3 Seater High Back-Enzo Cream-Dalby",
      "product_model": "3 Seater High Back-Enzo Cream-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302336,
      "product_name": "Large 4 Seater High Back-Enzo Ivory",
      "product_model": "Large 4 Seater High Back-Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302335,
      "product_name": "4 Seater High Back Enzo Ivory",
      "product_model": "4 Seater High Back Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302334,
      "product_name": "2 Seater High Back Enzo Ivory",
      "product_model": "2 Seater High Back Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302333,
      "product_name": "Loveseat High Back-Enzo Ivory",
      "product_model": "Loveseat High Back-Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302332,
      "product_name": "Armchair Enzo Ivory",
      "product_model": "Armchair Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800067,
      "product_name": "Dalby-Storage Footstool-Enzo Ivory",
      "product_model": "Dalby-Storage Footstool-Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800066,
      "product_name": "Accent Footstool-Enzo Ivory",
      "product_model": "Accent Footstool-Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302331,
      "product_name": "3 Seater High Back Enzo Ivory",
      "product_model": "3 Seater High Back Enzo Ivory",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302342,
      "product_name": "Large 4 Seater High Back-Enzo Stone",
      "product_model": "Large 4 Seater High Back-Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302341,
      "product_name": "4 Seater High Back Enzo Stone",
      "product_model": "4 Seater High Back Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302340,
      "product_name": "2 Seater High Back Enzo Stone",
      "product_model": "2 Seater High Back Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302339,
      "product_name": "Loveseat High Back-Enzo Stone",
      "product_model": "Loveseat High Back-Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302338,
      "product_name": "Armchair-Enzo Stone",
      "product_model": "Armchair-Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800069,
      "product_name": "Dalby-Storage Footstool-Enzo Stone",
      "product_model": "Dalby-Storage Footstool-Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800068,
      "product_name": "Accent Footstool-Enzo Stone",
      "product_model": "Accent Footstool-Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302337,
      "product_name": "3 Seater High Back Enzo Stone",
      "product_model": "3 Seater High Back Enzo Stone",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302348,
      "product_name": "Large 4 Seater High Back-Enzo Cocoa",
      "product_model": "Large 4 Seater High Back-Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302347,
      "product_name": "4 Seater High Back-Enzo Cocoa",
      "product_model": "4 Seater High Back-Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302346,
      "product_name": "2 Seater High Back-Enzo Cocoa",
      "product_model": "2 Seater High Back-Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302345,
      "product_name": "Loveseat High Back-Enzo Cocoa",
      "product_model": "Loveseat High Back-Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302344,
      "product_name": "Armchair-Enzo Cocoa",
      "product_model": "Armchair-Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800071,
      "product_name": "Storage Footstool-Enzo Cocoa",
      "product_model": "Storage Footstool-Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800070,
      "product_name": "Accent Footstool-Enzo Cocoa",
      "product_model": "Accent Footstool-Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302343,
      "product_name": "3 Seater High Back Enzo Cocoa",
      "product_model": "3 Seater High Back Enzo Cocoa",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302354,
      "product_name": "Large 4 Seater High Back-Enzo Blush-Dalby",
      "product_model": "Large 4 Seater High Back-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302353,
      "product_name": "4 Seater High Back-Enzo Blush-Dalby",
      "product_model": "4 Seater High Back-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302352,
      "product_name": "2 Seater High Back-Enzo Blush-Dalby",
      "product_model": "2 Seater High Back-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302351,
      "product_name": "Loveseat High Back-Enzo Blush-Dalby",
      "product_model": "Loveseat High Back-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302350,
      "product_name": "Armchair-Enzo Blush-Dalby",
      "product_model": "Armchair-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800073,
      "product_name": "Storage Footstool-Enzo Blush-Dalby",
      "product_model": "Storage Footstool-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800072,
      "product_name": "Accent Footstool-Enzo Blush-Dalby",
      "product_model": "Accent Footstool-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302349,
      "product_name": "3 Seater High Back-Enzo Blush-Dalby",
      "product_model": "3 Seater High Back-Enzo Blush-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302360,
      "product_name": "Large 4 Seater High Back-Enzo Olive-Dalby",
      "product_model": "Large 4 Seater High Back-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302359,
      "product_name": "4 Seater High Back-Enzo Olive-Dalby",
      "product_model": "4 Seater High Back-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302358,
      "product_name": "2 Seater High Back-Enzo Olive-Dalby",
      "product_model": "2 Seater High Back-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302357,
      "product_name": "Loveseat High Back-Enzo Olive-Dalby",
      "product_model": "Loveseat High Back-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302356,
      "product_name": "Armchair-Enzo Olive-Dalby",
      "product_model": "Armchair-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800075,
      "product_name": "Storage Footstool-Enzo Olive-Dalby",
      "product_model": "Storage Footstool-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800074,
      "product_name": "Accent Footstool-Enzo Olive-Dalby",
      "product_model": "Accent Footstool-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302355,
      "product_name": "3 Seater High Back-Enzo Olive-Dalby",
      "product_model": "3 Seater High Back-Enzo Olive-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 700800081,
      "product_name": "Accent Footstool-Origami Sage-Dalby",
      "product_model": "Accent Footstool-Origami Sage-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800079,
      "product_name": "Accent Footstool-Origami Ivory-Dalby",
      "product_model": "Accent Footstool-Origami Ivory-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800078,
      "product_name": "Accent Footstool-Origami Charcoal-Dalby",
      "product_model": "Accent Footstool-Origami Charcoal-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800077,
      "product_name": "Accent Footstool-Origami Gold-Dalby",
      "product_model": "Accent Footstool-Origami Gold-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800076,
      "product_name": "Accent Footstool-Origami Denim-Dalby",
      "product_model": "Accent Footstool-Origami Denim-Dalby",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 302138,
      "product_name": "Storage Footstool-Fernando Mustard Fabric",
      "product_model": "Storage Footstool-Fernando Mustard Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302137,
      "product_name": "Armchair-Fernando Mustard Fabric",
      "product_model": "Armchair-Fernando Mustard Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302136,
      "product_name": "2 Seater Sofa-Fernando Mustard Fabric",
      "product_model": "2 Seater Sofa-Fernando Mustard Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302135,
      "product_name": "4 Seater Sofa-Fernando Mustard Fabric",
      "product_model": "4 Seater Sofa-Fernando Mustard Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302134,
      "product_name": "3 Seater Sofa-Fernando Mustard Fabric",
      "product_model": "3 Seater Sofa-Fernando Mustard Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302143,
      "product_name": "Storage Footstool-Fernando Teal Fabric",
      "product_model": "Storage Footstool-Fernando Teal Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302142,
      "product_name": "Armchair-Fernando Teal Fabric",
      "product_model": "Armchair-Fernando Teal Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302141,
      "product_name": "2 Seater Sofa-Fernando Teal Fabric",
      "product_model": "2 Seater Sofa-Fernando Teal Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302140,
      "product_name": "4 Seater Sofa-Fernando Teal Fabric",
      "product_model": "4 Seater Sofa-Fernando Teal Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302139,
      "product_name": "3 Seater Sofa-Fernando Teal Fabric",
      "product_model": "3 Seater Sofa-Fernando Teal Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302148,
      "product_name": "Storage Footstool-Fernando Denim Fabric",
      "product_model": "Storage Footstool-Fernando Denim Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302147,
      "product_name": "Armchair-Fernando Denim Fabric",
      "product_model": "Armchair-Fernando Denim Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302146,
      "product_name": "2 Seater Sofa-Fernando Denim Fabric",
      "product_model": "2 Seater Sofa-Fernando Denim Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302145,
      "product_name": "4 Seater Sofa-Fernando Denim Fabric",
      "product_model": "4 Seater Sofa-Fernando Denim Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302144,
      "product_name": "3 Seater Sofa-Fernando Denim Fabric",
      "product_model": "3 Seater Sofa-Fernando Denim Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302153,
      "product_name": "Storage Footstool-Fernando Shark Fabric",
      "product_model": "Storage Footstool-Fernando Shark Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302152,
      "product_name": "Armchair-Fernando Shark Fabric",
      "product_model": "Armchair-Fernando Shark Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302151,
      "product_name": "2 Seater Sofa-Fernando Shark Fabric",
      "product_model": "2 Seater Sofa-Fernando Shark Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302150,
      "product_name": "4 Seater Sofa-Fernando Shark Fabric",
      "product_model": "4 Seater Sofa-Fernando Shark Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302149,
      "product_name": "3 Seater Sofa-Fernando Shark Fabric",
      "product_model": "3 Seater Sofa-Fernando Shark Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302158,
      "product_name": "Storage Footstool-Fernando Stone Fabric",
      "product_model": "Storage Footstool-Fernando Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302157,
      "product_name": "Armchair-Fernando Stone Fabric",
      "product_model": "Armchair-Fernando Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302156,
      "product_name": "2 Seater Sofa-Fernando Stone Fabric",
      "product_model": "2 Seater Sofa-Fernando Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302155,
      "product_name": "4 Seater Sofa-Fernando Stone Fabric",
      "product_model": "4 Seater Sofa-Fernando Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302154,
      "product_name": "3 Seater Sofa-Fernando Stone Fabric",
      "product_model": "3 Seater Sofa-Fernando Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302162,
      "product_name": "Footstool-VNM Aqua Fabric",
      "product_model": "Footstool-VNM Aqua Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302161,
      "product_name": "Loveseat-VNM Aqua Fabric",
      "product_model": "Loveseat-VNM Aqua Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302160,
      "product_name": "2 Seater Sofa-VNM Aqua Fabric",
      "product_model": "2 Seater Sofa-VNM Aqua Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302159,
      "product_name": "3 Seater Sofa-VNM Aqua Fabric",
      "product_model": "3 Seater Sofa-VNM Aqua Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302166,
      "product_name": "Footstool-VNM Beige Fabric",
      "product_model": "Footstool-VNM Beige Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302165,
      "product_name": "Loveseat-VNM Beige Fabric",
      "product_model": "Loveseat-VNM Beige Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302164,
      "product_name": "2 Seater Sofa-VNM Beige Fabric",
      "product_model": "2 Seater Sofa-VNM Beige Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302163,
      "product_name": "3 Seater Sofa-VNM Beige Fabric",
      "product_model": "3 Seater Sofa-VNM Beige Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302170,
      "product_name": "Footstool-VNM Stone Fabric",
      "product_model": "Footstool-VNM Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302169,
      "product_name": "Loveseat-VNM Stone Fabric",
      "product_model": "Loveseat-VNM Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302168,
      "product_name": "2 Seater Sofa-VNM Stone Fabric",
      "product_model": "2 Seater Sofa-VNM Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302167,
      "product_name": "3 Seater Sofa-VNM Stone Fabric",
      "product_model": "3 Seater Sofa-VNM Stone Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302174,
      "product_name": "Footstool-VNM Blush Fabric",
      "product_model": "Footstool-VNM Blush Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302173,
      "product_name": "Loveseat-VNM Blush Fabric",
      "product_model": "Loveseat-VNM Blush Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302172,
      "product_name": "2 Seater Sofa-VNM Blush Fabric",
      "product_model": "2 Seater Sofa-VNM Blush Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302171,
      "product_name": "3 Seater Sofa-VNM Blush Fabric",
      "product_model": "3 Seater Sofa-VNM Blush Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302361,
      "product_name": "3 Seater Sofa-Cherub Grey Fabric-Bridgeport",
      "product_model": "3 Seater Sofa-Cherub Grey Fabric-Bridgeport",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302367,
      "product_name": "3 Seater Sofa-Cherub Beige Fabric-Bridgeport",
      "product_model": "3 Seater Sofa-Cherub Beige Fabric-Bridgeport",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 302380,
      "product_name": "3 Seater Sofa-Cherub Duckegg Fabric",
      "product_model": "3 Seater Sofa-Cherub Duckegg Fabric",
      "product_mpn": "",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS"
    },
    {
      "product_arid": 8000319,
      "product_name": "Samsung Galaxy Book Pro 11th Gen 13\" (i7)",
      "product_model": "Samsung Galaxy Book Pro 11th Gen 13\" (i7)",
      "product_mpn": "Samsung Galaxy Book Pro 11th Gen 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000318,
      "product_name": "Samsung Galaxy Book Pro 11th Gen 13\" (i5)",
      "product_model": "Samsung Galaxy Book Pro 11th Gen 13\" (i5)",
      "product_mpn": "Samsung Galaxy Book Pro 11th Gen 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000317,
      "product_name": "Lenovo Yoga Slim 7 11th Gen 14\" (i7)",
      "product_model": "Lenovo Yoga Slim 7 11th Gen 14\" (i7)",
      "product_mpn": "Lenovo Yoga Slim 7 11th Gen Carbon Edition 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000316,
      "product_name": "Lenovo Yoga Slim 7 11th Gen 13\" (i5)",
      "product_model": "Lenovo Yoga Slim 7 11th Gen 13\" (i5)",
      "product_mpn": "Lenovo Yoga Slim 7 11th Gen Carbon Edition 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000315,
      "product_name": "MSI Prestige 14 11th Gen 14\" (i7)",
      "product_model": "MSI Prestige 14 11th Gen 14\" (i7)",
      "product_mpn": "MSI Prestige 14 11th Gen 14\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000314,
      "product_name": "MSI Prestige 14 11th Gen 14\" (i5)",
      "product_model": "MSI Prestige 14 11th Gen 14\" (i5)",
      "product_mpn": "MSI Prestige 14 11th Gen 14\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000313,
      "product_name": "Asus Zenbook 11th Gen UX363 13\" (i7)",
      "product_model": "Asus Zenbook 11th Gen UX363 13\" (i7)",
      "product_mpn": "Asus Zenbook 11th Gen UX363 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000312,
      "product_name": "Asus Zenbook 11th Gen UX363 13\" (i5)",
      "product_model": "Asus Zenbook 11th Gen UX363 13\" (i5)",
      "product_mpn": "Asus Zenbook 11th Gen UX363 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000311,
      "product_name": "Acer Swift 3 11th Gen SF314-59 (i7)",
      "product_model": "Acer Swift 3 11th Gen SF314-59 (i7)",
      "product_mpn": "Acer Swift 3 11th Gen SF314-59 (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000310,
      "product_name": "Acer Swift 3 11th Gen SF314-59 (i5)",
      "product_model": "Acer Swift 3 11th Gen SF314-59 (i5)",
      "product_mpn": "Acer Swift 3 11th Gen SF314-59 (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000309,
      "product_name": "LG Gram 11th Gen 1690P 16\" (i7)",
      "product_model": "LG Gram 11th Gen 1690P 16\" (i7)",
      "product_mpn": "LG Gram 11th Gen 1690P 16\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000308,
      "product_name": "LG Gram 11th Gen 1690P 16\" (i5)",
      "product_model": "LG Gram 11th Gen 1690P 16\" (i5)",
      "product_mpn": "LG Gram 11th Gen 1690P 16\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000307,
      "product_name": "Acer Swift 5 11th Gen 14\" (i7)",
      "product_model": "Acer Swift 5 11th Gen 14\" (i7)",
      "product_mpn": "Acer Swift 5 11th Gen 14\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000306,
      "product_name": "Acer Swift 5 11th Gen 14\" (i5)",
      "product_model": "Acer Swift 5 11th Gen 14\" (i5)",
      "product_mpn": "Acer Swift 5 11th Gen 14\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000305,
      "product_name": "Dell XPS 11th Gen 13\" (i7)",
      "product_model": "Dell XPS 11th Gen 13\" (i7)",
      "product_mpn": "Dell XPS 11th Gen 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000304,
      "product_name": "Dell XPS 11th Gen 13\" (i5)",
      "product_model": "Dell XPS 11th Gen 13\" (i5)",
      "product_mpn": "Dell XPS 11th Gen 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000303,
      "product_name": "HP Spectre x360 11th Gen 14-ea0519na 13\" (i7)",
      "product_model": "HP Spectre x360 11th Gen 14-ea0519na 13\" (i7)",
      "product_mpn": "HP Spectre x360 11th Gen 14-ea0519na 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000302,
      "product_name": "HP Spectre x360 11th Gen 14-ea0519na 13\" (i5)",
      "product_model": "HP Spectre x360 11th Gen 14-ea0519na 13\" (i5)",
      "product_mpn": "HP Spectre x360 11th Gen 14-ea0519na 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000301,
      "product_name": "Lenovo Yoga 7i 11th Gen 14\" (i7)",
      "product_model": "Lenovo Yoga 7i 11th Gen 14\" (i7)",
      "product_mpn": "Lenovo Yoga 7i 11th Gen 14\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000300,
      "product_name": "Lenovo Yoga 7i 11th Gen 14\" (i5)",
      "product_model": "Lenovo Yoga 7i 11th Gen 14\" (i5)",
      "product_mpn": "Lenovo Yoga 7i 11th Gen 14\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000299,
      "product_name": "Razer Book 13 (i7)",
      "product_model": "Razer Book 13 (i7)",
      "product_mpn": "Razer Book 13 (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000298,
      "product_name": "Razer Book 11th Gen 13\" (i5)",
      "product_model": "Razer Book 11th Gen 13\" (i5)",
      "product_mpn": "Razer Book 11th Gen 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000297,
      "product_name": "Asus Zenbook Flip S 11th Gen - HL701TS 13\" (i7)",
      "product_model": "Asus Zenbook Flip S 11th Gen - HL701TS 13\" (i7)",
      "product_mpn": "Asus Zenbook Flip S 11th Gen - HL701TS 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000296,
      "product_name": "Asus Zenbook Flip S 11th Gen - HL701TS 13\" (i5)",
      "product_model": "Asus Zenbook Flip S 11th Gen - HL701TS 13\" (i5)",
      "product_mpn": "Asus Zenbook Flip S 11th Gen - HL701TS 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000295,
      "product_name": "Acer Swift 3 11th Gen SF313-53 13\" (i7)",
      "product_model": "Acer Swift 3 11th Gen SF313-53 13\" (i7)",
      "product_mpn": "Acer Swift 3 11th Gen SF313-53 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000294,
      "product_name": "Acer Swift 3 11th Gen SF313-53 13\" (i5)",
      "product_model": "Acer Swift 3 11th Gen SF313-53 13\" (i5)",
      "product_mpn": "Acer Swift 3 11th Gen SF313-53 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000293,
      "product_name": "HP Envy x360 Convertible 11th Gen - 13-BD0063TU 13\" (i7)",
      "product_model": "HP Envy x360 Convertible 11th Gen - 13-BD0063TU 13\" (i7)",
      "product_mpn": "HP Envy x360 Convertible 11th Gen - 13-BD0063TU 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000292,
      "product_name": "HP Envy x360 Convertible 11th Gen - 13-BD0063TU 13\" (i5)",
      "product_model": "HP Envy x360 Convertible 11th Gen - 13-BD0063TU 13\" (i5)",
      "product_mpn": "HP Envy x360 Convertible 11th Gen - 13-BD0063TU 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000291,
      "product_name": "HP Spectre x360 11th Gen 13-aw2001TU 13\" (i7)",
      "product_model": "HP Spectre x360 11th Gen 13-aw2001TU 13\" (i7)",
      "product_mpn": "HP Spectre x360 11th Gen 13-aw2001TU 13\" (i7)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000290,
      "product_name": "HP Spectre x360 11th Gen 13-aw2001TU 13\" (i5)",
      "product_model": "HP Spectre x360 11th Gen 13-aw2001TU 13\" (i5)",
      "product_mpn": "HP Spectre x360 11th Gen 13-aw2001TU 13\" (i5)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 700800087,
      "product_name": "Ashley Manor Ashby Accent Footstool - Cadiz Smoke",
      "product_model": "Ashley Manor Ashby Accent Footstool - Cadiz Smoke",
      "product_mpn": "Ashley Manor Ashby Accent Footstool - Cadiz Smoke",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 700800086,
      "product_name": "Ashley Manor Ashby Accent Footstool - Cadiz Natural",
      "product_model": "Ashley Manor Ashby Accent Footstool - Cadiz Natural",
      "product_mpn": "Ashley Manor Ashby Accent Footstool - Cadiz Natural",
      "product_brand": "Oak Furnitureland",
      "product_category": "ALL ROOMS",
      "product_subcategory": "STOOLS"
    },
    {
      "product_arid": 6900104,
      "product_name": "POP KEYS WIRELESS Heartbreaker Rose",
      "product_model": "POP KEYS WIRELESS Heartbreaker Rose",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500060,
      "product_name": "MEVO START Black",
      "product_model": "MEVO START Black",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 500500059,
      "product_name": "M750 L Graphite",
      "product_model": "M750 L Graphite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500058,
      "product_name": "M750 Rose",
      "product_model": "M750 Rose",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500057,
      "product_name": "M750 Off White",
      "product_model": "M750 Off White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500056,
      "product_name": "M750 Graphite",
      "product_model": "M750 Graphite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500055,
      "product_name": "M650 L LEFT Offwhite",
      "product_model": "M650 L LEFT Offwhite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500054,
      "product_name": "M650 L Rose",
      "product_model": "M650 L Rose",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500053,
      "product_name": "M650 L Red",
      "product_model": "M650 L Red",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500052,
      "product_name": "M650 L Blue",
      "product_model": "M650 L Blue",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500051,
      "product_name": "M650 Rose",
      "product_model": "M650 Rose",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500050,
      "product_name": "M650 Off White",
      "product_model": "M650 Off White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500049,
      "product_name": "LIFT VERTICAL LEFT Graphite",
      "product_model": "LIFT VERTICAL LEFT Graphite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500048,
      "product_name": "LIFT VERTICAL Rose",
      "product_model": "LIFT VERTICAL Rose",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500047,
      "product_name": "LIFT VERTICAL Offwhite",
      "product_model": "LIFT VERTICAL Offwhite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500046,
      "product_name": "Logitech Lift Vertical Ergonomic Mouse  - GRAPHITE / BLACK",
      "product_model": "Logitech Lift Vertical Ergonomic Mouse  - GRAPHITE / BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 8000360,
      "product_name": "Intel Evo 12th Gen Avatar | Korea",
      "product_model": "Intel Evo 12th Gen Avatar | Korea",
      "product_mpn": "Intel Evo 12th Gen Avatar | Korea",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000332,
      "product_name": "Intel Evo 12th Gen Avatar | France",
      "product_model": "Intel Evo 12th Gen Avatar | France",
      "product_mpn": "Intel Evo 12th Gen Avatar | France",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000331,
      "product_name": "Intel Evo 12th Gen Avatar | Sweden",
      "product_model": "Intel Evo 12th Gen Avatar | Sweden",
      "product_mpn": "Intel Evo 12th Gen Avatar | Sweden",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000330,
      "product_name": "Intel Evo 12th Gen Avatar | Turkey",
      "product_model": "Intel Evo 12th Gen Avatar | Turkey",
      "product_mpn": "Intel Evo 12th Gen Avatar | Turkey",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000329,
      "product_name": "Intel Evo 12th Gen Avatar | Spain",
      "product_model": "Intel Evo 12th Gen Avatar | Spain",
      "product_mpn": "Intel Evo 12th Gen Avatar | Spain",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000328,
      "product_name": "Intel Evo 12th Gen Avatar | Norway",
      "product_model": "Intel Evo 12th Gen Avatar | Norway",
      "product_mpn": "Intel Evo 12th Gen Avatar | Norway",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000327,
      "product_name": "Intel Evo 12th Gen Avatar | Hungary",
      "product_model": "Intel Evo 12th Gen Avatar | Hungary",
      "product_mpn": "Intel Evo 12th Gen Avatar | Hungary",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000326,
      "product_name": "Intel Evo 12th Gen Avatar | Germany",
      "product_model": "Intel Evo 12th Gen Avatar | Germany",
      "product_mpn": "Intel Evo 12th Gen Avatar | Germany",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000325,
      "product_name": "Intel Evo 12th Gen Avatar | French Canada",
      "product_model": "Intel Evo 12th Gen Avatar | French Canada",
      "product_mpn": "Intel Evo 12th Gen Avatar | French Canada",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000324,
      "product_name": "Intel Evo 12th Gen Avatar | Finland",
      "product_model": "Intel Evo 12th Gen Avatar | Finland",
      "product_mpn": "Intel Evo 12th Gen Avatar | Finland",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000323,
      "product_name": "Intel Evo 12th Gen Avatar | UK",
      "product_model": "Intel Evo 12th Gen Avatar | UK",
      "product_mpn": "Intel Evo 12th Gen Avatar | UK",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000322,
      "product_name": "Intel Evo 12th Gen Avatar | Denmark",
      "product_model": "Intel Evo 12th Gen Avatar | Denmark",
      "product_mpn": "Intel Evo 12th Gen Avatar | Denmark",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000321,
      "product_name": "Intel Evo 12th Gen Avatar | Brazil",
      "product_model": "Intel Evo 12th Gen Avatar | Brazil",
      "product_mpn": "Intel Evo 12th Gen Avatar | Brazil",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000403,
      "product_name": "Lenovo Yoga 9i 2-in-1 14\" 11th Gen Evo i7",
      "product_model": "Lenovo Yoga 9i 2-in-1 14\" 11th Gen Evo i7",
      "product_mpn": "Lenovo Yoga 9i 2-in-1 14\" 11th Gen Evo i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000404,
      "product_name": "Lenovo Yoga Slim 9i 14\" Evo i7 11th gen",
      "product_model": "Lenovo Yoga Slim 9i 14\" Evo i7 11th gen",
      "product_mpn": "Lenovo Yoga Slim 9i 14\" Evo i7 11th gen",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8400019,
      "product_name": "WD_BLACK P40 Game Drive SSD",
      "product_model": "WD_BLACK P40 Game Drive SSD",
      "product_mpn": "WD_BLACK P40 Game Drive SSD",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400020,
      "product_name": "SanDisk Extreme Portable SSD",
      "product_model": "SanDisk Extreme Portable SSD",
      "product_mpn": "SanDisk Extreme Portable SSD",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 6800073,
      "product_name": "JBL Tune 230NC TWS True Wireless in-Ear Noise Cancelling Headphones",
      "product_model": "JBL Tune 230NC TWS True Wireless in-Ear Noise Cancelling Headphones",
      "product_mpn": "JBL Tune 230NC TWS True Wireless in-Ear Noise Cancelling Headphones",
      "product_brand": "intel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 7100017,
      "product_name": "Razer Seiren X Studio Microphone Black",
      "product_model": "Razer Seiren X Studio Microphone Black",
      "product_mpn": "Razer Seiren X Studio Microphone Black",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 7100018,
      "product_name": "Mars Gaming MMICX Professional Microphone, LED, Mute, USB and 3.5mm Jack, Black",
      "product_model": "Mars Gaming MMICX Professional Microphone, LED, Mute, USB and 3.5mm Jack, Black",
      "product_mpn": "Mars Gaming MMICX Professional Microphone, LED, Mute, USB and 3.5mm Jack, Black",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MICROPHONES"
    },
    {
      "product_arid": 6900106,
      "product_name": "Razer BlackWidow V3 Tenkeyless TKL Mechanical Gaming Keyboard:",
      "product_model": "Razer BlackWidow V3 Tenkeyless TKL Mechanical Gaming Keyboard:",
      "product_mpn": "Razer BlackWidow V3 Tenkeyless TKL Mechanical Gaming Keyboard:",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900107,
      "product_name": "Asus ROG M601 Falchion gaming keyboard",
      "product_model": "Asus ROG M601 Falchion gaming keyboard",
      "product_mpn": "Asus ROG M601 Falchion gaming keyboard",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900108,
      "product_name": "CORSAIR K60 RGB PRO LOW PROFILE Mechanical Gaming Keyboard",
      "product_model": "CORSAIR K60 RGB PRO LOW PROFILE Mechanical Gaming Keyboard",
      "product_mpn": "CORSAIR K60 RGB PRO LOW PROFILE Mechanical Gaming Keyboard",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900109,
      "product_name": "HP Pavilion Wireless Keyboard 600 Black",
      "product_model": "HP Pavilion Wireless Keyboard 600 Black",
      "product_mpn": "HP Pavilion Wireless Keyboard 600 Black",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 8400015,
      "product_name": "WD My Passport Go 500GB Blue Portable Hard Drive",
      "product_model": "WD My Passport Go 500GB Blue Portable Hard Drive",
      "product_mpn": "WD My Passport Go 500GB Blue Portable Hard Drive",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 500500064,
      "product_name": "ASUS ROG Chakram Core Gaming Mouse",
      "product_model": "ASUS ROG Chakram Core Gaming Mouse",
      "product_mpn": "ASUS ROG Chakram Core Gaming Mouse",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500065,
      "product_name": "Razer DeathAdder V2 Pro Wireless Mouse",
      "product_model": "Razer DeathAdder V2 Pro Wireless Mouse",
      "product_mpn": "Razer DeathAdder V2 Pro Wireless Mouse",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500066,
      "product_name": "Trust GXT 130 Wireless Gaming Mouse Ranoo Black",
      "product_model": "Trust GXT 130 Wireless Gaming Mouse Ranoo Black",
      "product_mpn": "Trust GXT 130 Wireless Gaming Mouse Ranoo Black",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500067,
      "product_name": "HP Z3700 White Wireless Mouse",
      "product_model": "HP Z3700 White Wireless Mouse",
      "product_mpn": "HP Z3700 White Wireless Mouse",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6800074,
      "product_name": "Corsair VOID RGB ELITE Wireless USB Type-A Connector Circumaural Premium Gaming Headset",
      "product_model": "Corsair VOID RGB ELITE Wireless USB Type-A Connector Circumaural Premium Gaming Headset",
      "product_mpn": "Corsair VOID RGB ELITE Wireless USB Type-A Connector Circumaural Premium Gaming Headset",
      "product_brand": "intel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800075,
      "product_name": "Sennheiser Epos GSP 301 gaming headset",
      "product_model": "Sennheiser Epos GSP 301 gaming headset",
      "product_mpn": "Sennheiser Epos GSP 301 gaming headset",
      "product_brand": "intel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800076,
      "product_name": "OMEN Mindframe Prime Gaming Headset",
      "product_model": "OMEN Mindframe Prime Gaming Headset",
      "product_mpn": "OMEN Mindframe Prime Gaming Headset",
      "product_brand": "intel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800077,
      "product_name": "Sony MDR-RF895RK Wireless Headphones Black",
      "product_model": "Sony MDR-RF895RK Wireless Headphones Black",
      "product_mpn": "Sony MDR-RF895RK Wireless Headphones Black",
      "product_brand": "intel",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 7000057,
      "product_name": "Asus TUF VG24VQR 24'' Full HD 165Hz curved gaming monitor",
      "product_model": "Asus TUF VG24VQR 24'' Full HD 165Hz curved gaming monitor",
      "product_mpn": "Asus TUF VG24VQR 24'' Full HD 165Hz curved gaming monitor",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000058,
      "product_name": "Samsung LC32G75TQSR 32'' WQHD 240Hz curved gaming monitor",
      "product_model": "Samsung LC32G75TQSR 32'' WQHD 240Hz curved gaming monitor",
      "product_mpn": "Samsung LC32G75TQSR 32'' WQHD 240Hz curved gaming monitor",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000059,
      "product_name": "Lenovo G32qc-10 32'' QHD 144Hz Curved Gaming Monitor",
      "product_model": "Lenovo G32qc-10 32'' QHD 144Hz Curved Gaming Monitor",
      "product_mpn": "Lenovo G32qc-10 32'' QHD 144Hz Curved Gaming Monitor",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000060,
      "product_name": "Monitor gaming LG UltraWide 29WP60G-B 29'' WFull HD 75 Hz",
      "product_model": "Monitor gaming LG UltraWide 29WP60G-B 29'' WFull HD 75 Hz",
      "product_mpn": "Monitor gaming LG UltraWide 29WP60G-B 29'' WFull HD 75 Hz",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000061,
      "product_name": "Monitor Samsung LF27T350F 27'' Full HD",
      "product_model": "Monitor Samsung LF27T350F 27'' Full HD",
      "product_mpn": "Monitor Samsung LF27T350F 27'' Full HD",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000062,
      "product_name": "Monitor HP M24f 24'' Full HD 75Hz",
      "product_model": "Monitor HP M24f 24'' Full HD 75Hz",
      "product_mpn": "Monitor HP M24f 24'' Full HD 75Hz",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 8400018,
      "product_name": "WD_Black P50 SSD - PC Room Element",
      "product_model": "WD_Black P50 SSD - PC Room Element",
      "product_mpn": "WD_Black P50 SSD - PC Room Element",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400017,
      "product_name": "My Passport SSD - PC Room Element",
      "product_model": "My Passport SSD - PC Room Element",
      "product_mpn": "My Passport SSD - PC Room Element",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400016,
      "product_name": "My Passport HDD - PC Room",
      "product_model": "My Passport HDD - PC Room",
      "product_mpn": "My Passport HDD - PC Room",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8000384,
      "product_name": "HP Laptop Victus 16-d0504la 16 inch 11 gen Intel Core i5-11400H ",
      "product_model": "HP Laptop Victus 16-d0504la 16 inch 11 gen Intel Core i5-11400H ",
      "product_mpn": "HP Laptop Victus 16-d0504la 16 inch 11 gen Intel Core i5-11400H ",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000382,
      "product_name": "MSI Gaming Laptop Stealth GS66 12 gen 12UGS-243MX, i7-12700H 15.6\"",
      "product_model": "MSI Gaming Laptop Stealth GS66 12 gen 12UGS-243MX, i7-12700H 15.6\"",
      "product_mpn": "MSI Gaming Laptop Stealth GS66 12 gen 12UGS-243MX, i7-12700H 15.6\"",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000381,
      "product_name": "MSI Gaming Laptop Vector GP66 12UE-429MX 12 gen, i7-12700H 15.6\" ",
      "product_model": "MSI Gaming Laptop Vector GP66 12UE-429MX 12 gen, i7-12700H 15.6\" ",
      "product_mpn": "MSI Gaming Laptop Vector GP66 12UE-429MX 12 gen, i7-12700H 15.6\" ",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000385,
      "product_name": "Asus ROG Flow Z13, Gaming laptop 13 inch Intel Core i7 12th Gen,",
      "product_model": "Asus ROG Flow Z13, Gaming laptop 13 inch Intel Core i7 12th Gen,",
      "product_mpn": "Asus ROG Flow Z13, Gaming laptop 13 inch Intel Core i7 12th Gen,",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000378,
      "product_name": "Lenovo Yoga 7i (16\") 12th Gen Evo Core i5-1240P",
      "product_model": "Lenovo Yoga 7i (16\") 12th Gen Evo Core i5-1240P",
      "product_mpn": "Lenovo Yoga 7i (16\") 12th Gen Evo Core i5-1240P",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000386,
      "product_name": "ASUS VivoBook 14 14” Core i3 F415EA-AS31 11 gen",
      "product_model": "ASUS VivoBook 14 14” Core i3 F415EA-AS31 11 gen",
      "product_mpn": "ASUS VivoBook 14 14” Core i3 F415EA-AS31 11 gen",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000358,
      "product_name": "MSI Stealth 15M 15.6\" Gaming, 11th Gen Core i7",
      "product_model": "MSI Stealth 15M 15.6\" Gaming, 11th Gen Core i7",
      "product_mpn": "MSI Stealth 15M 15.6\" Gaming, 12th Gen Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000357,
      "product_name": "Alienware M15 R6 Gaming Laptop, 15.6 inch Core i7",
      "product_model": "Alienware M15 R6 Gaming Laptop, 15.6 inch Core i7",
      "product_mpn": "Alienware M15 R6 Gaming Laptop, 15.6 inch Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000356,
      "product_name": "Acer Predator Helios 300 15.6 inch PH315-54-760S Gaming core i7",
      "product_model": "Acer Predator Helios 300 15.6 inch PH315-54-760S Gaming core i7",
      "product_mpn": "Acer Predator Helios 300 15.6 inch PH315-54-760S Gaming core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000355,
      "product_name": "Acer Nitro 5 AN517-54-79L1 Gaming Laptop 11th Gen Core i7-11800H (17.3\")",
      "product_model": "Acer Nitro 5 AN517-54-79L1 Gaming Laptop 11th Gen Core i7-11800H (17.3\")",
      "product_mpn": "Acer Nitro 5 AN517-54-79L1 Gaming Laptop 12th Gen Core i7-11800H (17.3\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000354,
      "product_name": "ASUS TUF Dash 15 Ultra Slim Gaming Laptop, 15.6”  Core i7",
      "product_model": "ASUS TUF Dash 15 Ultra Slim Gaming Laptop, 15.6”  Core i7",
      "product_mpn": "ASUS TUF Dash 15 Ultra Slim Gaming Laptop, 15.6”  Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000353,
      "product_name": "Dell Inspiron 13 5310, QHD Core - i7 (13.3\")",
      "product_model": "Dell Inspiron 13 5310, QHD Core - i7 (13.3\")",
      "product_mpn": "Dell Inspiron 13 5310, QHD Core - i7 (13.3\")",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000379,
      "product_name": "HP Pavilion 15.6\" 11th Gen Core i7",
      "product_model": "HP Pavilion 15.6\" 11th Gen Core i7",
      "product_mpn": "HP Pavilion 15.6\" 11th Gen Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000359,
      "product_name": "HP Pavilion x360 Convertible 11th Gen (14\") i5",
      "product_model": "HP Pavilion x360 Convertible 11th Gen (14\") i5",
      "product_mpn": "HP Pavilion x360 Convertible 11th Gen (14\") i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000352,
      "product_name": "HP 17.3-inch Laptop, 11th Gen Intel Core i5",
      "product_model": "HP 17.3-inch Laptop, 11th Gen Intel Core i5",
      "product_mpn": "HP 17.3-inch Laptop, 11th Gen Intel Core i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000351,
      "product_name": "HP 15.6-inch Laptop, 11 Gen Core i5",
      "product_model": "HP 15.6-inch Laptop, 11 Gen Core i5",
      "product_mpn": "HP 15.6-inch Laptop, 11 Gen Core i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000350,
      "product_name": "ASUS VivoBook Flip 14 Thin and Light 11th Gen (14\") i3",
      "product_model": "ASUS VivoBook Flip 14 Thin and Light 11th Gen (14\") i3",
      "product_mpn": "ASUS VivoBook Flip 14 Thin and Light 11th Gen (14\") i3",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000346,
      "product_name": "Acer Aspire 5 i3 11 gen 15.6\"",
      "product_model": "Acer Aspire 5 i3 11 gen 15.6\"",
      "product_mpn": "Acer Aspire 5 i3 11 gen 15.6\"",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000375,
      "product_name": "Intel Evo 11th Gen Avatar | Korea",
      "product_model": "Intel Evo 11th Gen Avatar | Korea",
      "product_mpn": "Intel Evo 11th Gen Avatar | Korea",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000374,
      "product_name": "Intel Evo 11th Gen Avatar | Indonesia",
      "product_model": "Intel Evo 11th Gen Avatar | Indonesia",
      "product_mpn": "Intel Evo 11th Gen Avatar | Indonesia",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000373,
      "product_name": "Intel Evo 11th Gen Avatar | France",
      "product_model": "Intel Evo 11th Gen Avatar | France",
      "product_mpn": "Intel Evo 11th Gen Avatar | France",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000372,
      "product_name": "Intel Evo 11th Gen Avatar | Canada (French)",
      "product_model": "Intel Evo 11th Gen Avatar | Canada (French)",
      "product_mpn": "Intel Evo 11th Gen Avatar | Canada (French)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000371,
      "product_name": "Intel Evo 11th Gen Avatar | Malaysia",
      "product_model": "Intel Evo 11th Gen Avatar | Malaysia",
      "product_mpn": "Intel Evo 11th Gen Avatar | Malaysia",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000370,
      "product_name": "Intel Evo 11th Gen Avatar | Turkey",
      "product_model": "Intel Evo 11th Gen Avatar | Turkey",
      "product_mpn": "Intel Evo 11th Gen Avatar | Turkey",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000369,
      "product_name": "Intel Evo 11th Gen Avatar | Sweden",
      "product_model": "Intel Evo 11th Gen Avatar | Sweden",
      "product_mpn": "Intel Evo 11th Gen Avatar | Sweden",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000368,
      "product_name": "Intel Evo 11th Gen Avatar | Spain",
      "product_model": "Intel Evo 11th Gen Avatar | Spain",
      "product_mpn": "Intel Evo 11th Gen Avatar | Spain",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000367,
      "product_name": "Intel Evo 11th Gen Avatar | Norway",
      "product_model": "Intel Evo 11th Gen Avatar | Norway",
      "product_mpn": "Intel Evo 11th Gen Avatar | Norway",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000366,
      "product_name": "Intel Evo 11th Gen Avatar | Hungary",
      "product_model": "Intel Evo 11th Gen Avatar | Hungary",
      "product_mpn": "Intel Evo 11th Gen Avatar | Hungary",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000365,
      "product_name": "Intel Evo 11th Gen Avatar | Finland",
      "product_model": "Intel Evo 11th Gen Avatar | Finland",
      "product_mpn": "Intel Evo 11th Gen Avatar | Finland",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000364,
      "product_name": "Intel Evo 11th Gen Avatar | Denmark",
      "product_model": "Intel Evo 11th Gen Avatar | Denmark",
      "product_mpn": "Intel Evo 11th Gen Avatar | Denmark",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000363,
      "product_name": "Intel Evo 11th Gen Avatar | Brazil (Portuguese)",
      "product_model": "Intel Evo 11th Gen Avatar | Brazil (Portuguese)",
      "product_mpn": "Intel Evo 11th Gen Avatar | Brazil (Portuguese)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000362,
      "product_name": "Intel Evo 11th Gen Avatar | Germany",
      "product_model": "Intel Evo 11th Gen Avatar | Germany",
      "product_mpn": "Intel Evo 11th Gen Avatar | Germany",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000361,
      "product_name": "Intel Evo 11th Gen Avatar | English (US)",
      "product_model": "Intel Evo 11th Gen Avatar | English (US)",
      "product_mpn": "Intel Evo 11th Gen Avatar | English (US)",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": null,
      "product_name": "",
      "product_model": "",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "",
      "product_subcategory": ""
    },
    {
      "product_arid": null,
      "product_name": "",
      "product_model": "",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "",
      "product_subcategory": ""
    },
    {
      "product_arid": 1001000400004,
      "product_name": "Miami Rattan Garden Sun Lounger in Premium Truffle Brown and Champagne",
      "product_model": "Miami Rattan Garden Sun Lounger in Premium Truffle Brown and Champagne",
      "product_mpn": "Miami Rattan Garden Sun Lounger in Premium Truffle Brown and Champagne",
      "product_brand": "RattanDirect",
      "product_category": "GARDEN OUTDOOR",
      "product_subcategory": "SUNLOUNGERS"
    },
    {
      "product_arid": 7000054,
      "product_name": "XG320U",
      "product_model": "XG320U",
      "product_mpn": "XG320U",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000051,
      "product_name": "VP2776",
      "product_model": "VP2776",
      "product_mpn": "VP2776",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000050,
      "product_name": "VP3256-4K",
      "product_model": "VP3256-4K",
      "product_mpn": "VP3256-4K",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000049,
      "product_name": "VX2718-2KPC-MHD",
      "product_model": "VX2718-2KPC-MHD",
      "product_mpn": "VX2718-2KPC-MHD",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000048,
      "product_name": "VX2718-P-MHD",
      "product_model": "VX2718-P-MHD",
      "product_mpn": "VX2718-P-MHD",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000055,
      "product_name": "XG321UG",
      "product_model": "XG321UG",
      "product_mpn": "XG321UG",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000047,
      "product_name": "VA1655",
      "product_model": "VA1655",
      "product_mpn": "VA1655",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000046,
      "product_name": "VX1755",
      "product_model": "VX1755",
      "product_mpn": "VX1755",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000053,
      "product_name": "VG1655",
      "product_model": "VG1655",
      "product_mpn": "VG1655",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 7000052,
      "product_name": "TD1655",
      "product_model": "TD1655",
      "product_mpn": "TD1655",
      "product_brand": "Viewsonic",
      "product_category": "COMPUTING",
      "product_subcategory": "MONITORS"
    },
    {
      "product_arid": 600200003,
      "product_name": "Withings Sleep Tracking Mat",
      "product_model": "Withings Sleep Tracking Mat",
      "product_mpn": "Withings Sleep Tracking Mat",
      "product_brand": "Withings",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "WELLBEING"
    },
    {
      "product_arid": 600200005,
      "product_name": "Withings BPM Connect",
      "product_model": "Withings BPM Connect",
      "product_mpn": "Withings BPM Connect",
      "product_brand": "Withings",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "WELLBEING"
    },
    {
      "product_arid": 600200004,
      "product_name": "Withings Body Cardio Smart Scale - Black",
      "product_model": "Withings Body Cardio Smart Scale - Black",
      "product_mpn": "Withings Body Cardio Smart Scale - Black",
      "product_brand": "Withings",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "WELLBEING"
    },
    {
      "product_arid": 600200002,
      "product_name": "Withings Body+ Smart Scale - Black",
      "product_model": "Withings Body+ Smart Scale - Black",
      "product_mpn": "Withings Body+ Smart Scale - Black",
      "product_brand": "Withings",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "WELLBEING"
    },
    {
      "product_arid": 2200100018,
      "product_name": "Withings ScanWatch Horizon 43mm - Green",
      "product_model": "Withings ScanWatch Horizon 43mm - Green",
      "product_mpn": "Withings ScanWatch Horizon 43mm - Green",
      "product_brand": "Withings",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100019,
      "product_name": "Withings ScanWatch Horizon 43mm - Blue",
      "product_model": "Withings ScanWatch Horizon 43mm - Blue",
      "product_mpn": "Withings ScanWatch Horizon 43mm - Blue",
      "product_brand": "Withings",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100016,
      "product_name": "Withings ScanWatch 38mm in Rose Gold - White",
      "product_model": "Withings ScanWatch 38mm in Rose Gold - White",
      "product_mpn": "Withings ScanWatch 38mm in Rose Gold - White",
      "product_brand": "Withings",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100017,
      "product_name": "Withings ScanWatch 38mm in Rose Gold - Blue",
      "product_model": "Withings ScanWatch 38mm in Rose Gold - Blue",
      "product_mpn": "Withings ScanWatch 38mm in Rose Gold - Blue",
      "product_brand": "Withings",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100014,
      "product_name": "Withings ScanWatch 42mm - Black",
      "product_model": "Withings ScanWatch 42mm - Black",
      "product_mpn": "Withings ScanWatch 42mm - Black",
      "product_brand": "Withings",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 2200100015,
      "product_name": "Withings ScanWatch 38mm - Black",
      "product_model": "Withings ScanWatch 38mm - Black",
      "product_mpn": "Withings ScanWatch 38mm - Black",
      "product_brand": "Withings",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 300200018,
      "product_name": "KitchenAid K400 Variable Speed Blender",
      "product_model": "KitchenAid K400 Variable Speed Blender",
      "product_mpn": "KitchenAid K400 Variable Speed Blender",
      "product_brand": "KitchenAid",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 900047,
      "product_name": "Nespresso Vertuo Next Premium Coffee by Breville",
      "product_model": "Nespresso Vertuo Next Premium Coffee by Breville",
      "product_mpn": "Nespresso Vertuo Next Premium Coffee by Breville",
      "product_brand": "Nespresso",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 6800070,
      "product_name": "Arena 9",
      "product_model": "Arena 9",
      "product_mpn": "Arena 9",
      "product_brand": "Steel Series",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 6800071,
      "product_name": "Arena 7",
      "product_model": "Arena 7",
      "product_mpn": "Arena 7",
      "product_brand": "Steel Series",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 6800072,
      "product_name": "Arena 3",
      "product_model": "Arena 3",
      "product_mpn": "Arena 3",
      "product_brand": "Steel Series",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 12400032,
      "product_name": "QcK Edge - Large",
      "product_model": "QcK Edge - Large",
      "product_mpn": "QcK Edge - Large",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400033,
      "product_name": "QcK Prism Cloth - XL ",
      "product_model": "QcK Prism Cloth - XL ",
      "product_mpn": "QcK Prism Cloth - XL ",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 12400034,
      "product_name": "Qck Prism Cloth Medium",
      "product_model": "Qck Prism Cloth Medium",
      "product_mpn": "Qck Prism Cloth Medium",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "COMPUTER ACCESSORIES"
    },
    {
      "product_arid": 500500061,
      "product_name": "Aerox 3 Wireless Snow",
      "product_model": "Aerox 3 Wireless Snow",
      "product_mpn": "Aerox 3 Wireless Snow",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500062,
      "product_name": "Aerox 9 Wireless",
      "product_model": "Aerox 9 Wireless",
      "product_mpn": "Aerox 9 Wireless",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500063,
      "product_name": "Aerox 5 Wireless ",
      "product_model": "Aerox 5 Wireless ",
      "product_mpn": "Aerox 5 Wireless ",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900105,
      "product_name": "Apex Pro Mini Wireless",
      "product_model": "Apex Pro Mini Wireless",
      "product_mpn": "Apex Pro Mini Wireless",
      "product_brand": "Steel Series",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 75000121108,
      "product_name": "HIS8655U",
      "product_model": "HIS8655U",
      "product_mpn": "HIS8655U",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 75000121109,
      "product_name": "HIS8055U",
      "product_model": "HIS8055U",
      "product_mpn": "HIS8055U",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 75000121110,
      "product_name": "HGS8655UC",
      "product_model": "HGS8655UC",
      "product_mpn": "HGS8655UC",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 75000121111,
      "product_name": "HGS8645UC",
      "product_model": "HGS8645UC",
      "product_mpn": "HGS8645UC",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 75000121112,
      "product_name": "HGS8055UC",
      "product_model": "HGS8055UC",
      "product_mpn": "HGS8055UC",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 75000121113,
      "product_name": "HGS8045UC",
      "product_model": "HGS8045UC",
      "product_mpn": "HGS8045UC",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "COOKERS"
    },
    {
      "product_arid": 9400010,
      "product_name": "HCB50651UC",
      "product_model": "HCB50651UC",
      "product_mpn": "HCB50651UC",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "HOODS"
    },
    {
      "product_arid": 13500135,
      "product_name": "B36FD50SNB",
      "product_model": "B36FD50SNB",
      "product_mpn": "B36FD50SNB",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500136,
      "product_name": "B36CL81ENG",
      "product_model": "B36CL81ENG",
      "product_mpn": "B36CL81ENG",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500138,
      "product_name": "B36CD50SNS",
      "product_model": "B36CD50SNS",
      "product_mpn": "B36CD50SNS",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500137,
      "product_name": "B36CD50SNB",
      "product_model": "B36CD50SNB",
      "product_mpn": "B36CD50SNB",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 13500139,
      "product_name": "B36FD50SNS",
      "product_model": "B36FD50SNS",
      "product_mpn": "B36FD50SNS",
      "product_brand": "BSH",
      "product_category": "HOME APPLIANCES",
      "product_subcategory": "FRIDGE- FREEZERS"
    },
    {
      "product_arid": 8000419,
      "product_name": "ASUS - ROG Zephyrus 16\" 12 gen Gaming Laptop-Intel Core i7",
      "product_model": "ASUS - ROG Zephyrus 16\" 12 gen Gaming Laptop-Intel Core i7",
      "product_mpn": "ASUS - ROG Zephyrus 16\" 12 gen Gaming Laptop-Intel Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000420,
      "product_name": "MSI - Stealth GS77 17.3\" Gaming Laptop - Intel Core i7 12 gen",
      "product_model": "MSI - Stealth GS77 17.3\" Gaming Laptop - Intel Core i7 12 gen",
      "product_mpn": "MSI - Stealth GS77 17.3\" Gaming Laptop - Intel Core i7 12 gen",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000421,
      "product_name": "Lenovo Legion Slim 7i 16\" Gaming Laptop - Core i7 12 gen",
      "product_model": "Lenovo Legion Slim 7i 16\" Gaming Laptop - Core i7 12 gen",
      "product_mpn": "Lenovo Legion Slim 7i 16\" Gaming Laptop - Core i7 12 gen",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000422,
      "product_name": "Asus ROG Flow Z13, Gaming laptop 13 inch Intel Core i9 12th Gen",
      "product_model": "Asus ROG Flow Z13, Gaming laptop 13 inch Intel Core i9 12th Gen",
      "product_mpn": "Asus ROG Flow Z13, Gaming laptop 13 inch Intel Core i9 12th Gen",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000423,
      "product_name": "Acer - Predator Triton 300 SE-14” 12 gen Gaming Laptop–Intel Core i7",
      "product_model": "Acer - Predator Triton 300 SE-14” 12 gen Gaming Laptop–Intel Core i7",
      "product_mpn": "Acer - Predator Triton 300 SE-14” 12 gen Gaming Laptop–Intel Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000424,
      "product_name": "ASUS - ROG Zephyrus 16\" 12 gen Gaming Laptop-Intel Core i9",
      "product_model": "ASUS - ROG Zephyrus 16\" 12 gen Gaming Laptop-Intel Core i9",
      "product_mpn": "ASUS - ROG Zephyrus 16\" 12 gen Gaming Laptop-Intel Core i9",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000410,
      "product_name": "Samsung galaxy book pro 360 13.3 inch 11 gen evo core i5",
      "product_model": "Samsung galaxy book pro 360 13.3 inch 11 gen evo core i5",
      "product_mpn": "Samsung galaxy book pro 360 13.3 inch 11 gen evo core i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000411,
      "product_name": "LG Gram 2-in-1 14”  12th gen Intel Evo Platform Core i7",
      "product_model": "LG Gram 2-in-1 14”  12th gen Intel Evo Platform Core i7",
      "product_mpn": "LG Gram 2-in-1 14”  12th gen Intel Evo Platform Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000412,
      "product_name": "LG - gram 16” Ultra lightweight Laptop 12th gen evo i7",
      "product_model": "LG - gram 16” Ultra lightweight Laptop 12th gen evo i7",
      "product_mpn": "LG - gram 16” Ultra lightweight Laptop 12th gen evo i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000413,
      "product_name": "LG - gram 2-in-1 16” Laptop – 12 gen Intel Evo Platform Core i7 –",
      "product_model": "LG - gram 2-in-1 16” Laptop – 12 gen Intel Evo Platform Core i7 –",
      "product_mpn": "LG - gram 2-in-1 16” Laptop – 12 gen Intel Evo Platform Core i7 –",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000414,
      "product_name": "Lenovo - Yoga 9i 14 inch 2-in-1 Laptop - 12 gen Intel Evo Platform - Core i7",
      "product_model": "Lenovo - Yoga 9i 14 inch 2-in-1 Laptop - 12 gen Intel Evo Platform - Core i7",
      "product_mpn": "Lenovo - Yoga 9i 14 inch 2-in-1 Laptop - 12 gen Intel Evo Platform - Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000415,
      "product_name": "Dell - Inspiron 2-in-1 14” FHD+ Touch Laptop – 12th Gen Intel Core i5",
      "product_model": "Dell - Inspiron 2-in-1 14” FHD+ Touch Laptop – 12th Gen Intel Core i5",
      "product_mpn": "Dell - Inspiron 2-in-1 14” FHD+ Touch Laptop – 12th Gen Intel Core i5",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000416,
      "product_name": "Dell - Inspiron 2-in-1 14” FHD+ Touch Laptop – 12th Gen Intel Core i7",
      "product_model": "Dell - Inspiron 2-in-1 14” FHD+ Touch Laptop – 12th Gen Intel Core i7",
      "product_mpn": "Dell - Inspiron 2-in-1 14” FHD+ Touch Laptop – 12th Gen Intel Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000406,
      "product_name": "Dell XPS 13 Notebook 9310 13.4\" 11th Gen Core i7",
      "product_model": "Dell XPS 13 Notebook 9310 13.4\" 11th Gen Core i7",
      "product_mpn": "Dell XPS 13 Notebook 9310 13.4\" 11th Gen Core i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 8000405,
      "product_name": "Acer Aspire Vero Emerald 14\" 12 gen Evo i7",
      "product_model": "Acer Aspire Vero Emerald 14\" 12 gen Evo i7",
      "product_mpn": "Acer Aspire Vero Emerald 14\" 12 gen Evo i7",
      "product_brand": "intel",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 2200100020,
      "product_name": "Oversized Brecken Gold-Tone Watch",
      "product_model": "Oversized Brecken Gold-Tone Watch",
      "product_mpn": "Oversized Brecken Gold-Tone Watch",
      "product_brand": "(blank)",
      "product_category": "WEARABLE",
      "product_subcategory": "WATCHES"
    },
    {
      "product_arid": 300400033,
      "product_name": "NN-SE985S",
      "product_model": "NN-SE985S",
      "product_mpn": "NN-SE985S",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400034,
      "product_name": "NN-SE785S",
      "product_model": "NN-SE785S",
      "product_mpn": "NN-SE785S",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400035,
      "product_name": "NN-SN77HS",
      "product_model": "NN-SN77HS",
      "product_mpn": "NN-SN77HS",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400036,
      "product_name": "NN-SN68KS",
      "product_model": "NN-SN68KS",
      "product_mpn": "NN-SN68KS",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400032,
      "product_name": "NN-SV79MS",
      "product_model": "NN-SV79MS",
      "product_mpn": "NN-SV79MS",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400031,
      "product_name": "NN-SD69LS",
      "product_model": "NN-SD69LS",
      "product_mpn": "NN-SD69LS",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400030,
      "product_name": "NN-SD372SR",
      "product_model": "NN-SD372SR",
      "product_mpn": "NN-SD372SR",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400029,
      "product_name": "NN-GN68KS",
      "product_model": "NN-GN68KS",
      "product_mpn": "NN-GN68KS",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 300400028,
      "product_name": "NN-CD87KS",
      "product_model": "NN-CD87KS",
      "product_mpn": "NN-CD87KS",
      "product_brand": "Panasonic",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "MICROWAVES"
    },
    {
      "product_arid": 500500084,
      "product_name": "G735 White",
      "product_model": "G735 White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500069,
      "product_name": "M650-BLACK",
      "product_model": "M650-BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500070,
      "product_name": "M650 OFF-WHITE",
      "product_model": "M650 OFF-WHITE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500071,
      "product_name": "M650-ROSE",
      "product_model": "M650-ROSE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500072,
      "product_name": "M650 L BLACK",
      "product_model": "M650 L BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500073,
      "product_name": "M650 L CLASSIC BLUE",
      "product_model": "M650 L CLASSIC BLUE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500074,
      "product_name": "M650 L CLASSIC RED",
      "product_model": "M650 L CLASSIC RED",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500075,
      "product_name": "M650 L OFF-WHITE",
      "product_model": "M650 L OFF-WHITE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500076,
      "product_name": "M650 L Rose - Cordless Mice",
      "product_model": "M650 L Rose - Cordless Mice",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500077,
      "product_name": "M650 L LEFT OFF-WHITE",
      "product_model": "M650 L LEFT OFF-WHITE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 1000017,
      "product_name": "C922 Black",
      "product_model": "C922 Black",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 500500078,
      "product_name": "G303 Shroud",
      "product_model": "G303 Shroud",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500079,
      "product_name": "G305 Lol - Gaming Mice",
      "product_model": "G305 Lol - Gaming Mice",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500080,
      "product_name": "G305 Mint",
      "product_model": "G305 Mint",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500081,
      "product_name": "G705 White",
      "product_model": "G705 White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500082,
      "product_name": "G713 WIRED White",
      "product_model": "G713 WIRED White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500085,
      "product_name": "M650 Graphite",
      "product_model": "M650 Graphite",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900111,
      "product_name": "MX KEYS MINI Graphite ",
      "product_model": "MX KEYS MINI Graphite ",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900112,
      "product_name": "MX MASTER 3S Pale Grey",
      "product_model": "MX MASTER 3S Pale Grey",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500083,
      "product_name": "G715 WIRELESS White",
      "product_model": "G715 WIRELESS White",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 3100200008,
      "product_name": "Guinness Six Nations Jacket Standee",
      "product_model": "Guinness Six Nations Jacket Standee",
      "product_mpn": "",
      "product_brand": "Diageo",
      "product_category": "GROCERY",
      "product_subcategory": "FSDU"
    },
    {
      "product_arid": 3100200007,
      "product_name": "Guinness Six Nations Slim Case Tidy",
      "product_model": "Guinness Six Nations Slim Case Tidy",
      "product_mpn": "",
      "product_brand": "Diageo",
      "product_category": "GROCERY",
      "product_subcategory": "FSDU"
    },
    {
      "product_arid": 3100200006,
      "product_name": "Guinness Six Nations Standard Case Tidy",
      "product_model": "Guinness Six Nations Standard Case Tidy",
      "product_mpn": "",
      "product_brand": "Diageo",
      "product_category": "GROCERY",
      "product_subcategory": "FSDU"
    },
    {
      "product_arid": 3100200005,
      "product_name": "Guinness Six Nations Half Pallet Display",
      "product_model": "Guinness Six Nations Half Pallet Display",
      "product_mpn": "",
      "product_brand": "Diageo",
      "product_category": "GROCERY",
      "product_subcategory": "FSDU"
    },
    {
      "product_arid": 3100200003,
      "product_name": "Guinness Six Nations Double Pallet Display",
      "product_model": "Guinness Six Nations Double Pallet Display",
      "product_mpn": "Guinness Six Nations Double Pallet Display",
      "product_brand": "Diageo",
      "product_category": "GROCERY",
      "product_subcategory": "FSDU"
    },
    {
      "product_arid": 300200019,
      "product_name": "Ninja CI090UK",
      "product_model": "Ninja CI090UK",
      "product_mpn": "Ninja CI090UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 12600112,
      "product_name": "Shark HZ3000UKT",
      "product_model": "Shark HZ3000UKT",
      "product_mpn": "Shark HZ3000UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600098,
      "product_name": "Shark AZ913UK",
      "product_model": "Shark AZ913UK",
      "product_mpn": "Shark AZ913UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600102,
      "product_name": "Shark NZ690UK",
      "product_model": "Shark NZ690UK",
      "product_mpn": "Shark NZ690UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600104,
      "product_name": "Shark IZ252UK",
      "product_model": "Shark IZ252UK",
      "product_mpn": "Shark IZ252UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600106,
      "product_name": "Shark IZ202UK",
      "product_model": "Shark IZ202UK",
      "product_mpn": "Shark IZ202UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600109,
      "product_name": "Shark IZ400UK",
      "product_model": "Shark IZ400UK",
      "product_mpn": "Shark IZ400UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600110,
      "product_name": "Shark NZ860UKT",
      "product_model": "Shark NZ860UKT",
      "product_mpn": "Shark NZ860UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600111,
      "product_name": "Shark NZ860UK",
      "product_model": "Shark NZ860UK",
      "product_mpn": "Shark NZ860UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 300200020,
      "product_name": "Ninja CI100UK",
      "product_model": "Ninja CI100UK",
      "product_mpn": "Ninja CI100UK",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "BLENDERS"
    },
    {
      "product_arid": 12600099,
      "product_name": "Shark NZ710UKT",
      "product_model": "Shark NZ710UKT",
      "product_mpn": "Shark NZ710UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600101,
      "product_name": "Shark NZ690UKT",
      "product_model": "Shark NZ690UKT",
      "product_mpn": "Shark NZ690UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600103,
      "product_name": "Shark IZ252UKT",
      "product_model": "Shark IZ252UKT",
      "product_mpn": "Shark IZ252UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600105,
      "product_name": "Shark IZ202UKT",
      "product_model": "Shark IZ202UKT",
      "product_mpn": "Shark IZ202UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600107,
      "product_name": "Shark IZ420UKT",
      "product_model": "Shark IZ420UKT",
      "product_mpn": "Shark IZ420UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 12600108,
      "product_name": "Shark IZ400UKT",
      "product_model": "Shark IZ400UKT",
      "product_mpn": "Shark IZ400UKT",
      "product_brand": "Shark",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "VACUUMING & FLOORCARES"
    },
    {
      "product_arid": 8400021,
      "product_name": "G-DRIVE PRO",
      "product_model": "G-DRIVE PRO",
      "product_mpn": "G-DRIVE PRO",
      "product_brand": "Western Digital",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400022,
      "product_name": "G-DRIVE SSD",
      "product_model": "G-DRIVE SSD",
      "product_mpn": "G-DRIVE SSD",
      "product_brand": "Western Digital",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400023,
      "product_name": "G-DRIVE",
      "product_model": "G-DRIVE",
      "product_mpn": "G-DRIVE",
      "product_brand": "Western Digital",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400024,
      "product_name": "ArmorATD 1TB",
      "product_model": "ArmorATD 1TB",
      "product_mpn": "ArmorATD 1TB",
      "product_brand": "Western Digital",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8400025,
      "product_name": "ArmorATD 4TB",
      "product_model": "ArmorATD 4TB",
      "product_mpn": "ArmorATD 4TB",
      "product_brand": "Western Digital",
      "product_category": "COMPUTING",
      "product_subcategory": "DATA STORAGES"
    },
    {
      "product_arid": 8000408,
      "product_name": "WFlow - 11th Oct",
      "product_model": "WFlow - 11th Oct",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 900200000,
      "product_name": "Ninja CW102GYUK PossiblePan",
      "product_model": "Ninja CW102GYUK PossiblePan",
      "product_mpn": "Ninja CW102GYUK PossiblePan",
      "product_brand": "Shark",
      "product_category": "KITCHEN & BATHROOM",
      "product_subcategory": "COOKWARE"
    },
    {
      "product_arid": 8000409,
      "product_name": "Model WF - 21 Oct",
      "product_model": "Model WF - 21 Oct",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "COMPUTING",
      "product_subcategory": "LAPTOPS"
    },
    {
      "product_arid": 3100200004,
      "product_name": "Guinness Six Nations Full Pallet Display",
      "product_model": "Guinness Six Nations Full Pallet Display",
      "product_mpn": "",
      "product_brand": "Diageo",
      "product_category": "GROCERY",
      "product_subcategory": "FSDU"
    },
    {
      "product_arid": 1000018,
      "product_name": "Model flow - Nov 2nd",
      "product_model": "Model flow - Nov 2nd",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 200087,
      "product_name": "Test model_4th Nov",
      "product_model": "Test model_4th Nov",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "SMART TECH & PHONES",
      "product_subcategory": "PHONES"
    },
    {
      "product_arid": 1001000400005,
      "product_name": "Product list",
      "product_model": "Product list",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "GARDEN OUTDOOR",
      "product_subcategory": "GARDEN TABLE"
    },
    {
      "product_arid": 900048,
      "product_name": "Coffee NPD Test",
      "product_model": "Coffee NPD Test",
      "product_mpn": "Coffee NPD Test",
      "product_brand": "Ninja",
      "product_category": "SMALL APPLIANCES",
      "product_subcategory": "COFFEE MACHINES"
    },
    {
      "product_arid": 1000019,
      "product_name": "Partner View 01",
      "product_model": "Partner View 01",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "CAMERAS & CAMCORDERS",
      "product_subcategory": "DIGITAL CAMERAS"
    },
    {
      "product_arid": 700500022,
      "product_name": "Partner as Brand client - 01",
      "product_model": "Partner as Brand client - 01",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 500500108,
      "product_name": "M350-LAVENDER LEMONADE",
      "product_model": "M350-LAVENDER LEMONADE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500107,
      "product_name": "G502 X PLUS-WHITE/PREMIUM",
      "product_model": "G502 X PLUS-WHITE/PREMIUM",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500106,
      "product_name": "G502 X PLUS-BLACK/PREMIUM",
      "product_model": "G502 X PLUS-BLACK/PREMIUM",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500105,
      "product_name": "G502 X LIGHTSPEED-WHITE/CORE",
      "product_model": "G502 X LIGHTSPEED-WHITE/CORE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500104,
      "product_name": "G502 X LIGHTSPEED-BLACK/CORE",
      "product_model": "G502 X LIGHTSPEED-BLACK/CORE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500103,
      "product_name": "G502 X-WHITE",
      "product_model": "G502 X-WHITE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500102,
      "product_name": "G502 X-BLACK",
      "product_model": "G502 X-BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6800087,
      "product_name": "ZONE VIBE 100-GRAPHITE M/N:A00167",
      "product_model": "ZONE VIBE 100-GRAPHITE M/N:A00167",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800086,
      "product_name": "WONDERBOOM 3-PERFORMANCE BLUE",
      "product_model": "WONDERBOOM 3-PERFORMANCE BLUE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 6800085,
      "product_name": "WONDERBOOM 3-JOYOUS BRIGHTS",
      "product_model": "WONDERBOOM 3-JOYOUS BRIGHTS",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 6800084,
      "product_name": "WONDERBOOM 3-HYPER PINK",
      "product_model": "WONDERBOOM 3-HYPER PINK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 6800083,
      "product_name": "WONDERBOOM 3-ACTIVE BLACK",
      "product_model": "WONDERBOOM 3-ACTIVE BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 500500101,
      "product_name": "PRO X SUPERLIGHT-RED",
      "product_model": "PRO X SUPERLIGHT-RED",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500100,
      "product_name": "POP Mouse with emoji-MIST_SAND",
      "product_model": "POP Mouse with emoji-MIST_SAND",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900118,
      "product_name": "POP KEYS-MIST_SAND",
      "product_model": "POP KEYS-MIST_SAND",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 500500099,
      "product_name": "MX MECHANICAL MINI FOR MAC-SPACE GREY",
      "product_model": "MX MECHANICAL MINI FOR MAC-SPACE GREY",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500098,
      "product_name": "MX Master 3S For Mac-PALE GREY",
      "product_model": "MX Master 3S For Mac-PALE GREY",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500097,
      "product_name": "MX Master 3S -GRAPHITE",
      "product_model": "MX Master 3S -GRAPHITE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500096,
      "product_name": "MX Master 3S-BLACK",
      "product_model": "MX Master 3S-BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 500500095,
      "product_name": "LOGICOOL PEBBLE M350-BLUEBERRY",
      "product_model": "LOGICOOL PEBBLE M350-BLUEBERRY",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "MOUSES"
    },
    {
      "product_arid": 6900117,
      "product_name": "K380 MULTI-DEVICE FOR MAC-BLUEBERRY",
      "product_model": "K380 MULTI-DEVICE FOR MAC-BLUEBERRY",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900116,
      "product_name": "K380 MULTI-DEVICE-SAND",
      "product_model": "K380 MULTI-DEVICE-SAND",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6900115,
      "product_name": "K380 MULTI-DEVICE-LAVENDER LEMONADE",
      "product_model": "K380 MULTI-DEVICE-LAVENDER LEMONADE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "COMPUTING",
      "product_subcategory": "KEYBOARD & MOUSES"
    },
    {
      "product_arid": 6800082,
      "product_name": "FITS-WHITE",
      "product_model": "FITS-WHITE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 6800081,
      "product_name": "FITS-BLACK",
      "product_model": "FITS-BLACK",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "SOUND & VISION",
      "product_subcategory": "HEAD- PHONES"
    },
    {
      "product_arid": 11700016,
      "product_name": "CLOUD GAMING HANDHELD-WHITE",
      "product_model": "CLOUD GAMING HANDHELD-WHITE",
      "product_mpn": "",
      "product_brand": "Logitech",
      "product_category": "GAMING",
      "product_subcategory": "CONSOLES & GAMES"
    },
    {
      "product_arid": 700500023,
      "product_name": "Test Model - 01",
      "product_model": "Test Model - 01",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "ALL ROOMS",
      "product_subcategory": "SOFAS & CHAIRS"
    },
    {
      "product_arid": 600059,
      "product_name": "JBL Boombox 2",
      "product_model": "JBL Boombox 2",
      "product_mpn": "",
      "product_brand": "JBL",
      "product_category": "SOUND & VISION",
      "product_subcategory": "SOUND SYSTEMS"
    },
    {
      "product_arid": 1200100000,
      "product_name": "WF-13th Dec-Test",
      "product_model": "WF-13th Dec-Test",
      "product_mpn": "",
      "product_brand": "(blank)",
      "product_category": "TOYS & GAMES",
      "product_subcategory": "CONSTRUCTION & CARS"
    }
  ]
  */