/*

  s i d e  b y  s i d e
  Side By Side

  :description:
  A page to view the old/new player side by side.
  
*/

//
//  :react & redux:
import React from 'react';
import { useState } from 'react';

//
//  :code:
import './SideBySide.css';
import Page from '../../components/layout/page/Page';
import { talk } from '@eyekandy/app-core';
import { useParams } from 'react-router-dom';
import EyekandyModelViewer from '../../components/models/eky-model-viewer/EyekandyModelViewer';
import { EYEKANDY_MODELS } from '../../eyekandy-models';
import { RedirectIfNotLoggedIn } from '../../components/auth/RedirectIfNotLoggedIn';

EYEKANDY_MODELS.sort((a, b) => {
  if (a.product_brand > b.product_brand) { return 1 }
  if (a.product_brand < b.product_brand) { return -1 }
  return 0
})
//
//  :component:
const SideBySide = (props) => {
  const group = `[ROUTE-SIDE-BY-SIDE]`

  if (!props) {
    props = {}
  }
  let { arid } = useParams();
  
  if (!arid) {
    arid = "8800017"
  }
  const [viewARID, setViewARID] = useState(arid)
  const [userARID, setUserARID] = useState(arid)

  talk(`${group} call to render`)
  return (
    <Page>
      <RedirectIfNotLoggedIn></RedirectIfNotLoggedIn>
      <div className='h-full w-full flex flex-1 relative'>
        <EyekandyModelViewer className={"flex flex-1"} arid={viewARID} mode={"calibrate"}></EyekandyModelViewer>
        <iframe title="3DFW" className='flex flex-1 w-full' src={`https://eyekandy-player.pointandplace.com/raw/?arid=${viewARID}`}></iframe>

        <div className='absolute bottom-0 left-[40%] p-2 border-2  bg-gray-50'>
          <label>ARID: </label>
          <input list="models" type={"text"} value={userARID} onChange={(event) => { setUserARID(event.target.value) }} />
          <datalist id="models">
            {EYEKANDY_MODELS.map((model) => {
              if(!model.product_arid){ return false }
              return <option value={model.product_arid} key={model.product_arid}>{model.product_brand} {model.product_name} [{model.product_category} | {model.product_subcategory}]</option>
            })}
          </datalist>
          <button type='button' onClick={(event) => { setUserARID("") }}>Clear</button>

          <button type='button' onClick={(event) => { setViewARID(userARID) }}>Load</button>
        </div>
      </div>

    </Page>
  );
}

export default SideBySide;
