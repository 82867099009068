/*

  
  v i e w  u i
  View UI

  :description:
  Our UI component of the "view" concept of our app (to view a model in 3D).

  Some "config" options are exposed via buttons and tied in through redux actions.
  
*/

//
//  :react & redux:
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :code:
import {
  disableFullscreen,
  enableFullscreen,
  selectConfig,
  selectIsFullscreen,
  updateAlwaysRotate,
  updateAutoRotate,
  updateDisableTap,
  updateInteractionPrompt,
  updateStaticView,
} from '../../../features/calibration/redux/calibrationSlice'
import { KNOWN_CONFIG_ATTRIBUTES } from '../../../features/calibration/statics'
import { exitFullscreen, goFullscreenOnElement } from '../../../js/dom-utils'
import { talk } from '@eyekandy/app-core'
import { readModelViewerAttributeValue } from '../../../js/utils'
import { FullscreenEnterIcon } from '../../ui/svgs/fullscreen-enter'
import { FullscreenExitIcon } from '../../ui/svgs/fullscreen-exit'
import { ViewQRIcon } from '../../ui/svgs/view-qr-icon'
import { AutoRotateLockedIcon, AutoRotateUnlockedIcon } from '../../ui/svgs/auto-rotate-icon'
import { ViewInARIcon } from '../../ui/svgs/view-in-ar-icon'
import { CloseIcon } from '../../ui/svgs/close-icon'

//
//  :packages:
import { useSearchParams } from 'react-router-dom'
import { Spinner } from '../../ui/spinner/Spinner'
import { APP_CORE } from '../../../features/app-core/redux/memory'
import { AUDIO_ENABLED_ARIDS, DEFAULT_LANGAUGE } from '../../../settings'
import { PauseIcon } from '../../ui/svgs/pause-icon'
import { OflCustomFullscreenIcon } from '../../ui/svgs/ofl-custom-fullscreen-icon'
// import { PlayIconv2 } from '../../ui/svgs/play-icon-v2'

export const ViewUI = props => {
  const group = `[EYEKANDY-VIEW-UI]`
  const {
    arid,
    setClickedToStart,
    setLoadingScreenIsHidden,
    setStaticView,
    isOFL,
    isPausedFromParent,
    setIsPaused,
    buttonFillColour,
  } = props
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams()
  const isStaging = searchParams.get('environment') === 'staging'
  const environment = isStaging ? 'dev' : 'prod'

  //
  //  :react:
  const [showQRUI, setShowQRUI] = useState(false)
  const [canHandleAR, setCanHandleAR] = useState(false)
  const [hasBeenScanned, setHasBeenScanned] = useState(false)
  const [qrLoaded, setQRLoaded] = useState(false)

  //
  /// :v2:
  //  Pause/Play button code.
  // eslint-disable-next-line
  const [showPausePlayUI, setShowPausePlayUI] = useState(false)
  //
  //  interact overlay
  const [userInteracted, setUserInteracted] = useState(false)

  //
  //  :redux:
  const dispatch = useDispatch()
  const config = useSelector(selectConfig)
  const isFullscreen = useSelector(selectIsFullscreen)

  const MODEL_HAS_SOUND = AUDIO_ENABLED_ARIDS.includes(arid)

  useEffect(() => {
    const go = async () => {
      if (APP_CORE.env.hasBeenScanned) {
        setCanHandleAR(APP_CORE.env.canHandleAR)
        setHasBeenScanned(true)
      }
    }
    go()
  }, [])

  useEffect(() => {
    if (APP_CORE.readBrowserConfig('showPausePlayUI')) {
      setShowPausePlayUI(true)
    }
  }, [dispatch])

  useEffect(() => {
    setIsPaused(isPausedFromParent)
  }, [isPausedFromParent, setIsPaused])

  //
  // :model-viewer-hand:
  useEffect(() => {
    if (APP_CORE) {
      let modelViewerHand = true
      if (APP_CORE.readBrowserConfig('hideModelViewerHand')) {
        modelViewerHand = false
      }
      if (searchParams.has('eky-hide-model-viewer-hand')) {
        modelViewerHand = false
      }
      if (isOFL) {
        modelViewerHand = false
      }

      modelViewerHand ? dispatch(updateInteractionPrompt('auto')) : dispatch(updateInteractionPrompt('none'))
    }
  }, [isOFL, searchParams, dispatch])

  //
  //  :always-rotate:
  useEffect(() => {
    if (APP_CORE) {
      let alwaysRotate = true
      if (APP_CORE.readBrowserConfig('disableAlwaysRotate')) {
        alwaysRotate = false
      }
      if (searchParams.has('eky-disable-always-rotate')) {
        alwaysRotate = false
      }
      if (isOFL) {
        alwaysRotate = false
      }

      alwaysRotate ? dispatch(updateAlwaysRotate(true)) : dispatch(updateAlwaysRotate(false))
    }
  }, [isOFL, searchParams, dispatch])

  //
  //  :user-interacted:
  useEffect(() => {
    if (APP_CORE) {
      let userInteractedInitial = false
      if (APP_CORE.readBrowserConfig('disableUserMustInteract')) {
        userInteractedInitial = true
      }
      if (searchParams.has('eky-disable-user-must-interact')) {
        userInteractedInitial = true
      }
      if (isOFL) {
        userInteractedInitial = true
      }

      setUserInteracted(userInteractedInitial)
    }
  }, [isOFL, searchParams])

  //
  //  :enable-tap:
  useEffect(() => {
    if (APP_CORE) {
      let disableTap = true
      if (APP_CORE.readBrowserConfig('enableTap')) {
        disableTap = false
      }
      if (searchParams.has('eky-enable-tap')) {
        disableTap = false
      }
      if (isOFL) {
        disableTap = false
      }

      dispatch(updateDisableTap(disableTap))
    }
  }, [isOFL, searchParams, dispatch])

  //
  //  @Louis
  //  :bi-directional-window-messaging:
  //  Handler to assist with receiving/sending messages from window.
  const onWindowMessage = event => {
    try {
      if (!event || typeof event !== 'object') {
        return
      }
      if (!event.data) {
        return
      }
      const action = event.data.action
      const owner = event.data.owner
      if (owner !== 'eyekandy') {
        return
      }
      handleWindowMessageAction(action, event.data, event)
    } catch (err) {
      console.error(err)
    }
  }
  const handleWindowMessageAction = (action, data, event) => {
    /*
    window.postMessage({owner: "eyekandy", action: "show-qr"})
    */
    //
    //  :step 1:
    //  Validate that this is a known action.
    const VALID_ACTIONS = ['show-qr']
    if (!VALID_ACTIONS.includes(action)) {
      return false
    }
    //
    //  :step 2:
    //  Ok, now handle the action.
    let handled = false
    if (action === 'show-qr') {
      openQRUI()
      handled = true
    }
    //
    //  :step 3:
    //  All done, return true if we handled this action.
    return handled
  }

  //
  //  @Louis: Will resolve at some point.

  useEffect(() => {
    window.addEventListener('message', onWindowMessage)
    return () => {
      window.removeEventListener('message', onWindowMessage)
    }
    // eslint-disable-next-line
  }, [])

  //
  //  :events:
  //  Functions that are called when events are fired.
  const onClickToggleFullscreen = event => {
    if (isFullscreen) {
      exitFullscreen()
      dispatch(disableFullscreen())
    } else {
      //
      //  Go fullscreen on the page itself (it's the first singleton div in <Page>).
      goFullscreenOnElement(document.querySelector('#eky-page'))
      dispatch(enableFullscreen())
    }

    setUserInteracted(true)
  }

  const toggleAutoRotate = event => {
    dispatch(updateAutoRotate(!readModelViewerAttributeValue(config, KNOWN_CONFIG_ATTRIBUTES.autoRotate)))
    setUserInteracted(true)
  }

  const openQRUI = event => {
    setShowQRUI(true)
    setUserInteracted(true)
  }
  const closeQRUI = event => {
    setShowQRUI(false)
  }

  const pauseAudioElement = () => {
    const audioElement = document.getElementById('audio-element')
    if (audioElement) {
      audioElement.pause()
    }
  }
  //
  //  @liam It was requested that the play button overlay appears instead of the play button in the top right, here i'm updating all of the necessary states to achieve the functionality
  const onClickPauseModel = event => {
    dispatch(updateStaticView(true))
    setStaticView(true)
    setClickedToStart(false)
    setLoadingScreenIsHidden(false)
    pauseAudioElement()
    setIsPaused(true)
    setUserInteracted(true)
  }

  // const onClickPlayModel = event => {
  //   dispatch(updateStaticView(false))
  // }

  const renderQRURL = () => {
    const serviceToLaunch = MODEL_HAS_SOUND ? 'ar-assistant' : 'ar-models'
    return APP_CORE.qr.delivery(serviceToLaunch, arid, environment)
  }

  const openInAR = () => {
    const serviceToLaunch = MODEL_HAS_SOUND ? 'ar-assistant' : 'ar-models'
    APP_CORE.ar.launch(serviceToLaunch, arid, environment)
    setUserInteracted(true)
  }

  const getViewUIPositioningClasses = () => {
    const position = APP_CORE.readBrowserConfig('viewUISticky')
    if (!position) {
      return ''
    }
    if (position === 'NE') {
      if (isOFL && !isPausedFromParent) {
        return `right-0 md:top-[163px] lg:top-[165px] xl:top-[126px]`
      }
      return `top-0 right-0`
    }
    if (position === 'NW') {
      return `top-0 left-0`
    }
    if (position === 'SE') {
      return `bottom-0 right-0`
    }
    if (position === 'SW') {
      return `bottom-0 right-0`
    }
    return ''
  }

  talk([`${group} call to render`])

  const getQRBackgroundColour = () => {
    const colour = APP_CORE.readBrowserConfig('qrBackgroundColour')
    if (colour.includes('rgb')) {
      return colour
    }

    return `#${colour}`
  }

  const renderFullScreenExitEnterIcons = () => {
    if (isOFL) {
      return (
        <>
          <span className="w-[2.5rem] h-[2.5rem] text-[#4D4D51] bg-white leading-[2.25rem] text-center block border border-solid border-[#4D4D51] rounded-full">
            <OflCustomFullscreenIcon></OflCustomFullscreenIcon>
          </span>
        </>
      )
    }

    return isFullscreen ? (
      <FullscreenExitIcon fill={buttonFillColour}></FullscreenExitIcon>
    ) : (
      <FullscreenEnterIcon fill={buttonFillColour}></FullscreenEnterIcon>
    )
  }

  const renderQRARIcons = () => {
    if (isOFL && !canHandleAR && hasBeenScanned && APP_CORE.readBrowserConfig('hideARQRButton')) {
      return (
        <>
          <div
            className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg"
            onClick={openQRUI}
            title={APP_CORE.localiser.getLocalisation('view-button-qr-view', DEFAULT_LANGAUGE)}
          >
            <img
              src="https://cdn.pointandplace.com/buttons/ar-models/3a649d53-0802-4bd6-b09c-352f530352cc/en.gif"
              alt=""
            />
          </div>
        </>
      )
    }

    if (isOFL && canHandleAR && hasBeenScanned && APP_CORE.readBrowserConfig('hideARQRButton')) {
      return (
        <>
          <div
            className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg"
            onClick={openInAR}
            title={APP_CORE.localiser.getLocalisation('view-button-ar-view', DEFAULT_LANGAUGE)}
          >
            <img
              src="https://cdn.pointandplace.com/buttons/ar-models/3a649d53-0802-4bd6-b09c-352f530352cc/en.gif"
              alt=""
            />
          </div>
        </>
      )
    }

    if (!canHandleAR && hasBeenScanned && APP_CORE.readBrowserConfig('hideARQRButton')) {
      return (
        <>
          <div
            className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg"
            onClick={openQRUI}
            title={APP_CORE.localiser.getLocalisation('view-button-qr-view', DEFAULT_LANGAUGE)}
          >
            {<ViewQRIcon fill={buttonFillColour}></ViewQRIcon>}
          </div>
        </>
      )
    }

    if (canHandleAR && hasBeenScanned && APP_CORE.readBrowserConfig('hideARQRButton')) {
      return (
        <>
          <div
            className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg"
            onClick={openInAR}
            title={APP_CORE.localiser.getLocalisation('view-button-ar-view', DEFAULT_LANGAUGE)}
          >
            {<ViewInARIcon fill={buttonFillColour}></ViewInARIcon>}
          </div>
        </>
      )
    }
  }

  return (
    <>
      {!userInteracted && !isPausedFromParent && (
        <div
          className="user-interact-ui absolute top-0 bottom-0 p-2 flex flex-col justify-center items-center w-full transition-opacity cursor-pointer"
          onClick={() => {
            setUserInteracted(true)
          }}
        ></div>
      )}
      {isOFL && !showQRUI && !isPausedFromParent && (
        <div className="absolute hidden left-0 p-4 md:p-6 border-b w-full md:flex md:flex-col text-[#4D4D51] eky-ofl-font bg-white">
          <h3 className="font-bold text-[0.75rem] sm:text-[1.75rem] eky-ofl-title-font">Take a closer look</h3>
          <p className="text-[0.7rem] md:text-[1.5rem]">
            Enjoy a 360° view or see the furniture in your own home with our augmented reality (AR) tools.
          </p>
        </div>
      )}
      <div className={`view-ui absolute ${getViewUIPositioningClasses()} p-2 flex flex-row-reverse gap-4`}>
        {!(APP_CORE.env.isiOS() && APP_CORE.env.isMobile()) && (
          <div
            className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg flex items-center"
            onClick={onClickToggleFullscreen}
            title={APP_CORE.localiser.getLocalisation(
              `view-button-fullscreen-${isFullscreen ? 'disable' : 'enable'}`,
              DEFAULT_LANGAUGE
            )}
          >
            {renderFullScreenExitEnterIcons()}
            {/* {isFullscreen ? (
              <FullscreenExitIcon fill={APP_CORE.readBrowserConfig('primaryColour')}></FullscreenExitIcon>
            ) : (
              <FullscreenEnterIcon fill={APP_CORE.readBrowserConfig('primaryColour')}></FullscreenEnterIcon>
            )} */}
          </div>
        )}

        <div
          className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg hidden"
          onClick={toggleAutoRotate}
          title="Toggle model rotation"
        >
          {config['auto-rotate'] ? (
            <AutoRotateLockedIcon></AutoRotateLockedIcon>
          ) : (
            <AutoRotateUnlockedIcon></AutoRotateUnlockedIcon>
          )}
        </div>

        {renderQRARIcons()}
        {/* {!canHandleAR && hasBeenScanned && APP_CORE.readBrowserConfig('hideARQRButton') && (
          <div
            className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg"
            onClick={openQRUI}
            title={APP_CORE.localiser.getLocalisation('view-button-qr-view', DEFAULT_LANGAUGE)}
          >
            {<ViewQRIcon fill={APP_CORE.readBrowserConfig('primaryColour')}></ViewQRIcon>}
          </div>
        )}

        {canHandleAR && hasBeenScanned && APP_CORE.readBrowserConfig('hideARQRButton') && (
          <div
            className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg"
            onClick={openInAR}
            title={APP_CORE.localiser.getLocalisation('view-button-ar-view', DEFAULT_LANGAUGE)}
          >
            {<ViewInARIcon fill={APP_CORE.readBrowserConfig('primaryColour')}></ViewInARIcon>}
          </div>
        )} */}

        {showPausePlayUI && config['is-visible'] && (
          <div className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg" onClick={onClickPauseModel}>
            <PauseIcon fill={buttonFillColour}></PauseIcon>
          </div>
        )}

        {/* {showPausePlayUI && !config['is-visible'] && (
          <div className="cursor-pointer w-[60px] h-[60px] drop-shadow-lg" onClick={onClickPlayModel}>
            <PlayIconv2 fill={APP_CORE.readBrowserConfig('primaryColour')}></PlayIconv2>
          </div>
        )} */}

        <div className="top-0 left-0 right-0 bottom-0"></div>
      </div>

      {showQRUI && (
        <div
          className="qr-ui absolute top-0 left-0 flex w-full h-full z-10 justify-center items-center flex-col text-white"
          style={{
            backgroundColor: getQRBackgroundColour(),
            backdropFilter: `blur(16px)`,
          }}
        >
          <h1 className="text-lg md:text-2xl lg:text-3xl p-4 uppercase text-center text-white">
            {APP_CORE.localiser.getLocalisation('qr-text-heading', DEFAULT_LANGAUGE)}
          </h1>

          <div className="w-40 h-40 md:w-64 md:h-64 lg:w-96 lg:m-4 flex flex-col items-center justify-center relative">
            <img
              className={`w-40 h-40 md:w-64 md:h-64 rounded-3xl overflow-none transition-opacity ${
                qrLoaded ? '' : 'opacity-0'
              }`}
              style={{
                transitionProperty: 'opacity',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                transitionDuration: `500ms`,
              }}
              alt={APP_CORE.localiser.getLocalisation('qr-image-information', DEFAULT_LANGAUGE)}
              title={APP_CORE.localiser.getLocalisation('qr-image-information', DEFAULT_LANGAUGE)}
              src={renderQRURL()}
              onLoad={e => {
                setQRLoaded(true)
              }}
            />

            {!qrLoaded && (
              <div className="absolute top-0 left-0 flex justify-center items-center w-40 h-40 md:w-64 md:h-64 lg:w-96 lg:h-96">
                <Spinner></Spinner>
              </div>
            )}
          </div>

          <p className="text-sm md:text-lg lg:text-xl p-4 w-full md:w-[75%] xl:w-[50%] text-center uppercase text-white">
            {APP_CORE.localiser.getLocalisation('qr-text-instruction', DEFAULT_LANGAUGE)}
          </p>
          <span
            className="cursor-pointer"
            title={APP_CORE.localiser.getLocalisation('qr-button-close', DEFAULT_LANGAUGE)}
            onClick={event => {
              closeQRUI(event)
              setQRLoaded(false)
            }}
          >
            <CloseIcon fill={buttonFillColour}></CloseIcon>
          </span>
        </div>
      )}
    </>
  )
}
