import { REACT_APP_BASE_API_URL } from './settings'

export const getApiUrl = () => {
  //
  //  Check if the url is set in local storage.
  if (localStorage.getItem("apiBaseUrl")) {
    return localStorage.getItem("apiBaseUrl")
  }
  if (window.location.href.indexOf("localhost") > -1) {
    return `http://localhost:3338/dev`
  }
  //
  //  Return the base url that was injected when starting the application.
  return REACT_APP_BASE_API_URL
}
