/*

  m e m o r y
  Memory

  :description:
  A JavaScript representation of something in RAM (singleton instances etc).

*/

//
//  :code:
import { talk } from '@eyekandy/app-core';
import { CalibrationConfig } from "../classes/CalibrationConfig"
import { ClassifyLegacyCalibrationConfig } from "../classes/ClassifyLegacyCalibrationConfig"

//
//  :exports:
export let CONFIG = null
export let LEGACY_CONFIG = null

//
//  We only want to define our config object once.
if (typeof window.EKY_MEMORY_ALLOC_A === "undefined") {
    talk(`[MEMORY] creating a new singleton instance of our CalibrationConfig class`)
    CONFIG = new CalibrationConfig()
    window.EKY_MEMORY_ALLOC_A = true
}

if (typeof window.EKY_MEMORY_ALLOC_B === "undefined") {
  talk(`[MEMORY] creating a new singleton instance of our ClassifyLegacyCalibrationConfig class`)
  LEGACY_CONFIG = new ClassifyLegacyCalibrationConfig()
  window.EKY_MEMORY_ALLOC_EKY_MEMORY_ALLOC_B = true
}
