import React from 'react'

export const ViewQRIcon = props => {
  const fill = props.fill || '0CB7EB'
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' fill='none'>
      <circle cx='30' cy='30' r='30' fill='#fff' />
      <path
        fill={`#${fill}`}
        d='m28.695 43.095-9.225-5.4a2.72 2.72 0 0 1-.855-.9c-.21-.36-.315-.78-.315-1.26v-10.8c0-.42.105-.817.315-1.192.21-.375.495-.698.855-.968l9.27-5.4c.42-.24.848-.36 1.283-.36.434 0 .847.12 1.237.36l9.27 5.4c.36.27.645.593.855.968.21.375.315.772.315 1.192v10.8c0 .48-.105.9-.315 1.26-.21.36-.51.66-.9.9l-9.315 5.4c-.39.24-.803.36-1.238.36-.434 0-.847-.12-1.237-.36Zm.63-1.305V30.495l-9.63-5.49V35.49c0 .18.045.352.135.517.09.166.225.308.405.428l9.09 5.355Zm1.35 0 9.09-5.355c.18-.12.315-.263.405-.428.09-.164.135-.337.135-.517V25.005l-9.63 5.49V41.79ZM12 18.975V16.14c0-1.14.405-2.115 1.215-2.925C14.025 12.405 15 12 16.14 12h2.835v1.395H16.14c-.78 0-1.433.263-1.958.787-.524.525-.787 1.178-.787 1.958v2.835H12ZM16.14 48c-1.14 0-2.115-.405-2.925-1.215C12.405 45.975 12 45 12 43.86v-2.835h1.395v2.835c0 .78.263 1.432.787 1.958.525.525 1.178.787 1.958.787h2.835V48H16.14Zm24.885-.405V46.2h2.835c.78 0 1.432-.263 1.958-.788.525-.524.787-1.177.787-1.957v-2.88H48v2.88c0 1.14-.405 2.115-1.215 2.925-.81.81-1.785 1.215-2.925 1.215h-2.835Zm5.58-28.62V16.14c0-.78-.262-1.433-.787-1.958-.526-.524-1.178-.787-1.958-.787h-2.835V12h2.835c1.14 0 2.115.405 2.925 1.215C47.595 14.025 48 15 48 16.14v2.835h-1.395ZM30 29.28l9.63-5.535-9.09-5.265c-.18-.09-.36-.135-.54-.135-.18 0-.36.045-.54.135l-9.09 5.265L30 29.28Z'
      />
    </svg>
  )
}
