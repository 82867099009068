/*

  
  e y e k a n d y  m o d e l  v i e w e r
  Eyekandy Model Viewer

  :description:
  
  
*/

//
//  :react & redux:
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

//
//  :code:
import './EyekandyModelViewer.css'
import {
  loadFromCloudAsync,
  selectConfig,
  selectIsLoading,
  selectLoadFromCloud,
  setARID,
  setIsLoading,
  setIsNotLoading,
  updateStaticView,
} from '../../../features/calibration/redux/calibrationSlice'
import { Spinner } from '../../ui/spinner/Spinner'
import { MODE_APPROVE, MODE_CALIBRATE, MODE_VIEW } from '../../../features/calibration/statics'
import { talk } from '@eyekandy/app-core'
import { ViewUI } from '../view-ui/ViewUI'
import { CalibrateUI } from '../calibrate-ui/CalibrateUI'
import { isTruthy } from '../../../js/utils'
import { ApproveUI } from '../approve-ui/ApproveUI'

//
//  :packages:
// eslint-disable-next-line
// import "@google/model-viewer"
// import {ModelViewerElement} from '@google/model-viewer'
// eslint-disable-next-line
import { APP_CORE } from '../../../features/app-core/redux/memory'
import { ErrorIcon } from '../../ui/svgs/error-icon'
import { AUDIO_ENABLED_ARIDS, DEFAULT_LANGAUGE } from '../../../settings'
import { configureLogging, logModelClick, logModelDelivery } from '../../../features/app-core/redux/appCoreSlice'
import { PlayIconv2 } from '../../ui/svgs/play-icon-v2'

//
//  :component:
const EyekandyModelViewer = props => {
  const group = `[EYEKANDY-MODEL-VIEWER]`
  //
  //  :props:
  const { arid, mode, calibrateUIOpen, isOFL } = props
  const modelViewer = useRef(null)
  const audioElement = useRef(null)
  // eslint-disable-next-line
  const [searchParams] = useSearchParams()
  const isStaging = searchParams.get('environment') === 'staging'
  const environment = isStaging ? 'dev' : 'prod'
  const translate = searchParams.get('translate') === null ? true : isTruthy(searchParams.get('translate'))

  //
  //  :redux:
  const dispatch = useDispatch()
  const config = useSelector(selectConfig)
  const isLoading = useSelector(selectIsLoading)
  const loadFromCloudAction = useSelector(selectLoadFromCloud)

  const [clickedToStart, setClickedToStart] = useState(false)
  const [loadingScreenIsHidden, setLoadingScreenIsHidden] = useState(false)
  const [hasLoggedDeliveryOfModel, setHasLoggedDeliveryOfModel] = useState(false)

  const [errorUI, setErrorUI] = useState(false)

  const [staticView, setStaticView] = useState(false)
  const [isPaused, setIsPaused] = useState(false)

  //
  //  button fill colour
  const [buttonFillColour, setButtonFillColour] = useState(null)

  const MODEL_HAS_SOUND = AUDIO_ENABLED_ARIDS.includes(arid)

  //
  //  :model-viewer-events-effect:
  //  Our primary effect to handle model-viewer events and bind them to actions.
  useEffect(() => {
    //
    //  Define our logging group.
    const innerGroup = `[MODEL-VIEWER]`
    //
    //  :step 1:
    //  Find our model-viewer element in the DOM.
    const element = modelViewer.current

    //
    //  :handlers:
    //  Create a range of event handlers for the events we are dealing with.
    const echoEvent = event => {
      talk([`${innerGroup} event from model-viewer:`, `${event.type}`, event.detail])
    }
    const onLoadEvent = event => {
      echoEvent(event)
      setErrorUI(false)
    }
    const onModelLoaded = event => {
      echoEvent(event)
      if (event.detail.visible === false) {
        dispatch(setIsLoading())
      } else {
        //
        //  The model was loaded.
        dispatch(setIsNotLoading())
      }
    }

    const onModelError = event => {
      if (!event) {
        return
      }
      if (!event.detail) {
        return
      }
      if (event.detail.type === 'loadfailure') {
        setErrorUI(true)
        talk([`${innerGroup} error, the model failed to load:`, 'error'])
      }
      if (event.detail.type === 'webglcontextlost') {
        setTimeout(() => {
          //window.location.reload()
        }, 2222)
      }
    }

    const onModelProgress = event => {
      /*
      if(event.detail.totalProgress % 10 === 0 || event.detail.totalProgress > 0.9){
        talk([`${innerGroup} model load progress: ${Math.round(event.detail.totalProgress * 100)}%`])
      }
      */
    }

    //
    //  :step 2:
    //  Map out all of the events and their handlers.
    const modelViewerEventToHandlerMapping = {
      'model-visibility': onModelLoaded,
      progress: onModelProgress,
      load: onLoadEvent,
      error: onModelError,
    }

    //
    //  :step 3:
    //  Apply all of the handlers to the DOM element.
    for (const [k, v] of Object.entries(modelViewerEventToHandlerMapping)) {
      element.addEventListener(k, v)
    }

    return () => {
      //
      //  Critically, remove the bindings we set when re-rendering.
      for (const [k, v] of Object.entries(modelViewerEventToHandlerMapping)) {
        element.removeEventListener(k, v)
      }
    }
  }, [dispatch])

  //
  //  Set the ARID of our config when we first load in.
  useEffect(() => {
    if (arid) {
      dispatch(setARID(arid))
    }
  }, [dispatch, arid])

  //
  //  Set an async request running to load any config from the cloud.
  useEffect(() => {
    const go = async () => {
      if (!loadFromCloudAction.requesting && !loadFromCloudAction.error && !loadFromCloudAction.response) {
        //
        //  Wait for our configuration to load from the cloud.
        await dispatch(loadFromCloudAsync({ arid: arid, isStaging: isStaging }))
        //
        //  Now re-apply our get arguments to the config.
        dispatch(updateStaticView(APP_CORE.readBrowserConfig('staticView')))
      }
    }
    go()
  }, [arid, dispatch, loadFromCloudAction, isLoading, isStaging])

  //
  //  :auto-rotation-hault:
  //  It takes loads of compute to handle the model rotating, when not in focus - let's pause its rotation.
  //  @Louis: This doesn't work.
  /*
  useEffect(() => {
    if (initialAutoRotateSetting === 'empty') {
      setInitialAutoRotateSetting(readModelViewerAttributeValue(config, KNOWN_CONFIG_ATTRIBUTES.autoRotate))
    }
    window.onfocus = function () {
      //
      //  Make sure we are only playing once the user has interacted with the DOM (modern browser security feature)
      if (modelViewer.current && clickedToStart) {
        modelViewer.current.play()
        if (audioElement.current) {
          audioElement.current.play()
        }
      }
    }
    window.onblur = function () {
      modelViewer.current.pause()
      if (audioElement.current) {
        audioElement.current.pause()
      }
    }
  }, [config, dispatch, initialAutoRotateSetting, clickedToStart])
  */

  //
  //  @Louis: When the user has not yet clicked to start, the model will not spin.
  //          Will need to be refactored.
  //          @Louis: This breaks auto rotation.
  /*
  useEffect(() => {
    if (!clickedToStart) {
      dispatch(updateAutoRotate(false))
      setTimeout(() => {
        dispatch(updateAutoRotate(false))
      }, 2000)
    } else {
      dispatch(updateAutoRotate(initialAutoRotateSetting))
    }
  }, [clickedToStart, dispatch, initialAutoRotateSetting])
  */

  //
  // update config with classification
  /*
  useEffect(() => {
    if (isTruthy(translate)){
      const classification = new ClassifyLegacyCalibrationConfig(arid, dispatch)
      classification.renderCalibrationTranslations()
    }
  }, [dispatch, arid, translate])
  */

  //
  //  :app-core:
  //  We need to set up logging if we're delivering models.
  //  @Louis: Fires twice on dev due to react.
  useEffect(() => {
    //
    //  If we don't have an ARID, don't attempt to set up the logging.
    if (!arid) {
      return
    }

    //
    //  :step 1:
    //  Configure our logging using our reducer, pass the ARID we have for the current model.
    dispatch(configureLogging({ arid: arid }))

    //
    //  :step 2:
    //  If we have not yet logged delivery of this model, do so now.
    if (!hasLoggedDeliveryOfModel) {
      dispatch(logModelDelivery({ arid: arid }))
      setHasLoggedDeliveryOfModel(true)
    }
  }, [arid, dispatch, hasLoggedDeliveryOfModel])

  useEffect(() => {
    if (APP_CORE) {
      setStaticView(APP_CORE.readBrowserConfig('staticView'))
    }
  }, [])

  useEffect(() => {
    if (staticView) {
      setIsPaused(true)
    }
  }, [staticView, setIsPaused])

  //
  //  :clicks-on-model:
  //  We need to record every click on our model.
  const onClickOnModel = event => {
    const embedTpe = searchParams.get('eky-embedded') === 'true' ? 'embed' : 'button'
    dispatch(logModelClick({ arid: arid, x: event.clientX, y: event.clientY, type: embedTpe }))
  }
  useEffect(() => {
    window.addEventListener('mousedown', onClickOnModel)
    return () => {
      window.removeEventListener('mousedown', onClickOnModel)
    }
    // eslint-disable-next-line
  }, [])

  //
  //  :button-fill-colour:
  useEffect(() => {
    if (APP_CORE) {
      let buttonFillColourTemp = APP_CORE.readBrowserConfig('primaryColour')
      if (APP_CORE.readBrowserConfig('buttonFillColour')) {
        buttonFillColourTemp = APP_CORE.readBrowserConfig('buttonFillColour')
      }
      if (searchParams.has('eky-button-fill-colour')) {
        buttonFillColourTemp = searchParams.get('eky-button-fill-colour')
      }
      setButtonFillColour(buttonFillColourTemp)
    }
  }, [searchParams])

  //
  //  :helpers:
  const isViewMode = () => {
    return mode === MODE_VIEW
  }
  const isCalibrateMode = () => {
    return mode === MODE_CALIBRATE
  }
  const isCurrentlyLoading = () => {
    return isLoading || loadFromCloudAction.requesting
  }
  const isApproveMode = () => {
    return mode === MODE_APPROVE
  }

  const renderModelViewerStyles = () => {
    //
    // for now we are only using backgroundColor
    if (isLoading) {
      return {}
    }
    if (config) {
      if (config.backgroundColor) {
        return { backgroundColor: config.backgroundColor }
      }
    }
    return {}
  }

  //
  //  :ui-renderers:
  //  Functions to render our UI.
  const renderLoadingScreenUI = () => {
    if (clickedToStart) {
      return <></>
    }
    const FADE_IN_MILLISECONDS = 1 * 600
    return (
      <>
        <div
          className={`view-ui absolute top-0 bottom-0 p-2 flex flex-col justify-center items-center w-full transition-opacity ${
            loadingScreenIsHidden ? 'opacity-0' : ''
          }`}
          style={{
            backgroundColor: 'rgba(0,0,0,0.66)',
            backdropFilter: `blur(4.5px)`,
            transitionProperty: 'opacity',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: `${FADE_IN_MILLISECONDS}ms`,
          }}
        >
          {isOFL && (
            <div className="flex flex-col absolute top-[13%] md:top-[11%] p-[1.7rem] ml-[2rem] md:ml-0 max-w-[465px] md:max-w-[724px] eky-ofl-font">
              <h3 className="text-white font-bold md:text-[1.75rem] eky-ofl-title-font">Take a closer look</h3>
              <p className="text-white text-[0.7rem] md:text-[1.5rem]">
                Enjoy a 360° view or see the furniture in your own home with our augmented reality (AR) tools.
              </p>
            </div>
          )}
          <div
            className="cursor-pointer w-[80px] h-[80px] drop-shadow-lg flex items-center justify-center"
            style={{
              transform: 'scale(1.55)',
            }}
            onClick={event => {
              setLoadingScreenIsHidden(true)
              //dispatch(updateDisableZoom(false))
              setIsPaused(false)
              dispatch(updateStaticView(false))
              setTimeout(() => {
                //
                //  @Louis: Start the audio playing, not sure where there is a timeout required here.
                //  Potentially due to the "rotationDelay" value of the config?
                //
                //  Restart the animations of model-viewer and start the audio.
                if (audioElement.current) {
                  audioElement.current.play()
                }
                modelViewer.current.play()

                //
                //  Mark that we clicked to start the experience.
                setClickedToStart(true)
              }, FADE_IN_MILLISECONDS)
            }}
            title={APP_CORE.localiser.getLocalisation('view-button-start-experience', DEFAULT_LANGAUGE)}
          >
            {isCurrentlyLoading() ? <Spinner></Spinner> : <PlayIconv2 fill={buttonFillColour}></PlayIconv2>}
          </div>
        </div>
      </>
    )
  }

  //
  //  :ui-helpers:
  //  Functions that assist in working out which UI should be rendered.
  const shouldRenderModelViewer = () => {
    //return !loadFromCloudAction.requesting
    try {
      //
      //  @Louis: Try to update our background colour as soon as possible in our cycle.
      //          Likely a white flash still remains.
      document.querySelector('model-viewer').style.backgroundColor = APP_CORE.readBrowserConfig('backgroundColour')
    } catch (err) {}

    return true
  }

  const shouldRenderViewUI = () => {
    if (isLoading) {
      return null
    }
    return isViewMode() || isCalibrateMode()
  }

  const shouldRenderCalibrateUI = () => {
    if (isLoading) {
      return null
    }
    return isCalibrateMode()
  }

  const shouldRenderApproveUI = () => {
    if (isLoading) {
      return null
    }
    return isApproveMode()
  }

  //
  //  :events:
  //  Functions that are called when events are fired.

  //
  //  :jsx:
  //  The actual JSX we'll return from this component.
  talk([`${group} call to render, config:`, config])
  return (
    <div className="flex-1 h-full flex flex-row-reverse relative">
      {/* model-viewer component */}
      {shouldRenderModelViewer() && (
        <>
          <model-viewer
            style={renderModelViewerStyles()}
            ref={modelViewer}
            src={APP_CORE.android.renderModelURL(arid, environment)}
            {...config}
          >
            {/* @Liam: Lets refactor this, but for now just get it working */}
            <effect-composer render-mode="quality" msaa="2">
              <color-grade-effect
                contrast={config['contrast']}
                brightness={config['brightness']}
                hue={config['hue']}
                tonemapping={config['tone-mapping']}
                saturation={config['saturation']}
              ></color-grade-effect>
            </effect-composer>
            {/*div slot="interaction-prompt">MOVE</div> */}
            {MODEL_HAS_SOUND && (
              <audio id="audio-element" ref={audioElement} autoPlay={false} loop>
                <source src={APP_CORE.android.renderModelSoundURL(arid, environment)} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            )}
          </model-viewer>
        </>
      )}

      {/* ui */}
      {staticView && renderLoadingScreenUI()}
      {shouldRenderViewUI() && (
        <ViewUI
          arid={arid}
          translate={translate}
          isOFL={isOFL}
          setClickedToStart={setClickedToStart}
          setLoadingScreenIsHidden={setLoadingScreenIsHidden}
          isPausedFromParent={isPaused}
          setIsPaused={setIsPaused}
          setStaticView={setStaticView}
          buttonFillColour={buttonFillColour}
        />
      )}
      {shouldRenderCalibrateUI() && (
        <CalibrateUI
          enableBasicUI={true}
          enableAdvancedUI={true}
          enableExperimentalUI={false}
          calibrateUIOpen={calibrateUIOpen}
          enablePresetUI={true}
          enableLocalSaveLoadUI={true}
          enableCloudSaveLoadUI={true}
          translate={translate}
          arid={arid}
        />
      )}
      {shouldRenderApproveUI() && <ApproveUI />}
      {errorUI && (
        <>
          <div
            className="qr-ui error-ui absolute top-0 left-0 flex w-full h-full z-10 justify-center items-center flex-col text-white"
            style={{}}
          >
            <div className="w-64 lg:w-96 flex flex-col relative bg-stone-700 rounded-3xl overflow-hidden drop-shadow-xl">
              <div className="w-full bg-stone-800 px-4 py-4 flex items-center justify-between gap-4">
                <ErrorIcon></ErrorIcon>{' '}
                <h1 className="text-xl font-bold">
                  {APP_CORE.localiser.getLocalisation('error-text-heading-not-found', DEFAULT_LANGAUGE)}
                </h1>
              </div>
              <div className="w-full p-6 flex flex-col gap-2">
                <p>{APP_CORE.localiser.getLocalisation('error-text-content-not-found', DEFAULT_LANGAUGE)}</p>
                <button className="w-full px-4 py-2 bg-stone-600 rounded-lg mt-4 hidden">
                  {APP_CORE.localiser.getLocalisation('error-button-close-not-found', DEFAULT_LANGAUGE)}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EyekandyModelViewer
